import Icon from "../Icon"
import React from "react"
import styled, { css } from "styled-components"

type TimePickerRangeId = string | number

interface IDeleteIconButtonProps {
  id: TimePickerRangeId
  onDelete?: (id: string | number) => void
  disabled?: boolean
  readonly?: boolean
}

export const DeleteIconButton: React.FC<IDeleteIconButtonProps> = ({
  id,
  onDelete,
  disabled,
  readonly,
}) => {
  const handleDelete = () => {
    onDelete?.(id)
  }

  return (
    <StyledDeleteButton
      data-value={id}
      type="button"
      onClick={handleDelete}
      hidden={disabled || readonly}
    >
      <Icon remixiconClass="ri-close-line" size={20} />
    </StyledDeleteButton>
  )
}

const StyledDeleteButton = styled.button`
  ${({ theme, disabled }) => css`
    width: 24px;
    height: 24px;
    padding: 0px;
    margin-top: 4px;
    margin-left: 8px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    border: none;
    box-shadow: none;
    background: ${theme.colors.transparent};
    cursor: pointer;

    &:hover,
    &:focus {
      color: ${disabled ? theme.colors["Neutral5"] : theme.colors["Neutral6"]};
    }
  `}
`
