import Button from "../../../../ui/Button"
import ConfirmationModal from "../../../../ui/ConfirmationModal"
import DataTable from "../../../../ui/DataTable"
import EmptyState from "../../../../ui/EmptyState"
import { showGraphqlErrors } from "../../../../ui/ErrorList/ErrorList"
import DetailPage from "../../../../ui/Layouts/MainLayout/DetailPage"
import PageTitle from "../../../../ui/PageTitle"
import notification from "../../../../ui/notification"
import DisplayQRModal from "./DisplayQRModal"
import EditTable from "./EditTable"
import { useDisableLocationTableMutation } from "./GraphQL/disableLocationTable.generated"
import type { GetAllTablesByLocationQuery } from "./GraphQL/getAllTablesByLocation.generated"
import { useGetAllTablesByLocationQuery } from "./GraphQL/getAllTablesByLocation.generated"
import QRCodeViewer from "./QRCodeViewer"
import type {
  ILocationTableZoneModel,
  ITablesPageParams,
} from "./TableForm/hookforms.interfaces"
import type { ILocationTableModel } from "./graphql.interfaces"
import tableUtils from "./table-list.util"
import get from "lodash/get"
import React, { useCallback, useEffect, useState } from "react"
import { useIntl } from "react-intl"
import { useParams } from "react-router-dom"
import styled from "styled-components"

export const Tables: React.FC = () => {
  const intl = useIntl()
  const { locationUUID } = useParams<ITablesPageParams>()

  const [selectedTableUUIDs, setSelectedTableUUIDs] = useState<Array<string>>(
    []
  )
  const [selectedTables, setSelectedRecords] = useState<
    Array<ILocationTableModel>
  >([])
  const [showConfirmDelete, setShowConfirmDelete] = useState<boolean>()
  const [showBulkPreview, setShowBulkPreview] = useState<boolean>()
  const [showTableQR, setShowTableQR] = useState<boolean>(false)
  const [rowSelected, setRowSelected] = useState<ILocationTableModel>()
  const [rowDeleteByDropdown, setRowDeleteByDropdown] = useState<string>()
  const [editData, setEditData] = useState<ILocationTableZoneModel>()

  const [columns] = tableUtils<ILocationTableModel>({
    onQRClick: (_, record) => {
      setShowTableQR(true)
      setRowSelected(record)
    },
    deletedTable: setRowDeleteByDropdown,
  })

  const [disableTable, { loading: deleting }] =
    useDisableLocationTableMutation()

  const { data, error, fetchMore, refetch, loading } =
    useGetAllTablesByLocationQuery({
      variables: { locationUUID },
      skip: !locationUUID,
      notifyOnNetworkStatusChange: true,
      returnPartialData: true,
    })

  const tableList: ILocationTableModel[] = get(
    data,
    "getAllTablesByLocation.results",
    []
  )
  const hasNextPage = get(data, "getAllTablesByLocation.hasNextPage", false)
  const endCursor = get(data, "getAllTablesByLocation.endCursor", undefined)

  const fetchMoreTables = useCallback(async () => {
    await fetchMore({
      variables: { locationUUID, after: endCursor },
      updateQuery: (prev: GetAllTablesByLocationQuery, { fetchMoreResult }) => {
        if (
          !fetchMoreResult ||
          prev.getAllTablesByLocation?.endCursor ===
            fetchMoreResult.getAllTablesByLocation?.endCursor
        )
          return prev

        return Object.assign({}, prev, {
          getAllTablesByLocation: {
            ...fetchMoreResult.getAllTablesByLocation,
            results: [
              ...(prev.getAllTablesByLocation.results as ILocationTableModel[]),
              ...(fetchMoreResult.getAllTablesByLocation
                .results as ILocationTableModel[]),
            ],
          },
        })
      },
    })
  }, [endCursor, locationUUID, fetchMore])

  const onCancelDelete = () => {
    setShowConfirmDelete(false)
  }

  const onConfirmDelete = () => {
    setShowConfirmDelete(true)
  }

  const onDisplayQRPreview = () => {
    setShowBulkPreview(true)
  }

  const onCloseQRPreview = () => {
    setShowBulkPreview(false)
  }

  const doBulkDeleteTables = async () => {
    try {
      await Promise.all(
        selectedTableUUIDs.map((uuid) => {
          return disableTable({
            variables: {
              data: {
                uuid,
                locationUUID: locationUUID ?? "",
              },
            },
          })
        })
      )

      refetch()
      setSelectedTableUUIDs([])
      setShowConfirmDelete(false)

      notification({
        type: "success",
        description: intl.formatMessage({
          id: "restaurants.location.bulk.delete.table.modal.success",
          defaultMessage: "Your tables were deleted",
        }),
      })
    } catch (deleteError) {
      showGraphqlErrors(deleteError)
    }
  }

  const setBannerText = (rows?: number) => {
    return intl.formatMessage(
      {
        id: "restaurants.location.tables.banner",
        defaultMessage:
          "{rows} {rows, plural, one {Table} other {Tables}} Selected",
      },
      { rows }
    )
  }

  const hideQRModal = () => setShowTableQR(false)

  const retry = () => refetch()

  const onEditTableClose = () => {
    setEditData(undefined)
  }

  useEffect(() => {
    if (rowDeleteByDropdown && selectedTableUUIDs.length > 0) {
      setSelectedTableUUIDs(
        selectedTableUUIDs.filter((uuid) => uuid !== rowDeleteByDropdown)
      )
      setRowDeleteByDropdown(undefined)
    }
  }, [rowDeleteByDropdown, selectedTableUUIDs])

  return (
    <DetailPage id="ingredients-page">
      <PageTitle
        title={intl.formatMessage({
          id: "restaurants.location.tables.section.1.title",
          defaultMessage: "Table List",
        })}
        description={intl.formatMessage({
          id: "restaurants.location.tables.section.1.subtitle",
          defaultMessage:
            "Here you can see information about the tables, seats and their statuses. Double click on the row to edit a table.",
        })}
      />

      <StyledTablesContent>
        <ConfirmationModal
          visible={showConfirmDelete}
          itemType={intl.formatMessage({
            id: "restaurants.location.table.delete.table.modal.title.type",
            defaultMessage: "Tables",
          })}
          onConfirm={doBulkDeleteTables}
          onCancel={onCancelDelete}
          loading={deleting}
        />

        <DisplayQRModal
          closable={false}
          visible={showTableQR}
          onCancel={hideQRModal}
          tableRowData={rowSelected}
        />

        <QRCodeViewer
          datasource={selectedTables}
          visible={showBulkPreview}
          onCancel={onCloseQRPreview}
          transitionName={undefined}
        />

        <EditTable
          visible={!!editData}
          defaultValues={editData}
          onClose={onEditTableClose}
          closable={false}
        />

        <DataTable
          rowSelectionType="checkbox"
          substactPixelesToScrollHeight={300}
          dataSource={tableList}
          columns={columns}
          loading={loading}
          fetchMore={fetchMoreTables}
          hasNextPage={hasNextPage}
          selectedRowKeys={selectedTableUUIDs}
          onSelectCheckboxes={(selectedKeys, records) => {
            setSelectedTableUUIDs(selectedKeys)
            setSelectedRecords(records || [])
          }}
          onRow={(record) => {
            return {
              onDoubleClick: () => {
                setEditData(record as ILocationTableZoneModel)
              },
            }
          }}
          bannerText={setBannerText}
          bannerActions={[
            <Button
              key="action-delete"
              title={intl.formatMessage({
                id: "restaurants.location.tables.banner.delete",
                defaultMessage: "Delete",
              })}
              className="right-spaced"
              hierarchy="secondary"
              onClick={onConfirmDelete}
            />,
            <Button
              key="action-print-qr"
              title={intl.formatMessage({
                id: "restaurants.location.tables.banner.printQR",
                defaultMessage: "Print QR Code",
              })}
              hierarchy="secondary"
              onClick={onDisplayQRPreview}
            />,
          ]}
          emptyMessage={
            <EmptyState
              title={intl.formatMessage({
                id: "restaurants.location.tables.empty.title",
                defaultMessage: "No tables added yet",
              })}
              description={intl.formatMessage({
                id: "restaurants.location.tables.empty.description",
                defaultMessage: "You can add one by clicking the plus button",
              })}
            />
          }
          error={error}
          errorRefetch={retry}
        />
      </StyledTablesContent>
    </DetailPage>
  )
}

const StyledTablesContent = styled.div`
  margin-top: 48px;
`
