import * as Types from '../../../../../../shared/graphql/generated/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DisableTableZoneMutationVariables = Types.Exact<{
  data: Types.DisableTableZoneInput;
}>;


export type DisableTableZoneMutation = { __typename?: 'Mutation', disableTableZone: { __typename?: 'TableZoneLocationModel', uuid: string, name: string } };


export const DisableTableZoneDocument = gql`
    mutation disableTableZone($data: DisableTableZoneInput!) {
  disableTableZone(data: $data) {
    uuid
    name
  }
}
    `;
export type DisableTableZoneMutationFn = Apollo.MutationFunction<DisableTableZoneMutation, DisableTableZoneMutationVariables>;

/**
 * __useDisableTableZoneMutation__
 *
 * To run a mutation, you first call `useDisableTableZoneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDisableTableZoneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [disableTableZoneMutation, { data, loading, error }] = useDisableTableZoneMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useDisableTableZoneMutation(baseOptions?: Apollo.MutationHookOptions<DisableTableZoneMutation, DisableTableZoneMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DisableTableZoneMutation, DisableTableZoneMutationVariables>(DisableTableZoneDocument, options);
      }
export type DisableTableZoneMutationHookResult = ReturnType<typeof useDisableTableZoneMutation>;
export type DisableTableZoneMutationResult = Apollo.MutationResult<DisableTableZoneMutation>;
export type DisableTableZoneMutationOptions = Apollo.BaseMutationOptions<DisableTableZoneMutation, DisableTableZoneMutationVariables>;