import { CacheUpdateFunction } from "../../../Employees/types/graphql.enums"
import {
  LocationCateringRuleDocument,
  LocationCateringRuleQuery,
} from "../../Graphql/locationCateringRule.generated"
import {
  UpdateLocationCateringRulesMutation,
  UpdateLocationCateringRulesMutationVariables,
} from "../../Graphql/updateLocationCateringRules.generated"

export const updateCateringOrderCache: CacheUpdateFunction<
  UpdateLocationCateringRulesMutation,
  UpdateLocationCateringRulesMutationVariables
> = (cache, result) => {
  if (result.data) {
    const updateLocationCateringRules = result.data.updateLocationCateringRules

    cache.updateQuery<LocationCateringRuleQuery>(
      {
        query: LocationCateringRuleDocument,
      },
      (prevData) => {
        if (prevData) {
          return {
            locationCateringRule: {
              ...prevData.locationCateringRule,
              ...updateLocationCateringRules,
            },
          }
        }
      }
    )
  }
}
