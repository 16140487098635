import Button from "../Button"
import type { ButtonClassType } from "../Button/interfaces"
import type { IconColor } from "../Icon"
import Icon from "../Icon"
import Text from "../Typography/Text"
import type { ModalProps } from "antd"
import { Modal } from "antd"
import React from "react"
import { useIntl } from "react-intl"
import { FormattedMessage } from "react-intl"
import type { IBaseThemeColors } from "styled-components"
import styled, { css } from "styled-components"

export type ConfirmationModalType =
  | "danger"
  | "info"
  | "success"
  | "error"
  | "warning"

const ActionsConfig: {
  [key in ConfirmationModalType]: {
    danger: boolean
    icon: string
    iconColor: IconColor
    buttonDisplay: ButtonClassType
  }
} = {
  danger: {
    danger: true,
    icon: "ri-error-warning-line",
    iconColor: "Danger5",
    buttonDisplay: "danger",
  },
  info: {
    danger: false,
    icon: "ri-error-warning-line",
    iconColor: "Primary5",
    buttonDisplay: "default",
  },
  error: {
    danger: true,
    icon: "ri-close-circle-line",
    iconColor: "Danger5",
    buttonDisplay: "danger",
  },
  success: {
    danger: false,
    icon: "ri-checkbox-circle-line",
    iconColor: "Success5",
    buttonDisplay: "default",
  },
  warning: {
    danger: false,
    icon: "ri-error-warning-line",
    iconColor: "Warning5",
    buttonDisplay: "warning",
  },
}

interface IConfirmationModal extends Omit<ModalProps, "width"> {
  itemType?: string
  onConfirm?: () => void
  onCancel?: () => void
  cancelButtonText?: string
  confirmButtonText?: string
  title?: string
  subtitle?: string | React.ReactNode
  itemName?: string
  loading?: boolean
  type?: ConfirmationModalType
}

export const ConfirmationModal: React.FC<IConfirmationModal> = ({
  cancelButtonText,
  confirmButtonText,
  itemType,
  itemName,
  onConfirm,
  onCancel,
  loading,
  subtitle,
  title,
  children,
  type = "danger",
  ...modalProps
}) => {
  const intl = useIntl()
  const { icon, iconColor, buttonDisplay } = ActionsConfig[type]

  return (
    <StyledConfirmationModal {...modalProps} footer={false} closable={false}>
      <div role="delete-confirmation-wrapper">
        <StyledConfirmationContent>
          <StyledContentIcon>
            <Icon remixiconClass={icon} color={iconColor} size={24} />
          </StyledContentIcon>
          <StyledContentText>
            <Text weight="bold" size="m">
              {title ??
                intl.formatMessage(
                  {
                    id: "components.confirmation.modal.title",
                    defaultMessage: "Are you sure to delete this {itemType}?",
                  },
                  { itemType }
                )}
            </Text>
            {itemName && (
              <Text
                weight="regular"
                size="m"
                className="delete-content-subtitle"
              >
                <FormattedMessage
                  id="components.confirmation.modal.description"
                  defaultMessage="You are about to delete {itemName}, you cannot undo this action."
                  values={{
                    itemName: (
                      <StyledContentSubTitleColor>
                        {itemName}
                      </StyledContentSubTitleColor>
                    ),
                  }}
                />
              </Text>
            )}
            {subtitle && (
              <Text
                weight="regular"
                size="m"
                className="delete-content-subtitle"
              >
                {subtitle}
              </Text>
            )}
            {children}
          </StyledContentText>
        </StyledConfirmationContent>
        <StyledConfirmationButtons>
          <Button
            aria-label="cancel-button"
            onClick={onCancel}
            display={buttonDisplay}
            disabled={loading}
            hierarchy="secondary"
            title={
              cancelButtonText ??
              intl.formatMessage({
                id: "components.confirmation.modal.cancel.title",
                defaultMessage: "No, Go Back",
              })
            }
          />

          {!!onConfirm && (
            <StyledConfirmButton
              aria-label="delete-button"
              data-testid="confirm-button"
              onClick={onConfirm}
              disabled={loading}
              loading={loading}
              title={
                confirmButtonText ??
                intl.formatMessage(
                  {
                    id: "components.confirmation.modal.ok.title",
                    defaultMessage: "Delete {itemType}",
                  },
                  { itemType }
                )
              }
              display={buttonDisplay}
            />
          )}
        </StyledConfirmationButtons>
      </div>
    </StyledConfirmationModal>
  )
}

const StyledConfirmationModal = styled(Modal).attrs({
  width: "416px",
})`
  white-space: pre-wrap;
  border-radius: 8px;

  .ant-modal-body {
    padding: 32px 32px 24px 32px;
  }

  .ant-modal-content {
    border-radius: 8px;
  }
`

const StyledConfirmationContent = styled.div`
  margin-bottom: 24px;
  display: flex;
`

const StyledContentIcon = styled.div`
  width: 24px;
  height: 24px;
  margin-right: 16px;
`

const StyledContentText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  & h5.delete-content-subtitle {
    margin-top: 8px;
  }
`

const StyledContentSubTitleColor = styled.span`
  ${({ theme }) =>
    css`
      color: ${theme.colors["Primary5"]};
    `}
`

const StyledConfirmationButtons = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: right;
  align-items: center;
`

const StyledConfirmButton = styled(Button)<{
  background?: keyof IBaseThemeColors
}>`
  margin-left: 8px;
`
