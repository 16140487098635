import { CardSkeleton } from "../Card/Card.skeleton"
import Container from "../Container"
import { LabelFieldSkeleton } from "../InputLabel"
import { InputFieldSkeleton } from "../Inputs"
import { ModalFull } from "./ModalFull"

export const ModalFullSkeleton = () => {
  return (
    <ModalFull
      visible
      title={
        <>
          <LabelFieldSkeleton />
          <LabelFieldSkeleton />
        </>
      }
    >
      <Container display="flex" flexDirection="column" gap="50px">
        <Container>
          <InputFieldSkeleton size="large" width="200px" marginBottom="5px" />
          <LabelFieldSkeleton />
          <LabelFieldSkeleton />
        </Container>
        <Container>
          <LabelFieldSkeleton />
          <LabelFieldSkeleton />
        </Container>
        <Container>
          <LabelFieldSkeleton />
          <CardSkeleton cards={2} />
        </Container>
        <Container>
          <LabelFieldSkeleton />
          <CardSkeleton cards={1} />
        </Container>
      </Container>
    </ModalFull>
  )
}
