import { MM_DD_YYYY } from "../../shared/utils/constant/dateFormats"
import Container from "../Container"
import InputHelper from "../Inputs/InputHelper"
import { DatePicker } from "antd"
import type { PickerProps } from "antd/lib/date-picker/generatePicker"
import type { Moment } from "moment"
import React from "react"
import styled, { css } from "styled-components"

type StyledDatePickerProps = Partial<PickerProps<Moment>> & {
  hasErrors?: boolean
  helperText?: string
}

export const SimpleDatePicker: React.FC<StyledDatePickerProps> = ({
  placeholder = "Select Date",
  hasErrors,
  helperText,
  ...props
}) => {
  return (
    <Container>
      <StyledDatePicker
        placeholder={placeholder}
        {...props}
        $hasError={hasErrors}
        format={MM_DD_YYYY}
        getPopupContainer={handlePopupContainer}
      />

      {!!helperText && (
        <Container position="relative" margin="4px 0 0 0">
          <InputHelper $hasError={hasErrors} size="s">
            {helperText}
          </InputHelper>
        </Container>
      )}
    </Container>
  )
}

const handlePopupContainer = (trigger: HTMLElement) => trigger

type StyledSimpleDatePickerProps = PickerProps<Moment> & {
  $hasError?: boolean
}

const StyledDatePicker = styled(DatePicker)<StyledSimpleDatePickerProps>`
  ${({ theme, $hasError }) =>
    css`
      border-radius: 4px;
      width: 100%;

      &.ant-picker:hover,
      &.ant-picker-focused {
        border-color: ${theme.colors["Primary5"]};
        box-shadow: none;
      }

      .ant-picker-panel-container {
        filter: drop-shadow(${theme.shadows.elevationHigh});
        display: flex;
        flex-direction: row-reverse;
      }

      .ant-picker-panel-container > .ant-picker-footer {
        min-width: max-content;
        display: flex;
        flex-direction: row-reverse;
      }

      .ant-picker-today-btn {
        color: ${theme.colors["Primary6"]};
      }

      .ant-picker-cell-inner::before {
        border: 1px solid ${theme.colors["Primary6"]} !important;
      }

      .ant-picker-cell-selected {
        > .ant-picker-cell-inner {
          background-color: ${theme.colors["Primary6"]};
        }
      }

      button:hover {
        color: ${theme.colors["Primary6"]};
      }

      ${$hasError &&
      css`
        border-color: ${theme.colors["Danger5"]};

        &:hover {
          outline: 0px;
        }

        &:focus-within {
          outline: 1px solid ${theme.colors["Danger5"]};
        }

        &.ant-picker:hover,
        &.ant-picker-focused {
          border: 1px solid ${theme.colors["Danger5"]};
        }
      `}
    `}
`
