import { Skeleton } from "antd"
import styled from "styled-components"

export const SubMenuSkeleton = () => {
  return (
    <StyledSubMenuWrapper className="submenu-skeleton">
      <StyledSubMenu className="menu-skeleton" paragraph={false} active />
      <StyledMenuItem className="menu-item-skeleton" paragraph={false} active />
    </StyledSubMenuWrapper>
  )
}

const StyledSubMenuWrapper = styled.div`
  .submenu-skeleton {
    .ant-skeleton-title {
      margin-top: 0;
    }
  }
`

export const StyledSubMenu = styled(Skeleton)`
  &.menu-skeleton {
    .ant-skeleton-title {
      height: 24px;
      width: 160px;
    }
  }
`

const StyledMenuItem = styled(Skeleton)`
  &.menu-item-skeleton {
    .ant-skeleton-title {
      height: 24px;
      width: 128px;
      margin-left: 32px;
      margin-right: 16px;
    }
  }
`
