import { DeliveryIntegrationEnum } from "../../../../../shared/graphql/generated/types"
import AddIcon from "../../../../../ui/AddIcon"
import Container from "../../../../../ui/Container"
import CreateDeliveryIntegration from "../CreateDeliveryIntegration"
import { useState } from "react"

const defaultValues = { integrationName: DeliveryIntegrationEnum.ONFLEET }

export const AddDeliveryIntegrationButton = () => {
  const [displayModal, setDislayModal] = useState<boolean>(false)

  const onClose = () => {
    setDislayModal(false)
  }

  const showModal = () => {
    setDislayModal(true)
  }

  return (
    <Container display="flex" alignItems="center">
      <AddIcon onClick={showModal} />
      <CreateDeliveryIntegration
        visible={displayModal}
        onCancel={onClose}
        closable={false}
        defaultValues={defaultValues}
      />
    </Container>
  )
}
