import yup from "../../../../../shared/yup"
import { yupResolver } from "@hookform/resolvers/yup"

export const TableResolver = yupResolver(
  yup.object().shape({
    name: yup.string().required(),
    capacity: yup.number().integer().moreThan(0).lessThan(1000).required(),
    tableZoneUUID: yup.string().required(),
  })
)
