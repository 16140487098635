import * as Types from '../../../../../shared/graphql/generated/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateScheduleForLocationMutationVariables = Types.Exact<{
  data: Types.CreateScheduleForLocationInput;
}>;


export type CreateScheduleForLocationMutation = { __typename?: 'Mutation', createScheduleForLocation: { __typename?: 'ScheduleBasicWithDetailsModel', uuid: string, scheduleDetail: Array<{ __typename?: 'ScheduleDetailModel', uuid: string, day: string, endDate: any, startDate: any }> } };


export const CreateScheduleForLocationDocument = gql`
    mutation createScheduleForLocation($data: CreateScheduleForLocationInput!) {
  createScheduleForLocation(data: $data) {
    uuid
    scheduleDetail {
      uuid
      day
      endDate
      startDate
    }
  }
}
    `;
export type CreateScheduleForLocationMutationFn = Apollo.MutationFunction<CreateScheduleForLocationMutation, CreateScheduleForLocationMutationVariables>;

/**
 * __useCreateScheduleForLocationMutation__
 *
 * To run a mutation, you first call `useCreateScheduleForLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateScheduleForLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createScheduleForLocationMutation, { data, loading, error }] = useCreateScheduleForLocationMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateScheduleForLocationMutation(baseOptions?: Apollo.MutationHookOptions<CreateScheduleForLocationMutation, CreateScheduleForLocationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateScheduleForLocationMutation, CreateScheduleForLocationMutationVariables>(CreateScheduleForLocationDocument, options);
      }
export type CreateScheduleForLocationMutationHookResult = ReturnType<typeof useCreateScheduleForLocationMutation>;
export type CreateScheduleForLocationMutationResult = Apollo.MutationResult<CreateScheduleForLocationMutation>;
export type CreateScheduleForLocationMutationOptions = Apollo.BaseMutationOptions<CreateScheduleForLocationMutation, CreateScheduleForLocationMutationVariables>;