import integrationsStyles from "../../Integrations.module.css"
import { loremIpsum } from "../../SquareDetail/config"
import { Anchor, Group, Stack, Text, Title } from "@mantine/core"
import { IconExternalLink } from "@tabler/icons-react"
import { useIntl } from "react-intl"

type HowItWorksSectionProps = {
  href: string
  description?: string
}

export const HowItWorksSection = ({
  href,
  description = loremIpsum,
}: HowItWorksSectionProps) => {
  const intl = useIntl()
  return (
    <Stack spacing={16} className={integrationsStyles["bordered-container"]}>
      <Title order={5} c="gray.9">
        {intl.formatMessage({
          defaultMessage: "How it works",
          id: "restaurants.settings.locations.square.integration.how.it.works.title",
        })}
      </Title>
      <Text c="gray.6">{description}</Text>
      <Anchor href={href} target="_blank" c="primary.6" w={160}>
        <Group spacing={8}>
          <Text size="sm">
            {intl.formatMessage({
              id: "restaurants.settings.locations.square.integration.documentation.anchor",
              defaultMessage: "Go to documentation",
            })}
          </Text>
          <IconExternalLink size={16} />
        </Group>
      </Anchor>
    </Stack>
  )
}
