import { ExternalIntegrationEnum } from "../../../../shared/graphql/generated/types"
import paths from "../../../../shared/routes/paths"
import { IntlMessage } from "../../../../shared/types"

type IntegrationDataType = {
  createPath: (locationUUID?: string) => string
  editPath: (locationUUID?: string) => string
  title: IntlMessage
  description: IntlMessage
}

type IntegrationsObjectType = Record<
  ExternalIntegrationEnum,
  Array<IntegrationDataType>
>

export const SquareIntlMessage = {
  id: "restaurants.settings.locations.integration.square.name",
  defaultMessage: "Square",
}

export const integrationsFilterValues: Record<ExternalIntegrationEnum, string> =
  {
    MENU: "data-import",
    ORDER_AGGREGATION: "order-aggregation",
  }

export const integrationsData: IntegrationsObjectType = {
  MENU: [
    {
      createPath: paths.settings.locations.createSquareIntegrationByLocation,
      editPath: paths.settings.locations.editSquareIntegrationByLocation,
      title: SquareIntlMessage,
      description: {
        id: "restaurants.settings.locations.integration.square.description",
        defaultMessage:
          "Fulfilling takeout and delivery orders just got easier!",
      },
    },
  ],
  ORDER_AGGREGATION: [
    {
      createPath: paths.settings.locations.createSquareIntegrationByLocation,
      editPath: paths.settings.locations.editSquareIntegrationByLocation,
      title: SquareIntlMessage,
      description: {
        id: "restaurants.settings.locations.integration.square.description",
        defaultMessage:
          "Fulfilling takeout and delivery orders just got easier!",
      },
    },
  ],
}
