import * as Types from '../../../../../shared/graphql/generated/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetAllTablesByLocationQueryVariables = Types.Exact<{
  locationUUID: Types.Scalars['ID'];
  tableIdentifier?: Types.InputMaybe<Types.Scalars['String']>;
  after?: Types.InputMaybe<Types.Scalars['String']>;
  isActive?: Types.InputMaybe<Types.Scalars['Boolean']>;
  take?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type GetAllTablesByLocationQuery = { __typename?: 'Query', getAllTablesByLocation: { __typename?: 'PaginatedLocationTableZoneModel', hasNextPage: boolean, endCursor?: string | null, results?: Array<{ __typename?: 'LocationTableZoneModel', uuid: string, name: string, description?: string | null, capacity: number, status: Types.StatusTableEnum, smoking: boolean, qrPath: string, tableIdentifier: string, isActive: boolean, location: { __typename?: 'BasicLocationModel', uuid: string, name: string }, tableZone?: { __typename?: 'BasicZoneModel', uuid: string, name: string } | null }> | null } };


export const GetAllTablesByLocationDocument = gql`
    query getAllTablesByLocation($locationUUID: ID!, $tableIdentifier: String, $after: String, $isActive: Boolean, $take: Int) {
  getAllTablesByLocation(
    tableIdentifier: $tableIdentifier
    after: $after
    isActive: $isActive
    take: $take
    locationUUID: $locationUUID
  ) {
    results {
      uuid
      name
      description
      capacity
      status
      smoking
      qrPath
      tableIdentifier
      isActive
      location {
        uuid
        name
      }
      tableZone {
        uuid
        name
      }
    }
    hasNextPage
    endCursor
  }
}
    `;

/**
 * __useGetAllTablesByLocationQuery__
 *
 * To run a query within a React component, call `useGetAllTablesByLocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllTablesByLocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllTablesByLocationQuery({
 *   variables: {
 *      locationUUID: // value for 'locationUUID'
 *      tableIdentifier: // value for 'tableIdentifier'
 *      after: // value for 'after'
 *      isActive: // value for 'isActive'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useGetAllTablesByLocationQuery(baseOptions: Apollo.QueryHookOptions<GetAllTablesByLocationQuery, GetAllTablesByLocationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllTablesByLocationQuery, GetAllTablesByLocationQueryVariables>(GetAllTablesByLocationDocument, options);
      }
export function useGetAllTablesByLocationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllTablesByLocationQuery, GetAllTablesByLocationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllTablesByLocationQuery, GetAllTablesByLocationQueryVariables>(GetAllTablesByLocationDocument, options);
        }
export type GetAllTablesByLocationQueryHookResult = ReturnType<typeof useGetAllTablesByLocationQuery>;
export type GetAllTablesByLocationLazyQueryHookResult = ReturnType<typeof useGetAllTablesByLocationLazyQuery>;
export type GetAllTablesByLocationQueryResult = Apollo.QueryResult<GetAllTablesByLocationQuery, GetAllTablesByLocationQueryVariables>;