export type Shadows = {
  xs: string
  sm: string
  md: string
  lg: string
  xl: string
}

export const themeShadows: Shadows = {
  xs: "0px 1px 3px 0px rgba(0, 0, 0, 0.05), 0px 1px 2px 0px rgba(0, 0, 0, 0.10)",
  sm: "0px 1px 3px 0px rgba(0, 0, 0, 0.05), 0px 10px 15px -5px rgba(0, 0, 0, 0.10), 0px 7px 7px -5px rgba(0, 0, 0, 0.04)",
  md: "0px 1px 3px 0px rgba(0, 0, 0, 0.05), 0px 20px 25px -5px rgba(0, 0, 0, 0.10), 0px 10px 10px -5px rgba(0, 0, 0, 0.04)",
  lg: "0px 1px 3px 0px rgba(0, 0, 0, 0.05), 0px 28px 23px -7px rgba(0, 0, 0, 0.10), 0px 12px 12px -7px rgba(0, 0, 0, 0.04)",
  xl: "0px 1px 3px 0px rgba(0, 0, 0, 0.05), 0px 36px 28px -7px rgba(0, 0, 0, 0.10), 0px 17px 17px -7px rgba(0, 0, 0, 0.04)",
}
