import type { ICreateOrder } from "../../pages/Restaurants/CreateOrder/hookforms.interfaces"
import Container from "../Container"
import { showGraphqlErrors } from "../ErrorList"
import Select, { OptionSelect } from "../Select"
import isNumber from "lodash/isNumber"
import React, { useEffect, useState } from "react"
import { Controller, useFormContext } from "react-hook-form"

const NOON = 12
const MIDNIGHT = 0

export enum MeridiemEnum {
  AM = "AM",
  PM = "PM",
}

const meridiemList = (
  Object.keys(MeridiemEnum) as Array<keyof typeof MeridiemEnum>
).map((meridiem) => ({
  label: meridiem,
  value: meridiem,
}))

type MeridiemType = `${MeridiemEnum}`

type Intervals = {
  label: string
  value: string
}

type TimePickerSelectProps = {
  options: Array<string>
  open?: boolean
}

export const TimePickerSelect: React.FC<TimePickerSelectProps> = ({
  options,
  ...props
}) => {
  const {
    control,
    setValue,
    formState: { errors },
  } = useFormContext<ICreateOrder>()

  const [meridiemValue, setMeridiemValue] = useState<MeridiemType>("AM")
  const [morningRanges, setMorningRanges] = useState<Array<Intervals>>([])
  const [afternoonRanges, setAfternoonRanges] = useState<Array<Intervals>>([])

  const displayOptions =
    meridiemValue === "AM" ? morningRanges : afternoonRanges

  const onMeridiemChange = (value: MeridiemType) => {
    setMeridiemValue(value)
    const scheduledTime = value === "AM" ? morningRanges[0] : afternoonRanges[0]

    if (scheduledTime?.value) {
      setValue("scheduleTime", scheduledTime.value.toString())
    }
  }

  useEffect(() => {
    try {
      const morningIntervals: Array<Intervals> = []
      const afternoonIntervals: Array<Intervals> = []

      options.forEach((option) => {
        try {
          const hourValue = option?.split(":")?.[0]
          const hour = Number(hourValue)

          if (isNumber(hour) && hour < NOON && hour >= MIDNIGHT) {
            morningIntervals.push({ value: option, label: `${option}` })
          } else {
            afternoonIntervals.push({
              value: option,
              label:
                hour === NOON
                  ? `${option}`
                  : `${(hour - NOON).toString().padStart(2, "0")}:${
                      option?.split(":")?.[1]
                    }`,
            })
          }
        } catch (error) {
          showGraphqlErrors(error)
        }
      })

      setMeridiemValue(morningIntervals.length > 0 ? "AM" : "PM")
      setMorningRanges(morningIntervals)
      setAfternoonRanges(afternoonIntervals)
      const scheduledIntervals =
        morningIntervals.length > 0 ? morningIntervals : afternoonIntervals

      if (scheduledIntervals[0]?.value) {
        setValue("scheduleTime", scheduledIntervals[0].value.toString())
      }
    } catch (error) {
      showGraphqlErrors(error)
    }
  }, [options, setValue])

  return (
    <Container display="flex" gap="8px">
      <Controller
        control={control}
        name="scheduleTime"
        render={({ field }) => {
          return (
            <Select
              {...props}
              value={field.value}
              width="150px"
              hasError={!!errors.scheduleTime}
              onChange={(option) => field.onChange(option)}
              aria-label="time-select"
              defaultActiveFirstOption
              allowSearch
              hideSearchIcon
            >
              {displayOptions.map((option) => {
                return (
                  <OptionSelect
                    key={option.value}
                    label={option.label}
                    value={option.value}
                  >
                    {option.label}
                  </OptionSelect>
                )
              })}
            </Select>
          )
        }}
      />

      <Select
        aria-label="select-meridium"
        value={meridiemValue}
        options={meridiemList}
        onChange={onMeridiemChange}
        disabled={morningRanges.length === 0 || afternoonRanges.length === 0}
        hideSearchIcon
      />
    </Container>
  )
}
