import Container from "../Container"
import Icon from "../Icon"
import Text from "../Typography/Text"
import { useAutoLoaderContext } from "./AutoLoaderProvider"
import { Spin } from "antd"
import { useEffect } from "react"
import { useHistory } from "react-router-dom"
import styled from "styled-components"

export const AutoLoader = () => {
  const { status, setStatus } = useAutoLoaderContext()
  const history = useHistory()

  useEffect(() => {
    return history.listen(() => {
      setStatus("IDLE")
    })
  }, [history, setStatus])

  if (status === "LOADING") {
    return (
      <StyledDiv>
        <Spin
          indicator={
            <Icon
              remixiconClass="ri-loader-5-line"
              size={24}
              color="Secondary5"
              spinning
            />
          }
        />
      </StyledDiv>
    )
  }

  if (status === "SAVED") {
    return (
      <StyledDiv>
        <Container display="flex" alignItems="center">
          <Text color="Success5" className="title" size="m">
            Saved
          </Text>
          <Icon remixiconClass="ri-checkbox-circle-line" color="Success5" />
        </Container>
      </StyledDiv>
    )
  }

  if (status === "ERROR") {
    return (
      <StyledDiv>
        <Container display="flex" alignItems="center">
          <Text color="Danger5" className="title" size="m">
            Not Saved
          </Text>
          <Icon remixiconClass="ri-cloud-off-line" color="Danger5" />
        </Container>
      </StyledDiv>
    )
  }

  return null
}

const StyledDiv = styled.div`
  z-index: 3000;
  position: fixed;
  right: 80px;
  top: 25px;

  .title {
    margin-right: 8px;
  }
`
