export const getDefaultTipOption = (
  values: Array<string>,
  defaultValue?: string
): string | undefined => {
  if (values.length === 1) {
    return values[0]
  }

  if (defaultValue && !values.includes(defaultValue)) {
    const minValue = Math.min.apply(null, values.map(Number).filter(Boolean))

    return Number.isFinite(Number(minValue)) ? minValue.toString() : undefined
  }

  return defaultValue
}
