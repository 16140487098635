import { OrderMethodEnum } from "../../../../../../../shared/graphql/generated/types"
import { GetOrderMethodSettingQuery } from "../../GraphQL/getOrderMethodSettings.generated"
import { useUpsertOrderMethodSettingMutation } from "../../GraphQL/upsertOrderMethodSettings.generated"
import NotificationSettingsForm from "../NotificationSettingsForm"
import { OrderMethodSettingsResolver } from "../OrderMethodSettings.yup"
import TipSettingsForm from "../TipSettingsForm"
import { getFormDefaultData } from "../TipSettingsForm/utils/get-form-default-data.util"
import { getTipOptions } from "../TipSettingsForm/utils/get-tip-options.util"
import { DefaultTips } from "../config"
import { OrderMethodSettingsFormType } from "../hookforms.interfaces"
import { Button, Flex, ScrollArea, Space, Text } from "@mantine/core"
import { FormProvider, useForm } from "react-hook-form"
import { useIntl } from "react-intl"

type OrderMethodSettingsFormProps = {
  orderMethod: OrderMethodEnum
  responseData?: GetOrderMethodSettingQuery
  onClose: () => void
}

export const OrderMethodSettingsForm = (
  props: OrderMethodSettingsFormProps
) => {
  const { orderMethod, responseData, onClose } = props

  const intl = useIntl()

  const [upsertOrderMethodSetting, { loading: upsertLoading }] =
    useUpsertOrderMethodSettingMutation()

  const formMethods = useForm<OrderMethodSettingsFormType>({
    defaultValues: getFormDefaultData(orderMethod, responseData),
    resolver: OrderMethodSettingsResolver,
  })
  const {
    handleSubmit,
    formState: { isDirty },
  } = formMethods

  const handleSave = (data: OrderMethodSettingsFormType) => {
    const {
      tipSettings: {
        //TODO: Delete the hardcoded values and use the variable values when request tip is added
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        requestTipAfter,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        requestTipBefore,
        type: tipType,
        inactiveType,
      },
      orderReady,
      orderVerification,
      tipsData,
    } = data

    upsertOrderMethodSetting({
      variables: {
        data: {
          tipSettings: {
            amounts:
              tipsData[tipType].options
                ?.filter((option) => option.selected)
                .map((option) => Number(option.value)) || [],
            defaultAmount: Number(tipsData[tipType].default),
            //TODO: Delete the hardcoded values and use the variable values when request tip is added
            requestTipAfter: false,
            requestTipBefore: true,
            // requestTipAfter: requestTipAfter,
            // requestTipBefore: requestTipBefore,
            valueType: tipType,
          },
          notificationSettings: {
            sendOrderReadyNotification: orderReady.checked,
            orderReadyAdditionalText: orderReady.additionalText || null,
            sendOrderVerificationNotification: orderVerification.checked,
            orderVerificationAdditionalText:
              orderVerification.additionalText || null,
          },
          orderMethod: orderMethod,
        },
      },
      onCompleted: () => {
        onClose()
        if (inactiveType)
          tipsData[inactiveType] = {
            options: getTipOptions(inactiveType),
            default: DefaultTips[inactiveType][1].toString(),
          }
      },
      onError: (error) => console.error(error),
    })
  }

  return (
    <FormProvider {...formMethods}>
      <ScrollArea.Autosize
        type="scroll"
        mah="calc(100dvh - (5dvh * 2) - 148px)"
      >
        <TipSettingsForm />
        <Space h={24} />
        <NotificationSettingsForm />
        <Space h={24} />
      </ScrollArea.Autosize>
      <Flex justify="end" mt={8}>
        <Button
          color="primary.6"
          c="white"
          onClick={handleSubmit(handleSave)}
          loading={upsertLoading}
          disabled={!isDirty}
          w={75}
        >
          {!upsertLoading && (
            <Text weight={400}>
              {intl.formatMessage({
                id: "generic.label.save.button",
                defaultMessage: "Save",
              })}
            </Text>
          )}
        </Button>
      </Flex>
    </FormProvider>
  )
}
