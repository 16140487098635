import AddIcon from "../../../../../ui/AddIcon"
import Container from "../../../../../ui/Container"
import CreateMember from "../CreateMember"
import { useState } from "react"

export const AddMemberButton = () => {
  const [displayModal, setDislayModal] = useState<boolean>(false)

  const onClose = () => {
    setDislayModal(false)
  }

  const showModal = () => {
    setDislayModal(true)
  }

  return (
    <Container display="flex" alignItems="center">
      <AddIcon onClick={showModal} />
      <CreateMember visible={displayModal} onClose={onClose} closable={false} />
    </Container>
  )
}
