import type { DeliveryPrice } from "../../../../../../shared/graphql/generated/types"
import ConfirmationModal from "../../../../../../ui/ConfirmationModal"
import Container from "../../../../../../ui/Container"
import { showGraphqlErrors } from "../../../../../../ui/ErrorList"
import DropDownAction from "../../../../../../ui/Header/DropdownAction"
import Icon from "../../../../../../ui/Icon"
import Text from "../../../../../../ui/Typography/Text"
import notification from "../../../../../../ui/notification"
import type { ILocationDeliveryParams } from "../../hookforms.interfaces"
import { useDeleteDeliveryPriceMutation } from "../GraphQL/deleteDeliveryLocation.generated"
import EditDeliveryZoneModal from "../ModalDeliveryZones/EditDeliveryZoneModal"
import type { IDeliveryZonesTableModel } from "../TableDeliveryZones/deliveryZonesTable.interfaces"
import React, { useState } from "react"
import { useIntl } from "react-intl"
import { useParams } from "react-router-dom"
import styled from "styled-components"

type DropdownDeliveryZoneProps = {
  record: IDeliveryZonesTableModel
}

const DropdownDeliveryZone: React.FC<DropdownDeliveryZoneProps> = ({
  record,
}) => {
  const intl = useIntl()
  const { locationUUID } = useParams<ILocationDeliveryParams>()

  const [showDeleteConfirmModal, setShowDeleteConfirmModal] =
    useState<boolean>(false)
  const [displayModalEditDeliveryZone, setDisplayModalEditDeliveryZone] =
    useState<boolean>(false)

  const onShowDeleteConfirmationModal = () => {
    setShowDeleteConfirmModal(true)
  }

  const onCloseDeleteConfirmationModal = () => {
    setShowDeleteConfirmModal(false)
  }

  const onShowModalEditDeliveryZone = () => {
    setDisplayModalEditDeliveryZone(true)
  }

  const onCloseEditDeliveryZone = () => {
    setDisplayModalEditDeliveryZone(false)
  }

  const [deleteDeliveryPrice, { loading: isDeliveryZoneDeleting }] =
    useDeleteDeliveryPriceMutation({
      update: (cache, result) => {
        cache.modify({
          fields: {
            getDeliveryPricesByLocation: (data: DeliveryPrice[]) => {
              return data.filter(
                (current) =>
                  current.uuid !== result.data?.deleteDeliveryPrice.uuid
              )
            },
          },
        })
      },
      onCompleted: () => {
        notification({
          description: intl.formatMessage({
            id: "settings.locations.delivery.zones.dropdown.delete.success.message",
            defaultMessage: "Your delivery zone was deleted",
          }),
          type: "success",
        })

        onCloseDeleteConfirmationModal()
      },
    })

  const onConfirmDelete = async () => {
    if (!record.uuid) {
      return
    }

    try {
      await deleteDeliveryPrice({
        variables: {
          uuid: record.uuid,
          locationUUID,
        },
      })
    } catch (error) {
      showGraphqlErrors(error)
    }
  }

  return (
    <Container display="flex" justifyContent="flex-end">
      <DropDownAction
        placement="bottomRight"
        overlay={
          <ul className="ant-menu ant-menu-root ant-menu-vertical ant-menu-light">
            <StyledMenuItem
              className="ant-menu-item"
              onClick={onShowModalEditDeliveryZone}
            >
              <Icon remixiconClass="ri-edit-line" color="Neutral7" />
              <Text size="s">
                {intl.formatMessage({
                  id: "settings.locations.delivery.zones.dropdown.item.edit.zone",
                  defaultMessage: "Edit zone",
                })}
              </Text>
            </StyledMenuItem>
            <StyledMenuItem
              className="ant-menu-item"
              onClick={onShowDeleteConfirmationModal}
            >
              <Icon remixiconClass="ri-delete-bin-5-line" color="Neutral7" />
              <Text size="s">
                {intl.formatMessage({
                  id: "settings.locations.delivery.zones.dropdown.item.delete.zone",
                  defaultMessage: "Delete zone",
                })}
              </Text>
            </StyledMenuItem>
          </ul>
        }
      >
        <StyledIconPoints>
          <Icon
            remixiconClass="ri-more-fill"
            cursor="pointer"
            color="Neutral6"
            size={16}
          />
        </StyledIconPoints>
      </DropDownAction>

      {displayModalEditDeliveryZone && (
        <EditDeliveryZoneModal
          data={record}
          visible={displayModalEditDeliveryZone}
          onClose={onCloseEditDeliveryZone}
        />
      )}

      {showDeleteConfirmModal && (
        <ConfirmationModal
          visible={showDeleteConfirmModal}
          title={intl.formatMessage({
            id: "settings.locations.delivery.zones.dropdown.confirmation.modal.title",
            defaultMessage: "Are you sure delete this zone?",
          })}
          subtitle={intl.formatMessage({
            id: "settings.locations.delivery.zones.dropdown.confirmation.modal.subtitle",
            defaultMessage:
              "This action can affect your delivery coverage area",
          })}
          onConfirm={onConfirmDelete}
          onCancel={onCloseDeleteConfirmationModal}
          confirmButtonText={intl.formatMessage({
            id: "settings.locations.delivery.zones.dropdown.confirmation.modal.button.yes",
            defaultMessage: "Yes",
          })}
          cancelButtonText={intl.formatMessage({
            id: "settings.locations.delivery.zones.dropdown.confirmation.modal.button.no",
            defaultMessage: "No",
          })}
          loading={isDeliveryZoneDeleting}
        />
      )}
    </Container>
  )
}

export default DropdownDeliveryZone

const StyledIconPoints = styled.div`
  width: 24px;
  height: 32px;
  margin-left: 6px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

const StyledMenuItem = styled.li`
  display: flex;
  gap: 8px;
`
