import yup from "../../../../../shared/yup"
import { TypeOfService } from "../shared/hookforms.intefaces"
import { AnyObject } from "yup/lib/types"

export const typeOfServiceTest = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any | undefined,
  context: yup.TestContext<AnyObject>
) => {
  const { pickup, delivery, foodTruckExperience, insideVenue } =
    context.parent as TypeOfService

  return pickup || delivery || foodTruckExperience || insideVenue
}
