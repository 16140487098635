import type { GetAllLocationsByUserQuery } from "../../../../../../GraphQL/Queries/getAllLocationsByUser.generated"
import { useGetAllLocationsByUserQuery } from "../../../../../../GraphQL/Queries/getAllLocationsByUser.generated"
import { useGeneralContext } from "../../../../../../shared/contexts/StoreProvider"
import type { LocationModel } from "../../../../../../shared/graphql/generated/types"
import { updateGetAllLocationsByUser } from "../../../../../../shared/graphql/updateQuery/updateGetAllLocationsByUser"
import Checkbox from "../../../../../../ui/Checkbox"
import { showGraphqlErrors } from "../../../../../../ui/ErrorList"
import PageTitle from "../../../../../../ui/PageTitle"
import Select, { OptionSelect } from "../../../../../../ui/Select"
import { onPopupScroll } from "../../../../../../ui/Select/helpers/onPopupScroll"
import Spacer from "../../../../../../ui/Spacer"
import { useCreateTaxContext } from "../../CreateTax/CreateTax"
import { LocationListSkeleton } from "./LocationList.skeleton"
import debounce from "lodash/debounce"
import get from "lodash/get"
import { useCallback, useEffect, useMemo, useState } from "react"
import { Controller } from "react-hook-form"
import { useIntl } from "react-intl"

export const TaxLocationsForm = () => {
  const intl = useIntl()

  const [locationName, setLocationName] = useState<string | undefined>()

  const {
    control,
    setValue,
    watch,
    formState: { errors },
  } = useCreateTaxContext()

  const locationsWatcher = watch("locations")

  const {
    state: {
      currentRestaurant: { uuid: restaurantUUID },
      auth: {
        admin: { uuid: userUUID },
      },
    },
  } = useGeneralContext()

  const {
    data: locationsData,
    loading,
    refetch,
    fetchMore,
  } = useGetAllLocationsByUserQuery({
    variables: {
      restaurantUUID,
      userUUID,
      isActive: true,
    },
  })

  const locationList: LocationModel[] = get(
    locationsData,
    "getAllLocationsByUser.results",
    []
  )
  const hasNextPage = get(
    locationsData,
    "getAllLocationsByUser.hasNextPage",
    false
  )
  const endCursor = get(locationsData, "getAllLocationsByUser.endCursor", null)
  const canFetchMore = !loading && hasNextPage && endCursor

  const onApplyToAllLocations = (checked: boolean) => {
    const locations = checked ? locationList.map(({ uuid }) => uuid) : []
    setValue("locations", locations, { shouldValidate: true })
  }

  const searchLocation = useCallback(
    (name: string) => {
      try {
        setLocationName(name)

        refetch({
          restaurantUUID,
          userUUID,
          name: name === "" ? undefined : name,
          take: 20,
        })
      } catch (error) {
        showGraphqlErrors(error)
      }
    },
    [restaurantUUID, userUUID, refetch]
  )

  const searchLocationsDebounce = useMemo(
    () => debounce(searchLocation, 1000),
    [searchLocation]
  )

  const onSearchTermChange = (value: string) => {
    if (locationName !== value) {
      searchLocationsDebounce(value)
    }
  }

  const fetchMoreLocationsByUser = useCallback(async () => {
    try {
      await fetchMore({
        variables: {
          restaurantUUID,
          userUUID,
          isActive: true,
          after: endCursor,
        },
        updateQuery: (prev: GetAllLocationsByUserQuery, { fetchMoreResult }) =>
          updateGetAllLocationsByUser(prev, fetchMoreResult),
      })
    } catch (error) {
      showGraphqlErrors(error)
    }
  }, [endCursor, fetchMore, restaurantUUID, userUUID])

  useEffect(() => {
    if (
      locationList?.length === locationsWatcher?.length &&
      locationList?.length > 0
    ) {
      setValue("applyAllLocations", true)
    }
  }, [locationList?.length, locationsWatcher?.length, setValue])

  return (
    <div>
      <PageTitle
        title={intl.formatMessage({
          id: "restaurants.taxes.form.section.2.title",
          defaultMessage: "Location",
        })}
        description={intl.formatMessage({
          id: "restaurants.taxes.form.section.2.description",
          defaultMessage:
            "Select the locations that you want to link to this tax",
        })}
      />
      {loading ? (
        <LocationListSkeleton />
      ) : (
        <>
          <Controller
            control={control}
            name="applyFutureLocations"
            render={({ field: futureLocationsField }) => (
              <Checkbox
                {...futureLocationsField}
                content={intl.formatMessage({
                  id: "restaurants.taxes.form.section.2.checkbox.future.location.label",
                  defaultMessage: "All Future Locations",
                })}
                classId="all-future-locations-checkbox"
                checked={futureLocationsField.value}
              />
            )}
          />

          <Spacer size={4} />

          <Controller
            control={control}
            name="applyAllLocations"
            render={({ field: applyAllLocationsField }) => (
              <Checkbox
                {...applyAllLocationsField}
                content={intl.formatMessage({
                  id: "restaurants.taxes.form.section.2.checkbox.all.locations.label",
                  defaultMessage: "All Locations",
                })}
                classId="all-locations-checkbox"
                checked={applyAllLocationsField.value}
                onChange={({ target: { checked } }) => {
                  applyAllLocationsField.onChange(checked)
                  onApplyToAllLocations(checked)
                }}
              />
            )}
          />

          <Spacer size={8} />

          <Controller
            control={control}
            name="locations"
            render={({ field: locationsField }) => (
              <Select
                mode="multiple"
                loading={loading}
                value={locationsField.value}
                onChange={locationsField.onChange}
                onSearch={onSearchTermChange}
                hasError={!!errors?.locations}
                helperText={get(errors, "locations.message")}
                listHeight={200}
                onPopupScroll={(e) =>
                  onPopupScroll(e, canFetchMore, fetchMoreLocationsByUser)
                }
                allowSearch
              >
                {locationList.map((location) => {
                  return (
                    <OptionSelect
                      key={location.uuid}
                      label={location.name}
                      value={location.uuid}
                      aria-extra={location}
                    >
                      {location.name}
                    </OptionSelect>
                  )
                })}
              </Select>
            )}
          />
        </>
      )}
    </div>
  )
}
