import * as Types from '../../../../../shared/graphql/generated/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetRestaurantSubscriptionQueryVariables = Types.Exact<{
  uuid: Types.Scalars['ID'];
}>;


export type GetRestaurantSubscriptionQuery = { __typename?: 'Query', getRestaurantSubscription: { __typename?: 'StripeSubscription', gracePeriodEndDate?: any | null, numberOfActiveLocations: number, numberOfLocations: number, status: Types.SubscriptionStatusEnum, tier: Types.SubscriptionTierEnum, trialEndDate: any, uuid: string } };


export const GetRestaurantSubscriptionDocument = gql`
    query getRestaurantSubscription($uuid: ID!) {
  getRestaurantSubscription(uuid: $uuid) {
    gracePeriodEndDate
    numberOfActiveLocations
    numberOfLocations
    status
    tier
    trialEndDate
    uuid
  }
}
    `;

/**
 * __useGetRestaurantSubscriptionQuery__
 *
 * To run a query within a React component, call `useGetRestaurantSubscriptionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRestaurantSubscriptionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRestaurantSubscriptionQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useGetRestaurantSubscriptionQuery(baseOptions: Apollo.QueryHookOptions<GetRestaurantSubscriptionQuery, GetRestaurantSubscriptionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRestaurantSubscriptionQuery, GetRestaurantSubscriptionQueryVariables>(GetRestaurantSubscriptionDocument, options);
      }
export function useGetRestaurantSubscriptionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRestaurantSubscriptionQuery, GetRestaurantSubscriptionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRestaurantSubscriptionQuery, GetRestaurantSubscriptionQueryVariables>(GetRestaurantSubscriptionDocument, options);
        }
export type GetRestaurantSubscriptionQueryHookResult = ReturnType<typeof useGetRestaurantSubscriptionQuery>;
export type GetRestaurantSubscriptionLazyQueryHookResult = ReturnType<typeof useGetRestaurantSubscriptionLazyQuery>;
export type GetRestaurantSubscriptionQueryResult = Apollo.QueryResult<GetRestaurantSubscriptionQuery, GetRestaurantSubscriptionQueryVariables>;