import { ImageCoverFieldSkeleton } from "../../../../ui/CoverPreview"
import { LabelFieldSkeleton } from "../../../../ui/InputLabel"
import { InputFieldSkeleton } from "../../../../ui/Inputs"
import DetailPage from "../../../../ui/Layouts/MainLayout/DetailPage"
import Spacer from "../../../../ui/Spacer"
import type { SkeletonProps } from "antd"
import React from "react"

export const LoyaltyProgramSkeleton: React.FC<SkeletonProps> = ({
  children,
  loading = true,
}) => {
  return loading ? (
    <DetailPage role="loyality-program-skeleton">
      <LabelFieldSkeleton />
      <InputFieldSkeleton />
      <Spacer size={64} />

      <LabelFieldSkeleton />
      <ImageCoverFieldSkeleton />
      <Spacer size={64} />

      <LabelFieldSkeleton />
      <InputFieldSkeleton />
      <Spacer size={16} />
      <InputFieldSkeleton />

      <Spacer size={64} />

      <LabelFieldSkeleton />
      <InputFieldSkeleton />
      <Spacer size={64} />

      <LabelFieldSkeleton />
    </DetailPage>
  ) : (
    <>{children}</>
  )
}
