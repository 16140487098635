import {
  ContentTypeEnum,
  FileExtensionEnum,
} from "../../graphql/generated/types"

export type ImageTypes =
  | "image/jpg"
  | "image/png"
  | "image/jpeg"
  | "image/webp"
  | "image/avif"

export enum SupportedImages {
  JPG = "image/jpg",
  PNG = "image/png",
  JPEG = "image/jpeg",
  WEBP = "image/webp",
  AVIF = "image/avif",
}

const getImageMetadata = (
  extension?: string
): { ext: FileExtensionEnum; contentType: ContentTypeEnum } => {
  let ext: FileExtensionEnum
  let contentType: ContentTypeEnum

  switch (extension) {
    case SupportedImages.JPEG: {
      ext = FileExtensionEnum.JPEG
      contentType = ContentTypeEnum.JPEG
      break
    }
    case SupportedImages.PNG: {
      ext = FileExtensionEnum.PNG
      contentType = ContentTypeEnum.PNG
      break
    }
    case SupportedImages.AVIF: {
      ext = FileExtensionEnum.AVIF
      contentType = ContentTypeEnum.AVIF
      break
    }
    case SupportedImages.WEBP: {
      ext = FileExtensionEnum.WEBP
      contentType = ContentTypeEnum.WEBP
      break
    }
    default: {
      ext = FileExtensionEnum.JPG
      contentType = ContentTypeEnum.JPG
      break
    }
  }

  return { ext, contentType }
}

export default getImageMetadata
