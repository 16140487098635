import Text from "../../../ui/Typography/Text"
import type { IStyledRadioGroupProps } from "./RadioGroupButton.styled"
import { StyledRadioGroup } from "./RadioGroupButton.styled"
import { Radio } from "antd"
import type { RefObject } from "react"
import React from "react"

interface IRadioGroupButtonProps extends IStyledRadioGroupProps {
  items: {
    value: string
    label: string
    callback?: () => void
  }[]
}

export const RadioGroupButton = React.forwardRef(
  (
    radioGroupButtonProps: IRadioGroupButtonProps,
    ref:
      | ((instance: HTMLParagraphElement | null) => void)
      | RefObject<HTMLParagraphElement>
      | null
      | undefined
  ) => {
    const { items, ...props } = radioGroupButtonProps

    return (
      <StyledRadioGroup
        className="radio-group-button"
        defaultValue={items[0].value}
        ref={ref}
        buttonStyle="solid"
        {...props}
      >
        {items.map((item, index) => (
          <Radio.Button key={index} value={item.value} onClick={item.callback}>
            <Text size="m" textAlign="center">
              {item.label}
            </Text>
          </Radio.Button>
        ))}
      </StyledRadioGroup>
    )
  }
)
