import { useSortable } from "@dnd-kit/sortable"
import { CSS } from "@dnd-kit/utilities"
import React from "react"
import styled, { css } from "styled-components"

type SortableItemProps = {
  id: string
}

export const SortableItem: React.FC<SortableItemProps> = ({ id, children }) => {
  const { attributes, setNodeRef, transform, transition } = useSortable({ id })

  return (
    <StyledSortableItem
      {...attributes}
      ref={setNodeRef}
      transition={transition}
      transform={CSS.Transform.toString(transform)}
      role="row"
    >
      {children}
    </StyledSortableItem>
  )
}

type StyledSortableItemProps = {
  transform?: string
  transition?: string
  role?: string
}

const StyledSortableItem = styled.ul<StyledSortableItemProps>`
  display: flex;
  list-style: none;
  justify-content: "flex-start";
  min-width: 100%;
  padding-bottom: 4px;
  padding-top: 4px;
  margin: 0;
  padding-left: 0;
  // overflow-y: visible;
  // overflow-x: scroll;

  :hover {
    .ri-close-line:not(.first-group-child) {
      ${({ theme }) =>
        css`
          color: ${theme.colors["Primary3"]};
        `}
    }

    .draggable {
      .ri-menu-fill {
        ${({ theme }) =>
          css`
            color: ${theme.colors["Primary3"]};
          `}
      }
    }

    .first-group-child {
      cursor: auto;
    }
  }

  ${({ transform }) =>
    transform &&
    css`
      transform: ${transform};
    `}

  ${({ transition }) =>
    transition &&
    css`
      transition: ${transition};
    `}
`
