import type { ScheduleType } from "../../../../../../shared/types/schedule.types"
import {
  HH_mm,
  hh_mm,
  isoStringHoursFormat,
} from "../../../../../../shared/utils/constant/dateFormats"
import moment from "moment-timezone"

export const getTimeFormattedHH_mm = (date: ScheduleType | undefined) => {
  if (!date || !date?.time) return undefined

  return moment(`${date.time} ${date.meridium}`, isoStringHoursFormat).format(
    HH_mm
  )
}

export const getTimeFormattedhh_mm = (time: string | undefined) => {
  if (!time) return undefined

  return moment(time, HH_mm).format(hh_mm)
}

export const formattedTime = (time: string | undefined) => {
  if (!time) return undefined

  const [hour, minute] = time.split(":")

  let formattedHour = Number.parseInt(hour, 10)

  if (formattedHour >= 12) {
    if (formattedHour > 12) {
      formattedHour -= 12
    }
  } else if (formattedHour === 0) {
    formattedHour = 12
  }

  return `${String(formattedHour).padStart(2, "0")}:${minute.padStart(2, "0")}`
}
