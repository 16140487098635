import { NotificationPreferences } from "../../forms/NotificationSettingsForm/notification.title"
import {
  Checkbox,
  Group,
  Stack,
  Text,
  TextInput,
  Tooltip,
  useMantineTheme,
} from "@mantine/core"
import { IconInfoCircle } from "@tabler/icons-react"
import { useIntl } from "react-intl"

type NotificationPreferenceProps = {
  label: string
  description: string
  placeholder: string
  isChecked: boolean
  additionalText: string
  onCheckboxChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  onAdditionalTextChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}

export const NotificationPreference = (props: NotificationPreferenceProps) => {
  const {
    label,
    description,
    placeholder,
    isChecked,
    additionalText,
    onCheckboxChange,
    onAdditionalTextChange,
  } = props
  const intl = useIntl()
  const { colors } = useMantineTheme()

  return (
    <Stack spacing={16}>
      <Stack spacing={4}>
        <Group spacing={4}>
          <Checkbox
            label={label}
            checked={isChecked}
            onChange={onCheckboxChange}
          />
          {!isChecked && (
            <Tooltip
              label={
                <Text size="xs" color="white">
                  {intl.formatMessage(NotificationPreferences.tooltip)}
                </Text>
              }
              bg="gray"
              position="top"
              withArrow
            >
              <IconInfoCircle size={16} color={colors.gray[7]} />
            </Tooltip>
          )}
        </Group>
        <Text size="xs" color="gray.6" pl={28}>
          {description}
        </Text>
      </Stack>

      <TextInput
        ml={28}
        value={additionalText}
        onChange={onAdditionalTextChange}
        label={intl.formatMessage(NotificationPreferences.inputLabel)}
        placeholder={placeholder}
      />
    </Stack>
  )
}
