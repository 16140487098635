import { isoStringDateFormat } from "../constant/dateFormats"
import moment from "moment-timezone"

const formatIsoStringDate = (
  date: string | undefined | null,
  formatStyle = isoStringDateFormat
) => {
  return date ? moment(date).format(formatStyle) : null
}

export default formatIsoStringDate
