import ErrorPage from "../../pages/ErrorPage"
import * as Sentry from "@sentry/react"
import { Component, ErrorInfo } from "react"

type ErrorBoundaryState = {
  hasError: boolean
}

class CrashError extends Error {
  constructor(message: string) {
    super(message)
    this.name = "CrashError"
  }
}

export class ErrorBoundary extends Component<unknown, ErrorBoundaryState> {
  constructor(props: unknown) {
    super(props)

    this.resetError = this.resetError.bind(this)
  }

  state: ErrorBoundaryState = {
    hasError: false,
  }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    const { componentStack } = errorInfo

    Sentry.withScope((scope) => {
      scope.setExtras({ componentStack })
      scope.setTags({ crash: true })
      scope.setLevel("fatal")
      Sentry.captureException(new CrashError(error.message))
    })
  }

  private resetError() {
    this.setState({ hasError: false })
  }

  render() {
    if (this.state.hasError) {
      return <ErrorPage onBackHome={this.resetError} />
    }

    return this.props.children
  }
}
