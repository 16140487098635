import * as Types from '../../../../../shared/graphql/generated/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateOrUpdateLoyaltyProgramMutationVariables = Types.Exact<{
  data: Types.CreateOrUpdateLoyaltyProgramInput;
}>;


export type CreateOrUpdateLoyaltyProgramMutation = { __typename?: 'Mutation', createOrUpdateLoyaltyProgram: { __typename?: 'LoyaltyProgramWithRestaurantModel', uuid: string, isActive: boolean, conversionRate: number, increment: number } };


export const CreateOrUpdateLoyaltyProgramDocument = gql`
    mutation createOrUpdateLoyaltyProgram($data: CreateOrUpdateLoyaltyProgramInput!) {
  createOrUpdateLoyaltyProgram(data: $data) {
    uuid
    isActive
    conversionRate
    increment
  }
}
    `;
export type CreateOrUpdateLoyaltyProgramMutationFn = Apollo.MutationFunction<CreateOrUpdateLoyaltyProgramMutation, CreateOrUpdateLoyaltyProgramMutationVariables>;

/**
 * __useCreateOrUpdateLoyaltyProgramMutation__
 *
 * To run a mutation, you first call `useCreateOrUpdateLoyaltyProgramMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrUpdateLoyaltyProgramMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrUpdateLoyaltyProgramMutation, { data, loading, error }] = useCreateOrUpdateLoyaltyProgramMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateOrUpdateLoyaltyProgramMutation(baseOptions?: Apollo.MutationHookOptions<CreateOrUpdateLoyaltyProgramMutation, CreateOrUpdateLoyaltyProgramMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOrUpdateLoyaltyProgramMutation, CreateOrUpdateLoyaltyProgramMutationVariables>(CreateOrUpdateLoyaltyProgramDocument, options);
      }
export type CreateOrUpdateLoyaltyProgramMutationHookResult = ReturnType<typeof useCreateOrUpdateLoyaltyProgramMutation>;
export type CreateOrUpdateLoyaltyProgramMutationResult = Apollo.MutationResult<CreateOrUpdateLoyaltyProgramMutation>;
export type CreateOrUpdateLoyaltyProgramMutationOptions = Apollo.BaseMutationOptions<CreateOrUpdateLoyaltyProgramMutation, CreateOrUpdateLoyaltyProgramMutationVariables>;