import { EmployeeRoleNameEnum } from "../../../../../shared/graphql/generated/types"
import { EmployeeRole } from "../types/graphql.enums"

type EmployeeRoleType = {
  value: EmployeeRoleNameEnum
  label: string
}

export const employeesRolesData: EmployeeRoleType[] = [
  {
    value: EmployeeRoleNameEnum.BARISTA,
    label: EmployeeRole[EmployeeRoleNameEnum.BARISTA],
  },
  {
    value: EmployeeRoleNameEnum.BUSSER,
    label: EmployeeRole[EmployeeRoleNameEnum.BUSSER],
  },
  {
    value: EmployeeRoleNameEnum.CASH,
    label: EmployeeRole[EmployeeRoleNameEnum.CASH],
  },
  {
    value: EmployeeRoleNameEnum.DISWASHER,
    label: EmployeeRole[EmployeeRoleNameEnum.DISWASHER],
  },
  {
    value: EmployeeRoleNameEnum.KITCHEN_MANAGER,
    label: EmployeeRole[EmployeeRoleNameEnum.KITCHEN_MANAGER],
  },
  {
    value: EmployeeRoleNameEnum.LINE_COOK,
    label: EmployeeRole[EmployeeRoleNameEnum.LINE_COOK],
  },
  {
    value: EmployeeRoleNameEnum.RESTAURANT_EMPLOYEE,
    label: EmployeeRole[EmployeeRoleNameEnum.RESTAURANT_EMPLOYEE],
  },
  {
    value: EmployeeRoleNameEnum.RUNNER,
    label: EmployeeRole[EmployeeRoleNameEnum.RUNNER],
  },
  {
    value: EmployeeRoleNameEnum.SERVER,
    label: EmployeeRole[EmployeeRoleNameEnum.SERVER],
  },
]
