import * as Types from '../../../../../../shared/graphql/generated/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetAllTablesZoneByLocationQueryVariables = Types.Exact<{
  locationUUID: Types.Scalars['ID'];
  name?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type GetAllTablesZoneByLocationQuery = { __typename?: 'Query', getAllTablesZoneByLocation: Array<{ __typename?: 'TableZoneDetailModel', uuid: string, name: string, isActive: boolean, location: { __typename?: 'LocationBasicModel', uuid: string, name: string } }> };


export const GetAllTablesZoneByLocationDocument = gql`
    query getAllTablesZoneByLocation($locationUUID: ID!, $name: String) {
  getAllTablesZoneByLocation(locationUUID: $locationUUID, name: $name) {
    uuid
    name
    isActive
    location {
      uuid
      name
    }
  }
}
    `;

/**
 * __useGetAllTablesZoneByLocationQuery__
 *
 * To run a query within a React component, call `useGetAllTablesZoneByLocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllTablesZoneByLocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllTablesZoneByLocationQuery({
 *   variables: {
 *      locationUUID: // value for 'locationUUID'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useGetAllTablesZoneByLocationQuery(baseOptions: Apollo.QueryHookOptions<GetAllTablesZoneByLocationQuery, GetAllTablesZoneByLocationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllTablesZoneByLocationQuery, GetAllTablesZoneByLocationQueryVariables>(GetAllTablesZoneByLocationDocument, options);
      }
export function useGetAllTablesZoneByLocationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllTablesZoneByLocationQuery, GetAllTablesZoneByLocationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllTablesZoneByLocationQuery, GetAllTablesZoneByLocationQueryVariables>(GetAllTablesZoneByLocationDocument, options);
        }
export type GetAllTablesZoneByLocationQueryHookResult = ReturnType<typeof useGetAllTablesZoneByLocationQuery>;
export type GetAllTablesZoneByLocationLazyQueryHookResult = ReturnType<typeof useGetAllTablesZoneByLocationLazyQuery>;
export type GetAllTablesZoneByLocationQueryResult = Apollo.QueryResult<GetAllTablesZoneByLocationQuery, GetAllTablesZoneByLocationQueryVariables>;