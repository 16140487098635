import Button from "../../../../../../ui/Button"
import ConfirmationModal from "../../../../../../ui/ConfirmationModal"
import Container from "../../../../../../ui/Container"
import { showGraphqlErrors } from "../../../../../../ui/ErrorList/ErrorList"
import Icon from "../../../../../../ui/Icon"
import SimpleInput from "../../../../../../ui/Inputs/SimpleInput"
import Text from "../../../../../../ui/Typography/Text"
import notification from "../../../../../../ui/notification"
import { GetAllTablesZoneByLocationDocument } from "../../CreateTable/GraphQL/getAllTablesZoneByLocation.generated"
import { GetAllTablesByLocationDocument } from "../../GraphQL/getAllTablesByLocation.generated"
import { useDisableTableZoneMutation } from "../GraphQL/disableTableZone.generated"
import { useUpdateTableZoneMutation } from "../GraphQL/updateTableZone.generated"
import type { ITablesPageParams } from "../hookforms.interfaces"
import { LocationTableZoneResolver } from "./LocationTableZone.yup"
import type { Input as AntdInput } from "antd"
import { Menu } from "antd"
import React, { useRef, useState } from "react"
import { Controller, FormProvider, useForm } from "react-hook-form"
import { useIntl } from "react-intl"
import { useParams } from "react-router-dom"
import styled from "styled-components"

type SelectZoneActionsMenuProps = {
  zoneItem: { name: string; uuid: string }
  deleteCallback: (zoneItem: string) => void
}

export const SelectZoneActionsMenu: React.FC<SelectZoneActionsMenuProps> = ({
  deleteCallback,
  zoneItem,
}) => {
  const intl = useIntl()
  const editZoneInputRef = useRef<AntdInput>()
  const [showConfirmDelete, setShowConfirmDelete] = useState<boolean>()

  const { locationUUID } = useParams<ITablesPageParams>()

  const methods = useForm<{ name: string; uuid: string }>({
    mode: "all",
    resolver: LocationTableZoneResolver,
    defaultValues: zoneItem,
  })

  const [disableZone, { loading: disabling }] = useDisableTableZoneMutation({
    refetchQueries: [GetAllTablesZoneByLocationDocument],
  })

  const [updateZone, { loading }] = useUpdateTableZoneMutation({
    refetchQueries: [
      GetAllTablesZoneByLocationDocument,
      GetAllTablesByLocationDocument,
    ],
  })

  const onDisableZone = async () => {
    try {
      await disableZone({
        variables: {
          data: {
            uuid: zoneItem.uuid,
            locationUUID: locationUUID ?? "",
          },
        },
      })

      setShowConfirmDelete(false)

      notification({
        description: intl.formatMessage({
          id: "restaurants.location.table.form.zone.deleted.message",
          defaultMessage: "Table Zone Deleted",
        }),
        type: "success",
      })
    } catch (error) {
      showGraphqlErrors(error)
    } finally {
      deleteCallback(zoneItem.uuid)
    }
  }

  const onUpdateZone = async () => {
    try {
      await updateZone({
        variables: {
          data: {
            uuid: zoneItem.uuid,
            name: methods.getValues("name"),
            locationUUID: locationUUID ?? "",
          },
        },
      })

      notification({
        type: "success",
        description: intl.formatMessage({
          id: "restaurants.location.table.form.zone.updated.message",
          defaultMessage: "Table Zone Updated",
        }),
      })
    } catch (error) {
      showGraphqlErrors(error)
    }
  }

  const onInputFocus = () => {
    editZoneInputRef.current?.focus()
  }

  const onInputSelect = () => {
    editZoneInputRef.current?.select()
  }

  const onDisableZoneConfirm = () => {
    setShowConfirmDelete(true)
  }

  const onCancelDelete = () => {
    setShowConfirmDelete(false)
  }

  const onEditZoneClick = (event: {
    domEvent: { stopPropagation: () => void }
  }) => {
    event.domEvent.stopPropagation()
  }

  return (
    <>
      <Menu role="zone-actions-menu">
        <Menu.Item key="edit-zone" onClick={onEditZoneClick}>
          <Container onClick={onInputFocus} onDoubleClick={onInputSelect}>
            <FormProvider {...methods}>
              <StyledContInputEdit>
                <Controller
                  control={methods.control}
                  name="name"
                  render={({ field }) => (
                    <SimpleInput
                      {...field}
                      label={intl.formatMessage({
                        id: "restaurants.location.table.form.zone.name.label",
                        defaultMessage: "Zone Name",
                      })}
                      ref={editZoneInputRef}
                      aria-label="edit-zone-input"
                      className="edit-zone-input"
                      wrapperProps={{ style: InputWrapperStyle }}
                      hasError={!!methods.formState.errors.name}
                      onPressEnter={(field.onChange, onUpdateZone)}
                      autoComplete="off"
                      loading={loading}
                      capitalizeFirstLetter={false}
                      autoFocus
                    />
                  )}
                />
                <Button type="submit" title="Ok" onClick={onUpdateZone} />
              </StyledContInputEdit>
            </FormProvider>
          </Container>
        </Menu.Item>
        <Menu.Item
          key="delete-zone"
          icon={<Icon remixiconClass="ri-delete-bin-line" size={18} />}
          onClick={onDisableZoneConfirm}
        >
          <Text size="s">
            {intl.formatMessage({
              id: "restaurants.location.table.form.zone.delete.button",
              defaultMessage: "Delete",
            })}
          </Text>
        </Menu.Item>
      </Menu>
      <ConfirmationModal
        visible={showConfirmDelete}
        itemType={intl.formatMessage({
          id: "restaurants.location.table.delete.zone.modal.title.type",
          defaultMessage: "Zone",
        })}
        itemName={methods.getValues("name")}
        onConfirm={onDisableZone}
        onCancel={onCancelDelete}
        loading={disabling}
      />
    </>
  )
}

const InputWrapperStyle = {
  height: 24,
  marginRight: 5,
  width: 150,
}

const StyledContInputEdit = styled.div`
  display: flex;
  align-items: center;
  button {
    height: 24px;
  }
`
