import { useGeneralContext } from "../../../../../../../shared/contexts/StoreProvider"
import Fieldset from "../../components/Fieldset"
import NotificationPreference from "../../components/NotificationPreference"
import { OrderMethodSettingsFormType } from "../hookforms.interfaces"
import { NotificationPreferences } from "./notification.title"
import { Stack } from "@mantine/core"
import { useFormContext, useWatch } from "react-hook-form"
import { useIntl } from "react-intl"

export const NotificationSettingsForm = () => {
  const intl = useIntl()

  const {
    state: {
      currentRestaurant: { name: restaurantName },
      selectedLocation: { name: locationName },
    },
  } = useGeneralContext()

  const { control, setValue } = useFormContext<OrderMethodSettingsFormType>()

  const [orderReady, orderVerification] = useWatch({
    control,
    name: ["orderReady", "orderVerification"],
  })

  const onVerificationCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setValue("orderVerification.checked", event.currentTarget.checked, {
      shouldDirty: true,
    })
  }

  const onReadyCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setValue("orderReady.checked", event.currentTarget.checked, {
      shouldDirty: true,
    })
  }

  const onVerificationTextChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setValue("orderVerification.additionalText", event.target.value, {
      shouldDirty: true,
    })
  }

  const onReadyTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue("orderReady.additionalText", event.target.value, {
      shouldDirty: true,
    })
  }

  return (
    <Fieldset
      label={intl.formatMessage({
        id: "restaurants.locations.overview.order.method.modal.notifications.section.title",
        defaultMessage: "Notification settings",
      })}
    >
      <Stack spacing={32}>
        <NotificationPreference
          label={intl.formatMessage(NotificationPreferences.verification.label)}
          description={intl.formatMessage(
            NotificationPreferences.verification.description,
            { restaurantName }
          )}
          placeholder={intl.formatMessage(
            NotificationPreferences.verification.placeholder
          )}
          isChecked={!!orderVerification?.checked}
          additionalText={orderVerification?.additionalText}
          onCheckboxChange={onVerificationCheckboxChange}
          onAdditionalTextChange={onVerificationTextChange}
        />
        <NotificationPreference
          label={intl.formatMessage(NotificationPreferences.ready.label)}
          description={intl.formatMessage(
            NotificationPreferences.ready.description,
            { restaurantName, locationName }
          )}
          placeholder={intl.formatMessage(
            NotificationPreferences.ready.placeholder
          )}
          isChecked={!!orderReady?.checked}
          additionalText={orderReady?.additionalText}
          onCheckboxChange={onReadyCheckboxChange}
          onAdditionalTextChange={onReadyTextChange}
        />
      </Stack>
    </Fieldset>
  )
}
