import { DeliveryIntegrationEnum } from "../../../../../shared/graphql/generated/types"
import Container from "../../../../../ui/Container"
import InputLabel from "../../../../../ui/InputLabel"
import Input from "../../../../../ui/Inputs"
import InputHelper from "../../../../../ui/Inputs/InputHelper"
import Radio, { RadioGroup } from "../../../../../ui/Radio"
import HighlightedText from "../../../../../ui/Typography/HighlightedText"
import Text from "../../../../../ui/Typography/Text"
import type { CreateDeliveryIntegrationForm } from "../interfaces/hookforms.interface"
import { DeliveryProviders } from "./delivery-integrations.util"
import React from "react"
import { Controller, useFormContext } from "react-hook-form"
import { useIntl } from "react-intl"
import styled from "styled-components"

type DeliveryIntegrationFormProps = React.ComponentProps<"form"> & {
  imgProps?: React.ComponentProps<"img">
}

export const DeliveryIntegrationForm: React.FC<DeliveryIntegrationFormProps> =
  ({ ...props }) => {
    const intl = useIntl()

    const {
      control,
      watch,
      formState: { errors },
    } = useFormContext<CreateDeliveryIntegrationForm>()

    const deliveryProviders = (
      Object.keys(DeliveryProviders) as Array<`${DeliveryIntegrationEnum}`>
    ).map((provider) => {
      return {
        id: provider,
        value: provider,
        logo: DeliveryProviders[provider].logo,
      }
    })

    const integrationName = watch("integrationName")
    const selectedProvider = DeliveryProviders[integrationName]

    const goToProviderApiLink = () => {
      window.open(selectedProvider.apiKeyLink, "_blank")
    }

    return (
      <form {...props} aria-label="delivery-integration-form">
        <Container display="flex" flexDirection="column" gap="24px">
          <Controller
            name="integrationName"
            control={control}
            render={({ field: integrationLabel }) => (
              <StyledIntegrationNameContainer>
                <InputLabel
                  label={intl.formatMessage({
                    id: "settings.restaurant.delivery.integration.selection.provider.message",
                    defaultMessage: "Select your Delivery Provider",
                  })}
                  requirement="required"
                />
                <StyledRadioGroup {...integrationLabel} flexFlow="row">
                  {deliveryProviders.map((provider) => (
                    <Radio
                      key={provider.id}
                      $classId={provider.id}
                      value={provider.value}
                      content={<img src={provider.logo} {...props.imgProps} />}
                      className="image"
                    />
                  ))}
                </StyledRadioGroup>

                <InputHelper
                  $hasError={!!errors?.integrationName}
                  className="integration-name-helper"
                >
                  {errors?.integrationName?.message}
                </InputHelper>
              </StyledIntegrationNameContainer>
            )}
          />
          <Controller
            control={control}
            name="apiKey"
            render={({ field: nameField }) => {
              return (
                <Input
                  {...nameField}
                  label={intl.formatMessage({
                    id: "settings.restaurant.delivery.integration.add.delivery.integration.form.api.key.label",
                    defaultMessage: "API Key",
                  })}
                  description={
                    <Container
                      display="flex"
                      justifyContent="start"
                      alignItems="center"
                      gap="8px"
                    >
                      <Text size="m">
                        {intl.formatMessage({
                          id: "settings.restaurant.delivery.integration.add.delivery.integration.form.api.key.description",
                          defaultMessage: "Where to find it?",
                        })}
                      </Text>

                      {
                        //TODO: Replace with LinkButton
                      }
                      <HighlightedText
                        cursor="pointer"
                        onClick={goToProviderApiLink}
                      >
                        {intl.formatMessage(selectedProvider.docs)}
                      </HighlightedText>
                    </Container>
                  }
                  aria-label="name"
                  hasError={!!errors?.apiKey}
                  helperText={errors?.apiKey?.message}
                  requirement="required"
                />
              )
            }}
          />
          <Controller
            control={control}
            name="integrationLabel"
            render={({ field: integrationLabel }) => (
              <Input
                {...integrationLabel}
                label={intl.formatMessage({
                  id: "settings.restaurant.delivery.integration.add.delivery.integration.form.integration.label.label",
                  defaultMessage: "Name",
                })}
                aria-label="name"
                hasError={!!errors?.integrationLabel}
                helperText={errors?.integrationLabel?.message}
                requirement="required"
                capitalizeFirstLetter={false}
              />
            )}
          />
          {integrationName === DeliveryIntegrationEnum.DOORDASH && (
            <>
              <Controller
                control={control}
                name="developerId"
                render={({ field: developerIdLabel }) => (
                  <Input
                    {...developerIdLabel}
                    label={intl.formatMessage({
                      id: "settings.restaurant.delivery.integration.add.delivery.integration.form.developer.id.label",
                      defaultMessage: "Developer Id",
                    })}
                    aria-label="developerId"
                    hasError={!!errors?.developerId}
                    helperText={errors?.developerId?.message}
                    requirement="required"
                  />
                )}
              />
              <Controller
                control={control}
                name="signingSecret"
                render={({ field: signingSecretLabel }) => (
                  <Input
                    {...signingSecretLabel}
                    label={intl.formatMessage({
                      id: "settings.restaurant.delivery.integration.add.delivery.integration.form.developer.signing.secret.label",
                      defaultMessage: "Signing Secret",
                    })}
                    aria-label="signingSecret"
                    hasError={!!errors?.signingSecret}
                    helperText={errors?.signingSecret?.message}
                    requirement="required"
                  />
                )}
              />
            </>
          )}
          <button type="submit" className="hidden" />
        </Container>
      </form>
    )
  }

const StyledRadioGroup = styled(RadioGroup)`
  display: flex;
  flex-direction: row;
  gap: 32px;
`

const StyledIntegrationNameContainer = styled.div`
  .integration-name-helper {
    margin-left: 24px;
  }
`
