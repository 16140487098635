import yup from "../../../../../shared/yup"
import { yupResolver } from "@hookform/resolvers/yup"

export const CreateTaxSchema = yup.object().shape({
  name: yup.string().required(),
  taxRate: yup.number().moreThan(0).max(100).required(),
  locations: yup.array().min(1).label("locations").of(yup.string()).required(),
})

export const CreateTaxResolver = yupResolver(CreateTaxSchema)
