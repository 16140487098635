import { TipValueTypeEnum } from "../../../../../../../../shared/graphql/generated/types"
import { DefaultTips } from "../../config"

const AMOUNTS_STEP = 0.5

export const getTipOptions = (
  type: TipValueTypeEnum,
  initValues: Array<number> = []
) => {
  // [options] Amounts => 0 to 50 with 0.5 steps / Percentages => 0 to 50 with 1 steps
  const options =
    type === TipValueTypeEnum.AMOUNTS
      ? Array.from({ length: 101 }, (_, i) => ({
          value: i * AMOUNTS_STEP,
          selected: false,
        }))
      : Array.from({ length: 51 }, (_, i) => ({
          value: i,
          selected: false,
        }))

  const selectedOptions =
    initValues.length === 0 ? DefaultTips[type] : initValues

  selectedOptions.forEach((value) => {
    const index =
      type === TipValueTypeEnum.AMOUNTS ? value / AMOUNTS_STEP : value
    options[index].selected = true
  })

  const optionFormatted = options.map((option) => ({
    ...option,
    value: option.value.toString(),
  }))
  return optionFormatted
}
