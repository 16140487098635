import type { DeliveryPrice } from "../../../../../../../shared/graphql/generated/types"
import metersToMiles from "../../../../../../../shared/utils/helpers/metersToMiles"
import milesToMeters from "../../../../../../../shared/utils/helpers/milesToMeters"
import Button from "../../../../../../../ui/Button"
import Container from "../../../../../../../ui/Container"
import { showGraphqlErrors } from "../../../../../../../ui/ErrorList"
import Modal from "../../../../../../../ui/Modal"
import type { IModalProps } from "../../../../../../../ui/Modal"
import type { ILocationDeliveryParams } from "../../../hookforms.interfaces"
import { useUpdateDeliveryPriceMutation } from "../../GraphQL/updateDeliveryPrice.generated"
import type { IDeliveryZonesTableModel } from "../../TableDeliveryZones/deliveryZonesTable.interfaces"
import FormDeliveryZoneModal from "../FormDeliveryZoneModal"
import { DeliveryZoneResolver } from "../FormDeliveryZoneModal/FormDeliveryZoneModal.yup"
import type { IDeliveryZoneForm } from "../FormDeliveryZoneModal/interfaces/hookforms.interfaces"
import React from "react"
import { FormProvider, useForm } from "react-hook-form"
import { useIntl } from "react-intl"
import { useParams } from "react-router-dom"

type EditDeliveryZoneModalProps = Omit<IModalProps, "onCancel"> & {
  onClose: () => void
  data: IDeliveryZonesTableModel
}

const EditDeliveryZoneModal: React.FC<EditDeliveryZoneModalProps> = ({
  onClose,
  data,
  ...props
}) => {
  const intl = useIntl()
  const { locationUUID } = useParams<ILocationDeliveryParams>()

  const EditDeliveryZoneModalMethods = useForm<IDeliveryZoneForm>({
    mode: "all",
    resolver: DeliveryZoneResolver,
    defaultValues: {
      price: data.price,
      maxDistance: Number.parseInt(metersToMiles(data.maxDistance)),
    },
  })

  const {
    formState: { isValid },
    reset,
    handleSubmit,
  } = EditDeliveryZoneModalMethods

  const [updateDeliveryZone, { loading }] = useUpdateDeliveryPriceMutation({
    update: (cache, result) => {
      cache.modify({
        fields: {
          getDeliveryPricesByLocation: (deliveryPrices: DeliveryPrice[]) => {
            return deliveryPrices.map((current, index) => {
              const deliveryZoneUUID = result.data?.updateDeliveryPrice.uuid

              if (current.uuid === deliveryZoneUUID) {
                return result.data?.updateDeliveryPrice
              }

              if (deliveryPrices[index - 1]?.uuid === deliveryZoneUUID) {
                return {
                  ...deliveryPrices[index],
                  minDistance: result.data?.updateDeliveryPrice.maxDistance,
                }
              }

              return current
            })
          },
        },
      })
    },
  })

  const onUpdateDeliveryZone = async (formData: IDeliveryZoneForm) => {
    try {
      await updateDeliveryZone({
        variables: {
          uuid: data.uuid,
          locationUUID,
          maxDistance: milesToMeters(formData.maxDistance ?? 0),
          price: formData.price ?? 0,
        },
      })

      reset()
      onClose()
    } catch (error) {
      showGraphqlErrors(error)
    }
  }

  return (
    <FormProvider {...EditDeliveryZoneModalMethods}>
      <Modal
        {...props}
        title={intl.formatMessage({
          id: "settings.locations.delivery.zones.edit.modal.title",
          defaultMessage: "Edit Delivery Zone",
        })}
        width="520px"
        onCancel={onClose}
        closable={false}
        footer={
          <Container
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
            gap="8px"
          >
            <Button
              title={intl.formatMessage({
                id: "settings.locations.delivery.zones.edit.modal.button.cancel",
                defaultMessage: "Cancel",
              })}
              hierarchy="secondary"
              onClick={onClose}
              size="large"
              disabled={loading}
            />

            <Button
              title={intl.formatMessage({
                id: "settings.locations.delivery.zones.edit.modal.button.create",
                defaultMessage: "Save",
              })}
              size="large"
              disabled={!isValid}
              onClick={handleSubmit(onUpdateDeliveryZone)}
              loading={loading}
            />
          </Container>
        }
        shouldCloseOnClickOutside
        destroyOnClose
        centered
      >
        <FormDeliveryZoneModal />
      </Modal>
    </FormProvider>
  )
}

export default EditDeliveryZoneModal
