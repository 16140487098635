import { LabelFieldSkeleton } from "../InputLabel"
import { Skeleton } from "antd"
import styled from "styled-components"

export const CheckboxFieldSkeleton = () => {
  return (
    <>
      <LabelFieldSkeleton />
      <StyledCheckboxFieldSkeleton className="checkbox-field-skeleton">
        <Skeleton.Avatar active size="small" shape="square" />
      </StyledCheckboxFieldSkeleton>
    </>
  )
}

const StyledCheckboxFieldSkeleton = styled.div`
  &.checkbox-field-skeleton {
    width: 70px;
    height: 24px;
    display: flex;
    justify-content: space-between;
  }
`
