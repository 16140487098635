import { FeeQuantityEnum, PrePaymentType } from "../shared/hookforms.intefaces"

export const prePaymentVerify = (
  prePayment: PrePaymentType | undefined | null
) => {
  return {
    paymentMethod: prePayment?.percentage
      ? FeeQuantityEnum.PERCENTAGE
      : FeeQuantityEnum.AMOUNT,
    paymentValue: prePayment?.percentage || prePayment?.amount || 0,
  }
}
