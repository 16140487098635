import type { GraphqlError } from "./parseGraphqlErrorMessages"

export interface NetworkError {
  name: string
  result: { errors: GraphqlError[] }
  length: number
  path: string[]
  statusCode: number
}

const parseNetworkErrorMessages = (error: NetworkError): string[] => {
  const reduceErrors = (errorList: GraphqlError[]) => {
    const messages = errorList
      // eslint-disable-next-line unicorn/no-array-reduce
      .reduce<string[][]>((allErrors, currentError) => {
        const currentMessages =
          currentError?.extensions?.exception?.response?.message || []

        if (currentMessages.length > 0) {
          allErrors.push(currentMessages)
        } else {
          allErrors.push([
            currentError.message.slice(
              Math.max(0, currentError.message.indexOf("Field"))
            ),
          ])
        }

        return allErrors
      }, [])
      .flat()
      .filter((errorMessage) => errorMessage !== undefined)

    return messages
  }

  if (error.result?.errors.length) {
    return reduceErrors(error.result?.errors)
  }

  return []
}

export default parseNetworkErrorMessages
