import { ReactComponent as CloseIcon } from "../../shared/assets/icons/notification_close.svg"
import { ReactComponent as ErrorICon } from "../../shared/assets/icons/notification_error.svg"
import { ReactComponent as InfoIcon } from "../../shared/assets/icons/notification_info.svg"
import { ReactComponent as SuccessIcon } from "../../shared/assets/icons/notification_success.svg"
import { ReactComponent as WarningIcon } from "../../shared/assets/icons/notification_warning.svg"
import { ThemeProvider } from "../../shared/contexts/ThemeProvider"
import { colors } from "../../styles/global/themes/mainTheme"
import Text from "../Typography/Text"
import { notification as antNotification } from "antd"
import type { ArgsProps } from "antd/lib/notification"
import React from "react"
import styled from "styled-components"

type NotificationType = "success" | "error" | "warning" | "info"

type NotificationProps = Omit<ArgsProps, "message" | "description"> & {
  type: NotificationType
  closable?: boolean
  description?: React.ReactNode
  header?: string
  title?: string
}

const iconConfig: Record<
  string,
  React.FunctionComponent<React.SVGProps<SVGSVGElement>>
> = {
  success: SuccessIcon,
  error: ErrorICon,
  warning: WarningIcon,
  info: InfoIcon,
}

export const notification = ({
  closable = true,
  top = 75,
  duration = 5,
  type,
  description,
  title,
  ...props
}: NotificationProps) => {
  const Icon = iconConfig[type]

  const notificationDismiss = {
    ["success"]: duration,
    ["error"]: duration,
    ["warning"]: duration,
    ["info"]: duration,
  }

  return antNotification.open({
    ...props,
    top,
    description: <ThemeProvider>{description}</ThemeProvider>,
    duration: notificationDismiss[type],
    message: title ?? NotificationTitle(type),
    icon: (
      <i>
        <Icon width={16} height={16} />
      </i>
    ),
    style: NotificationStyles(type),
    closeIcon: closable ? (
      <i>
        <CloseIcon width={12} height={12} />
      </i>
    ) : (
      <></>
    ),
  })
}

export const NotificationTitle = (type: NotificationType): React.ReactNode => {
  switch (type) {
    case "success": {
      return <div>Success</div>
    }

    case "error": {
      return <div>Error</div>
    }

    case "warning": {
      return <div>Warning</div>
    }

    case "info": {
      return <div>Info</div>
    }
  }
}

export const NotificationStyles = (
  type: NotificationType
): React.CSSProperties => {
  switch (type) {
    case "success": {
      return {
        background: colors["Success1"],
        border: `1px solid ${colors["Success2"]}`,
        borderRadius: 8,
      }
    }

    case "error": {
      return {
        background: colors["Danger1"],
        border: `1px solid ${colors["Danger2"]}`,
        borderRadius: 8,
      }
    }

    case "warning": {
      return {
        background: colors["Warning1"],
        border: `1px solid ${colors["Warning2"]}`,
        borderRadius: 8,
      }
    }

    default: {
      return {
        background: colors["Primary1"],
        border: `1px solid ${colors["Primary3"]}`,
        borderRadius: 8,
      }
    }
  }
}

export const closeNotification = (key: string) => antNotification.close(key)

export const formNotification = ({
  error,
  header,
  title,
  ...props
}: Omit<NotificationProps, "description"> & {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error: Record<string, { message: string } | undefined | any>
}) => {
  props.key && antNotification.close(props.key)

  return notification({
    ...props,
    title: header,
    description: (
      <StyledDescription>
        <Text size="s">
          {title}
          <ul>
            {Object.keys(error).map((key) => {
              if (!error[key]?.message) {
                return
              }

              return <li key={key}>{error[key]?.message}</li>
            })}
          </ul>
        </Text>
      </StyledDescription>
    ),
  })
}

const StyledDescription = styled.div`
  ul {
    margin: 0;
    padding-left: 20px;
  }
`
