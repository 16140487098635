import { autocompleteAddressValidation } from "../../../../components/AutocompleteAddress/AutocompleteAddress.yup"
import {
  PHONE_FORMAT_ERROR_ID,
  REQUIRED_FIELD_ERROR_ID,
} from "../../../../shared/titles/validations.title"
import yup from "../../../../shared/yup"
import { yupResolver } from "@hookform/resolvers/yup"

const LocationInfoSchema = yup.object().shape({
  ...autocompleteAddressValidation,
  name: yup.string().required(),
  phone: yup
    .string()
    .required(REQUIRED_FIELD_ERROR_ID)
    .validatePhoneNumber(PHONE_FORMAT_ERROR_ID),
  orderTypes: yup
    .array()
    .min(1)
    .label("order types")
    .of(yup.string())
    .required(),
})

export const LocationInfoResolver = yupResolver(LocationInfoSchema)
