import * as Types from '../../../../../../shared/graphql/generated/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateLocationTableMutationVariables = Types.Exact<{
  data: Types.CreateLocationTableInput;
}>;


export type CreateLocationTableMutation = { __typename?: 'Mutation', createLocationTable: { __typename?: 'LocationTableModel', uuid: string, name: string, description?: string | null, capacity: number, isActive: boolean, qrPath: string, smoking: boolean, status: Types.StatusTableEnum, tableIdentifier: string } };


export const CreateLocationTableDocument = gql`
    mutation createLocationTable($data: CreateLocationTableInput!) {
  createLocationTable(data: $data) {
    uuid
    name
    description
    capacity
    isActive
    qrPath
    smoking
    status
    tableIdentifier
  }
}
    `;
export type CreateLocationTableMutationFn = Apollo.MutationFunction<CreateLocationTableMutation, CreateLocationTableMutationVariables>;

/**
 * __useCreateLocationTableMutation__
 *
 * To run a mutation, you first call `useCreateLocationTableMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLocationTableMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLocationTableMutation, { data, loading, error }] = useCreateLocationTableMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateLocationTableMutation(baseOptions?: Apollo.MutationHookOptions<CreateLocationTableMutation, CreateLocationTableMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateLocationTableMutation, CreateLocationTableMutationVariables>(CreateLocationTableDocument, options);
      }
export type CreateLocationTableMutationHookResult = ReturnType<typeof useCreateLocationTableMutation>;
export type CreateLocationTableMutationResult = Apollo.MutationResult<CreateLocationTableMutation>;
export type CreateLocationTableMutationOptions = Apollo.BaseMutationOptions<CreateLocationTableMutation, CreateLocationTableMutationVariables>;