import { useGeneralContext } from "../../../../shared/contexts/StoreProvider"
import paths from "../../../../shared/routes/paths"
import DataTable from "../../../../ui/DataTable"
import EmptyState from "../../../../ui/EmptyState"
import { PageLayout } from "../../../../ui/Layouts/MainLayout/Content"
import { useGetTaxesByRestaurantQuery } from "./GraphQL/getTaxesByRestaurant.generated"
import type { ITaxRowModel } from "./datatable.interfaces"
import taxesTableConfig from "./tax-list.util"
import get from "lodash/get"
import React from "react"
import { useIntl } from "react-intl"
import { useHistory } from "react-router-dom"

export const Taxes: React.FC = () => {
  const [columns] = taxesTableConfig()
  const intl = useIntl()
  const { push } = useHistory()

  const {
    state: {
      currentRestaurant: { uuid: restaurantUUID },
    },
  } = useGeneralContext()

  const { data, loading, error, refetch } = useGetTaxesByRestaurantQuery({
    variables: {
      restaurantUUID,
    },
    skip: !restaurantUUID,
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
    returnPartialData: true,
  })

  const taxesList = get(data, "getTaxesByRestaurant", [])

  const onTaxRowClick = (dataRow: ITaxRowModel) => {
    const { isGeneral, uuid } = dataRow

    return {
      onClick: () => {
        if (isGeneral) {
          push(paths.settings.restaurant.editSalesTax(uuid))
        } else {
          push(paths.settings.restaurant.editItemTax(uuid))
        }
      },
    }
  }

  const retry = () => refetch()

  return (
    <PageLayout role="taxes-table">
      <DataTable
        substactPixelesToScrollHeight={123}
        dataSource={taxesList}
        columns={columns}
        loading={loading}
        onRow={onTaxRowClick}
        emptyMessage={
          <EmptyState
            title={intl.formatMessage({
              id: "restaurants.taxes.empty.title",
              defaultMessage: "No taxes added yet",
            })}
            description={intl.formatMessage({
              id: "restaurants.taxes.empty.description",
              defaultMessage: "You can add one by clicking the plus button",
            })}
            fullScreen
          />
        }
        error={error}
        errorRefetch={retry}
      />
    </PageLayout>
  )
}
