import { Progress as AntProgress, ProgressProps } from "antd"
import styled from "styled-components"

export const Progress = (properties: ProgressProps) => {
  return <StyledProgress {...properties} />
}

const StyledProgress = styled(AntProgress)`
  .ant-progress-circle-trail {
    stroke: ${({ theme }) => theme.colors.Primary1};
  }
  .ant-progress-inner:not(.ant-progress-circle-gradient)
    .ant-progress-circle-path {
    stroke: ${({ theme }) => theme.colors.Primary5};
  }
  .ant-progress-text {
    color: ${({ theme }) => theme.colors.Neutral9};
    font-family: ${({ theme }) => theme.text.fonts.dmSansRegular};
  }
  &.ant-progress-circle.ant-progress-status-success .ant-progress-text {
    color: ${({ theme }) => theme.colors.Primary5};
  }
  .ant-progress-inner {
    background-color: ${({ theme, type }) =>
      type === "circle" ? theme.colors.Neutral0 : theme.colors.Primary1};
  }
  .ant-progress-bg {
    background-color: ${({ theme }) => theme.colors.Primary5};
  }
`
