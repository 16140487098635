import { useGeneralContext } from "../../../../../shared/contexts/StoreProvider"
import paths from "../../../../../shared/routes/paths"
import Button from "../../../../../ui/Button"
import Container from "../../../../../ui/Container"
import { showGraphqlErrors } from "../../../../../ui/ErrorList/ErrorList"
import ModalFull from "../../../../../ui/ModalFull"
import Spacer from "../../../../../ui/Spacer"
import notification from "../../../../../ui/notification"
import type { IItemTaxModel } from "../CreateItemTax/hookforms.interfaces"
import { GetTaxesByRestaurantDocument } from "../GraphQL/getTaxesByRestaurant.generated"
import TaxInformationForm from "../TaxForm/TaxInformationForm"
import TaxLocationsForm from "../TaxForm/TaxLocationsForm"
import { CreateTaxResolver } from "./CreateTax.yup"
import { useCreateLocationTaxMutation } from "./GraphQL/createLocationTax.generated"
import { useCreateTaxMutation } from "./GraphQL/createTax.generated"
import type { ITaxModel } from "./hookforms.interfaces"
import get from "lodash/get"
import React from "react"
import { FormProvider, useForm, useFormContext } from "react-hook-form"
import { useIntl } from "react-intl"
import { useHistory } from "react-router-dom"

export const useCreateTaxContext = () =>
  useFormContext<ITaxModel | IItemTaxModel>()

type CreateTaxProps = {
  defaultValues?: Record<string, unknown>
}

export const CreateTax: React.FC<CreateTaxProps> = ({ defaultValues }) => {
  const intl = useIntl()
  const { goBack } = useHistory()
  const { settings } = paths

  const {
    state: {
      currentRestaurant: { uuid: restaurantUUID },
    },
  } = useGeneralContext()

  const formMethods = useForm<ITaxModel>({
    mode: "all",
    resolver: CreateTaxResolver,
    defaultValues: {
      isGeneral: false,
      applyAllLocations: false,
      applyFutureLocations: false,
      ...defaultValues,
    },
  })

  const {
    handleSubmit,
    formState: { isValid, isDirty },
  } = formMethods

  const [createGeneralTax, { loading }] = useCreateTaxMutation({
    refetchQueries: [GetTaxesByRestaurantDocument],
  })

  const [createLocationTax, createLocationTaxState] =
    useCreateLocationTaxMutation()

  const onCreateTax = async ({
    name,
    description,
    applyFutureLocations,
    locations,
    taxRate,
  }: ITaxModel) => {
    try {
      const { data } = await createGeneralTax({
        variables: {
          data: {
            restaurantUUID,
            taxRate,
            name,
            ...(!!description && { description }),
            applyFutureLocations,
            isGeneral: true,
          },
        },
      })

      if (locations?.length) {
        const createdTaxUUID = get(data, "createTax.uuid")

        await createLocationTax({
          variables: {
            data: {
              taxUUID: createdTaxUUID,
              locationUUIDs: locations,
            },
          },
        })
      }

      notification({
        description: intl.formatMessage({
          id: "restaurants.taxes.sales.form.save.message",
          defaultMessage: "Your sales tax were created",
        }),
        type: "success",
      })
      goBack()
    } catch (error) {
      showGraphqlErrors(error)
    }
  }

  return (
    <ModalFull
      id="create-tax-modal"
      goBackPath={settings.restaurant.taxes}
      title={intl.formatMessage({
        id: "restaurants.taxes.form.new.title",
        defaultMessage: "New Taxes",
      })}
      visible
      closable
    >
      <FormProvider {...formMethods}>
        <form
          onSubmit={handleSubmit(onCreateTax)}
          role="form"
          aria-label="create-tax-form"
        >
          <TaxInformationForm />
          <Spacer size={64} />
          <TaxLocationsForm />
          <Spacer size={64} />

          <Container display="flex" justifyContent="flex-start">
            <Button
              loading={loading || createLocationTaxState.loading}
              title={intl.formatMessage({
                id: "restaurants.taxes.sales.form.save.button",
                defaultMessage: "Save Information",
              })}
              hierarchy="secondary"
              onClick={handleSubmit(onCreateTax)}
              disabled={!isValid || !isDirty}
            />
          </Container>
          <button type="submit" className="hidden" />
        </form>
      </FormProvider>
    </ModalFull>
  )
}
