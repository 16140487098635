import { useGetBankingAccountByRestaurantQuery } from "../../../../GraphQL/Queries/getBankingAccountByRestaurant.generated"
import {
  GetSingleLocationDocument,
  useGetSingleLocationQuery,
} from "../../../../GraphQL/Queries/getSingleLocation.generated"
import { useRestaurantCompletionStepsContext } from "../../../../shared/contexts/RestaurantCompletionStepsProvider"
import { useGeneralContext } from "../../../../shared/contexts/StoreProvider"
import { showGraphqlErrors } from "../../../../ui/ErrorList"
import DetailPage from "../../../../ui/Layouts/MainLayout/DetailPage"
import RetryPage from "../../../../ui/RetryPage"
import Select from "../../../../ui/Select"
import notification from "../../../../ui/notification"
import { useAddRelationBankingAccountMutation } from "../CreateLocation/GraphQL/addRelationBankingAccount.generated"
import { useRemoveRelationBankingAccountMutation } from "../CreateLocation/GraphQL/removeRelationBankingAccount.generated"
import type { ILocationsParams } from "../hookforms.interfaces"
import { isLocationActive } from "../utils/is-location-active"
import type { ILocationBankingForm } from "./hookforms.interfaces"
import React, { useState } from "react"
import { Controller, useForm } from "react-hook-form"
import { useIntl } from "react-intl"
import { useParams } from "react-router-dom"

export const LocationBanking: React.FC = () => {
  const intl = useIntl()
  const [loading, setLoading] = useState(false)
  const { locationUUID } = useParams<ILocationsParams>()
  const {
    mainLocationUUID,
    hasBankAccountConnected,
    updateRestaurantCompletionSteps,
  } = useRestaurantCompletionStepsContext()

  const {
    state: {
      currentRestaurant: { uuid: restaurantUUID },
    },
  } = useGeneralContext()

  const {
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm<ILocationBankingForm>({
    mode: "all",
  })

  const [addLocationBankAccount] = useAddRelationBankingAccountMutation({
    refetchQueries: [GetSingleLocationDocument],
  })
  const [removeRelationBankingAccount] =
    useRemoveRelationBankingAccountMutation({
      refetchQueries: [GetSingleLocationDocument],
    })

  const { data, error, refetch } = useGetBankingAccountByRestaurantQuery({
    variables: {
      restaurantUUID,
      ...(!!locationUUID && {
        locationUUID,
      }),
    },
    skip: !restaurantUUID || !locationUUID,
  })

  const bankAccountList = data?.getBankingAccountsByRestaurant || []

  const bankAccountOptions = bankAccountList
    .filter(({ stIsConnected }) => stIsConnected)
    .map(({ name, uuid }) => {
      return {
        label: name,
        value: uuid,
      }
    })

  const {
    loading: loadingSingleLocation,
    data: locationData,
    error: locationError,
    refetch: locationRefetch,
  } = useGetSingleLocationQuery({
    variables: { locationUUID },
    skip: !locationUUID,
    fetchPolicy: "network-only",
    onError: showGraphqlErrors,
    onCompleted: (response) => {
      const location = response.getSingleLocation

      if (location.uuid === mainLocationUUID) {
        const hasLocationBankAccountConnected =
          !!location?.bankingAccount?.stIsConnected

        if (hasBankAccountConnected !== hasLocationBankAccountConnected) {
          updateRestaurantCompletionSteps({
            hasBankAccountConnected: hasLocationBankAccountConnected,
          })
        }
      }

      if (location) {
        reset({
          ...(location?.bankingAccount?.stIsConnected && {
            bankAccountName: location.bankingAccount.name,
            bankAccountUUID: location.bankingAccount.uuid,
            isActive: isLocationActive(location),
          }),
        })
      }
    },
  })
  const isActive = isLocationActive(locationData?.getSingleLocation)

  const updateLocationBankAccount = async (bankingAccountUUID: string) => {
    if (!bankingAccountUUID) {
      return
    }

    return addLocationBankAccount({
      variables: {
        data: {
          bankingAccountUUID,
          locationUUID,
        },
      },
    })
  }

  const removeLocationBankAccount = async (locationBankUUID: string) => {
    if (!locationBankUUID) {
      return
    }

    return removeRelationBankingAccount({
      context: { locationUUID: locationBankUUID },
    })
  }

  const saveLocation = async (formData: ILocationBankingForm) => {
    try {
      setLoading(true)

      await (formData.bankAccountUUID
        ? updateLocationBankAccount(formData.bankAccountUUID)
        : removeLocationBankAccount(locationUUID))

      notification({
        description: intl.formatMessage({
          id: "components.layouts.settings.bar.locations.banking.success.label",
          defaultMessage: "Your location banking was updated",
        }),
        type: "success",
      })
    } catch (saveError) {
      showGraphqlErrors(saveError)
    } finally {
      setLoading(false)
    }
  }

  const retry = () => {
    refetch()
    locationRefetch()
  }

  if (error) {
    return (
      <DetailPage>
        <RetryPage error={error} reload={retry} fullScreen />
      </DetailPage>
    )
  }

  return (
    <DetailPage
      isActive={isActive}
      message={intl.formatMessage({
        id: "restaurants.locations.deactivated.title",
        defaultMessage: "This location is not available.",
      })}
      error={locationError}
      reload={retry}
    >
      <Controller
        control={control}
        name="bankAccountUUID"
        render={({ field: { onChange, value } }) => (
          <Select
            value={value}
            label={intl.formatMessage({
              id: "components.layouts.settings.bar.locations.banking.label",
              defaultMessage: "Banking Integrations",
            })}
            placeholder={intl.formatMessage({
              id: "components.layouts.settings.bar.locations.banking.placeholder",
              defaultMessage: "Select a Bank to connect with this location",
            })}
            aria-label="location"
            options={bankAccountOptions}
            hasError={!!errors?.bankAccountUUID}
            helperText={errors?.bankAccountUUID?.message}
            onChange={(changedValue) => {
              onChange({ target: { value: changedValue } })
              handleSubmit(saveLocation)()
            }}
            loading={loading || loadingSingleLocation}
            allowClear
          />
        )}
      />
    </DetailPage>
  )
}
