import Divider from "../../../../ui/Divider"
import { LabelFieldSkeleton } from "../../../../ui/InputLabel"
import { InputFieldSkeleton } from "../../../../ui/Inputs"
import DetailPage from "../../../../ui/Layouts/MainLayout/DetailPage"
import Spacer from "../../../../ui/Spacer"
import type { SkeletonProps } from "antd"
import React from "react"

export const LocationInfoSkeleton: React.FC<SkeletonProps> = ({
  children,
  loading = true,
}) => {
  return loading ? (
    <DetailPage role="location-info-skeleton">
      <LabelFieldSkeleton />
      <InputFieldSkeleton />
      <Divider />
      <Spacer size={30} />

      <InputFieldSkeleton width="150px" marginBottom="20px" />

      <Spacer size={64} />

      <LabelFieldSkeleton />
      <InputFieldSkeleton width="150px" marginBottom="8px" />
      <InputFieldSkeleton width="150px" />

      <Spacer size={68} />

      <LabelFieldSkeleton />
      <InputFieldSkeleton />
      <Spacer size={16} />

      <LabelFieldSkeleton />
      <InputFieldSkeleton />

      <Spacer size={68} />

      <LabelFieldSkeleton />
    </DetailPage>
  ) : (
    <>{children}</>
  )
}
