import type { SwitchProps as AntSwitchProps } from "antd"
import { Switch as AntdSwitch } from "antd"
import React from "react"
import styled, { css } from "styled-components"

export const Switch: React.FC<AntSwitchProps> = (props) => {
  return <StyledSwitch {...props} />
}

const StyledSwitch = styled(AntdSwitch)`
  ${({ theme }) => css`
    &.ant-switch {
      background-color: ${theme.colors["Neutral5"]};
    }

    & .ant-switch-loading-icon {
      color: ${theme.colors["Neutral5"]};
    }

    &.ant-switch-checked .ant-switch-loading-icon {
      color: ${theme.colors["Secondary5"]};
    }

    &.ant-switch-checked {
      background-color: ${theme.colors["Secondary5"]};
    }
  `}
`
