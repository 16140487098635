import { useGetAllLocationsByUserLazyQuery } from "../../../../GraphQL/Queries/getAllLocationsByUser.generated"
import { useGetBankingAccountByRestaurantLazyQuery } from "../../../../GraphQL/Queries/getBankingAccountByRestaurant.generated"
import { useGetItemListLazyQuery } from "../../../../GraphQL/Queries/getItemList.generated"
import { useGetMenuListLazyQuery } from "../../../../GraphQL/Queries/getMenuList.generated"
import { useGetSingleLocationLazyQuery } from "../../../../GraphQL/Queries/getSingleLocation.generated"
import { useRestaurantCompletionStepsContext } from "../../../../shared/contexts/RestaurantCompletionStepsProvider"
import { useGeneralContext } from "../../../../shared/contexts/StoreProvider"
import paths from "../../../../shared/routes/paths"
import notification from "../../../notification"
import { getRestaurantCompletionSteps } from "./utils/get-restaurant-completion-steps.util"
import { useEffect, useState } from "react"
import { useIntl } from "react-intl"

export interface RestaurantCompletionProgressState {
  percent: number
  loading: boolean
  totalSteps: number
  completedSteps: number
  steps: Array<{
    completed: boolean
    title: string
    link: string
  }>
}

export const useRestaurantCompletionProgress = () => {
  const intl = useIntl()
  const {
    mainLocationUUID,
    hasMenuSetupCompleted,
    hasBankAccountConnected,
    hasItemsCombosSetupCompleted,
    hasBankAccountSetupCompleted,
    updateRestaurantCompletionSteps,
    hasHoursOfOperationSetupCompleted,
  } = useRestaurantCompletionStepsContext()

  const [loading, setLoading] = useState(true)

  const {
    state: {
      currentRestaurant: { uuid: restaurantUUID },
      auth: {
        admin: { uuid: userUUID },
      },
    },
  } = useGeneralContext()

  const [getItemList] = useGetItemListLazyQuery()
  const [getMenuList] = useGetMenuListLazyQuery()
  const [getSingleLocation] = useGetSingleLocationLazyQuery()
  const [getAllLocationsByUser] = useGetAllLocationsByUserLazyQuery()
  const [getBankingAccountByRestaurant] =
    useGetBankingAccountByRestaurantLazyQuery()

  useEffect(() => {
    const asyncEffect = async () => {
      try {
        const restaurantCompletionSteps = await getRestaurantCompletionSteps({
          userUUID,
          getItemList,
          getMenuList,
          restaurantUUID,
          getSingleLocation,
          getAllLocationsByUser,
          getBankingAccountByRestaurant,
        })

        if (!restaurantCompletionSteps) return

        updateRestaurantCompletionSteps(restaurantCompletionSteps)

        setLoading(false)
      } catch (error) {
        if (error instanceof Error) console.error(error.message, error.stack)

        // Avoid show error notification when the request is aborted. This error is caused
        // by React 18 rerendering in reactStrictMode
        if ((error as { name: string })?.name !== "AbortError")
          notification({
            type: "error",
            description: intl.formatMessage({
              id: "components.restaurant.completion.progress.error",
              defaultMessage: "Failed to get restaurant completion steps.",
            }),
          })
      }
    }

    asyncEffect()
  }, [
    intl,
    userUUID,
    getItemList,
    getMenuList,
    restaurantUUID,
    getSingleLocation,
    getAllLocationsByUser,
    getBankingAccountByRestaurant,
    updateRestaurantCompletionSteps,
  ])

  const steps =
    !loading && mainLocationUUID
      ? [
          {
            completed: hasHoursOfOperationSetupCompleted,
            title: intl.formatMessage({
              id: "components.restaurant.completion.progress.drawer.steps.hours.of.operation",
              defaultMessage: "Setup Hours of Operation",
            }),
            link: paths.settings.locations.hoursOfOperation(mainLocationUUID),
          },
          {
            completed: hasMenuSetupCompleted,
            title: intl.formatMessage({
              id: "components.restaurant.completion.progress.drawer.steps.menu",
              defaultMessage: "Setup Menu",
            }),
            link: paths.restaurants.menu,
          },
          {
            completed: hasItemsCombosSetupCompleted,
            title: intl.formatMessage({
              id: "components.restaurant.completion.progress.drawer.steps.items.combos",
              defaultMessage: "Setup Items/Combos",
            }),
            link: paths.restaurants.items,
          },
          {
            completed: hasBankAccountSetupCompleted,
            title: intl.formatMessage({
              id: "components.restaurant.completion.progress.drawer.bank.account.setup",
              defaultMessage: "Setup Bank Account",
            }),
            link: paths.settings.restaurant.banking,
          },
          {
            completed: hasBankAccountConnected,
            title: intl.formatMessage({
              id: "components.restaurant.completion.progress.drawer.steps.bank.account.connect",
              defaultMessage: "Connect Bank Account",
            }),
            link: paths.settings.locations.bankingByLocation(mainLocationUUID),
          },
        ]
      : []

  const totalSteps = steps.length
  const completedSteps = steps.filter((step) => step.completed).length
  const percent = Math.round((completedSteps / totalSteps) * 100)

  return {
    steps,
    loading,
    percent,
    totalSteps,
    completedSteps,
  }
}
