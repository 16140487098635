import type React from "react"

export const onPopupScroll = (
  event: React.UIEvent<HTMLDivElement, UIEvent>,
  condition: boolean,
  fetchMore: () => void
) => {
  const target = event.target as HTMLElement

  //This is the percentage of the scroll height that needs to be reached before we fetch more
  //it also improves the user experience by fetching more before the user reaches the end of the list
  const heightPercentageToFetch = 0.75

  if (
    condition &&
    target?.scrollTop + target?.offsetHeight >
      target?.scrollHeight * heightPercentageToFetch
  ) {
    fetchMore()
  }
}
