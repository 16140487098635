import { RestaurantCompletionStepsState } from "../../../../../shared/contexts/RestaurantCompletionStepsProvider"
import {
  GetHasBankAccountSetupCompletedArgs,
  getHasBankAccountSetupCompleted,
} from "./get-has-bank-account-setup-completed.util"
import {
  GetHasItemsCombosSetupCompletedArgs,
  getHasItemsCombosSetupCompleted,
} from "./get-has-items-combos-setup-completed.util"
import {
  GetHasMenuSetupCompletedArgs,
  getHasMenuSetupCompleted,
} from "./get-has-menu-setup-completed.util"
import { GetMainLocationArgs, getMainLocation } from "./get-main-location.util"

type GetRestaurantCompletionStepsArgs = GetMainLocationArgs &
  GetHasItemsCombosSetupCompletedArgs &
  GetHasBankAccountSetupCompletedArgs &
  GetHasMenuSetupCompletedArgs

export const getRestaurantCompletionSteps = async ({
  userUUID,
  getItemList,
  getMenuList,
  restaurantUUID,
  getSingleLocation,
  getAllLocationsByUser,
  getBankingAccountByRestaurant,
}: GetRestaurantCompletionStepsArgs): Promise<
  RestaurantCompletionStepsState | undefined
> => {
  if (!userUUID || !restaurantUUID) return

  const mainLocation = await getMainLocation({
    userUUID,
    restaurantUUID,
    getSingleLocation,
    getAllLocationsByUser,
  })

  if (!mainLocation) return

  const hasHoursOfOperationSetupCompleted = mainLocation.schedules.some(
    (schedule) => {
      return schedule.scheduleDetail.length > 0
    }
  )

  const hasMenuSetupCompleted = await getHasMenuSetupCompleted({
    getMenuList,
  })

  const hasItemsCombosSetupCompleted = await getHasItemsCombosSetupCompleted({
    getItemList,
  })

  const hasBankAccountSetupCompleted = await getHasBankAccountSetupCompleted({
    restaurantUUID,
    getBankingAccountByRestaurant,
  })

  const hasBankAccountConnected = !!mainLocation.bankingAccount

  return {
    mainLocationUUID: mainLocation.uuid,
    hasHoursOfOperationSetupCompleted,
    hasMenuSetupCompleted,
    hasItemsCombosSetupCompleted,
    hasBankAccountSetupCompleted,
    hasBankAccountConnected,
  }
}
