import yup from "../../../../shared/yup"
import { yupResolver } from "@hookform/resolvers/yup"

const CreateOrUpdateLoyaltyProgramSchema = yup.object().shape({
  conversionRate: yup.number().moreThan(0).required(),
  increment: yup
    .number()
    .moreThan(0)
    .required()
    .test({
      name: "validate loyalty",
      test: (values, { createError }) => {
        if (values == null) {
          return createError({
            message:
              "You must disable the program to be able to set the increment to 0",
            path: "increment",
          })
        }

        return true
      },
    }),
})

export const CreateOrUpdateLoyaltyProgramResolver = yupResolver(
  CreateOrUpdateLoyaltyProgramSchema
)
