import { Action } from "../shared/contexts/actions"

export enum UIActionTypes {
  SidebarToggle = "SIDEBAR_TOGGLE",
}

export interface SidebarToggleAction
  extends Action<UIActionTypes.SidebarToggle> {
  payload: {
    active: boolean
  }
}
