import * as Types from '../../../../../shared/graphql/generated/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DisableBankingAccountMutationVariables = Types.Exact<{
  uuid: Types.Scalars['ID'];
}>;


export type DisableBankingAccountMutation = { __typename?: 'Mutation', disableBankingAccount: { __typename?: 'BankingAccountModel', uuid: string, name: string, isActive: boolean, stIsConnected: boolean } };


export const DisableBankingAccountDocument = gql`
    mutation disableBankingAccount($uuid: ID!) {
  disableBankingAccount(uuid: $uuid) {
    uuid
    name
    isActive
    stIsConnected
  }
}
    `;
export type DisableBankingAccountMutationFn = Apollo.MutationFunction<DisableBankingAccountMutation, DisableBankingAccountMutationVariables>;

/**
 * __useDisableBankingAccountMutation__
 *
 * To run a mutation, you first call `useDisableBankingAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDisableBankingAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [disableBankingAccountMutation, { data, loading, error }] = useDisableBankingAccountMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useDisableBankingAccountMutation(baseOptions?: Apollo.MutationHookOptions<DisableBankingAccountMutation, DisableBankingAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DisableBankingAccountMutation, DisableBankingAccountMutationVariables>(DisableBankingAccountDocument, options);
      }
export type DisableBankingAccountMutationHookResult = ReturnType<typeof useDisableBankingAccountMutation>;
export type DisableBankingAccountMutationResult = Apollo.MutationResult<DisableBankingAccountMutation>;
export type DisableBankingAccountMutationOptions = Apollo.BaseMutationOptions<DisableBankingAccountMutation, DisableBankingAccountMutationVariables>;