import { MIDDAY } from "../../../ui/CustomTimePicker/constants"
import { MeridiemEnum } from "../../../ui/TimePickerSelect"

export const getMeridium = (time: string | undefined) => {
  if (!time) return undefined

  return Number(time.split(":")[0]) >= MIDDAY
    ? MeridiemEnum.PM
    : MeridiemEnum.AM
}
