import { TokenTypeEnum } from "../AccountDebitCardForm/hookforms.interfaces"

const tokenTypeOptions = [
  {
    value: TokenTypeEnum.BANK_ACCOUNT,
    labelId: "restaurants.bank.accounts.token.type.bank.account",
    defaultMessage: "Bank Account",
  },
  {
    value: TokenTypeEnum.DEBIT_CARD,
    labelId: "restaurants.bank.accounts.token.type.card",
    defaultMessage: "Debit Card",
  },
]

export default tokenTypeOptions
