import * as Types from '../../../../../shared/graphql/generated/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SetupIntegrationMutationVariables = Types.Exact<{
  data: Types.SetupIntegrationInput;
}>;


export type SetupIntegrationMutation = { __typename?: 'Mutation', setupIntegration: { __typename?: 'Integration', accessToken: string, appId: string, externalId: string, source: Types.ExternalSourceEnum, uuid: string } };


export const SetupIntegrationDocument = gql`
    mutation setupIntegration($data: SetupIntegrationInput!) {
  setupIntegration(data: $data) {
    accessToken
    appId
    externalId
    source
    uuid
  }
}
    `;
export type SetupIntegrationMutationFn = Apollo.MutationFunction<SetupIntegrationMutation, SetupIntegrationMutationVariables>;

/**
 * __useSetupIntegrationMutation__
 *
 * To run a mutation, you first call `useSetupIntegrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetupIntegrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setupIntegrationMutation, { data, loading, error }] = useSetupIntegrationMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSetupIntegrationMutation(baseOptions?: Apollo.MutationHookOptions<SetupIntegrationMutation, SetupIntegrationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetupIntegrationMutation, SetupIntegrationMutationVariables>(SetupIntegrationDocument, options);
      }
export type SetupIntegrationMutationHookResult = ReturnType<typeof useSetupIntegrationMutation>;
export type SetupIntegrationMutationResult = Apollo.MutationResult<SetupIntegrationMutation>;
export type SetupIntegrationMutationOptions = Apollo.BaseMutationOptions<SetupIntegrationMutation, SetupIntegrationMutationVariables>;