import type { BasicLocationModel } from "../../../../../shared/graphql/generated/types"
import { DeviceStatusEnum } from "../../../../../shared/graphql/generated/types"
import firstLetterToUpperCase from "../../../../../shared/utils/helpers/firstLetterToUpperCase"
import Badge from "../../../../../ui/Badge"
import Text from "../../../../../ui/Typography/Text"
import DropdownDevices from "../DropdownDevices"
import type { IDeviceRowModel } from "../datatable.interfaces"
import calculateElapsedTimeFromNow from "./calculate-elapsed-time-from-now.util"
import { FormattedMessage } from "react-intl"

const devicesColumns = <T extends IDeviceRowModel>() => {
  const columns = [
    {
      dataIndex: "functionType",
      title: (
        <FormattedMessage
          id="restaurants.devices.datatable.header.function"
          defaultMessage="Function"
        />
      ),
    },
    {
      dataIndex: "deviceKey",
      title: (
        <FormattedMessage
          id="restaurants.devices.datatable.header.device.key"
          defaultMessage="Device Name"
        />
      ),
    },
    {
      dataIndex: "code",
      title: (
        <FormattedMessage
          id="restaurants.devices.datatable.header.device.code"
          defaultMessage="Device Code"
        />
      ),
    },
    {
      dataIndex: "location",
      title: (
        <FormattedMessage
          id="restaurants.devices.datatable.header.location"
          defaultMessage="Location"
        />
      ),
      render: (location: BasicLocationModel) => {
        return location.name
      },
    },
    {
      dataIndex: "lastChecking",
      title: (
        <FormattedMessage
          id="restaurants.devices.datatable.header.last.checkin"
          defaultMessage="Last check in"
        />
      ),
      render: (lastChecking: string) => {
        return <Text size="s">{calculateElapsedTimeFromNow(lastChecking)}</Text>
      },
    },
    {
      dataIndex: "status",
      title: (
        <FormattedMessage
          id="restaurants.devices.datatable.header.status"
          defaultMessage="Status"
        />
      ),
      render: (status: string) => {
        return (
          <Text size="s">
            <Badge
              color={
                status === DeviceStatusEnum.CONNECTED ? "Success5" : "Neutral5"
              }
            />
            {firstLetterToUpperCase(status)}
          </Text>
        )
      },
    },
    {
      dataIndex: "uuid",
      width: "48px",
      render: (_: string, record: T) => {
        return <DropdownDevices record={record} />
      },
    },
  ]

  return [columns]
}

export default devicesColumns
