import * as Types from '../../../../../shared/graphql/generated/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type LocationCateringRuleQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type LocationCateringRuleQuery = { __typename?: 'Query', locationCateringRule?: { __typename?: 'LocationCateringRule', uuid: string, orderScheduling: number, orderMethodAcceptance: Types.OrderMethodAcceptanceEnum, services: Array<{ __typename?: 'CateringServices', uuid: string, type: Types.CateringServiceEnum, minimumAmount?: number | null, feePercentage?: number | null, feeFixedAmount?: number | null }>, prePayment: { __typename?: 'CateringPrePayment', percentage?: number | null, amount?: number | null }, employees: Array<{ __typename?: 'EmployeeBasic', uuid: string, firstName: string, lastName: string, phone: string, deactivatedAt?: any | null }>, cancelPolicy: Array<{ __typename?: 'CateringCancelPolicy', percentage: number, timeBeforeEvent: Types.TimeBeforeEventEnum }> } | null };


export const LocationCateringRuleDocument = gql`
    query locationCateringRule {
  locationCateringRule {
    uuid
    services {
      uuid
      type
      minimumAmount
      feePercentage
      feeFixedAmount
    }
    prePayment {
      percentage
      amount
    }
    orderScheduling
    orderMethodAcceptance
    employees {
      uuid
      firstName
      lastName
      phone
      deactivatedAt
    }
    cancelPolicy {
      percentage
      timeBeforeEvent
    }
  }
}
    `;

/**
 * __useLocationCateringRuleQuery__
 *
 * To run a query within a React component, call `useLocationCateringRuleQuery` and pass it any options that fit your needs.
 * When your component renders, `useLocationCateringRuleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLocationCateringRuleQuery({
 *   variables: {
 *   },
 * });
 */
export function useLocationCateringRuleQuery(baseOptions?: Apollo.QueryHookOptions<LocationCateringRuleQuery, LocationCateringRuleQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LocationCateringRuleQuery, LocationCateringRuleQueryVariables>(LocationCateringRuleDocument, options);
      }
export function useLocationCateringRuleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LocationCateringRuleQuery, LocationCateringRuleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LocationCateringRuleQuery, LocationCateringRuleQueryVariables>(LocationCateringRuleDocument, options);
        }
export type LocationCateringRuleQueryHookResult = ReturnType<typeof useLocationCateringRuleQuery>;
export type LocationCateringRuleLazyQueryHookResult = ReturnType<typeof useLocationCateringRuleLazyQuery>;
export type LocationCateringRuleQueryResult = Apollo.QueryResult<LocationCateringRuleQuery, LocationCateringRuleQueryVariables>;