import * as Types from '../../../../../shared/graphql/generated/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateLocationCateringRulesMutationVariables = Types.Exact<{
  input: Types.CreateLocationCateringRulesInput;
}>;


export type CreateLocationCateringRulesMutation = { __typename?: 'Mutation', createLocationCateringRules: { __typename?: 'LocationCateringRule', uuid: string, orderScheduling: number, orderMethodAcceptance: Types.OrderMethodAcceptanceEnum, services: Array<{ __typename?: 'CateringServices', uuid: string, minimumAmount?: number | null, type: Types.CateringServiceEnum, feePercentage?: number | null, feeFixedAmount?: number | null }>, prePayment: { __typename?: 'CateringPrePayment', percentage?: number | null, amount?: number | null }, employees: Array<{ __typename?: 'EmployeeBasic', uuid: string, phone: string, lastName: string, firstName: string, deactivatedAt?: any | null }>, cancelPolicy: Array<{ __typename?: 'CateringCancelPolicy', percentage: number, timeBeforeEvent: Types.TimeBeforeEventEnum }> } };


export const CreateLocationCateringRulesDocument = gql`
    mutation createLocationCateringRules($input: CreateLocationCateringRulesInput!) {
  createLocationCateringRules(input: $input) {
    uuid
    services {
      uuid
      minimumAmount
      type
      feePercentage
      feeFixedAmount
    }
    prePayment {
      percentage
      amount
    }
    orderScheduling
    orderMethodAcceptance
    employees {
      uuid
      phone
      lastName
      firstName
      deactivatedAt
    }
    cancelPolicy {
      percentage
      timeBeforeEvent
    }
  }
}
    `;
export type CreateLocationCateringRulesMutationFn = Apollo.MutationFunction<CreateLocationCateringRulesMutation, CreateLocationCateringRulesMutationVariables>;

/**
 * __useCreateLocationCateringRulesMutation__
 *
 * To run a mutation, you first call `useCreateLocationCateringRulesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLocationCateringRulesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLocationCateringRulesMutation, { data, loading, error }] = useCreateLocationCateringRulesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateLocationCateringRulesMutation(baseOptions?: Apollo.MutationHookOptions<CreateLocationCateringRulesMutation, CreateLocationCateringRulesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateLocationCateringRulesMutation, CreateLocationCateringRulesMutationVariables>(CreateLocationCateringRulesDocument, options);
      }
export type CreateLocationCateringRulesMutationHookResult = ReturnType<typeof useCreateLocationCateringRulesMutation>;
export type CreateLocationCateringRulesMutationResult = Apollo.MutationResult<CreateLocationCateringRulesMutation>;
export type CreateLocationCateringRulesMutationOptions = Apollo.BaseMutationOptions<CreateLocationCateringRulesMutation, CreateLocationCateringRulesMutationVariables>;