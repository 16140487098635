import yup from "../../../../../shared/yup"
import { yupResolver } from "@hookform/resolvers/yup"

export const EmployeeResolver = yupResolver(
  yup.object().shape({
    firstName: yup.string().required(),
    lastName: yup.string().required(),
    role: yup.string().required(),
    phone: yup
      .string()
      .validatePhoneNumber("Invalid format for phone number")
      .required(),
    code: yup.string().required().length(6),
    payRate: yup.number().moreThan(0).required(),
    attachment: yup.mixed().optional(),
  })
)
