import { useResize } from "../../../../../shared/hooks/useResize"
import { colors } from "../../../../../styles/global/themes/mainTheme"
import Container from "../../../../../ui/Container"
import Icon from "../../../../../ui/Icon"
import Text from "../../../../../ui/Typography/Text"
import RequirementItem from "./RequirementItem"
import {
  ERequirementStatus,
  ERequirementTypes,
  requirements,
} from "./whiteLabelRequirements.mock"
import { Col, Collapse, Progress, Row } from "antd"
import classnames from "classnames"
import React, { useMemo, useState } from "react"
import { useIntl } from "react-intl"
import styled, { css } from "styled-components"

export const WhiteLabelRequirements: React.FC = () => {
  const intl = useIntl()

  const [showProgressLabels, setShowProgressLabels] = useState(true)
  const [showProgressBar, setShowProgressBar] = useState(true)

  const percent = useMemo(() => {
    const filterRequirements = requirements.filter(
      (requirement) => requirement.type === ERequirementTypes.REQUIRED
    )
    const requirementsLength = filterRequirements.length

    // eslint-disable-next-line unicorn/no-array-reduce
    const requirementedCompleted = requirements.reduce((total, requirement) => {
      return (total +=
        requirement.status === ERequirementStatus.COMPLETED ? 1 : 0)
    }, 0)

    return requirementsLength > 0
      ? Math.round((requirementedCompleted * 100) / requirementsLength)
      : 100
  }, [])

  const changeWidth = () => {
    setShowProgressLabels(window.innerWidth > 992)
  }

  useResize(changeWidth)

  const onCollapseChange = (keys: string | string[]) => {
    if (
      (typeof keys === "string" && keys === "progress-collapse-panel") ||
      keys[0] === "progress-collapse-panel"
    ) {
      setShowProgressBar(false)
    } else {
      setShowProgressBar(true)
    }

    return undefined
  }

  return (
    <StyledRequirementsWrapper showProgressLabels={showProgressLabels} hidden>
      <Collapse
        bordered={false}
        onChange={onCollapseChange}
        expandIcon={({ isActive, className }) => (
          <div className={className} role="listitem">
            <Icon
              remixiconClass={classnames({
                ["ri-arrow-down-s-line"]: isActive,
                ["ri-arrow-right-s-line"]: !isActive,
              })}
              size={16}
            />
          </div>
        )}
      >
        <Collapse.Panel
          key="progress-collapse-panel"
          header={
            showProgressBar && (
              <div className="progress-bar-container">
                <Progress
                  percent={percent}
                  type="line"
                  strokeWidth={8}
                  status="normal"
                  strokeColor={colors["Primary5"]}
                />
              </div>
            )
          }
        >
          <StyledCollapsedContainer>
            <Container className="requirements-progress-container">
              <Progress
                percent={percent}
                type="circle"
                width={104}
                strokeWidth={8}
                status="normal"
                strokeColor={colors["Primary5"]}
                gapPosition="right"
              />
              <Container display="flex" flexDirection="column">
                <Text size="m" weight="bold">
                  {intl.formatMessage({
                    id: "settings.white.label.application.requirements.title",
                    defaultMessage: "Complete Your Data",
                  })}
                </Text>
                <Text size="s">
                  {intl.formatMessage({
                    id: "settings.white.label.application.requirements.note",
                    defaultMessage:
                      "*To publish your app, the information must be 100% complete.",
                  })}
                </Text>
              </Container>
            </Container>

            <StyledDivider />

            <Container className="requirements-container">
              <Row className="requirement-row" gutter={[27, 16]}>
                {requirements.map((requirement, rowIndex) => (
                  <Col
                    key={rowIndex}
                    className="requirement-column"
                    sm={24}
                    lg={24}
                    xl={requirements.length === 1 ? 15 : 12}
                    xxl={requirements.length < 3 ? 12 : 8}
                  >
                    <RequirementItem
                      title={requirement.title}
                      isCompleted={
                        requirement.status === ERequirementStatus.COMPLETED
                      }
                      path={requirement.path}
                    />
                  </Col>
                ))}
              </Row>
            </Container>
          </StyledCollapsedContainer>
        </Collapse.Panel>
      </Collapse>
    </StyledRequirementsWrapper>
  )
}

interface StyledRequirementsWrapperProps {
  showProgressLabels: boolean
}

const StyledRequirementsWrapper = styled(
  Container
)<StyledRequirementsWrapperProps>`
  ${({ theme }) => css`
    border-bottom: 1px solid ${theme.colors["Neutral4"]};
  `}

  .requirement-column {
    display: flex;
    align-items: center;
  }

  .requirement-row {
    flex: 1;
  }

  .requirements-container {
    flex: ${({ showProgressLabels }) => (showProgressLabels ? 2 : 3)};
    align-items: center;
    display: flex;
    flex-direction: row;
  }

  .requirements-progress-container {
    flex: ${({ showProgressLabels }) => (showProgressLabels ? 1 : 2)};
    gap: 24px;
    align-items: center;
    display: flex;
    flex-direction: ${({ showProgressLabels }) =>
      showProgressLabels ? "row" : "column"};
    justify-content: center;
  }

  .ant-collapse-header {
    display: flex;
    align-items: center;
  }

  .progress-bar-container {
    flex: 1;
  }

  .ant-collapse
    > .ant-collapse-item
    > .ant-collapse-header
    .ant-collapse-arrow {
    display: flex;
  }
`

const StyledDivider = styled.div`
  ${({ theme }) => css`
    border-right: 2px solid ${theme.colors["TransparentDark"]};
  `}
`

const StyledCollapsedContainer = styled(Container)`
  display: flex;
  flex-direction: row;
  padding: 28px 40px;
  gap: 27px;
`
