import { Skeleton, Stack } from "@mantine/core"

export const BankAccountTableSkeleton = () => {
  return (
    <Stack>
      <Skeleton height={70} />
      <Skeleton height={70} />
      <Skeleton height={70} />
    </Stack>
  )
}
