import { ActionTypes } from "../../../../../pages/Login/actions"
import { useGeneralContext } from "../../../../../shared/contexts/StoreProvider"
import paths from "../../../../../shared/routes/paths"
import storage from "../../../../../shared/utils/storage"
import Text from "../../../../Typography/Text"
import React, { useEffect } from "react"
import { useIntl } from "react-intl"
import { NavLink, useHistory } from "react-router-dom"
import styled, { css } from "styled-components"

export const Logout: React.FC = () => {
  const history = useHistory()
  const { dispatch } = useGeneralContext()
  const intl = useIntl()

  const removeHistoryListener = history.listen((location) => {
    if (location.pathname === paths.guest.login) {
      storage.clear()
      dispatch({ type: ActionTypes.Logout })
    }
  })

  useEffect(() => {
    return () => {
      removeHistoryListener()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <NavLink to="/login">
      <StyledText size="s">
        {intl.formatMessage({
          id: "generic.logout",
          defaultMessage: "Log Out",
        })}
      </StyledText>
    </NavLink>
  )
}

const StyledText = styled(Text)`
  ${({ theme }) => css`
    color: ${theme.colors["Neutral6"]};
  `};
`
