import { getTimeOptions } from "../../pages/Settings/Locations/HoursOfOperation/BusinessHours/utils/getTimeOptions"
import type { ScheduleType } from "../../shared/types/schedule.types"
import Container from "../Container"
import RadioGroupButton from "../Radio/RadioGroupButton"
import Select from "../Select"
import { MeridiemEnum } from "../TimePickerSelect"
import type { RadioChangeEvent } from "antd"
import { isEqual } from "lodash"
import React, { useMemo, useRef } from "react"
import { useIntl } from "react-intl"
import styled, { css } from "styled-components"

type TimeSelectProps = {
  value: ScheduleType
  defaultValue?: ScheduleType
  timeOptions?: Array<{ label: string; value: string }>
  onChange: (value: ScheduleType) => void
  onClear?: () => void
  hasError?: boolean
  helperText?: string
  disabled?: boolean
  allowAllDay?: boolean
  disableMeridiem?: boolean
  allowClear?: boolean
  width?: string
  wrapperWidth?: string
  meridiemVisible?: boolean
  includeMidnight?: boolean
}

const ALL_DAY_VALUE = "all"
const MIDNIGHT_VALUE = { value: "23:59", label: "Midnight" }
const MIDNIGHT_VALUE_PARSED = { time: "11:59", meridium: "PM" }
export const TimeSelect: React.FC<TimeSelectProps> = ({
  value,
  defaultValue,
  timeOptions,
  onChange,
  onClear,
  hasError,
  helperText,
  disabled,
  allowAllDay,
  disableMeridiem,
  wrapperWidth,
  width = "160px",
  allowClear = true,
  meridiemVisible = false,
  includeMidnight = false,
}) => {
  const intl = useIntl()

  const hideMeridiem = meridiemVisible || value.time !== "all"
  const isMidnight = isEqual(MIDNIGHT_VALUE_PARSED, value)

  const allDayOption = useMemo(
    () => ({
      value: ALL_DAY_VALUE,
      label: intl.formatMessage({
        id: "restaurants.locations.hours.of.operation.all.option",
        defaultMessage: "All Day",
      }),
    }),
    [intl]
  )

  const defaultOptions = useMemo(() => {
    const items = allowAllDay
      ? [allDayOption, ...getTimeOptions()]
      : getTimeOptions()

    includeMidnight && items.push(MIDNIGHT_VALUE)

    return items
  }, [allDayOption, allowAllDay, includeMidnight])

  const scheduleRef = useRef({ ...value })

  scheduleRef.current = { ...value }

  const handleMeridiumChange = (event: RadioChangeEvent) => {
    const meridium = event.target.value as MeridiemEnum

    scheduleRef.current.meridium = meridium
    onChange(scheduleRef.current)
  }

  const handleTimeChange = (time: string) => {
    if (!time) {
      scheduleRef.current = { time: undefined, meridium: undefined }

      return
    }

    scheduleRef.current.time = time

    onChange(scheduleRef.current)
  }

  return (
    <Container display="flex" gap="9px">
      <Select
        width={width}
        wrapperWidth={wrapperWidth}
        placeholder={intl.formatMessage({
          id: "restaurants.locations.hours.of.operation.select.label",
          defaultMessage: "Select Time",
        })}
        defaultValue={defaultValue?.time}
        value={isMidnight ? MIDNIGHT_VALUE.label : value.time}
        options={timeOptions ?? defaultOptions}
        onChange={handleTimeChange}
        onClear={onClear}
        filterOption={(input, option) =>
          (option?.value).toLowerCase().includes(input.toLowerCase())
        }
        hasError={hasError}
        helperText={helperText}
        disabled={disabled}
        allowClear={allowClear}
        allowSearch
        hideSearchIcon
      />

      <StyledContRadio visible={!hideMeridiem}>
        <RadioGroupButton
          items={Object.keys(MeridiemEnum).map((key) => ({
            value: key,
            label: key,
          }))}
          size="small"
          defaultValue={defaultValue?.meridium}
          value={value.meridium}
          onChange={handleMeridiumChange}
          disabled={disabled || disableMeridiem}
        />
      </StyledContRadio>
    </Container>
  )
}

const StyledContRadio = styled(Container)<{ visible: boolean }>`
  ${({ visible }) => css`
    ${visible ? "visibility: hidden;" : ""};
  `}
`
