import { usePortal } from "../../shared/hooks/usePortal"
import Button from "../Button"
import Container from "../Container"
import Icon from "../Icon"
import Text from "../Typography/Text"
import type { ModalProps } from "antd"
import { Modal as AntModal } from "antd"
import React from "react"
import { useIntl } from "react-intl"
import styled, { css } from "styled-components"

export interface IModalProps extends Omit<ModalProps, "okText" | "cancelText"> {
  title?: React.ReactNode | string
  okText?: string
  cancelText?: string
  id?: string
  shouldCloseOnClickOutside?: boolean
  closeButton?: React.ReactNode
  enableCloseButton?: boolean
}

export const Modal: React.FC<IModalProps> = ({
  enableCloseButton = true,
  onCancel,
  onOk,
  okButtonProps,
  okText,
  cancelText,
  shouldCloseOnClickOutside,
  closeButton,
  ...props
}) => {
  const intl = useIntl()

  const container = usePortal("modal-container")

  const onCancelModal = (event: React.MouseEvent<HTMLElement>) => {
    shouldCloseOnClickOutside && onCancel && onCancel(event)
  }

  const title = () => {
    if (!props.title) {
      return
    }

    const titleText = (
      <Text size="m" weight="bold" textAlign="center">
        {props.title}
      </Text>
    )

    return closeButton ? (
      <Container display="flex" justifyContent="flex-end" alignItems="center">
        <Container
          display="flex"
          alignItems="center"
          minWidth="536px"
          justifyContent="space-between"
        >
          {titleText}
          {closeButton}
        </Container>
      </Container>
    ) : (
      titleText
    )
  }

  const footer = () => {
    return (
      <Container
        display="flex"
        justifyContent="flex-end"
        gap="8px"
        padding="6px 8px"
      >
        <Button
          title={
            cancelText ||
            intl.formatMessage({
              id: "components.modal.cancel.button",
              defaultMessage: "Cancel",
            })
          }
          disabled={okButtonProps?.loading ? true : false}
          hierarchy="secondary"
          onClick={onCancel}
        />
        <Button
          disabled={okButtonProps?.disabled || !!okButtonProps?.loading}
          loading={!!okButtonProps?.loading}
          title={
            okText ||
            intl.formatMessage({
              id: "components.modal.ok.button",
              defaultMessage: "OK",
            })
          }
          onClick={onOk}
        />
      </Container>
    )
  }

  return (
    <StyledModal
      footer={footer()}
      enableCloseButton={enableCloseButton}
      {...props}
      closeIcon={
        enableCloseButton ? (
          <Container
            width="100%"
            height="100%"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Icon
              remixiconClass="ri-close-line"
              size={24}
              color="Neutral6"
              onClick={onCancel}
            />
          </Container>
        ) : null
      }
      title={title()}
      closable={closeButton ? false : props.closable}
      getContainer={container}
      onCancel={onCancelModal}
    />
  )
}

const StyledModal = styled(AntModal)<{ enableCloseButton: boolean }>`
  .ant-modal-content {
    border-radius: 4px;
  }

  .ant-modal-title {
    text-align: center;
  }

  .ant-modal-close-x {
    display: ${({ enableCloseButton }) =>
      enableCloseButton ? "block" : "none"};
  }

  .ant-modal-header {
    border-radius: 4px;
    ${({ theme }) => css`
      background: ${theme.colors["Neutral1"]};
    `}
  }
`
