import * as Types from '../../../../../../shared/graphql/generated/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateManyDeliveryLocationsMutationVariables = Types.Exact<{
  data: Types.CreateDeliveryLocationManyInput;
}>;


export type CreateManyDeliveryLocationsMutation = { __typename?: 'Mutation', createManyDeliveryLocations: Array<{ __typename?: 'DeliveryLocation', uuid: string, isDefault: boolean, deliveryIntegration: { __typename?: 'RDeliveryIntegration', uuid: string, apiKey: string, developerId?: string | null, integrationLabel?: string | null, integrationName: Types.DeliveryIntegrationEnum, isActive: boolean, issues?: string | null, settings?: any | null, signingSecret?: string | null } }> };


export const CreateManyDeliveryLocationsDocument = gql`
    mutation createManyDeliveryLocations($data: CreateDeliveryLocationManyInput!) {
  createManyDeliveryLocations(data: $data) {
    uuid
    isDefault
    deliveryIntegration {
      uuid
      apiKey
      developerId
      integrationLabel
      integrationName
      isActive
      issues
      settings
      signingSecret
    }
  }
}
    `;
export type CreateManyDeliveryLocationsMutationFn = Apollo.MutationFunction<CreateManyDeliveryLocationsMutation, CreateManyDeliveryLocationsMutationVariables>;

/**
 * __useCreateManyDeliveryLocationsMutation__
 *
 * To run a mutation, you first call `useCreateManyDeliveryLocationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateManyDeliveryLocationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createManyDeliveryLocationsMutation, { data, loading, error }] = useCreateManyDeliveryLocationsMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateManyDeliveryLocationsMutation(baseOptions?: Apollo.MutationHookOptions<CreateManyDeliveryLocationsMutation, CreateManyDeliveryLocationsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateManyDeliveryLocationsMutation, CreateManyDeliveryLocationsMutationVariables>(CreateManyDeliveryLocationsDocument, options);
      }
export type CreateManyDeliveryLocationsMutationHookResult = ReturnType<typeof useCreateManyDeliveryLocationsMutation>;
export type CreateManyDeliveryLocationsMutationResult = Apollo.MutationResult<CreateManyDeliveryLocationsMutation>;
export type CreateManyDeliveryLocationsMutationOptions = Apollo.BaseMutationOptions<CreateManyDeliveryLocationsMutation, CreateManyDeliveryLocationsMutationVariables>;