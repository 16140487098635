import { TokenCurrencyEnum } from "../AccountDebitCardForm/hookforms.interfaces"

const currencyOptions = Object.keys(TokenCurrencyEnum).map((label) => {
  return {
    value: TokenCurrencyEnum[label as keyof typeof TokenCurrencyEnum],
    label,
  }
})

export default currencyOptions
