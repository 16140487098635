import type { ItemCardModel } from "../../../../../../components/ItemCard"
import ItemCard from "../../../../../../components/ItemCard"
import { useGeneralContext } from "../../../../../../shared/contexts/StoreProvider"
import type { DeliveryIntegrationEnum } from "../../../../../../shared/graphql/generated/types"
import paths from "../../../../../../shared/routes/paths"
import { CardSkeleton } from "../../../../../../ui/Card/Card.skeleton"
import Container from "../../../../../../ui/Container"
import EmptyState from "../../../../../../ui/EmptyState"
import Input from "../../../../../../ui/Inputs/Input"
import type { IModalProps } from "../../../../../../ui/Modal"
import Modal from "../../../../../../ui/Modal"
import RoleView from "../../../../../../ui/RoleView"
import Spacer from "../../../../../../ui/Spacer"
import HighlightedText from "../../../../../../ui/Typography/HighlightedText"
import Text from "../../../../../../ui/Typography/Text"
import { useGetDeliveryIntegrationsByRestaurantQuery } from "../../../../Restaurant/DeliveryIntegration/GraphQL/getDeliveryIntegrationsByRestaurant.generated"
import type { IDeliveryIntegrationsTableModel } from "../../../../Restaurant/DeliveryIntegration/interfaces/deliveryIntegrationsTable.interfaces"
import { DeliveryProviderLogos } from "../../../../Restaurant/DeliveryIntegration/utils/providers-config.util"
import type { ILocationDeliveryParams } from "../../hookforms.interfaces"
import type { CheckboxChangeEvent } from "antd/lib/checkbox/Checkbox"
import get from "lodash/get"
import unionBy from "lodash/unionBy"
import React, { useEffect, useState } from "react"
import { useIntl } from "react-intl"
import { useHistory, useParams } from "react-router-dom"
import styled from "styled-components"

type ItemCardDeliveryModel = ItemCardModel & {
  delivery: `${DeliveryIntegrationEnum}`
}

export interface ISearchDeliveryIntegrationsModal
  extends Omit<IModalProps, "onOk"> {
  onSave: (
    selectedItems: Array<ItemCardDeliveryModel>,
    removedItems?: Array<ItemCardDeliveryModel>
  ) => void
  selectedItems: Array<ItemCardDeliveryModel>
}

export const SearchDeliveryIntegrationsModal: React.FC<ISearchDeliveryIntegrationsModal> =
  ({ selectedItems, onSave, ...props }) => {
    const intl = useIntl()

    const { locationUUID } = useParams<ILocationDeliveryParams>()
    const { push } = useHistory()

    const [value, setValue] = useState("")
    const [deliveryIntegrations, setDeliveryIntegrations] = useState<
      ItemCardDeliveryModel[]
    >([])
    const [searching, setSearching] = useState<boolean>()
    const [selectedCards, setSelectedCards] = useState<
      Array<ItemCardDeliveryModel>
    >([])
    const [removedCards, setRemovedCards] = useState<
      Array<ItemCardDeliveryModel>
    >([])

    const {
      state: {
        currentRestaurant: { uuid: restaurantUUID },
      },
    } = useGeneralContext()

    const { data, loading, called } =
      useGetDeliveryIntegrationsByRestaurantQuery({
        variables: {
          restaurantUUID,
          locationUUID,
        },
        skip: !restaurantUUID || !locationUUID,
        notifyOnNetworkStatusChange: true,
      })

    const deliveryIntegrationList: IDeliveryIntegrationsTableModel[] = get(
      data,
      "getDeliveryIntegrationsByRestaurant",
      []
    )

    const searchDeliveryIntegration = (
      event: React.ChangeEvent<HTMLInputElement>
    ) => {
      //TODO: feature/api-1302-add-name-filter-to getDeliveryIntegrationsByRestaurant
      setSearching(true)
      const filteredDeliveryIntegrationName = event.target.value

      if (filteredDeliveryIntegrationName === "") {
        setDeliveryIntegrations(
          deliveryIntegrationList.map((delivery) => {
            return {
              uuid: delivery.uuid,
              name: delivery.integrationLabel,
              delivery: delivery.integrationName,
            }
          })
        )
      } else {
        const results = deliveryIntegrations.filter((location) => {
          return location.name
            ?.toLocaleLowerCase()
            .includes(filteredDeliveryIntegrationName.toLowerCase())
        })

        setDeliveryIntegrations(results)
      }
      setValue(filteredDeliveryIntegrationName)
      setSearching(false)
    }

    const redirectToNewItem = () => push(paths.settings.restaurant.delivery)

    const onCheckCard = (
      event: CheckboxChangeEvent | React.MouseEvent<HTMLDivElement, MouseEvent>,
      itemCardModel: ItemCardDeliveryModel
    ) => {
      if (!itemCardModel.uuid) return

      const deliveryServicePresent = selectedCards.some(
        (deliveryService) => deliveryService.uuid === itemCardModel.uuid
      )

      if (deliveryServicePresent) {
        setSelectedCards((prev) => {
          return prev.filter(({ uuid }) => uuid !== itemCardModel.uuid)
        })

        setRemovedCards((prev) => {
          return [
            ...prev.filter(({ uuid }) => uuid !== itemCardModel.uuid),
            itemCardModel,
          ]
        })
      } else {
        setSelectedCards((prev) => {
          return [
            ...prev.filter(({ uuid }) => uuid !== itemCardModel.uuid),
            itemCardModel,
          ]
        })

        setRemovedCards((prev) => {
          return prev.filter(({ uuid }) => uuid !== itemCardModel.uuid)
        })
      }
    }

    const onSaveData = () => {
      const currentState = unionBy(selectedItems, selectedCards, "uuid").filter(
        (defaultItem) => {
          return !removedCards.some(({ uuid }) => uuid === defaultItem.uuid)
        }
      )

      onSave(currentState, removedCards)
      setSelectedCards([])
      setRemovedCards([])
    }

    useEffect(() => {
      if (deliveryIntegrationList.length > 0) {
        setDeliveryIntegrations(
          deliveryIntegrationList.map((delivery) => {
            return {
              uuid: delivery.uuid,
              name: delivery.integrationLabel,
              delivery: delivery.integrationName,
            }
          })
        )
      }
    }, [deliveryIntegrationList])

    const skeleton = <CardSkeleton cards={2} loading />

    return (
      <Modal {...props} closable={false} onOk={onSaveData} destroyOnClose>
        <Input
          placeholder={intl.formatMessage({
            id: "settings.locations.delivery.search.integrations.modal.placeholder",
            defaultMessage: "Search Delivery Integrations",
          })}
          onChange={searchDeliveryIntegration}
          loading={searching}
          value={value}
          disabled={deliveryIntegrationList.length === 0 && !loading}
          searchIcon
        />

        <Spacer size={24} />
        <StyledModalBody id="search-items-modal">
          {deliveryIntegrationList.length === 0 && called && !loading && (
            <Container centered display="flex" flexDirection="column">
              <EmptyState
                title=""
                description={intl.formatMessage({
                  id: "settings.locations.delivery.search.integrations.modal.not.found.title",
                  defaultMessage: "Sorry, we did not find any results",
                })}
              />
              <RoleView
                allowed={["RESTAURANT_OWNER", "RESTAURANT_ADMIN"]}
                extra={
                  <Text size="s">
                    {intl.formatMessage({
                      id: "settings.locations.delivery.search.integrations.modal.not.found.description.restricted",
                      defaultMessage:
                        "Contact your admin to add a delivery provider",
                    })}
                  </Text>
                }
              >
                <Text size="s">
                  {intl.formatMessage({
                    id: "settings.locations.delivery.search.integrations.modal.not.found.description",
                    defaultMessage: "You can create a new delivery provider ",
                  })}
                  <HighlightedText
                    size="s"
                    weight="regular"
                    cursor="pointer"
                    onClick={redirectToNewItem}
                  >
                    {intl.formatMessage({
                      id: "settings.locations.delivery.search.integrations.modal.not.found.link",
                      defaultMessage: "here",
                    })}
                  </HighlightedText>
                </Text>
              </RoleView>
            </Container>
          )}

          {loading && skeleton}

          {deliveryIntegrations.map((item) => (
            <ItemCard
              key={item.uuid}
              id={item.uuid}
              title={item.name || ""}
              src={DeliveryProviderLogos[item.delivery]}
              bordered={false}
              imageFit="contain"
              checked={
                !!unionBy(selectedItems, selectedCards, "uuid").some(
                  ({ uuid }) => uuid === item.uuid
                )
              }
              onCheckCard={(
                event:
                  | CheckboxChangeEvent
                  | React.MouseEvent<HTMLDivElement, MouseEvent>,
                itemCardModel: ItemCardModel
              ) => {
                onCheckCard(event, {
                  ...itemCardModel,
                  delivery: item.delivery,
                })
              }}
              checkable
            />
          ))}
        </StyledModalBody>
      </Modal>
    )
  }

const StyledModalBody = styled.div`
  overflow-y: auto;
  max-height: 400px;
  min-height: 200px;
`
