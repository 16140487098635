export const datePickerFormat = "MM-DD-YY"
export const YYYY_MM_DD = "YYYY-MM-DD"
export const MM_DD_YYYY = "MM-DD-YYYY"
export const ddd_D = "ddd D"
export const isoStringDateFormat = "MM/DD/YY"
export const isoStringHoursFormat = "HH:mm A"
export const hh_mm_a = "hh:mm a"
export const hh_mm_A = "hh:mm A"
export const HH_mm = "HH:mm"
export const MMM_Do_YYYY_h_mm_ss_a = "MMM Do, YYYY [at] hh:mm:ss A"
export const DD_MM_YYYY_h_mm_ss_a = "DD/MM/YYYY - hh:mm:ss a"
export const hh_mm = "hh:mm"
export const ALL_DAY_VALUE = "all"
export const HH = "HH"
export const mm = "mm"
export const UNIX_TIMESTAMP_ID = "x"
export const LL = "LL"
