import type { SkeletonProps } from "antd"
import { Card, Skeleton } from "antd"
import range from "lodash/range"
import React from "react"
import styled from "styled-components"

interface CardSkeletonProps extends SkeletonProps {
  cards?: number
}

export const CardSkeleton: React.FC<CardSkeletonProps> = ({
  children,
  cards = 5,
  ...props
}) => {
  return (
    <StyledCardWrapperSkeleton className="card-skeleton" role="card-skeleton">
      {range(cards).map((index) => (
        <StyledCardSkeleton key={index}>
          <Skeleton
            key={index}
            title={false}
            paragraph={{ rows: 2, width: ["20%", "50%"] }}
            active
            {...props}
          >
            {children}
          </Skeleton>
        </StyledCardSkeleton>
      ))}
    </StyledCardWrapperSkeleton>
  )
}

const StyledCardWrapperSkeleton = styled.div`
  &.card-skeleton {
    .ant-skeleton-content .ant-skeleton-paragraph > li {
      height: 12px;
    }
  }
`

const StyledCardSkeleton = styled(Card)`
  padding: 7px 23px 7px 15px;
  margin-bottom: 16px;
  border-radius: 4px;
  height: 56px;

  .ant-card-body {
    padding: 0;
  }

  .ant-skeleton-header {
    padding-right: 8px;
  }

  .ant-skeleton.ant-skeleton-active .ant-skeleton-avatar {
    border-radius: 4px;
  }
`
