import firstLetterToUpperCase from "../../../../../shared/utils/helpers/firstLetterToUpperCase"
import Button from "../../../../../ui/Button"
import Container from "../../../../../ui/Container"
import type { IModalProps } from "../../../../../ui/Modal"
import Modal from "../../../../../ui/Modal"
import Spacer from "../../../../../ui/Spacer"
import CheckableTag from "../../../../../ui/Tag/CheckableTag"
import Text from "../../../../../ui/Typography/Text"
import type {
  IBusinessHoursForm,
  IBussinessHourType,
} from "../hookforms.interfaces"
import { CustomDaysOfTheWeekEnum } from "./days-of-the-week.enum"
import React, { useState } from "react"
import { useFormContext } from "react-hook-form"
import { useIntl } from "react-intl"
import styled from "styled-components"

type DuplicateTimesModalProps = Omit<IModalProps, "onCancel"> & {
  onClose: () => void
  dayCopy: string
  schedulesCopy: Array<IBussinessHourType> | undefined
}

export const DuplicateTimesModal: React.FC<DuplicateTimesModalProps> = ({
  onClose,
  dayCopy,
  schedulesCopy,
  ...props
}) => {
  const intl = useIntl()
  const [selectedDays, setSelectedDays] = useState<
    Array<{ day: string; index: number }>
  >([])
  const { setValue } = useFormContext<IBusinessHoursForm>()

  const daysOfTheWeek = Object.keys(
    CustomDaysOfTheWeekEnum
  ) as Array<`${CustomDaysOfTheWeekEnum}`>

  const handleChange = (
    selectedDay: string,
    checked: boolean,
    dayIndex: number
  ) => {
    if (selectedDays) {
      const nextselectedDays = checked
        ? [...selectedDays, { day: selectedDay, index: dayIndex }]
        : selectedDays.filter(({ day }) => day !== selectedDay)

      setSelectedDays(nextselectedDays)
    }
  }

  const handleClose = () => {
    onClose()
    setSelectedDays([])
  }

  const handleCopy = () => {
    selectedDays.forEach((daySelected) => {
      if (daySelected) {
        setValue(`schedules.${daySelected.index}.defaultValues`, schedulesCopy)
      }
    })

    setSelectedDays([])
    onClose()
  }

  return (
    <Modal
      onCancel={handleClose}
      closable={false}
      shouldCloseOnClickOutside
      {...props}
      footer={
        <Container
          display="flex"
          justifyContent="flex-end"
          padding="4px 0"
          gap="8px"
        >
          <Button
            title={intl.formatMessage({
              id: "components.modal.cancel.button",
              defaultMessage: "Cancel",
            })}
            hierarchy="secondary"
            onClick={handleClose}
          />

          <Button
            disabled={selectedDays.length === 0}
            title={intl.formatMessage({
              id: "restaurants.locations.hours.of.operation.copy.modal.confirm.button",
              defaultMessage: "Copy",
            })}
            onClick={handleCopy}
          />
        </Container>
      }
      title={intl.formatMessage({
        id: "restaurants.locations.hours.of.operation.copy.modal.title",
        defaultMessage: "Copy times",
      })}
      destroyOnClose
    >
      <StyledDuplicateDuplicateTimes>
        <Spacer size={16} />
        <Text size="s" textAlign="center">
          {intl.formatMessage({
            id: "restaurants.locations.hours.of.operation.copy.modal.description",
            defaultMessage: "Select the days you want to copy this times to:",
          })}
        </Text>
        <Spacer size={32} />
        <Container
          display="flex"
          justifyContent="center"
          flexWrap="wrap"
          gap="18px"
        >
          {daysOfTheWeek.map((day, dayIndex) => (
            <>
              {day !== dayCopy && (
                <CheckableTag
                  key={day}
                  onChange={(checked) => handleChange(day, checked, dayIndex)}
                  checked={
                    selectedDays.findIndex((select) => select.day === day) > -1
                  }
                  size="small"
                >
                  {firstLetterToUpperCase(day)?.slice(0, 2)}
                </CheckableTag>
              )}
            </>
          ))}
        </Container>
        <Spacer size={16} />
      </StyledDuplicateDuplicateTimes>
    </Modal>
  )
}

const StyledDuplicateDuplicateTimes = styled(Container)`
  margin: 0 16px;
`
