import _colors from "../../design-tokens/json/colors.json"
import designTokenTypography from "../../design-tokens/json/typography.json"

export const colors = { ..._colors, transparent: "transparent", white: "white" }

export const shadows = {
  elevationLow: "0px 1px 0px rgba(240,240,240,1)",
  elevationMedium: "0px 3px 8px rgba(0, 0, 0, 0.12)",
  elevationHigh: "0px 7px 16px rgba(0, 0, 0, 0.12)",
}

export const spacing = {
  cornerRadiusSmall: "4px",
}

export const text = {
  fonts: {
    ralewayRegular: "Raleway Regular, sans-serif",
    ralewayMedium: "Raleway Medium, sans-serif",
    ralewaySemiBold: "Raleway Semi-Bold, sans-serif",
    dmSansRegular: "DM Sans, sans-serif",
    dmSansBold: "DM Sans Bold, sans-serif",
    // dmSansMedium: 'DM Sans Medium, sans-serif',
  },
  opacity: {
    primary: "0.85",
    secondary: "0.45",
    disabled: "0.25",
  },
  ...designTokenTypography.typography,
}

const sizing = {
  button: {
    large: "360px",
  },
  input: {
    large: "360px",
  },
  sidebar: "208px",
}

const mainTheme = {
  colors,
  shadows,
  spacing,
  text,
  sizing,
}

export default mainTheme
