import { useGeneralContext } from "../../../../shared/contexts/StoreProvider"
import { SubscriptionTierEnum } from "../../../../shared/graphql/generated/types"
import notification from "../../../../ui/notification"
import GetPlanConfirmModal from "./GetPlanConfirmModal"
import { useGetBillingSubscriptionPlansQuery } from "./GraphQL/getBillingSubscriptionPlans.generated"
import { useGetRestaurantSubscriptionQuery } from "./GraphQL/getRestaurantSubscription.generated"
import PlanCard from "./PlanCard/PlanCard"
import {
  MILISECONDS_IN_A_SECOND,
  SECONDS_IN_A_DAY,
  SUCCESS_QUERY_PARAM,
  addDays,
} from "./plans.utils"
import React, { useEffect, useMemo, useState } from "react"
import { useIntl } from "react-intl"
import { useHistory, useLocation } from "react-router-dom"
import styled from "styled-components"

export const Plans: React.FC = () => {
  const { search } = useLocation()
  const { replace } = useHistory()
  const intl = useIntl()

  const searchParams = useMemo(() => new URLSearchParams(search), [search])
  const isStripeRedirection = searchParams.get(SUCCESS_QUERY_PARAM) === "true"
  const {
    state: {
      currentRestaurant: { uuid },
    },
  } = useGeneralContext()

  const { data: subscriptionResponse } = useGetRestaurantSubscriptionQuery({
    variables: { uuid },
  })
  const subscriptionStatus =
    subscriptionResponse?.getRestaurantSubscription.status
  const hasSubscription = !!subscriptionStatus
  const { data: plansResponse } = useGetBillingSubscriptionPlansQuery()

  const allPlansData = plansResponse?.getBillingSubscriptionPlans

  const subscriptionData = subscriptionResponse?.getRestaurantSubscription

  const currentPlanData = allPlansData?.find(
    (plan) => plan.stLookUpKey === SubscriptionTierEnum.STANDARD
  )

  const daysLeftInSeconds =
    Math.abs(new Date(subscriptionData?.trialEndDate).getTime() - Date.now()) /
    MILISECONDS_IN_A_SECOND
  const daysLeft = Math.ceil(daysLeftInSeconds / SECONDS_IN_A_DAY)

  const [isGetPlanConfirmModalOpen, setIsGetPlanConfirmModalOpen] =
    useState<boolean>(false)

  const openGetPlanConfirmModal = () => setIsGetPlanConfirmModalOpen(true)
  const closeGetPlanConfirmModal = () => setIsGetPlanConfirmModalOpen(false)

  useEffect(() => {
    if (isStripeRedirection && hasSubscription) {
      notification({
        type: "success",
        description: intl.formatMessage({
          defaultMessage: "You have gotten the Standard plan",
          id: "settings.restaurant.plans.successful.subscription.notification",
        }),
      })
      searchParams.delete(SUCCESS_QUERY_PARAM)
      replace({ search: searchParams.toString() })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscriptionStatus])

  return (
    <>
      <MainContainer>
        {hasSubscription && currentPlanData ? (
          <PlanCard
            subscriptionStatus={subscriptionStatus}
            days={daysLeft}
            features={currentPlanData.features}
            name={currentPlanData.name}
            price={currentPlanData.price}
          />
        ) : (
          allPlansData?.map((plan) => (
            <PlanCard
              key={plan.uuid}
              features={plan.features}
              name={plan.name}
              price={plan.price}
              days={plan.trialDays}
              openGetPlanConfirmModal={openGetPlanConfirmModal}
            />
          ))
        )}
      </MainContainer>

      {currentPlanData && (
        <GetPlanConfirmModal
          isOpen={isGetPlanConfirmModalOpen}
          handleClose={closeGetPlanConfirmModal}
          planData={{
            name: currentPlanData.name,
            price: currentPlanData.price,
            date: addDays(new Date(), currentPlanData.trialDays),
          }}
        />
      )}
    </>
  )
}

const MainContainer = styled.div`
  padding: 64px;
  overflow-y: auto;
  height: calc(100vh - 48px);
  display: flex;
  flex-direction: column;
  align-items: center;
`
