import Button from "../Button"
import type { ButtonProps } from "../Button/Button"
import React from "react"

export const AddIcon: React.FC<ButtonProps> = (props) => {
  return (
    <Button
      {...props}
      hierarchy="tertiary"
      leadingIcon="ri-add-line"
      shape="square"
      transparent
    />
  )
}
