import * as Types from '../../../../../../shared/graphql/generated/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateRestaurantMemberMutationVariables = Types.Exact<{
  data: Types.UpdateMemberInput;
}>;


export type UpdateRestaurantMemberMutation = { __typename?: 'Mutation', updateRestaurantMember: { __typename?: 'MemberWithInviteAndLocationsModel', userUUID?: string | null, restaurantUUID: string, inviteUUID: string, email: string, firstName: string, inviteStatus: Types.AdminInviteStatusEnum, lastName: string, roleName: Types.AdminRoleNameEnum, locations: Array<{ __typename?: 'LocationBasicModel', uuid: string, name: string }> } };


export const UpdateRestaurantMemberDocument = gql`
    mutation updateRestaurantMember($data: UpdateMemberInput!) {
  updateRestaurantMember(data: $data) {
    userUUID
    restaurantUUID
    inviteUUID
    email
    firstName
    inviteStatus
    lastName
    locations {
      uuid
      name
    }
    roleName
  }
}
    `;
export type UpdateRestaurantMemberMutationFn = Apollo.MutationFunction<UpdateRestaurantMemberMutation, UpdateRestaurantMemberMutationVariables>;

/**
 * __useUpdateRestaurantMemberMutation__
 *
 * To run a mutation, you first call `useUpdateRestaurantMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRestaurantMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRestaurantMemberMutation, { data, loading, error }] = useUpdateRestaurantMemberMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateRestaurantMemberMutation(baseOptions?: Apollo.MutationHookOptions<UpdateRestaurantMemberMutation, UpdateRestaurantMemberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateRestaurantMemberMutation, UpdateRestaurantMemberMutationVariables>(UpdateRestaurantMemberDocument, options);
      }
export type UpdateRestaurantMemberMutationHookResult = ReturnType<typeof useUpdateRestaurantMemberMutation>;
export type UpdateRestaurantMemberMutationResult = Apollo.MutationResult<UpdateRestaurantMemberMutation>;
export type UpdateRestaurantMemberMutationOptions = Apollo.BaseMutationOptions<UpdateRestaurantMemberMutation, UpdateRestaurantMemberMutationVariables>;