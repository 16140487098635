import { ThemeProvider } from "../../shared/contexts/ThemeProvider"
import Icon from "../Icon"
import type { IconColor } from "../Icon"
import {
  NotificationStyles,
  NotificationTitle,
} from "../notification/notification"
import type { AlertProps } from "antd"
import { Alert as AntdAlert } from "antd"
import React from "react"
import styled, { css } from "styled-components"

export type CustomAlertProps = {
  message: string | React.ReactNode
  type: "success" | "info" | "warning" | "error"
}

const iconConfig: Record<string, { remixiconClass: string; color: IconColor }> =
  {
    success: { remixiconClass: "ri-checkbox-circle-line", color: "Success4" },
    error: { remixiconClass: "ri-close-circle-line", color: "Danger5" },
    warning: { remixiconClass: "ri-error-warning-line", color: "Warning5" },
    info: { remixiconClass: "ri-information-line", color: "Primary5" },
  }

export const Alert: React.FC<CustomAlertProps & AlertProps> = ({
  message,
  type,
  description,
  showIcon = false,
}) => {
  return (
    <StyledAntdAlert
      message={message ?? NotificationTitle(type)}
      description={description && <ThemeProvider>{description}</ThemeProvider>}
      type={type}
      icon={
        <Icon
          classes="alert-icon"
          remixiconClass={iconConfig[type].remixiconClass}
          color={iconConfig[type].color}
          size={20}
        />
      }
      showIcon={showIcon}
      style={NotificationStyles(type)}
    />
  )
}

const StyledAntdAlert = styled(AntdAlert)`
  ${({ description }) => css`
    padding: 16px;

    .alert-icon {
      margin-right: 12px;
      ${!!description &&
      css`
        padding-top: 3px;
      `}
    }

    .ant-alert-message {
      margin: 0;
      ${!!description &&
      css`
        padding-bottom: 6px;
      `}
    }
  `}
`
