import { ReactComponent as LoaderIcon } from "../../shared/assets/icons/loader-5-line.svg"
import folder from "../../shared/assets/images/folder.svg"
import Wrapper from "../../ui/Field/Wrapper"
import Checkbox from "../Checkbox"
import Container from "../Container"
import Icon from "../Icon"
import Img from "../Img"
import type { InputLabelProps } from "../InputLabel"
import InputLabel from "../InputLabel"
import InputHelper from "../Inputs/InputHelper"
import Spacer from "../Spacer"
import type { TagProps } from "../Tag"
import Tag from "../Tag"
import Text from "../Typography/Text"
import { MaxTag } from "./MaxTag"
import type { SelectMode, SelectSize } from "./select.interfaces"
import type {
  SelectProps as AntdSelectProps,
  LabeledValue,
  SelectValue,
} from "antd/lib/select"
import AntdSelect from "antd/lib/select"
import classnames from "classnames"
import type CSS from "csstype"
import type { DefaultValueType } from "rc-select/lib/interface/generator"
import React from "react"
import type { ReactNode } from "react"
import { useIntl } from "react-intl"
import styled, { css } from "styled-components"

export type CustomSelectType = Omit<InputLabelProps, "label"> & {
  className?: string
  label?: string
  wrapHelperText?: boolean
  helperText?: string
  hasError?: boolean
  tagProps?: Omit<TagProps, "size" | "disabled">
  width?: string
  wrapperWidth?: CSS.Property.Width
  hideSearchIcon?: boolean
  allowSearch?: boolean
  onChangeWithCheckbox?: (options: Array<string>) => void
  withCheckbox?: boolean
  dropdownHeader?: React.ReactNode
  closableTags?: boolean
}

const Select = <T extends SelectValue>(
  props: Omit<AntdSelectProps<T>, "showSearch"> & Omit<CustomSelectType, "ref">
) => {
  const {
    className,
    label,
    labelClassName,
    dropdownClassName,
    requirement,
    tooltipTitle,
    helperText,
    hasError,
    allowSearch,
    options,
    onChangeWithCheckbox,
    withCheckbox,
    disabled,
    tagProps,
    onDeselect,
    wrapHelperText,
    width,
    loading,
    hideSearchIcon = false,
    dropdownHeader,
    wrapperWidth,
    closableTags = true,
    size = "middle",
    maxTagCount = "responsive",
    ...rest
  } = props

  const intl = useIntl()

  const handlePopupContainer = (trigger: HTMLElement) => trigger

  type CustomTagProps = TagProps & {
    label: ReactNode
    value: DefaultValueType
  }

  const tagRender = (renderProps: CustomTagProps) => {
    const tagLabel = renderProps?.label
    const value = renderProps?.value as string

    let renderTagProps = {
      ...tagProps,
    }

    if (disabled) {
      renderTagProps = {
        ...renderTagProps,
        type: "secondary",
        borderStyle: "solid",
        cursor: "not-allowed",
      }
    }

    return (
      <StyledTag
        {...renderProps}
        {...renderTagProps}
        closable={closableTags}
        onClose={(event) =>
          withCheckbox
            ? onCheckChange({ value, label: tagLabel })
            : renderProps.onClose && renderProps.onClose(event)
        }
        size={size}
      >
        {tagLabel}
      </StyledTag>
    )
  }

  const stopPropagation = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    event.stopPropagation()
  }

  const suffixIconRender = () => {
    if (loading) {
      return (
        <StyledLoaderContainer position="absolute">
          <LoaderIcon className="spinning" width={20} height={20} />
        </StyledLoaderContainer>
      )
    }

    if (allowSearch && !hideSearchIcon && !hasError && !disabled) {
      return (
        <Icon
          remixiconClass="ri-search-line"
          classes="suffix-icon"
          color="Neutral5"
        />
      )
    }

    return (
      <Icon
        remixiconClass="ri-arrow-down-s-line"
        classes="suffix-icon"
        color="Neutral5"
      />
    )
  }

  const onCheckChange = (data: LabeledValue) => {
    if (!data || !onChangeWithCheckbox) return

    const selectedOptionsWithCheckbox = rest.value as Array<string>

    const isLocationPresent = selectedOptionsWithCheckbox?.some(
      (selectedOption) => {
        return selectedOption === data.value
      }
    )

    if (isLocationPresent && selectedOptionsWithCheckbox) {
      onChangeWithCheckbox([
        ...selectedOptionsWithCheckbox?.filter(
          (selectedOption) => selectedOption !== data.value
        ),
      ])
    } else {
      if (selectedOptionsWithCheckbox) {
        onChangeWithCheckbox([
          ...selectedOptionsWithCheckbox,
          data.value as string,
        ])
      } else {
        onChangeWithCheckbox([data.value as string])
      }
    }
  }

  const onOptionsChange = <Z extends SelectValue>(selectedOptions: Z) => {
    if (Array.isArray(selectedOptions)) {
      onChangeWithCheckbox?.(selectedOptions.map((option) => option.toString()))
    }
  }

  return (
    <Wrapper width={wrapperWidth}>
      {label && (
        <InputLabel
          label={label}
          labelClassName={labelClassName}
          requirement={requirement}
          tooltipTitle={tooltipTitle}
          size={size}
        />
      )}

      <StyledSelectWrapper
        $width={width}
        className={classnames("select-wrapper", className)}
        $hasError={hasError}
        $hasSearchIcon={allowSearch && !hideSearchIcon}
        size={size}
        mode={rest.mode}
        disabled={disabled}
      >
        <AntdSelect
          getPopupContainer={handlePopupContainer}
          optionFilterProp="children"
          disabled={disabled}
          optionLabelProp="label"
          tagRender={tagRender}
          size={size}
          onDeselect={onDeselect}
          loading={loading}
          suffixIcon={suffixIconRender}
          clearIcon={
            <Icon
              classes="close__icon"
              remixiconClass="ri-close-circle-line"
              display="flex"
              alignItems="center"
              color="Neutral5"
            />
          }
          notFoundContent={
            <Container
              display="flex"
              flexDirection="column"
              alignItems="center"
            >
              <Container
                display="flex"
                justifyContent="center"
                alignItems="center"
                margin="8px"
              >
                <Img placeholderImage={folder} />
              </Container>
              <Spacer size={8} />
              <Text>
                {intl.formatMessage({
                  id: "components.select.dropdown.footer.placeholder.text",
                  defaultMessage: "No data",
                })}
              </Text>
            </Container>
          }
          showSearch={allowSearch}
          dropdownClassName={classnames(
            "select-default-dropdown",
            dropdownClassName
          )}
          dropdownAlign={{
            overflow: { adjustY: 0 },
          }}
          bordered={false}
          onChange={onOptionsChange}
          maxTagCount={rest.mode ? maxTagCount : undefined}
          maxTagPlaceholder={(values) => MaxTag(values, disabled ?? false)}
          {...rest}
        >
          {dropdownHeader}
          {rest.children
            ? rest.children
            : withCheckbox
            ? options?.map(({ value, label: optionLabel }) => {
                const selectedOptionsWithCheckbox = rest.value as Array<string>

                const checked = selectedOptionsWithCheckbox?.some(
                  (selectedLocation) => selectedLocation === value
                )

                return (
                  <OptionSelect
                    className="option-select-checkbox"
                    key={value}
                    value={value}
                    label={optionLabel}
                  >
                    <StyledCheckbox
                      key={value}
                      padding="4px 12px"
                      checked={checked}
                    >
                      <Checkbox
                        classId={`location-${value}-checkbox`}
                        content={
                          <Text
                            size="m"
                            weight={checked ? "bold" : "regular"}
                            onClick={stopPropagation}
                          >
                            {optionLabel}
                          </Text>
                        }
                        checked={checked}
                      />
                    </StyledCheckbox>
                  </OptionSelect>
                )
              })
            : options?.map(
                ({
                  value,
                  label: optionLabel,
                  hasIcon,
                  remixiconClass,
                  color,
                  extra,
                  ...restValues
                }) => (
                  <OptionSelect
                    value={value}
                    label={optionLabel}
                    key={value}
                    {...restValues}
                  >
                    <Container
                      width="100%"
                      display="flex"
                      justifyContent="space-between"
                    >
                      <div>
                        {hasIcon && remixiconClass ? (
                          <StyledOptionWithIconWrapper>
                            <Icon
                              size={18}
                              remixiconClass={remixiconClass}
                              color={color || "Primary4"}
                            />

                            {optionLabel}
                          </StyledOptionWithIconWrapper>
                        ) : (
                          optionLabel
                        )}
                      </div>
                      <div onClick={stopPropagation}>{extra}</div>
                    </Container>
                  </OptionSelect>
                )
              )}
        </AntdSelect>

        {rest.mode && (
          <Icon
            remixiconClass="ri-arrow-down-s-line"
            classes="mode-suffix-icon"
            color="Neutral5"
          />
        )}

        <StyledInputHelperWrapper>
          {!!helperText && (
            <InputHelper
              $hasError={hasError && !disabled}
              wrap={wrapHelperText}
              size={size === "large" ? "m" : "s"}
            >
              {helperText}
            </InputHelper>
          )}
        </StyledInputHelperWrapper>
      </StyledSelectWrapper>
    </Wrapper>
  )
}

export default Select

export const OptionSelect = AntdSelect.Option

const StyledSelectWrapper = styled(Wrapper)<{
  size: SelectSize
  mode?: SelectMode
  hasHelperText?: boolean
  $width?: string
  $hasSearchIcon?: boolean
  $hasError?: boolean
  disabled?: boolean
}>`
  .option-select-checkbox {
    padding: 0;
    margin: 0;
  }

  ${({ theme, size }) => {
    const fontSize = size === "large" ? "l" : "m"
    const fontStyle = theme.text.body[`${fontSize}`].regular

    return css`
      /** Placeholder style **/
      .ant-select-selector .ant-select-selection-placeholder {
        align-self: center;
        font-size: ${fontStyle.fontSize}px;
        font-weight: ${fontStyle.fontWeight};
        line-height: ${fontStyle.lineHeight}px;
        letter-spacing: ${fontStyle.letterSpacing}px;
        color: ${theme.colors.Neutral5};
      }

      /** Hide default Antd tag for options **/
      .ant-select-selection-item {
        margin-top: 0;
        margin-bottom: 0;
        background: none;
        border: none;
        border-radius: 0;
        padding-inline-start: 0;
        padding-inline-start: 0;
        margin-inline-end: 0;
        padding-inline-end: 0;
      }

      /** Text style **/
      .ant-select-selection-item,
      .ant-select-open .ant-select-selection-item {
        font-size: ${fontStyle.fontSize}px;
        font-weight: ${fontStyle.fontWeight};
        letter-spacing: ${fontStyle.letterSpacing}px;
        color: ${theme.colors.Neutral8};
      }
    `
  }}

  ${({ theme }) => {
    const fontStyle = theme.text.body.m.regular
    const selectedFontStyle = theme.text.body.m.bold

    return css`
      /** Dropdown list style **/
      & .select-default-dropdown {
        border-radius: ${theme.spacing.cornerRadiusSmall};
        outline: 1px solid ${theme.colors["Neutral4"]};
        box-shadow: none;
        padding: 0px;

        .ant-select-item-option {
          font-weight: ${fontStyle.fontWeight};
          font-size: ${fontStyle.fontSize}px;
          letter-spacing: ${fontStyle.letterSpacing}px;
          background-color: ${theme.colors["Neutral0"]};
          color: ${theme.colors.Neutral8};

          &:hover:not(.ant-select-item-option-selected):not(.no-background) {
            background-color: ${theme.colors["Neutral2"]};
          }
        }

        .option-select-checkbox-selected:hover {
          background-color: ${theme.colors["Neutral2"]};
        }

        .ant-select-item-option-selected {
          font-weight: ${selectedFontStyle.fontWeight};
          font-family: ${selectedFontStyle.fontFamily};
          font-size: ${selectedFontStyle.fontSize}px;
          letter-spacing: ${selectedFontStyle.letterSpacing}px;
          color: ${theme.colors.Neutral8};

          &:not(.no-background) {
            background-color: ${theme.colors["Primary1"]};
          }

          .option-select-checkbox-selected {
            background-color: ${theme.colors["Primary1"]};
          }

          .ant-select-item-option-state svg {
            width: 0px;
            height: 0px;
          }

          .anticon-check {
            visibility: hidden;
          }
        }
      }
    `
  }}

  ${({ theme, mode, $hasError, disabled, size, $width }) => css`
    width: ${$width};
    height: ${size === "large" ? "40px" : " 32px"};
    outline: 1px solid ${theme.colors["Neutral4"]};
    border-radius: ${theme.spacing.cornerRadiusSmall};
    background-color: ${theme.colors.Neutral0};

    &:hover {
      outline: 1px solid ${theme.colors["Primary5"]};
    }

    &:focus-within {
      outline: 1px solid ${theme.colors["Primary5"]};
      box-shadow: inset 0px 0px 0px 1px ${theme.colors["Primary5"]};
    }

    & .ant-select-open .ant-select-selection-placeholder {
      color: ${theme.colors.Neutral8};
    }

    .suffix-icon {
      pointer-events: none !important;
      position: absolute;
      top: -2px;
      right: 0px;
    }

    .input-helper {
      margin-top: 4px;
    }

    .ant-select-clear {
      right: 2px;
      width: 24px;
      height: 20px;
      top: ${size === "large" ? "18px" : " 15px"};
    }

    ${mode &&
    css`
      height: auto;

      .mode-suffix-icon {
        pointer-events: none !important;
        position: absolute;
        top: ${size === "large" ? "12px" : "8px"};
        right: 10px;
      }

      & .ant-select-selector {
        padding-left: 3px;
        padding-right: 25px;

        .ant-select-selection-search {
          margin-top: 0px;
          margin-bottom: 0px;
        }
      }

      & .ant-select-selector:has(.ant-tag) .ant-select-selection-search-input {
        margin-left: 4px;
      }
    `}

    ${$hasError &&
    !disabled &&
    css`
      &,
      &:hover {
        outline: 1px solid ${theme.colors["Danger5"]};
      }

      &:focus-within {
        box-shadow: inset 0px 0px 0px 1px ${theme.colors["Danger5"]};
        outline: 1px solid ${theme.colors["Danger5"]};
      }

      .ant-select-selector .ant-select-selection-placeholder {
        color: ${theme.colors.Neutral8};
      }
    `}

    ${disabled &&
    css`
      background-color: ${theme.colors["Neutral3"]};

      .ant-select-disabled .ant-select-selection-item {
        color: ${theme.colors.Neutral5};
      }
    `}
  `}
`

const StyledLoaderContainer = styled(Container)`
  top: -4px;
  right: 4px;
`

const StyledInputHelperWrapper = styled.div`
  position: relative;
`

export const StyledOptionWithIconWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  > div:first-child {
    margin-right: 16px;
  }
`

const StyledTag = styled(Tag)`
  ${({ size }) => css`
    height: ${`${size === "large" ? 32 : 24}px`};
  `}
`

const StyledCheckbox = styled(Container)<{ checked?: boolean }>`
  ${({ theme, checked }) => css`
    ${checked &&
    css`
      background-color: ${theme.colors.Primary1};
      font-weight: bold;
    `}
  `}
`
