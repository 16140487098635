export interface GraphqlErrorLocation {
  line: number
  column: number
}
export interface GraphqlErrorResponse {
  statusCode: number
  message: string[]
  error: string
}

export interface GraphqlErrorException {
  response: GraphqlErrorResponse
  status: number
  message: string
  stacktrace: string[]
}

export interface GraphqlErrorExtensions {
  code: string
  exception: GraphqlErrorException
}

export interface GraphqlError {
  message: string
  locations: GraphqlErrorLocation[]
  path: string[]
  extensions: GraphqlErrorExtensions
}

export interface GraphqlMutationError {
  graphQLErrors: GraphqlError[]
  networkError?: unknown
  message: string
  clientErrors: unknown[]
  extraInfo?: unknown
  result?: { errors: GraphqlError[] }
}

const parseGraphqlErrorMessages = (error: GraphqlMutationError): string[] => {
  const reduceErrors = (errorList: GraphqlError[]) => {
    const messages = errorList
      // eslint-disable-next-line unicorn/no-array-reduce
      .reduce<string[][]>((allErrors, currentError) => {
        const currentMessages =
          currentError?.extensions?.exception?.response?.message ||
          currentError.message ||
          []

        if (currentMessages.length > 0) {
          allErrors.push(currentMessages)
        }

        return allErrors
      }, [])
      .flat()
      .filter((errorMessage) => errorMessage !== undefined)

    return messages
  }

  if (error.graphQLErrors.length > 0) {
    return reduceErrors(error.graphQLErrors)
  }

  return []
}

export default parseGraphqlErrorMessages
