import * as Types from '../../../../../../shared/graphql/generated/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateDeliveryPriceMutationVariables = Types.Exact<{
  uuid: Types.Scalars['ID'];
  label?: Types.InputMaybe<Types.Scalars['String']>;
  locationUUID: Types.Scalars['ID'];
  maxDistance?: Types.InputMaybe<Types.Scalars['Int']>;
  price?: Types.InputMaybe<Types.Scalars['Float']>;
}>;


export type UpdateDeliveryPriceMutation = { __typename?: 'Mutation', updateDeliveryPrice: { __typename?: 'DeliveryPrice', uuid: string, label?: string | null, maxDistance: number, minDistance: number, price: number } };


export const UpdateDeliveryPriceDocument = gql`
    mutation updateDeliveryPrice($uuid: ID!, $label: String, $locationUUID: ID!, $maxDistance: Int, $price: Float) {
  updateDeliveryPrice(
    data: {uuid: $uuid, label: $label, location: {uuid: $locationUUID}, maxDistance: $maxDistance, price: $price}
  ) {
    uuid
    label
    maxDistance
    minDistance
    price
  }
}
    `;
export type UpdateDeliveryPriceMutationFn = Apollo.MutationFunction<UpdateDeliveryPriceMutation, UpdateDeliveryPriceMutationVariables>;

/**
 * __useUpdateDeliveryPriceMutation__
 *
 * To run a mutation, you first call `useUpdateDeliveryPriceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDeliveryPriceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDeliveryPriceMutation, { data, loading, error }] = useUpdateDeliveryPriceMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *      label: // value for 'label'
 *      locationUUID: // value for 'locationUUID'
 *      maxDistance: // value for 'maxDistance'
 *      price: // value for 'price'
 *   },
 * });
 */
export function useUpdateDeliveryPriceMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDeliveryPriceMutation, UpdateDeliveryPriceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDeliveryPriceMutation, UpdateDeliveryPriceMutationVariables>(UpdateDeliveryPriceDocument, options);
      }
export type UpdateDeliveryPriceMutationHookResult = ReturnType<typeof useUpdateDeliveryPriceMutation>;
export type UpdateDeliveryPriceMutationResult = Apollo.MutationResult<UpdateDeliveryPriceMutation>;
export type UpdateDeliveryPriceMutationOptions = Apollo.BaseMutationOptions<UpdateDeliveryPriceMutation, UpdateDeliveryPriceMutationVariables>;