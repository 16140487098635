import { ActionTypes } from "../../../../../pages/Restaurants/actions"
import { useGeneralContext } from "../../../../../shared/contexts/StoreProvider"
import paths from "../../../../../shared/routes/paths"
import type { IRestaurant, IntlMessage } from "../../../../../shared/types"
import storage from "../../../../../shared/utils/storage"
import { SETTINGS_PATH } from "../../../../../shared/utils/storage/constants"
import Icon from "../../../../Icon"
import InlineMenuItem from "../../../../Menu/InlineMenuItem"
import Spacer from "../../../../Spacer"
import Text from "../../../../Typography/Text"
import { DropDownMenuSkeleton } from "./DropDownMenu.skeleton"
import { Logout } from "./Logout"
import { RestaurantItem } from "./RestaurantItem"
import { Menu } from "antd"
import type { MenuClickEventHandler } from "rc-menu/lib/interface"
import React from "react"
import { useIntl } from "react-intl"
import { NavLink, useLocation } from "react-router-dom"
import styled, { css } from "styled-components"

export interface IDropdownMenuProps {
  loading?: boolean
}

type DropdownMenuType = (args: IDropdownMenuProps) => React.ReactElement

interface IDropdownItemProps {
  key: string
  path: string
  label: IntlMessage
}

const dropdownItems: IDropdownItemProps[] = [
  {
    key: "support-item",
    path: paths.account.support,
    label: {
      id: "components.layouts.sidebar.item.help.support",
      defaultMessage: "Help & Support",
    },
  },
  {
    key: "roadmap-item",
    path: paths.account.roadmap,
    label: {
      id: "components.layouts.sidebar.item.roadmap.changelog",
      defaultMessage: "Roadmap & Changelog",
    },
  },
  {
    key: "community-item",
    path: paths.account.community,
    label: {
      id: "components.layouts.sidebar.item.join.slack.community",
      defaultMessage: "Join Slack Community",
    },
  },
]

export const DropdownMenu: DropdownMenuType = ({ loading }) => {
  const intl = useIntl()
  const { pathname } = useLocation()

  const {
    state: { restaurants, currentRestaurant },
    dispatch,
    getSettingsRoot,
  } = useGeneralContext()

  const settingsRoot = getSettingsRoot()

  const updateCurrentRestaurant: MenuClickEventHandler = (event) => {
    const selectedRestaurantUUID = event.key

    dispatch({
      type: ActionTypes.SetCurrentState,
      payload: {
        restaurantUUID: selectedRestaurantUUID,
        refirectToRoot: true,
      },
    })
  }

  const handleSettingsClick = () => {
    storage.set(SETTINGS_PATH, `${pathname}${window.location.search}`)
  }

  return (
    <StyledMenu role="menu-settings">
      <StyledTitleWrapper className="custom-colored-links">
        <StyledText size="s">
          {intl.formatMessage({
            id: "components.main.sidebar.restaurants.title",
            defaultMessage: "Restaurants",
          })}
        </StyledText>
      </StyledTitleWrapper>

      <Spacer size={12} />

      {loading ? (
        <DropDownMenuSkeleton />
      ) : (
        restaurants?.map(
          ({
            uuid: restaurantUUID,
            name: restaurantName,
            attachments,
          }: IRestaurant) => {
            const selected = currentRestaurant?.uuid === restaurantUUID
            let image = ""

            try {
              image =
                attachments?.find((a) => a.type === "LOGO")?.signedUrl ??
                attachments?.[0]?.signedUrl ??
                ""
            } catch {}

            return (
              <InlineMenuItem
                key={restaurantUUID}
                onClick={updateCurrentRestaurant}
              >
                <RestaurantItem
                  restaurantUUID={restaurantUUID}
                  restaurantName={restaurantName}
                  isSelected={selected}
                  icon={image}
                />
              </InlineMenuItem>
            )
          }
        )
      )}

      <Spacer size={8} />

      <InlineMenuItem
        icon={<Icon size={20} remixiconClass="ri-add-line" />}
        key="create-restaurant"
        className="custom-colored-links"
        allowed={["RESTAURANT_OWNER", "RESTAURANT_ADMIN", "RESTAURANT_MANAGER"]}
      >
        <NavLink to={paths.restaurants.create}>
          {intl.formatMessage({
            id: "components.main.sidebar.restaurants.create",
            defaultMessage: "Create a restaurant",
          })}
        </NavLink>
      </InlineMenuItem>

      <Menu.Divider />
      <InlineMenuItem key="settings-item" className="custom-colored-links">
        <NavLink to={settingsRoot.path} onClick={handleSettingsClick}>
          {intl.formatMessage({
            id: "components.layouts.sidebar.item.settings",
            defaultMessage: "Settings",
          })}
        </NavLink>
      </InlineMenuItem>
      <Menu.Divider />

      {dropdownItems.map((dropdownItem) => (
        <InlineMenuItem
          key={dropdownItem.key}
          className="custom-colored-links hidden"
        >
          <NavLink to={dropdownItem.path}>
            {intl.formatMessage(dropdownItem.label)}
          </NavLink>
        </InlineMenuItem>
      ))}

      <Menu.Divider className="hidden" />

      <InlineMenuItem key="logout-item">
        <Logout />
      </InlineMenuItem>
    </StyledMenu>
  )
}

const StyledMenu = styled(Menu)`
  height: auto;
  max-height: 600px;
  overflow: scroll;

  .ant-dropdown:has(&.ant-dropdown-menu) {
    height: 100%;
  }

  .ant-dropdown-menu-item-group-title > .ant-typography {
    ${({ theme }) =>
      css`
        color: ${theme.colors["Neutral8"]};
      `}
  }

  .ant-dropdown-menu-item,
  .ant-dropdown-menu-item-only-child,
  .ant-dropdown-menu-item-group-title {
    padding-left: 8px !important;
    height: 32px;
    margin: 0px 16px;
    width: 192px;
    border-radius: 4px;
    font-size: 13px;
  }

  .ant-dropdown-menu-item:hover {
    background: ${({ theme }) => theme.colors["Neutral2"]};
  }

  .custom-colored-links > .ant-dropdown-menu-title-content,
  .custom-colored-links > .anticon {
    ${({ theme }) =>
      css`
        color: ${theme.colors["Neutral6"]};
      `}
  }
  .custom-colored-links > .anticon {
    margin-right: 8px;
  }
`

const StyledTitleWrapper = styled.div`
  padding: 6px 16px;
`

const StyledText = styled(Text)`
  ${({ theme }) => css`
    color: ${theme.colors["Neutral6"]};
    line-height: 20px;
  `};
`
