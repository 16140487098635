import { cateringFeeTypes } from "../../../shared/config"
import {
  CateringForm,
  FeeQuantityEnum,
} from "../../../shared/hookforms.intefaces"
import { validationCateringTitle } from "../../../shared/validation-catering.title"
import styles from "./CateringFeeField.module.css"
import {
  Checkbox,
  Grid,
  Group,
  NumberInput,
  SegmentedControl,
  SegmentedControlItem,
  Stack,
} from "@mantine/core"
import { IconCurrencyDollar, IconPercentage } from "@tabler/icons-react"
import {
  Control,
  Controller,
  FieldPath,
  UseFormSetValue,
  UseFormTrigger,
} from "react-hook-form"
import { FormattedMessage, useIntl } from "react-intl"

type IntlType = {
  id: string
  defaultMessage: string
}

interface CateringFeeFieldProps {
  control: Control<CateringForm, unknown>
  nameCheckbox: FieldPath<CateringForm>
  nameValue: FieldPath<CateringForm>
  nameType: FieldPath<CateringForm>
  type: FeeQuantityEnum
  apply: boolean
  label: IntlType
  id: string
  setValue: UseFormSetValue<CateringForm>
  trigger?: UseFormTrigger<CateringForm>
}

export const CateringFeeField = ({
  control,
  nameCheckbox,
  nameValue,
  nameType,
  apply,
  type,
  label,
  id,
  setValue,
  trigger,
}: CateringFeeFieldProps) => {
  const intl = useIntl()

  const segmentedControlData = Object.keys(
    cateringFeeTypes
  ).map<SegmentedControlItem>((key) => {
    const method = cateringFeeTypes[key as keyof typeof cateringFeeTypes]
    return {
      label: (
        <FormattedMessage
          defaultMessage={method.defaultMessage}
          id={method.id}
        />
      ),
      value: key,
    }
  })

  return (
    <Grid.Col span={6}>
      <Stack id={id} spacing={12} w="100%">
        <Controller
          control={control}
          name={nameCheckbox}
          render={({ field }) => (
            <Checkbox
              label={<FormattedMessage {...label} />}
              checked={apply}
              onChange={(val) => {
                field.onChange(val)
                trigger && trigger(nameValue)
              }}
            />
          )}
        />

        <Group align="start">
          <Controller
            control={control}
            name={nameType}
            render={({ field }) => (
              <SegmentedControl
                style={{ flex: "1 1 208px" }}
                disabled={!apply}
                size="xs"
                classNames={{
                  label: styles["sc-label"],
                  indicator: styles["sc-indicator"],
                }}
                h={32}
                value={String(field.value)}
                onChange={(value) => {
                  field.onChange(value)
                  setValue(nameValue, 0)
                  trigger && trigger(nameValue)
                }}
                data={segmentedControlData}
              />
            )}
          />

          <Controller
            control={control}
            name={nameValue}
            render={({ field, fieldState: { error } }) => {
              if (type === FeeQuantityEnum.PERCENTAGE) {
                return (
                  <NumberInput
                    {...field}
                    type="number"
                    maxLength={3}
                    max={100}
                    hideControls
                    error={
                      error?.message
                        ? intl.formatMessage(
                            validationCateringTitle[error?.message]
                          )
                        : undefined
                    }
                    rightSection={<IconPercentage size={16} />}
                    disabled={!apply}
                    className={styles["number-input"]}
                    classNames={{
                      label: styles.label,
                      input: styles.input,
                      rightSection: styles["right-section"],
                    }}
                    value={typeof field.value === "number" ? field.value : ""}
                    onChange={field.onChange}
                  />
                )
              }
              return (
                <NumberInput
                  type="number"
                  maxLength={10_000}
                  {...field}
                  error={
                    error?.message
                      ? intl.formatMessage(
                          validationCateringTitle[error?.message]
                        )
                      : undefined
                  }
                  hideControls
                  icon={<IconCurrencyDollar size={16} />}
                  disabled={!apply}
                  className={styles["number-input"]}
                  classNames={{
                    label: styles.label,
                    input: styles.input,
                    rightSection: styles["right-section"],
                  }}
                  value={typeof field.value === "number" ? field.value : ""}
                  onChange={field.onChange}
                />
              )
            }}
          />
        </Group>
      </Stack>
    </Grid.Col>
  )
}
