import StatusBadge from "../StatusBadge"
import styles from "./IntegrationCard.module.css"
import { Card, Image, Skeleton, Stack, Text, Title } from "@mantine/core"

type IntegrationCardProps = {
  title: string
  description: string
  connected: boolean
  image?: string
  loading?: boolean
  onClick?: (title: string) => void
}

const IntegrationCard = (props: IntegrationCardProps) => {
  const {
    title,
    description,
    image,
    connected,
    onClick,
    loading = false,
  } = props

  const handleClick = () => {
    onClick?.(title)
  }

  if (loading) return <Skeleton width={288} height={276} />

  return (
    <Card
      padding={0}
      radius="md"
      withBorder
      w={288}
      h={276}
      onClick={handleClick}
      className={styles["card"]}
    >
      <Card.Section>
        <Image
          src={image}
          mx={54}
          my={28.5}
          height={95}
          width={180}
          alt={`${title} logo`}
        />
      </Card.Section>
      <Card.Section>
        <Stack
          bg="gray.0"
          spacing={8}
          justify="center"
          w="100%"
          h={124}
          px={16}
        >
          <Title order={5} c="gray.9">
            {title}
          </Title>
          {connected && <StatusBadge connected />}
          <Text size="sm" c="gray.7">
            {description}
          </Text>
        </Stack>
      </Card.Section>
    </Card>
  )
}

export default IntegrationCard
