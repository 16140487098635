import { CheckboxFieldSkeleton } from "../../../../../ui/Checkbox"
import { ImageIconFieldSkeleton } from "../../../../../ui/ImageIcon"
import { LabelFieldSkeleton } from "../../../../../ui/InputLabel"
import { InputFieldSkeleton } from "../../../../../ui/Inputs"
import Spacer from "../../../../../ui/Spacer"
import type { SkeletonProps } from "antd"
import React from "react"

export const TableFormSkeleton: React.FC<SkeletonProps> = ({
  children,
  loading,
}) => {
  return loading ? (
    <div role="loading">
      <LabelFieldSkeleton />
      <InputFieldSkeleton />
      <Spacer size={24} />

      <LabelFieldSkeleton />
      <InputFieldSkeleton />
      <Spacer size={24} />

      <ImageIconFieldSkeleton />
      <Spacer size={24} />

      <LabelFieldSkeleton />
      <InputFieldSkeleton />
      <Spacer size={24} />

      <CheckboxFieldSkeleton />
      <Spacer size={24} />

      <CheckboxFieldSkeleton />
    </div>
  ) : (
    <>{children}</>
  )
}
