import * as Types from '../../../../../../shared/graphql/generated/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateDeliveryLocationMutationVariables = Types.Exact<{
  data: Types.UpdateDeliveryLocationInput;
}>;


export type UpdateDeliveryLocationMutation = { __typename?: 'Mutation', updateDeliveryLocation: { __typename?: 'DeliveryLocation', uuid: string, isDefault: boolean, deliveryIntegration: { __typename?: 'RDeliveryIntegration', uuid: string, apiKey: string, developerId?: string | null, integrationLabel?: string | null, integrationName: Types.DeliveryIntegrationEnum, isActive: boolean, issues?: string | null, settings?: any | null, signingSecret?: string | null } } };


export const UpdateDeliveryLocationDocument = gql`
    mutation updateDeliveryLocation($data: UpdateDeliveryLocationInput!) {
  updateDeliveryLocation(data: $data) {
    uuid
    isDefault
    deliveryIntegration {
      uuid
      apiKey
      developerId
      integrationLabel
      integrationName
      isActive
      issues
      settings
      signingSecret
    }
  }
}
    `;
export type UpdateDeliveryLocationMutationFn = Apollo.MutationFunction<UpdateDeliveryLocationMutation, UpdateDeliveryLocationMutationVariables>;

/**
 * __useUpdateDeliveryLocationMutation__
 *
 * To run a mutation, you first call `useUpdateDeliveryLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDeliveryLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDeliveryLocationMutation, { data, loading, error }] = useUpdateDeliveryLocationMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateDeliveryLocationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDeliveryLocationMutation, UpdateDeliveryLocationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDeliveryLocationMutation, UpdateDeliveryLocationMutationVariables>(UpdateDeliveryLocationDocument, options);
      }
export type UpdateDeliveryLocationMutationHookResult = ReturnType<typeof useUpdateDeliveryLocationMutation>;
export type UpdateDeliveryLocationMutationResult = Apollo.MutationResult<UpdateDeliveryLocationMutation>;
export type UpdateDeliveryLocationMutationOptions = Apollo.BaseMutationOptions<UpdateDeliveryLocationMutation, UpdateDeliveryLocationMutationVariables>;