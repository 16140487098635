import Container from "../../../../ui/Container"
import Divider from "../../../../ui/Divider"
import { InputFieldSkeleton } from "../../../../ui/Inputs"
import Spacer from "../../../../ui/Spacer"
import type { SkeletonProps } from "antd"
import { List } from "antd"
import classnames from "classnames"
import React from "react"
import styled from "styled-components"

export const OrderThrottlingSkeleton: React.FC<SkeletonProps> = ({
  children,
  loading = true,
}) => {
  return loading ? (
    <div>
      <InputFieldSkeleton size="default" width="200px" marginBottom="2px" />
      <InputFieldSkeleton width="350px" />
      <Spacer size={12} />
      <Divider />
      <Spacer size={20} />
      <InputFieldSkeleton width="200px" marginBottom="8px" />
      <InputFieldSkeleton width="400px" />

      <Spacer size={16} />

      <InputFieldSkeleton width="200px" marginBottom="6px" />
      <InputFieldSkeleton size="default" width="92px" />

      <Spacer size={60} />

      <InputFieldSkeleton width="200px" marginBottom="8px" />
      <InputFieldSkeleton width="400px" />

      <Spacer size={16} />

      <Container display="flex" flexDirection="column" gap="24px">
        <StyledList className="custom-list">
          {Array.from({ length: 7 })
            .fill({})
            .map((_, idx) => (
              <StyledList.Item
                key={idx}
                className={classnames({ "first-item-list": idx === 0 })}
              >
                <StyledContainer
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                  alignItems="center"
                  gap="32px"
                >
                  <Container
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    gap="32px"
                  >
                    <InputFieldSkeleton width="73px" />
                    <InputFieldSkeleton width="228px" size="default" />
                  </Container>
                  <InputFieldSkeleton width="92px" size="default" />
                </StyledContainer>
              </StyledList.Item>
            ))}
        </StyledList>
      </Container>

      <Spacer size={68} />
      <InputFieldSkeleton size="default" width="158px" />
    </div>
  ) : (
    <>{children}</>
  )
}

const StyledContainer = styled(Container)`
  max-width: 496px;
  height: auto;
`

const StyledList = styled(List)`
  &.custom-list {
    .first-item-list {
      padding: 16px 0px 36px 0px !important;
    }

    .ant-list-item {
      padding: 36px 0px;
    }
  }
`
