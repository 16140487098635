import { TipValueTypeEnum } from "../../../../../../../shared/graphql/generated/types"
import Fieldset from "../../components/Fieldset"
import { tipsSettingsControl } from "../config"
import { OrderMethodSettingsFormType } from "../hookforms.interfaces"
import { getDefaultTipOption } from "./utils/get-default-tip-option.util"
import { getSelectData } from "./utils/get-select-data.util"
import {
  //TODO Remove it when request tip is added
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  Checkbox, // eslint-disable-next-line @typescript-eslint/no-unused-vars
  Group,
  MultiSelect,
  SegmentedControl,
  Select,
  Stack,
  Text,
} from "@mantine/core"
import { useFormContext, useWatch } from "react-hook-form"
import { useIntl } from "react-intl"

export const TipSettingsForm = () => {
  const intl = useIntl()

  const {
    control,
    setValue,
    clearErrors,
    formState: { errors },
  } = useFormContext<OrderMethodSettingsFormType>()

  const [requestTipBefore, requestTipAfter, tipSettings, tipsData] = useWatch({
    control,
    name: [
      "tipSettings.requestTipBefore",
      "tipSettings.requestTipAfter",
      "tipSettings",
      "tipsData",
    ],
  })

  const isPercentage = tipSettings.type === TipValueTypeEnum.PERCENTAGE

  const onlyOneOptionAvailable =
    (tipsData[tipSettings.type].options?.filter((option) => option.selected)
      .length || 0) <= 1

  const tipsSegmentedData = tipsSettingsControl.map(({ label, value }) => ({
    label: (
      <Text key={`${value}`} size="sm" weight={400}>
        {intl.formatMessage(label)}
      </Text>
    ),
    value,
  }))

  const handleSegmentedControlChange = (tipControl: TipValueTypeEnum) => {
    setValue("tipSettings.inactiveType", tipSettings.type)
    setValue("tipSettings.type", TipValueTypeEnum[tipControl], {
      shouldDirty: true,
    })
  }

  {
    /*TODO Remove it when request tip is added */
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleBeforeCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) =>
    setValue("tipSettings.requestTipBefore", event.currentTarget.checked, {
      shouldDirty: true,
    })

  {
    /*TODO Remove it when request tip is added */
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleAfterCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) =>
    setValue("tipSettings.requestTipAfter", event.currentTarget.checked, {
      shouldDirty: true,
    })

  const handleMultiSelectChange = (values: Array<string>) => {
    const options = tipsData[tipSettings.type].options?.map((option) => ({
      ...option,
      selected: values.includes(option.value),
    }))
    const defaultValue = tipsData[tipSettings.type].default
    const defaultTip = getDefaultTipOption(values, defaultValue)

    setValue(`tipsData.${tipSettings.type}.default`, defaultTip, {
      shouldDirty: true,
    })
    options &&
      setValue(`tipsData.${tipSettings.type}.options`, options, {
        shouldDirty: true,
      })
    clearErrors(`tipsData.${tipSettings.type}.default`)
  }

  const handleSelectChange = (value: string) => {
    setValue(`tipsData.${tipSettings.type}.default`, value, {
      shouldDirty: true,
    })
  }

  return (
    <Fieldset
      label={intl.formatMessage({
        id: "restaurants.locations.overview.order.method.modal.tips.section.title",
        defaultMessage: "Tips settings",
      })}
    >
      <Stack spacing={16}>
        <SegmentedControl
          size="sm"
          data={tipsSegmentedData}
          value={tipSettings.type}
          fullWidth
          onChange={handleSegmentedControlChange}
        />
        <>
          <MultiSelect
            maxSelectedValues={5}
            key={`multiselect-${tipSettings.type}`}
            error={!!errors.tipsData?.[tipSettings.type]?.default}
            data={getSelectData(
              tipsData[TipValueTypeEnum[tipSettings.type]].options || [],
              tipSettings.type,
              intl
            )}
            value={tipsData[TipValueTypeEnum[tipSettings.type]].options
              ?.filter((option) => option.selected)
              .map((option) => option.value)}
            disabled={!requestTipBefore && !requestTipAfter}
            onChange={handleMultiSelectChange}
            label={intl.formatMessage(
              {
                id: "restaurants.locations.overview.order.method.modal.tip.percentage.label",
                defaultMessage:
                  "Tip {view, plural, =0 {Amounts} other {Percentage}}",
              },
              { view: Number(isPercentage) }
            )}
            description={intl.formatMessage({
              id: "restaurants.locations.overview.order.method.modal.tip.percentage.description",
              defaultMessage: "Select up to 5",
            })}
          />

          <Select
            key={`select-${tipSettings.type}`}
            error={!!errors.tipsData?.[tipSettings.type]?.default}
            data={getSelectData(
              tipsData[TipValueTypeEnum[tipSettings.type]].options?.filter(
                (option) => option.selected
              ) || [],
              tipSettings.type,
              intl
            )}
            value={tipsData[tipSettings.type].default}
            onChange={handleSelectChange}
            disabled={
              (!requestTipBefore && !requestTipAfter) || onlyOneOptionAvailable
            }
            label={intl.formatMessage({
              id: "restaurants.locations.overview.order.method.modal.default.tip.label",
              defaultMessage: "Default Tip",
            })}
            description={intl.formatMessage({
              id: "restaurants.locations.overview.order.method.modal.default.tip.description",
              defaultMessage:
                "Select the tip that will be selected by default.",
            })}
          />
        </>
        {/*TODO add back in when request tip is added */}
        {/*<Stack spacing={0}>*/}
        {/*  <Text size="sm" weight={500}>*/}
        {/*    {intl.formatMessage({*/}
        {/*      id: "restaurants.locations.overview.order.method.modal.request.tip.label",*/}
        {/*      defaultMessage: "Request tip",*/}
        {/*    })}*/}
        {/*  </Text>*/}
        {/*  <Group pt={8}>*/}
        {/*    <Checkbox*/}
        {/*      checked={requestTipBefore}*/}
        {/*      onChange={handleBeforeCheckboxChange}*/}
        {/*      label={intl.formatMessage({*/}
        {/*        id: "restaurants.locations.overview.order.method.modal.before.checkout.label",*/}
        {/*        defaultMessage: "Before checkout",*/}
        {/*      })}*/}
        {/*    />*/}
        {/*    <Checkbox*/}
        {/*      checked={requestTipAfter}*/}
        {/*      onChange={handleAfterCheckboxChange}*/}
        {/*      label={intl.formatMessage({*/}
        {/*        id: "restaurants.locations.overview.order.method.modal.after.checkout.label",*/}
        {/*        defaultMessage: "After checkout",*/}
        {/*      })}*/}
        {/*    />*/}
        {/*  </Group>*/}
        {/*</Stack>*/}
      </Stack>
    </Fieldset>
  )
}
