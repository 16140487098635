export enum DAYS_OF_THE_WEEK_ENUM {
  "MONDAY" = "MONDAY",
  "TUESDAY" = "TUESDAY",
  "WEDNESDAY" = "WEDNESDAY",
  "THURSDAY" = "THURSDAY",
  "FRIDAY" = "FRIDAY",
  "SATURDAY" = "SATURDAY",
  "SUNDAY" = "SUNDAY",
}

export type IBussinessHourType = {
  day: `${DAYS_OF_THE_WEEK_ENUM}`
  startDate?: string
  endDate?: string
}

export type IBussinessHourRangeType = {
  day: `${DAYS_OF_THE_WEEK_ENUM}`
  defaultValues?: Array<IBussinessHourType>
  open?: boolean
}

export interface IBusinessHoursForm {
  schedules: Array<IBussinessHourRangeType>
}
