import { useGetSingleLocationQuery } from "../../../../../GraphQL/Queries/getSingleLocation.generated"
import ConfirmationModal from "../../../../../ui/ConfirmationModal"
import Container from "../../../../../ui/Container"
import { showGraphqlErrors } from "../../../../../ui/ErrorList"
import DropDownAction from "../../../../../ui/Header/DropdownAction"
import Icon from "../../../../../ui/Icon"
import Text from "../../../../../ui/Typography/Text"
import notification from "../../../../../ui/notification"
import EditDevice from "../EditDevice"
import { useUpdateDeviceMutation } from "../EditDevice/GraphQl/updateDevice.generated"
import { GetAllDevicesDocument } from "../GraphQl/getAllDevices.generated"
import type { IDeviceRowModel } from "../datatable.interfaces"
import { Menu } from "antd"
import get from "lodash/get"
import React, { useState } from "react"
import { useIntl } from "react-intl"

type DropdownDevicesProps = {
  record: IDeviceRowModel
}

export const DropdownDevices: React.FC<DropdownDevicesProps> = ({ record }) => {
  const intl = useIntl()
  const [isEditDeviceModalShown, setIsEditDeviceModalShown] =
    useState<boolean>(false)
  const [isDeleteDeviceModalShown, setIsDeleteDeviceModalShown] =
    useState<boolean>(false)

  const onEditDeviceModalClose = () => {
    setIsEditDeviceModalShown(false)
  }

  const showEditDeviceModal = () => {
    setIsEditDeviceModalShown(true)
  }

  const showDeleteDeviceConfirmationModal = () => {
    setIsDeleteDeviceModalShown(true)
  }

  const onCloseDeleteDeviceConfirmationModal = () => {
    setIsDeleteDeviceModalShown(false)
  }

  const [updateDevice, { loading: isDeviceUpdateLoading }] =
    useUpdateDeviceMutation({
      refetchQueries: [GetAllDevicesDocument],
    })

  const onConfirmDelete = async () => {
    try {
      if (!record.uuid) {
        return
      }

      if (record.location?.uuid) {
        const updatedDevice = await updateDevice({
          variables: {
            data: {
              uuid: record.uuid,
              locationUUID: record.location?.uuid,
              isActive: false,
            },
          },
        })
        const deviceUUID = get(updatedDevice, "data.updateDevice.uuid", null)

        if (deviceUUID) {
          notification({
            description: intl.formatMessage({
              id: "restaurants.devices.delete.device.modal.success.message",
              defaultMessage: "Your device was deleted",
            }),
            type: "success",
          })
          onCloseDeleteDeviceConfirmationModal()
        }
      }
    } catch (error) {
      showGraphqlErrors(error)
    }
  }

  const { data: locationData } = useGetSingleLocationQuery({
    variables: { locationUUID: record.location?.uuid ?? "" },
  })

  const locationPhone = get(locationData, "getSingleLocation.phone", null)

  return (
    <Container display="flex" justifyContent="flex-end">
      <DropDownAction
        placement="bottomRight"
        overlay={
          <Container>
            <Menu role="menu-more-options-device" selectedKeys={[]}>
              <Menu.Item
                key="dropdown-item-remove-device"
                icon={
                  <Icon remixiconClass="ri-delete-bin-line" color="Neutral7" />
                }
                onClick={showDeleteDeviceConfirmationModal}
              >
                <Text size="s">
                  {intl.formatMessage({
                    id: "restaurants.devices.device.dropdown.item.remove.device",
                    defaultMessage: "Remove device",
                  })}
                </Text>
              </Menu.Item>
              <Menu.Item
                key="dropdown-item-edit-device"
                icon={<Icon remixiconClass="ri-edit-2-line" color="Neutral7" />}
                onClick={showEditDeviceModal}
              >
                <Text size="s">
                  {intl.formatMessage({
                    id: "restaurants.devices.device.dropdown.item.edit.device",
                    defaultMessage: "Edit device",
                  })}
                </Text>
              </Menu.Item>
            </Menu>
            {isEditDeviceModalShown && (
              <EditDevice
                device={record}
                visible={isEditDeviceModalShown}
                onClose={onEditDeviceModalClose}
                closable={false}
                locationPhone={locationPhone}
              />
            )}
            {isDeleteDeviceModalShown && (
              <ConfirmationModal
                visible={isDeleteDeviceModalShown}
                itemType={intl.formatMessage({
                  id: "restaurants.devices.delete.device.modal.title.type",
                  defaultMessage: "Device",
                })}
                onConfirm={onConfirmDelete}
                onCancel={onCloseDeleteDeviceConfirmationModal}
                loading={isDeviceUpdateLoading}
                title={intl.formatMessage({
                  id: "restaurants.devices.delete.device.modal.title",
                  defaultMessage:
                    "Are you sure you want to remove this device?",
                })}
                subtitle={intl.formatMessage(
                  {
                    id: "restaurants.devices.delete.device.modal.subtitle",
                    defaultMessage:
                      "You are about to remove {deviceKey}.\nDoing this will log this device out of Crmb. Devices can be re-added at anytime.",
                  },
                  { deviceKey: record.deviceKey }
                )}
                confirmButtonText={intl.formatMessage({
                  id: "restaurants.devices.delete.device.modal.confirm.button.text",
                  defaultMessage: "Remove device",
                })}
              />
            )}
          </Container>
        }
      >
        <Icon
          remixiconClass="ri-more-fill"
          cursor="pointer"
          color="Neutral5"
          size={16}
        />
      </DropDownAction>
    </Container>
  )
}
