import type CSS from "csstype"
import type { LegacyRef } from "react"
import type React from "react"
import type { IBaseThemeColors } from "styled-components"
import styled, { css } from "styled-components"

type DivProps = React.ComponentProps<"div">

export interface ContainerProps extends Omit<DivProps, "ref"> {
  display?: CSS.Property.Display
  alignItems?: CSS.Property.AlignItems
  justifyContent?: CSS.Property.JustifyContent
  textAlign?: CSS.Property.TextAlign
  whiteSpace?: CSS.Property.WhiteSpace
  position?: CSS.Property.Position
  centered?: boolean | number
  fullScreen?: boolean
  reference?: LegacyRef<HTMLDivElement> | undefined
  minHeight?: CSS.Property.MinHeight
  minWidth?: CSS.Property.MinWidth
  maxHeight?: CSS.Property.MaxHeight
  maxWidth?: CSS.Property.MaxWidth
  height?: CSS.Property.Height
  width?: CSS.Property.Width
  flexDirection?: CSS.Property.FlexDirection
  padding?: CSS.Property.Padding
  margin?: CSS.Property.Margin
  flexWrap?: CSS.Property.FlexWrap
  flex?: CSS.Property.Flex
  right?: CSS.Property.Right
  left?: CSS.Property.Left
  gap?: CSS.Property.Gap
  readonly?: boolean
  pointerEvents?: CSS.Property.PointerEvents
  overFlowX?: CSS.Property.OverflowX
  overFlowY?: CSS.Property.OverflowY
  cursor?: CSS.Property.Cursor
  background?: keyof IBaseThemeColors
  visibleOnHover?: boolean
  alignSelf?: CSS.Property.AlignSelf
  justifySelf?: CSS.Property.JustifySelf
  visibility?: CSS.Property.Visibility
}

export const StyledContainer = styled.div<ContainerProps>`
  ${({ centered, fullScreen }) =>
    !!centered &&
    css`
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      ${!!fullScreen &&
      css`
        min-height: 70vh;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      `}
    `}

  ${({ pointerEvents }) =>
    !!pointerEvents &&
    css`
      pointer-events: ${pointerEvents};
    `}

  ${({ readonly }) => css`
    pointer-events: ${readonly ? "none" : "auto"};
    opacity: ${readonly ? 0.8 : 1};
  `}

  ${({ display }) =>
    !!display &&
    css`
      display: ${display};
    `}

  ${({ padding }) =>
    !!padding &&
    css`
      padding: ${padding};
    `}
    
  ${({ margin }) =>
    !!margin &&
    css`
      margin: ${margin};
    `}

  ${({ alignItems }) =>
    !!alignItems &&
    css`
      align-items: ${alignItems};
    `}

  ${({ justifyContent }) =>
    !!justifyContent &&
    css`
      justify-content: ${justifyContent};
    `}

  ${({ textAlign }) =>
    !!textAlign &&
    css`
      text-align: ${textAlign};
    `}
    
  ${({ position }) =>
    !!position &&
    css`
      position: ${position};
    `}

  ${({ minHeight }) =>
    !!minHeight &&
    css`
      min-height: ${minHeight};
    `}

    ${({ minWidth }) =>
    !!minWidth &&
    css`
      min-width: ${minWidth};
    `}

    ${({ maxHeight }) =>
    !!maxHeight &&
    css`
      max-height: ${maxHeight};
    `}

    ${({ maxWidth }) =>
    !!maxWidth &&
    css`
      max-width: ${maxWidth};
    `}

  ${({ flexDirection }) =>
    !!flexDirection &&
    css`
      flex-direction: ${flexDirection};
    `}

    ${({ flexWrap }) =>
    !!flexWrap &&
    css`
      flex-wrap: ${flexWrap};
    `}
      
    ${({ flex }) =>
    !!flex &&
    css`
      flex: ${flex};
    `}

  ${({ gap }) =>
    !!gap &&
    css`
      gap: ${gap};
    `}

    ${({ width }) =>
    !!width &&
    css`
      width: ${width};
    `}

    ${({ right }) =>
    !!right &&
    css`
      right: ${right};
    `}

    ${({ left }) =>
    !!left &&
    css`
      left: ${left};
    `}

    ${({ overFlowX }) =>
    !!overFlowX &&
    css`
      overflow-x: ${overFlowX};
    `}
    
    ${({ overFlowY }) =>
    !!overFlowY &&
    css`
      overflow-y: ${overFlowY};
    `}

    ${({ cursor }) =>
    !!cursor &&
    css`
      cursor: ${cursor};
    `}
      
    ${({ height }) =>
    !!height &&
    css`
      height: ${height};
    `}

    ${({ whiteSpace: WhiteSpace }) =>
    !!WhiteSpace &&
    css`
      white-space: ${WhiteSpace};
    `}

    ${({ justifySelf }) =>
    !!justifySelf &&
    css`
      justify-self: ${justifySelf};
    `}

    ${({ alignSelf }) =>
    !!alignSelf &&
    css`
      align-self: ${alignSelf};
    `}

    ${({ background, theme }) =>
    !!background &&
    css`
      background: ${theme.colors[background]};
    `}

    ${({ visibleOnHover }) =>
    !!visibleOnHover &&
    css`
      * {
        visibility: hidden;
      }

      &:hover * {
        visibility: visible;
      }
    `}

    ${({ visibility }) =>
    !!visibility &&
    css`
      visibility: ${visibility};
    `}
`
