import Icon from "../../../../Icon"
import Progress from "../../../../Progress"
import Text from "../../../../Typography/Text"
import { RestaurantCompletionProgressState } from "../useRestaurantCompletionProgress"
import RestaurantCompletionStep from "./RestaurantCompletionStep"
import { Drawer, DrawerProps } from "antd"
import { useIntl } from "react-intl"
import styled from "styled-components"

interface RestaurantCompletionProgressDrawerProps
  extends Pick<DrawerProps, "visible"> {
  onClose: () => void
  restaurantCompletionProgressState: RestaurantCompletionProgressState
}

const RestaurantCompletionProgressDrawer = ({
  visible,
  onClose,
  restaurantCompletionProgressState,
}: RestaurantCompletionProgressDrawerProps) => {
  const intl = useIntl()
  const { percent, steps } = restaurantCompletionProgressState

  return (
    <StyledDrawer
      width={350}
      visible={visible}
      onClose={onClose}
      closeIcon={<Icon remixiconClass="ri-close-line" color="Neutral9" />}
    >
      <Header>
        <Progress type="circle" percent={percent} width={65} strokeWidth={8} />
        <div>
          <Text size="s" weight="bold" color="Neutral9">
            {intl.formatMessage({
              id: "components.restaurant.completion.progress.drawer.header.title",
              defaultMessage: "Complete Your Data",
            })}
          </Text>
          <Text size="s" color="Neutral8">
            {intl.formatMessage({
              id: "components.restaurant.completion.progress.drawer.header.description",
              defaultMessage:
                "*To publish your app, the information must be 100% complete.",
            })}
          </Text>
        </div>
      </Header>
      <Steps>
        {steps.map((step) => (
          <RestaurantCompletionStep
            key={step.title}
            link={step.link}
            title={step.title}
            completed={step.completed}
            onClick={onClose}
          />
        ))}
      </Steps>
    </StyledDrawer>
  )
}

const Steps = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0px 12px;
  margin-top: 20px;
  gap: 12px;
`

const StyledDrawer = styled(Drawer)`
  .ant-drawer-body {
    padding: 60px 25px;
    background-color: ${({ theme }) => theme.colors.Neutral0};
  }
`

const Header = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 16px;
  border-right: 1px solid ${({ theme }) => theme.colors.Neutral3};
`

export default RestaurantCompletionProgressDrawer
