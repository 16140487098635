import { Flex, Group, Skeleton, Stack } from "@mantine/core"

export const EmployeesSkeleton = () => {
  return (
    <>
      {Array.from({ length: 2 }).map((_, index) => (
        <Flex key={index} p={12} justify="space-between">
          <Group>
            <Skeleton h={40} w={40} />
            <Stack>
              <Skeleton h={12} w={100} />
              <Skeleton h={12} w={100} />
            </Stack>
            <Stack>
              <Skeleton h={12} w={100} />
              <Skeleton h={12} w={100} />
            </Stack>
          </Group>
          <Group>
            <Skeleton h={24} w={140} />
            <Skeleton h={24} w={140} />
            <Skeleton h={24} w={24} />
          </Group>
        </Flex>
      ))}
    </>
  )
}
