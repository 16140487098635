import Container from "../../../../../ui/Container"
import CardNumberInput from "../../../../../ui/Inputs/CardNumberInput"
import Input from "../../../../../ui/Inputs/Input"
import RadioGroupButton from "../../../../../ui/Radio/RadioGroupButton"
import Select from "../../../../../ui/Select"
import Spacer from "../../../../../ui/Spacer"
import countryOptions from "../AddAccountOrDebitCard/countryOptions"
import currencyOptions from "../AddAccountOrDebitCard/currencyOptions"
import holderTypeOptions from "../AddAccountOrDebitCard/holderTypeOptions"
import tokenTypeOptions from "../AddAccountOrDebitCard/tokenTypeOptions"
import type { IAccountOrDebitCard } from "./hookforms.interfaces"
import React from "react"
import { Controller, useFormContext } from "react-hook-form"
import { useIntl } from "react-intl"

type AccountDebitCardFormProps = Omit<React.ComponentProps<"form">, "ref">

export const AccountDebitCardForm: React.FC<AccountDebitCardFormProps> = ({
  ...props
}) => {
  const intl = useIntl()

  const {
    control,
    watch,
    formState: { errors },
  } = useFormContext<IAccountOrDebitCard>()

  const typeWatcher = watch("type")

  return (
    <form role="create-token-form" {...props}>
      <Container centered>
        <Controller
          control={control}
          name="type"
          render={({ field }) => (
            <RadioGroupButton
              value={field.value}
              items={tokenTypeOptions.map(
                ({ value, labelId, defaultMessage }) => ({
                  value,
                  label: intl.formatMessage({ id: labelId, defaultMessage }),
                })
              )}
              onChange={field.onChange}
            />
          )}
        />
      </Container>
      <Spacer size={34} />
      <Container display="flex" flexDirection="column">
        {typeWatcher === "card" && (
          <Container>
            <CardNumberInput />
          </Container>
        )}
        <Container
          display="flex"
          flexDirection="column"
          gap="24px"
          hidden={typeWatcher === "card"}
        >
          <Controller
            control={control}
            name="country"
            render={({ field: { onChange, value } }) => (
              <Select
                value={value}
                onChange={onChange}
                label={intl.formatMessage({
                  id: "restaurants.bank.accounts.token.form.country",
                  defaultMessage: "Country",
                })}
                requirement="required"
                options={countryOptions}
                hasError={!!errors.country}
                helperText={errors.country?.message}
              />
            )}
          />

          <Controller
            control={control}
            name="currency"
            render={({ field: { onChange, value } }) => (
              <Select
                value={value}
                onChange={onChange}
                label={intl.formatMessage({
                  id: "restaurants.bank.accounts.token.form.currency",
                  defaultMessage: "Currency",
                })}
                requirement="required"
                options={currencyOptions}
                hasError={!!errors.currency}
                helperText={errors.currency?.message}
              />
            )}
          />

          <Controller
            control={control}
            name="routing_number"
            render={({ field }) => (
              <Input
                {...field}
                label={intl.formatMessage({
                  id: "restaurants.bank.accounts.token.form.routing.number",
                  defaultMessage: "Routing Number",
                })}
                requirement="required"
                hasError={!!errors.routing_number}
                helperText={errors.routing_number?.message}
                onlyInteger
              />
            )}
          />

          <Controller
            control={control}
            name="account_number"
            render={({ field }) => (
              <Input
                {...field}
                label={intl.formatMessage({
                  id: "restaurants.bank.accounts.token.form.account.number",
                  defaultMessage: "Account Number",
                })}
                requirement="required"
                hasError={!!errors.account_number}
                helperText={errors.account_number?.message}
                onlyInteger
              />
            )}
          />

          <Controller
            control={control}
            name="account_holder_name"
            render={({ field }) => (
              <Input
                {...field}
                label={intl.formatMessage({
                  id: "restaurants.bank.accounts.token.form.account.holder.name",
                  defaultMessage: "Account Holder Name",
                })}
                requirement="required"
                hasError={!!errors.account_holder_name}
                helperText={errors.account_holder_name?.message}
                onlyLetters
              />
            )}
          />
          <Controller
            control={control}
            name="account_holder_type"
            render={({ field: { onChange, value } }) => (
              <Select
                value={value}
                onChange={onChange}
                label={intl.formatMessage({
                  id: "restaurants.bank.accounts.token.form.account.holder.type",
                  defaultMessage: "Account Holder Type",
                })}
                requirement="required"
                options={holderTypeOptions.map(
                  ({ value: cardType, labelId, defaultMessage }) => ({
                    value: cardType,
                    label: intl.formatMessage({ id: labelId, defaultMessage }),
                  })
                )}
                hasError={!!errors.account_holder_type}
                helperText={errors.account_holder_type?.message}
              />
            )}
          />
        </Container>
      </Container>
      <button type="submit" className="hidden" />
    </form>
  )
}
