import Text from "../Typography/Text"
import type { RadioProps as AntdRadioProps } from "antd"
import { Radio as AntdRadio } from "antd"
import classnames from "classnames"
import type { ReactNode, RefObject } from "react"
import React from "react"
import type { IBody } from "styled-components"
import styled, { css } from "styled-components"

export type RadioProps = AntdRadioProps & {
  content?: string | ReactNode
  $classId: string
  size?: keyof IBody
}

export const Radio = React.forwardRef(
  (
    radioProps: RadioProps,
    ref:
      | ((instance: HTMLParagraphElement | null) => void)
      | RefObject<HTMLParagraphElement>
      | null
      | undefined
  ) => {
    const {
      content,
      className,
      $classId,
      disabled,
      size = "s",
      ...props
    } = radioProps

    return (
      <StyledRadio
        className={classnames(className, $classId)}
        disabled={disabled}
        $classId={$classId}
        {...props}
      >
        {content && (
          <Text size={size} ref={ref} textAlign="center">
            {content}
          </Text>
        )}
      </StyledRadio>
    )
  }
)

interface StyledRadioProps {
  $classId: string
}

const StyledRadio = styled(AntdRadio)<StyledRadioProps>`
  ${({ $classId }) => css`
    &.${$classId} {
      display: flex;
      align-items: center;

      & .ant-radio {
        top: 0;
      }

      & .ant-radio .ant-radio-inner {
        ${({ theme }) => css`
          border-color: ${theme.colors["Neutral4"]};
        `}
      }

      & .ant-radio:hover .ant-radio-inner {
        ${({ theme }) => css`
          border-color: ${theme.colors["Primary5"]};
        `}
      }

      & .ant-radio-checked .ant-radio-inner {
        ${({ theme }) => css`
          border-color: ${theme.colors["Primary5"]};
        `}
      }

      & .ant-radio-checked .ant-radio-inner:after {
        ${({ theme }) => css`
          background-color: ${theme.colors["Primary5"]};
        `}
      }

      & .ant-radio-disabled .ant-radio-inner {
        ${({ theme }) => css`
          border-color: ${theme.colors["Neutral4"]} !important;
          background-color: ${theme.colors["Neutral3"]};
        `}
      }

      & .ant-radio-disabled .ant-radio-inner:after {
        ${({ theme }) => css`
          background-color: ${theme.colors["TransparentDark"]};
        `}
      }

      &.ant-radio-wrapper-disabled .ant-typography {
        ${({ theme }) => css`
          color: ${theme.colors["TransparentDark"]};
        `}
      }

      &.image {
        margin: 0;
        border: 1px solid ${({ theme }) => `${theme.colors["Neutral4"]}`};

        .ant-radio {
          display: none;
        }

        &.ant-radio-wrapper {
          display: flex;
          width: 140px;
          height: 140px;
          align-items: center;
          justify-content: center;
          border-radius: 8px;

          * {
            max-width: 100%;
            max-height: 100%;
            padding: 0;
          }
        }

        &.ant-radio-wrapper-checked {
          border: 1px solid ${({ theme }) => `${theme.colors["Primary5"]}`};
          box-shadow: ${({ theme }) => `${theme.shadows.elevationHigh}`};
        }
      }
    }
  `}
`
