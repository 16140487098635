import { ReactComponent as LoaderIcon } from "../../shared/assets/icons/loader-5-line.svg"
import type { SpinProps } from "antd"
import { Spin } from "antd"
import type CSS from "csstype"
import React from "react"
import { useIntl } from "react-intl"
import styled, { css } from "styled-components"

interface LoaderProps extends SpinProps {
  text?: string
  loading?: boolean
  marginTop?: CSS.Property.MarginTop
}

export const Loader: React.FC<LoaderProps> = ({
  children,
  text,
  loading = true,
  marginTop = "0px",
  ...rest
}) => {
  const intl = useIntl()

  const loadingText =
    text === undefined
      ? intl.formatMessage({
          id: "components.loader.message",
          defaultMessage: "Loading...",
        })
      : text

  return loading ? (
    <StyledLoader
      tip={loadingText}
      $marginTop={marginTop}
      {...rest}
      indicator={<LoaderIcon className="spinning" />}
    />
  ) : (
    <>{children}</>
  )
}

interface StyledLoaderProps {
  $marginTop: CSS.Property.MarginTop
}

const StyledLoader = styled(Spin)<StyledLoaderProps>`
  width: 100%;
  &.ant-spin {
    color: ${(props) => props.theme.colors["Secondary5"]};
  }
  ${({ $marginTop }) =>
    css`
      margin-top: ${$marginTop};
    `}
`
