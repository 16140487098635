import type { GetAllLocationsByUserQuery } from "../../../../../GraphQL/Queries/getAllLocationsByUser.generated"
import { useGetAllLocationsByUserQuery } from "../../../../../GraphQL/Queries/getAllLocationsByUser.generated"
import { ActionTypes } from "../../../../../pages/Restaurants/actions"
import { useGeneralContext } from "../../../../../shared/contexts/StoreProvider"
import { updateGetAllLocationsByUser } from "../../../../../shared/graphql/updateQuery/updateGetAllLocationsByUser"
import paths from "../../../../../shared/routes/paths"
import Container from "../../../../Container"
import { showGraphqlErrors } from "../../../../ErrorList"
import Icon from "../../../../Icon"
import InlineMenuItem from "../../../../Menu/InlineMenuItem"
import SubMenu from "../../../../Menu/SubMenu"
import SubMenuItem from "../../../../Menu/SubMenu/SubMenuItem"
import SubTitleMenu from "../../../../MenuSubTitle"
import Spacer from "../../../../Spacer"
import RestaurantCompletionProgress from "../../../components/RestaurantCompletionProgress"
import { locationItems } from "../locationItems"
import { sidebarItems } from "../sidebarItems"
import { SidebarLocationMenuSkeleton } from "./SidebarLocationMenu.skeleton"
import { Menu } from "antd"
import { useFlags } from "launchdarkly-react-client-sdk"
import React, { useCallback, useEffect, useMemo, useState } from "react"
import { useIntl } from "react-intl"
import { NavLink, useHistory, useLocation } from "react-router-dom"
import styled from "styled-components"

const getSubmenuKey = (locationUUID: string) =>
  `submenu-location-${locationUUID}`

export const SidebarMenu: React.FC = () => {
  const intl = useIntl()
  const { pathname } = useLocation()
  const { push } = useHistory()

  const { discounts, completionProgress, apEnablePayroll, apEnableCatering } =
    useFlags()
  const [openSubMenu, setOpenSubmenu] = useState<string | undefined>()

  const {
    state: {
      currentRestaurant: { uuid: restaurantUUID },
      selectedLocation: { uuid: locationUUID },
      auth: {
        admin: { uuid: userUUID },
      },
    },
    dispatch,
  } = useGeneralContext()

  const { data, loading, fetchMore } = useGetAllLocationsByUserQuery({
    variables: { restaurantUUID, userUUID, isActive: true },
    skip: !restaurantUUID || !userUUID,
  })

  const locations = data?.getAllLocationsByUser?.results ?? []
  const hasNextPage = data?.getAllLocationsByUser?.hasNextPage ?? false
  const endCursor = data?.getAllLocationsByUser?.endCursor ?? null
  const canFetchMore = !loading && hasNextPage && endCursor

  const showAddLocation = () => {
    push(paths.settings.locations.create)
  }

  const onSelectedLocation = (selectedLocationUUID: string) => {
    dispatch({
      type: ActionTypes.SetCurrentState,
      payload: {
        restaurantUUID,
        locationUUID: selectedLocationUUID,
      },
    })
  }

  const clearSelectedLocation = () => onSelectedLocation("")

  const menusByRoute = useMemo(
    () => [
      {
        route: paths.restaurants.locations.root,
        key: getSubmenuKey(locationUUID),
      },
    ],
    [locationUUID]
  )

  const defaultOpenKeys = useMemo(
    () =>
      menusByRoute
        .filter(({ route }) => pathname.includes(route))
        .map(({ key }) => key),
    [menusByRoute, pathname]
  )

  const getSelectedKey = () => {
    let selectedKey = pathname

    sidebarItems.some((value) => {
      if (pathname.includes(value.path)) selectedKey = value.path
    })

    locationItems.some((value) => {
      if (pathname.includes(value.path(locationUUID)))
        selectedKey = value.path(locationUUID)
    })

    return selectedKey
  }

  const fetchMoreLocationsByUser = useCallback(async () => {
    try {
      await fetchMore({
        variables: {
          restaurantUUID,
          userUUID,
          isActive: true,
          after: endCursor,
        },
        updateQuery: (prev: GetAllLocationsByUserQuery, { fetchMoreResult }) =>
          updateGetAllLocationsByUser(prev, fetchMoreResult),
      })
    } catch (error) {
      showGraphqlErrors(error)
    }
  }, [endCursor, fetchMore, restaurantUUID, userUUID])

  const onScroll = (event: React.UIEvent<HTMLDivElement, UIEvent>) => {
    const target = event.target as HTMLElement
    const scrolledToBottom =
      Math.abs(target.scrollHeight - target.clientHeight - target.scrollTop) < 1

    if (canFetchMore && scrolledToBottom) {
      fetchMoreLocationsByUser()
    }
  }

  useEffect(() => {
    setOpenSubmenu(defaultOpenKeys[0])
  }, [defaultOpenKeys])

  return (
    <Container
      padding="8px 16px"
      height="100%"
      overFlowY="auto"
      onScroll={onScroll}
    >
      <StyledSidebarMenu
        inlineIndent={8}
        className="sidebar__menu"
        id="sidebar__menu"
        mode="inline"
        role="sidebar"
        selectedKeys={[getSelectedKey()]}
        defaultOpenKeys={defaultOpenKeys}
        openKeys={openSubMenu ? [openSubMenu] : []}
      >
        {restaurantUUID && (
          <>
            {sidebarItems.map((menuItem) => {
              if (menuItem.title === "Discounts" && !discounts) {
                return
              }

              if (menuItem.title === "Catering" && !apEnableCatering) {
                return
              }

              return (
                <InlineMenuItem
                  className="menu-items-with-icon"
                  icon={<Icon size={12} remixiconClass={menuItem.icon} />}
                  key={menuItem.path}
                  allowed={menuItem.allowedRoles}
                >
                  <NavLink to={menuItem.path} onClick={clearSelectedLocation}>
                    {menuItem.title}
                  </NavLink>
                </InlineMenuItem>
              )
            })}

            {locations.length > 0 ? (
              <SubTitleMenu
                title={intl.formatMessage({
                  id: "components.main.sidebar.locations.title",
                  defaultMessage: "Locations",
                })}
              />
            ) : null}

            {loading ? (
              <SidebarLocationMenuSkeleton />
            ) : (
              locations?.map((location) => (
                <SubMenu
                  key={getSubmenuKey(location.uuid)}
                  title={location.name}
                  className="sidebar-submenu submenu-items"
                  icon={<Icon size={16} remixiconClass="ri-settings-3-line" />}
                  onTitleClick={() => {
                    setOpenSubmenu((prev) =>
                      prev === getSubmenuKey(location.uuid)
                        ? undefined
                        : getSubmenuKey(location.uuid)
                    )
                  }}
                >
                  {locationItems.map((locationItem) =>
                    (locationItem.name === "Payroll" && !apEnablePayroll) ||
                    (locationItem.name === "Catering" &&
                      !apEnableCatering) ? null : (
                      <SubMenuItem
                        key={locationItem.path(location.uuid)}
                        className="sidebar-submenu__item"
                      >
                        <NavLink
                          to={locationItem.path(location.uuid)}
                          onClick={() => onSelectedLocation(location.uuid)}
                        >
                          {locationItem.name}
                        </NavLink>
                      </SubMenuItem>
                    )
                  )}
                </SubMenu>
              ))
            )}

            {locations.length > 0 ? null : (
              <>
                <InlineMenuItem
                  className="add-location"
                  icon={<Icon size={16} remixiconClass="ri-add-line" />}
                  key="create-location"
                  onClick={showAddLocation}
                  allowed={["RESTAURANT_OWNER", "RESTAURANT_ADMIN"]}
                >
                  {intl.formatMessage({
                    id: "components.main.sidebar.locations.add",
                    defaultMessage: "Add Location",
                  })}
                </InlineMenuItem>
              </>
            )}

            {locations?.length ? <Spacer size={16} /> : null}
          </>
        )}
        {completionProgress && (
          <div className="footer">
            <RestaurantCompletionProgress />
          </div>
        )}
      </StyledSidebarMenu>
    </Container>
  )
}

const StyledSidebarMenu = styled(Menu)`
  border-right: none;
  min-height: 100%;
  display: flex;
  flex-direction: column;

  .ant-menu-vertical .ant-menu-item::after,
  .ant-menu-vertical-left .ant-menu-item::after,
  .ant-menu-vertical-right .ant-menu-item::after,
  .ant-menu-inline .ant-menu-item::after {
    border: none;
  }
  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: ${({ theme }) => theme.colors["Primary1"]};
  }
  .ant-menu-item {
    .anticon {
      width: 16px !important ;
    }
  }
  .sidebar-submenu__item {
    padding-left: 33px !important ;
  }

  .add-location {
    margin-top: 16px;
  }
  .ant-menu-submenu-vertical {
    display: none;
  }
  .footer {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: sticky;
    width: 100%;
    bottom: 0px;
    padding-bottom: 56px;
    background: ${({ theme }) => theme.colors.Neutral0};
  }
`
