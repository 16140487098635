import * as Types from '../../../../../shared/graphql/generated/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SetupApiKeysMutationVariables = Types.Exact<{
  data: Types.SaveIntegrationApiKeysInput;
}>;


export type SetupApiKeysMutation = { __typename?: 'Mutation', setupApiKeys: { __typename?: 'Integration', accessToken: string, appId: string, externalId: string, source: Types.ExternalSourceEnum, uuid: string } };


export const SetupApiKeysDocument = gql`
    mutation SetupApiKeys($data: SaveIntegrationApiKeysInput!) {
  setupApiKeys(data: $data) {
    accessToken
    appId
    externalId
    source
    uuid
  }
}
    `;
export type SetupApiKeysMutationFn = Apollo.MutationFunction<SetupApiKeysMutation, SetupApiKeysMutationVariables>;

/**
 * __useSetupApiKeysMutation__
 *
 * To run a mutation, you first call `useSetupApiKeysMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetupApiKeysMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setupApiKeysMutation, { data, loading, error }] = useSetupApiKeysMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSetupApiKeysMutation(baseOptions?: Apollo.MutationHookOptions<SetupApiKeysMutation, SetupApiKeysMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetupApiKeysMutation, SetupApiKeysMutationVariables>(SetupApiKeysDocument, options);
      }
export type SetupApiKeysMutationHookResult = ReturnType<typeof useSetupApiKeysMutation>;
export type SetupApiKeysMutationResult = Apollo.MutationResult<SetupApiKeysMutation>;
export type SetupApiKeysMutationOptions = Apollo.BaseMutationOptions<SetupApiKeysMutation, SetupApiKeysMutationVariables>;