import * as Types from '../../../../../shared/graphql/generated/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetExternalLocationsQueryVariables = Types.Exact<{
  externalIntegration: Types.RuuidInput;
  integrationType: Types.ExternalIntegrationEnum;
}>;


export type GetExternalLocationsQuery = { __typename?: 'Query', getExternalLocations: Array<{ __typename?: 'ExternalLocation', id: string, name: string }> };


export const GetExternalLocationsDocument = gql`
    query getExternalLocations($externalIntegration: RUUIDInput!, $integrationType: ExternalIntegrationEnum!) {
  getExternalLocations(
    externalIntegration: $externalIntegration
    integrationType: $integrationType
  ) {
    id
    name
  }
}
    `;

/**
 * __useGetExternalLocationsQuery__
 *
 * To run a query within a React component, call `useGetExternalLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExternalLocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExternalLocationsQuery({
 *   variables: {
 *      externalIntegration: // value for 'externalIntegration'
 *      integrationType: // value for 'integrationType'
 *   },
 * });
 */
export function useGetExternalLocationsQuery(baseOptions: Apollo.QueryHookOptions<GetExternalLocationsQuery, GetExternalLocationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetExternalLocationsQuery, GetExternalLocationsQueryVariables>(GetExternalLocationsDocument, options);
      }
export function useGetExternalLocationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetExternalLocationsQuery, GetExternalLocationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetExternalLocationsQuery, GetExternalLocationsQueryVariables>(GetExternalLocationsDocument, options);
        }
export type GetExternalLocationsQueryHookResult = ReturnType<typeof useGetExternalLocationsQuery>;
export type GetExternalLocationsLazyQueryHookResult = ReturnType<typeof useGetExternalLocationsLazyQuery>;
export type GetExternalLocationsQueryResult = Apollo.QueryResult<GetExternalLocationsQuery, GetExternalLocationsQueryVariables>;