import safeNumber from "../../../../../../shared/utils/helpers/safeNumber"
import { getPaymentMethods } from "../../../utils/format-catering-order"
import { RuleCollapse } from "../../components/RuleCollapse"
import { RuleChip } from "../../components/RuleCollapse/RuleChip"
import { RulesMessage } from "../../shared/config"
import type { CateringForm } from "../../shared/hookforms.intefaces"
import { FeeQuantityEnum } from "../../shared/hookforms.intefaces"
import { validationCateringTitle } from "../../shared/validation-catering.title"
import {
  Flex,
  Input,
  NumberInput,
  SegmentedControl,
  Stack,
  Text,
  useMantineTheme,
} from "@mantine/core"
import { IconCurrencyDollar, IconPercentage } from "@tabler/icons-react"
import { Controller, useFormContext, useWatch } from "react-hook-form"
import { useIntl } from "react-intl"

const paymentMethods: FeeQuantityEnum[] = [
  FeeQuantityEnum.AMOUNT,
  FeeQuantityEnum.PERCENTAGE,
]

export const PaymentForm = () => {
  const intl = useIntl()
  const mantineTheme = useMantineTheme()
  const {
    control,
    setValue,
    trigger,
    formState: { errors },
  } = useFormContext<CateringForm>()
  const [isActive, prePayment] = useWatch({
    control,
    name: ["isActive", "rules.prePayment"],
  })

  const segmentedControlData = getPaymentMethods(paymentMethods).map(
    (type) => ({
      value: type.value,
      label: intl.formatMessage({
        id: type.label.id,
        defaultMessage: type.label.defaultMessage,
      }),
    })
  )

  const amountPayment =
    prePayment.paymentMethod === FeeQuantityEnum.PERCENTAGE
      ? safeNumber(prePayment.paymentValue) / 100
      : safeNumber(prePayment.paymentValue)

  return (
    <RuleCollapse
      label={intl.formatMessage(RulesMessage.payment.title)}
      error={errors.rules?.prePayment?.paymentValue?.message ? true : false}
      rightSection={
        prePayment.paymentValue && (
          <RuleChip
            data={[
              intl.formatMessage(
                {
                  id: "restaurants.settings.location.catering.rules.payment.chip.label",
                  defaultMessage: "{paymentValue} in advance",
                },
                {
                  paymentValue: intl.formatNumber(amountPayment, {
                    style:
                      prePayment.paymentMethod === FeeQuantityEnum.AMOUNT
                        ? "currency"
                        : "percent",
                    currency: "USD",
                  }),
                }
              ),
            ]}
            disabled={!isActive}
          />
        )
      }
      disabled={!isActive}
    >
      <Stack spacing={16}>
        <Text size="sm">
          {intl.formatMessage(RulesMessage.payment.description)}
        </Text>
        <Input.Wrapper
          label={intl.formatMessage({
            id: "catering.payment.form.input.label",
            defaultMessage: "Select amount or percentage",
          })}
          required
          styles={{
            label: {
              fontWeight: 600,
            },
          }}
        >
          <Flex align="flex-start" mt={4} gap={8}>
            <Controller
              control={control}
              name="rules.prePayment.paymentMethod"
              render={({ field }) => (
                <SegmentedControl
                  h={36}
                  {...field}
                  onChange={(value) => {
                    field.onChange(value)
                    setValue("rules.prePayment.paymentValue", 0)
                    trigger([
                      "rules.minimumAmount",
                      "rules.prePayment.paymentValue",
                    ])
                  }}
                  data={segmentedControlData}
                />
              )}
            />
            <Stack spacing={2}>
              <Controller
                control={control}
                name="rules.prePayment.paymentValue"
                render={({ field, fieldState: { error } }) => {
                  if (prePayment.paymentMethod === FeeQuantityEnum.AMOUNT) {
                    return (
                      <NumberInput
                        {...field}
                        onChange={(value) => {
                          field.onChange(value)
                          trigger("rules.minimumAmount")
                        }}
                        type="number"
                        error={
                          error?.message
                            ? intl.formatMessage(
                                validationCateringTitle[error?.message]
                              )
                            : undefined
                        }
                        rightSection={
                          <IconCurrencyDollar
                            size={16}
                            color={mantineTheme.colors.gray[6]}
                          />
                        }
                      />
                    )
                  }
                  return (
                    <NumberInput
                      {...field}
                      type="number"
                      max={100}
                      error={
                        error?.message
                          ? intl.formatMessage(
                              validationCateringTitle[error?.message]
                            )
                          : undefined
                      }
                      rightSection={
                        <IconPercentage
                          size={16}
                          color={mantineTheme.colors.gray[6]}
                        />
                      }
                    />
                  )
                }}
              />
              {!errors.rules?.prePayment?.paymentValue?.message && (
                <Text size="xs" c="gray.6">
                  {intl.formatMessage({
                    id: "catering.payment.form.input.description",
                    defaultMessage: "Only numbers are allowed",
                  })}
                </Text>
              )}
            </Stack>
          </Flex>
        </Input.Wrapper>
      </Stack>
    </RuleCollapse>
  )
}
