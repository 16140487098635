import classnames from "classnames"
import type CSS from "csstype"
import get from "lodash/get"
import React from "react"
import type { IBaseThemeColors } from "styled-components"
import styled, { css } from "styled-components"

export type IconColor = keyof Pick<
  IBaseThemeColors,
  | "Neutral0"
  | "Neutral9"
  | "Neutral8"
  | "Neutral7"
  | "Neutral6"
  | "Neutral5"
  | "Neutral4"
  | "Primary6"
  | "Primary5"
  | "Primary4"
  | "Primary3"
  | "Secondary5"
  | "Success5"
  | "Success4"
  | "Warning5"
  | "Warning4"
  | "Danger4"
  | "Danger5"
  | "transparent"
>

export type IconProps = {
  remixiconClass: string
  size?: number
  color?: IconColor
  classes?: string
  onClick?: React.MouseEventHandler<HTMLInputElement>
  cursor?: CSS.Property.Cursor
  alignSelf?: CSS.Property.AlignSelf
  verticalAlign?: CSS.Property.VerticalAlign
  spinning?: boolean
  display?: CSS.Property.Display
  alignItems?: CSS.Property.AlignItems
}

export const Icon: React.FC<IconProps> = ({
  remixiconClass,
  classes,
  spinning,
  onClick,
  ...props
}) => {
  return (
    <StyledIcon
      {...props}
      className={classnames("anticon", classes)}
      role="anticon"
      onClick={onClick}
    >
      <i
        className={classnames({ spinning }, remixiconClass)}
        role={remixiconClass}
      />
    </StyledIcon>
  )
}

Icon.defaultProps = {
  size: 16,
}

type StyledIconProps = Omit<IconProps, "remixiconClass"> & {
  color?: keyof IBaseThemeColors
}

const StyledIcon = styled.div<StyledIconProps>`
  display: ${({ display }) => (display ? display : "")};
  align-items: ${({ alignItems }) => (alignItems ? alignItems : "")};
  ${({ cursor }) =>
    cursor &&
    css`
      &:hover {
        cursor: ${cursor};
      }
    `}
  ${({ alignSelf }) =>
    alignSelf &&
    css`
      align-self: ${alignSelf};
    `}
    ${({ verticalAlign }) =>
    verticalAlign &&
    css`
      vertical-align: ${verticalAlign};
    `}
    ${({ size }) =>
    css`
      width: ${size}px;
      height: ${size}px;
    `}
    i {
    ${({ size }) =>
      css`
        font-size: ${size}px;
      `}

    ${({ color = "Neutral9", theme }) =>
      color &&
      css`
        color: ${get(theme, `colors['${color}']`)};
      `}
  }

  i::before {
    ${({ size }) =>
      css`
        display: inline-block;
        width: ${size}px;
        height: ${size}px;
      `}
  }
`
