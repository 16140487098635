import { device } from "../../../../shared/breakpoints/responsive"
import { useGeneralContext } from "../../../../shared/contexts/StoreProvider"
import {
  AppHistoryStatusEnum,
  AttachmentTypeEnum,
} from "../../../../shared/graphql/generated/types"
import { useMediaQuery } from "../../../../shared/hooks/useMediaQuery"
import type { IntlMessage } from "../../../../shared/types"
import { putFileWithSignedUrl } from "../../../../shared/utils/api/client"
import { getImageFile } from "../../../../shared/utils/helpers/getImageFile"
import getImageFilename from "../../../../shared/utils/helpers/getImageFilename"
import getImageMetadata from "../../../../shared/utils/helpers/getImageMetadata"
import {
  ANDROID_DOMAIN,
  IOS_DOMAIN,
  getWhiteLabelConfig,
} from "../../../../shared/utils/helpers/getWhiteLabelConfig"
import { colors } from "../../../../styles/global/themes/mainTheme"
import Alert from "../../../../ui/Alert"
import Button from "../../../../ui/Button"
import Collapse, { CollapsePanel } from "../../../../ui/Collapse"
import ConfirmationModal from "../../../../ui/ConfirmationModal"
import Container from "../../../../ui/Container"
import { showGraphqlErrors } from "../../../../ui/ErrorList"
import TextArea from "../../../../ui/Inputs/TextArea"
import SectionHeader from "../../../../ui/SectionHeader"
import Spacer from "../../../../ui/Spacer"
import Text from "../../../../ui/Typography/Text"
import UploadAvatar from "../../../../ui/UploadAvatar"
import notification from "../../../../ui/notification"
import ColorPicker from "./ColorPicker"
import { useGetAppHistoryQuery } from "./Graphql/getAppHistory.generated"
import { useGetRestaurantWhiteLabelConfigQuery } from "./Graphql/getRestaurantWhiteLabelConfig.generated"
import { useSaveWhiteLabelConfigMutation } from "./Graphql/saveWhiteLabelConfig.generated"
import PublishingHistory from "./PublishingHistory"
import { WhiteLabelApplicationSkeleton } from "./WhiteLabelApplication.skeleton"
import { WhiteLabelApplicationResolver } from "./WhiteLabelApplicationResolver.yup"
import WhiteLabelRequirements from "./WhiteLabelRequirements"
import IconPreview from "./WhiteLabelRequirements/IconPreview"
import type { IFormWhiteLabel, WhiteLabelConfig } from "./hookforms.interfaces"
import { SegmentedComponentValuesEnum } from "./white-label-app.enums"
import type { RcFile } from "antd/lib/upload"
import React, { useState } from "react"
import { Controller, FormProvider, useForm } from "react-hook-form"
import { useIntl } from "react-intl"
import { useLocation } from "react-router-dom"
import styled, { css } from "styled-components"

interface IAppIconPreview {
  width: string
  height: string
  description: IntlMessage
}

const iconPreviews: IAppIconPreview[] = [
  {
    width: "96px",
    height: "96px",
    description: {
      id: "settings.white.label.application.section.application.icon.app.store",
      defaultMessage: "App Store",
    },
  },
  {
    width: "96px",
    height: "96px",
    description: {
      id: "settings.white.label.application.section.application.icon.play.store",
      defaultMessage: "Play Store",
    },
  },
  {
    width: "24px",
    height: "24px",
    description: {
      id: "settings.white.label.application.section.application.icon.notification",
      defaultMessage: "Notification",
    },
  },
]

export const WhiteLabelApplication: React.FC = () => {
  const intl = useIntl()

  const location = useLocation()

  const search = new URLSearchParams(location.search)
  const selectedFilter = search.get("filter")?.toUpperCase()
  const smallerThanMediumScreen = useMediaQuery(device.md)

  const {
    state: {
      currentRestaurant: {
        uuid: restaurantUUID,
        name: restaurantName,
        urlIdentifier,
      },
    },
  } = useGeneralContext()

  const formMethods = useForm<IFormWhiteLabel>({
    defaultValues: {
      appIcon: undefined,
      description: "",
      primaryBrandColor: colors.Neutral8,
    },
    mode: "all",
    resolver: WhiteLabelApplicationResolver,
  })

  const {
    handleSubmit,
    setValue,
    watch,
    control,
    formState: { isValid, dirtyFields },
  } = formMethods

  const avatarPreview = watch("appIcon")

  const [isWaitingForReview, setIsWaitingForReview] = useState<boolean>(false)
  const [showDeleteConfirmationModal, setShowPublishConfirmationModal] =
    useState(false)

  const [createOrUpdateWhite] = useSaveWhiteLabelConfigMutation()

  const { refetch } = useGetAppHistoryQuery({
    variables: {
      restaurantUUID,
      take: 1,
    },
    skip: true,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
  })

  const getLastAppPublish = async () => {
    try {
      const {
        data: { getAppHistory },
      } = await refetch()
      const result = getAppHistory.results

      if (result?.length) {
        setIsWaitingForReview(
          result[0].status === AppHistoryStatusEnum.WAITING_FOR_REVIEW
        )
      }
    } catch {}
  }

  const { loading } = useGetRestaurantWhiteLabelConfigQuery({
    variables: {
      restaurantUUID,
    },
    skip: !restaurantUUID,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
    onCompleted: async (response) => {
      const data = response.getRestaurantWhiteLabelConfig

      if (data.attachment) {
        try {
          const attachmentFile = await getImageFile(data.attachment)
          setValue("appIcon", attachmentFile as RcFile)
        } catch {}
      }

      if (data.whiteLabelConfig) {
        const whiteLabelConfig: WhiteLabelConfig = JSON.parse(
          data.whiteLabelConfig
        )

        const values = {
          uuid: data.uuid,
          whiteLabelConfig: whiteLabelConfig,
        }

        setValue("description", values?.whiteLabelConfig?.description)
        setValue(
          "primaryBrandColor",
          values?.whiteLabelConfig?.styles.colors[0].dark
        )

        getLastAppPublish()
      }
    },
  })

  const onOpenPublishConfirmationModal = () =>
    setShowPublishConfirmationModal(true)

  const onClosePublishConfirmation = () =>
    setShowPublishConfirmationModal(false)

  const onSaveInformation = async (formData: IFormWhiteLabel) => {
    try {
      const whiteLabelConfig = getWhiteLabelConfig({
        restaurantName,
        restaurantUUID,
        urlIdentifier,
        description: formData.description,
        primaryBrandColor: formData.primaryBrandColor,
        iosDomain: IOS_DOMAIN,
        androidDomain: ANDROID_DOMAIN,
      })

      const isShouldPublish = !!(dirtyFields.description || dirtyFields.appIcon)

      const { data } = await createOrUpdateWhite({
        variables: {
          data: {
            restaurantUUID,
            whiteLabelConfig,
            isShouldPublish,
            ...(formData?.appIcon?.uid && {
              attachment: {
                type: AttachmentTypeEnum.APP_LOGO,
                fileName: getImageFilename(formData?.appIcon?.name),
                ...getImageMetadata(formData?.appIcon?.type),
              },
            }),
          },
        },
      })

      getLastAppPublish()

      if (data?.createOrUpdateWhiteLabelConfigToRestaurant.attachment) {
        const signedUrl =
          data?.createOrUpdateWhiteLabelConfigToRestaurant?.attachment
            ?.signedUrl

        if (formData?.appIcon?.uid) {
          try {
            await putFileWithSignedUrl(signedUrl, formData?.appIcon)
          } catch {
            notification({
              description: intl.formatMessage({
                id: "generic.error.image.message",
                defaultMessage: "Your image was unable to save.",
              }),
              type: "error",
            })
            onClosePublishConfirmation()

            return
          }
        }
      }

      onClosePublishConfirmation()

      notification({
        description: intl.formatMessage({
          id: "settings.white.label.application.notification.text",
          defaultMessage:
            "The publication process has started, this will take approximately 15 minutes",
        }),
        type: "success",
      })
    } catch (error) {
      showGraphqlErrors(error)
    }
  }

  if (loading) {
    return <WhiteLabelApplicationSkeleton />
  }

  if (selectedFilter === SegmentedComponentValuesEnum.PUBLISHING) {
    return <PublishingHistory />
  }

  return (
    <FormProvider {...formMethods}>
      <Container display="flex" flexDirection="column">
        <WhiteLabelRequirements />
        <StyledContainer
          display="flex"
          flexDirection="row"
          className="mobile-app-wrapper"
        >
          <StyledDataContainer
            className="data-container"
            display="flex"
            flexDirection="column"
            width="100%"
          >
            <StyledSectionContainerWithBorder>
              <StyledSectionContainer
                display="flex"
                flexDirection="column"
                gap="16px"
              >
                <SectionHeader
                  title={intl.formatMessage({
                    id: "settings.white.label.application.section.header.title.application.settings",
                    defaultMessage: "Application Settings",
                  })}
                  description={intl.formatMessage({
                    id: "settings.white.label.application.section.header.description.application.settings",
                    defaultMessage: "Customize your mobile application",
                  })}
                />

                <Container>
                  <SectionHeader
                    title={intl.formatMessage({
                      id: "settings.white.label.application.section.header.title.application.icon",
                      defaultMessage: "App Icon",
                    })}
                    description={intl.formatMessage({
                      id: "settings.white.label.application.section.header.description.application.icon",
                      defaultMessage: "Image must be 1024px x 1024px",
                    })}
                  />
                  <Spacer size={8} />

                  <Container
                    display="flex"
                    flexDirection="row"
                    width="100%"
                    gap="128px"
                    className="mobile-app-avatar-wrapper"
                  >
                    <Controller
                      name="appIcon"
                      control={control}
                      render={({ field: { value, onChange } }) => {
                        return (
                          <UploadAvatar
                            avatar={value}
                            onChange={(avatar) => {
                              const actualAvatar = value
                              onChange(avatar)
                              !!actualAvatar &&
                                !avatar &&
                                onChange(actualAvatar)
                            }}
                            validateSquareLogo
                          />
                        )
                      }}
                    />

                    {!!avatarPreview && (
                      <StyledUploadAvatar
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                        width="372px"
                        gap="48px"
                        className="mobile-app-styled-avatar-wrapper"
                      >
                        {iconPreviews.map((props, index) => {
                          return (
                            <IconPreview
                              {...props}
                              key={index}
                              src={avatarPreview}
                            />
                          )
                        })}
                      </StyledUploadAvatar>
                    )}
                  </Container>

                  <Spacer size={16} />
                </Container>

                <Container>
                  <SectionHeader
                    title={intl.formatMessage({
                      id: "settings.white.label.application.section.header.title.primary.color",
                      defaultMessage: "Primary Color",
                    })}
                    description={intl.formatMessage({
                      id: "settings.white.label.application.section.header.description.primary.color",
                      defaultMessage: "Select a Primary Brand Color",
                    })}
                  />
                  <Spacer size={14} />
                  <Controller
                    name="primaryBrandColor"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <ColorPicker
                        key={value}
                        color={value}
                        onChange={onChange}
                        title={intl.formatMessage({
                          id: "settings.white.label.application.color.picker.title",
                          defaultMessage: "Custom Primary Color",
                        })}
                        text
                      />
                    )}
                  />
                </Container>
              </StyledSectionContainer>
            </StyledSectionContainerWithBorder>

            <StyledSectionContainer
              className="description-section"
              display="flex"
              flexDirection="column"
              gap="4px"
            >
              <SectionHeader
                title={intl.formatMessage({
                  id: "settings.white.label.application.section.header.title.app.store.settings",
                  defaultMessage: "App Store Settings",
                })}
                description={intl.formatMessage({
                  id: "settings.white.label.application.section.header.description.app.store.settings",
                  defaultMessage:
                    "Add description of your app that will appear in the store",
                })}
              />
              <Controller
                name="description"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <TextArea value={value} onChange={onChange} />
                )}
              />
              {smallerThanMediumScreen && (
                <Alert
                  type="warning"
                  message={intl.formatMessage({
                    id: "settings.white.labe.application.preview.disclaimer.alert",
                    defaultMessage:
                      "App Preview is only availabe for desktop browsers.",
                  })}
                />
              )}
              <Spacer size={8} />
              <Collapse
                className="hidden"
                defaultActiveKey={["description-information"]}
                classId="description-information"
                contentBordered
                removeHeaderBottomBorder
              >
                <CollapsePanel
                  key="description-information"
                  header={
                    <Text size="s">
                      {intl.formatMessage({
                        id: "settings.white.label.application.collapse.header",
                        defaultMessage:
                          "The remainder of your app’s description:",
                      })}
                    </Text>
                  }
                >
                  <Text size="s">
                    {intl.formatMessage({
                      id: "settings.white.label.application.collapse.content.1",
                      defaultMessage:
                        "This will be crafted by CRMB but will be the remainder of the paragraphs of the apps description. We will need a copywriter to come up with something clever here. This will be crafted by CRMB but will be the remainder of the paragraphs of the apps description. We will need a copywriter to come up with something clever here.This will be crafted by CRMB but will be the remainder of the paragraphs of the apps description. We will need a copywriter to come up with something clever hereThis will be crafted by CRMB but will be the remainder of the paragraphs of the apps description. We will need a copywriter to come up with something clever here",
                    })}
                  </Text>
                  <Spacer size={32} />
                  <Text size="s">
                    {intl.formatMessage({
                      id: "settings.white.label.application.collapse.content.2",
                      defaultMessage:
                        "This will be crafted by CRMB but will be the remainder of the paragraphs of the apps description. We will need a copywriter to come up with something clever here. This will be crafted by CRMB but will be the remainder of the paragraphs of the apps description. We will need a copywriter to come up with something clever here",
                    })}
                  </Text>
                  <Spacer size={32} />
                  <Text size="s">
                    {intl.formatMessage({
                      id: "settings.white.label.application.collapse.content.3",
                      defaultMessage:
                        "This will be crafted by CRMB but will be the remainder of the paragraphs of the apps description. We will need a copywriter to come up with something clever here",
                    })}
                  </Text>
                </CollapsePanel>
              </Collapse>
              <Container>
                {isWaitingForReview && (
                  <Alert
                    message={intl.formatMessage({
                      id: "settings.white.label.application.warning.text",
                      defaultMessage:
                        "A publish process is running and you cannot make any more changes until the process completes.",
                    })}
                    type="warning"
                  />
                )}
                <Button
                  disabled={!(!isWaitingForReview && isValid)}
                  title={intl.formatMessage({
                    id: "settings.white.label.application.publish.label",
                    defaultMessage: "Publish",
                  })}
                  onClick={onOpenPublishConfirmationModal}
                />
              </Container>
            </StyledSectionContainer>
          </StyledDataContainer>
        </StyledContainer>
      </Container>

      <ConfirmationModal
        visible={showDeleteConfirmationModal}
        title={intl.formatMessage({
          id: "settings.white.label.application.publish.confirmation.title",
          defaultMessage: "Publish Your App",
        })}
        subtitle={intl.formatMessage({
          id: "settings.white.label.application.publish.confirmation.subtitle",
          defaultMessage:
            "This action will send your application to the app store for review.",
        })}
        cancelButtonText={intl.formatMessage({
          id: "components.modal.cancel.button",
          defaultMessage: "Cancel",
        })}
        confirmButtonText={intl.formatMessage({
          id: "settings.white.label.application.publish.confirmation.text",
          defaultMessage: "Publish",
        })}
        onCancel={onClosePublishConfirmation}
        onConfirm={handleSubmit(onSaveInformation)}
        type="info"
      />
    </FormProvider>
  )
}

const StyledContainer = styled(Container)`
  height: calc(100vh);
  overflow-y: hidden;
`

const StyledSectionContainer = styled(Container)`
  padding: 32px 40px;
`

const StyledSectionContainerWithBorder = styled(Container)`
  ${({ theme }) => css`
    border-bottom: 1px solid ${theme.colors["Neutral4"]};
  `}
`

const StyledDataContainer = styled(Container)`
  ${({ theme }) => css`
    border-right: 1px solid ${theme.colors["Neutral4"]};
    overflow-y: scroll;
    overflow-x: hidden;
    height: 100%;
  `}
`

const StyledUploadAvatar = styled(Container)`
  img {
    border-radius: 12px;
  }
`
