import * as Types from '../../../../../../shared/graphql/generated/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateLocationTableMutationVariables = Types.Exact<{
  data: Types.UpdateLocationTableInput;
}>;


export type UpdateLocationTableMutation = { __typename?: 'Mutation', updateLocationTable: { __typename?: 'LocationTableZoneModel', uuid: string, name: string, description?: string | null, capacity: number, isActive: boolean, qrPath: string, smoking: boolean, status: Types.StatusTableEnum, tableIdentifier: string, tableZone?: { __typename?: 'BasicZoneModel', uuid: string, name: string } | null } };


export const UpdateLocationTableDocument = gql`
    mutation updateLocationTable($data: UpdateLocationTableInput!) {
  updateLocationTable(data: $data) {
    uuid
    name
    description
    capacity
    isActive
    qrPath
    smoking
    status
    tableIdentifier
    tableZone {
      uuid
      name
    }
  }
}
    `;
export type UpdateLocationTableMutationFn = Apollo.MutationFunction<UpdateLocationTableMutation, UpdateLocationTableMutationVariables>;

/**
 * __useUpdateLocationTableMutation__
 *
 * To run a mutation, you first call `useUpdateLocationTableMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLocationTableMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLocationTableMutation, { data, loading, error }] = useUpdateLocationTableMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateLocationTableMutation(baseOptions?: Apollo.MutationHookOptions<UpdateLocationTableMutation, UpdateLocationTableMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateLocationTableMutation, UpdateLocationTableMutationVariables>(UpdateLocationTableDocument, options);
      }
export type UpdateLocationTableMutationHookResult = ReturnType<typeof useUpdateLocationTableMutation>;
export type UpdateLocationTableMutationResult = Apollo.MutationResult<UpdateLocationTableMutation>;
export type UpdateLocationTableMutationOptions = Apollo.BaseMutationOptions<UpdateLocationTableMutation, UpdateLocationTableMutationVariables>;