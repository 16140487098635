import type { TypographyTextProps } from "../Text"
import { StyledText } from "../Text"
import React from "react"
import type { ITitle } from "styled-components"
import { useTheme } from "styled-components"

export const Title: React.FC<TypographyTextProps & { size?: keyof ITitle }> = (
  props
) => {
  const { size, weight, ...rest } = props
  const theme = useTheme()

  let style = theme.text.body.m.regular

  if (size && weight) {
    style = theme.text.title[size][weight]
  }

  return <StyledText {...style} {...rest} />
}
