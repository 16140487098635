import { ReactNode } from "react"
import { FormattedMessage } from "react-intl"

export enum EmployeeFilterEnum {
  ALL = "All",
  ACTIVE = "Active",
  INACTIVE = "Inactive",
}

export type StatusType = {
  label: ReactNode
  status: `${EmployeeFilterEnum}`
  onClick: () => void
}

export const getStatusOptions = (
  callback: (value?: boolean) => void
): StatusType[] => [
  {
    label: (
      <FormattedMessage
        id="settings.locations.employees.header.status.option.all"
        defaultMessage="All"
      />
    ),
    status: EmployeeFilterEnum.ALL,
    onClick: () => callback(undefined),
  },
  {
    label: (
      <FormattedMessage
        id="settings.locations.employees.header.status.option.active"
        defaultMessage="Active"
      />
    ),
    status: EmployeeFilterEnum.ACTIVE,
    onClick: () => callback(true),
  },
  {
    label: (
      <FormattedMessage
        id="settings.locations.employees.header.status.option.inactive"
        defaultMessage="Inactive"
      />
    ),
    status: EmployeeFilterEnum.INACTIVE,
    onClick: () => callback(false),
  },
]
