import * as Types from '../../../../../shared/graphql/generated/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DisableEmployeeMutationVariables = Types.Exact<{
  input: Types.DisableEmployeeInput;
}>;


export type DisableEmployeeMutation = { __typename?: 'Mutation', disableEmployee: { __typename?: 'EmployeeBasic', deactivatedAt?: any | null, uuid: string } };


export const DisableEmployeeDocument = gql`
    mutation DisableEmployee($input: DisableEmployeeInput!) {
  disableEmployee(input: $input) {
    deactivatedAt
    uuid
  }
}
    `;
export type DisableEmployeeMutationFn = Apollo.MutationFunction<DisableEmployeeMutation, DisableEmployeeMutationVariables>;

/**
 * __useDisableEmployeeMutation__
 *
 * To run a mutation, you first call `useDisableEmployeeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDisableEmployeeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [disableEmployeeMutation, { data, loading, error }] = useDisableEmployeeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDisableEmployeeMutation(baseOptions?: Apollo.MutationHookOptions<DisableEmployeeMutation, DisableEmployeeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DisableEmployeeMutation, DisableEmployeeMutationVariables>(DisableEmployeeDocument, options);
      }
export type DisableEmployeeMutationHookResult = ReturnType<typeof useDisableEmployeeMutation>;
export type DisableEmployeeMutationResult = Apollo.MutationResult<DisableEmployeeMutation>;
export type DisableEmployeeMutationOptions = Apollo.BaseMutationOptions<DisableEmployeeMutation, DisableEmployeeMutationVariables>;