import notification from "../../../../../ui/notification"
import {
  buildTypeServicesRules,
  cancelationPoliciesFormatted,
} from "../../utils/catering-utils"
import { FormCatering } from "../FormCatering"
import { useCreateLocationCateringRulesMutation } from "../Graphql/createLocationCateringRules.generated"
import { LocationCateringRuleDocument } from "../Graphql/locationCateringRule.generated"
import type { CateringForm, EmployeeType } from "../shared/hookforms.intefaces"
import { FeeQuantityEnum } from "../shared/hookforms.intefaces"
import { useIntl } from "react-intl"

interface CreateCateringProps {
  employees: EmployeeType[]
}

export const CreateCatering = ({ employees }: CreateCateringProps) => {
  const intl = useIntl()

  const [createCateringRules, { loading }] =
    useCreateLocationCateringRulesMutation()

  const onCreateCateringRules = async (formData: CateringForm) => {
    const employeesUUID = formData.selectedEmployees?.map((employee) => ({
      uuid: employee,
    }))

    const paymentMethod = formData.rules.prePayment.paymentMethod
    const paymentValue = formData.rules.prePayment.paymentValue

    try {
      await createCateringRules({
        variables: {
          input: {
            employees: employeesUUID ?? [],
            orderMethodAcceptance: formData.acceptanceMethod,
            orderScheduling: formData.rules.orderScheduling.daysInAdvance,
            prePayment:
              paymentMethod === FeeQuantityEnum.AMOUNT
                ? { amount: paymentValue }
                : { percentage: paymentValue },
            services: buildTypeServicesRules(
              formData.rules.typeOfService,
              formData.rules.minimumAmount,
              formData.rules.cateringFee
            ),
            cancelPolicies: cancelationPoliciesFormatted(
              formData.rules.cancelPolicy.cancelPolicies,
              formData.rules.cancelPolicy.isApply ?? false
            ),
          },
        },
        refetchQueries: [
          {
            query: LocationCateringRuleDocument,
          },
        ],
      })
    } catch {
      notification({
        type: "error",
        description: intl.formatMessage({
          id: "settings.locations.create.catering.error.message",
          defaultMessage:
            "Something went wrong. Please try again. If the problem persists, contact support.",
        }),
      })
    }
  }

  return (
    <FormCatering
      employees={employees}
      upsertSubmit={onCreateCateringRules}
      loading={loading}
    />
  )
}
