import type { AdminInviteStatusEnum } from "../../../../../shared/graphql/generated/types"
import Container from "../../../../../ui/Container"
import Text from "../../../../../ui/Typography/Text"
import { CustomMemberStatusTitle } from "../memberStatus.title"
import getBadgeColor from "../utils/get-badge-color.util"
import { Badge } from "antd"
import React from "react"
import { useIntl } from "react-intl"

type MemberStatusLabelProps = {
  status: `${AdminInviteStatusEnum}`
}

export const MemberStatusLabel: React.FC<MemberStatusLabelProps> = ({
  status,
}) => {
  const intl = useIntl()

  return (
    <Container
      aria-label="member-status-label"
      display="flex"
      flexDirection="row"
      gap="8px"
    >
      <Text size="s">
        <Badge color={getBadgeColor(status)} />
        {intl.formatMessage(CustomMemberStatusTitle[status])}
      </Text>
    </Container>
  )
}
