import {
  GetItemListQuery,
  GetItemListQueryVariables,
} from "../../../../../GraphQL/Queries/getItemList.generated"
import { ItemProgressStatusEnum } from "../../../../../shared/graphql/generated/types"
import { LazyQueryExecFunction } from "@apollo/client"

export interface GetHasItemsCombosSetupCompletedArgs {
  getItemList: LazyQueryExecFunction<
    GetItemListQuery,
    GetItemListQueryVariables
  >
}

export const getHasItemsCombosSetupCompleted = async ({
  getItemList,
}: GetHasItemsCombosSetupCompletedArgs) => {
  const { data: itemsData } = await getItemList({
    fetchPolicy: "cache-first",
  })

  return !!itemsData?.getItemList.results?.some(
    (item) => item.status === ItemProgressStatusEnum.PUBLISHED
  )
}
