import notification from "../../../../../ui/notification"
import {
  buildTypeServicesRules,
  cancelationPoliciesFormatted,
} from "../../utils/catering-utils"
import { FormCatering } from "../FormCatering"
import { useLocationCateringRuleQuery } from "../Graphql/locationCateringRule.generated"
import { useUpdateLocationCateringRulesMutation } from "../Graphql/updateLocationCateringRules.generated"
import type { CateringForm, EmployeeType } from "../shared/hookforms.intefaces"
import {
  FeeQuantityEnum,
  mappingCateringService,
} from "../shared/hookforms.intefaces"
import { updateCateringOrderCache } from "../utils/cache/update-catering-order.cache"
import { useIntl } from "react-intl"

interface UpdateCateringProps {
  employees: EmployeeType[]
}

export const UpdateCatering = ({ employees }: UpdateCateringProps) => {
  const intl = useIntl()
  const { data } = useLocationCateringRuleQuery({
    fetchPolicy: "cache-only",
  })

  const [updateLocationCateringRules, { loading }] =
    useUpdateLocationCateringRulesMutation()

  const orderCateringRule = data?.locationCateringRule

  const onUpdateCateringRules = async (formData: CateringForm) => {
    const addEmployeesUUID = formData.selectedEmployees?.map((employee) => ({
      uuid: employee,
    }))

    const paymentMethod = formData.rules.prePayment.paymentMethod
    const paymentValue = formData.rules.prePayment.paymentValue

    try {
      await updateLocationCateringRules({
        variables: {
          input: {
            uuid: data?.locationCateringRule?.uuid ?? "",
            employees: addEmployeesUUID ?? [],
            orderMethodAcceptance: formData.acceptanceMethod,
            orderScheduling: formData.rules.orderScheduling.daysInAdvance,
            prePayment:
              paymentMethod === FeeQuantityEnum.AMOUNT
                ? { amount: paymentValue }
                : { percentage: paymentValue },
            services: buildTypeServicesRules(
              formData.rules.typeOfService,
              formData.rules.minimumAmount,
              formData.rules.cateringFee
            ),
            cancelPolicies: cancelationPoliciesFormatted(
              formData.rules.cancelPolicy.cancelPolicies,
              formData.rules.cancelPolicy.isApply ?? false
            ),
          },
        },
        update: (cache, result) => updateCateringOrderCache(cache, result, {}),
      })
    } catch {
      notification({
        type: "error",
        description: intl.formatMessage({
          id: "settings.locations.update.catering.error.message",
          defaultMessage:
            "Something went wrong. Please try again. If the problem persists, contact support.",
        }),
      })
    }
  }

  const minimumAmountCombine: Record<
    string,
    { amount: number; apply: boolean }
  > = {}
  const cateringFeeCombine: Record<
    string,
    { value: number; apply: boolean; type: FeeQuantityEnum }
  > = {}

  if (orderCateringRule) {
    orderCateringRule.services.forEach((service) => {
      const type = service.type
      const minimumAmount = service.minimumAmount

      const feeFixedAmount = service.feeFixedAmount
      const feePercentage = service.feePercentage

      if (type in mappingCateringService) {
        minimumAmountCombine[mappingCateringService[type]] = {
          amount: minimumAmount ?? 0,
          apply: true,
        }

        cateringFeeCombine[mappingCateringService[type]] = {
          value: feeFixedAmount ?? feePercentage ?? 0,
          type: feeFixedAmount
            ? FeeQuantityEnum.AMOUNT
            : FeeQuantityEnum.PERCENTAGE,
          apply: feeFixedAmount || feePercentage ? true : false,
        }
      }
    })
  }

  const cancelPolicies = orderCateringRule?.cancelPolicy ?? []

  return (
    <FormCatering
      employees={employees}
      employeesUUID={orderCateringRule?.employees.map((item) => item.uuid)}
      services={orderCateringRule?.services}
      cateringOrderAcceptMethod={orderCateringRule?.orderMethodAcceptance}
      daysInAdvance={orderCateringRule?.orderScheduling}
      cancelPolicy={{
        isApply: cancelPolicies.length > 0 ? true : false,
        cancelPolicies: cancelPolicies,
      }}
      upsertSubmit={onUpdateCateringRules}
      prePayment={orderCateringRule?.prePayment}
      minimumAmount={{
        delivery:
          {
            apply: minimumAmountCombine.delivery?.amount > 0 ? true : false,
            amount: minimumAmountCombine.delivery?.amount ?? 0,
          } ?? {},
        foodTruckExperience:
          {
            apply:
              minimumAmountCombine.foodTruckExperience?.amount > 0
                ? true
                : false,
            amount: minimumAmountCombine.foodTruckExperience?.amount ?? 0,
          } ?? {},
        insideVenue:
          {
            apply: minimumAmountCombine.insideVenue?.amount > 0 ? true : false,
            amount: minimumAmountCombine.insideVenue?.amount ?? 0,
          } ?? {},
        pickup:
          {
            apply: minimumAmountCombine.pickup?.amount > 0 ? true : false,
            amount: minimumAmountCombine.pickup?.amount ?? 0,
          } ?? {},
      }}
      fees={{
        delivery: cateringFeeCombine.delivery ?? {},
        foodTruckExperience: cateringFeeCombine.foodTruckExperience ?? {},
        insideVenue: cateringFeeCombine.insideVenue ?? {},
        pickup: cateringFeeCombine.pickup ?? {},
      }}
      loading={loading}
    />
  )
}
