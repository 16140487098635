import type { SliderSingleProps } from "antd"
import { Slider as AntSlider } from "antd"
import classnames from "classnames"
import type CSS from "csstype"
import React from "react"
import styled, { css } from "styled-components"

type SliderProps = SliderSingleProps & {
  width?: CSS.Property.Width
  name?: string
}

export const Slider: React.FC<SliderProps> = (props) => {
  return (
    <StyledSlider
      {...props}
      className={classnames("slider", props.className)}
    />
  )
}

const StyledSlider = styled(AntSlider)<SliderProps>`
  ${({ theme, width }) => css`
    &.slider {
      width: ${width ? width : "100%"};

      .ant-slider-rail {
        background-color: ${theme.colors["Neutral4"]};
      }

      .ant-slider-track {
        background-color: ${theme.colors["Neutral5"]};
      }

      .ant-slider-handle {
        border-color: ${theme.colors["Neutral5"]};
      }
    }
  `}
`
