import safeNumber from "../utils/helpers/safeNumber"
import { useEffect } from "react"

interface ITableScroll {
  onScroll: () => void
  loadingRows?: number
  containerHeight?: number
  hasNextPage?: boolean
}

export const useTableScroll = ({
  onScroll,
  containerHeight,
  hasNextPage,
  loadingRows = 2,
}: ITableScroll) => {
  useEffect(() => {
    const lastRow: HTMLInputElement | null = document.querySelector(
      `.ant-table-body  tr:nth-last-child(${loadingRows + 1})`
    )
    const header: HTMLInputElement | null =
      document.querySelector(".ant-layout-header")

    const windowHeight =
      containerHeight ||
      window.pageYOffset +
        window.innerHeight -
        safeNumber(header?.clientHeight, 50)
    const lastRowHeight =
      safeNumber(lastRow?.offsetTop) + safeNumber(lastRow?.offsetHeight)

    if (windowHeight > lastRowHeight && hasNextPage) {
      onScroll()
    }
  }, [onScroll, loadingRows, containerHeight, hasNextPage])
}
