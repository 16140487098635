import * as Types from '../../../../../../shared/graphql/generated/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeleteItemTaxesByLocationsMutationVariables = Types.Exact<{
  data: Types.DeleteItemTaxesByLocationsInput;
}>;


export type DeleteItemTaxesByLocationsMutation = { __typename?: 'Mutation', deleteItemTaxesByLocations: Array<{ __typename?: 'ItemTaxWithLocationModel', uuid: string, location: { __typename?: 'LocationBasicModel', uuid: string, name: string }, tax: { __typename?: 'TaxBasicModel', uuid: string, taxRate: number }, item: { __typename?: 'ItemBasicModel', uuid: string, name: string } }> };


export const DeleteItemTaxesByLocationsDocument = gql`
    mutation deleteItemTaxesByLocations($data: DeleteItemTaxesByLocationsInput!) {
  deleteItemTaxesByLocations(data: $data) {
    uuid
    location {
      uuid
      name
    }
    tax {
      uuid
      taxRate
    }
    item {
      uuid
      name
    }
  }
}
    `;
export type DeleteItemTaxesByLocationsMutationFn = Apollo.MutationFunction<DeleteItemTaxesByLocationsMutation, DeleteItemTaxesByLocationsMutationVariables>;

/**
 * __useDeleteItemTaxesByLocationsMutation__
 *
 * To run a mutation, you first call `useDeleteItemTaxesByLocationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteItemTaxesByLocationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteItemTaxesByLocationsMutation, { data, loading, error }] = useDeleteItemTaxesByLocationsMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useDeleteItemTaxesByLocationsMutation(baseOptions?: Apollo.MutationHookOptions<DeleteItemTaxesByLocationsMutation, DeleteItemTaxesByLocationsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteItemTaxesByLocationsMutation, DeleteItemTaxesByLocationsMutationVariables>(DeleteItemTaxesByLocationsDocument, options);
      }
export type DeleteItemTaxesByLocationsMutationHookResult = ReturnType<typeof useDeleteItemTaxesByLocationsMutation>;
export type DeleteItemTaxesByLocationsMutationResult = Apollo.MutationResult<DeleteItemTaxesByLocationsMutation>;
export type DeleteItemTaxesByLocationsMutationOptions = Apollo.BaseMutationOptions<DeleteItemTaxesByLocationsMutation, DeleteItemTaxesByLocationsMutationVariables>;