import * as Types from '../../../../../../shared/graphql/generated/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetDeliveryLocationsQueryVariables = Types.Exact<{
  location: Types.RuuidInput;
}>;


export type GetDeliveryLocationsQuery = { __typename?: 'Query', getDeliveryLocations: Array<{ __typename?: 'DeliveryLocation', uuid: string, isDefault: boolean, id: number, deliveryIntegration: { __typename?: 'RDeliveryIntegration', uuid: string, apiKey: string, developerId?: string | null, integrationLabel?: string | null, integrationName: Types.DeliveryIntegrationEnum, isActive: boolean, issues?: string | null, settings?: any | null, signingSecret?: string | null } }> };


export const GetDeliveryLocationsDocument = gql`
    query getDeliveryLocations($location: RUUIDInput!) {
  getDeliveryLocations(location: $location) {
    uuid
    isDefault
    id
    deliveryIntegration {
      uuid
      apiKey
      developerId
      integrationLabel
      integrationName
      isActive
      issues
      settings
      signingSecret
    }
  }
}
    `;

/**
 * __useGetDeliveryLocationsQuery__
 *
 * To run a query within a React component, call `useGetDeliveryLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDeliveryLocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDeliveryLocationsQuery({
 *   variables: {
 *      location: // value for 'location'
 *   },
 * });
 */
export function useGetDeliveryLocationsQuery(baseOptions: Apollo.QueryHookOptions<GetDeliveryLocationsQuery, GetDeliveryLocationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDeliveryLocationsQuery, GetDeliveryLocationsQueryVariables>(GetDeliveryLocationsDocument, options);
      }
export function useGetDeliveryLocationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDeliveryLocationsQuery, GetDeliveryLocationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDeliveryLocationsQuery, GetDeliveryLocationsQueryVariables>(GetDeliveryLocationsDocument, options);
        }
export type GetDeliveryLocationsQueryHookResult = ReturnType<typeof useGetDeliveryLocationsQuery>;
export type GetDeliveryLocationsLazyQueryHookResult = ReturnType<typeof useGetDeliveryLocationsLazyQuery>;
export type GetDeliveryLocationsQueryResult = Apollo.QueryResult<GetDeliveryLocationsQuery, GetDeliveryLocationsQueryVariables>;