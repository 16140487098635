import Button from "../Button"
import type { CollapseProps as AntCollapseProps } from "antd"
import { Collapse as AntCollapse } from "antd"
import classnames from "classnames"
import React from "react"
import type { IBaseThemeColors } from "styled-components"
import styled, { css } from "styled-components"

interface ICollapse {
  classId: string
  hasOpacity?: boolean
}

type CollapseProps = ICollapse &
  AntCollapseProps & {
    contentBorderColor?: keyof IBaseThemeColors
    contentBordered?: boolean
    removeHeaderBottomBorder?: boolean
    hasError?: boolean
  }

export const CollapsePanel = AntCollapse.Panel

export const Collapse: React.FC<CollapseProps> = ({
  hasError = false,
  className,
  classId,
  hasOpacity,
  contentBordered,
  removeHeaderBottomBorder,
  contentBorderColor,
  ...props
}) => {
  return (
    <StyleCollapseWrapper $classId={classId}>
      <StyledCollapse
        {...props}
        $classId={classId}
        $hasError={hasError}
        $hasOpacity={hasOpacity}
        $contentBordered={contentBordered}
        $removeHeaderBottomBorder={removeHeaderBottomBorder}
        $contentBorderColor={contentBorderColor}
        className={classnames(className, classId)}
        expandIconPosition="right"
        expandIcon={({ isActive, className: expandIconClassName }) => (
          <Button
            className={expandIconClassName}
            role="listitem"
            hierarchy="tertiary"
            shape="square"
            leadingIcon={
              isActive ? "ri-arrow-down-s-line" : "ri-arrow-right-s-line"
            }
          />
        )}
        ghost
      />
    </StyleCollapseWrapper>
  )
}

interface IStyleCollapseWrapperProps {
  $classId: string
}

const StyleCollapseWrapper = styled.div<IStyleCollapseWrapperProps>`
  ${({ $classId }) =>
    css`
      position: relative;

      .ant-collapse-ghost
        > .ant-collapse-item
        > .ant-collapse-content
        > .ant-collapse-content-box {
        padding: 16px;
      }

      .ant-collapse > .ant-collapse-item > .ant-collapse-header:hover {
        .remove-item-button.${$classId}, .dropdown-variant.${$classId} {
          opacity: 1;
        }
      }
    `}
`

interface IStyledCollapseProps {
  $classId?: string
  $hasOpacity?: boolean
  $contentBorderColor?: keyof IBaseThemeColors
  $contentBordered?: boolean
  $removeHeaderBottomBorder?: boolean
  $hasError?: boolean
}

const StyledCollapse = styled(AntCollapse)<IStyledCollapseProps>`
  ${({
    theme,
    $contentBordered,
    $removeHeaderBottomBorder,
    $classId,
    $hasOpacity,
    $hasError,
    $contentBorderColor,
  }) =>
    css`
      &.${$classId} {
        .ant-collapse-item {
          ${$contentBordered
            ? css`
                border: 1px solid
                  ${theme.colors[$contentBorderColor ?? "Neutral2"]};
                box-sizing: border-box;
                border-radius: 4px;
                margin-bottom: 8px;
              `
            : css`
                border: 0;
              `}

          ${$hasOpacity &&
          css`
            opacity: 0.4;
          `}
        }

        ${!$contentBordered &&
        css`
          .ant-collapse-header {
            border: 1px solid ${theme.colors.Neutral2};
          }
        `}

        .ant-collapse-item-disabled > * {
          cursor: text;
        }

        .ant-collapse-item-active {
          .ant-collapse-header {
            border-bottom: ${$removeHeaderBottomBorder ? "0" : "1"}px solid
              ${theme.colors.Neutral2};
            background: ${theme.colors.transparent};
            box-sizing: border-box;
            border-radius: 4px;
          }
        }

        .modifier-panel {
          overflow: initial;
        }

        .ant-collapse-header {
          flex-shrink: 1;
          display: flex;
          padding-right: 56px;

          :hover .variant-default-hover {
            opacity: 1;
          }

          .ant-collapse-arrow {
            display: flex;
            align-items: center;
            right: 8px;
          }

          .ant-typography {
            white-space: nowrap;
          }

          .ant-collapse-extra {
            margin-left: auto;
            flex-shrink: 1;
          }
        }
      }

      &.${$classId} > .ant-collapse-item {
        ${$hasError &&
        css`
          border: 1px solid ${theme.colors["Danger5"]};
        `}
      }
    `}
`
