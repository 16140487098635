import type { SubMenuProps as AntSubmMenuProps } from "antd"
import AntSubMenu from "antd/lib/menu/SubMenu"
import React from "react"
import styled, { css } from "styled-components"

type StyledSubMenuProps = {
  width?: number
  showIcon?: boolean
}

type SubMenuProps = AntSubmMenuProps & StyledSubMenuProps

export const SubMenu: React.FC<SubMenuProps> = ({ width = 176, ...props }) => {
  return <StyledSubMenu width={width} {...props} />
}

const StyledSubMenu = styled(AntSubMenu)<StyledSubMenuProps>`
  ${({ theme, width, showIcon }) => css`
    &.sidebar-submenu {
      .ri-settings-3-line {
        display: none;
      }

      &.ant-menu-submenu {
        width: ${width}px;
        .ant-menu-item:focus-visible,
        .ant-menu-submenu-title:focus-visible {
          box-shadow: none;
        }
        
        .ant-menu-submenu-title{
          padding-left: 8px !important ;
        }

        .ant-menu-item .ant-menu-item-icon,
        .ant-menu-submenu-title .ant-menu-item-icon,
        .ant-menu-item .anticon,
        .ant-menu-submenu-title .anticon {
          min-width: 12px;
        }

        .ant-menu-sub.ant-menu-inline {
          background: ${theme.colors.transparent};
        }

        &:hover {
          .ant-menu-submenu-title {
            .anticon {
              .ri-settings-3-line {
                color: ${theme.colors.Neutral9};
                position: absolute;
                display: ${showIcon ? "block" : "none"} 
                right: 8px;
              }
            }
          }
        }

        .ant-menu-submenu-arrow {
          color: ${theme.colors.Neutral9};
          right: auto;
          margin-left: 8px;
          transform: none;
          transition: none;

          &:before {
            transform: rotate(132deg) translateX(2.5px);
            height: 1.4px;
            transition: none;
          }
          &:after {
            transform: rotate(45deg) translateX(-2.5px);
            height: 1.4px;
            transition: none;
          }
        }

        &.ant-menu-submenu-open.ant-menu-submenu-inline {
          .ant-menu-submenu-title {
            .anticon {
              .ri-settings-3-line {
                color: ${theme.colors.Neutral9};
                position: absolute;
                display: ${showIcon ? "block" : "none"} ;
                right: 8px;
              }
            }
            .ant-menu-submenu-arrow {
              &:before {
                transform: rotate(-45deg) translate(1px, 3px);
                margin-left: -1.8px;
              }
              &:after {
                transform: rotate(45deg) translate(0px, 2px);
                margin-left: -1.8px;
              }
            }
          }
        }

        .-title-content {
          width: 90%;
          overflow-x: hidden;
          text-overflow: ellipsis;
          font-weight: 400;
          font-size: 13px;
          line-height: 20px;
          letter-spacing: 0.25px;
        }

        .ant-menu-title-content{
          font-weight: 400;
          font-size: 13px;
          line-height: 20px;
          letter-spacing: 0.25px;
        }

        .ant-menu-submenu-title {
          font-weight: 500;
          color: ${theme.colors["Neutral7"]};
          height: 32px;
          margin: 0;

          span {
            line-height: 20px;
          }
        }

        .ant-menu-submenu-title,
        .ant-menu-submenu-inline,
        .ant-menu-submenu-active,
        .ant-menu-submenu-open {
          width: ${width}px;
          border-radius: 4px;
        }

        .ant-menu-submenu-title:hover {
          background: ${theme.colors["Neutral2"]} !important;
        }

        .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
        .ant-menu-submenu-title:hover {
          color: ${theme.colors["Neutral7"]};
          background: ${theme.colors["Neutral2"]};
          width: ${width}px;
        }
      }
    }
  `}
`
