import { AdminRoleNameEnum } from "../../../../../shared/graphql/generated/types"

const roleOptions = [
  {
    value: AdminRoleNameEnum.RESTAURANT_ADMIN,
    labelId: "settings.restaurant.members.form.role.admin.label",
    defaultMessage: "Admin",
  },
  {
    value: AdminRoleNameEnum.RESTAURANT_MANAGER,
    labelId: "settings.restaurant.members.form.role.manager.label",
    defaultMessage: "Manager",
  },
]

export default roleOptions
