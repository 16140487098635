import { useSearchParams } from "../../../../../shared/hooks/useSearchParams"
import { EMPLOYEE_LIST_FILTER_NAME } from "../../../../../shared/utils/constant/searchParams"
import { CreateEmployeeModal } from "../Modals/CreateEmployeeModal"
import styles from "./EmployeesHeader.module.css"
import { EmployeeFilterEnum, StatusType, getStatusOptions } from "./config"
import {
  ActionIcon,
  Button,
  Flex,
  Group,
  Menu,
  Text,
  useMantineTheme,
} from "@mantine/core"
import { useDisclosure } from "@mantine/hooks"
import { IconChevronDown, IconPlus } from "@tabler/icons-react"
import { useState } from "react"
import { useIntl } from "react-intl"

interface EmployeesHeaderProps {
  onActive: (status: boolean | undefined) => void
  filter: string | null
}

export const EmployeesHeader = ({ onActive, filter }: EmployeesHeaderProps) => {
  const intl = useIntl()
  const mantineTheme = useMantineTheme()
  const [opened, { open, close }] = useDisclosure(false)
  const { setParam } = useSearchParams()

  const statusOptions = getStatusOptions(onActive)
  const [status, setStatus] = useState(filter || EmployeeFilterEnum.ALL)

  const handleFilterClick = (option: StatusType) => () => {
    option.onClick()
    setStatus(option.status)
    setParam("replace", EMPLOYEE_LIST_FILTER_NAME, option.status)
  }

  return (
    <Flex
      className={styles["header-container"]}
      h={48}
      align="center"
      justify="space-between"
      pl={32}
      pr={8}
      py={8}
    >
      <Text fw={600}>
        {intl.formatMessage({
          id: "restaurant.employees.header.title",
          defaultMessage: "Employees",
        })}
      </Text>
      <Group spacing={8}>
        <Menu position="bottom-end">
          <Menu.Target>
            <Button
              h={32}
              px={16}
              variant="outline"
              rightIcon={<IconChevronDown size={16} />}
            >
              <Text size="sm">{status}</Text>
            </Button>
          </Menu.Target>
          <Menu.Dropdown>
            {statusOptions.map((option, index) => (
              <Menu.Item
                key={index}
                w={140}
                onClick={handleFilterClick(option)}
              >
                {option.label}
              </Menu.Item>
            ))}
          </Menu.Dropdown>
        </Menu>

        <ActionIcon w={32} h={32} variant="white" onClick={open}>
          <IconPlus color={mantineTheme.colors.primary[6]} size={24} />
        </ActionIcon>
      </Group>
      <CreateEmployeeModal opened={opened} onClose={close} />
    </Flex>
  )
}
