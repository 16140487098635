import {
  formattedTime,
  getTimeFormattedHH_mm,
} from "../../pages/Settings/Locations/HoursOfOperation/BusinessHours/utils/getFormattedTime"
import { getTimeOptions } from "../../pages/Settings/Locations/HoursOfOperation/BusinessHours/utils/getTimeOptions"
import type { ScheduleType } from "../../shared/types/schedule.types"
import { getMeridium } from "../../shared/utils/helpers/getMeridiem"
import Container from "../Container"
import { DAY_END_HOUR, DAY_START_HOUR } from "../CustomTimePicker/constants"
import { Spacer } from "../Spacer/Spacer"
import TimeSelect from "../TimeSelect"
import Text from "../Typography/Text"
import type { TimeRangeEnum } from "./time-select-range.enums"
import React from "react"
import { useIntl } from "react-intl"
import styled, { css } from "styled-components"

const ALL_DAY_VALUE = "all"

type TimeSelectRangeProps = {
  startTime: string | undefined //24 h format
  endTime: string | undefined //24 h format
  onChange: (time: string, type: `${TimeRangeEnum}`, isAllDay?: boolean) => void
  onClear: (type: `${TimeRangeEnum}`) => void
  error?: string
  allowAllDay?: boolean
  disabled?: boolean
}

export const TimeSelectRange: React.FC<TimeSelectRangeProps> = ({
  startTime,
  endTime,
  onChange,
  onClear,
  error,
  allowAllDay,
  disabled,
}) => {
  const intl = useIntl()
  const startTimeFormatted = formattedTime(startTime)
  const endTimeFormatted = formattedTime(endTime)

  const allDayOption = {
    value: ALL_DAY_VALUE,
    label: intl.formatMessage({
      id: "restaurants.locations.hours.of.operation.all.option",
      defaultMessage: "All Day",
    }),
  }
  const startTimeOptions = allowAllDay
    ? [allDayOption, ...getTimeOptions()]
    : getTimeOptions()

  const isAllDayValue = startTime === DAY_START_HOUR && endTime === DAY_END_HOUR

  const onStartTimeChange = (value: ScheduleType) => {
    if (value.time === ALL_DAY_VALUE) {
      onChange(DAY_START_HOUR, "start", true)
      onChange(DAY_END_HOUR, "end", true)

      return
    }

    const value_HH_mm = getTimeFormattedHH_mm(value)
    value_HH_mm && onChange(value_HH_mm, "start")
  }

  const onEndTimeChange = (value: ScheduleType) => {
    const value_HH_mm = getTimeFormattedHH_mm(value)
    value_HH_mm && onChange(value_HH_mm, "end")
  }

  const onClearStartTime = () => {
    onClear("start")

    if (isAllDayValue) {
      onClear("end")
    }
  }

  const onClearEndTime = () => onClear("end")

  return (
    <Container
      display="flex"
      alignItems="center"
      gap="9px"
      flexWrap="wrap"
      justifyContent="flex-end"
    >
      <StyledErrorContainer $hasError={!!error}>
        <TimeSelect
          value={{
            time: isAllDayValue ? ALL_DAY_VALUE : startTimeFormatted,
            meridium: getMeridium(startTime),
          }}
          timeOptions={startTimeOptions}
          onChange={onStartTimeChange}
          onClear={onClearStartTime}
          hasError={!!error}
          helperText={error}
          disabled={disabled}
        />
      </StyledErrorContainer>
      <StyledContEndTime $notVisible={isAllDayValue} $hasError={false}>
        <div>
          <Text size="s">
            {intl.formatMessage({
              id: "restaurants.locations.hours.of.operation.text",
              defaultMessage: "To",
            })}
          </Text>
          {error && <Spacer size={24} />}
        </div>
      </StyledContEndTime>
      <StyledContEndTime
        display="flex"
        alignItems="center"
        gap="9px"
        $notVisible={isAllDayValue}
        $hasError={!!error}
      >
        <TimeSelect
          value={{
            time: endTimeFormatted,
            meridium: getMeridium(endTime),
          }}
          includeMidnight
          onChange={onEndTimeChange}
          onClear={onClearEndTime}
          hasError={!!error}
          disabled={disabled}
        />
      </StyledContEndTime>
    </Container>
  )
}

const StyledErrorContainer = styled(Container)<{ $hasError: boolean }>`
  ${({ $hasError }) => css`
    ${$hasError &&
    css`
      padding-bottom: 24px;
    `}
  `}
`

const StyledContEndTime = styled(StyledErrorContainer)<{
  $notVisible: boolean
}>`
  ${({ $notVisible }) => css`
    ${$notVisible &&
    css`
      visibility: hidden;
      height: 0;
      overflow: hidden;
    `}
  `}
`
