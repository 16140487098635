import * as Types from '../../../../../../shared/graphql/generated/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeleteRestaurantMemberMutationVariables = Types.Exact<{
  data: Types.DeleteMemberInput;
}>;


export type DeleteRestaurantMemberMutation = { __typename?: 'Mutation', deleteRestaurantMember: { __typename?: 'MemberWithInviteAndLocationsModel', inviteUUID: string, userUUID?: string | null, email: string } };


export const DeleteRestaurantMemberDocument = gql`
    mutation deleteRestaurantMember($data: DeleteMemberInput!) {
  deleteRestaurantMember(data: $data) {
    inviteUUID
    userUUID
    email
  }
}
    `;
export type DeleteRestaurantMemberMutationFn = Apollo.MutationFunction<DeleteRestaurantMemberMutation, DeleteRestaurantMemberMutationVariables>;

/**
 * __useDeleteRestaurantMemberMutation__
 *
 * To run a mutation, you first call `useDeleteRestaurantMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRestaurantMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRestaurantMemberMutation, { data, loading, error }] = useDeleteRestaurantMemberMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useDeleteRestaurantMemberMutation(baseOptions?: Apollo.MutationHookOptions<DeleteRestaurantMemberMutation, DeleteRestaurantMemberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteRestaurantMemberMutation, DeleteRestaurantMemberMutationVariables>(DeleteRestaurantMemberDocument, options);
      }
export type DeleteRestaurantMemberMutationHookResult = ReturnType<typeof useDeleteRestaurantMemberMutation>;
export type DeleteRestaurantMemberMutationResult = Apollo.MutationResult<DeleteRestaurantMemberMutation>;
export type DeleteRestaurantMemberMutationOptions = Apollo.BaseMutationOptions<DeleteRestaurantMemberMutation, DeleteRestaurantMemberMutationVariables>;