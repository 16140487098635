import Button from "../../Button"
import Container from "../../Container"
import Text from "../../Typography/Text"
import React from "react"
import { useIntl } from "react-intl"
import styled from "styled-components"

type SelectDropdownFooterType = {
  searchValue?: string
  onButtonClick?: () => void
  buttonSize?: "default" | "large"
  buttonAriaLabel?: string
  buttonLoading?: boolean
}

export const SelectDropdownFooter: React.FC<SelectDropdownFooterType> = ({
  searchValue,
  onButtonClick,
  buttonSize = "default",
  buttonAriaLabel,
}) => {
  const intl = useIntl()

  return (
    <Container display="flex" alignItems="center" margin="4px 12px" gap="4px">
      <Text color="Neutral5">
        {intl.formatMessage({
          id: "components.select.dropdown.footer.create.text",
          defaultMessage: "Create:",
        })}
      </Text>

      <Container className="ellipsis">
        <Text color="Neutral9">{searchValue}</Text>
      </Container>

      <StyledButton
        title={intl.formatMessage({
          id: "components.select.dropdown.footer.create.button.text",
          defaultMessage: "Create",
        })}
        hierarchy="secondary"
        onClick={onButtonClick}
        size={buttonSize}
        aria-label={buttonAriaLabel}
      />
    </Container>
  )
}

const StyledButton = styled(Button)`
  margin-left: auto;
`
