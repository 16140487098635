import HighlightedText from "../../../../ui/Typography/HighlightedText"
import Text from "../../../../ui/Typography/Text"
import type { ITaxRowModel } from "./datatable.interfaces"
import { FormattedMessage } from "react-intl"

const tableColumns = <T extends ITaxRowModel>() => {
  const columns = [
    {
      dataIndex: "name",
      title: (
        <FormattedMessage
          id="restaurants.taxes.datatable.config.tax.name"
          defaultMessage="Tax Name"
        />
      ),
    },
    {
      dataIndex: "taxRate",
      title: (
        <FormattedMessage
          id="restaurants.taxes.datatable.config.tax.percentage"
          defaultMessage="Tax Percentage"
        />
      ),
      render: (taxRate: number) => {
        return <Text size="s">{`${taxRate}%`}</Text>
      },
    },
    {
      dataIndex: "locations",
      title: (
        <FormattedMessage
          id="restaurants.taxes.datatable.config.tax.locations"
          defaultMessage="Locations"
        />
      ),
      render: (locations: Array<{ name: string; uuid: string }>, record: T) => {
        return (
          <div>
            {locations.length > 0 ? (
              locations.map(({ uuid, name }, index) => (
                <HighlightedText size="s" key={uuid}>
                  {index === locations.length - 1 ? name : `${name}, `}
                </HighlightedText>
              ))
            ) : record.isGeneral ? null : (
              <HighlightedText size="s">
                See locations on the detail
              </HighlightedText>
            )}
          </div>
        )
      },
    },
    {
      dataIndex: "isGeneral",
      title: (
        <FormattedMessage
          id="restaurants.taxes.datatable.config.tax.type"
          defaultMessage="Tax Type"
        />
      ),
      render: (isGeneral: boolean) => {
        return <div>{isGeneral ? "Sales Tax" : "Item tax"}</div>
      },
    },
  ]

  return [columns]
}

export default tableColumns
