import * as Types from '../../../../../../shared/graphql/generated/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpsertOrderMethodSettingMutationVariables = Types.Exact<{
  data: Types.UpsertOrderMethodSettingInput;
}>;


export type UpsertOrderMethodSettingMutation = { __typename?: 'Mutation', upsertOrderMethodSetting: { __typename?: 'OrderMethodSettings', createdAt: any, orderMethod: Types.OrderMethodEnum, uuid: string, location: { __typename?: 'BasicLocationModel', name: string, uuid: string }, notificationSettings?: { __typename?: 'NotificationSettings', orderReadyAdditionalText?: string | null, orderVerificationAdditionalText?: string | null, sendOrderReadyNotification: boolean, sendOrderVerificationNotification: boolean } | null, tipSettings?: { __typename?: 'TipSettings', amounts: Array<number>, defaultAmount: number, requestTipAfter?: boolean | null, requestTipBefore?: boolean | null, valueType: Types.TipValueTypeEnum } | null } };


export const UpsertOrderMethodSettingDocument = gql`
    mutation upsertOrderMethodSetting($data: UpsertOrderMethodSettingInput!) {
  upsertOrderMethodSetting(data: $data) {
    createdAt
    location {
      name
      uuid
    }
    notificationSettings {
      orderReadyAdditionalText
      orderVerificationAdditionalText
      sendOrderReadyNotification
      sendOrderVerificationNotification
    }
    orderMethod
    tipSettings {
      amounts
      defaultAmount
      requestTipAfter
      requestTipBefore
      valueType
    }
    uuid
  }
}
    `;
export type UpsertOrderMethodSettingMutationFn = Apollo.MutationFunction<UpsertOrderMethodSettingMutation, UpsertOrderMethodSettingMutationVariables>;

/**
 * __useUpsertOrderMethodSettingMutation__
 *
 * To run a mutation, you first call `useUpsertOrderMethodSettingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertOrderMethodSettingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertOrderMethodSettingMutation, { data, loading, error }] = useUpsertOrderMethodSettingMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpsertOrderMethodSettingMutation(baseOptions?: Apollo.MutationHookOptions<UpsertOrderMethodSettingMutation, UpsertOrderMethodSettingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertOrderMethodSettingMutation, UpsertOrderMethodSettingMutationVariables>(UpsertOrderMethodSettingDocument, options);
      }
export type UpsertOrderMethodSettingMutationHookResult = ReturnType<typeof useUpsertOrderMethodSettingMutation>;
export type UpsertOrderMethodSettingMutationResult = Apollo.MutationResult<UpsertOrderMethodSettingMutation>;
export type UpsertOrderMethodSettingMutationOptions = Apollo.BaseMutationOptions<UpsertOrderMethodSettingMutation, UpsertOrderMethodSettingMutationVariables>;