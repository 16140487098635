import yup from "../../../../../../shared/yup"
import { yupResolver } from "@hookform/resolvers/yup"

const HoursOfOperationSchema = yup.object().shape({
  schedules: yup.array().of(
    yup.object().shape({
      day: yup.string().dayOfTheWeek(),
      defaultValues: yup.array().of(
        yup
          .object()
          .shape({
            day: yup.string().dayOfTheWeek(),
            startDate: yup.string().timeRangeValue(),
            endDate: yup.string().timeRangeValue(),
          })
          .timeRanges()
      ),
      open: yup.boolean().optional(),
    })
  ),
})

export const HoursOfOperationResolver = yupResolver(HoursOfOperationSchema)
