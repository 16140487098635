import { Skeleton } from "antd"
import range from "lodash/range"
import React from "react"
import styled from "styled-components"

type DataTableRowSkeletonProps = {
  rows?: number
}

export const DataTableRowSkeleton: React.FC<DataTableRowSkeletonProps> = ({
  rows = 2,
}) => {
  return (
    <StyledContainer role="row-skeleton">
      {range(rows).map((key) => (
        <StyledSkeletonWrapper key={key}>
          <Skeleton
            title={false}
            paragraph={{ rows: 1, width: "100%" }}
            active
          />
        </StyledSkeletonWrapper>
      ))}
    </StyledContainer>
  )
}

const StyledSkeletonWrapper = styled.div`
  padding-top: 16px;

  .ant-skeleton-paragraph > li {
    margin: 0;
  }
`

const StyledContainer = styled.div`
  padding-bottom: 16px;
`
