import { GetSingleLocationDocument } from "../../../../../GraphQL/Queries/getSingleLocation.generated"
import generateRandomCode from "../../../../../shared/utils/helpers/generate-random-code.util"
import { parsePhoneNumber } from "../../../../../shared/utils/helpers/parsePhoneNumber"
import Button from "../../../../../ui/Button"
import Container from "../../../../../ui/Container"
import { showGraphqlErrors } from "../../../../../ui/ErrorList"
import type { IModalProps } from "../../../../../ui/Modal"
import Modal from "../../../../../ui/Modal"
import notification from "../../../../../ui/notification"
import { useUpdateLocationMutation } from "../../GraphQL/updateLocation.generated"
import type { ILocationsParams } from "../../hookforms.interfaces"
import DeviceForm from "../DeviceForm"
import { DeviceFormResolver } from "../DeviceForm/DeviceForm.yup"
import type { IDeviceForm } from "../DeviceForm/hookforms.interfaces"
import { GetAllDevicesDocument } from "../GraphQl/getAllDevices.generated"
import type { IDeviceRowModel } from "../datatable.interfaces"
import { useUpdateDeviceMutation } from "./GraphQl/updateDevice.generated"
import type { ApolloError } from "@apollo/client"
import get from "lodash/get"
import React from "react"
import { FormProvider, useForm } from "react-hook-form"
import { useIntl } from "react-intl"
import { useParams } from "react-router-dom"

type EditDeviceProps = Omit<IModalProps, "onCancel"> & {
  device: IDeviceRowModel
  onClose: () => void
  onErrorCallback?: (error?: ApolloError) => void
  locationPhone?: string
}

export const EditDevice: React.FC<EditDeviceProps> = ({
  device,
  onClose,
  onErrorCallback,
  locationPhone,
  ...props
}) => {
  const intl = useIntl()

  const { locationUUID } = useParams<ILocationsParams>()

  const randomCode = generateRandomCode().toString()

  const methods = useForm<IDeviceForm>({
    mode: "all",
    resolver: DeviceFormResolver,
    defaultValues: {
      uuid: device.uuid,
      deviceKey: device.deviceKey,
      location: device.location?.uuid,
      functionType: device.functionType,
      deviceCode: device.code ?? randomCode,
      locationPhone,
    },
  })

  const {
    handleSubmit,
    reset,
    getValues,
    setError,
    formState: { dirtyFields, isDirty },
  } = methods

  const [updateDevice, { loading: isDeviceUpdateLoading }] =
    useUpdateDeviceMutation({
      refetchQueries: [GetAllDevicesDocument],
    })

  const [updateLocation] = useUpdateLocationMutation({
    refetchQueries: [GetSingleLocationDocument],
  })

  const editDevice = async (formData: IDeviceForm) => {
    try {
      if (!device?.uuid) {
        return
      }

      const updatedDevice = await updateDevice({
        variables: {
          data: {
            uuid: device.uuid,
            deviceKey: formData.deviceKey,
            functionType: formData.functionType,
            locationUUID: formData.location,
            code: String(formData.deviceCode),
          },
        },
      })

      if (!locationPhone) {
        await updateLocation({
          variables: {
            data: {
              locationUUID,
              phone: parsePhoneNumber(getValues("locationPhone") ?? "").number,
            },
          },
        })
      }

      const deviceUUID = get(updatedDevice, "data.updateDevice.uuid", null)

      if (deviceUUID) {
        reset({
          deviceKey: formData.deviceKey,
          functionType: formData.functionType,
          location: formData.location,
          deviceCode: formData.deviceCode,
          locationPhone: formData.locationPhone,
        })
        onClose()

        notification({
          description: intl.formatMessage({
            id: "restaurants.devices.edit.device.modal.success.message",
            defaultMessage: "Your device was updated",
          }),
          type: "success",
        })
      }
    } catch (error) {
      const errorMessage = get(error, "message")

      if (errorMessage.includes("code")) {
        setError("deviceCode", {
          type: "custome",
          message: "Codes is already taken",
        })
      } else {
        showGraphqlErrors(error)
        onErrorCallback && onErrorCallback(error as ApolloError)
      }
    }
  }

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    handleSubmit(editDevice)()
  }

  return (
    <FormProvider {...methods}>
      <Modal
        onCancel={onClose}
        shouldCloseOnClickOutside={Object.keys(dirtyFields).length === 0}
        {...props}
        footer={
          <Container display="flex" justifyContent="flex-end" gap="8px">
            <Button
              disabled={isDeviceUpdateLoading}
              title={intl.formatMessage({
                id: "restaurants.devices.edit.device.cancel.button",
                defaultMessage: "Cancel",
              })}
              hierarchy="secondary"
              onClick={onClose}
            />

            <Button
              disabled={!dirtyFields || !isDirty}
              loading={isDeviceUpdateLoading}
              title={intl.formatMessage({
                id: "restaurants.devices.edit.device.save.changes.button",
                defaultMessage: "Save Changes",
              })}
              onClick={handleSubmit(editDevice)}
            />
          </Container>
        }
        title={intl.formatMessage({
          id: "restaurants.devices.edit.device.modal.title",
          defaultMessage: "Edit Device",
        })}
        afterClose={reset}
        destroyOnClose
      >
        <DeviceForm
          onSubmit={onSubmit}
          code={getValues("deviceCode")}
          locationPhone={locationPhone}
        />
      </Modal>
    </FormProvider>
  )
}
