import Container from "../Container"
import type { ContainerProps } from "../Container/Container.styled"
import React from "react"
import styled, { css } from "styled-components"

type BannerProps = Omit<ContainerProps, "ref"> & {
  width?: string
}

export const Banner: React.FC<BannerProps> = (props) => {
  return <StyledBanner centered {...props} />
}

const StyledBanner = styled(Container)`
  ${({ theme }) => css`
    position: fixed;
    bottom: 16px;
    height: 50px;
    min-width: 651px;
    border-radius: 4px;
    background: ${theme.colors["Primary1"]};
    box-shadow: ${theme.shadows.elevationHigh};

    .right-spaced {
      margin: 0 22px 0 0;
    }

    .right-spaced-icon {
      margin: 0 8px 0 0;
    }
  `}
`
