export const endpoints = {
  auth: {
    magiclink: "cms/auth/magiclink",
    validateMagiclink: "cms/auth/magiclink/validate",
    refreshToken: "cms/auth/refreshtoken",
    validateCode: "cms/auth/magiclink/validatecode",
    validateMemberInviteLink: "cms/auth/invite/validate",
    validateCodeMemberInvite: "cms/auth/invite/validatecode",
    google: "cms/auth/google",
    googleBackendCallback: (params: string) =>
      `cms/auth/google/callback${params}`,
  },
  bigQuery: "cms/big-query/order-details",
}
