import type { DropDownProps } from "antd"
import { Dropdown as AntDropdown, Button } from "antd"
import React from "react"
import type { CSSProperties } from "styled-components"
import styled, { css } from "styled-components"

export const Dropdown: React.FC<DropDownProps> = ({ children, ...props }) => {
  return (
    <StyledDropdown {...props}>
      <StyledButton>{children}</StyledButton>
    </StyledDropdown>
  )
}

const StyledDropdown = styled(AntDropdown).attrs({
  overlayStyle: {
    width: "224px",
    paddingTop: "4px",
  } as CSSProperties,
})``

const StyledButton = styled(Button)`
  padding: 4px 8px;
  border: none;
  box-shadow: none;
  font-weight: 500;

  ${({ theme }) => css`
    border-radius: ${theme.spacing.cornerRadiusSmall};

    &:hover {
      background-color: ${theme.colors["Neutral2"]};
    }

    &:focus,
    &:active {
      background-color: ${theme.colors["Neutral3"]};
    }
  `};
`
