import * as Types from '../../../../../../shared/graphql/generated/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetDeliveryPricesByLocationQueryVariables = Types.Exact<{
  locationUUID: Types.Scalars['ID'];
}>;


export type GetDeliveryPricesByLocationQuery = { __typename?: 'Query', getDeliveryPricesByLocation: Array<{ __typename?: 'DeliveryPrice', uuid: string, label?: string | null, maxDistance: number, minDistance: number, price: number }> };


export const GetDeliveryPricesByLocationDocument = gql`
    query getDeliveryPricesByLocation($locationUUID: ID!) {
  getDeliveryPricesByLocation(location: {uuid: $locationUUID}) {
    uuid
    label
    maxDistance
    minDistance
    price
  }
}
    `;

/**
 * __useGetDeliveryPricesByLocationQuery__
 *
 * To run a query within a React component, call `useGetDeliveryPricesByLocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDeliveryPricesByLocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDeliveryPricesByLocationQuery({
 *   variables: {
 *      locationUUID: // value for 'locationUUID'
 *   },
 * });
 */
export function useGetDeliveryPricesByLocationQuery(baseOptions: Apollo.QueryHookOptions<GetDeliveryPricesByLocationQuery, GetDeliveryPricesByLocationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDeliveryPricesByLocationQuery, GetDeliveryPricesByLocationQueryVariables>(GetDeliveryPricesByLocationDocument, options);
      }
export function useGetDeliveryPricesByLocationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDeliveryPricesByLocationQuery, GetDeliveryPricesByLocationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDeliveryPricesByLocationQuery, GetDeliveryPricesByLocationQueryVariables>(GetDeliveryPricesByLocationDocument, options);
        }
export type GetDeliveryPricesByLocationQueryHookResult = ReturnType<typeof useGetDeliveryPricesByLocationQuery>;
export type GetDeliveryPricesByLocationLazyQueryHookResult = ReturnType<typeof useGetDeliveryPricesByLocationLazyQuery>;
export type GetDeliveryPricesByLocationQueryResult = Apollo.QueryResult<GetDeliveryPricesByLocationQuery, GetDeliveryPricesByLocationQueryVariables>;