import isNumber from "lodash/isNumber"

const safeNumber = (value?: number | string | null, fallback = 0): number => {
  const numberValue = Number(value)

  if (!isNumber(numberValue) || Number.isNaN(numberValue)) {
    return fallback
  }

  return numberValue
}

export default safeNumber
