import * as Types from '../../../../../../../shared/graphql/generated/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetHolidaysListQueryVariables = Types.Exact<{
  location: Types.RuuidInput;
}>;


export type GetHolidaysListQuery = { __typename?: 'Query', getHolidaysList: Array<{ __typename?: 'Holiday', date: any, endTime?: any | null, isOpen: boolean, name: string, startTime?: any | null, uuid: string }> };


export const GetHolidaysListDocument = gql`
    query GetHolidaysList($location: RUUIDInput!) {
  getHolidaysList(location: $location) {
    date
    endTime
    isOpen
    name
    startTime
    uuid
  }
}
    `;

/**
 * __useGetHolidaysListQuery__
 *
 * To run a query within a React component, call `useGetHolidaysListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHolidaysListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHolidaysListQuery({
 *   variables: {
 *      location: // value for 'location'
 *   },
 * });
 */
export function useGetHolidaysListQuery(baseOptions: Apollo.QueryHookOptions<GetHolidaysListQuery, GetHolidaysListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetHolidaysListQuery, GetHolidaysListQueryVariables>(GetHolidaysListDocument, options);
      }
export function useGetHolidaysListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetHolidaysListQuery, GetHolidaysListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetHolidaysListQuery, GetHolidaysListQueryVariables>(GetHolidaysListDocument, options);
        }
export type GetHolidaysListQueryHookResult = ReturnType<typeof useGetHolidaysListQuery>;
export type GetHolidaysListLazyQueryHookResult = ReturnType<typeof useGetHolidaysListLazyQuery>;
export type GetHolidaysListQueryResult = Apollo.QueryResult<GetHolidaysListQuery, GetHolidaysListQueryVariables>;