import { ExternalIntegrationEnum } from "../../../../../../shared/graphql/generated/types"
import { useGetExternalLocationsQuery } from "../../GraphQL/getExternalLocations.generated"
import integrationsStyles from "../../Integrations.module.css"
import { SquareDetailForm } from "../../SquareDetail/hookform.interfaces"
import {
  Button,
  Container,
  MultiSelect,
  Select,
  Stack,
  Text,
  Title,
  Tooltip,
} from "@mantine/core"
import { useFormContext, useWatch } from "react-hook-form"
import { useIntl } from "react-intl"

type ConnectLocationsFormType = {
  integrationType: ExternalIntegrationEnum
  loading: boolean
  onConnectClick: () => void
  insideEditForm?: boolean
  id?: string
}

const ConnectLocationsForm = (props: ConnectLocationsFormType) => {
  const {
    integrationType,
    loading,
    onConnectClick,
    id,
    insideEditForm = false,
  } = props
  const intl = useIntl()

  const { setValue, control } = useFormContext<SquareDetailForm>()

  const [credentialUUID, externalLocations = []] = useWatch({
    control,
    name: ["credentialUUID", "externalLocations"],
  })

  const selectKey = externalLocations.map((location) => location.id).join("-")

  const isOrderAggregation =
    integrationType === ExternalIntegrationEnum.ORDER_AGGREGATION

  const { loading: loadingExternalLocations } = useGetExternalLocationsQuery({
    variables: {
      externalIntegration: { uuid: credentialUUID },
      integrationType,
    },
    skip: !credentialUUID || insideEditForm,
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      setValue(
        "externalLocations",
        data.getExternalLocations.map((location) => ({
          ...location,
          selected: false,
        }))
      )
    },
  })

  const description = intl.formatMessage(
    isOrderAggregation
      ? {
          defaultMessage:
            "By connecting locations you will be able to pull orders from this data source into CRMB.",
          id: "restaurants.settings.locations.square.integrations.connect.locations.order.aggregation.description",
        }
      : {
          defaultMessage:
            "By connecting a location, you will be able to import data like menus, menu items, modifiers, hours of operation, and taxes.",
          id: "restaurants.settings.locations.square.integrations.connect.locations.data.import.description",
        }
  )

  const handleSelectChange = (value: string[] | string | null) => {
    if (!value) return

    const prev = externalLocations

    setValue(
      "externalLocations",
      prev?.map((prevLocation) => ({
        ...prevLocation,
        selected:
          typeof value === "string"
            ? prevLocation.id === value
            : value.includes(prevLocation.id),
      }))
    )
  }

  const wrapWithTooltip = (children: React.ReactNode) => (
    <Tooltip
      label={intl.formatMessage({
        id: "restaurants.settings.locations.square.integration.unavailable.select.tooltip",
        defaultMessage: "All locations are currently used",
      })}
      disabled={
        externalLocations.length > 0 || loading || loadingExternalLocations
      }
      position="bottom"
      withArrow
    >
      <Container px={0}>{children}</Container>
    </Tooltip>
  )

  return (
    <Stack
      className={integrationsStyles["bordered-container"]}
      spacing={16}
      id={id}
    >
      <Title order={5} c="gray.9">
        {intl.formatMessage({
          defaultMessage: "Connect your Location(s)",
          id: "restaurants.settings.locations.square.integration.connect.locations.title",
        })}
      </Title>
      <Text size="md" c="gray.6">
        {description}
      </Text>

      <Container m={0} p={0}>
        {isOrderAggregation ? (
          <MultiSelect
            inputContainer={wrapWithTooltip}
            key={selectKey}
            disabled={insideEditForm || externalLocations.length === 0}
            w={400}
            classNames={{ label: integrationsStyles["label"] }}
            label={intl.formatMessage({
              defaultMessage: "Location(s)",
              id: "restaurants.settings.locations.square.integration.locations.select.label",
            })}
            withAsterisk
            placeholder={intl.formatMessage({
              defaultMessage: "Select a location",
              id: "restaurants.settings.locations.square.integration.locations.select.placeholder",
            })}
            data={externalLocations?.map((location) => ({
              label: location.name,
              value: location.id,
            }))}
            defaultValue={
              insideEditForm
                ? externalLocations.map((location) => location.id)
                : undefined
            }
            onChange={handleSelectChange}
          />
        ) : (
          <Select
            inputContainer={wrapWithTooltip}
            key={selectKey}
            disabled={insideEditForm || externalLocations.length === 0}
            w={400}
            classNames={{ label: integrationsStyles["label"] }}
            label={intl.formatMessage({
              defaultMessage: "Location(s)",
              id: "restaurants.settings.locations.square.integration.locations.select.label",
            })}
            withAsterisk
            placeholder={intl.formatMessage({
              defaultMessage: "Select a location",
              id: "restaurants.settings.locations.square.integration.locations.select.placeholder",
            })}
            data={externalLocations?.map((location) => ({
              value: location.id,
              label: location.name,
              selected: true,
            }))}
            defaultValue={
              insideEditForm
                ? externalLocations.find((location) => location.selected)?.id
                : undefined
            }
            onChange={handleSelectChange}
          />
        )}
      </Container>

      <Button
        variant="outline"
        w={84}
        h={32}
        p={0}
        onClick={onConnectClick}
        loading={loading}
        disabled={
          !externalLocations.some((location) => location.selected) ||
          insideEditForm
        }
      >
        {!loading && (
          <Text size="sm" weight={400}>
            {intl.formatMessage({
              defaultMessage: "Connect",
              id: "restaurants.settings.locations.square.integration.locations.connect.button",
            })}
          </Text>
        )}
      </Button>
    </Stack>
  )
}

export default ConnectLocationsForm
