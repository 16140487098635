import * as Types from '../../../../../shared/graphql/generated/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetAllMembersByRestaurantQueryVariables = Types.Exact<{
  restaurantUUID: Types.Scalars['ID'];
}>;


export type GetAllMembersByRestaurantQuery = { __typename?: 'Query', getAllMembersByRestaurant: Array<{ __typename?: 'MemberWithInviteAndLocationsModel', email: string, firstName: string, inviteStatus: Types.AdminInviteStatusEnum, inviteUUID: string, lastName: string, restaurantUUID: string, roleName: Types.AdminRoleNameEnum, userUUID?: string | null, locations: Array<{ __typename?: 'LocationBasicModel', uuid: string, name: string }> }> };


export const GetAllMembersByRestaurantDocument = gql`
    query getAllMembersByRestaurant($restaurantUUID: ID!) {
  getAllMembersByRestaurant(restaurantUUID: $restaurantUUID) {
    email
    firstName
    inviteStatus
    inviteUUID
    lastName
    locations {
      uuid
      name
    }
    restaurantUUID
    roleName
    userUUID
  }
}
    `;

/**
 * __useGetAllMembersByRestaurantQuery__
 *
 * To run a query within a React component, call `useGetAllMembersByRestaurantQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllMembersByRestaurantQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllMembersByRestaurantQuery({
 *   variables: {
 *      restaurantUUID: // value for 'restaurantUUID'
 *   },
 * });
 */
export function useGetAllMembersByRestaurantQuery(baseOptions: Apollo.QueryHookOptions<GetAllMembersByRestaurantQuery, GetAllMembersByRestaurantQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllMembersByRestaurantQuery, GetAllMembersByRestaurantQueryVariables>(GetAllMembersByRestaurantDocument, options);
      }
export function useGetAllMembersByRestaurantLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllMembersByRestaurantQuery, GetAllMembersByRestaurantQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllMembersByRestaurantQuery, GetAllMembersByRestaurantQueryVariables>(GetAllMembersByRestaurantDocument, options);
        }
export type GetAllMembersByRestaurantQueryHookResult = ReturnType<typeof useGetAllMembersByRestaurantQuery>;
export type GetAllMembersByRestaurantLazyQueryHookResult = ReturnType<typeof useGetAllMembersByRestaurantLazyQuery>;
export type GetAllMembersByRestaurantQueryResult = Apollo.QueryResult<GetAllMembersByRestaurantQuery, GetAllMembersByRestaurantQueryVariables>;