import SquareIcon from "../../../../../../shared/assets/images/square-icon.jpeg"
import {
  ExternalIntegrationEnum,
  ExternalSourceEnum,
} from "../../../../../../shared/graphql/generated/types"
import paths from "../../../../../../shared/routes/paths"
import { INTEGRATION_ACTION_FILTER_NAME } from "../../../../../../shared/utils/constant/searchParams"
import Breadcrumbs from "../../../../../../shared/v2/ui/Breadcrumbs"
import { ILocationsParams } from "../../../hookforms.interfaces"
import DisconnectModal from "../../DisconnectModal"
import { useAddLocationIntegrationMutation } from "../../GraphQL/addLocationIntegration.generated"
import { useDeleteLocationOrderIntegrationMutation } from "../../GraphQL/deleteLocationOrderIntegration.generated"
import { useGetLocationIntegrationQuery } from "../../GraphQL/getLocationIntegration.generated"
import { HowItWorksSection } from "../../components/HowItWorksSection/HowItWorksSection"
import StatusBadge from "../../components/StatusBadge"
import { SquareIntlMessage, integrationsFilterValues } from "../../config"
import ConnectLocationsForm from "../../forms/ConnectLocationsForm"
import ConnectSquareForm from "../../forms/ConnectSquareForm"
import { SquareData, getSquareBreadcrumbItems } from "../config"
import { ExternalLocationType, SquareDetailForm } from "../hookform.interfaces"
import styles from "./EditSquareIntegration.module.css"
import {
  Button,
  Center,
  Container,
  Divider,
  Group,
  Image,
  Stack,
  Text,
  Title,
} from "@mantine/core"
import { useDisclosure } from "@mantine/hooks"
import { notifications } from "@mantine/notifications"
import { omit } from "lodash"
import { useEffect, useMemo } from "react"
import { FormProvider, useForm, useWatch } from "react-hook-form"
import { useIntl } from "react-intl"
import { useHistory, useLocation } from "react-router"
import { Prompt, useParams } from "react-router-dom"

export const EditSquareIntegration = () => {
  const intl = useIntl()
  const { search } = useLocation()
  const { push, replace } = useHistory()
  const searchParams = useMemo(() => new URLSearchParams(search), [search])
  const { locationUUID } = useParams<ILocationsParams>()
  const [
    opened,
    { close: closeDisconnectionModal, open: openDisconnectionModal },
  ] = useDisclosure(false)

  const formMethods = useForm<SquareDetailForm>()
  const {
    control,
    setValue,
    handleSubmit,
    reset,
    formState: { isDirty },
  } = formMethods

  const [credentialUUID, externalLocations, hasIntegration] = useWatch({
    control,
    name: ["credentialUUID", "externalLocations", "hasIntegration"],
  })

  const integrationQueryParam = searchParams.get(INTEGRATION_ACTION_FILTER_NAME)

  const integrationType =
    integrationsFilterValues[ExternalIntegrationEnum.ORDER_AGGREGATION] ===
    integrationQueryParam
      ? ExternalIntegrationEnum.ORDER_AGGREGATION
      : ExternalIntegrationEnum.MENU

  const isOrderAggregation =
    integrationType === ExternalIntegrationEnum.ORDER_AGGREGATION

  const [addLocationIntegration, { loading: loadingConnection }] =
    useAddLocationIntegrationMutation()

  const [deleteOrderIntegration] = useDeleteLocationOrderIntegrationMutation()

  const { loading } = useGetLocationIntegrationQuery({
    variables: { source: ExternalSourceEnum.SQUARE },
    onCompleted: (data) => {
      const integrationResult = data.getLocationIntegration
      if (integrationResult) {
        setValue("credentialUUID", integrationResult.uuid)
        setValue("apiKey", integrationResult.appId)
        setValue("token", integrationResult.accessToken)
        setValue("hasIntegration", !!integrationResult.uuid)
      }
      const hasSquareMenuIntegration =
        !!data?.getLocationIntegration?.locationIntegration?.externalId

      const hasSquareOrderIntegration =
        !!data?.getLocationIntegration?.locationOrderIntegrations.some(
          (integration) => integration.externalId
        )

      let locationsConnected: ExternalLocationType[] = []

      if (isOrderAggregation && hasSquareOrderIntegration) {
        locationsConnected =
          data.getLocationIntegration?.locationOrderIntegrations.map(
            (externalLocation) => ({
              id: externalLocation.externalId,
              name: externalLocation.externalName,
              selected: true,
            })
          ) || []
      } else if (!isOrderAggregation && hasSquareMenuIntegration) {
        const externalLocation =
          data.getLocationIntegration?.locationIntegration
        if (externalLocation) {
          locationsConnected = [
            {
              id: externalLocation.externalId,
              name: externalLocation.externalName,
              selected: true,
            },
          ]
        }
      }
      setValue("externalLocations", locationsConnected)
    },
  })

  const handleConnectIntegration = () => {
    if (!credentialUUID) return
    handleSubmit((formData) => {
      reset(formData)
      const filteredExternalLocations = externalLocations
        .filter((location) => location.selected)
        .map((location) => omit(location, "selected"))

      addLocationIntegration({
        variables: {
          data: {
            externalIntegration: { uuid: credentialUUID },
            externalOrderLocations:
              ExternalIntegrationEnum.ORDER_AGGREGATION === integrationType
                ? filteredExternalLocations.map((location) => ({
                    ...location,
                    acceptInternalOrders: false,
                  }))
                : [],
            externalLocations:
              ExternalIntegrationEnum.MENU === integrationType
                ? filteredExternalLocations
                : [],
            integrationType,
          },
        },
        onCompleted: (data) => {
          if (data.addLocationIntegration.uuid) {
            setValue("hasIntegration", true)
            push(paths.settings.locations.integrationsByLocation(locationUUID))
          }
        },
        onError: () => {
          notifications.show({
            title: intl.formatMessage({
              id: "restaurants.settings.locations.square.integration.error.notification.title",
              defaultMessage: "Oops!",
            }),
            message: intl.formatMessage({
              id: "restaurants.settings.locations.square.integration.error.notification.description",
              defaultMessage:
                "Something went wrong, try again to save changes.",
            }),
            color: "red",
            withBorder: true,
          })
        },
      })
    })()
  }

  const handleDisconnection = () => {
    deleteOrderIntegration({
      variables: { data: { externalIntegration: { uuid: credentialUUID } } },
      onCompleted: () =>
        push(paths.settings.locations.integrationsByLocation(locationUUID)),
      onError: () => {
        closeDisconnectionModal()
        notifications.show({
          title: intl.formatMessage({
            id: "restaurants.settings.locations.square.integration.error.notification.title",
            defaultMessage: "Oops!",
          }),
          message: intl.formatMessage({
            id: "restaurants.settings.locations.square.integration.error.notification.description",
            defaultMessage: "Something went wrong, try again to save changes.",
          }),
          color: "red",
          withBorder: true,
        })
      },
    })
  }

  // Redirect to data-import when a wrong or empty query param is sent in the URL
  useEffect(() => {
    if (
      !integrationQueryParam ||
      !Object.values(integrationsFilterValues).includes(integrationQueryParam)
    ) {
      const params = new URLSearchParams()
      params.set(INTEGRATION_ACTION_FILTER_NAME, integrationsFilterValues.MENU)
      replace({ search: params.toString() })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <FormProvider {...formMethods}>
      <Container fluid p={0}>
        <Stack px={32} py={8} h={48} justify="center">
          <Breadcrumbs items={getSquareBreadcrumbItems(locationUUID)} />
        </Stack>
        <Divider w="100%" />

        <Container mx={{ base: 0, xs: 20, sm: 40, md: 80, xl: 184 }} mb={40}>
          <Stack pt={40} spacing={24}>
            <Stack spacing={8}>
              <Group spacing={4}>
                <Center p={4}>
                  <Image
                    alt={SquareData.iconAlt}
                    src={SquareIcon}
                    width={40}
                    height={40}
                  />
                </Center>
                <Title order={3}>{intl.formatMessage(SquareIntlMessage)}</Title>
              </Group>

              <StatusBadge connected={hasIntegration} />
            </Stack>

            <HowItWorksSection
              description={intl.formatMessage(
                SquareData.howItWorks[integrationType]
              )}
              href={SquareData.howItWorks.href}
            />
            <ConnectSquareForm />

            {credentialUUID && (
              <>
                {!loading && (
                  <ConnectLocationsForm
                    integrationType={integrationType}
                    onConnectClick={handleConnectIntegration}
                    loading={loadingConnection}
                    insideEditForm
                  />
                )}

                {isOrderAggregation && (
                  <Group
                    className={styles["disconnect-container"]}
                    position="apart"
                  >
                    <Title order={5} color="red.6">
                      {intl.formatMessage({
                        defaultMessage: "Disconnect Integration",
                        id: "restaurants.settings.locations.square.integration.locations.disconnect.heading",
                      })}
                    </Title>
                    <Button
                      variant="outline"
                      size="sm"
                      color="red.6"
                      onClick={() => openDisconnectionModal()}
                    >
                      <Text size="sm" weight={400}>
                        {intl.formatMessage({
                          defaultMessage: "Disconnect",
                          id: "restaurants.settings.locations.square.integration.locations.disconnect.button",
                        })}
                      </Text>
                    </Button>
                  </Group>
                )}
              </>
            )}
          </Stack>
        </Container>

        <DisconnectModal
          name={SquareIntlMessage.defaultMessage}
          onDisconnect={handleDisconnection}
          opened={opened}
          close={closeDisconnectionModal}
        />

        <Prompt
          when={isDirty && !hasIntegration}
          message={intl.formatMessage({
            id: "components.prompt.square.detail.modal.message",
            defaultMessage:
              "You made some changes to this page. Are you sure you want to discard them?",
          })}
        />
      </Container>
    </FormProvider>
  )
}
