import * as Types from '../../../../../shared/graphql/generated/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetTaxesByRestaurantQueryVariables = Types.Exact<{
  locationUUID?: Types.InputMaybe<Types.Scalars['ID']>;
  name?: Types.InputMaybe<Types.Scalars['String']>;
  restaurantUUID: Types.Scalars['ID'];
}>;


export type GetTaxesByRestaurantQuery = { __typename?: 'Query', getTaxesByRestaurant: Array<{ __typename?: 'TaxLocationModel', uuid: string, name: string, taxRate: number, description?: string | null, isGeneral?: boolean | null, locations?: Array<{ __typename?: 'LocationBasicModel', uuid: string, name: string }> | null }> };


export const GetTaxesByRestaurantDocument = gql`
    query getTaxesByRestaurant($locationUUID: ID, $name: String, $restaurantUUID: ID!) {
  getTaxesByRestaurant(
    locationUUID: $locationUUID
    name: $name
    restaurantUUID: $restaurantUUID
  ) {
    uuid
    name
    taxRate
    description
    isGeneral
    locations {
      uuid
      name
    }
  }
}
    `;

/**
 * __useGetTaxesByRestaurantQuery__
 *
 * To run a query within a React component, call `useGetTaxesByRestaurantQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTaxesByRestaurantQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTaxesByRestaurantQuery({
 *   variables: {
 *      locationUUID: // value for 'locationUUID'
 *      name: // value for 'name'
 *      restaurantUUID: // value for 'restaurantUUID'
 *   },
 * });
 */
export function useGetTaxesByRestaurantQuery(baseOptions: Apollo.QueryHookOptions<GetTaxesByRestaurantQuery, GetTaxesByRestaurantQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTaxesByRestaurantQuery, GetTaxesByRestaurantQueryVariables>(GetTaxesByRestaurantDocument, options);
      }
export function useGetTaxesByRestaurantLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTaxesByRestaurantQuery, GetTaxesByRestaurantQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTaxesByRestaurantQuery, GetTaxesByRestaurantQueryVariables>(GetTaxesByRestaurantDocument, options);
        }
export type GetTaxesByRestaurantQueryHookResult = ReturnType<typeof useGetTaxesByRestaurantQuery>;
export type GetTaxesByRestaurantLazyQueryHookResult = ReturnType<typeof useGetTaxesByRestaurantLazyQuery>;
export type GetTaxesByRestaurantQueryResult = Apollo.QueryResult<GetTaxesByRestaurantQuery, GetTaxesByRestaurantQueryVariables>;