export enum DateRangeEnum {
  TODAY = "TODAY",
  YESTERDAY = "YESTERDAY",
  THIS_WEEK = "THIS_WEEK",
  LAST_WEEK = "LAST_WEEK",
  THIS_MONTH = "THIS_MONTH",
  LAST_MONTH = "LAST_MONTH",
  PREVIOUS_30_DAYS = "PREVIOUS_30_DAYS",
  THIS_YEAR = "THIS_YEAR",
  LAST_YEAR = "LAST_YEAR",
  CUSTOM = "CUSTOM",
}

type DateRangeStatusProp = Record<
  DateRangeEnum,
  { id: string; defaultMessage: string }
>

export const DateRangeStatus: DateRangeStatusProp = {
  [DateRangeEnum.TODAY]: {
    id: "date.range.status.today",
    defaultMessage: "Today",
  },
  [DateRangeEnum.YESTERDAY]: {
    id: "date.range.status.yesterday",
    defaultMessage: "Yesterday",
  },
  [DateRangeEnum.THIS_WEEK]: {
    id: "date.range.status.this.week",
    defaultMessage: "This Week",
  },
  [DateRangeEnum.LAST_WEEK]: {
    id: "date.range.status.last.week",
    defaultMessage: "Last Week",
  },
  [DateRangeEnum.THIS_MONTH]: {
    id: "date.range.status.this.month",
    defaultMessage: "This Month",
  },
  [DateRangeEnum.LAST_MONTH]: {
    id: "date.range.status.last.month",
    defaultMessage: "Last month",
  },
  [DateRangeEnum.PREVIOUS_30_DAYS]: {
    id: "date.range.status.previous.30.days",
    defaultMessage: "Previous 30 days",
  },
  [DateRangeEnum.THIS_YEAR]: {
    id: "date.range.status.this.year",
    defaultMessage: "This Year",
  },
  [DateRangeEnum.LAST_YEAR]: {
    id: "date.range.status.last.year",
    defaultMessage: "Last Year",
  },
  [DateRangeEnum.CUSTOM]: {
    id: "date.range.status.custom",
    defaultMessage: "Custom",
  },
} as const
