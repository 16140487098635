import * as Types from '../../../../../../shared/graphql/generated/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateFutureLocationsInTaxMutationVariables = Types.Exact<{
  data: Types.UpdateFutureLocationsInTaxInput;
}>;


export type UpdateFutureLocationsInTaxMutation = { __typename?: 'Mutation', updateFutureLocationsInTax: { __typename?: 'TaxModel', uuid: string, name: string, taxRate: number, description?: string | null, isGeneral?: boolean | null, applyFutureLocations?: boolean | null } };


export const UpdateFutureLocationsInTaxDocument = gql`
    mutation updateFutureLocationsInTax($data: UpdateFutureLocationsInTaxInput!) {
  updateFutureLocationsInTax(data: $data) {
    uuid
    name
    taxRate
    description
    isGeneral
    applyFutureLocations
  }
}
    `;
export type UpdateFutureLocationsInTaxMutationFn = Apollo.MutationFunction<UpdateFutureLocationsInTaxMutation, UpdateFutureLocationsInTaxMutationVariables>;

/**
 * __useUpdateFutureLocationsInTaxMutation__
 *
 * To run a mutation, you first call `useUpdateFutureLocationsInTaxMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFutureLocationsInTaxMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFutureLocationsInTaxMutation, { data, loading, error }] = useUpdateFutureLocationsInTaxMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateFutureLocationsInTaxMutation(baseOptions?: Apollo.MutationHookOptions<UpdateFutureLocationsInTaxMutation, UpdateFutureLocationsInTaxMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateFutureLocationsInTaxMutation, UpdateFutureLocationsInTaxMutationVariables>(UpdateFutureLocationsInTaxDocument, options);
      }
export type UpdateFutureLocationsInTaxMutationHookResult = ReturnType<typeof useUpdateFutureLocationsInTaxMutation>;
export type UpdateFutureLocationsInTaxMutationResult = Apollo.MutationResult<UpdateFutureLocationsInTaxMutation>;
export type UpdateFutureLocationsInTaxMutationOptions = Apollo.BaseMutationOptions<UpdateFutureLocationsInTaxMutation, UpdateFutureLocationsInTaxMutationVariables>;