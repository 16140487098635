import imageDefault from "../../../../../../shared/assets/images/ingredient-placeholder.svg"
import type { IntlMessage } from "../../../../../../shared/types"
import Container from "../../../../../../ui/Container"
import Img from "../../../../../../ui/Img"
import Text from "../../../../../../ui/Typography/Text"
import type { RcFile } from "antd/lib/upload"
import React from "react"
import { useIntl } from "react-intl"
import styled from "styled-components"

type IconPreviewProps = {
  src?: RcFile
  description: IntlMessage
  width: string
  height: string
}

export const IconPreview: React.FC<IconPreviewProps> = ({
  src,
  width,
  height,
  description,
}) => {
  const intl = useIntl()

  if (!src) {
    return null
  }

  return (
    <StyledAvatarWrapper
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      alignItems="center"
    >
      <Container className="image-container" width={width}>
        <Img
          width={width}
          height={height}
          src={URL.createObjectURL(src)}
          placeholderImage={imageDefault}
        />
      </Container>
      <Text size="s">{intl.formatMessage(description)}</Text>
    </StyledAvatarWrapper>
  )
}

const StyledAvatarWrapper = styled(Container)`
  height: 118px;

  .image-container {
    margin: auto;
  }
`
