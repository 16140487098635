import Icon from "../../../../../Icon"
import Text from "../../../../../Typography/Text"
import { Link } from "react-router-dom"
import styled from "styled-components"

interface RestaurantCompletionStepProps {
  link: string
  title: string
  completed: boolean
  onClick: () => void
}

export const RestaurantCompletionStep = ({
  link,
  title,
  onClick,
  completed,
}: RestaurantCompletionStepProps) => {
  return (
    <Container onClick={onClick} to={link}>
      {completed ? (
        <Icon
          size={16}
          color="Success5"
          remixiconClass="ri-checkbox-circle-fill"
        />
      ) : (
        <UncompletedIcon />
      )}
      <Text size="s" color="Neutral8">
        {title}
      </Text>
      <ArrowContainer>
        <Icon
          size={16}
          color="Neutral6"
          remixiconClass="ri-arrow-right-s-line"
        />
      </ArrowContainer>
    </Container>
  )
}

const ArrowContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

const Container = styled(Link)`
  width: 100%;
  display: flex;
  align-items: center;
  text-decoration: none;
  gap: 8px;
  outline: none;
  padding: 4px;
  border-radius: 4px;
  cursor: pointer;
  background-color: ${({ theme }) => theme.colors.Neutral0};
  transition: background-color 0.15s ease-in-out;
  &:hover,
  &:focus {
    background-color: ${({ theme }) => theme.colors.Neutral2};
  }
`

const UncompletedIcon = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.Neutral4};
`
