import * as Types from '../../../../../../shared/graphql/generated/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateExternalAccountMutationVariables = Types.Exact<{
  data: Types.CreateExternalAccountInput;
}>;


export type UpdateExternalAccountMutation = { __typename?: 'Mutation', updateExternalAccount: { __typename?: 'BankingAccountModel', uuid: string, isActive: boolean, name: string, stIsConnected: boolean, stHasExternalAccount: boolean } };


export const UpdateExternalAccountDocument = gql`
    mutation updateExternalAccount($data: CreateExternalAccountInput!) {
  updateExternalAccount(data: $data) {
    uuid
    isActive
    name
    stIsConnected
    stHasExternalAccount
  }
}
    `;
export type UpdateExternalAccountMutationFn = Apollo.MutationFunction<UpdateExternalAccountMutation, UpdateExternalAccountMutationVariables>;

/**
 * __useUpdateExternalAccountMutation__
 *
 * To run a mutation, you first call `useUpdateExternalAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateExternalAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateExternalAccountMutation, { data, loading, error }] = useUpdateExternalAccountMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateExternalAccountMutation(baseOptions?: Apollo.MutationHookOptions<UpdateExternalAccountMutation, UpdateExternalAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateExternalAccountMutation, UpdateExternalAccountMutationVariables>(UpdateExternalAccountDocument, options);
      }
export type UpdateExternalAccountMutationHookResult = ReturnType<typeof useUpdateExternalAccountMutation>;
export type UpdateExternalAccountMutationResult = Apollo.MutationResult<UpdateExternalAccountMutation>;
export type UpdateExternalAccountMutationOptions = Apollo.BaseMutationOptions<UpdateExternalAccountMutation, UpdateExternalAccountMutationVariables>;