import { GetBankingAccountByRestaurantDocument } from "../../../../../GraphQL/Queries/getBankingAccountByRestaurant.generated"
import { useGeneralContext } from "../../../../../shared/contexts/StoreProvider"
import { colors } from "../../../../../styles/global/themes/mainTheme"
import Button from "../../../../../ui/Button"
import Container from "../../../../../ui/Container"
import { showGraphqlErrors } from "../../../../../ui/ErrorList"
import Input from "../../../../../ui/Inputs/Input"
import type { IModalProps } from "../../../../../ui/Modal"
import Modal from "../../../../../ui/Modal"
import notification from "../../../../../ui/notification"
import { useCreateOnboardingLinkMutation } from "./GraphQL/createOnboardingLink.generated"
import { NewBankAccountResolver } from "./NewBankAccountResolver.yup"
import type { ICreateBankAccount } from "./hookforms.interfaces"
import React from "react"
import { Controller, useForm } from "react-hook-form"
import { useIntl } from "react-intl"
import styled from "styled-components"

type CreateBankAccountProps = Omit<IModalProps, "onCancel"> & {
  onCancel: () => void
}

export const CreateBankAccount: React.FC<CreateBankAccountProps> = ({
  onCancel,
  ...props
}) => {
  const {
    state: {
      currentRestaurant: { uuid: restaurantUUID },
    },
  } = useGeneralContext()

  const intl = useIntl()

  const [connectStripeAccount, { loading }] = useCreateOnboardingLinkMutation({
    refetchQueries: [GetBankingAccountByRestaurantDocument],
  })

  const {
    handleSubmit,
    reset,
    control,
    formState: { isValid, errors, dirtyFields },
  } = useForm<ICreateBankAccount>({
    mode: "all",
    resolver: NewBankAccountResolver,
  })

  const onClickRedirectNow = (url: string) => {
    window.location.replace(url)
  }

  const saveBankAccount = async ({ name }: ICreateBankAccount) => {
    try {
      const { data } = await connectStripeAccount({
        variables: { data: { restaurantUUID, name } },
      })

      if (data?.createOnboardingLink.url) {
        onCancel()

        notification({
          description: (
            <p>
              {intl.formatMessage({
                id: "restaurants.bank.accounts.add.bank.account.success",
                defaultMessage:
                  "Account create, You`ll be redirected in a few seconds to complete the kyc or click ",
              })}
              <StyledRedirectNowButton
                type="button"
                onClick={() =>
                  onClickRedirectNow(data?.createOnboardingLink.url)
                }
              >
                {intl.formatMessage({
                  id: "restaurants.bank.accounts.add.bank.account.redirectNow",
                  defaultMessage: "Redirect Now",
                })}
              </StyledRedirectNowButton>
            </p>
          ),
          onClose: () => onClickRedirectNow(data?.createOnboardingLink.url),
          type: "success",
        })
      }
    } catch (error) {
      showGraphqlErrors(error)
    }
  }

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    handleSubmit(saveBankAccount)()
  }

  return (
    <Modal
      {...props}
      onCancel={onCancel}
      shouldCloseOnClickOutside={Object.keys(dirtyFields).length === 0}
      footer={
        <Container display="flex" justifyContent="flex-end" gap="8px">
          <Button
            title={intl.formatMessage({
              id: "components.modal.cancel.button",
              defaultMessage: "Cancel",
            })}
            hierarchy="secondary"
            onClick={onCancel}
          />
          <Button
            disabled={!isValid}
            loading={loading}
            title={intl.formatMessage({
              id: "restaurants.bank.accounts.add.bank.account.save.button",
              defaultMessage: "Save Account Alias",
            })}
            onClick={handleSubmit(saveBankAccount)}
          />
        </Container>
      }
      title={intl.formatMessage({
        id: "restaurants.bank.accounts.add.bank.account.title",
        defaultMessage: "Create Bank Account Connection",
      })}
      afterClose={reset}
      closable={false}
      destroyOnClose
    >
      <form onSubmit={onSubmit} role="create-bank-account-form">
        <Controller
          control={control}
          name="name"
          render={({ field: nameField }) => (
            <Input
              {...nameField}
              label={intl.formatMessage({
                id: "restaurants.bank.accounts.add.bank.account.name.label",
                defaultMessage: "Connection Name",
              })}
              aria-label="name"
              hasError={!!errors?.name}
              helperText={errors?.name?.message}
              requirement="required"
              capitalizeFirstLetter={false}
            />
          )}
        />
      </form>
    </Modal>
  )
}

const StyledRedirectNowButton = styled.button`
  border: 0;
  background: ${colors["Success1"]};
  cursor: pointer;
  text-decoration: underline;
  font-weight: bold;
  padding: 0;
`
