import { useGeneralContext } from "../../../../../shared/contexts/StoreProvider"
import { AdminRoleNameEnum } from "../../../../../shared/graphql/generated/types"
import { showGraphqlErrors } from "../../../../../ui/ErrorList"
import type { IModalProps } from "../../../../../ui/Modal"
import Modal from "../../../../../ui/Modal"
import type { CustomMembersModel } from "../CreateMember/CreateMember"
import MemberForm from "../MemberForm"
import { MemberResolver } from "../MemberForm/MemberResolver.yup"
import type { IMemberForm } from "../interfaces/hookforms.interfaces"
import type { IMembersTableModel } from "../interfaces/membersTable.interfaces"
import { useUpdateRestaurantMemberMutation } from "./GraphQL/updateRestaurantMember.generated"
import React, { useState } from "react"
import { FormProvider, useForm } from "react-hook-form"
import { useIntl } from "react-intl"

type EditMemberProps = Omit<IModalProps, "onCancel"> & {
  onClose: () => void
  record: IMembersTableModel
}

export const EditMember: React.FC<EditMemberProps> = ({
  record,
  onClose,
  ...props
}) => {
  const intl = useIntl()
  const [loading] = useState(false)

  const {
    state: {
      currentRestaurant: { uuid: restaurantUUID },
    },
  } = useGeneralContext()

  const methods = useForm<IMemberForm>({
    mode: "all",
    resolver: MemberResolver,
    defaultValues: {
      ...record,
      email: record.email === null ? undefined : record.email,
      roleName: record.roleName,
      locationUUIDs: record.locations.map((item) => {
        return item.uuid
      }),
    },
  })

  const {
    handleSubmit,
    reset,
    formState: { isValid },
  } = methods

  const [updateRestaurantMember, { loading: updating }] =
    useUpdateRestaurantMemberMutation({
      update: (cache, result) => {
        const data = result.data?.updateRestaurantMember

        if (data) {
          const newValue: CustomMembersModel = {
            uuid: data.inviteUUID,
            inviteUUID: data.inviteUUID,
            userUUID: data.userUUID,
            restaurantUUID,
            email: data.email,
            firstName: data.firstName,
            inviteStatus: data.inviteStatus,
            lastName: data.lastName,
            locations: data.locations,
            roleName: data.roleName,
          }

          cache.modify({
            fields: {
              getAllMembersByRestaurant: (prev) => {
                const previous = prev as Array<CustomMembersModel>

                return previous.map((current) => {
                  if (current.inviteUUID === data.inviteUUID) {
                    return newValue
                  }

                  return current
                })
              },
            },
          })
        }
      },
    })

  const editMember = async (formData: IMemberForm) => {
    try {
      await updateRestaurantMember({
        variables: {
          data: {
            inviteUUID: record.inviteUUID,
            restaurantUUID: record.restaurantUUID,
            ...(!!record.userUUID && { userUUID: record.userUUID }),
            email: formData.email,
            firstName: formData.firstName,
            lastName: formData.lastName,
            ...(formData.roleName === AdminRoleNameEnum.RESTAURANT_MANAGER && {
              locationUUIDs: formData.locationUUIDs,
            }),
            roleName: formData.roleName,
          },
        },
      })
      onClose()
    } catch (error) {
      showGraphqlErrors(error)
    }
  }

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    handleSubmit(editMember)()
  }

  return (
    <FormProvider {...methods}>
      <Modal
        {...props}
        title={intl.formatMessage({
          id: "settings.restaurant.members.edit.member.modal.title",
          defaultMessage: "Edit Member",
        })}
        okText={intl.formatMessage({
          id: "components.modal.ok.button",
          defaultMessage: "OK",
        })}
        afterClose={reset}
        onOk={handleSubmit(editMember)}
        onCancel={onClose}
        okButtonProps={{
          disabled: !isValid,
          loading: loading || updating,
        }}
        destroyOnClose
      >
        <MemberForm onSubmit={onSubmit} />
      </Modal>
    </FormProvider>
  )
}
