import * as Types from '../../../../../shared/graphql/generated/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetQrConfigRestaurantQueryVariables = Types.Exact<{
  restaurantUUID: Types.Scalars['ID'];
  locationUUID?: Types.InputMaybe<Types.Scalars['ID']>;
}>;


export type GetQrConfigRestaurantQuery = { __typename?: 'Query', getQrConfigRestaurant: { __typename?: 'RestaurantQrConfigModel', uuid: string, qrConfig?: any | null } };


export const GetQrConfigRestaurantDocument = gql`
    query getQrConfigRestaurant($restaurantUUID: ID!, $locationUUID: ID) {
  getQrConfigRestaurant(
    restaurantUUID: $restaurantUUID
    locationUUID: $locationUUID
  ) {
    uuid
    qrConfig
  }
}
    `;

/**
 * __useGetQrConfigRestaurantQuery__
 *
 * To run a query within a React component, call `useGetQrConfigRestaurantQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetQrConfigRestaurantQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetQrConfigRestaurantQuery({
 *   variables: {
 *      restaurantUUID: // value for 'restaurantUUID'
 *      locationUUID: // value for 'locationUUID'
 *   },
 * });
 */
export function useGetQrConfigRestaurantQuery(baseOptions: Apollo.QueryHookOptions<GetQrConfigRestaurantQuery, GetQrConfigRestaurantQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetQrConfigRestaurantQuery, GetQrConfigRestaurantQueryVariables>(GetQrConfigRestaurantDocument, options);
      }
export function useGetQrConfigRestaurantLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetQrConfigRestaurantQuery, GetQrConfigRestaurantQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetQrConfigRestaurantQuery, GetQrConfigRestaurantQueryVariables>(GetQrConfigRestaurantDocument, options);
        }
export type GetQrConfigRestaurantQueryHookResult = ReturnType<typeof useGetQrConfigRestaurantQuery>;
export type GetQrConfigRestaurantLazyQueryHookResult = ReturnType<typeof useGetQrConfigRestaurantLazyQuery>;
export type GetQrConfigRestaurantQueryResult = Apollo.QueryResult<GetQrConfigRestaurantQuery, GetQrConfigRestaurantQueryVariables>;