import Container from "../../../../../../ui/Container"
import {
  DAY_END_HOUR,
  DAY_START_HOUR,
} from "../../../../../../ui/CustomTimePicker/constants"
import DeleteIconButton from "../../../../../../ui/DeleteIconButton"
import TimeSelectRange from "../../../../../../ui/TimeSelectRange"
import type { TimeRangeEnum } from "../../../../../../ui/TimeSelectRange/time-select-range.enums"
import type { IBusinessHoursForm } from "../../hookforms.interfaces"
import TimeRangeActions from "../TimeRangeActions"
import React, { useState } from "react"
import { useFormContext } from "react-hook-form"

type BusinessTimeRangeProps = {
  dayIndex: number
  scheduleIndex: number
  copyShedule: () => void
  addSchedule: () => void
  deleteSchedule: (scheduleIndex: string | number) => void
}

export const BusinessTimeRange: React.FC<BusinessTimeRangeProps> = ({
  dayIndex,
  scheduleIndex,
  copyShedule,
  addSchedule,
  deleteSchedule,
}) => {
  const {
    setValue,
    watch,
    trigger,
    formState: { errors },
  } = useFormContext<IBusinessHoursForm>()

  const rangeErrors =
    errors.schedules?.[dayIndex]?.defaultValues?.[scheduleIndex]

  const defaultStartTime = watch(
    `schedules.${dayIndex}.defaultValues.${scheduleIndex}.startDate`
  )
  const defaultEndTime = watch(
    `schedules.${dayIndex}.defaultValues.${scheduleIndex}.endDate`
  )
  const isAllDayValue =
    defaultStartTime === DAY_START_HOUR && defaultEndTime === DAY_END_HOUR

  const [isAllDay, setIsAllDay] = useState<boolean | undefined>(isAllDayValue)

  const handleChange = (
    value_HH_mm: string,
    type: `${TimeRangeEnum}`,
    allDay?: boolean
  ) => {
    if (type === "end") {
      setValue(
        `schedules.${dayIndex}.defaultValues.${scheduleIndex}.endDate`,
        value_HH_mm ?? defaultStartTime,
        {
          shouldValidate: true,
          shouldDirty: true,
        }
      )
      trigger(`schedules.${dayIndex}.defaultValues.${scheduleIndex}`)
    }

    if (type === "start") {
      setIsAllDay(allDay)

      setValue(
        `schedules.${dayIndex}.defaultValues.${scheduleIndex}.startDate`,
        value_HH_mm ?? defaultEndTime,
        {
          shouldValidate: true,
          shouldDirty: true,
        }
      )
    }

    trigger(`schedules.${dayIndex}`)
  }

  const handleClear = (type: `${TimeRangeEnum}`) => {
    if (type === "end") {
      setValue(
        `schedules.${dayIndex}.defaultValues.${scheduleIndex}.endDate`,
        undefined,
        { shouldValidate: true, shouldDirty: true }
      )
    }

    if (type === "start") {
      setValue(
        `schedules.${dayIndex}.defaultValues.${scheduleIndex}.startDate`,
        undefined,
        { shouldValidate: true, shouldDirty: true }
      )
    }

    trigger(`schedules.${dayIndex}.defaultValues.${scheduleIndex}`)
  }

  return (
    <Container
      key={scheduleIndex}
      display="flex"
      justifyContent="flex-end"
      gap="6px"
    >
      <TimeSelectRange
        startTime={defaultStartTime}
        endTime={defaultEndTime}
        onChange={handleChange}
        onClear={handleClear}
        allowAllDay={scheduleIndex === 0}
        error={
          rangeErrors?.message ||
          rangeErrors?.startDate?.message ||
          rangeErrors?.endDate?.message
        }
      />
      <div hidden={scheduleIndex > 0}>
        <TimeRangeActions
          copyShedule={copyShedule}
          addSchedule={isAllDay ? undefined : addSchedule}
        />
      </div>
      <div hidden={scheduleIndex === 0}>
        <DeleteIconButton id={`${scheduleIndex}`} onDelete={deleteSchedule} />
      </div>
    </Container>
  )
}
