import type { GraphqlMutationError } from "../../shared/utils/helpers/parseGraphqlErrorMessages"
import parseGraphqlErrorMessages from "../../shared/utils/helpers/parseGraphqlErrorMessages"
import parseNetworkErrorMessages from "../../shared/utils/helpers/parseNetworkErrorMessages"
import { colors } from "../../styles/global/themes/mainTheme"
import notification from "../notification"
import type { ApolloError } from "@apollo/client"
import get from "lodash/get"
import React from "react"
import styled from "styled-components"

export interface ErrorListProps {
  messages: string[]
}

export const ErrorList: React.FC<ErrorListProps> = ({ messages }) => {
  return (
    <StyledErrorListWrapper>
      {messages.map((message, index) => (
        <li key={index}>{message}</li>
      ))}
    </StyledErrorListWrapper>
  )
}

export const showGraphqlErrors = (
  error: GraphqlMutationError | ApolloError | unknown,
  title?: string,
  duration?: number
) => {
  if (!error) return

  const exception = error as GraphqlMutationError

  if (exception?.graphQLErrors?.length) {
    const errorMessages = parseGraphqlErrorMessages(exception)

    if (errorMessages.length > 0) {
      notification({
        description: <ErrorList messages={errorMessages} />,
        type: "error",
        duration,
        closable: true,
      })

      return
    }
  }

  const graphqlError400 = JSON.parse(JSON.stringify(error))
  const networkError = get(graphqlError400, "networkError")

  if (networkError) {
    const errorMessages = parseNetworkErrorMessages(networkError)

    if (errorMessages.length > 0) {
      notification({
        description: <ErrorList messages={errorMessages} />,
        type: "error",
        duration,
        closable: true,
      })

      return
    }
  }

  notification({
    description: title || get(error, "message"),
    type: "error",
    duration,
    closable: true,
  })
}

const StyledErrorListWrapper = styled.ul`
  padding: 0;
  list-style: none;
`

export const NotificationErrorStyles: React.CSSProperties = {
  background: colors["Danger1"],
  border: `1px solid ${colors["Danger2"]}`,
  borderRadius: 4,
}
