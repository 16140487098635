import type { SizeType } from "../../../styles/global/types"
import Icon from "../../Icon"
import type { InputLabelProps } from "../../InputLabel"
import {
  eventCodeArrow,
  eventCodeKey,
  eventKeyOnlyLetters,
  leadingAndTrailingSpaces,
  multipleSpacesToOneSpace,
} from "./constant/regex"
import type { InputProps as AntdInputProps } from "antd"
import { Input as AntdInput, Spin, Tooltip } from "antd"
import type { Property } from "csstype"
import omit from "lodash/omit"
import pick from "lodash/pick"
import React, { useImperativeHandle } from "react"
import styled, { css } from "styled-components"

type InputInnerWrapperProps = {
  hasError?: boolean
  errorMessage?: string
  disabled?: boolean
  searchIcon?: boolean
  clearIcon?: boolean
  minWidth?: string
  maxWidth?: string
  width?: string
  size?: "middle" | "large"
}

type StyledInputProps = Omit<AntdInputProps, "width" | "allowClear"> & {
  width?: string
  className?: string
  label?: React.ReactNode | string
  textTransform?: Property.TextTransform
  useEllipsis?: boolean
}

type CustomInputProps = {
  wrapperProps?: Record<string, unknown>
  loading?: boolean
  onlyInteger?: boolean
  onlyLetters?: boolean
  capitalizeFirstLetter?: boolean
}

export type InputProps = Omit<InputLabelProps, "label"> &
  InputInnerWrapperProps &
  StyledInputProps &
  CustomInputProps

const FancySimpleInput = (
  props: InputProps,
  ref:
    | ((instance: unknown) => void)
    | React.RefObject<unknown>
    | null
    | undefined
) => {
  const wrapperPropsList = [
    "hasError",
    "errorMessage",
    "disabled",
    "searchIcon",
    "clearIcon",
    "minWidth",
    "maxWidth",
    "width",
    "size",
  ]
  const inputPropsList = [
    "disabled",
    "size",
    "type",
    "className",
    "label",
    "textTransform",
  ]

  const inputProps = pick(props, inputPropsList)
  const innerWrapperProps = pick(props, wrapperPropsList)

  const {
    wrapperProps,
    loading,
    onlyInteger,
    onlyLetters,
    onChange,
    onBlur,
    capitalizeFirstLetter,
    bordered = true,
    ...rest
  } = omit(props, [...wrapperPropsList, ...inputPropsList, "tooltipTitle"])

  const inputRef = React.createRef<AntdInput>()

  useImperativeHandle(ref, () => ({
    focus: () => {
      inputRef?.current?.focus()
    },
    select: () => {
      inputRef?.current?.select()
    },
  }))

  const onClearIconClick = () => {
    inputRef.current?.setValue("")
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onChange?.({ target: { value: "" } } as any)
  }

  function capitalizeFirstLetterInput(
    event: React.ChangeEvent<HTMLInputElement>
  ) {
    event.target.value =
      event.target.value.charAt(0).toUpperCase() + event.target.value.slice(1)

    return event
  }

  const keyboardDefault = new Set(["Backspace", "Enter"])

  const onInputNumberKeyDown = (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (
      event.code !== "KeyE" &&
      (keyboardDefault.has(event.code) ||
        eventCodeKey.test(event.code) ||
        eventCodeArrow.test(event.code))
    ) {
      props?.onKeyDown?.(event)

      return
    }

    const invalidOnlyIntegerNumber =
      onlyInteger &&
      (event.key === "." || event.key === "," || event.key === "-")

    const invalidNumbers = inputProps.type === "number" && event.key === "e"

    const invalidOnlyLetters =
      inputProps.type !== "number" &&
      onlyLetters &&
      !eventKeyOnlyLetters.test(event.key)

    if (invalidOnlyIntegerNumber || invalidNumbers || invalidOnlyLetters) {
      event.preventDefault()
    } else {
      props?.onKeyDown?.(event)
    }
  }

  const onChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange?.(event)
  }

  const onBlurInput = (event: React.FocusEvent<HTMLInputElement, Element>) => {
    if (event.target.value !== "") {
      event.target.value = event.target.value
        .replace(leadingAndTrailingSpaces, "")
        .replace(multipleSpacesToOneSpace, " ")
      onChange?.(
        capitalizeFirstLetter ? capitalizeFirstLetterInput(event) : event
      )
    }
    onBlur?.(event)
  }

  return (
    <StyledInputInnerWrapper
      className="input-inner-wrapper"
      $hasError={innerWrapperProps.hasError}
      $disabled={innerWrapperProps.disabled}
      $searchIcon={innerWrapperProps.searchIcon}
      $clearIcon={innerWrapperProps.clearIcon}
      $minWidth={innerWrapperProps.minWidth}
      $maxWidth={innerWrapperProps.maxWidth}
      width={innerWrapperProps.width}
      size={innerWrapperProps.size}
      bordered={bordered}
      {...wrapperProps}
    >
      {innerWrapperProps.searchIcon && (
        <Icon
          size={16}
          color="Neutral5"
          classes="search__icon"
          remixiconClass="ri-search-line"
        />
      )}

      <StyledInput
        role="textbox"
        {...rest}
        {...inputProps}
        type={inputProps.type}
        ref={inputRef}
        onKeyDown={onInputNumberKeyDown}
        onChange={onChangeInput}
        onBlur={onBlurInput}
        autoComplete="off"
        bordered={bordered}
      />

      {loading && (
        <StyledSpin
          className="spin_loading__icon"
          indicator={
            <Icon
              size={18}
              classes="loading__icon"
              remixiconClass="ri-loader-5-line"
              color="Secondary5"
              spinning
            />
          }
        />
      )}

      {innerWrapperProps.clearIcon && !loading && !innerWrapperProps.hasError && (
        <div className="wrapper_close__icon">
          <Icon
            size={16}
            classes="close__icon"
            remixiconClass="ri-close-circle-line"
            onClick={onClearIconClick}
            display="flex"
            alignItems="center"
            color="Neutral5"
          />
        </div>
      )}

      {innerWrapperProps.hasError && !loading && (
        <Tooltip title={innerWrapperProps.errorMessage}>
          <div className="wrapper_error__icon">
            <Icon
              size={16}
              classes="error__icon"
              remixiconClass="ri-error-warning-line"
              display="flex"
              alignItems="center"
              color="Danger5"
            />
          </div>
        </Tooltip>
      )}
    </StyledInputInnerWrapper>
  )
}

export const SimpleInput = React.forwardRef(FancySimpleInput)

SimpleInput.defaultProps = {
  size: "middle",
}

export const getInputHeight = (size?: "middle" | "large") => {
  if (size === "large") {
    return "40px"
  }

  return "32px"
}

export const getInputTextSizes = (size?: SizeType) => {
  switch (size) {
    case "small": {
      return css`
        ${({ theme }) => `${theme.text.body.s.regular.fontSize}px`}
      `
    }

    case "large": {
      return css`
        ${({ theme }) => `${theme.text.body.l.regular.fontSize}px`};
      `
    }
    default: {
      return css`
        ${({ theme }) => `${theme.text.body.m.regular.fontSize}px`};
      `
    }
  }
}

type StyledInputInnerWrapperProps = {
  $hasError?: boolean
  $disabled?: boolean
  $searchIcon?: boolean
  $clearIcon?: boolean
  $minWidth?: string
  $maxWidth?: string
  width?: string
  size?: "middle" | "large"
  bordered?: boolean
}

const StyledInputInnerWrapper = styled.div<StyledInputInnerWrapperProps>`
  height: ${(props) => getInputHeight(props.size)};
  position: relative;

  ${({ theme, bordered }) =>
    css`
      border-radius: 4px;
      padding: 0px;
      display: flex;
      flex-flow: row nowrap;
      justify-content: left;
      align-items: center;
      background-color: ${theme.colors.Neutral0};
      ${bordered &&
      css`
        border: 1px solid ${theme.colors.Neutral4};
      `}

      &:hover {
        border: 1px solid ${theme.colors.Primary5};
        box-shadow: none;
        transition: all 0.3s, height 0s;
      }

      &:focus,
      &:focus-within {
        border: 2px solid ${theme.colors.Primary5};
        box-shadow: none;
        transition: all 0.3s, height 0s;
      }

      &:active,
      &:focus,
      &:focus-within {
        border: 2px solid ${theme.colors.Primary5};
      }
    `}

  ${({ $disabled, theme }) =>
    $disabled &&
    css`
      background-color: ${theme.colors.Neutral3};
      border-color: ${theme.colors.Neutral4};

      .ant-input {
        color: ${theme.colors.Neutral5} !important;
      }

      &:hover,
      &:active,
      &:focus,
      &:focus-within {
        border-color: ${theme.colors.Neutral4};
        box-shadow: none;
      }
    `}

  ${({ $searchIcon }) =>
    $searchIcon &&
    css`
      & .search__icon {
        padding-left: 8px;
      }
    `}

    ${({ $clearIcon, $disabled }) =>
    $clearIcon &&
    css`
      .wrapper_close__icon {
        width: 18px;

        .close__icon {
          position: absolute;
          top: 0px;
          bottom: 0px;
          right: 7px;
          margin: auto;

          ${!$disabled &&
          css`
            &:hover {
              cursor: pointer;
            }
          `}
        }
      }
    `}

    ${({ $hasError, theme }) =>
    $hasError &&
    css`
      border-color: ${theme.colors.Danger5};

      &:hover,
      &:active,
      &:focus,
      &:focus-within {
        border-color: ${theme.colors.Danger5};
        box-shadow: none;
      }

      .wrapper_error__icon {
        width: 18px;

        .error__icon {
          position: absolute;
          top: 0px;
          bottom: 0px;
          right: 4px;
          margin: auto;
        }
      }
    `}

  .spin_loading__icon {
    width: 18px;
    .loading__icon {
      position: absolute;
      top: 0px;
      bottom: 0px;
      right: 7px;
      margin: auto;
    }
  }

  .ant-input-prefix,
  .ant-input-suffix {
    color: ${({ theme }) => theme.colors.Neutral5};
  }

  ${({ width }) =>
    width &&
    css`
      width: ${width};
    `}

  ${({ $minWidth }) =>
    $minWidth &&
    css`
      min-width: ${$minWidth};
    `}

    ${({ $maxWidth }) =>
    $maxWidth &&
    css`
      max-width: ${$maxWidth};
    `}
`

const StyledInput = styled(AntdInput)<StyledInputProps>`
  ${({ theme, size, textTransform, useEllipsis }) =>
    css`
      border-radius: 4px;

      &.ant-input-affix-wrapper,
      &.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover,
      &.ant-input-affix-wrapper-focused {
        border: none;
        box-shadow: none;

        background-color: ${theme.colors.transparent};
        .ant-input {
          background-color: ${theme.colors.transparent};
          text-transform: ${textTransform ? `${textTransform}` : ""};
        }
      }

      .ant-input,
      &.ant-input {
        color: ${theme.colors.Neutral8};
        ${useEllipsis
          ? `
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          `
          : ""}
      }

      &.ant-input,
      .ant-input-group-addon:not(:first-child):not(:last-child),
      .ant-input-group-wrap:not(:first-child):not(:last-child),
      .ant-input-group > .ant-input:not(:first-child):not(:last-child) {
        font-size: ${getInputTextSizes(size)};
        font-family: ${theme.text.fonts.dmSansRegular};
        font-style: ${theme.text.body.m.regular.fontStyle};
        font-weight: ${theme.text.body.m.regular.fontWeight};
        line-height: ${`${theme.text.body.m.regular.lineHeight}px`};
        text-transform: ${textTransform ? `${textTransform}` : ""};
        padding: 8px 12px;
        background-color: ${theme.colors.transparent};
        height: 100%;
        border: none;
        outline: 0;

        &:hover,
        &:active,
        &:focus {
          border-color: none;
          box-shadow: none;
        }

        &::placeholder {
          color: ${theme.colors["Neutral5"]};
          text-overflow: initial;
        }
      }
    `}
`

const StyledSpin = styled(Spin)`
  padding-right: 8px;
`
