import moment from "moment-timezone"

const calculateElapsedTimeFromNow = (date: string | undefined | null) => {
  try {
    if (!date) {
      return 0
    }

    const formatedLastCheckin = moment(date, moment.defaultFormat).toDate()

    return moment(formatedLastCheckin).fromNow()
  } catch {
    return 0
  }
}

export default calculateElapsedTimeFromNow
