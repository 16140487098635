/* eslint-disable unicorn/no-thenable */
import yup from "../../../../../shared/yup"
import { TokenTypeEnum } from "./hookforms.interfaces"
import { yupResolver } from "@hookform/resolvers/yup"

const accountTypeRequiredSchema = (field = yup.string()) =>
  field.when("type", {
    is: TokenTypeEnum.BANK_ACCOUNT,
    then: yup.string().required(),
  })

export const AccountDebitCardResolver = yupResolver(
  yup.object().shape({
    type: yup.string(),
    country: accountTypeRequiredSchema(),
    currency: accountTypeRequiredSchema(),
    routing_number: accountTypeRequiredSchema(
      yup.string().label("Routing Number").length(9)
    ),
    account_number: accountTypeRequiredSchema(),
    account_holder_name: accountTypeRequiredSchema(),
    account_holder_type: accountTypeRequiredSchema(),
  })
)
