import { Skeleton } from "antd"
import range from "lodash/range"

export const LocationListSkeleton = () => {
  return (
    <div role="location-list-skeleton">
      {range(5).map((index) => {
        return (
          <Skeleton
            key={index}
            title={false}
            paragraph={{ rows: 1, width: 250 }}
          />
        )
      })}
    </div>
  )
}
