import * as Types from '../../../../../../shared/graphql/generated/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetOneTaxQueryVariables = Types.Exact<{
  uuid: Types.Scalars['ID'];
}>;


export type GetOneTaxQuery = { __typename?: 'Query', getOneTax: { __typename?: 'TaxWithLocationOrItemModel', uuid: string, name: string, taxRate: number, description?: string | null, isGeneral?: boolean | null, applyFutureLocations?: boolean | null, itemTaxes: Array<{ __typename?: 'TaxWithItemModel', uuid: string, location: { __typename?: 'ItemBasicModel', uuid: string, name: string }, item: { __typename?: 'ItemBasicWithAttachmentModel', uuid: string, name: string, attachment?: { __typename?: 'AttachmentModel', uuid: string, ext: Types.FileExtensionEnum, fileName: string, contentType: Types.ContentTypeEnum, signedUrl: string } | null } }>, locationTaxes: Array<{ __typename?: 'TaxWithLocationModel', uuid: string, location: { __typename?: 'ItemBasicModel', uuid: string, name: string } }> } };


export const GetOneTaxDocument = gql`
    query getOneTax($uuid: ID!) {
  getOneTax(uuid: $uuid) {
    uuid
    name
    taxRate
    description
    isGeneral
    applyFutureLocations
    itemTaxes {
      uuid
      location {
        uuid
        name
      }
      item {
        uuid
        name
        attachment {
          uuid
          ext
          fileName
          contentType
          signedUrl
        }
      }
    }
    locationTaxes {
      uuid
      location {
        uuid
        name
      }
    }
  }
}
    `;

/**
 * __useGetOneTaxQuery__
 *
 * To run a query within a React component, call `useGetOneTaxQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOneTaxQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOneTaxQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useGetOneTaxQuery(baseOptions: Apollo.QueryHookOptions<GetOneTaxQuery, GetOneTaxQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOneTaxQuery, GetOneTaxQueryVariables>(GetOneTaxDocument, options);
      }
export function useGetOneTaxLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOneTaxQuery, GetOneTaxQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOneTaxQuery, GetOneTaxQueryVariables>(GetOneTaxDocument, options);
        }
export type GetOneTaxQueryHookResult = ReturnType<typeof useGetOneTaxQuery>;
export type GetOneTaxLazyQueryHookResult = ReturnType<typeof useGetOneTaxLazyQuery>;
export type GetOneTaxQueryResult = Apollo.QueryResult<GetOneTaxQuery, GetOneTaxQueryVariables>;