import { useGetSingleLocationQuery } from "../../../../GraphQL/Queries/getSingleLocation.generated"
import { useRestaurantCompletionStepsContext } from "../../../../shared/contexts/RestaurantCompletionStepsProvider"
import { useGeneralContext } from "../../../../shared/contexts/StoreProvider"
import { LOCATION_NOT_FOUND } from "../../../../shared/graphql/constants"
import DetailPage from "../../../../ui/Layouts/MainLayout/DetailPage"
import PageTitle from "../../../../ui/PageTitle"
import RetryPage from "../../../../ui/RetryPage"
import type { ILocationsParams } from "../hookforms.interfaces"
import { isLocationActive } from "../utils/is-location-active"
import { HolidayHours } from "./components/HolidayHours"
import { HoursTabs } from "./components/HoursTabs"
import { StandardHours } from "./components/StandardHours"
import { TabsItemsEnum } from "./utils/TabsItemsEnum"
import { TAB_SEARCH_FILTER_NAME } from "./utils/searchParams"
import get from "lodash/get"
import React, { useMemo } from "react"
import { useIntl } from "react-intl"
import { useLocation, useParams } from "react-router-dom"

export const HoursOfOperation: React.FC = () => {
  const { search } = useLocation()
  const searchParams = useMemo(() => new URLSearchParams(search), [search])
  const selectedTab = searchParams.get(TAB_SEARCH_FILTER_NAME)
  const {
    mainLocationUUID,
    updateRestaurantCompletionSteps,
    hasHoursOfOperationSetupCompleted,
  } = useRestaurantCompletionStepsContext()

  const intl = useIntl()
  const { locationUUID } = useParams<ILocationsParams>()

  const {
    state: {
      currentRestaurant: { uuid: restaurantUUID },
    },
  } = useGeneralContext()

  const {
    data,
    error,
    refetch,
    called,
    loading: isFetchingSchedules,
  } = useGetSingleLocationQuery({
    variables: { locationUUID },
    skip: !locationUUID,
    fetchPolicy: "cache-and-network",
    onCompleted: ({ getSingleLocation }) => {
      if (mainLocationUUID === locationUUID) {
        const hasHoursOfOperation = getSingleLocation.schedules.some(
          (schedule) => schedule.scheduleDetail.length > 0
        )

        if (hasHoursOfOperationSetupCompleted !== hasHoursOfOperation)
          updateRestaurantCompletionSteps({
            hasHoursOfOperationSetupCompleted: hasHoursOfOperation,
          })
      }
    },
  })

  const locationName = get(data, "getSingleLocation.name")
  const isActive = isLocationActive(data?.getSingleLocation)

  const reload = () => refetch()

  if (error) {
    return <RetryPage error={error} reload={reload} fullScreen />
  }

  if (called && !isFetchingSchedules && !isActive) {
    return (
      <RetryPage
        error={{ message: LOCATION_NOT_FOUND }}
        reload={reload}
        fullScreen
      />
    )
  }

  return (
    <DetailPage>
      <PageTitle
        title={intl.formatMessage(
          {
            id: "restaurants.locations.hours.of.operation.header.title",
            defaultMessage: "{locationName} Hours Of Operation",
          },
          {
            locationName: locationName ? `${locationName} -` : "",
          }
        )}
        border={false}
      />

      <HoursTabs />
      {selectedTab === TabsItemsEnum.HOLIDAY ? (
        <HolidayHours locationUUID={locationUUID} />
      ) : (
        <StandardHours
          restaurantUUID={restaurantUUID}
          locationUUID={locationUUID}
          schedulesData={data?.getSingleLocation.schedules}
          loading={isFetchingSchedules}
        />
      )}
    </DetailPage>
  )
}
