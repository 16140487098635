import paths from "../../../../../shared/routes/paths"

export const getSquareBreadcrumbItems = (locationUUID: string) => [
  {
    title: "Integrations",
    href: paths.settings.locations.integrationsByLocation(locationUUID),
  },
  {
    title: "Square",
    href: "#",
  },
]

export const loremIpsum =
  "Lorem ipsum dolor sit amet consectetur. Eget ac sodales nisi sed tortor hac egestas et phasellus est augue tortor turpis. Lorem ipsum dolor sit amet consectetur. Eget ac sodales nisi sed tortor hac egestas et phasellus est augue tortor turpis."

export const SquareData = {
  iconAlt: "Square Icon",
  howItWorks: {
    MENU: {
      id: "restaurants.settings.locations.square.integration.how.it.works.menu.description",
      defaultMessage:
        "Achieve seamless integration between your Square system and our platform. Easily import your menu details (items, prices, descriptions) in just minutes and have all the information at your fingertips. Enjoy efficiency and save valuable time.",
    },
    ORDER_AGGREGATION: {
      id: "restaurants.settings.locations.square.integration.how.it.works.order.description",
      defaultMessage:
        "Let us bridge the gap between Square and your kitchen display system. Our integration effortlessly syncs orders in real time, eliminating the need for manual transfers and ensuring your CRMB KDS always has the latest information at hand.",
    },
    href: "https://developer.squareup.com/docs/build-basics/access-tokens",
  },
}

export const connectLocationsFormId = "connect-locations-form"
