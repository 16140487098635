import Logo from "./Logo"
import SidebarMenu from "./SidebarMenu"
import React from "react"

export const Sidebar: React.FC = () => {
  return (
    <>
      <Logo />
      <SidebarMenu />
    </>
  )
}
