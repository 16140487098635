import Container from "../Container"
import Text from "../Typography/Text"
import { Empty } from "antd"
import React from "react"

interface EmptyStateProps {
  title?: string
  description?: string
  fullScreen?: boolean
  button?: React.ReactNode
  image?: string
}

export const EmptyState: React.FC<EmptyStateProps> = ({
  description,
  fullScreen,
  title = "",
  button,
  image,
}) => {
  return (
    <Container position="relative" centered fullScreen={fullScreen}>
      <Empty
        description={false}
        image={image ? <img src={image} /> : Empty.PRESENTED_IMAGE_DEFAULT}
        imageStyle={IconStyle}
      >
        <Text weight="bold" size="l" textAlign="center">
          {title}
        </Text>
        <Text size="s" textAlign="center">
          {description}
        </Text>
        {button ?? null}
      </Empty>
    </Container>
  )
}

const IconStyle = {
  height: "104px",
}
