import {
  GetBankingAccountByRestaurantQuery,
  GetBankingAccountByRestaurantQueryVariables,
} from "../../../../../GraphQL/Queries/getBankingAccountByRestaurant.generated"
import { LazyQueryExecFunction } from "@apollo/client"

export interface GetHasBankAccountSetupCompletedArgs {
  restaurantUUID: string
  getBankingAccountByRestaurant: LazyQueryExecFunction<
    GetBankingAccountByRestaurantQuery,
    GetBankingAccountByRestaurantQueryVariables
  >
}

export const getHasBankAccountSetupCompleted = async ({
  restaurantUUID,
  getBankingAccountByRestaurant,
}: GetHasBankAccountSetupCompletedArgs) => {
  const { data } = await getBankingAccountByRestaurant({
    fetchPolicy: "cache-first",
    variables: {
      restaurantUUID,
    },
  })

  return !!data?.getBankingAccountsByRestaurant.some(
    ({ stIsConnected }) => stIsConnected
  )
}
