import type { GetSingleLocationQuery } from "../../../../../../GraphQL/Queries/getSingleLocation.generated"
import { GetSingleLocationDocument } from "../../../../../../GraphQL/Queries/getSingleLocation.generated"
import type { IScheduleDetailForm } from "../../../../../../shared/types"
import { showGraphqlErrors } from "../../../../../../ui/ErrorList"
import Spacer from "../../../../../../ui/Spacer"
import Text from "../../../../../../ui/Typography/Text"
import notification from "../../../../../../ui/notification"
import { useCreateScheduleForLocationMutation } from "../../../CreateLocation/GraphQL/createScheduleForLocation.generated"
import { useUpdateScheduleForLocationMutation } from "../../../GraphQL/updateScheduleForLocation.generated"
import reduceScheduleToDaysOfTheWeek, {
  getScheduleFromDaysOfTheWeek,
} from "../../BusinessHours/utils/getTimeRangesByDaysOfTheWeek"
import type { IBusinessHoursForm } from "../../hookforms.interfaces"
import { HoursOfOperationForm } from "./HoursOfOperationForm/HoursOfOperationForm"
import { HoursOfOperationSkeleton } from "./HoursOfOperationSkeleton"
import { get } from "lodash"
import { useCallback, useState } from "react"
import { useIntl } from "react-intl"

interface IStandardHours {
  restaurantUUID: string
  locationUUID: string
  schedulesData:
    | GetSingleLocationQuery["getSingleLocation"]["schedules"]
    | undefined
  loading?: boolean
}

export const StandardHours = ({
  restaurantUUID,
  locationUUID,
  schedulesData,
}: IStandardHours) => {
  const intl = useIntl()
  const [loading, setLoading] = useState(false)

  const scheduleUUID = get(schedulesData, "0.uuid")
  const scheduleDetail = get(schedulesData, "0.scheduleDetail", [])

  const defaultValues = scheduleDetail?.map((value: IScheduleDetailForm) => ({
    day: value.day,
    startDate: value.startDate,
    endDate: value.endDate,
  }))

  const schedules = reduceScheduleToDaysOfTheWeek(defaultValues || [])

  const [createScheduleForLocation] = useCreateScheduleForLocationMutation({
    refetchQueries: [GetSingleLocationDocument],
  })

  const [updateScheduleForLocation] = useUpdateScheduleForLocationMutation({
    refetchQueries: [GetSingleLocationDocument],
  })

  const createOrUpdateLocationSchedule = useCallback(
    async (formData: IBusinessHoursForm, scheduleUUIDtoUpdate?: string) => {
      if (!scheduleUUIDtoUpdate) {
        await createScheduleForLocation({
          variables: {
            data: {
              restaurantUUID,
              locationUUID,
              schedules: getScheduleFromDaysOfTheWeek(formData.schedules),
            },
          },
        })

        return
      }

      await updateScheduleForLocation({
        variables: {
          data: {
            locationUUID,
            scheduleUUID: scheduleUUIDtoUpdate,
            schedules: getScheduleFromDaysOfTheWeek(formData.schedules),
          },
        },
      })
    },
    [
      createScheduleForLocation,
      locationUUID,
      restaurantUUID,
      updateScheduleForLocation,
    ]
  )

  const saveLocation = async (formData: IBusinessHoursForm) => {
    try {
      setLoading(true)

      await createOrUpdateLocationSchedule(formData, scheduleUUID)

      notification({
        description: intl.formatMessage({
          id: "restaurants.locations.overview.schedule.success.label",
          defaultMessage: "Hours of Operation Updated",
        }),
        type: "success",
      })
    } catch (error) {
      showGraphqlErrors(error)
      setLoading(false)
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <Spacer size={8} />
      <Text size="s">
        {intl.formatMessage({
          id: "restaurants.locations.hours.of.operation.standard.subtitle",
          defaultMessage: "Set up the hours of operation for your location.",
        })}
      </Text>
      <Spacer size={24} />
      <HoursOfOperationSkeleton loading={loading}>
        <HoursOfOperationForm
          saveLocation={saveLocation}
          schedules={schedules}
          loading={loading}
          displayEditActions={!!scheduleUUID}
        />
      </HoursOfOperationSkeleton>
    </>
  )
}
