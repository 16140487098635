import { showGraphqlErrors } from "../../../ui/ErrorList"

interface AttachmentModel {
  ext?: string
  fileName?: string
  signedUrl?: string
}

const getSingleImageFile = async (attachments: AttachmentModel) => {
  if (!(attachments.signedUrl && attachments.fileName && attachments.ext)) {
    throw new Error("Missing image fields")
  }
  const fileName = attachments.fileName
  const ext = attachments.ext

  return fetch(attachments.signedUrl)
    .then((res) => res.blob())
    .then((res) => {
      return new File([res], `${fileName}`, {
        type: `image/${ext}`,
      })
    })
}

export const getImageFile = async (
  attachments: AttachmentModel | AttachmentModel[]
) => {
  try {
    if (!Array.isArray(attachments)) {
      const response = await getSingleImageFile(attachments)

      return response
    }

    return Promise.all(
      attachments.map(async (attachment) => {
        const response = await getSingleImageFile(attachment)

        return response
      })
    )
  } catch (error) {
    showGraphqlErrors(error)
  }
}
