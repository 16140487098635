import * as Types from '../../../../../shared/graphql/generated/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateOrderThrottlingMutationVariables = Types.Exact<{
  data: Types.UpdateOrderThrottlingInput;
}>;


export type UpdateOrderThrottlingMutation = { __typename?: 'Mutation', updateOrderThrottling: { __typename?: 'OrderThrottling', uuid: string, defaultIntervalTime: number, defaultOrdersLimit: number, isActive: boolean, rules: Array<{ __typename?: 'OrderThrottlingRule', uuid: string, day: Types.WeekDaysEnum, startDate: any, endDate: any, endMin: number, ordersLimit: number, startMin: number, type: Types.OrderThrottlingTypeEnum }> } };


export const UpdateOrderThrottlingDocument = gql`
    mutation updateOrderThrottling($data: UpdateOrderThrottlingInput!) {
  updateOrderThrottling(data: $data) {
    uuid
    defaultIntervalTime
    defaultOrdersLimit
    isActive
    rules {
      uuid
      day
      startDate
      endDate
      endMin
      ordersLimit
      startMin
      type
    }
  }
}
    `;
export type UpdateOrderThrottlingMutationFn = Apollo.MutationFunction<UpdateOrderThrottlingMutation, UpdateOrderThrottlingMutationVariables>;

/**
 * __useUpdateOrderThrottlingMutation__
 *
 * To run a mutation, you first call `useUpdateOrderThrottlingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrderThrottlingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrderThrottlingMutation, { data, loading, error }] = useUpdateOrderThrottlingMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateOrderThrottlingMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOrderThrottlingMutation, UpdateOrderThrottlingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOrderThrottlingMutation, UpdateOrderThrottlingMutationVariables>(UpdateOrderThrottlingDocument, options);
      }
export type UpdateOrderThrottlingMutationHookResult = ReturnType<typeof useUpdateOrderThrottlingMutation>;
export type UpdateOrderThrottlingMutationResult = Apollo.MutationResult<UpdateOrderThrottlingMutation>;
export type UpdateOrderThrottlingMutationOptions = Apollo.BaseMutationOptions<UpdateOrderThrottlingMutation, UpdateOrderThrottlingMutationVariables>;