import { CardSkeleton } from "../../../../../ui/Card/Card.skeleton"
import { LabelFieldSkeleton } from "../../../../../ui/InputLabel"
import { InputFieldSkeleton } from "../../../../../ui/Inputs"
import Spacer from "../../../../../ui/Spacer"
import { LocationListSkeleton } from "../TaxForm/TaxLocationsForm/LocationList.skeleton"
import React from "react"

export const EditTaxSkeleton: React.FC = () => {
  return (
    <div>
      <InputFieldSkeleton size="default" marginBottom="16px" />
      <LabelFieldSkeleton />
      <InputFieldSkeleton width="400px" size="default" marginBottom="24px" />
      <LabelFieldSkeleton />
      <InputFieldSkeleton width="400px" size="default" marginBottom="24px" />
      <LabelFieldSkeleton />
      <InputFieldSkeleton width="400px" size="default" marginBottom="24px" />
      <Spacer size={64} />
      <InputFieldSkeleton size="default" marginBottom="8px" />
      <InputFieldSkeleton width="400px" marginBottom="16px" />
      <LocationListSkeleton />
      <Spacer size={64} />
      <InputFieldSkeleton width="100px" marginBottom="4px" />
      <InputFieldSkeleton width="400px" marginBottom="16px" />
      <CardSkeleton cards={2} />
      <Spacer size={64} />
    </div>
  )
}
