//TODO: remove eslint-disable when SearchItemsModal is going to be implemented again
import type { ItemCardModel } from "../../../../../components/ItemCard"
import paths from "../../../../../shared/routes/paths"
import Button from "../../../../../ui/Button"
import ConfirmationModal from "../../../../../ui/ConfirmationModal"
import Container from "../../../../../ui/Container"
import { showGraphqlErrors } from "../../../../../ui/ErrorList/ErrorList"
import ModalFull from "../../../../../ui/ModalFull"
import Spacer from "../../../../../ui/Spacer"
import Text from "../../../../../ui/Typography/Text"
import Title from "../../../../../ui/Typography/Title"
import notification from "../../../../../ui/notification"
import { SearchItemsModal } from "../../../../Restaurants/MenuItems/components/SearchItemsModal/SearchItemsModal"
// import SearchItemsModal from '../../../../Restaurants/Items/SearchItemsModal'
import { useCreateItemTaxLocationMutation } from "../CreateItemTax/GraphQL/createItemTaxLocation.generated"
import { useCreateLocationTaxMutation } from "../CreateItemTax/GraphQL/createLocationTax.generated"
import type {
  IItemTaxModel,
  ITaxItem,
} from "../CreateItemTax/hookforms.interfaces"
import type { ITaxModel } from "../CreateTax/hookforms.interfaces"
import { GetTaxesByRestaurantDocument } from "../GraphQL/getTaxesByRestaurant.generated"
import TaxComboItemForm from "../TaxForm/TaxComboItemForm"
import TaxInformationForm from "../TaxForm/TaxInformationForm"
import TaxLocationsForm from "../TaxForm/TaxLocationsForm"
import { EditTaxSkeleton } from "./EditTax.skeleton"
import { EditTaxResolver } from "./EditTax.yup"
import { useDeleteItemTaxesByLocationsMutation } from "./GraphQL/deleteItemTaxesByLocations.generated"
import { useDeleteLocationTaxMutation } from "./GraphQL/deleteLocationTax.generated"
import { useDeleteTaxMutation } from "./GraphQL/deleteTax.generated"
import { useGetOneTaxQuery } from "./GraphQL/getOneTax.generated"
import { useUpdateFutureLocationsInTaxMutation } from "./GraphQL/updateFutureLocationsInTax.generated"
import { useUpdateTaxMutation } from "./GraphQL/updateTax.generated"
import type { ITaxWithLocationOrItemModel } from "./graphql.interfaces"
import unionBy from "lodash/unionBy"
import uniq from "lodash/uniq"
import uniqBy from "lodash/uniqBy"
import React, { useEffect, useState } from "react"
import {
  FormProvider,
  useFieldArray,
  useForm,
  useFormContext,
} from "react-hook-form"
import { useIntl } from "react-intl"
import { useHistory, useParams } from "react-router-dom"

export const useEditTaxContext = () =>
  useFormContext<ITaxModel | IItemTaxModel>()

interface IlocationTaxParams {
  taxUUID: string
}

export const EditTax: React.FC = () => {
  const intl = useIntl()
  const { goBack } = useHistory()
  const { taxUUID } = useParams<IlocationTaxParams>()
  const { settings } = paths

  const [displayItemSearch, setDisplayItemSearch] = useState<
    boolean | undefined
  >()
  const [showDeleteConfirm, setShowDeleteConfirm] = useState<boolean>(false)

  const [itemsToDelete, setItemsToDelete] = useState<Array<ItemCardModel>>()
  const [itemsToAdd, setItemsToAdd] = useState<Array<ItemCardModel>>()

  const closeItemsModal = () => setDisplayItemSearch(false)

  const { data, loading: isFetchingOneTaxLoading } = useGetOneTaxQuery({
    variables: {
      uuid: taxUUID,
    },
    skip: !taxUUID,
    fetchPolicy: "cache-and-network",
  })

  const taxDetail = data?.getOneTax as ITaxWithLocationOrItemModel
  const taxName = data?.getOneTax.name

  const formMethods = useForm<IItemTaxModel>({
    mode: "all",
    resolver: EditTaxResolver,
    defaultValues: {
      isGeneral: false,
      applyAllLocations: false,
      applyFutureLocations: false,
    },
  })

  const { handleSubmit, reset, control, setValue } = formMethods

  const { fields: itemList, append: appendItem } = useFieldArray({
    control,
    name: "items",
  })

  const [updateTax, updateTaxState] = useUpdateTaxMutation({
    refetchQueries: [GetTaxesByRestaurantDocument],
  })

  const [deleteTax, { loading: onDeleteTaxIsLoading }] = useDeleteTaxMutation({
    refetchQueries: [GetTaxesByRestaurantDocument],
  })

  const [addItemsToTax] = useCreateItemTaxLocationMutation()
  const [removeTaxFromItem] = useDeleteItemTaxesByLocationsMutation()
  const [createLocationTax] = useCreateLocationTaxMutation()
  const [deleteLocationTax] = useDeleteLocationTaxMutation()
  const [updateApplyToFutureLocations] = useUpdateFutureLocationsInTaxMutation()

  const addLocationToTax = async (locationUUIDs: Array<string>) => {
    try {
      await createLocationTax({
        variables: {
          data: {
            taxUUID,
            locationUUIDs,
          },
        },
      })
    } catch (error) {
      showGraphqlErrors(error)
    }
  }

  const removeLocationFromTax = async (locationUUIDs: Array<string>) => {
    try {
      await deleteLocationTax({
        variables: {
          data: {
            taxUUID,
            locationUUIDs,
          },
        },
      })
    } catch (error) {
      showGraphqlErrors(error)
    }
  }

  const onApplyToFutureLocationsChange = async (
    applyFutureLocations: boolean
  ) => {
    try {
      await updateApplyToFutureLocations({
        variables: {
          data: {
            uuid: taxUUID,
            applyFutureLocations,
          },
        },
      })
    } catch (error) {
      showGraphqlErrors(error)
    }
  }

  const editItemTax = async ({ locations, items }: IItemTaxModel) => {
    try {
      const originLocation = taxDetail.itemTaxes.map(
        ({ location }) => location.uuid
      )

      const deleteLocations = async () => {
        const locationsToDelete = originLocation.filter((originUUID) => {
          return !locations.some((uuid) => {
            return originUUID == uuid
          })
        })

        if (locationsToDelete?.length && items?.length) {
          await Promise.all(
            items.map((item) => {
              if (!item.uuid) return

              removeTaxFromItem({
                variables: {
                  data: {
                    taxUUID,
                    locationUUIDs: locationsToDelete,
                    itemUUID: item.uuid,
                  },
                },
              })
            })
          )
        }
      }

      const linkItemsToTax = async () => {
        if (locations?.length && (itemsToAdd?.length || items.length > 0)) {
          try {
            await addItemsToTax({
              variables: {
                data: {
                  taxUUID,
                  locationUUIDs: locations,
                  itemUUIDs: unionBy(itemsToAdd, items, "uuid").map(
                    ({ uuid }) => uuid
                  ),
                },
              },
            })
          } catch (error) {
            showGraphqlErrors(error)
          }
        }
      }

      const unlinkTaxFromItem = async () => {
        if (locations?.length && itemsToDelete?.length) {
          await Promise.all(
            itemsToDelete.map((item) => {
              if (!item.uuid) return

              removeTaxFromItem({
                variables: {
                  data: {
                    taxUUID,
                    locationUUIDs: locations,
                    itemUUID: item.uuid,
                  },
                },
              })
            })
          )
        }
      }

      await deleteLocations()

      await linkItemsToTax()
      await unlinkTaxFromItem()

      notification({
        description: intl.formatMessage({
          id: "restaurants.taxes.item.form.edit.message",
          defaultMessage: "Your item tax was updated",
        }),
        type: "success",
      })
    } catch (error) {
      showGraphqlErrors(error)
    }
  }

  const editSalesTax = async ({ locations }: IItemTaxModel) => {
    try {
      const originLocation = taxDetail?.locationTaxes.map(
        ({ location }) => location.uuid
      )

      const deleteLocations = async () => {
        const locationsToDelete = originLocation.filter((originUUID) => {
          return !locations.some((uuid) => {
            return originUUID == uuid
          })
        })

        if (locationsToDelete.length > 0) {
          await removeLocationFromTax(locationsToDelete)
        }
      }

      const addLocations = async () => {
        const locationsToAdd = locations.filter((newUUID) => {
          return !originLocation.includes(newUUID)
        })

        if (locationsToAdd.length > 0) {
          await addLocationToTax(locationsToAdd)
        }
      }

      await deleteLocations()
      await addLocations()

      notification({
        type: "success",
        description: intl.formatMessage({
          id: "restaurants.taxes.sales.form.edit.message",
          defaultMessage: "Your sales tax were updated",
        }),
      })
    } catch (error) {
      showGraphqlErrors(error)
    }
  }

  const onEditTax = async (taxModel: IItemTaxModel) => {
    const {
      name,
      description,
      isGeneral,
      taxRate,
      applyFutureLocations,
      items,
    } = taxModel

    if (!isGeneral && !items?.length && !itemsToAdd?.length) {
      notification({
        type: "error",
        description: intl.formatMessage({
          id: "restaurants.taxes.form.section.3.description",
          defaultMessage: "Add items to apply this tax",
        }),
      })

      return
    }

    try {
      const updateTaxInformation = async () => {
        await updateTax({
          variables: {
            data: {
              uuid: taxUUID,
              name,
              description,
              taxRate,
            },
          },
        })
      }

      const updateFutureLocationsFlag = async () => {
        if (taxDetail.applyFutureLocations !== applyFutureLocations) {
          await onApplyToFutureLocationsChange(applyFutureLocations)
        }
      }

      await updateTaxInformation()
      await updateFutureLocationsFlag()

      await (isGeneral ? editSalesTax(taxModel) : editItemTax(taxModel))

      goBack()
    } catch (error) {
      showGraphqlErrors(error)
    }
  }

  const onItemsUpdated = (
    selectedItems: ItemCardModel[],
    removedItems?: ItemCardModel[]
  ) => {
    setValue("items", [])
    appendItem(selectedItems as Array<ITaxItem>)

    setItemsToAdd(selectedItems)
    setItemsToDelete(removedItems)

    setDisplayItemSearch(false)
  }

  const onDeleteTax = () => {
    setShowDeleteConfirm(true)
  }

  const onCancelDelete = () => {
    setShowDeleteConfirm(false)
  }

  const onDeletedTaxCallBack = () => {
    setShowDeleteConfirm(false)
  }

  const onConfirmDelete = async () => {
    try {
      await deleteTax({
        variables: {
          uuid: taxUUID,
        },
      })

      notification({
        description: intl.formatMessage({
          id: "restaurants.taxes.delete.succesful.message",
          defaultMessage: "Your tax was deleted",
        }),
        type: "success",
      })
      onDeletedTaxCallBack()
      goBack()
    } catch (error) {
      showGraphqlErrors(error)
    }
  }

  useEffect(() => {
    if (taxDetail) {
      const {
        name,
        taxRate,
        applyFutureLocations,
        description,
        itemTaxes,
        locationTaxes,
        isGeneral,
      } = taxDetail
      let locations: Array<string> = []
      let items: Array<ItemCardModel> = []

      if (isGeneral && locationTaxes.length > 0) {
        locations = uniq(locationTaxes.map(({ location }) => location.uuid))
      }

      if (!isGeneral && itemTaxes.length > 0) {
        items = uniqBy(
          itemTaxes.map(({ item }) => ({
            uuid: item.uuid,
            name: item.name,
            src: item.attachment?.signedUrl,
          })),
          "uuid"
        )
        locations = uniq(itemTaxes.map(({ location }) => location.uuid))
      }

      reset({
        name,
        taxRate,
        description,
        applyFutureLocations,
        locations,
        items,
        isGeneral,
      })
    }
  }, [taxDetail, reset])

  return (
    <FormProvider {...formMethods}>
      <ModalFull
        goBackPath={settings.restaurant.taxes}
        title={intl.formatMessage({
          id: "restaurants.taxes.form.edit.title",
          defaultMessage: "Edit Tax",
        })}
        visible
        closable
      >
        <form
          onSubmit={handleSubmit(onEditTax)}
          role="form"
          aria-label="edit-tax-form"
        >
          {isFetchingOneTaxLoading ? (
            <EditTaxSkeleton />
          ) : (
            <>
              <TaxInformationForm />
              <Spacer size={64} />
              <TaxLocationsForm />
              <Spacer size={64} />
              {!taxDetail?.isGeneral && (
                <div>
                  <TaxComboItemForm
                    setDisplayItemSearch={setDisplayItemSearch}
                    itemList={itemList}
                  />
                  <Spacer size={64} />
                </div>
              )}
              <Container display="flex" justifyContent="flex-start">
                <Button
                  title={intl.formatMessage({
                    id: "restaurants.taxes.sales.form.save.button",
                    defaultMessage: "Save Information",
                  })}
                  loading={updateTaxState.loading}
                  type="submit"
                  hierarchy="secondary"
                />
              </Container>
            </>
          )}
        </form>
        <Spacer size={64} />
        <Container>
          <Title size="xs" weight="bold">
            {intl.formatMessage({
              id: "restaurants.taxes.danger.zone.title",
              defaultMessage: "Danger Zone",
            })}
          </Title>
          <Spacer size={42} />
          <Container
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            gap="64px"
          >
            <Container>
              <Text size="l" weight="bold">
                {intl.formatMessage({
                  id: "restaurants.taxes.danger.zone.delete.title",
                  defaultMessage: "Delete this tax",
                })}
              </Text>
              <Text size="m">
                {intl.formatMessage({
                  id: "restaurants.taxes.danger.zone.delete.description",
                  defaultMessage:
                    "Once you delete a tax, there is no going back. Please be certain.",
                })}
              </Text>
            </Container>
            <Button
              role="delete-tax"
              title={intl.formatMessage({
                id: "restaurants.taxes.danger.zone.delete.button.title",
                defaultMessage: "Delete Tax",
              })}
              hierarchy="secondary"
              onClick={onDeleteTax}
              display="danger"
            />
            <ConfirmationModal
              visible={showDeleteConfirm}
              itemType={intl.formatMessage({
                id: "restaurants.taxes.danger.zone.form.delete.modal.title.type",
                defaultMessage: "Tax",
              })}
              itemName={intl.formatMessage(
                {
                  id: "restaurants.taxes.delete.confirmation.tax.name.message",
                  defaultMessage: "tax {taxName}",
                },
                {
                  taxName,
                }
              )}
              onConfirm={onConfirmDelete}
              onCancel={onCancelDelete}
              loading={onDeleteTaxIsLoading}
            />
          </Container>
        </Container>
      </ModalFull>
      {displayItemSearch && (
        <SearchItemsModal
          visible={displayItemSearch}
          onCancel={closeItemsModal}
          selectedItems={itemList}
          onSave={onItemsUpdated}
        />
      )}
    </FormProvider>
  )
}
