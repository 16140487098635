import type { InputLabelProps } from "../../InputLabel"
import InputLabel from "../../InputLabel"
import InputHelper from "../../Inputs/InputHelper"
import { Checkbox } from "../Checkbox"
import { Checkbox as AntdCheckbox } from "antd"
import type {
  CheckboxGroupProps as AntdCheckboxGroupProps,
  CheckboxChangeEvent,
  CheckboxOptionType,
} from "antd/lib/checkbox"
import classnames from "classnames"
import type { RefObject } from "react"
import React from "react"
import styled, { css } from "styled-components"

type StyledInnerWrapperProps = {
  flexFlow: "row" | "column"
}

type CheckboxOption = {
  id: string | number
  className?: string
  extra?: string | React.ReactNode
}

type CheckboxGroupProps = InputLabelProps &
  Omit<AntdCheckboxGroupProps, "options"> &
  StyledInnerWrapperProps & {
    options?: Array<
      CheckboxOptionType & CheckboxOption & { settingsIcon?: React.ReactNode }
    >
    onSingleOptionChange?: (event: CheckboxChangeEvent) => void
    helperText?: string
    hasError?: boolean
    classId: string
  }

// eslint-disable-next-line react/display-name
export const CheckboxGroup = React.forwardRef(
  (
    props: CheckboxGroupProps,
    ref:
      | ((instance: HTMLParagraphElement | null) => void)
      | RefObject<HTMLParagraphElement>
      | null
      | undefined
  ) => {
    const {
      options,
      disabled,
      helperText,
      hasError,
      classId,
      label,
      value,
      onChange,
      onSingleOptionChange,
      defaultValue,
      flexFlow = "row",
      ...labelProps
    } = props

    return (
      <StyledCheckBoxWrapper>
        {label && <InputLabel {...labelProps} label={label} />}

        <AntdCheckbox.Group
          onChange={onChange}
          value={value}
          defaultValue={defaultValue}
          ref={ref}
        >
          <StyledCheckboxInnerWrapper flexFlow={flexFlow}>
            {options &&
              options.map(
                ({ id, label: optionLabel, className, ...optionProps }) => (
                  <Checkbox
                    key={id}
                    {...optionProps}
                    content={optionLabel}
                    classId={classId}
                    disabled={disabled}
                    className={classnames(
                      className,
                      options.length > 1 && "checkbox-group-spaced"
                    )}
                    onChange={onSingleOptionChange}
                  />
                )
              )}
          </StyledCheckboxInnerWrapper>
        </AntdCheckbox.Group>
        {!!helperText && (
          <InputHelper $hasError={hasError}>{helperText}</InputHelper>
        )}
      </StyledCheckBoxWrapper>
    )
  }
)

const StyledCheckBoxWrapper = styled.div`
  position: relative;
  display: block;
`

const StyledCheckboxInnerWrapper = styled.div<StyledInnerWrapperProps>`
  ${({ flexFlow }) => css`
    flex-flow: ${flexFlow};
    display: flex;
    justify-content: left;
    flex-wrap: wrap;
    margin-left: 0;

    .checkbox-group-spaced {
      ${flexFlow === "column"
        ? css`
            margin-top: 4px;
            margin-bottom: 4px;
          `
        : css`
            margin-right: 8px;
          `}
    }

    .capitalized-option {
      text-transform: capitalize;
    }
  `}
`
