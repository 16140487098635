import { useDisableEmployeeMutation } from "../../GraphQL/disableEmployee.generated"
import { EmployeeType } from "../../types/employees.types"
import { useEmployeeCacheUpdate } from "../../utils/cache/employees-cache.util"
import { getFullName } from "../../utils/full-name.util"
import { Button, Flex, Modal, Text } from "@mantine/core"
import { useIntl } from "react-intl"

interface DeactivateEmployeeModalProps {
  deactivateEmployee: EmployeeType
  opened: boolean
  onClose: () => void
  isActiveFilter: boolean | undefined
}

export const DeactivateEmployeeModal = ({
  deactivateEmployee,
  opened,
  isActiveFilter: isActive,
  onClose,
}: DeactivateEmployeeModalProps) => {
  const intl = useIntl()

  const { disableEmployeeCacheUpdate } = useEmployeeCacheUpdate({ isActive })

  const [disableEmployee, { loading }] = useDisableEmployeeMutation()

  const handleDeactivateEmployee = async () => {
    try {
      await disableEmployee({
        variables: {
          input: {
            uuid: deactivateEmployee.uuid,
          },
        },
        onCompleted: (data) => {
          if (data) {
            onClose()
          }
        },
        update: (cache, result, options) =>
          disableEmployeeCacheUpdate(cache, result, options),
      })
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <Modal
      centered
      opened={opened}
      onClose={onClose}
      title={intl.formatMessage({
        id: "locations.employees.new.modal.deactivate.title",
        defaultMessage: "Deactivate Employee",
      })}
      styles={{
        header: {
          padding: "16px 16px 0px 16px",
        },
      }}
    >
      <Text py={8} size="sm" color="gray.7">
        {intl.formatMessage(
          {
            id: "locations.employees.new.modal.deactivate.description",
            defaultMessage:
              "You’re about to deactivate {employeeName}. The employee won’t be able to add new time entries until they are reactivated.",
          },
          {
            employeeName: <strong>{getFullName(deactivateEmployee)}</strong>,
          }
        )}
      </Text>
      <Flex justify="flex-end">
        <Button
          color="red.6"
          loading={loading}
          w={116}
          onClick={handleDeactivateEmployee}
        >
          {!loading &&
            intl.formatMessage({
              id: "locations.employees.new.modal.deactivate.button",
              defaultMessage: "Deactivate",
            })}
        </Button>
      </Flex>
    </Modal>
  )
}
