import * as Types from '../../../../../shared/graphql/generated/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RemoveRelationBankingAccountMutationVariables = Types.Exact<{ [key: string]: never; }>;


export type RemoveRelationBankingAccountMutation = { __typename?: 'Mutation', removeRelationBankingAccount: { __typename?: 'RBasicEntityData', uuid: string, name: string } };


export const RemoveRelationBankingAccountDocument = gql`
    mutation removeRelationBankingAccount {
  removeRelationBankingAccount {
    uuid
    name
  }
}
    `;
export type RemoveRelationBankingAccountMutationFn = Apollo.MutationFunction<RemoveRelationBankingAccountMutation, RemoveRelationBankingAccountMutationVariables>;

/**
 * __useRemoveRelationBankingAccountMutation__
 *
 * To run a mutation, you first call `useRemoveRelationBankingAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveRelationBankingAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeRelationBankingAccountMutation, { data, loading, error }] = useRemoveRelationBankingAccountMutation({
 *   variables: {
 *   },
 * });
 */
export function useRemoveRelationBankingAccountMutation(baseOptions?: Apollo.MutationHookOptions<RemoveRelationBankingAccountMutation, RemoveRelationBankingAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveRelationBankingAccountMutation, RemoveRelationBankingAccountMutationVariables>(RemoveRelationBankingAccountDocument, options);
      }
export type RemoveRelationBankingAccountMutationHookResult = ReturnType<typeof useRemoveRelationBankingAccountMutation>;
export type RemoveRelationBankingAccountMutationResult = Apollo.MutationResult<RemoveRelationBankingAccountMutation>;
export type RemoveRelationBankingAccountMutationOptions = Apollo.BaseMutationOptions<RemoveRelationBankingAccountMutation, RemoveRelationBankingAccountMutationVariables>;