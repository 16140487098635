import { colors } from "../../../../../styles/global/themes/mainTheme"
import Container from "../../../../../ui/Container"
import Text from "../../../../../ui/Typography/Text"
import { StripeAccountRequirements } from "../BankAccountRow/BankAccountRow"
import { isRequirementsArraysNonEmpty } from "../utils/check-empty-requirements"
import { Tooltip } from "@mantine/core"
import { IconAlertCircle } from "@tabler/icons-react"
import { Badge } from "antd"
import React from "react"
import { useIntl } from "react-intl"

type AccountStatusLabelProps = {
  isConnected: boolean
  requirements?: StripeAccountRequirements
  futureRequirements?: StripeAccountRequirements
}

export const AccountStatusLabel: React.FC<AccountStatusLabelProps> = ({
  isConnected,
  requirements,
  futureRequirements,
}) => {
  const intl = useIntl()

  const isRequirementsAnyArrayNonEmpty = isRequirementsArraysNonEmpty({
    requirements,
  })

  const isFutureRequirementsAnyArrayNonEmpty = isRequirementsArraysNonEmpty({
    futureRequirements,
  })

  return (
    <Container
      aria-label="account-status-label"
      display="flex"
      alignItems="center"
      flexDirection="row"
      gap="8px"
    >
      <Text size="s">
        <Badge color={isConnected ? colors.Success5 : colors.Warning5} />
        {intl.formatMessage(
          {
            id: "restaurants.bank.accounts.datatable.status",
            defaultMessage:
              "{isConnected, plural,=0 {Pending} other {Connected}}",
          },
          { isConnected: Number(isConnected) }
        )}
      </Text>
      {requirements &&
        isConnected &&
        (isRequirementsAnyArrayNonEmpty ||
          isFutureRequirementsAnyArrayNonEmpty) && (
          <Tooltip
            position="bottom"
            withArrow
            label={intl.formatMessage({
              id: "restaurants.bank.accounts.datatable.tooltip.label",
              defaultMessage:
                "This account has pending requirements. Click on the three dots to open the Complete KYC page.",
            })}
          >
            <IconAlertCircle size={14} color="orange" />
          </Tooltip>
        )}
    </Container>
  )
}
