import { ImageCoverFieldSkeleton } from "../../../../ui/CoverPreview"
import { ImageIconFieldSkeleton } from "../../../../ui/ImageIcon"
import { LabelFieldSkeleton } from "../../../../ui/InputLabel"
import { InputFieldSkeleton } from "../../../../ui/Inputs"
import DetailPage from "../../../../ui/Layouts/MainLayout/DetailPage"
import Spacer from "../../../../ui/Spacer"
import type { SkeletonProps } from "antd"
import React from "react"

export const WhiteLabelApplicationSkeleton: React.FC<SkeletonProps> = ({
  children,
  loading = true,
}) => {
  return loading ? (
    <DetailPage role="white-label-application-skeleton">
      <LabelFieldSkeleton />
      <InputFieldSkeleton />
      <Spacer size={88} />

      <ImageIconFieldSkeleton />
      <Spacer size={64} />

      <LabelFieldSkeleton />
      <ImageCoverFieldSkeleton />
      <Spacer size={64} />

      <LabelFieldSkeleton />
      <InputFieldSkeleton />
      <Spacer size={16} />

      <LabelFieldSkeleton />
      <InputFieldSkeleton />
      <Spacer size={64} />

      <LabelFieldSkeleton />
    </DetailPage>
  ) : (
    <>{children}</>
  )
}
