export enum TokenTypeEnum {
  BANK_ACCOUNT = "bank_account",
  DEBIT_CARD = "card",
}

export enum TokenCardHolderTypeEnum {
  INDIVIDUAL = "individual",
  COMPANY = "company",
}

export enum TokenCountryEnum {
  US = "US",
}

export enum TokenCurrencyEnum {
  USD = "usd",
}

export interface IAccountOrDebitCard extends IDebitCard {
  type: `${TokenTypeEnum}`
}

export interface IDebitCard extends IGenericStripeToken {
  routing_number: string
  account_number: string
  account_holder_name: string
  account_holder_type: `${TokenCardHolderTypeEnum}`
}

export interface IGenericStripeToken {
  country: `${TokenCountryEnum}`
  currency: `${TokenCurrencyEnum}`
}
