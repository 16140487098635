import style from "./Fieldset.module.css"
import { Container, Text } from "@mantine/core"

type FieldsetProps = {
  label: string
  children: React.ReactNode
}

export const Fieldset = ({ label, children }: FieldsetProps) => {
  return (
    <Container fluid p={24} className={style["container"]}>
      <Text size="sm" color="gray.6" className={style["label"]} lineClamp={1}>
        {label}
      </Text>
      {children}
    </Container>
  )
}
