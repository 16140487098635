import type { SkeletonProps } from "antd"
import { Skeleton } from "antd"
import React from "react"
import styled from "styled-components"

export const AccountFieldSkeleton: React.FC<SkeletonProps> = (props) => {
  return (
    <StyledSkeleton
      title={false}
      paragraph={{ rows: 1, width: "100%" }}
      className="connect-bank-skeleton"
      {...props}
    />
  )
}

export const AccountFooterSkeleton: React.FC<SkeletonProps> = (props) => {
  return (
    <StyledFooterSkeleton
      title={false}
      paragraph={{ rows: 2, width: ["100%", "20%"] }}
      className="connect-bank-skeleton"
      {...props}
    />
  )
}

const StyledSkeleton = styled(Skeleton)`
  &.connect-bank-skeleton {
    .ant-skeleton-content .ant-skeleton-paragraph {
      margin: 0;
    }

    .ant-skeleton-content .ant-skeleton-paragraph > li {
      height: 24px;
    }
  }
`

const StyledFooterSkeleton = styled(Skeleton)`
  &.connect-bank-skeleton {
    .ant-skeleton-content .ant-skeleton-paragraph {
      margin: 0;
    }

    .ant-skeleton-content .ant-skeleton-paragraph > li {
      height: 18px;
    }
  }
`
