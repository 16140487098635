import { useFullModalContext } from "../../shared/contexts/FullModalProvider"
import { usePortal } from "../../shared/hooks/usePortal"
import { useResize } from "../../shared/hooks/useResize"
import Button from "../Button"
import Container from "../Container"
import type { IModalProps } from "../Modal"
import { Modal as AntModal } from "antd"
import classnames from "classnames"
import type CSS from "csstype"
import React, { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import type { IBaseThemeColors } from "styled-components"
import styled, { css } from "styled-components"

type HeaderColor = Pick<IBaseThemeColors, "Neutral2" | "Neutral0">
export interface IModalFullProps extends IModalProps {
  goBackPath?: string | { pathname: string; search: string }
  borderBottomHeader?: boolean
  minWidth?: CSS.Property.MinWidth
  headerColor?: keyof HeaderColor
  notification?: React.ReactNode
}

export const ModalFull: React.FC<IModalFullProps> = ({
  title,
  afterClose,
  closable,
  goBackPath,
  wrapClassName,
  headerColor = "Neutral0",
  notification,
  ...props
}) => {
  const container = usePortal("modal-container")
  const siderOffset = 210
  const margin = 16

  const [width, setWidth] = useState(window.innerWidth - siderOffset - margin)

  const changeWidth = () => {
    setWidth(window.innerWidth - siderOffset - margin)
  }
  useResize(changeWidth)

  const { modalId, setModalId } = useFullModalContext()
  const { push, goBack } = useHistory()

  const onCancel = () => {
    if (closable) {
      afterClose?.()

      if (history.state === null) {
        push(goBackPath as string)
      } else {
        goBack()
      }
    }
  }

  useEffect(() => {
    if (modalId !== undefined && !!goBackPath) {
      push(goBackPath)
      setModalId("full-modal-id")
    }
  }, [goBackPath, modalId, push, setModalId])

  return (
    <StyledModalFull
      className="modal-full"
      wrapClassName={classnames("full-modal-wrap", {
        "modal-full-wrap": !!wrapClassName,
      })}
      footer={null}
      mask={false}
      maskClosable={false}
      getContainer={container}
      width={width}
      headerColor={headerColor}
      title={
        <Container>
          <StyledContainer
            background={headerColor}
            width="100%"
            padding="16px 32px 16px 24px"
            display="flex"
            justifyContent={title ? "space-between" : "flex-end"}
            alignItems="center"
          >
            {title}
            {closable ? (
              <Button
                onClick={props.onCancel ? props.onCancel : onCancel}
                hierarchy="tertiary"
                leadingIcon="ri-close-line"
                shape="square"
              />
            ) : null}
          </StyledContainer>
          {!!notification && <Container width="100%">{notification}</Container>}
        </Container>
      }
      closable={false}
      closeIcon={null}
      transitionName=""
      {...props}
    />
  )
}

const StyledContainer = styled(Container)<{ background: keyof HeaderColor }>`
  ${({ theme, background }) => css`
    background-color: ${theme.colors[background]};
  `}
`

const StyledModalFull = styled(AntModal)<IModalFullProps>`
  top: 0;
  max-width: unset;
  margin: 8px;
  padding: 0;
  border-radius: 10px;

  ${({ minWidth }) =>
    minWidth &&
    css`
      min-width: ${minWidth};
    `}

  .ant-modal-header {
    border-bottom: none;
    width: 100%;
    padding: 0;
    .ant-modal-title {
      color: ${({ theme }) => theme.colors["Neutral8"]};
    }

    ${({ theme, borderBottomHeader }) =>
      borderBottomHeader
        ? css`
            border-bottom: 1px solid ${theme.colors["Neutral4"]};
          `
        : css`
            border-bottom: none;
            width: 100%;
          `}
  }

  .ant-modal {
    max-width: unset;
    margin: unset;
  }

  .ant-modal-content {
    border-radius: 10px;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
    min-height: 98vh;
    min-width: 375px;
    max-width: 1516px;
    margin: 0 auto;
    overflow: auto;
  }

  .ant-modal-footer {
    border-top: none;
  }

  .ant-modal-body {
    margin: 0 150px;
    padding: 64px 24px;
    position: relative;
    min-width: 375px;
  }
`
