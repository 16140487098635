import type { TextWeightType } from "../../styles/global/types"
import { Menu } from "antd"
import type { MenuItemGroupProps } from "antd/lib/menu"
import classnames from "classnames"
import React from "react"
import styled, { css } from "styled-components"

type MenuSubTitleProps = MenuItemGroupProps & {
  weight?: TextWeightType
}

export const MenuSubTitle: React.FC<MenuSubTitleProps> = ({
  className,
  ...props
}) => {
  return (
    <StyledSubTitleText
      className={classnames(className, "menu-subtitle")}
      {...props}
    />
  )
}

const StyledSubTitleText = styled(Menu.ItemGroup)<MenuSubTitleProps>`
  ${({ theme, weight = "regular" }) =>
    css`
      &.menu-subtitle {
        &.ant-menu-item-group {
          color: ${theme.colors["Neutral6"]};

          .ant-menu-item-group-title {
            padding: 6px 8px;
            font-size: ${theme.text.body.s[`${weight}`].fontSize}px;
            font-weight: ${theme.text.body.s[`${weight}`].fontWeight};
            line-height: ${theme.text.body.s[`${weight}`].lineHeight}px;
            letter-spacing: ${theme.text.body.s[`${weight}`].letterSpacing}px;
            line-height: 20px;
          }
        }
      }
    `}
`
