// TODO: remove harcoded data when we have the query getPublishAppRequirements
import paths from "../../../../../shared/routes/paths"

export enum ERequirementTypes {
  REQUIRED,
  OPTIONAL,
}

export enum ERequirementStatus {
  COMPLETED,
  PENDING,
}

export const requirements = [
  {
    id: "restaurant",
    path: paths.settings.restaurant.restaurantInfo,
    title: "Restaurant Information completed",
    status: ERequirementStatus.COMPLETED,
    type: ERequirementTypes.REQUIRED,
  },
  {
    id: "items",
    path: paths.settings.restaurant.restaurantInfo,
    title: "Item published on a menu",
    status: ERequirementStatus.PENDING,
    type: ERequirementTypes.REQUIRED,
  },
  {
    id: "hours",
    path: paths.settings.locations.root,
    title: "Business hours setting per location",
    status: ERequirementStatus.COMPLETED,
    type: ERequirementTypes.REQUIRED,
  },
  {
    id: "banking",
    path: paths.settings.restaurant.banking,
    title: "Bank account connected to the restaurant and link to a location",
    status: ERequirementStatus.COMPLETED,
    type: ERequirementTypes.REQUIRED,
  },
  // Optionals
  {
    id: "taxes",
    path: paths.settings.restaurant.taxes,
    title: "Taxes settings",
    status: ERequirementStatus.PENDING,
    type: ERequirementTypes.OPTIONAL,
  },
  {
    id: "delivery",
    path: paths.settings.restaurant.delivery,
    title:
      "(If Restaurant has Delivery service type) Integrate OnFleet services",
    status: ERequirementStatus.PENDING,
    type: ERequirementTypes.OPTIONAL,
  },
]
