import {
  ExternalIntegrationEnum,
  ExternalSourceEnum,
} from "../../../../../../shared/graphql/generated/types"
import { useSearchParams } from "../../../../../../shared/hooks/useSearchParams"
import { INTEGRATION_ACTION_FILTER_NAME } from "../../../../../../shared/utils/constant/searchParams"
import { useGetSquareAuthUrlLazyQuery } from "../../GraphQL/getSquareAuthUrl.generated"
import { useSetupApiKeysMutation } from "../../GraphQL/setupApiKeys.generated"
import integrationsStyles from "../../Integrations.module.css"
import { connectLocationsFormId } from "../../SquareDetail/config"
import { SquareDetailForm } from "../../SquareDetail/hookform.interfaces"
import { integrationsFilterValues } from "../../config"
import {
  Button,
  Divider,
  PasswordInput,
  Stack,
  Text,
  Title,
  useMantineTheme,
} from "@mantine/core"
import { IconEye, IconEyeClosed } from "@tabler/icons-react"
import { ChangeEvent } from "react"
import { useFormContext, useWatch } from "react-hook-form"
import { useIntl } from "react-intl"

const ConnectSquareForm = () => {
  const { getParam } = useSearchParams()
  const {
    setValue,
    control,
    setError,
    clearErrors,
    formState: { errors },
  } = useFormContext<SquareDetailForm>()
  const [apiKey, token, credentialUUID, hasIntegration] = useWatch({
    control,
    name: ["apiKey", "token", "credentialUUID", "hasIntegration"],
  })

  const [setupApiKeys, { loading: tokenLoading }] = useSetupApiKeysMutation()
  const [getSquareAuthUrl, { loading: squareLoading }] =
    useGetSquareAuthUrlLazyQuery()

  const connectionLoading = tokenLoading || squareLoading

  const integrationQueryParam = getParam(
    INTEGRATION_ACTION_FILTER_NAME
  ) as ExternalIntegrationEnum

  const integrationType =
    integrationsFilterValues[ExternalIntegrationEnum.ORDER_AGGREGATION] ===
    integrationQueryParam
      ? ExternalIntegrationEnum.ORDER_AGGREGATION
      : ExternalIntegrationEnum.MENU

  const intl = useIntl()
  const { colors } = useMantineTheme()

  const handleApiKeyChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    !credentialUUID && setValue("apiKey", value, { shouldDirty: true })
    clearErrors(["apiKey", "token"])
  }

  const handleTokenChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    !credentialUUID && setValue("token", value, { shouldDirty: true })
    clearErrors(["apiKey", "token"])
  }
  const handleSubmit = () => {
    if (!token || !apiKey) {
      !apiKey && setError("apiKey", {})
      !token && setError("token", {})
      return
    }

    setupApiKeys({
      variables: {
        data: {
          source: ExternalSourceEnum.SQUARE,
          integrationAccessToken: token,
          integrationAppId: apiKey,
        },
      },
      onCompleted: (data) => {
        setValue("credentialUUID", data.setupApiKeys.uuid)
        const nextSection = document.querySelector(`#${connectLocationsFormId}`)
        nextSection?.scrollIntoView({ behavior: "smooth" })
      },
      onError: () => {
        setError("apiKey", {})
        setError("token", {})
      },
    })
  }

  const handleEditCredentialsClick = () => {
    setValue("credentialUUID", "")
    setValue("externalLocations", [])
  }

  const handleAuthWithSquare = async () => {
    const response = await getSquareAuthUrl({
      variables: { integrationType: integrationType },
    })

    const redirectionUrl = response.data?.getSquareAuthUrl

    if (redirectionUrl) {
      window.location.href = redirectionUrl
    }
  }

  return (
    <Stack
      className={integrationsStyles["bordered-container"]}
      spacing={16}
      justify="flex-start"
      align="flex-start"
    >
      <Title order={5} c="gray.9">
        {intl.formatMessage({
          defaultMessage: "Connect to Square",
          id: "restaurants.settings.locations.square.integration.connect.square.title",
        })}
      </Title>

      <PasswordInput
        classNames={{
          label: integrationsStyles.label,
          ...(credentialUUID && { innerInput: integrationsStyles.disabled }),
        }}
        w={400}
        label={intl.formatMessage({
          defaultMessage: "Api Key",
          id: "restaurants.settings.locations.square.integration.api.key.label",
        })}
        error={
          errors.apiKey &&
          intl.formatMessage({
            defaultMessage: "Invalid API Key",
            id: "restaurants.settings.locations.square.integration.api.key.error",
          })
        }
        placeholder={intl.formatMessage({
          defaultMessage: "Type your key",
          id: "restaurants.settings.locations.square.integration.key.placeholder",
        })}
        withAsterisk
        onChange={handleApiKeyChange}
        value={apiKey}
        defaultVisible
        visibilityToggleIcon={({ reveal }) =>
          reveal ? (
            <IconEye size={16} color={colors.gray[6]} />
          ) : (
            <IconEyeClosed size={16} color={colors.gray[6]} />
          )
        }
      />
      <PasswordInput
        classNames={{
          label: integrationsStyles.label,
          ...(credentialUUID && { innerInput: integrationsStyles.disabled }),
        }}
        w={400}
        error={
          errors.token &&
          intl.formatMessage({
            defaultMessage: "Invalid Token number",
            id: "restaurants.settings.locations.square.integration.token.error",
          })
        }
        label={intl.formatMessage({
          defaultMessage: "Token",
          id: "restaurants.settings.locations.square.integration.token.label",
        })}
        placeholder={intl.formatMessage({
          defaultMessage: "Type your key",
          id: "restaurants.settings.locations.square.integration.key.placeholder",
        })}
        withAsterisk
        onChange={handleTokenChange}
        value={token}
        defaultVisible
        visibilityToggleIcon={({ reveal }) =>
          reveal ? (
            <IconEye size={16} color={colors.gray[6]} />
          ) : (
            <IconEyeClosed size={16} color={colors.gray[6]} />
          )
        }
      />

      {credentialUUID && !hasIntegration ? (
        <Button
          variant="outline"
          type="submit"
          color="primary.6"
          size="sm"
          h={32}
          miw={155}
          p={0}
          onClick={handleEditCredentialsClick}
        >
          <Text size="sm" weight={400}>
            {intl.formatMessage({
              defaultMessage: "Edit credentials",
              id: "restaurants.settings.locations.square.integration.edit.button",
            })}
          </Text>
        </Button>
      ) : (
        <Button
          variant="outline"
          type="submit"
          color="primary.6"
          size="sm"
          h={32}
          miw={155}
          p={0}
          onClick={handleSubmit}
          loading={tokenLoading}
          disabled={
            !apiKey ||
            !token ||
            !!errors.apiKey ||
            !!errors.token ||
            hasIntegration ||
            connectionLoading
          }
        >
          {!tokenLoading && (
            <Text size="sm" weight={400}>
              {intl.formatMessage({
                defaultMessage: "Validate credentials",
                id: "restaurants.settings.locations.square.integration.validate.button",
              })}
            </Text>
          )}
        </Button>
      )}
      <Divider
        labelPosition="center"
        label={intl.formatMessage({
          id: "restaurants.settings.locations.square.integration.or.divider",
          defaultMessage: "Or",
        })}
        w={400}
      />
      <Button
        variant="outline"
        w={400}
        onClick={handleAuthWithSquare}
        loading={squareLoading}
        disabled={!!credentialUUID || hasIntegration || connectionLoading}
      >
        <Text weight={400}>
          {intl.formatMessage({
            id: "restaurants.settings.locations.square.integration.square.auth.button",
            defaultMessage: "Authorize with Square",
          })}
        </Text>
      </Button>
    </Stack>
  )
}

export default ConnectSquareForm
