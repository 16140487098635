import type {
  Maybe,
  ScheduleDetail,
  WeekDaysEnum,
} from "../../../../../../shared/graphql/generated/types"
import type { ScheduleInput } from "../../../../../../shared/types/schedule.types"
import { HH_mm } from "../../../../../../shared/utils/constant/dateFormats"
import { getDateDifference } from "../../../../../../shared/utils/helpers/getDateDifference"
import safeNumber from "../../../../../../shared/utils/helpers/safeNumber"
import {
  ALL_DAY_MINUTES_MIN,
  DAY_END_HOUR,
  DAY_START_HOUR,
} from "../../../../../../ui/CustomTimePicker/constants"
import type {
  DAYS_OF_THE_WEEK_ENUM,
  IBussinessHourRangeType,
  IBussinessHourType,
} from "../../hookforms.interfaces"
import moment from "moment-timezone"

const daysOfTheWeek = [1, 2, 3, 4, 5, 6, 7]

const reduceScheduleToDaysOfTheWeek = (
  schedules: Array<ScheduleInput>
): Array<IBussinessHourRangeType> => {
  const reducedDate = schedules?.map((schedule) => {
    const dateStart = new Date(schedule.startDate)
    const dateEnd = new Date(schedule.endDate)
    let isAllDay = false

    const dateDifference = getDateDifference(
      schedule.startDate,
      schedule.endDate
    )

    if (dateDifference >= ALL_DAY_MINUTES_MIN) isAllDay = true

    return {
      day: schedule.day as `${DAYS_OF_THE_WEEK_ENUM}`,
      startDate: isAllDay ? DAY_START_HOUR : moment(dateStart).format(HH_mm),
      endDate: isAllDay ? DAY_END_HOUR : moment(dateEnd).format(HH_mm),
    }
  })

  const filterByDay = (
    scheduleArray:
      | {
          day: `${DAYS_OF_THE_WEEK_ENUM}`
          startDate: string
          endDate: string
        }[]
      | undefined,
    day: number
  ) => {
    const dayName = moment().isoWeekday(day).format("dddd")

    return {
      day: dayName.toUpperCase() as `${DAYS_OF_THE_WEEK_ENUM}`,
      defaultValues: scheduleArray?.filter(
        (schedule) => schedule.day.toUpperCase() === dayName.toUpperCase()
      ),
    }
  }

  const weekDays = daysOfTheWeek.map((weekDay) =>
    filterByDay(reducedDate, weekDay)
  )

  return weekDays
}

export const getScheduleFromDaysOfTheWeek = (
  ranges: Array<IBussinessHourRangeType>
): Array<ScheduleDetail> => {
  try {
    const today = moment()

    const schedule = (
      ranges.flatMap(({ defaultValues, day }) =>
        defaultValues
          ?.map((data) => ({ ...data, day }))
          .filter(
            ({ startDate, endDate, day: filterDay }) =>
              !!startDate && !!endDate && !!filterDay
          )
      ) as Array<IBussinessHourType>
    ).map((data) => {
      const formatStartDate = moment(data.startDate, HH_mm)
      const formatEndDate = moment(data.endDate, HH_mm)

      const startDate = today
        .isoWeekday(data.day)
        .set("hour", safeNumber(formatStartDate.hour()))
        .set("minute", safeNumber(formatStartDate.minutes()))
        .toISOString(true)

      const endDate = today
        .isoWeekday(data.day)
        .set("hour", safeNumber(formatEndDate.hour()))
        .set("minute", safeNumber(formatEndDate.minutes()))
        .toISOString(true)

      return { day: data.day as Maybe<WeekDaysEnum>, startDate, endDate }
    })

    return schedule
  } catch {
    return []
  }
}

export default reduceScheduleToDaysOfTheWeek
