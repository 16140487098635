import { checkIfRealAddress } from "../../shared/utils/helpers/checkAddress"
import yup from "../../shared/yup"

export const autocompleteAddressValidation = {
  address: yup
    .string()
    .required()
    .test(
      "correctAddressFormat",
      "Please enter a complete address that includes the street or postal code",
      (_, fields) => {
        const { addressComponents, latitude, longitude } = fields.parent

        const valid = addressComponents
          ? checkIfRealAddress([...addressComponents])
          : !!latitude && !!longitude

        return !!valid
      }
    ),
  addressComponents: yup.array(
    yup.object().shape({
      type: yup.string(),
    })
  ),
}
