import type { TextAlignType } from "../../../styles/global/types"
import classnames from "classnames"
import type CSS from "csstype"
import React from "react"
import type { RefObject } from "react"
import type {
  IBaseThemeColors,
  IBaseThemeFontProps,
  IBaseThemeFontWeighProps,
  IBody,
} from "styled-components"
import styled, { css, useTheme } from "styled-components"

export type TextColor = keyof Pick<
  IBaseThemeColors,
  | "Neutral9"
  | "Neutral8"
  | "Neutral7"
  | "Neutral6"
  | "Neutral5"
  | "Neutral4"
  | "Neutral1"
  | "Neutral0"
  | "Primary5"
  | "Primary2"
  | "Danger4"
  | "Success5"
  | "Warning5"
  | "Danger5"
>

type SpanProps = Omit<React.ComponentProps<"span">, "ref">

export interface TypographyTextProps extends SpanProps {
  weight?: keyof IBaseThemeFontWeighProps
  textAlign?: TextAlignType
  whiteSpace?: React.CSSProperties["whiteSpace"]
  textTransform?: CSS.Property.TextTransform
  color?: TextColor
  children: React.ReactNode
  ellipsis?: boolean
}

export const Text = React.forwardRef(
  (
    props: TypographyTextProps & { size?: keyof IBody },
    ref:
      | ((instance: HTMLParagraphElement | null) => void)
      | RefObject<HTMLParagraphElement>
      | null
      | undefined
  ) => {
    const theme = useTheme()

    const {
      whiteSpace,
      textAlign = "left",
      textTransform,
      weight = "regular",
      size = "m",
      color = "Neutral9",
      ellipsis = false,
      className,
      ...rest
    } = props
    const style = theme.text.body[size][weight]

    return (
      <StyledContainer
        ref={ref}
        className={classnames({ ellipsis: !!ellipsis })}
        color={color}
        $textAlign={textAlign}
      >
        <StyledText
          $whiteSpace={whiteSpace}
          $textTransform={textTransform}
          color={color}
          className={classnames(className, "text-typography")}
          {...style}
          {...rest}
        />
      </StyledContainer>
    )
  }
)

const StyledContainer = styled.div<{
  $textAlign?: TextAlignType
  color?: TextColor
}>`
  ${({ $textAlign }) =>
    !!$textAlign &&
    css`
      text-align: ${$textAlign};
    `}

  ${({ theme, color = "Neutral9" }) => css`
    color: ${theme.colors[`${color}`]};
  `}
`

type StyledTextProps = IBaseThemeFontProps & {
  color?: TextColor
  $whiteSpace?: React.CSSProperties["whiteSpace"]
  $textTransform?: CSS.Property.TextTransform
}

export const StyledText = styled.span<StyledTextProps>`
  ${(props) => css`
    font-size: ${props.fontSize}px;
    font-family: ${props.fontFamily};
    font-weight: ${props.fontWeight};
    letter-spacing: ${props.letterSpacing}px;
    line-height: ${props.lineHeight}px;
  `}

  ${({ theme, color = "Neutral9" }) => css`
    color: ${theme.colors[`${color}`]};
  `}

  ${({ $whiteSpace }) =>
    !!$whiteSpace &&
    css`
      white-space: ${$whiteSpace};
    `}

    ${({ $textTransform }) =>
    !!$textTransform &&
    css`
      text-transform: ${$textTransform};
    `}
`
