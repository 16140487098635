export const eventCodeKey = /^key/gi

export const eventCodeArrow = /^arrow/gi

export const eventKeyOnlyInteger = /^[\d\b]+$/gi

export const eventKeyOnlyLetters = /^[\sA-z]+$/

export const leadingAndTrailingSpaces = /(^\s*)|(\s*$)/gi

export const multipleSpacesToOneSpace = / {2,}/gi
