import { REQUIRED_FIELD_ERROR_ID } from "../pages/Restaurants/Discounts/forms/GenericForm/validations.title"
import {
  END_DATE_FIELD_NAME,
  END_TIME_FIELD_NAME,
} from "../pages/Restaurants/Discounts/forms/Rules/constants"
import { DateAndTimeRule } from "../pages/Restaurants/Discounts/interfaces/dateRule.interfaces"
import { generateTimeFromForm } from "../pages/Restaurants/Discounts/utils/generateTimeFromForm"
import { DAYS_OF_THE_WEEK_ENUM } from "../pages/Settings/Locations/HoursOfOperation/hookforms.interfaces"
import { isPhoneNumber } from "./utils/helpers/parsePhoneNumber"
import { cloneDeep } from "lodash"
import * as yup from "yup"

yup.setLocale({
  mixed: {
    required: (data: { label: string }) => {
      if (data?.label) {
        return `${data.label} is a required value`
      }

      return "Required value"
    },
    notType: (data) => {
      const type = data.type

      if (type) {
        return `This field must be ${type}`
      }

      return "Invalid type"
    },
  },
  number: {
    positive: (data) => {
      return `${data.label ?? " This field"} must be positive number`
    },
    moreThan: ({ more }: { more: number }) => `Should be greater than ${more}`,
    min: ({ min }: { min: number }) => `Min value is ${min}`,
    max: ({ max }: { max: number }) => `Max value is ${max}`,
  },
  string: {
    matches: (data) => {
      return `Invalid format for ${data.label ?? " this field"}`
    },
    email: () => {
      return "Invalid email format"
    },
  },
  array: {
    min: ({ min, label }: { min: number; path: string; label: string }) => {
      return `Select ${min} or more ${label}`
    },
  },
})

yup.addMethod(yup.string, "integer", function () {
  return this.matches(/^\d+$/, "The field should have digits only")
})

yup.addMethod(yup.string, "dayOfTheWeek", function () {
  return this.oneOf(Object.keys(DAYS_OF_THE_WEEK_ENUM))
})

yup.addMethod(yup.object, "timeRanges", function () {
  return this.test("overlapping-time-ranges", (value, fields) => {
    const errors: yup.ValidationError[] = []
    const this_time_range_is_overlaped = "This time range is overlapped"

    try {
      const data: Array<{ start: number; end: number }> = fields.parent
        ?.filter(
          ({ startDate, endDate }: { startDate: string; endDate: string }) =>
            !!startDate && !!endDate
        )
        .map(
          ({ startDate, endDate }: { startDate: string; endDate: string }) => {
            const [startH, startM] = startDate?.split(":")
            const [endH, endM] = endDate?.split(":")

            const startTimeInMinutes = Number(startH) * 60 + Number(startM)
            const endTimeInMinutes = Number(endH) * 60 + Number(endM)

            return { start: startTimeInMinutes, end: endTimeInMinutes }
          }
        )

      const originalData = cloneDeep(data)

      data.forEach(({ end }) => {
        const overlap = originalData.filter(
          (og) => og.start < end && og.end >= end
        )

        if (overlap.length > 1) {
          errors.push(
            new yup.ValidationError(
              this_time_range_is_overlaped,
              value,
              fields.path
            )
          )
        }
      })
    } catch {}

    if (errors.length > 0) {
      return new yup.ValidationError(errors)
    }

    return true
  })
})

yup.addMethod(yup.string, "timeRangeValue", function () {
  return this.test("invalid-time-range", (value, fields) => {
    const errors: yup.ValidationError[] = []

    const select_a_later_time_for_end_time =
      "Select an end time later than start time"
    const select_a_different_start_time = "Select a different end time"
    const required_value = "Required value"

    try {
      const { startDate, endDate } = fields.parent

      if (!startDate && endDate) {
        errors.push(new yup.ValidationError(required_value, value, fields.path))
      }

      if (startDate && !endDate) {
        errors.push(new yup.ValidationError(required_value, value, fields.path))
      }

      if (fields?.parent?.startDate && fields?.parent?.endDate) {
        const [startH, startM] = fields.parent.startDate?.split(":")
        const [endH, endM] = fields.parent.endDate?.split(":")

        const startTimeInMinutes = Number(startH) * 60 + Number(startM)
        const endTimeInMinutes = Number(endH) * 60 + Number(endM)

        if (endTimeInMinutes < startTimeInMinutes) {
          errors.push(
            new yup.ValidationError(
              select_a_later_time_for_end_time,
              value,
              fields.path
            )
          )
        }

        if (endTimeInMinutes === startTimeInMinutes) {
          errors.push(
            new yup.ValidationError(
              select_a_different_start_time,
              value,
              fields.path
            )
          )
        }
      }
    } catch {}

    if (errors.length > 0) {
      return new yup.ValidationError(errors)
    }

    return true
  })
})

yup.addMethod(yup.boolean, "validateMenuForm", function () {
  return this.test("invalid-time-range", (value, fields) => {
    const errors: yup.ValidationError[] = []

    const untitled_menu = "Untitled Category"
    const name_it = "Name it"
    // const at_least_add_one_item_or_combo = 'At least add one item or combo'

    if (fields.parent.name === untitled_menu) {
      errors.push(new yup.ValidationError(name_it, value, "name"))
    }

    if (
      !!fields.parent?.isReadyToPublish &&
      !fields.parent.combos?.length &&
      !fields.parent.items?.length
    ) {
      //TODO: uncomment this when api is ready
      // errors.push(new yup.ValidationError(at_least_add_one_item_or_combo, value, 'errorMessage'))
    }

    if (errors.length > 0) {
      return new yup.ValidationError(errors)
    }

    return true
  })
})

yup.addMethod(yup.string, "timeRange", function () {
  return this.test("invalid-time-range", (value, fields) => {
    const errors: yup.ValidationError[] = []

    const select_a_later_time_for_end_time =
      "Select an end time later than start time"
    const select_a_different_start_time = "Select a different end time"
    const required_value = "Required value"

    try {
      const { startTime, endTime } = fields.parent

      if (!startTime) {
        errors.push(new yup.ValidationError(required_value, value, fields.path))
      }

      if (!endTime) {
        errors.push(new yup.ValidationError(required_value, value, fields.path))
      }

      if (fields?.parent?.startTime && fields?.parent?.endTime) {
        const startingTime = new Date(fields.parent.startTime)
        const endingTime = new Date(fields.parent.endTime)

        if (endingTime < startingTime) {
          errors.push(
            new yup.ValidationError(
              select_a_later_time_for_end_time,
              value,
              fields.path
            )
          )
        }

        if (endingTime.getTime() === startingTime.getTime()) {
          errors.push(
            new yup.ValidationError(
              select_a_different_start_time,
              value,
              fields.path
            )
          )
        }
      }
    } catch {}

    if (errors.length > 0) {
      return new yup.ValidationError(errors)
    }

    return true
  })
})

yup.addMethod(yup.string, "validatePhoneNumber", function (message: string) {
  return this.test("validate-phone-number", message, (value) => {
    return isPhoneNumber(value ?? "", "US")
  })
})

yup.addMethod(yup.object, "dateAndTimeRange", function () {
  return this.test("datesRangeValid", (value, fields) => {
    const { startDate, endDate, addEndTime } = fields.parent as DateAndTimeRule

    const errors: yup.ValidationError[] = []

    const isStartDateValid =
      !!startDate?.date &&
      !!startDate?.hour?.meridium &&
      !!startDate?.hour?.time

    if (isStartDateValid && !addEndTime) return true

    if (!endDate?.hour.time) {
      errors.push(
        new yup.ValidationError(
          REQUIRED_FIELD_ERROR_ID,
          value,
          END_TIME_FIELD_NAME
        )
      )
    }

    if (!endDate?.date) {
      errors.push(
        new yup.ValidationError(
          REQUIRED_FIELD_ERROR_ID,
          value,
          END_DATE_FIELD_NAME
        )
      )
    }

    if (errors.length > 0) {
      return new yup.ValidationError(errors)
    }

    const startDateTime = generateTimeFromForm(startDate)
    const endDateTime =
      endDate?.date &&
      generateTimeFromForm({
        date: endDate?.date,
        hour: endDate?.hour,
      })

    if (startDateTime?.isSameOrAfter(endDateTime)) {
      return new yup.ValidationError(
        "restaurants.discounts.forms.generic.rules.select.later.end.date.message",
        value,
        fields.path
      )
    }

    return true
  })
})

export default yup
