import * as Types from '../../../../../shared/graphql/generated/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type OrderThrottlingQueryVariables = Types.Exact<{
  locationUUID: Types.Scalars['ID'];
}>;


export type OrderThrottlingQuery = { __typename?: 'Query', orderThrottling?: { __typename?: 'OrderThrottling', uuid: string, defaultIntervalTime: number, defaultOrdersLimit: number, isActive: boolean, rules: Array<{ __typename?: 'OrderThrottlingRule', uuid: string, day: Types.WeekDaysEnum, startDate: any, endDate: any, endMin: number, ordersLimit: number, startMin: number, type: Types.OrderThrottlingTypeEnum }> } | null };


export const OrderThrottlingDocument = gql`
    query orderThrottling($locationUUID: ID!) {
  orderThrottling(location: {uuid: $locationUUID}) {
    uuid
    defaultIntervalTime
    defaultOrdersLimit
    isActive
    rules {
      uuid
      day
      startDate
      endDate
      endMin
      ordersLimit
      startMin
      type
    }
  }
}
    `;

/**
 * __useOrderThrottlingQuery__
 *
 * To run a query within a React component, call `useOrderThrottlingQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderThrottlingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderThrottlingQuery({
 *   variables: {
 *      locationUUID: // value for 'locationUUID'
 *   },
 * });
 */
export function useOrderThrottlingQuery(baseOptions: Apollo.QueryHookOptions<OrderThrottlingQuery, OrderThrottlingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrderThrottlingQuery, OrderThrottlingQueryVariables>(OrderThrottlingDocument, options);
      }
export function useOrderThrottlingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrderThrottlingQuery, OrderThrottlingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrderThrottlingQuery, OrderThrottlingQueryVariables>(OrderThrottlingDocument, options);
        }
export type OrderThrottlingQueryHookResult = ReturnType<typeof useOrderThrottlingQuery>;
export type OrderThrottlingLazyQueryHookResult = ReturnType<typeof useOrderThrottlingLazyQuery>;
export type OrderThrottlingQueryResult = Apollo.QueryResult<OrderThrottlingQuery, OrderThrottlingQueryVariables>;