import { Skeleton } from "antd"
import React from "react"
import styled, { css } from "styled-components"

interface InputFieldSkeletonProps {
  size?: "default" | "small" | "large" | undefined
  width?: string
  marginBottom?: string
}

export const InputFieldSkeleton: React.FC<InputFieldSkeletonProps> = ({
  width,
  marginBottom,
  size = "small",
}) => {
  return (
    <StyledInputFieldSkeleton
      width={width}
      $marginBottom={marginBottom}
      className="input-field-skeleton"
    >
      <Skeleton.Input active size={size} />
    </StyledInputFieldSkeleton>
  )
}

interface StyledInputFieldSkeletonProps {
  width?: string
  $marginBottom?: string
}

const StyledInputFieldSkeleton = styled.div<StyledInputFieldSkeletonProps>`
  &.input-field-skeleton {
    ${({ $marginBottom }) =>
      $marginBottom &&
      css`
        margin-bottom: ${$marginBottom};
      `}

    .ant-skeleton-element {
      width: ${({ width }) => width};
    }

    .ant-skeleton-input {
      ${({ width }) =>
        width
          ? css`
              width: ${width};
            `
          : css`
              width: 250px;
            `}
    }
  }
`
