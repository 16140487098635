import Container from "../Container"
import Divider from "../Divider"
import Text from "../Typography/Text"
import Title from "../Typography/Title"
import React from "react"
import styled, { css } from "styled-components"

interface IPageTitle {
  title?: string
  description?: string | React.ReactNode
  actionButton?: React.ReactNode
  margin?: boolean
  border?: boolean
}

export const PageTitle: React.FC<IPageTitle> = ({
  title,
  description,
  actionButton,
  margin,
  border,
}) => {
  return (
    <StyledSection $margin={margin}>
      <Container
        className="title"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        flexDirection="row"
        gap="24px"
      >
        <Title size="xs" weight="bold">
          {title}
        </Title>

        {actionButton}
      </Container>
      <Text size="s">{description}</Text>
      {border && <Divider verticalMargin="32px" />}
    </StyledSection>
  )
}

const StyledSection = styled.section<{ $margin: boolean | undefined }>`
  width: 100%;
  margin-bottom: 16px;

  .title {
    ${({ $margin }) => css`
      margin-bottom: ${$margin ? "8px" : "0px"};
    `}
  }
`
