import type { DeviceFunctionEnum } from "../../../../../shared/graphql/generated/types"
import { validationsTitle } from "../../../../../shared/titles/validations.title"
import { EMPTY_STRING } from "../../../../../shared/utils/constant/values"
import formatPhoneNumber from "../../../../../shared/utils/helpers/formatPhoneNumber"
import Input from "../../../../../ui/Inputs"
import Select from "../../../../../ui/Select"
import Spacer from "../../../../../ui/Spacer"
import { CustomDeviceFunctionTitle } from "../titles/deviceFunction.title"
import { DEVICE_CODE_LENGTH } from "./DeviceForm.constants"
import type { IDeviceForm } from "./hookforms.interfaces"
import React, { useEffect, useState } from "react"
import type { ChangeEvent } from "react"
import { Controller, useFormContext } from "react-hook-form"
import { useIntl } from "react-intl"
import styled from "styled-components"

type DeviceFormProps = Omit<React.ComponentProps<"form">, "ref"> & {
  locationPhone?: string
  code?: string
}

export const DeviceForm: React.FC<DeviceFormProps> = ({
  locationPhone,
  code,
  ...props
}) => {
  const intl = useIntl()

  const functionOptions = (
    Object.keys(CustomDeviceFunctionTitle) as Array<`${DeviceFunctionEnum}`>
  ).map((deviceFunction) => {
    return {
      label: intl.formatMessage(CustomDeviceFunctionTitle[deviceFunction]),
      value: deviceFunction,
    }
  })

  const {
    control,
    formState: { errors },
  } = useFormContext<IDeviceForm>()

  const [codeToString, setCodeToString] = useState(String(code))
  const [codeLength, setCodeLength] = useState(0)

  useEffect(() => {
    setCodeLength(codeToString.length)
  }, [codeToString])

  return (
    <StyledDeviceForm role="form" aria-label="device-form" {...props}>
      <Controller
        control={control}
        name="functionType"
        render={({ field: { onChange, value } }) => (
          <Select
            onChange={onChange}
            value={value}
            requirement="required"
            label={intl.formatMessage({
              id: "restaurants.devices.add.device.function.label",
              defaultMessage: "Function",
            })}
            aria-label="function-type"
            options={functionOptions}
            hasError={!!errors?.functionType}
            helperText={errors?.functionType?.message}
          />
        )}
      />

      <Spacer size={24} />
      <Controller
        control={control}
        name="deviceKey"
        render={({ field: deviceField }) => (
          <Input
            {...deviceField}
            label={intl.formatMessage({
              id: "restaurants.devices.add.device.form.device.id.label",
              defaultMessage: "Device Name",
            })}
            aria-label="device-key"
            maxLength={40}
            hasError={!!errors?.deviceKey}
            helperText={errors?.deviceKey?.message}
            requirement="required"
          />
        )}
      />

      <Spacer size={24} />
      <Controller
        control={control}
        name="deviceCode"
        rules={{ maxLength: DEVICE_CODE_LENGTH }}
        render={({ field: deviceField }) => (
          <Input
            {...deviceField}
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              event.target.value = event.target.value.toLocaleUpperCase()
              deviceField.onChange(event)
            }}
            label={intl.formatMessage({
              id: "restaurants.devices.add.device.form.device.code.label",
              defaultMessage: "Device code",
            })}
            aria-label="device-code"
            maxLength={DEVICE_CODE_LENGTH}
            hasError={!!errors?.deviceCode}
            helperText={
              errors?.deviceCode?.message
                ? intl.formatMessage(
                    validationsTitle[errors.deviceCode.message],
                    {
                      length: DEVICE_CODE_LENGTH,
                    }
                  )
                : EMPTY_STRING
            }
            requirement="required"
            textLength={codeLength}
            defaultValue={code}
            onInput={(e: ChangeEvent<HTMLInputElement>) => {
              if (e.target.value.length > e.target.maxLength) {
                e.target.value = e.target.value.slice(0, e.target.maxLength)
              }
              setCodeToString(e.target.value)
            }}
            showCount
          />
        )}
      />

      <Spacer size={24} />
      <Controller
        control={control}
        name="locationPhone"
        render={({ field: deviceField }) => (
          <Input
            {...deviceField}
            label={intl.formatMessage({
              id: "restaurants.devices.add.device.form.device.phone.label",
              defaultMessage: "Location phone number",
            })}
            aria-label="location-phone"
            maxLength={40}
            hasError={!!errors?.locationPhone}
            helperText={
              errors?.locationPhone?.message
                ? intl.formatMessage(
                    validationsTitle[errors.locationPhone.message]
                  )
                : EMPTY_STRING
            }
            requirement="required"
            value={
              locationPhone
                ? formatPhoneNumber(locationPhone)
                : formatPhoneNumber(deviceField.value)
            }
            disabled={locationPhone ? true : false}
          />
        )}
      />
      <button type="submit" className="hidden" />
    </StyledDeviceForm>
  )
}

const StyledDeviceForm = styled.form`
  margin: 24px 0px;
`
