import { TokenCountryEnum } from "../AccountDebitCardForm/hookforms.interfaces"

const countryOptions = (
  Object.keys(TokenCountryEnum) as Array<`${TokenCountryEnum}`>
).map((label) => {
  return {
    value: TokenCountryEnum[label],
    label,
  }
})

export default countryOptions
