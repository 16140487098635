import { AdminRoleNameEnum } from "../../../../../shared/graphql/generated/types"
import Container from "../../../../../ui/Container"
import Text from "../../../../../ui/Typography/Text"
import DropdownMember from "../DropdownMember"
import MemberStatusLabel from "../MemberStatusLabel"
import type { IMembersTableModel } from "../interfaces/membersTable.interfaces"
import { CustomAdminRoleNameEnum } from "../member.enum"
import { FormattedMessage } from "react-intl"

const tableColumns = <T extends IMembersTableModel>() => {
  const columns = [
    {
      dataIndex: "name",
      title: (
        <FormattedMessage
          id="settings.restaurant.members.datatable.header.name"
          defaultMessage="Name"
        />
      ),
      width: "24%",
      render: (_: string, record: T) => {
        const name = `${record.firstName || "-"} ${record.lastName || ""}`

        return (
          <Container display="flex" flexDirection="column">
            <Text size="s" title={name} className="ellipsis">
              {name}
            </Text>
            <Text
              size="s"
              title={record.email}
              color="Neutral5"
              className="ellipsis"
            >
              {record.email}
            </Text>
          </Container>
        )
      },
    },
    {
      dataIndex: "roleName",
      title: (
        <FormattedMessage
          id="settings.restaurant.members.datatable.header.role"
          defaultMessage="Role"
        />
      ),
      width: "20%",
      render: (_: string, record: T) => {
        return CustomAdminRoleNameEnum[record.roleName]
      },
    },
    {
      dataIndex: "locations",
      title: (
        <FormattedMessage
          id="settings.restaurant.members.datatable.header.locations.access"
          defaultMessage="Locations access"
        />
      ),
      width: "30%",
      render: (locations: Array<{ name: string; uuid: string }>, record: T) => {
        const names = locations?.map((location) => location.name)

        return AdminRoleNameEnum.RESTAURANT_ADMIN === record.roleName ? (
          <Text size="s" className="ellipsis">
            All locations
          </Text>
        ) : (
          names.join(", ")
        )
      },
    },
    {
      dataIndex: "inviteStatus",
      title: (
        <FormattedMessage
          id="settings.restaurant.members.datatable.header.status"
          defaultMessage="Status"
        />
      ),
      width: "18%",
      render: (_: string, record: T) => {
        return <MemberStatusLabel status={record.inviteStatus} />
      },
    },
    {
      dataIndex: "",
      width: "48px",
      render: (_: string, record: T) => {
        return <DropdownMember record={record} />
      },
    },
  ]

  return [columns]
}
export default tableColumns
