import { datePickerFormat } from "../../shared/utils/constant/dateFormats"
import { DatePicker as AntDatePicker } from "antd"
import type { RangePickerProps } from "antd/lib/date-picker/generatePicker"
import type { Moment } from "moment"
import moment from "moment-timezone"
import React from "react"
import styled, { css } from "styled-components"

const { RangePicker } = AntDatePicker

type StyledDatePickerProps = RangePickerProps<Moment> & {
  startDatePlaceHolder?: string | undefined
  endDatePlaceHolder?: string | undefined
}

export const DatePicker: React.FC<StyledDatePickerProps> = ({
  startDatePlaceHolder = "Start date",
  endDatePlaceHolder = "End date",
  ...props
}) => {
  return (
    <StyledDatePicker
      {...props}
      ranges={{
        Today: [moment(), moment()],
        Yesterday: [moment().subtract(1, "days"), moment().subtract(1, "days")],
        "This week": [moment().startOf("week"), moment().endOf("week")],
        "Last week": [
          moment().startOf("week").subtract(1, "weeks"),
          moment().endOf("week").subtract(1, "weeks"),
        ],
        "This month": [moment().startOf("month"), moment().endOf("month")],
        "Last month": [
          moment().startOf("month").subtract(1, "months"),
          moment().endOf("month").subtract(1, "months"),
        ],
        "Last 30 days": [moment().subtract(30, "days"), moment()],
        "Last 90 days": [moment().subtract(90, "days"), moment()],
        "This year": [moment().startOf("year"), moment().endOf("year")],
        "Last year": [
          moment().startOf("year").subtract(1, "years"),
          moment().endOf("year").subtract(1, "years"),
        ],
      }}
      format={datePickerFormat}
      placeholder={[startDatePlaceHolder, endDatePlaceHolder]}
      getPopupContainer={handlePopupContainer}
    />
  )
}

const handlePopupContainer = (trigger: HTMLElement) => trigger

const StyledDatePicker = styled(RangePicker)<RangePickerProps<Moment>>`
  ${({ theme }) =>
    css`
      &.ant-picker:hover,
      &.ant-picker-focused {
        border-color: ${theme.colors["Primary6"]};
        box-shadow: none;
      }

      &.ant-picker-input-active {
        border-bottom: ${theme.colors["Primary6"]};
      }

      &.ant-picker-range .ant-picker-active-bar {
        background: ${theme.colors["Primary6"]};
      }
      .ant-picker-header-view button:hover {
        color: ${theme.colors["Primary4"]};
      }

      .ant-picker-panel-container {
        filter: ${({ theme: defaultTheme }) =>
          `drop-shadow(${defaultTheme.shadows.elevationHigh})`};
        display: flex;
        flex-direction: row-reverse;
      }

      .ant-picker-panel-container > .ant-picker-footer {
        min-width: max-content;
        display: flex;
        flex-direction: row-reverse;
      }

      .ant-picker-ranges {
        display: flex;
        flex-direction: column;
      }

      .ant-picker-cell-inner::before {
        border: 1px solid ${theme.colors["Primary6"]} !important;
      }

      .ant-picker-cell-in-view.ant-picker-cell-in-range::before {
        background: ${theme.colors["Neutral5"]} !important;
      }

      .ant-pickerr-cell-range-hover-start {
        background: ${theme.colors["Neutral5"]} !important;
      }

      .ant-picker-cell-in-view.ant-picker-cell-start
        .ant-picker-cell-in-view.ant-picker-cell-selected
        .ant-picker-cell-inner,
      .ant-picker-cell-in-view.ant-picker-cell-range-start
        .ant-picker-cell-inner,
      .ant-picker-cell-in-view.ant-picker-cell-range-end
        .ant-picker-cell-inner {
        background: ${theme.colors["Primary6"]} !important;
      }

      .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
      .ant-picker-cell-in-view.ant-picker-cell-range-start
        .ant-picker-cell-inner,
      .ant-picker-cell-in-view.ant-picker-cell-range-end
        .ant-picker-cell-inner {
        color: ${theme.colors["Neutral1"]};
        background: ${theme.colors["Primary6"]} !important;
      }

      .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before,
      .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before {
        background: ${theme.colors["Neutral5"]} !important;
      }

      .ant-picker-ranges {
        padding: 0 !important;
      }

      .ant-tag-blue {
        width: 100%;
        color: ${theme.colors.Neutral8} !important;
        background: ${theme.colors.transparent} !important;
        border-color: ${theme.colors["Neutral1"]} !important;
        cursor: pointer;
        font-size: 15px !important;
      }

      .ant-picker-preset:hover {
        background: ${theme.colors["Neutral2"]} !important;
      }

      .ant-picker-preset:hover > .ant-tag-blue {
        border-color: ${theme.colors["Neutral2"]} !important;
      }

      .ant-picker-date-panel
        .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start
        .ant-picker-cell-inner::after,
      .ant-picker-date-panel
        .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end
        .ant-picker-cell-inner::after {
        position: absolute;
        top: 0;
        bottom: 0;
        z-index: -1;
        background: ${theme.colors["Neutral6"]} !important;
        transition: all 0.3s;
        content: "";
      }

      .ant-picker-cell-in-view.ant-picker-cell-range-hover:not(ant-picker-cell-range-hover-start):not(ant-picker-cell-range-hover-end)::after {
        border-top: 1px dashed ${theme.colors["Primary6"]} !important;
        border-bottom: 1px dashed ${theme.colors["Primary6"]} !important;
        border-left: none;
        border-right: none;
        content: "";
      }
      .ant-picker-cell-in-view.ant-picker-cell-range-hover-start::after {
        border-top: 1px dashed ${theme.colors["Primary6"]} !important;
        border-bottom: 1px dashed ${theme.colors["Primary6"]} !important;
        border-left: 1px dashed ${theme.colors["Primary6"]} !important;
        border-right: none !important;
        content: "";
      }
      .ant-picker-cell-in-view.ant-picker-cell-range-hover-end::after {
        border-top: 1px dashed ${theme.colors["Primary6"]} !important;
        border-bottom: 1px dashed ${theme.colors["Primary6"]} !important;
        border-left: none !important;
        border-right: 1px dashed ${theme.colors["Primary6"]} !important;
        content: "";
      }
    `}
`
