import { useGeneralContext } from "../../../../../shared/contexts/StoreProvider"
import type { QRConfig } from "../../../../../shared/hooks/useQRStyleConfig"
import { useQRStyleConfig } from "../../../../../shared/hooks/useQRStyleConfig"
import paths from "../../../../../shared/routes/paths"
import Button from "../../../../../ui/Button"
import Collapse, { CollapsePanel } from "../../../../../ui/Collapse"
import Container from "../../../../../ui/Container"
import { showGraphqlErrors } from "../../../../../ui/ErrorList"
import InputLabel from "../../../../../ui/InputLabel"
import ModalFull from "../../../../../ui/ModalFull"
import PageTitle from "../../../../../ui/PageTitle"
import SectionHeader from "../../../../../ui/SectionHeader"
import Slider from "../../../../../ui/Slider"
import Spacer from "../../../../../ui/Spacer"
import Text from "../../../../../ui/Typography/Text"
import type { ILocationsParams } from "../../../../Settings/Locations/hookforms.interfaces"
import ColorPicker from "../../../Restaurant/WhiteLabelApplication/ColorPicker"
import { useAddQrConfigToRestaurantMutation } from "../GraphQL/addQrConfigToRestaurant.generated"
import { useGetQrConfigRestaurantQuery } from "../GraphQL/getQrConfigRestaurant.generated"
import QRCode from "easyqrcodejs"
import React, { useEffect } from "react"
import { useIntl } from "react-intl"
import { useHistory, useParams } from "react-router-dom"
import styled, { css } from "styled-components"

export const CustomizeQRStyle: React.FC = () => {
  const initialRender = React.useRef(true)
  const code = React.createRef<HTMLDivElement>()
  const intl = useIntl()
  const { goBack } = useHistory()

  const { locationUUID } = useParams<ILocationsParams>()

  const {
    state: {
      currentRestaurant: { uuid: restaurantUUID },
    },
  } = useGeneralContext()

  const { config, setConfig } = useQRStyleConfig()

  const sliderValues = {
    min: 0.6,
    max: 1,
    defaultValue: 1,
    step: 0.1,
  }

  const [saveQrConfig, { loading: savingConfig }] =
    useAddQrConfigToRestaurantMutation({})

  useGetQrConfigRestaurantQuery({
    fetchPolicy: "no-cache",
    variables: { restaurantUUID },
    skip: !restaurantUUID,
    onError: showGraphqlErrors,
    onCompleted: ({ getQrConfigRestaurant }) => {
      if (getQrConfigRestaurant.qrConfig) {
        try {
          const qrConfig: QRConfig = JSON.parse(getQrConfigRestaurant.qrConfig)

          setConfig(qrConfig)
        } catch (error) {
          showGraphqlErrors(error)
        }
      }
    },
  })

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false
    } else {
      code?.current?.children[0].remove()
    }

    new QRCode(code.current, {
      ...config,
      text: `${process.env.REACT_APP_STOREFRONT_URL}`,
      width: 256,
      height: 256,
      correctLevel: QRCode.CorrectLevel.H,
    })
  }, [code, config])

  const onAttributeChange = (color: string, name?: string) => {
    setConfig({
      ...config,
      [`${name}`]: color,
      ...(name === "colorDark" && {
        PO_TL: color,
        PO_TR: color,
        PO_BL: color,
        PI_TL: color,
        PI_TR: color,
        PI_BL: color,
      }),
    })
  }

  const onDotScaleChange = (value: number) => {
    setConfig({
      ...config,
      dotScale: value,
    })
  }

  const onDotScaleTimingHChange = (value: number) => {
    setConfig({
      ...config,
      dotScaleTiming_H: value,
    })
  }

  const onDotScaleTimingVChange = (value: number) => {
    setConfig({
      ...config,
      dotScaleTiming_V: value,
    })
  }

  const onDotScaleAlignmentOuterChange = (value: number) => {
    setConfig({
      ...config,
      dotScaleAO: value,
    })
  }

  const onDotScaleAlignmentInnerChange = (value: number) => {
    setConfig({
      ...config,
      dotScaleAI: value,
    })
  }

  const onSaveConfig = async () => {
    try {
      await saveQrConfig({
        variables: {
          data: {
            restaurantUUID,
            qrConfig: JSON.stringify(config),
          },
        },
      })
      goBack()
    } catch (error) {
      showGraphqlErrors(error)
    }
  }

  return (
    <ModalFull
      id="create-tax-modal"
      goBackPath={paths.settings.locations.tablesByLocation(locationUUID)}
      visible
      closable
      title={intl.formatMessage({
        id: "settings.qr.code.modal.title",
        defaultMessage: "Customize QR Code",
      })}
    >
      <Container pointerEvents="none">
        <StyledTitleContainer>
          <PageTitle
            title={intl.formatMessage({
              id: "settings.qr.code.title",
              defaultMessage: "QR Code",
            })}
            description={intl.formatMessage({
              id: "settings.qr.code.description",
              defaultMessage:
                "Here you can change the appearance of the QR Code related to your restaurant app",
            })}
            margin={false}
          />
        </StyledTitleContainer>
        <StyledContainer
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
        >
          <StyledSettingsContent>
            <StyledMainColorsContainer>
              <Container
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
                width="280px"
              >
                <SectionHeader
                  title={intl.formatMessage({
                    id: "settings.qr.code.primary.color.title",
                    defaultMessage: "Primary Color",
                  })}
                  description={intl.formatMessage({
                    id: "settings.qr.code.primary.color.description",
                    defaultMessage: "Select a primary QR Color",
                  })}
                />
                <ColorPicker
                  name="colorDark"
                  color={config.colorDark}
                  onChange={onAttributeChange}
                  text={false}
                  title={intl.formatMessage({
                    id: "settings.qr.code.primary.color.picker.title",
                    defaultMessage: "Custom Primary Color",
                  })}
                />
              </Container>

              <Container
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
                width="280px"
              >
                <SectionHeader
                  title={intl.formatMessage({
                    id: "settings.qr.code.secondary.color.title",
                    defaultMessage: "Secondary Color",
                  })}
                  description={intl.formatMessage({
                    id: "settings.qr.code.secondary.color.description",
                    defaultMessage: "Select a secondary QR Color",
                  })}
                />
                <ColorPicker
                  name="colorLight"
                  color={config.colorLight}
                  secondary={true}
                  onChange={onAttributeChange}
                  text={false}
                  title={intl.formatMessage({
                    id: "settings.qr.code.secondary.color.picker.title",
                    defaultMessage: "Custom Secondary Color",
                  })}
                />
              </Container>
            </StyledMainColorsContainer>
            <Spacer size={36} />

            <Container
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
            >
              <SectionHeader
                title={intl.formatMessage({
                  id: "settings.qr.code.dot.scale.title",
                  defaultMessage: "Dot Scale",
                })}
                description={intl.formatMessage({
                  id: "settings.qr.code.dot.scale.description",
                  defaultMessage: "Select the size of the QR dots",
                })}
              />
              <Slider
                min={sliderValues.min}
                max={sliderValues.max}
                defaultValue={sliderValues.defaultValue}
                step={sliderValues.step}
                width="150px"
                name="dotScale"
                value={config.dotScale}
                onChange={onDotScaleChange}
              />
            </Container>
            <Spacer size={42} />

            <Collapse classId="qr-style-advanced-settings">
              <CollapsePanel
                key="advanced-settings-panel"
                header={
                  <Text size="m">
                    {intl.formatMessage({
                      id: "settings.qr.code.advanced.settings.header.title",
                      defaultMessage: "Advanced Settings",
                    })}
                  </Text>
                }
              >
                <Spacer size={42} />

                <Container
                  display="flex"
                  justifyContent="space-between"
                  flexDirection="column"
                >
                  <SectionHeader
                    title={intl.formatMessage({
                      id: "settings.qr.code.position.outer.color.title",
                      defaultMessage: "Position Outer Color",
                    })}
                    description={intl.formatMessage({
                      id: "settings.qr.code.position.outer.color.description",
                      defaultMessage:
                        "Select the outer color for the position squares",
                    })}
                  />
                  <StyledItemsContainer
                    display="flex"
                    justifyContent="flex-start"
                    flexDirection="row"
                    flexWrap="wrap"
                    maxWidth="600px"
                  >
                    <StyledItem display="flex" flexDirection="column">
                      <InputLabel
                        label={intl.formatMessage({
                          id: "settings.qr.code.top.left",
                          defaultMessage: "Top left",
                        })}
                      />
                      <ColorPicker
                        name="PO_TL"
                        color={config.PO_TL}
                        onChange={onAttributeChange}
                        text={false}
                        title={intl.formatMessage({
                          id: "settings.qr.code.top.left.outer.color.picker.title",
                          defaultMessage: "Custom Top Left Outer Color",
                        })}
                      />
                    </StyledItem>
                    <StyledItem display="flex" flexDirection="column">
                      <InputLabel
                        label={intl.formatMessage({
                          id: "settings.qr.code.top.right",
                          defaultMessage: "Top right",
                        })}
                      />
                      <ColorPicker
                        name="PO_TR"
                        color={config.PO_TR}
                        onChange={onAttributeChange}
                        text={false}
                        title={intl.formatMessage({
                          id: "settings.qr.code.top.right.outer.color.picker.title",
                          defaultMessage: "Custom Top Right Outer Color",
                        })}
                      />
                    </StyledItem>
                    <StyledItem display="flex" flexDirection="column">
                      <InputLabel
                        label={intl.formatMessage({
                          id: "settings.qr.code.bottom.left",
                          defaultMessage: "Bottom left",
                        })}
                      />
                      <ColorPicker
                        name="PO_BL"
                        color={config.PO_BL}
                        onChange={onAttributeChange}
                        text={false}
                        title={intl.formatMessage({
                          id: "settings.qr.code.bottom.left.outer.color.picker.title",
                          defaultMessage: "Custom Bottom Left Outer Color",
                        })}
                      />
                    </StyledItem>
                  </StyledItemsContainer>
                </Container>
                <Spacer size={50} />

                <Container
                  display="flex"
                  justifyContent="space-between"
                  flexDirection="column"
                  pointerEvents="none"
                >
                  <SectionHeader
                    title={intl.formatMessage({
                      id: "settings.qr.code.position.inner.color.title",
                      defaultMessage: "Position Inner Color",
                    })}
                    description={intl.formatMessage({
                      id: "settings.qr.code.position.inner.color.description",
                      defaultMessage:
                        "Select the inner color for the position squares",
                    })}
                  />
                  <StyledItemsContainer
                    display="flex"
                    justifyContent="flex-start"
                    flexDirection="row"
                    flexWrap="wrap"
                    maxWidth="600px"
                  >
                    <StyledItem display="flex" flexDirection="column">
                      <InputLabel
                        label={intl.formatMessage({
                          id: "settings.qr.code.top.left",
                          defaultMessage: "Top left",
                        })}
                      />
                      <ColorPicker
                        name="PI_TL"
                        color={config.PI_TL}
                        onChange={onAttributeChange}
                        text={false}
                        title={intl.formatMessage({
                          id: "settings.qr.code.top.left.inner.color.picker.title",
                          defaultMessage: "Custom Top Left Inner Color",
                        })}
                      />
                    </StyledItem>
                    <StyledItem display="flex" flexDirection="column">
                      <InputLabel
                        label={intl.formatMessage({
                          id: "settings.qr.code.top.right",
                          defaultMessage: "Top right",
                        })}
                      />
                      <ColorPicker
                        name="PI_TR"
                        color={config.PI_TR}
                        onChange={onAttributeChange}
                        text={false}
                        title={intl.formatMessage({
                          id: "settings.qr.code.top.right.inner.color.picker.title",
                          defaultMessage: "Custom Top Right Inner Color",
                        })}
                      />
                    </StyledItem>
                    <StyledItem display="flex" flexDirection="column">
                      <InputLabel
                        label={intl.formatMessage({
                          id: "settings.qr.code.bottom.left",
                          defaultMessage: "Bottom left",
                        })}
                      />
                      <ColorPicker
                        name="PI_BL"
                        color={config.PI_BL}
                        onChange={onAttributeChange}
                        text={false}
                        title={intl.formatMessage({
                          id: "settings.qr.code.bottom.left.inner.color.picker.title",
                          defaultMessage: "Custom Bottom Left Inner Color",
                        })}
                      />
                    </StyledItem>
                  </StyledItemsContainer>
                </Container>
                <Spacer size={50} />

                <Container
                  display="flex"
                  justifyContent="space-between"
                  flexDirection="column"
                >
                  <SectionHeader
                    title={intl.formatMessage({
                      id: "settings.qr.code.dot.scale.timing.title",
                      defaultMessage: "Dot Scale for Timing",
                    })}
                    description={intl.formatMessage({
                      id: "settings.qr.code.dot.scale.timing.description",
                      defaultMessage: "Select the size of the timing dots",
                    })}
                  />
                  <StyledItemsContainer
                    display="flex"
                    flexDirection="row"
                    flexWrap="wrap"
                    maxWidth="600px"
                  >
                    <Container display="flex" flexDirection="column">
                      <InputLabel
                        label={intl.formatMessage({
                          id: "settings.qr.code.dot.scale.timing.horizontal",
                          defaultMessage: "Timing Horizontal Scale",
                        })}
                      />
                      <Slider
                        min={sliderValues.min}
                        max={sliderValues.max}
                        defaultValue={sliderValues.defaultValue}
                        step={sliderValues.step}
                        width="150px"
                        name="dotScaleTiming_H"
                        value={config.dotScaleTiming_H}
                        onChange={onDotScaleTimingHChange}
                      />
                    </Container>
                    <Container display="flex" flexDirection="column">
                      <InputLabel
                        label={intl.formatMessage({
                          id: "settings.qr.code.dot.scale.timing.vertical",
                          defaultMessage: "Timing Vertical Scale",
                        })}
                      />
                      <Slider
                        min={sliderValues.min}
                        max={sliderValues.max}
                        defaultValue={sliderValues.defaultValue}
                        step={sliderValues.step}
                        width="150px"
                        name="dotScaleTiming_V"
                        value={config.dotScaleTiming_V}
                        onChange={onDotScaleTimingVChange}
                      />
                    </Container>
                  </StyledItemsContainer>
                </Container>
                <Spacer size={50} />

                <Container
                  display="flex"
                  justifyContent="space-between"
                  flexDirection="column"
                >
                  <SectionHeader
                    title={intl.formatMessage({
                      id: "settings.qr.code.dot.scale.alignment.title",
                      defaultMessage: "Dot Scale for Alignment",
                    })}
                    description={intl.formatMessage({
                      id: "settings.qr.code.dot.scale.alignment.description",
                      defaultMessage:
                        "Select the size of the alignment square dots",
                    })}
                  />
                  <StyledItemsContainer
                    display="flex"
                    flexDirection="row"
                    flexWrap="wrap"
                    maxWidth="600px"
                  >
                    <Container display="flex" flexDirection="column">
                      <InputLabel
                        label={intl.formatMessage({
                          id: "settings.qr.code.dot.scale.alignment.outer",
                          defaultMessage: "Alignment Outer Scale",
                        })}
                      />
                      <Slider
                        min={sliderValues.min}
                        max={sliderValues.max}
                        defaultValue={sliderValues.defaultValue}
                        step={sliderValues.step}
                        width="150px"
                        name="dotScaleTiming_H"
                        value={config.dotScaleAO}
                        onChange={onDotScaleAlignmentOuterChange}
                      />
                    </Container>
                    <Container display="flex" flexDirection="column">
                      <InputLabel
                        label={intl.formatMessage({
                          id: "settings.qr.code.dot.scale.alignment.inner",
                          defaultMessage: "Alignment Inner Scale",
                        })}
                      />
                      <Slider
                        min={sliderValues.min}
                        max={sliderValues.max}
                        defaultValue={sliderValues.defaultValue}
                        step={sliderValues.step}
                        width="150px"
                        name="dotScaleTiming_V"
                        value={config.dotScaleAI}
                        onChange={onDotScaleAlignmentInnerChange}
                      />
                    </Container>
                  </StyledItemsContainer>
                </Container>
              </CollapsePanel>
            </Collapse>
            <Spacer size={80} />

            <Button
              title={intl.formatMessage({
                id: "settings.qr.code.save.button",
                defaultMessage: "Save Information",
              })}
              onClick={onSaveConfig}
              disabled={savingConfig}
              loading={savingConfig}
            />
          </StyledSettingsContent>
          <StyledQRContainer>
            <StyledQRBox id="qrCode" ref={code} />
          </StyledQRContainer>
        </StyledContainer>
      </Container>
    </ModalFull>
  )
}

const StyledContainer = styled(Container)`
  flex-wrap: wrap-reverse;
`

const StyledTitleContainer = styled(Container)`
  padding: 0px 30px 0px 30px;
`

const StyledSettingsContent = styled.div`
  padding: 10px 40px 32px 40px;
  max-width: 700px;
  width: 100%;
`

const StyledQRContainer = styled.div`
  ${({ theme }) => css`
    border-left: 1px solid ${theme.colors["Neutral4"]};
  `}
`

const StyledQRBox = styled.div`
  padding: 10px 40px 40px 40px;
`

const StyledItemsContainer = styled(Container)`
  margin-right: 160px;
`

const StyledItem = styled(Container)`
  margin: 0px 20px 20px 0px;
`

const StyledMainColorsContainer = styled.div`
  display: flex;
  gap: 36px;
  flex-wrap: wrap;
`
