import {
  MM_DD_YYYY,
  hh_mm_A,
} from "../../../../../../shared/utils/constant/dateFormats"
import AddButton from "../../../../../../ui/AddButton"
import Badge from "../../../../../../ui/Badge"
import { Container } from "../../../../../../ui/Container/Container"
import EmptyState from "../../../../../../ui/EmptyState"
import SectionHeader from "../../../../../../ui/SectionHeader"
import Spacer from "../../../../../../ui/Spacer"
import Table from "../../../../../../ui/Table"
import BodyCell from "../../../../../../ui/Table/BodyCell"
import EmptyStateCell from "../../../../../../ui/Table/EmptyStateCell"
import Text from "../../../../../../ui/Typography/Text"
import EmptyIcon from "./../../../../../../shared/assets/icons/calendar-empty-icon.svg"
import { useGetHolidaysListQuery } from "./GraphQL/getHolidaysList.generated"
import { HolidayDropdown } from "./HolidayDrodown"
import { HolidayModalForm } from "./HolidayModalForm"
import { columns } from "./utils/columns"
import get from "lodash/get"
import moment from "moment-timezone"
import { useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"

interface IHolidayHours {
  locationUUID: string
  loading?: boolean
}

export const HolidayHours = ({ locationUUID }: IHolidayHours) => {
  const intl = useIntl()
  const [visibleHolidayModal, setVisibleHolidayModal] = useState(false)

  const { data, loading, error, refetch } = useGetHolidaysListQuery({
    variables: {
      location: {
        uuid: locationUUID,
      },
    },
    skip: !locationUUID,
  })

  const holidayList = get(data, "getHolidaysList", [])

  const retry = () => refetch()

  return (
    <>
      <Spacer size={8} />
      <Text size="s">
        {intl.formatMessage({
          id: "restaurants.locations.hours.of.operation.holidays.subtitle",
          defaultMessage:
            "Enter hours for days when your location has an irregular schedule.",
        })}
      </Text>
      <Spacer size={24} />

      <SectionHeader
        actionButton={
          <AddButton onClick={() => setVisibleHolidayModal(true)} />
        }
      />

      <Table
        top="105px"
        columns={columns}
        loading={loading}
        error={error}
        errorRefetch={retry}
        leftSpace={28}
      >
        {!!holidayList?.length &&
          holidayList.map((holiday) => {
            return (
              <tr className="styled-data-table-row" key={holiday.uuid}>
                <BodyCell>{holiday.name}</BodyCell>
                <BodyCell>
                  <Badge
                    color={holiday.isOpen ? "Success5" : "Neutral5"}
                    text={
                      <FormattedMessage
                        id="restaurants.locations.hours.of.operation.holidays.table.availability"
                        defaultMessage={holiday.isOpen ? "Open" : "Closed"}
                      />
                    }
                  />
                </BodyCell>
                <BodyCell>
                  {moment(holiday.date).utc().format(MM_DD_YYYY)}
                </BodyCell>

                <BodyCell>
                  {holiday.startTime || holiday.endTime
                    ? `${moment(holiday.startTime).format(hh_mm_A)} to ${moment(
                        holiday.endTime
                      ).format(hh_mm_A)}`
                    : "N/A"}
                </BodyCell>
                <BodyCell>
                  <HolidayDropdown
                    holiday={holiday}
                    locationUUID={locationUUID}
                  />
                </BodyCell>
              </tr>
            )
          })}

        {!holidayList?.length && !loading && (
          <EmptyStateCell colSpan={columns.length}>
            <Container padding="24px 0">
              <EmptyState
                image={EmptyIcon}
                title={intl.formatMessage({
                  id: "restaurants.locations.hours.of.operation.holidays.table.empty",
                  defaultMessage: "You don’t have any holiday yet",
                })}
              />
            </Container>
          </EmptyStateCell>
        )}
      </Table>

      {visibleHolidayModal && (
        <HolidayModalForm
          locationUUID={locationUUID}
          onClose={() => setVisibleHolidayModal(false)}
          visible={visibleHolidayModal}
        />
      )}
    </>
  )
}
