import Input from "../../../../../../ui/Inputs/Input"
import InputNumber from "../../../../../../ui/Inputs/InputNumber"
import PageTitle from "../../../../../../ui/PageTitle"
import Spacer from "../../../../../../ui/Spacer"
import { useCreateTaxContext } from "../../CreateTax/CreateTax"
import { Controller } from "react-hook-form"
import { useIntl } from "react-intl"

export const TaxInformationForm = () => {
  const {
    control,
    formState: { errors },
  } = useCreateTaxContext()
  const intl = useIntl()

  return (
    <div>
      <PageTitle
        title={intl.formatMessage({
          id: "restaurants.taxes.form.section.1.title",
          defaultMessage: "Tax Information",
        })}
      />

      <Controller
        control={control}
        name="name"
        render={({ field: nameField }) => (
          <Input
            {...nameField}
            label={intl.formatMessage({
              id: "restaurants.taxes.form.section.1.name.label",
              defaultMessage: "Tax Name",
            })}
            aria-label="name"
            requirement="required"
            hasError={!!errors?.name}
            helperText={errors?.name?.message}
            onBlur={nameField.onBlur}
            capitalizeFirstLetter={false}
          />
        )}
      />

      <Spacer size={24} />
      <Controller
        control={control}
        name="description"
        render={({ field: descriptionField }) => (
          <Input
            {...descriptionField}
            aria-label="description"
            label={intl.formatMessage({
              id: "restaurants.taxes.form.section.1.description.label",
              defaultMessage: "Tax Description",
            })}
            capitalizeFirstLetter={false}
          />
        )}
      />

      <Spacer size={24} />
      <Controller
        control={control}
        name="taxRate"
        render={({ field: taxRateField }) => (
          <InputNumber
            {...taxRateField}
            aria-label="taxRate"
            type="number"
            step={0.01}
            max={100}
            precision={2}
            width="200px"
            placeholder={intl.formatMessage({
              id: "generic.placeholder.amount",
              defaultMessage: "0.00",
            })}
            requirement="required"
            hasError={!!errors?.taxRate}
            helperText={errors?.taxRate?.message}
            label={intl.formatMessage({
              id: "restaurants.taxes.form.section.1.percentage.label",
              defaultMessage: "Tax Percentage",
            })}
          />
        )}
      />
    </div>
  )
}
