import {
  GetSingleLocationDocument,
  useGetSingleLocationQuery,
} from "../../../../../GraphQL/Queries/getSingleLocation.generated"
import { parsePhoneNumber } from "../../../../../shared/utils/helpers/parsePhoneNumber"
import Button from "../../../../../ui/Button"
import Container from "../../../../../ui/Container"
import { showGraphqlErrors } from "../../../../../ui/ErrorList"
import type { IModalProps } from "../../../../../ui/Modal"
import Modal from "../../../../../ui/Modal"
import { useUpdateLocationMutation } from "../../GraphQL/updateLocation.generated"
import DeviceForm from "../DeviceForm"
import { DeviceFormResolver } from "../DeviceForm/DeviceForm.yup"
import type { IDeviceForm } from "../DeviceForm/hookforms.interfaces"
import { GetAllDevicesDocument } from "../GraphQl/getAllDevices.generated"
import { useCreateDeviceMutation } from "./GraphQL/createDevice.generated"
import type { ApolloError } from "@apollo/client"
import get from "lodash/get"
import React, { useEffect } from "react"
import { FormProvider, useForm } from "react-hook-form"
import { useIntl } from "react-intl"
import { useParams } from "react-router-dom"

type CreateDeviceProps = Omit<IModalProps, "onCancel"> & {
  onClose: () => void
  onErrorCallback?: (error?: ApolloError) => void
  defaultValues?: Record<string, unknown>
  randomCode: string
}

interface ILocationParams {
  locationUUID: string
}

export const CreateDevice: React.FC<CreateDeviceProps> = ({
  defaultValues,
  onClose,
  onErrorCallback,
  randomCode,
  ...props
}) => {
  const intl = useIntl()
  const { locationUUID } = useParams<ILocationParams>()

  const { data: locationData } = useGetSingleLocationQuery({
    variables: { locationUUID },
    fetchPolicy: "network-only",
  })

  const locationPhone = get(locationData, "getSingleLocation.phone", null)

  const [createDevice, { loading }] = useCreateDeviceMutation({
    refetchQueries: [GetAllDevicesDocument],
  })

  const methods = useForm<IDeviceForm>({
    mode: "all",
    resolver: DeviceFormResolver,
    defaultValues,
  })

  const {
    handleSubmit,
    reset,
    setValue,
    getValues,
    setError,
    formState: { isValid, dirtyFields },
  } = methods

  useEffect(() => {
    setValue("locationPhone", locationPhone, { shouldDirty: true })
    setValue("deviceCode", randomCode, { shouldDirty: true })
  }, [setValue, locationPhone, randomCode])

  const [updateLocation] = useUpdateLocationMutation({
    refetchQueries: [GetSingleLocationDocument],
  })

  const addDevice = async (formData: IDeviceForm) => {
    try {
      const createdDevice = await createDevice({
        variables: {
          data: {
            deviceKey: formData.deviceKey,
            functionType: formData.functionType,
            locationUUID,
            code: getValues("deviceCode"),
          },
        },
      })

      if (!locationPhone) {
        await updateLocation({
          variables: {
            data: {
              locationUUID,
              phone: parsePhoneNumber(getValues("locationPhone") ?? "").number,
            },
          },
        })
      }

      const deviceUUID = get(createdDevice, "data.createDevice.uuid")

      if (deviceUUID) {
        reset()
        onClose()
      }
    } catch (error) {
      const errorMessage = get(error, "message")

      if (errorMessage.includes("code")) {
        setError("deviceCode", {
          type: "custome",
          message: "Codes is already taken",
        })
      } else {
        showGraphqlErrors(error)
        onErrorCallback && onErrorCallback(error as ApolloError)
      }
    }
  }

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    handleSubmit(addDevice)()
  }

  return (
    <FormProvider {...methods}>
      <Modal
        onCancel={onClose}
        shouldCloseOnClickOutside={Object.keys(dirtyFields).length === 0}
        {...props}
        footer={
          <Container display="flex" justifyContent="flex-end" gap="8px">
            <Button
              disabled={loading}
              title={intl.formatMessage({
                id: "restaurants.devices.add.device.cancel.button",
                defaultMessage: "Cancel",
              })}
              hierarchy="secondary"
              onClick={onClose}
            />

            <Button
              disabled={!isValid}
              loading={loading}
              title={intl.formatMessage({
                id: "restaurants.devices.add.device.button",
                defaultMessage: "Create",
              })}
              onClick={handleSubmit(addDevice)}
            />
          </Container>
        }
        title={intl.formatMessage({
          id: "restaurants.devices.add.device.modal.title",
          defaultMessage: "Add new device",
        })}
        afterClose={reset}
        destroyOnClose
      >
        <DeviceForm
          onSubmit={onSubmit}
          locationPhone={locationPhone}
          code={randomCode}
        />
      </Modal>
    </FormProvider>
  )
}
