import * as Types from '../../../../../../shared/graphql/generated/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateTableZoneMutationVariables = Types.Exact<{
  data: Types.CreateTableZoneInput;
}>;


export type CreateTableZoneMutation = { __typename?: 'Mutation', createTableZone: { __typename?: 'TableZoneModel', uuid: string, name: string } };


export const CreateTableZoneDocument = gql`
    mutation createTableZone($data: CreateTableZoneInput!) {
  createTableZone(data: $data) {
    uuid
    name
  }
}
    `;
export type CreateTableZoneMutationFn = Apollo.MutationFunction<CreateTableZoneMutation, CreateTableZoneMutationVariables>;

/**
 * __useCreateTableZoneMutation__
 *
 * To run a mutation, you first call `useCreateTableZoneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTableZoneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTableZoneMutation, { data, loading, error }] = useCreateTableZoneMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateTableZoneMutation(baseOptions?: Apollo.MutationHookOptions<CreateTableZoneMutation, CreateTableZoneMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTableZoneMutation, CreateTableZoneMutationVariables>(CreateTableZoneDocument, options);
      }
export type CreateTableZoneMutationHookResult = ReturnType<typeof useCreateTableZoneMutation>;
export type CreateTableZoneMutationResult = Apollo.MutationResult<CreateTableZoneMutation>;
export type CreateTableZoneMutationOptions = Apollo.BaseMutationOptions<CreateTableZoneMutation, CreateTableZoneMutationVariables>;