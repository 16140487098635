import {
  OrderMethodEnum,
  TipValueTypeEnum,
} from "../../../../../../../../shared/graphql/generated/types"
import { GetOrderMethodSettingQuery } from "../../../GraphQL/getOrderMethodSettings.generated"
import { DefaultTips, defaultTipRequest } from "../../config"
import { OrderMethodSettingsFormType } from "../../hookforms.interfaces"
import { getTipOptions } from "./get-tip-options.util"

const getEmptyFormData = (orderMethod: OrderMethodEnum) => ({
  tipSettings: {
    requestTipBefore: defaultTipRequest[orderMethod].before,
    requestTipAfter: defaultTipRequest[orderMethod].after,
    type: TipValueTypeEnum.PERCENTAGE,
    inactiveType: TipValueTypeEnum.AMOUNTS,
  },
  tipsData: {
    AMOUNTS: {
      options: getTipOptions(TipValueTypeEnum.AMOUNTS),
      default: DefaultTips.AMOUNTS[1].toString(),
    },
    PERCENTAGE: {
      options: getTipOptions(TipValueTypeEnum.PERCENTAGE),
      default: DefaultTips.PERCENTAGE[1].toString(),
    },
  },
  orderReady: { additionalText: "", checked: true },
  orderVerification: { additionalText: "", checked: true },
})

export const getFormDefaultData = (
  orderMethod: OrderMethodEnum,
  data?: GetOrderMethodSettingQuery
) => {
  if (!data) {
    return getEmptyFormData(orderMethod)
  }

  const orderMethodSettings = data.getOrderMethodSetting

  if (
    !orderMethodSettings ||
    !orderMethodSettings.notificationSettings ||
    !orderMethodSettings.tipSettings
  )
    return getEmptyFormData(orderMethod)

  const { notificationSettings, tipSettings } = orderMethodSettings

  if (!notificationSettings || !tipSettings)
    return getEmptyFormData(orderMethod)

  const {
    valueType,
    requestTipAfter,
    requestTipBefore,
    defaultAmount,
    amounts,
  } = tipSettings

  const {
    sendOrderReadyNotification,
    sendOrderVerificationNotification,
    orderReadyAdditionalText,
    orderVerificationAdditionalText,
  } = notificationSettings

  const activeTab =
    valueType === TipValueTypeEnum.AMOUNTS
      ? TipValueTypeEnum.AMOUNTS
      : TipValueTypeEnum.PERCENTAGE

  const inactiveTab =
    valueType === TipValueTypeEnum.AMOUNTS
      ? TipValueTypeEnum.PERCENTAGE
      : TipValueTypeEnum.AMOUNTS

  const filledForm: OrderMethodSettingsFormType = {
    tipSettings: {
      type: valueType,
      inactiveType: inactiveTab,
      requestTipBefore: !!requestTipBefore,
      requestTipAfter: !!requestTipAfter,
    },
    orderVerification: {
      additionalText: orderVerificationAdditionalText ?? "",
      checked: sendOrderVerificationNotification ?? true,
    },
    orderReady: {
      additionalText: orderReadyAdditionalText ?? "",
      checked: sendOrderReadyNotification ?? true,
    },
    tipsData: {
      AMOUNTS: {},
      PERCENTAGE: {},
    },
  }

  filledForm.tipsData[activeTab] = {
    default: defaultAmount.toString(),
    options: getTipOptions(activeTab, amounts),
  }

  filledForm.tipsData[inactiveTab] = {
    options: getTipOptions(inactiveTab),
    default: DefaultTips[inactiveTab][1].toString(),
  }

  return filledForm
}
