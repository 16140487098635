import { GetAllLocationsByUserDocument } from "../../../../../GraphQL/Queries/getAllLocationsByUser.generated"
import { useGeneralContext } from "../../../../../shared/contexts/StoreProvider"
import { OrderMethodEnum } from "../../../../../shared/graphql/generated/types"
import paths from "../../../../../shared/routes/paths"
import { postRefreshToken } from "../../../../../shared/utils/api/refresh-token"
import { parsePhoneNumber } from "../../../../../shared/utils/helpers/parsePhoneNumber"
import { showGraphqlErrors } from "../../../../../ui/ErrorList"
import ModalFull from "../../../../../ui/ModalFull"
import notification from "../../../../../ui/notification"
import { ActionTypes } from "../../../../Restaurants/actions"
import reduceScheduleToDaysOfTheWeek, {
  getScheduleFromDaysOfTheWeek,
} from "../../HoursOfOperation/BusinessHours/utils/getTimeRangesByDaysOfTheWeek"
import { useCreateLocationMutation } from "../GraphQL/createLocation.generated"
import { useCreateScheduleForLocationMutation } from "../GraphQL/createScheduleForLocation.generated"
import LocationForm from "./LocationForm"
import { LocationResolver } from "./LocationForm/LocationForm.yup"
import type { ILocationForm } from "./LocationForm/hookforms.interfaces"
import get from "lodash/get"
import { useState } from "react"
import { FormProvider, useForm } from "react-hook-form"
import { useIntl } from "react-intl"
import { useHistory } from "react-router-dom"

export const AddLocationModalFull = () => {
  const intl = useIntl()
  const [loading, setLoading] = useState(false)

  const {
    state: {
      currentRestaurant: { uuid: restaurantUUID },
    },
    dispatch,
    initRestaurants,
  } = useGeneralContext()
  const { push } = useHistory()
  const schedules = reduceScheduleToDaysOfTheWeek([])

  const formMethods = useForm<ILocationForm>({
    mode: "all",
    resolver: LocationResolver,
    defaultValues: {
      orderTypes: [OrderMethodEnum.TAKE_OUT],
      schedules,
      estimatedPreparationTime: 10,
    },
  })

  const { handleSubmit, reset } = formMethods
  const [createLocation] = useCreateLocationMutation({
    refetchQueries: [GetAllLocationsByUserDocument],
  })
  const [createScheduleForLocation] = useCreateScheduleForLocationMutation()

  const saveLocation = async (values: ILocationForm) => {
    try {
      const response = await createLocation({
        variables: {
          data: {
            restaurantUUID,
            name: values.name,
            address: values.address,
            latitude: values.latitude,
            longitude: values.longitude,
            orderTypes: values.orderTypes || [],
            ...(values.phone && {
              phone: parsePhoneNumber(values.phone).number,
            }),
            ...(values.estimatedPreparationTime && {
              estimatedPreparationTime: values.estimatedPreparationTime,
            }),
          },
        },
      })

      const locationUUID = get(response, "data.createLocation.uuid")

      const scheduleDetails = getScheduleFromDaysOfTheWeek(
        values.schedules || []
      )

      if (locationUUID && scheduleDetails.length > 0) {
        await createScheduleForLocation({
          variables: {
            data: {
              restaurantUUID,
              locationUUID,
              schedules: scheduleDetails,
            },
          },
        })
      }

      const data = await postRefreshToken()
      await initRestaurants(data)

      dispatch({
        type: ActionTypes.SetCurrentState,
        payload: {
          restaurantUUID,
          locationUUID,
        },
      })

      notification({
        description: intl.formatMessage({
          id: "onboarding.location.add.location.modal.success.message",
          defaultMessage: "Your location was created",
        }),
        type: "success",
      })
      push(paths.settings.locations.locationInfo(locationUUID))
    } catch (error) {
      showGraphqlErrors(error)
    } finally {
      setLoading(false)
    }
  }

  const afterClose = () => {
    reset()
  }

  return (
    <FormProvider {...formMethods}>
      <ModalFull
        id="add-location-modal"
        afterClose={afterClose}
        destroyOnClose
        visible
        closable
      >
        <LocationForm onSubmit={handleSubmit(saveLocation)} saving={loading} />
      </ModalFull>
    </FormProvider>
  )
}
