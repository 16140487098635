import Icon from "../../../../../../ui/Icon"
import Text from "../../../../../../ui/Typography/Text"
import classnames from "classnames"
import React from "react"
import { Link } from "react-router-dom"
import styled from "styled-components"

type RequirementItemButtonProps = {
  title: string
  path: string
  isCompleted?: boolean
}

const LinkContainer = ({
  path,
  isDisabled,
  children,
  className,
}: {
  path: string
  className: string
  children: React.ReactNode
  isDisabled?: boolean
}) => {
  return isDisabled ? (
    <div className={className}>{children}</div>
  ) : (
    <Link className={className} to={path}>
      {children}
    </Link>
  )
}

export const RequirementItem: React.FC<RequirementItemButtonProps> = ({
  title,
  path,
  isCompleted,
}) => {
  return (
    <StyledRequirementItemWrapper className="requirement-item-wrapper">
      <LinkContainer
        className="requirement-item-container"
        isDisabled={isCompleted}
        path={path}
      >
        <Icon
          classes="requirement-item-left-icon"
          remixiconClass={classnames({
            ["ri-checkbox-circle-fill"]: isCompleted,
            ["ri-error-warning-line"]: !isCompleted,
          })}
          color={isCompleted ? "Success5" : "Neutral8"}
          size={16}
        />
        <StyledTextContainer>
          <Text size="s">{title}</Text>
        </StyledTextContainer>
        <Icon
          classes="requirement-item-left-icon"
          remixiconClass="ri-arrow-right-s-line"
          color={isCompleted ? "Neutral4" : "Primary5"}
          size={20}
        />
      </LinkContainer>
    </StyledRequirementItemWrapper>
  )
}

const StyledRequirementItemWrapper = styled.div`
  flex: 1;
  .requirement-item-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 16px;
    gap: 8px;
  }

  .requirement-item-left-icon {
    margin-right: 8px;
  }
`

const StyledTextContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
`
