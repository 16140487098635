import styles from "./StatusBadge.module.css"
import { Flex, Text } from "@mantine/core"
import { useIntl } from "react-intl"

const statusData = [
  {
    label: {
      defaultMessage: "Not Connected",
      id: "settings.integrations.status.badge.disconnected.label",
    },
    textColor: "gray.9",
  },
  {
    label: {
      defaultMessage: "Connected",
      id: "settings.integrations.status.badge.disconnected.label",
    },
    textColor: "green.9",
  },
]

type StatusBadgeType = {
  connected: boolean
}
const StatusBadge = ({ connected = false }: StatusBadgeType) => {
  const intl = useIntl()
  const data = statusData[Number(connected)]
  const connectedClassName = connected ? styles.active : styles.inactive

  return (
    <Flex align="center" gap={8}>
      <div className={`${styles.circle} ${connectedClassName}`} />
      <Text size="sm" c={data.textColor}>
        {intl.formatMessage(data.label)}
      </Text>
    </Flex>
  )
}

export default StatusBadge
