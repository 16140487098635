import * as Types from '../../../../../../shared/graphql/generated/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetOrderMethodSettingQueryVariables = Types.Exact<{
  orderMethod: Types.OrderMethodEnum;
}>;


export type GetOrderMethodSettingQuery = { __typename?: 'Query', getOrderMethodSetting?: { __typename?: 'OrderMethodSettings', createdAt: any, orderMethod: Types.OrderMethodEnum, uuid: string, location: { __typename?: 'BasicLocationModel', name: string, uuid: string }, notificationSettings?: { __typename?: 'NotificationSettings', orderReadyAdditionalText?: string | null, orderVerificationAdditionalText?: string | null, sendOrderReadyNotification: boolean, sendOrderVerificationNotification: boolean } | null, tipSettings?: { __typename?: 'TipSettings', amounts: Array<number>, defaultAmount: number, requestTipAfter?: boolean | null, requestTipBefore?: boolean | null, valueType: Types.TipValueTypeEnum } | null } | null };


export const GetOrderMethodSettingDocument = gql`
    query getOrderMethodSetting($orderMethod: OrderMethodEnum!) {
  getOrderMethodSetting(orderMethod: $orderMethod) {
    createdAt
    location {
      name
      uuid
    }
    notificationSettings {
      orderReadyAdditionalText
      orderVerificationAdditionalText
      sendOrderReadyNotification
      sendOrderVerificationNotification
    }
    orderMethod
    tipSettings {
      amounts
      defaultAmount
      requestTipAfter
      requestTipBefore
      valueType
    }
    uuid
  }
}
    `;

/**
 * __useGetOrderMethodSettingQuery__
 *
 * To run a query within a React component, call `useGetOrderMethodSettingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrderMethodSettingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrderMethodSettingQuery({
 *   variables: {
 *      orderMethod: // value for 'orderMethod'
 *   },
 * });
 */
export function useGetOrderMethodSettingQuery(baseOptions: Apollo.QueryHookOptions<GetOrderMethodSettingQuery, GetOrderMethodSettingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOrderMethodSettingQuery, GetOrderMethodSettingQueryVariables>(GetOrderMethodSettingDocument, options);
      }
export function useGetOrderMethodSettingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrderMethodSettingQuery, GetOrderMethodSettingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOrderMethodSettingQuery, GetOrderMethodSettingQueryVariables>(GetOrderMethodSettingDocument, options);
        }
export type GetOrderMethodSettingQueryHookResult = ReturnType<typeof useGetOrderMethodSettingQuery>;
export type GetOrderMethodSettingLazyQueryHookResult = ReturnType<typeof useGetOrderMethodSettingLazyQuery>;
export type GetOrderMethodSettingQueryResult = Apollo.QueryResult<GetOrderMethodSettingQuery, GetOrderMethodSettingQueryVariables>;