import Wrapper from "../../Field/Wrapper"
import InputLabel from "../../InputLabel"
import Text from "../../Typography/Text"
import InputHelper from "../InputHelper"
import SimpleInput from "../SimpleInput"
import type { InputProps as SimpleInputProps } from "../SimpleInput/SimpleInput"
import classnames from "classnames"
import React, { useState } from "react"
import type { IBody } from "styled-components"
import styled from "styled-components"

export type InputProps = SimpleInputProps & {
  className?: string
  label?: string
  description?: string | React.ReactNode
  helperText?: string
  wrapHelperText?: boolean
  hasError?: boolean
  inputWrapperClassName?: string
  commentCharactersLimit?: number
  helperSize?: keyof IBody
  showCount?: boolean
  textLength?: number
}

const initialCounter = 0

const FancyInput = (
  props: InputProps,
  ref:
    | ((instance: unknown) => void)
    | React.RefObject<unknown>
    | null
    | undefined
) => {
  const {
    value,
    className,
    requirement,
    hasError,
    helperText,
    searchIcon,
    clearIcon,
    label,
    labelClassName,
    description,
    tooltipTitle,
    disabled,
    loading,
    width,
    wrapHelperText,
    size = "middle",
    inputWrapperClassName,
    helperSize = "m",
    showCount,
    textLength = 0,
    commentCharactersLimit = initialCounter,
    bordered = true,
    ...rest
  } = props

  const [countDown, setCountDown] = useState<number>(
    value ? value?.toString().length : initialCounter
  )

  const onKeyUpInput = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.currentTarget.value) {
      setCountDown(event.currentTarget.value.length)
    } else {
      setCountDown(initialCounter)
    }
  }

  return (
    <StyledInputWrapper
      className={inputWrapperClassName}
      hasDescription={Boolean(description)}
    >
      {label && (
        <InputLabel
          label={label}
          labelClassName={labelClassName}
          requirement={requirement}
          tooltipTitle={tooltipTitle}
        />
      )}
      {description && (
        <div className="label-description-wrapper">
          <Text className={"description-text"} size="s">
            {description}
          </Text>
        </div>
      )}
      <StyledFieldWrapper className={classnames("input-wrapper", className)}>
        <SimpleInput
          value={value}
          hasError={hasError}
          disabled={disabled}
          searchIcon={searchIcon}
          clearIcon={clearIcon}
          loading={loading}
          size={size}
          ref={ref}
          width={width}
          onKeyUp={onKeyUpInput}
          bordered={bordered}
          {...rest}
        />

        <InputHelper
          size={helperSize}
          $hasError={hasError}
          wrap={wrapHelperText}
          countDown={countDown}
          commentCharactersLimit={commentCharactersLimit}
        >
          {helperText}
        </InputHelper>
        {showCount && (
          <Text color="Neutral5" textAlign="right">
            {textLength}/{props.maxLength}
          </Text>
        )}
      </StyledFieldWrapper>
    </StyledInputWrapper>
  )
}

export const Input = React.forwardRef(FancyInput)

interface IStyledInputWrapper {
  hasDescription?: boolean
}

const StyledFieldWrapper = styled(Wrapper)`
  &.input-wrapper {
    display: block;
    position: relative;
  }
`

const StyledInputWrapper = styled(Wrapper)<IStyledInputWrapper>`
  .label-description-wrapper {
    margin-bottom: 8px;
  }

  .input-label-wrapper {
    margin-bottom: ${({ hasDescription }) => (hasDescription ? "0px" : "8px")};
  }
`
