import { setDecimals } from "../../../ui/Inputs/InputNumber/utils/get-decimals.util"

const MILES_MULTIPLIER = 0.000_621_371

const metersToMiles = (distanceInMeters: number) => {
  const distanceInMiles = setDecimals(
    (distanceInMeters * MILES_MULTIPLIER).toString(),
    2
  )

  return distanceInMiles
}

export default metersToMiles
