export const NotificationPreferences = {
  verification: {
    label: {
      id: "restaurants.locations.overview.order.method.modal.send.order.verification.label",
      defaultMessage: "Send order verification notification",
    },
    description: {
      id: "restaurants.locations.overview.order.method.modal.send.order.verification.description",
      defaultMessage:
        "Thank you for ordering from {restaurantName}. Your order number is [order number]. Receipt :  [Receipt URL]",
    },
    placeholder: {
      id: "restaurants.locations.overview.order.method.modal.send.order.verification.input.placeholder",
      defaultMessage: "e.g. We’ll text you when your order is ready...",
    },
  },
  ready: {
    label: {
      id: "restaurants.locations.overview.order.method.modal.order.ready.label",
      defaultMessage: "Send order ready notification",
    },
    description: {
      id: "restaurants.locations.overview.order.method.modal.order.ready.description",
      defaultMessage:
        "Your order [Order Number] from {restaurantName} at {locationName} is ready.",
    },
    placeholder: {
      id: "restaurants.locations.overview.order.method.modal.order.ready.input.placeholder",
      defaultMessage: "e.g. Please pick it up at the counter...",
    },
  },
  inputLabel: {
    id: "restaurants.locations.overview.order.method.modal.notification.input.label",
    defaultMessage: "Additional Text",
  },
  tooltip: {
    id: "restaurants.locations.overview.order.method.modal.notification.disabled.tooltip",
    defaultMessage:
      "This message has been disabled and customers won't receive this notification",
  },
}
