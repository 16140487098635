import styled from "styled-components"

export const Spinner = () => {
  return (
    <StyledSpinner role="spinner-loading">
      <i className="ri-loader-4-line spinning" />
    </StyledSpinner>
  )
}

const StyledSpinner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  font-size: 24px;
  line-height: 38px;

  .spinning {
    animation-duration: 800ms;
  }
`
