import Container from "../../../../../../../ui/Container"
import { InputFieldSkeleton } from "../../../../../../../ui/Inputs"
import type { SkeletonProps } from "antd"
import React from "react"
import styled, { css } from "styled-components"

export const HoursOfOperationSkeleton: React.FC<SkeletonProps> = ({
  children,
  loading = true,
}) => {
  return loading ? (
    <Container
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
    >
      {Array.from({ length: 7 })
        .fill({})
        .map((_, idx) => (
          <StyledContainer
            key={idx}
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            gap="24px"
          >
            <InputFieldSkeleton width="98px" />
            <Container display="flex" gap="9px" justifyContent="flex-end">
              <Container
                display="flex"
                gap="9px"
                flexWrap="wrap"
                justifyContent="flex-end"
              >
                <Container display="flex" gap="9px">
                  <InputFieldSkeleton size="default" width="160px" />
                  <InputFieldSkeleton size="default" width="78px" />
                </Container>
                <Container display="flex" gap="9px">
                  <InputFieldSkeleton size="default" width="14px" />
                  <InputFieldSkeleton size="default" width="160px" />
                  <InputFieldSkeleton size="default" width="78px" />
                </Container>
              </Container>
              <InputFieldSkeleton size="default" width="30px" />
            </Container>
          </StyledContainer>
        ))}
    </Container>
  ) : (
    <>{children}</>
  )
}

const StyledContainer = styled(Container)`
  width: 100%;
  padding: 24px 0;
  ${({ theme }) => css`
    border-bottom: 1px solid ${theme.colors["Neutral3"]};
  `}
`
