import * as Types from '../../../../../shared/graphql/generated/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateDeliveryIntegrationMutationVariables = Types.Exact<{
  data: Types.AddDeliveryIntegrationInput;
}>;


export type CreateDeliveryIntegrationMutation = { __typename?: 'Mutation', createDeliveryIntegration: { __typename?: 'DeliveryIntegrationWithLocationsModel', uuid: string, apiKey: string, integrationLabel: string, integrationName: Types.DeliveryIntegrationEnum, isActive: boolean, issues?: string | null, settings?: any | null } };


export const CreateDeliveryIntegrationDocument = gql`
    mutation createDeliveryIntegration($data: AddDeliveryIntegrationInput!) {
  createDeliveryIntegration(data: $data) {
    uuid
    apiKey
    integrationLabel
    integrationName
    isActive
    issues
    settings
  }
}
    `;
export type CreateDeliveryIntegrationMutationFn = Apollo.MutationFunction<CreateDeliveryIntegrationMutation, CreateDeliveryIntegrationMutationVariables>;

/**
 * __useCreateDeliveryIntegrationMutation__
 *
 * To run a mutation, you first call `useCreateDeliveryIntegrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDeliveryIntegrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDeliveryIntegrationMutation, { data, loading, error }] = useCreateDeliveryIntegrationMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateDeliveryIntegrationMutation(baseOptions?: Apollo.MutationHookOptions<CreateDeliveryIntegrationMutation, CreateDeliveryIntegrationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateDeliveryIntegrationMutation, CreateDeliveryIntegrationMutationVariables>(CreateDeliveryIntegrationDocument, options);
      }
export type CreateDeliveryIntegrationMutationHookResult = ReturnType<typeof useCreateDeliveryIntegrationMutation>;
export type CreateDeliveryIntegrationMutationResult = Apollo.MutationResult<CreateDeliveryIntegrationMutation>;
export type CreateDeliveryIntegrationMutationOptions = Apollo.BaseMutationOptions<CreateDeliveryIntegrationMutation, CreateDeliveryIntegrationMutationVariables>;