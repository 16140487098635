import styles from "./MantineCard.module.css"
import { Box, Card, Title } from "@mantine/core"
import { ReactNode } from "react"

interface MantineCardProps {
  title: string
  children: ReactNode
}

export const MantineCard = ({ title, children }: MantineCardProps) => {
  return (
    <Card withBorder>
      <Card.Section className={styles["card-section"]} px={16} py={8}>
        <Title order={4} c="gray.6">
          {title}
        </Title>
      </Card.Section>
      <Box pt={16}>{children}</Box>
    </Card>
  )
}
