import AccessDenied from "../../pages/AccessDenied"
import {
  FORBIDDEN_RESOURCE_RESPONSE_ERROR,
  LOCATION_NOT_FOUND,
} from "../../shared/graphql/constants"
import Button from "../Button"
import Container from "../Container"
import type { ResultProps } from "antd"
import { Result } from "antd"
import React from "react"
import { useIntl } from "react-intl"

interface IRetryProps extends ResultProps {
  error?: { message: string }
  reload?: () => void
  fullScreen?: boolean
}

export const RetryPage: React.FC<IRetryProps> = ({
  status = "403",
  fullScreen = true,
  ...props
}) => {
  const intl = useIntl()

  if (props.error?.message === FORBIDDEN_RESOURCE_RESPONSE_ERROR) {
    return <AccessDenied fullScreen={!!fullScreen} />
  }

  if (props.error?.message === LOCATION_NOT_FOUND) {
    return fullScreen ? (
      <Container
        position="relative"
        role="retry-page"
        fullScreen={fullScreen}
        centered
      >
        <Result status={status} subTitle={props?.error?.message} />
      </Container>
    ) : null
  }

  return (
    <Container
      position="relative"
      role="retry-page"
      fullScreen={fullScreen}
      centered
    >
      <Result
        status={status}
        subTitle={props?.error?.message}
        extra={
          <Container centered>
            <Button
              hierarchy="secondary"
              title={intl.formatMessage({
                id: "generic.error.page.retry",
                defaultMessage: "Retry",
              })}
              onClick={props.reload}
            />
          </Container>
        }
      />
    </Container>
  )
}
