import { useGeneralContext } from "../../../shared/contexts/StoreProvider"
import LayoutBase from "../LayoutBase"
import Sider from "../components/Sider"
import Content from "./Content"
import RestaurantSidebar from "./Sidebar"
import React from "react"
import styled, { css } from "styled-components"

export const MainLayout: React.FC = ({ children }) => {
  const {
    state: { currentRestaurant, ui },
  } = useGeneralContext()

  return (
    <LayoutBase flexDirection="row">
      {!ui.hideSideBar && (
        <StyledSider uuid={currentRestaurant?.uuid}>
          <RestaurantSidebar />
        </StyledSider>
      )}

      <StyledDivider />
      <Content>{children}</Content>
    </LayoutBase>
  )
}

const StyledDivider = styled.div`
  border-left: 1px solid ${({ theme }) => theme.colors["Neutral3"]};
`

const StyledSider = styled(Sider)<{ uuid?: string }>`
  ${({ uuid }) => css`
    display: ${uuid ? "block" : "none"};
  `}
`
