import { useGeneralContext } from "../../../../../../shared/contexts/StoreProvider"
import metersToMiles from "../../../../../../shared/utils/helpers/metersToMiles"
import DataTable from "../../../../../../ui/DataTable"
import { setDecimals } from "../../../../../../ui/Inputs/InputNumber/utils/get-decimals.util"
import Text from "../../../../../../ui/Typography/Text"
import type { ILocationDeliveryParams } from "../../hookforms.interfaces"
import DropdownDeliveryZone from "../DropdownDeliveryZones"
import { useGetDeliveryPricesByLocationQuery } from "../GraphQL/getDeliveryLocations.generated"
import type { IDeliveryZonesTableModel } from "./deliveryZonesTable.interfaces"
import { Table } from "antd"
import get from "lodash/get"
import { FormattedMessage } from "react-intl"
import { useParams } from "react-router-dom"

const TableDeliveryZones = () => {
  const { locationUUID } = useParams<ILocationDeliveryParams>()

  const {
    state: {
      currentRestaurant: { uuid: restaurantUUID },
    },
  } = useGeneralContext()

  const { data, loading, error, refetch } = useGetDeliveryPricesByLocationQuery(
    {
      variables: {
        locationUUID: locationUUID ?? "",
      },
      skip: !restaurantUUID,
      notifyOnNetworkStatusChange: true,
    }
  )

  const deliveryZonesList: IDeliveryZonesTableModel[] = get(
    data,
    "getDeliveryPricesByLocation",
    []
  ).map((deliveryZone, index) => {
    return {
      id: (index + 1).toString().padStart(2, "0"),
      ...deliveryZone,
    }
  })

  const retry = () => refetch()

  if (deliveryZonesList.length === 0) {
    return null
  }

  return (
    <DataTable
      substactPixelesToScrollHeight={311}
      dataSource={deliveryZonesList}
      loading={loading}
      error={error}
      errorRefetch={retry}
    >
      <Table.Column
        dataIndex="id"
        title={
          <FormattedMessage
            id="settings.locations.delivery.zones.table.zone"
            defaultMessage="Zone"
          />
        }
        width="25%"
        render={(id) => {
          return <Text>{id}</Text>
        }}
      />

      <Table.Column
        dataIndex="minDistance"
        title={
          <FormattedMessage
            id="settings.locations.delivery.zones.table.min.distance"
            defaultMessage="Min distance"
          />
        }
        width="25%"
        render={(minDistance: number) => {
          return <Text>{`${metersToMiles(minDistance)} mi`}</Text>
        }}
      />

      <Table.Column
        dataIndex="maxDistance"
        title={
          <FormattedMessage
            id="settings.locations.delivery.zones.table.max.distance"
            defaultMessage="Max distance"
          />
        }
        width="25%"
        render={(maxDistance: number) => {
          return <Text>{`${metersToMiles(maxDistance)} mi`}</Text>
        }}
      />

      <Table.Column
        dataIndex="price"
        title={
          <FormattedMessage
            id="settings.locations.delivery.zones.table.fee"
            defaultMessage="Fee"
          />
        }
        width="15%"
        render={(fee: number) => {
          return <Text>{`$${setDecimals(fee.toString(), 2)}`}</Text>
        }}
      />

      <Table.Column
        dataIndex=""
        width="10%"
        render={(_: string, record: IDeliveryZonesTableModel) => {
          return <DropdownDeliveryZone record={record} />
        }}
      />
    </DataTable>
  )
}

export default TableDeliveryZones
