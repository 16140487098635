import { COMBO_FILTER } from "../../pages/Restaurants/MenuItems/Combos/forms/SingleItemForm/utils/constants"
import paths from "../../shared/routes/paths"
import storage from "../../shared/utils/storage"
import type {
  BreadcrumbData,
  IBreadcrumbLocations,
  IBreadcrumbs,
} from "./interfaces"

interface GeneratePathsProps {
  breadcrumbsData: IBreadcrumbs[]
  params: Record<string, string>
  search: string
  pathName: string
  customTitle?: string
}

export const breadcrumbs: BreadcrumbData = {
  createMenu: [
    { pathPattern: paths.restaurants.menu, title: "Categories" },
    { pathPattern: paths.restaurants.createMenu, title: "New Category" },
  ],
  editMenu: [
    { pathPattern: paths.restaurants.menu, title: "Categories" },
    { pathPattern: paths.restaurants.editMenu(), title: "Edit Category" },
  ],
  editItems: [
    { pathPattern: paths.restaurants.items, title: "Items" },
    { pathPattern: paths.restaurants.editProduct(), title: "Edit Item" },
  ],
  orderDetails: [
    { pathPattern: paths.restaurants.orders, title: "Orders" },
    {
      pathPattern: paths.restaurants.orders,
      title: "Order Details",
      hasSearch: true,
    },
  ],
  editItemTax: [
    { pathPattern: paths.settings.restaurant.taxes, title: "Taxes" },
    {
      pathPattern: paths.settings.restaurant.editItemTax(),
      title: "Edit Item Tax",
    },
  ],
  editSaleTax: [
    { pathPattern: paths.settings.restaurant.taxes, title: "Taxes" },
    {
      pathPattern: paths.settings.restaurant.editSalesTax(),
      title: "Edit Sale Tax",
    },
  ],
  CorporateMenuDetail: [
    {
      pathPattern: paths.restaurants.locations.menusByLocation(),
      title: "Categories",
    },
    {
      pathPattern: paths.restaurants.locations.editMenuByLocation(),
      title: "Corporate Category",
    },
  ],
  createCorporateItem: [
    {
      pathPattern: paths.restaurants.locations.itemsByLocation(),
      title: "Items",
    },
    {
      pathPattern: paths.restaurants.locations.createProductByLocation(),
      title: "New Item",
    },
  ],
  createItem: [
    { pathPattern: paths.restaurants.menu, title: "Items" },
    { pathPattern: paths.restaurants.createProduct, title: "New Menu Item" },
  ],
  createCorporateCombo: [
    {
      pathPattern: paths.restaurants.locations.itemsByLocation() + COMBO_FILTER,
      title: "Items",
    },
    {
      pathPattern: paths.restaurants.locations.createComboByLocation(),
      title: "New Item",
    },
  ],
  createCombo: [
    { pathPattern: paths.restaurants.menu, title: "Combos" },
    { pathPattern: paths.restaurants.createCombo, title: "New Combo" },
  ],
  createModifierGroup: [
    { pathPattern: paths.restaurants.modifiers, title: "Modifiers" },
    {
      pathPattern: paths.restaurants.createModifiers,
      title: "New Modifier Group",
    },
  ],
  corporateItemDetail: [
    {
      pathPattern: paths.restaurants.locations.itemsByLocation(),
      title: "Items",
    },
    {
      pathPattern: paths.restaurants.locations.editItemByLocation(),
      title: "Corporate Item",
    },
  ],
}

export const generatePaths = ({
  breadcrumbsData,
  params,
  search,
  pathName,
  customTitle,
}: GeneratePathsProps) => {
  const allPaths: IBreadcrumbs[] = []
  const paramsArray = Object.keys(params)

  const breadcrumbAfterSearch = breadcrumbsData.map((breadcrumb) => {
    if (breadcrumb.hasSearch)
      return {
        ...breadcrumb,
        pathPattern: `${breadcrumb.pathPattern}${search}`,
      }

    return breadcrumb
  })

  const updateTitle = (
    index: number,
    newTitle: string | undefined,
    breadcrumbsToUpdate: IBreadcrumbs[]
  ) => {
    if (newTitle) {
      const newBreadcrumbs = breadcrumbsToUpdate.map(
        (breadcrumb, targetIndex) =>
          index === targetIndex
            ? { ...breadcrumb, title: newTitle }
            : breadcrumb
      )

      return newBreadcrumbs
    }

    return breadcrumbsToUpdate
  }

  if (paramsArray.length > 0) {
    let breadcrumbHelper = JSON.stringify(breadcrumbAfterSearch)

    paramsArray.forEach((param) => {
      const newPaths = breadcrumbHelper.replaceAll(`:${param}`, params[param])
      breadcrumbHelper = newPaths
    })
    const newPaths = JSON.parse(breadcrumbHelper)
    allPaths.push(...newPaths)
    const index = allPaths.findIndex(
      (path) => path.pathPattern === `${pathName}${search}`
    )
    const slicedPaths = updateTitle(index, customTitle, allPaths).slice(
      0,
      index + 1
    )

    return slicedPaths
  } else {
    const index = breadcrumbAfterSearch.findIndex(
      (path) => path.pathPattern === `${pathName}${search}`
    )

    const slicedPaths = updateTitle(
      index,
      customTitle,
      breadcrumbAfterSearch
    ).slice(0, index + 1)

    return slicedPaths
  }
}

export const slicePaths = (
  id: string,
  locations: IBreadcrumbLocations[],
  currentPath: string
) => {
  const index = locations?.findIndex(
    (location) => location.path === currentPath
  )

  if (index > 0) {
    const newLocations = locations?.slice(0, index)
    storage.set(id, newLocations)

    return newLocations
  }

  return locations
}
