import defaultImage from "../../shared/assets/images/ingredient-placeholder.svg"
import Spacer from "../Spacer"
import type { AvatarProps } from "antd"
import { Avatar } from "antd"
import type CSS from "csstype"
import type { ReactElement } from "react"
import React, { useState } from "react"
import type { IBaseThemeColors } from "styled-components"
import styled, { css } from "styled-components"

export type ImageIconProps = AvatarProps & {
  size?: number
  subtitle?: string | ReactElement
  color?: keyof IBaseThemeColors
  imagefit?: CSS.Property.ObjectFit
}

export const StyledAvatar = styled(Avatar)<ImageIconProps>`
  ${({ color }) =>
    color &&
    css`
      background-color: ${({ theme }) => theme.colors[color]};
    `}
  ${({ size }) =>
    size &&
    css`
      min-width: ${size > 0 ? `${size}px` : "24px"};
    `}
  ${({ size }) =>
    size &&
    css`
      min-height: ${size > 0 ? `${size}px` : "24px"};
    `}
  img {
    object-fit: ${({ imagefit }) => imagefit ?? "cover"};
  }
`

export const ImageIcon: React.FC<ImageIconProps> = ({
  subtitle,
  src,
  ...props
}: ImageIconProps) => {
  const [imageUploadError, setImageUploadError] = useState<boolean>(false)

  const onSetImageUploadError = () => {
    setImageUploadError(true)

    return true
  }

  return (
    <>
      <StyledAvatar
        {...props}
        src={imageUploadError ? defaultImage : src}
        onError={onSetImageUploadError}
      />
      {subtitle && (
        <>
          <Spacer size={12} />
          {subtitle}
        </>
      )}
    </>
  )
}

ImageIcon.defaultProps = {
  size: 88,
}
