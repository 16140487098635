import Container from "../Container"
import Content from "../Layouts/MainLayout/Content"
import Text from "../Typography/Text"
import Search from "./Search"
import React from "react"
import styled, { css } from "styled-components"

export type HeaderProps = {
  title?: string | React.ReactNode
  search?: boolean
  filter?: () => React.ReactNode
  actions?: React.ComponentType
  extra?: () => React.ReactNode
  actionsOverflow?: "hidden" | "scroll" | "auto" | "visible"
  details?: boolean
  sort?: () => React.ReactNode
}

type StyledRightWrapperProps = {
  actionsOverflow?: "hidden" | "scroll" | "auto" | "visible"
  details?: boolean
}

export const Header: React.FC<HeaderProps> = ({
  title,
  children,
  extra,
  search,
  filter,
  details,
  actions: Actions,
  actionsOverflow = "hidden",
  sort,
}) => {
  return (
    <StyledHeaderWrapper className={extra ? "header-wrapper" : ""}>
      <StyledLeftWrapper
        className={!!title && !!filter ? "header-left-wrapper" : ""}
      >
        {!!title && (
          <Text className="header-title" size="m" weight="bold">
            {title}
          </Text>
        )}
        {!!filter && filter()}
      </StyledLeftWrapper>
      {extra && (
        <StyledExtraWrapper className="header-extra-wrapper">
          {extra && extra()}
        </StyledExtraWrapper>
      )}
      <Container display="flex" gap="16px">
        {sort && sort()}
        <StyledRightWrapper
          actionsOverflow={actionsOverflow}
          details={details}
          className={extra ? "header-right-wrapper" : ""}
        >
          {search && <Search />}
          {Actions && <Actions />}
          {children}
        </StyledRightWrapper>
      </Container>
    </StyledHeaderWrapper>
  )
}

export const withHeader = (
  WrappedComponent: React.ReactElement,
  props: HeaderProps
) => {
  const ContentWithHeader = () => {
    return <Content header={<Header {...props} />}>{WrappedComponent}</Content>
  }

  return ContentWithHeader
}

const StyledHeaderWrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  margin: 0px;
  padding: 0px 16px;
  justify-content: space-between;

  .header-title {
    align-items: center;
    flex-shrink: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 0 20px;
  }
  .header-left-wrapper {
    width: 100%;
    gap: 8px;
  }
`

const StyledLeftWrapper = styled.div`
  display: flex;
  gap: 48px;
`

const StyledExtraWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  align-self: center;
`

const StyledRightWrapper = styled.div<StyledRightWrapperProps>`
  display: flex;
  align-items: center;
  padding-right: 16px;
  ${({ actionsOverflow }) =>
    actionsOverflow &&
    css`
      overflow: ${actionsOverflow};
    `};
  ${({ details }) =>
    details &&
    css`
      flex: 1;
      padding-right: 36px;
    `};
`
