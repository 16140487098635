import Button from "../../../../../ui/Button"
import Container from "../../../../../ui/Container"
import Divider from "../../../../../ui/Divider"
import { showGraphqlErrors } from "../../../../../ui/ErrorList"
import type { IModalProps } from "../../../../../ui/Modal"
import Modal from "../../../../../ui/Modal"
import Text from "../../../../../ui/Typography/Text"
import type {
  TokenCountryEnum,
  TokenCurrencyEnum,
} from "../AccountDebitCardForm/hookforms.interfaces"
import { TokenTypeEnum } from "../AccountDebitCardForm/hookforms.interfaces"
import tokenTypeOptions from "../AddAccountOrDebitCard/tokenTypeOptions"
import { useGetBankingAccountLazyQuery } from "../GraphQL/getBankingAccount.generated"
import { useRefreshOnboardingMutation } from "../GraphQL/refreshOnboarding.generated"
import {
  AccountFieldSkeleton,
  AccountFooterSkeleton,
} from "./ConnectBankAccount.skeleton"
import get from "lodash/get"
import React, { useEffect, useRef, useState } from "react"
import { useIntl } from "react-intl"

type ConnectBankAccountProps = Omit<IModalProps, "onCancel"> & {
  onCancel: () => void
  uuid: string
}

export interface IStripeAccount {
  requirements: Array<string>
  transfersStatus: string
  externalAccount: {
    type: `${TokenTypeEnum}`
    last4: string
    currency: `${TokenCurrencyEnum}`
    country: `${TokenCountryEnum}`
  }
}

export const ConnectBankAccount: React.FC<ConnectBankAccountProps> = ({
  uuid,
  onCancel,
  ...props
}) => {
  const intl = useIntl()
  const initialRender = useRef(true)
  const [stripeAccount, setStripeAccount] = useState<IStripeAccount>()

  const accountType = tokenTypeOptions.find(
    ({ value }) => value === stripeAccount?.externalAccount.type
  )

  const [getStripeLink, { loading: gettingLink }] =
    useRefreshOnboardingMutation()

  const [getBankingAccount, { loading }] = useGetBankingAccountLazyQuery({
    variables: { uuid },
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      try {
        if (data?.getBankingAccount.stData) {
          const account = JSON.parse(data?.getBankingAccount.stData)

          const req = get(account, "requirements.currently_due", [])
          const externalAccounts = get(account, "external_accounts.data.0")
          const status = get(account, "capabilities.transfers")

          setStripeAccount({
            requirements: req,
            transfersStatus: status,
            externalAccount: {
              type: externalAccounts?.object,
              country: externalAccounts?.country,
              currency: externalAccounts?.currency,
              last4: externalAccounts?.last4,
            },
          })
        }
      } catch (error) {
        showGraphqlErrors(error)
      }
    },
  })

  const onCreateConnectionLink = async () => {
    try {
      const { data } = await getStripeLink({ variables: { uuid } })

      if (data?.refreshOnboarding.url) {
        window.location.replace(data?.refreshOnboarding.url)
      }
    } catch (error) {
      showGraphqlErrors(error)
    }
  }

  const afterModalCloses = () => {
    setStripeAccount(undefined)
    initialRender.current = true
  }

  useEffect(() => {
    if (props.visible && initialRender.current) {
      getBankingAccount()
      initialRender.current = false
    }
  }, [props.visible, getBankingAccount])

  return (
    <Modal
      {...props}
      onCancel={onCancel}
      footer={
        <Container display="flex" justifyContent="flex-end" gap="8px">
          <Button
            title={intl.formatMessage({
              id: "components.modal.close.button",
              defaultMessage: "Close",
            })}
            hierarchy="secondary"
            onClick={onCancel}
          />
          <Button
            loading={gettingLink}
            title={intl.formatMessage({
              id: "restaurants.bank.accounts.connect.save.button",
              defaultMessage: "Complete Information",
            })}
            onClick={onCreateConnectionLink}
            hidden={!stripeAccount?.requirements.length}
          />
        </Container>
      }
      title={intl.formatMessage({
        id: "restaurants.bank.accounts.connect.title",
        defaultMessage: "Connect Account",
      })}
      closable={false}
      afterClose={afterModalCloses}
      destroyOnClose
    >
      <>
        <Container display="flex" flexDirection="column" gap="24px" centered>
          <Container
            display="flex"
            justifyContent="space-between"
            width="300px"
          >
            <AccountFieldSkeleton loading={loading}>
              <Text size="m" weight="bold">
                {intl.formatMessage({
                  id: "restaurants.bank.accounts.connect.transfer.status",
                  defaultMessage: "Transfers Status",
                })}
              </Text>
              <Text size="m">
                {stripeAccount?.transfersStatus.toUpperCase() || "-"}
              </Text>
            </AccountFieldSkeleton>
          </Container>

          <Container
            display="flex"
            justifyContent="space-between"
            width="300px"
          >
            <AccountFieldSkeleton loading={loading}>
              <Text size="m" weight="bold">
                {intl.formatMessage({
                  id: "restaurants.bank.accounts.connect.currency",
                  defaultMessage: "Currency",
                })}
              </Text>
              <Text size="m">
                {stripeAccount?.externalAccount.currency
                  ? stripeAccount?.externalAccount.currency.toUpperCase()
                  : "-"}
              </Text>
            </AccountFieldSkeleton>
          </Container>

          <Container
            display="flex"
            justifyContent="space-between"
            width="300px"
          >
            <AccountFieldSkeleton loading={loading}>
              <Text size="m" weight="bold">
                {intl.formatMessage({
                  id: "restaurants.bank.accounts.connect.country",
                  defaultMessage: "Country",
                })}
              </Text>
              <Text size="m">
                {stripeAccount?.externalAccount.country || "-"}
              </Text>
            </AccountFieldSkeleton>
          </Container>

          <Container
            display="flex"
            justifyContent="space-between"
            width="300px"
          >
            <AccountFieldSkeleton loading={loading}>
              <Text size="m" weight="bold">
                {accountType
                  ? intl.formatMessage({
                      id: accountType.labelId,
                      defaultMessage: accountType.defaultMessage,
                    })
                  : "Last digits"}
                {
                  TokenTypeEnum[
                    stripeAccount?.externalAccount
                      .type as keyof typeof TokenTypeEnum
                  ]
                }
              </Text>
              <Text size="m">
                {stripeAccount?.externalAccount.last4 || "-"}
              </Text>
            </AccountFieldSkeleton>
          </Container>
        </Container>
        <Divider />

        <AccountFooterSkeleton loading={loading}>
          {!!stripeAccount && (
            <>
              <Text size="m" weight="bold" textAlign="center">
                {intl.formatMessage(
                  {
                    id: "restaurants.bank.accounts.connect.requirement.title",
                    defaultMessage:
                      "{count, plural, =0 {You've passed all the requirements} other {You have {count} requirements left}}",
                  },
                  {
                    count: stripeAccount?.requirements.length || 0,
                  }
                )}
              </Text>

              <Text size="s" textAlign="center">
                {intl.formatMessage(
                  {
                    id: "restaurants.bank.accounts.connect.requirement.description",
                    defaultMessage:
                      "{count, plural, =0 {} other {Please complete the information required}}",
                  },
                  {
                    count: stripeAccount?.requirements.length || 0,
                  }
                )}
              </Text>
            </>
          )}
        </AccountFooterSkeleton>
      </>
    </Modal>
  )
}
