import { useFilters } from "../../../shared/contexts/FilterProvider"
import { TAB_SEARCH_FILTER_NAME } from "../../../shared/utils/constant/searchParams"
import Container from "../../Container"
import Icon from "../../Icon"
import Input from "../../Inputs"
import type { Input as AntdInput } from "antd"
import classnames from "classnames"
import debounce from "lodash/debounce"
import queryString from "query-string"
import React, { useRef, useState } from "react"
import type { ChangeEvent } from "react"
import { useIntl } from "react-intl"
import { useLocation } from "react-router-dom"
import styled, { css } from "styled-components"

enum ItemTypesEnum {
  products = "products",
  combos = "combos",
}

export const Search: React.FC = () => {
  const intl = useIntl()

  const inputRef = useRef<AntdInput>()

  const { search: locationSearch } = useLocation()
  const searchParams = new URLSearchParams(locationSearch)
  const param = searchParams.get(TAB_SEARCH_FILTER_NAME)
  const { getSearchQueryFilter, updateSearch } = useFilters()

  const search = getSearchQueryFilter()

  const [collapsed, setCollapsed] = useState<boolean>(!search)

  const onIconToggle = () => {
    if (collapsed) {
      inputRef.current?.focus()
    }

    setCollapsed((prev) => {
      return !prev
    })
  }

  const debouncedSearch = debounce(
    async (value: string, tab: ItemTypesEnum, abort: boolean) => {
      if (abort) return

      const filters = queryString.parse(window.location.search, {
        arrayFormat: "comma",
      })

      if (filters.filter === tab || filters) {
        updateSearch({ search: value })
      }
    },
    1000
  )

  const onChangeSearchInput = async (event: ChangeEvent<HTMLInputElement>) => {
    const emptyInput = event.target.value === ""

    if (event.type === "blur") {
      return
    }

    if (emptyInput) {
      updateSearch({ search: undefined })
    }

    const tab =
      param === ItemTypesEnum.combos
        ? ItemTypesEnum.combos
        : ItemTypesEnum.products
    debouncedSearch(event.target.value, tab, emptyInput)
  }

  const onInputSubmit = () => {
    updateSearch({ search })
  }

  return (
    <StyledSearchWrapper
      display="flex"
      alignItems="center"
      className={classnames({ closed: collapsed, open: !collapsed })}
    >
      <Icon
        cursor="pointer"
        classes="search_icon"
        remixiconClass="ri-search-line"
        size={16}
        onClick={onIconToggle}
      />

      <StyledSearchInput
        placeholder={intl.formatMessage({
          id: "components.search.input.placeholder",
          defaultMessage: "Search",
        })}
        ref={inputRef}
        defaultValue={search}
        onChange={onChangeSearchInput}
        onSubmit={onInputSubmit}
        clearIcon={!!search}
        searchIcon
        suffix={
          !search && (
            <StyledSearchClose
              remixiconClass="ri-close-circle-line"
              size={16}
              color="Neutral5"
              cursor="pointer"
              onClick={onIconToggle}
            />
          )
        }
      />
    </StyledSearchWrapper>
  )
}

const StyledSearchWrapper = styled(Container)`
  ${({ theme }) => css`
    overflow: hidden;
    color: ${theme.colors["Neutral5"]};
    padding-right: 16px;

    &.open {
      .search_icon {
        display: none;
      }
    }

    &.closed {
      .search__icon {
        display: none;
      }

      .ant-input {
        width: 0;
        padding: 0;
      }

      .close__icon {
        display: none;
      }

      .ant-input-suffix {
        display: none;
      }

      .input-inner-wrapper {
        border: none;
        padding: 0;
      }
    }
  `}
`

const StyledSearchInput = styled(Input)`
  ${({ theme }) => css`
    caret-color: ${theme.colors["Neutral6"]};
    align-self: center;
    overflow: hidden;
    display: contents;

    .input-inner-wrapper {
      border: 1px solid ${theme.colors["Neutral4"]};
      background-color: ${theme.colors["Neutral1"]};
      padding-left: 16px;
      height: 28px;
    }

    .ant-input-affix-wrapper {
      display: contents;
    }

    .ant-input-affix-wrapper::before {
      padding-left: 16px;
      height: 28px;
    }

    .ant-input {
      transition: all 0.5s ease;
      padding: 4px 14px;
      font-size: 15px;
    }

    .ant-input-suffix {
      margin: 4px 7px;
      font-size: 15px;
      padding: 0;
      span {
        color: ${theme.colors["Neutral5"]};
      }
    }

    .search__icon {
      position: relative;
    }

    .ri-search-line {
      position: absolute;
      left: -8px;
    }
  `}
`

const StyledSearchClose = styled(Icon)`
  cursor: pointer;
`
