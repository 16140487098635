import PageHeader from "../../../PageHeader"
import { Layout } from "antd"
import React from "react"
import styled, { css } from "styled-components"

interface IMainProps {
  header?: React.ReactNode
}

export const Content: React.FC<IMainProps> = ({ header, children }) => {
  return (
    <StyledMainContentContainer>
      {header && <PageHeader>{header}</PageHeader>}
      <Layout.Content id="main-layout-content" className="main-layout">
        {children}
      </Layout.Content>
    </StyledMainContentContainer>
  )
}

const StyledMainContentContainer = styled(Layout)`
  ${({ theme }) => css`
    background-color: ${theme.colors["Neutral1"]};
  `}

  flex: 1;
  display: flex;
  flex-direction: column;

  header {
    display: flex;
  }

  .main-layout {
    overflow-x: auto;
  }
`

export const StyledPageLayout = styled.div`
  padding: 36px;
  overflow-x: hidden;
  max-height: 95vh;
  overflow-y: auto;
`
