import type { OrderMethodEnum } from "../../graphql/generated/types"
import query from "query-string"

const getQrCodePath = (
  urlIdentifier: string,
  data: {
    location: string
    orderMethod?: `${OrderMethodEnum}`
    tableUUID?: string
  }
) => {
  const { tableUUID, ...rest } = data

  return `${
    process.env.REACT_APP_STOREFRONT_URL
  }/${urlIdentifier}/createOrder?${query.stringify(rest)}&tableID=${tableUUID}`
}

export default getQrCodePath
