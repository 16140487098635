import { device } from "../../shared/breakpoints/responsive"
import "./fonts/dmsans/stylesheet.css"
import "./fonts/raleway/stylesheet.css"
import { colors, shadows } from "./themes/mainTheme"
import "antd/dist/antd.css"
import "remixicon/fonts/remixicon.css"
import { createGlobalStyle, css } from "styled-components"

const generic = css`
  html,
  body,
  #root,
  .app {
    font-family: "DM Sans", sans-serif;
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    letter-spacing: 0.25px;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  * {
    box-sizing: border-box;
  }
  :root {
    --ag-header-cell-hover-background-color: ${colors["Neutral2"]};
    --ag-subheader-background-color: ${colors["Neutral3"]};
    --ag-selected-tab-underline-color: ${colors["Primary4"]};
    --ag-row-hover-color: ${colors["Neutral2"]};
    --ag-foreground-color: ${colors["Neutral8"]};
    --ag-header-foreground-color: ${colors["Neutral8"]};
    --ag-data-color: ${colors["Neutral8"]};
    --ag-row-border-color: ${colors["Neutral3"]};
    --ag-border-color: ${colors["Neutral3"]};
    --ag-secondary-foreground-color: ${colors["Neutral5"]};
    --ag-checkbox-checked-color: ${colors["Primary5"]};
    --ag-checkbox-unchecked-color: ${colors["Neutral4"]};
    --ag-material-primary-color: ${colors["Primary5"]};
    --ag-range-selection-border-color: ${colors["Neutral2"]};
    --ag-range-selection-background-color-1: ${colors["Neutral2"]};
  }
`

const modalStyles = css`
  .ant-modal-wrap {
    margin-left: 210px;
    right: 0;
  }

  .non__full__modal__wrap {
    margin-left: 0px;
  }

  .modal-full-wrap {
    overflow: hidden;
  }

  .order-detail-modal-wrapper {
    .ant-modal-header {
      padding: 0px;
    }
  }

  .order-detail-modal-wrapper,
  .create-order-modal-wrapper {
    .ant-modal-body {
      margin: 0px;
      padding: 0px;
      display: flex;
      width: 100%;
      overflow: hidden;
      max-height: calc(100vh - 76px);
    }
  }

  .custom-modal-container {
    .ant-modal-content {
      border-radius: 4px;
    }

    .ant-modal-body {
      padding: 0;
      margin: 0;
    }
  }

  .modifier-search-dropdown {
    position: fixed !important;
    z-index: 10000;
  }

  .customize-product-modal-wrapper {
    .ant-modal-body {
      margin: 0px;
      padding: 0px;
      min-height: 500px;
    }

    .ant-modal-close {
      top: 10px !important;
      right: 16px !important;
    }
  }
`

const customClasses = css`
  .pointer {
    cursor: pointer;
  }
  .ellipsis {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .ant-notification-notice-close {
    top: 20px;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .hidden {
    display: none !important;
  }

  .readonly {
    pointer-events: none !important;
    opacity: 0.8;
  }

  .spinning {
    animation: spin-animation 500ms infinite;
    animation-timing-function: linear;
    display: inline-block;
  }

  .styled-data-table-row {
    position: relative;

    .dropdown-actions {
      visibility: hidden;
    }

    :hover {
      .dropdown-actions {
        visibility: visible;
      }
    }

    ${({ theme }) =>
      css`
        border-bottom: 1px solid ${theme.colors.Neutral3};
        background: ${theme.colors.Neutral0};
      `}
  }

  @keyframes spin-animation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`

const timePicker = css`
  .time-picker-footer-hidden .ant-picker-footer {
    display: none;
  }

  .ant-picker-time-panel-column
    > li.ant-picker-time-panel-cell-selected
    .ant-picker-time-panel-cell-inner {
    background: ${colors["Primary2"]};
  }

  .ant-picker-time-panel-column
    > li.ant-picker-time-panel-cell
    .ant-picker-time-panel-cell-inner:hover {
    background: ${colors["Neutral2"]};
  }
`

const print = css`
  @media all {
    .page-break {
      display: none;
    }

    .new-page {
      page-break-before: always;
      display: flex;
      flex-direction: column;
    }

    body {
      overflow: visible;
      display: initial;
    }
  }

  @media print {
    html,
    body {
      height: initial !important;
      overflow: initial !important;
      -webkit-print-color-adjust: exact;
    }
  }

  @media print {
    .page-break {
      margin-top: 1rem;
      display: block;
      page-break-before: auto;
    }
  }

  @page {
    size: auto;
    margin: 20mm;
  }
`

const imageCropperStyle = css`
  .img-crop-modal {
    .ant-modal-body {
      .ant-slider {
        .ant-slider-track {
          background-color: ${colors["Secondary5"]};
        }
        .ant-slider-handle {
          border: solid 2px ${colors["Secondary5"]};
          :focus {
            box-shadow: ${shadows.elevationLow};
          }
        }
      }
    }

    .ant-modal-footer {
      .ant-btn {
        background: none;
        border-radius: 4px;
        color: ${colors["Primary5"]};
        border-color: ${colors["Primary5"]};
      }

      .ant-btn:hover {
        border-color: ${colors["Primary4"]};
        color: ${colors["Primary4"]};
      }

      .ant-btn.ant-btn-primary {
        border: none;
        color: ${colors["Neutral1"]};
        background-color: ${colors["Primary6"]};
      }

      .ant-btn.ant-btn-primary:hover {
        background-color: ${colors["Primary4"]};
      }
    }
  }
`

const responsiveStyle = css`
  @media ${device.xl} {
    .mobile-app-styled-avatar-wrapper {
      flex-direction: row;
    }
    .mobile-app-avatar-wrapper {
      flex-direction: column;
      align-items: center;
    }
  }

  .detail-page {
    padding: 64px 32px;
  }

  @media ${device.lg} {
    .mobile-app-styled-avatar-wrapper {
      flex-direction: column;
    }
    .modal-full .ant-modal-body {
      margin: 0 !important;
      padding: 64px 48px;
    }
  }

  @media ${device.md} {
    .create-location-wrapper,
    .create-restaurant-wrapper {
      padding: 40px 0;
    }

    .ant-modal-wrap {
      margin-left: 0;
    }
    .modal-full .ant-modal-content {
      min-width: 375px !important;
    }

    .full-modal-wrap .ant-modal {
      width: calc(100% - 16px) !important;
    }
    .modifier-collapse {
      min-width: auto;
    }
    #logo-wrapper {
      position: relative;
    }
    #sider {
      position: absolute;
      z-index: 5;
    }
    #sidebar__menu {
      margin-top: 0;
    }
    .remove-row-button > .display-on-hover {
      display: initial !important;
    }
    .reports-header-title {
      padding-right: 8px;
    }
    .reports-header-calendar {
      min-width: 208px;
    }
    .mobile-app-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      .data-container {
        height: 100%;
      }
      .mobile-app-preview-wrapper {
        border-top: ${colors.Neutral3} 1px solid;
        height: 100%;
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: center;
        flex-basis: 56px;
        margin: 0;
        padding: 0;
      }
    }
    .mobile-app-styled-avatar-wrapper {
      flex-direction: column;
    }
    .mobile-app-avatar-wrapper {
      flex-direction: row;
    }
    .modal-full .ant-modal-body {
      padding: 64px 48px;
    }
  }

  @media ${device.sm} {
    .form-button {
      width: 300px;
    }

    .detail-page {
      overflow: hidden !important;
    }

    .page-header {
      height: auto;
      vertical-align: center;
      .header-wrapper {
        margin: 8px 0 8px 0;
      }
    }
    .open .ant-input {
      width: 128px !important;
    }
    .reports-header-calendar {
      justify-content: center;
    }
    .header-left-wrapper {
      margin: 8px 0 8px 0;
      display: grid;
      grid-template-columns: auto;
      grid-template-rows: 24px 32px;
      justify-content: center;
      align-items: center;
      .header-title {
        grid-column: 1;
        grid-row: 1;
      }
      .reports-header-calendar {
        grid-column: 1;
        grid-row: 2;
        margin: auto;
      }
    }

    .header-wrapper {
      display: grid;
      gap: 2px;
      grid-template-columns: auto;
      grid-template-rows: 50% 50%;
      justify-content: center;
      align-items: center;
      .header-extra-wrapper {
        grid-column: 1;
        grid-row: 1;
        margin: auto;
      }
      .header-right-wrapper {
        grid-column: 1;
        grid-row: 2;
        margin: auto;
      }
    }
    .mobile-app-avatar-wrapper {
      flex-direction: column;
    }
  }

  @media ${device.xs} {
    .detail-page {
      padding: 16px 8px !important;
    }
    .onboarding-children-wrapper {
      margin: 0;
    }
    form .bg {
      min-width: unset !important;
    }
    .create-location-wrapper,
    .create-restaurant-wrapper {
      padding: 16px 0;
    }
  }
`

const popover = css`
  .popover-custom {
    box-shadow: 0px 4px 8px 1px rgba(78, 75, 102, 0.16);
    padding-top: 0;
    border: 1px solid ${colors.Neutral4};

    z-index: 999999;
    pointer-events: all;

    .ant-popover-arrow-content {
      width: 0;
      height: 0;
    }

    .ant-popover-inner-content {
      padding: 0;
    }

    .ant-popover-inner {
      box-shadow: none;
    }
  }
`

const GlobalStyle = createGlobalStyle`
${generic}

${modalStyles}

${customClasses}

${timePicker}

${print}

${imageCropperStyle}

${responsiveStyle}

${popover}
`

export default GlobalStyle
