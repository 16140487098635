/* eslint-disable unicorn/no-thenable */
import { DeliveryIntegrationEnum } from "../../../../../shared/graphql/generated/types"
import yup from "../../../../../shared/yup"
import { yupResolver } from "@hookform/resolvers/yup"

export const DeliveryIntegrationResolver = yupResolver(
  yup.object().shape({
    integrationName: yup.string().required(),
    apiKey: yup.string().required(),
    integrationLabel: yup.string().required(),
    developerId: yup.string().when("integrationName", {
      is: DeliveryIntegrationEnum.DOORDASH,
      then: yup.string().required(),
    }),
    signingSecret: yup.string().when("integrationName", {
      is: DeliveryIntegrationEnum.DOORDASH,
      then: yup.string().required(),
    }),
  })
)
