import * as Types from '../../../../../shared/graphql/generated/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpsertEmployeeMutationVariables = Types.Exact<{
  input: Types.UpsertEmployeeInput;
}>;


export type UpsertEmployeeMutation = { __typename?: 'Mutation', upsertEmployee: { __typename?: 'Employee', uuid: string, firstName: string, lastName: string, phone: string, payRate?: number | null, deactivatedAt?: any | null, code: string, allowTip: boolean, role: { __typename?: 'RoleInfo', uuid: string, roleCode: Types.EmployeeRoleCodeEnum, name: string }, attachment?: { __typename?: 'AttachmentModel', uuid: string, signedUrl: string } | null } };


export const UpsertEmployeeDocument = gql`
    mutation UpsertEmployee($input: UpsertEmployeeInput!) {
  upsertEmployee(input: $input) {
    uuid
    firstName
    lastName
    phone
    payRate
    deactivatedAt
    code
    allowTip
    role {
      uuid
      roleCode
      name
    }
    attachment {
      uuid
      signedUrl
    }
  }
}
    `;
export type UpsertEmployeeMutationFn = Apollo.MutationFunction<UpsertEmployeeMutation, UpsertEmployeeMutationVariables>;

/**
 * __useUpsertEmployeeMutation__
 *
 * To run a mutation, you first call `useUpsertEmployeeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertEmployeeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertEmployeeMutation, { data, loading, error }] = useUpsertEmployeeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertEmployeeMutation(baseOptions?: Apollo.MutationHookOptions<UpsertEmployeeMutation, UpsertEmployeeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertEmployeeMutation, UpsertEmployeeMutationVariables>(UpsertEmployeeDocument, options);
      }
export type UpsertEmployeeMutationHookResult = ReturnType<typeof useUpsertEmployeeMutation>;
export type UpsertEmployeeMutationResult = Apollo.MutationResult<UpsertEmployeeMutation>;
export type UpsertEmployeeMutationOptions = Apollo.BaseMutationOptions<UpsertEmployeeMutation, UpsertEmployeeMutationVariables>;