import * as Types from '../../../../../shared/graphql/generated/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AddRelationBankingAccountMutationVariables = Types.Exact<{
  data: Types.AddRelationBankingAccountInput;
}>;


export type AddRelationBankingAccountMutation = { __typename?: 'Mutation', addRelationBankingAccount: { __typename?: 'LocationWithBankingAccountModel', uuid: string, name: string, bankingAccount: { __typename?: 'BankAccountBasicModel', uuid: string, name: string, stIsConnected: boolean } } };


export const AddRelationBankingAccountDocument = gql`
    mutation addRelationBankingAccount($data: AddRelationBankingAccountInput!) {
  addRelationBankingAccount(data: $data) {
    uuid
    name
    bankingAccount {
      uuid
      name
      stIsConnected
    }
  }
}
    `;
export type AddRelationBankingAccountMutationFn = Apollo.MutationFunction<AddRelationBankingAccountMutation, AddRelationBankingAccountMutationVariables>;

/**
 * __useAddRelationBankingAccountMutation__
 *
 * To run a mutation, you first call `useAddRelationBankingAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddRelationBankingAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addRelationBankingAccountMutation, { data, loading, error }] = useAddRelationBankingAccountMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAddRelationBankingAccountMutation(baseOptions?: Apollo.MutationHookOptions<AddRelationBankingAccountMutation, AddRelationBankingAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddRelationBankingAccountMutation, AddRelationBankingAccountMutationVariables>(AddRelationBankingAccountDocument, options);
      }
export type AddRelationBankingAccountMutationHookResult = ReturnType<typeof useAddRelationBankingAccountMutation>;
export type AddRelationBankingAccountMutationResult = Apollo.MutationResult<AddRelationBankingAccountMutation>;
export type AddRelationBankingAccountMutationOptions = Apollo.BaseMutationOptions<AddRelationBankingAccountMutation, AddRelationBankingAccountMutationVariables>;