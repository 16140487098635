import Container from "../../../../ui/Container"
import DetailPage from "../../../../ui/Layouts/MainLayout/DetailPage"
import PageTitle from "../../../../ui/PageTitle"
import LocationDeliveryIntegrations from "./LocationDeliveryIntegrations"
import LocationDeliveryZones from "./LocationDeliveryZones"
import React from "react"
import { useIntl } from "react-intl"

const LocationDelivery: React.FC = () => {
  const intl = useIntl()

  return (
    <DetailPage role="content-layout">
      <PageTitle
        title={intl.formatMessage({
          id: "settings.locations.delivery.title",
          defaultMessage: "Location Delivery Settings",
        })}
        description={intl.formatMessage({
          id: "settings.locations.delivery.description",
          defaultMessage:
            "Set the fee and the delivery providers you want to connect to this location.",
        })}
        border
      />

      <Container display="flex" flexDirection="column" gap="52px">
        <LocationDeliveryIntegrations />

        <LocationDeliveryZones />
      </Container>
    </DetailPage>
  )
}

export default LocationDelivery
