import {
  MM_DD_YYYY,
  hh_mm_a,
} from "../../../../../../shared/utils/constant/dateFormats"
import PublishingStatusLabel from "../PublishingStatusLabel"
import type { IPublishingModel } from "../interfaces/publishing.interfaces"
import moment from "moment-timezone"

const tableColumns = <T extends IPublishingModel>() => {
  const columns = [
    {
      dataIndex: "version",
      title:
        "settings.white.label.application.publishing.data.table.header.version",
      width: "20%",
    },
    {
      dataIndex: "dateTime",
      title:
        "settings.white.label.application.publishing.data.table.header.date.and.time",
      width: "30%",
      render: (text: string) => {
        return `${moment(text).format(MM_DD_YYYY)} - ${moment(text).format(
          hh_mm_a
        )}`
      },
    },
    {
      dataIndex: "platform",
      title:
        "settings.white.label.application.publishing.data.table.header.platform",
      width: "22%",
    },
    {
      dataIndex: "status",
      title:
        "settings.white.label.application.publishing.data.table.header.status",
      width: "28%",
      render: (_: string, record: T) => {
        return <PublishingStatusLabel status={record.status} />
      },
    },
  ]

  return [columns]
}

export default tableColumns
