import { colors, text } from "../../../styles/global/themes/mainTheme"
import { CardElement } from "@stripe/react-stripe-js"
import type { StripeCardElementChangeEvent } from "@stripe/stripe-js"
import { useState } from "react"
import styled, { css } from "styled-components"

export interface ICardError {
  type: string
  code: string
  message: string
}

export const defaultInputOptions = {
  style: {
    base: {
      color: colors["Neutral9"],
      fontSmoothing: "antialiased",
      fontSize: `${text.body.l.regular.fontSize}px`,
      "::placeholder": {
        color: colors["Neutral6"],
      },
    },
    invalid: {
      color: colors["Danger5"],
      iconColor: colors["Danger5"],
    },
  },
}

export const CardNumberInput = () => {
  const [cardError, setCardError] = useState<ICardError | null | undefined>()

  const onCardElementChange = (data: StripeCardElementChangeEvent) => {
    setCardError(data.error)
  }

  return (
    <StyledCardElementWrapper hasError={!!cardError}>
      <CardElement
        options={defaultInputOptions}
        onChange={onCardElementChange}
      />
    </StyledCardElementWrapper>
  )
}

const StyledCardElementWrapper = styled.div<{ hasError?: boolean }>`
  ${({ theme, hasError }) => css`
    padding: 8px;
    border-radius: 4px;
    border: 1px solid ${theme.colors["Neutral4"]};

    &:hover,
    &:focus {
      border: 1px solid ${theme.colors["Primary4"]};
      box-shadow: ${theme.shadows.elevationMedium};
    }

    ${hasError &&
    css`
      border: 1px solid ${theme.colors["Danger5"]};

      &:hover,
      &:active,
      &:focus {
        border-color: ${theme.colors["Danger5"]};
        box-shadow: none;
      }
    `}
  `}
`
