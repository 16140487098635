import Button from "../Button"
import type { ButtonProps } from "../Button/Button"

export const AddButton = (props: ButtonProps) => {
  return (
    <Button
      {...props}
      hierarchy="secondary"
      shape="square"
      leadingIcon="ri-add-line"
    />
  )
}
