import Dinero from "dinero.js"
import isNumber from "lodash/isNumber"

const format = "$0,0.00"
const currency = "USD"

const formatAmount = (amount: number): string => {
  return Dinero({ amount, currency }).toFormat(format)
}

export const toFormat = (amount: number): string => {
  const cents: number = amount * 100

  if (!isNumber(cents) || Number.isNaN(amount)) {
    return formatAmount(0)
  }

  return formatAmount(Number(cents.toFixed(2)))
}
