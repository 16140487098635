import { OrderStatusEnum } from "../graphql/generated/types"

export const CustomOrderStatusTitle: Record<
  OrderStatusEnum,
  { id: string; defaultMessage: string }
> = {
  CANCELLED: {
    id: "restaurants.orders.order.status.cancelled",
    defaultMessage: "Cancelled",
  },
  CHECK_OUT: {
    id: "restaurants.orders.order.status.checkout",
    defaultMessage: "Checkout",
  },
  CLOSED: {
    id: "restaurants.orders.order.status.closed",
    defaultMessage: "Closed",
  },
  COMPLETED: {
    id: "restaurants.orders.order.status.completed",
    defaultMessage: "Completed",
  },
  OPEN: { id: "restaurants.orders.order.status.open", defaultMessage: "Open" },
  PAYMENT_PENDING: {
    id: "restaurants.orders.order.status.payment.pending",
    defaultMessage: "Payment Pending",
  },
  REFUNDED: {
    id: "restaurants.orders.order.status.refunded",
    defaultMessage: "Refunded",
  },
  PENDING: {
    id: "restaurants.orders.order.status.pending",
    defaultMessage: "Pending (KDS)",
  },
  PREPARING: {
    id: "restaurants.orders.order.status.preparing",
    defaultMessage: "Preparing",
  },
  READY: {
    id: "restaurants.orders.order.status.ready",
    defaultMessage: "Ready",
  },
  DELIVERY_FAILED: {
    id: "restaurants.orders.order.status.delivery.failed",
    defaultMessage: "Delivery Failed",
  },
  PICKED_UP: {
    id: "restaurants.orders.order.status.picked.up",
    defaultMessage: "Delivery Picked Up",
  },
  IN_REVISION: {
    id: "restaurants.orders.order.status.in.revision",
    defaultMessage: "In Revision",
  },
  PENDING_REASSIGN: {
    id: "restaurants.orders.order.status.pending.reassign",
    defaultMessage: "Pending Reassign",
  },
  PLACED: {
    id: "restaurants.orders.order.status.placed",
    defaultMessage: "Placed",
  },
}
