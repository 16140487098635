import { useGeneralContext } from "../../shared/contexts/StoreProvider"
import React from "react"

export type AllowedRoles =
  | "RESTAURANT_ADMIN"
  | "RESTAURANT_EMPLOYEE"
  | "RESTAURANT_MANAGER"
  | "RESTAURANT_OWNER"
  | "SUPER_ADMIN"

interface IRoleViewProps {
  allowed: AllowedRoles[]
  extra?: string | React.ReactNode
}

export const RoleView: React.FC<IRoleViewProps> = ({
  allowed,
  extra,
  children,
}) => {
  const {
    state: {
      auth: {
        admin: { role },
      },
      currentRestaurantUserRole,
    },
  } = useGeneralContext()

  const ownerRole = role?.name
  const userRole = currentRestaurantUserRole?.name

  const isIncluded = allowed.find(
    (allow) => userRole === allow || allow === ownerRole
  )

  if ((!userRole || !ownerRole) && !isIncluded) return <> {extra} </>

  return <>{children}</>
}
