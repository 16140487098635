import type { IAddress } from "../../../../../../components/AutocompleteAddress"
import AutocompleteAddress from "../../../../../../components/AutocompleteAddress"
import LocationMap from "../../../../../../components/LocationMap/LocationMap"
import { GoogleMapsProvider } from "../../../../../../shared/contexts/GoogleMapsProvider"
import { OrderMethodEnum } from "../../../../../../shared/graphql/generated/types"
import paths from "../../../../../../shared/routes/paths"
import { validationsTitle } from "../../../../../../shared/titles/validations.title"
import { CustomOrderMethodTitle } from "../../../../../../shared/utils/constant/orderMethod.title"
import { EMPTY_STRING } from "../../../../../../shared/utils/constant/values"
import formatPhoneNumber from "../../../../../../shared/utils/helpers/formatPhoneNumber"
import Button from "../../../../../../ui/Button"
import { CheckboxGroup } from "../../../../../../ui/Checkbox"
import Container from "../../../../../../ui/Container"
import InputLabel from "../../../../../../ui/InputLabel"
import Input from "../../../../../../ui/Inputs"
import PageTitle from "../../../../../../ui/PageTitle"
import Select from "../../../../../../ui/Select"
import Spacer from "../../../../../../ui/Spacer"
import HighlightedText from "../../../../../../ui/Typography/HighlightedText"
import Text from "../../../../../../ui/Typography/Text"
import BusinessHoursRow from "../../../HoursOfOperation/BusinessHours/BusinessHoursRow"
import { preparationTimeOptions } from "../../../utils/constants"
import type { ILocationForm } from "./hookforms.interfaces"
import { useFlags } from "launchdarkly-react-client-sdk"
import get from "lodash/get"
import React from "react"
import { Controller, useFormContext, useWatch } from "react-hook-form"
import { useIntl } from "react-intl"
import { useHistory } from "react-router"
import styled from "styled-components"

type LocationEditFormProps = Omit<React.ComponentProps<"form">, "ref"> & {
  onSubmit: (event: React.FormEvent<HTMLFormElement>) => void
  saving: boolean
}

export const LocationForm: React.FC<LocationEditFormProps> = ({
  onSubmit,
  saving,
  ...props
}) => {
  const intl = useIntl()
  const { push } = useHistory()
  const { mapWithPin, selectPrepTimeEnable } = useFlags()

  const {
    setValue,
    control,
    trigger,
    watch,
    formState: { errors, isValid, isDirty },
  } = useFormContext<ILocationForm>()

  const goToDeliverySettings = () => {
    push(paths.settings.restaurant.delivery)
  }

  const [selectedOrderTypes, latitude, longitude] = useWatch({
    name: ["orderTypes", "latitude", "longitude"],
    control,
  })

  const deliveryTypeExtra = (orderTypeItem: OrderMethodEnum) =>
    selectedOrderTypes?.includes(orderTypeItem) &&
    orderTypeItem === "DELIVERY" && (
      <>
        {
          //TODO: Replace with LinkButton
        }
        <HighlightedText cursor="pointer" onClick={goToDeliverySettings}>
          {intl.formatMessage({
            id: "restaurants.location.form.add.delivery.integration",
            defaultMessage: "Add delivery integration",
          })}
        </HighlightedText>
      </>
    )

  const orderTypeList = (
    Object.keys(OrderMethodEnum) as Array<OrderMethodEnum>
  ).map((orderTypeItem) => ({
    id: orderTypeItem,
    value: orderTypeItem,
    label: intl.formatMessage(CustomOrderMethodTitle[orderTypeItem]),
    extra: deliveryTypeExtra(orderTypeItem),
    className: "capitalized-option",
  }))

  // Filtering order types based on a validation to hide the delivery option
  const orderTypeListFiltered =
    process.env.REACT_APP_ENABLE_DELIVERY?.toLowerCase() === "false"
      ? orderTypeList.filter(
          (orderType) => orderType.value !== OrderMethodEnum.DELIVERY
        )
      : orderTypeList

  const onChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue("name", event.target.value)
  }

  const onAddressChange = (data: IAddress) => {
    setValue("address", data.address || "")
    setValue("latitude", data.latitude)
    setValue("longitude", data.longitude)
    setValue("addressComponents", data.addressComponents)
  }

  const validateAddress = () => {
    trigger(["address", "addressComponents"])
  }

  const weekDays = watch("schedules") ?? []

  const setLatitude = (lat: number) =>
    setValue("latitude", `${lat}`, { shouldTouch: true })

  const setLongitude = (lng: number) =>
    setValue("longitude", `${lng}`, { shouldTouch: true })

  return (
    <div role="location-form">
      <PageTitle
        title={intl.formatMessage({
          id: "restaurants.locations.overview.head.label",
          defaultMessage: "Location Info",
        })}
        description={intl.formatMessage({
          id: "restaurants.locations.overview.edit.description.label",
          defaultMessage: "Manage your Location settings",
        })}
      />
      <StyledContentLocation>
        <form
          className="location-edit-form"
          role="form"
          aria-label="location-edit-form"
          onSubmit={onSubmit}
          {...props}
        >
          <InputLabel
            label={intl.formatMessage({
              id: "restaurants.locations.overview.order.type.label",
              defaultMessage: "Location Order Type",
            })}
            requirement="required"
          />
          <Container display="flex" gap="8px" flexDirection="column">
            <Text size="s" color="Neutral5">
              {intl.formatMessage({
                id: "restaurants.locations.overview.order.type.description",
                defaultMessage:
                  "Select the type of orders that this location can support",
              })}
            </Text>
            <Controller
              control={control}
              name="orderTypes"
              render={({ field: locationsField }) => (
                <CheckboxGroup
                  {...locationsField}
                  classId="order-type-checkbox-group"
                  flexFlow="column"
                  aria-label="order-type"
                  options={orderTypeListFiltered}
                  hasError={!!errors.orderTypes}
                  helperText={get(errors, "orderTypes.message")}
                />
              )}
            />
            <Spacer size={8} />
            {selectPrepTimeEnable && (
              <Controller
                name="estimatedPreparationTime"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    label={intl.formatMessage({
                      id: "restaurants.locations.estimated.prep.time.label",
                      defaultMessage: "Estimated preparation time in minutes",
                    })}
                    options={preparationTimeOptions}
                    hasError={!!errors.estimatedPreparationTime}
                    helperText={errors.estimatedPreparationTime?.message}
                    aria-label="preparation-time"
                  />
                )}
              />
            )}
          </Container>

          <Spacer size={68} />
          <Controller
            name="name"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                requirement="required"
                label={intl.formatMessage({
                  id: "restaurants.locations.overview.name.label",
                  defaultMessage: "Location Name",
                })}
                onChange={onChangeName}
                hasError={!!errors.name}
                helperText={errors.name?.message}
                aria-label="name"
                capitalizeFirstLetter={false}
              />
            )}
          />

          <Spacer size={24} />

          <GoogleMapsProvider>
            {mapWithPin && (
              <>
                <LocationMap
                  coordinatesSetters={{ setLatitude, setLongitude }}
                  position={{ lat: latitude, lng: longitude }}
                  validateAddress={() => trigger("address")}
                />
                <Spacer size={16} />
              </>
            )}
            <Controller
              name="address"
              control={control}
              render={({ field }) => (
                <AutocompleteAddress
                  name="address"
                  inputProps={{
                    ...field,
                    name: "address",
                    label: intl.formatMessage({
                      id: "restaurants.locations.overview.address.label",
                      defaultMessage: "Location Address",
                    }),
                    requirement: "required",
                    placeholder: intl.formatMessage({
                      id: "restaurants.location.form.address.placeholder",
                      defaultMessage: "Location Address",
                    }),
                    hasError:
                      !!errors.address ||
                      !!errors.latitude ||
                      !!errors.longitude,
                    helperText:
                      errors.address?.message ??
                      errors.latitude?.message ??
                      errors.longitude?.message,
                  }}
                  error={errors.address}
                  onSelect={onAddressChange}
                  validateAddress={validateAddress}
                />
              )}
            />
          </GoogleMapsProvider>

          <Spacer size={24} />

          <Controller
            control={control}
            name="phone"
            render={({ field: phoneNumberField }) => (
              <Input
                {...phoneNumberField}
                label={intl.formatMessage({
                  id: "restaurants.locations.overview.phone.number.label",
                  defaultMessage: "Location Phone Number",
                })}
                requirement="required"
                aria-label="phone-number"
                value={formatPhoneNumber(phoneNumberField.value)}
                hasError={!!errors?.phone}
                helperText={
                  errors?.phone?.message
                    ? intl.formatMessage(validationsTitle[errors.phone.message])
                    : EMPTY_STRING
                }
                prefix="+"
                onlyInteger
              />
            )}
          />

          <StyledContentLocationOperation>
            <Text size="m" weight="bold">
              {intl.formatMessage({
                id: "restaurants.locations.overview.schedule.label",
                defaultMessage: "Hours of Operation",
              })}
            </Text>
            <Spacer size={26} />

            <Container>
              {weekDays?.map(({ day, defaultValues }, index) => {
                return (
                  <BusinessHoursRow
                    key={index}
                    dayIndex={index}
                    day={day}
                    defaultValues={defaultValues}
                  />
                )
              })}
            </Container>
          </StyledContentLocationOperation>
          <StyledContentButtonEdit>
            <Button
              type="submit"
              aria-label="save"
              title={intl.formatMessage({
                id: "restaurants.locations.overview.edit.button.save",
                defaultMessage: "Save Information",
              })}
              loading={saving}
              disabled={!isDirty || saving || !isValid}
            />
          </StyledContentButtonEdit>
        </form>
      </StyledContentLocation>
    </div>
  )
}

const StyledContentLocation = styled.div`
  margin-top: 48px;
`

const StyledContentLocationOperation = styled.div`
  margin-top: 68px;
`

const StyledContentButtonEdit = styled.div`
  margin-top: 64px;
`
