import Container from "../Container"
import Icon from "../Icon"
import { Checkbox as AntdCheckbox } from "antd"
import type { CheckboxProps as AntdCheckboxProps } from "antd"
import classnames from "classnames"
import type { ReactNode, RefObject } from "react"
import React from "react"
import styled, { css } from "styled-components"

export type CheckboxProps = AntdCheckboxProps & {
  classId: string
  remixIconClass?: string
  content?: string | ReactNode
  extra?: string | ReactNode
  settingsIcon?: ReactNode
}

// eslint-disable-next-line react/display-name
export const Checkbox = React.forwardRef(
  (
    checkboxProps: CheckboxProps,
    ref:
      | ((instance: HTMLParagraphElement | null) => void)
      | RefObject<HTMLParagraphElement>
      | null
      | undefined
  ) => {
    const {
      remixIconClass,
      content,
      classId,
      className,
      disabled,
      settingsIcon,
      extra,
      ...props
    } = checkboxProps

    return (
      <MainWrapper display="flex" alignItems="center">
        <StyledCheckbox
          className={classnames(className, classId)}
          $classId={classId}
          disabled={disabled}
          {...props}
        >
          {(remixIconClass || content) && (
            <Container display="flex" alignItems="center" gap="8px">
              {remixIconClass ? (
                <Icon
                  remixiconClass={remixIconClass}
                  size={16}
                  color="Neutral6"
                />
              ) : null}
              {content ? (
                <StyledCheckboxText ref={ref}>{content}</StyledCheckboxText>
              ) : null}
            </Container>
          )}
        </StyledCheckbox>
        {settingsIcon && (
          <SettingsIconContainer>{settingsIcon}</SettingsIconContainer>
        )}
        {extra || null}
      </MainWrapper>
    )
  }
)

interface StyledCheckboxProps {
  $classId: string
  remixIconClass?: string
  content?: string | ReactNode
}
const SettingsIconContainer = styled(Container)`
  display: none;
  margin-right: 8px;
`

const MainWrapper = styled(Container)`
  &:hover ${SettingsIconContainer} {
    display: flex;
    align-items: center;
  }
`

const StyledCheckbox = styled(AntdCheckbox)<StyledCheckboxProps>`
  ${({ theme, $classId, disabled, remixIconClass, content }) => css`
    &.${$classId} {
      &.ant-checkbox-wrapper {
        margin-left: 0;
        height: 24px;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 8px;
        p {
          margin: 0;
          ${disabled &&
          css`
            color: ${theme.colors["Neutral5"]};
          `};
        }

        &:hover {
          .ant-checkbox-inner,
          .ant-typography,
          p {
            color: ${disabled
              ? theme.colors["Neutral5"]
              : theme.colors["Secondary5"]};
          }

          .ant-checkbox-inner {
            border-color: ${disabled
              ? theme.colors["Neutral5"]
              : theme.colors["Secondary5"]};
          }
        }

        .ant-checkbox {
          position: sticky;
          margin-top: auto;
          margin-bottom: auto;

          :hover {
            &.ant-checkbox-inner {
              border-color: ${disabled
                ? theme.colors["Neutral5"]
                : theme.colors["Secondary5"]};
              color: ${disabled
                ? theme.colors["Neutral5"]
                : theme.colors["Secondary5"]};
            }
          }
        }

        .ant-checkbox + span {
          ${!remixIconClass &&
          !content &&
          css`
            padding: 0;
          `}
        }

        .ant-checkbox-disabled {
          .ant-checkbox-inner {
            background: ${theme.colors["Neutral1"]};
            border-color: ${theme.colors["Neutral5"]};
          }
        }

        .ant-checkbox-checked {
          .ant-checkbox-inner {
            background: ${disabled
              ? theme.colors["Neutral1"]
              : theme.colors["Secondary5"]};
            border-color: ${disabled
              ? theme.colors["Neutral5"]
              : theme.colors["Secondary5"]};
          }

          ::after {
            border: 1px solid
              ${disabled
                ? theme.colors["Neutral5"]
                : theme.colors["Secondary5"]};
          }
        }

        .ant-checkbox-input:focus + .ant-checkbox-inner {
          border-color: ${disabled
            ? theme.colors["Neutral5"]
            : theme.colors["Secondary5"]};
          color: ${disabled
            ? theme.colors["Neutral5"]
            : theme.colors["Secondary5"]};
        }

        .ant-checkbox-indeterminate .ant-checkbox-inner::after {
          background: ${disabled
            ? theme.colors["Neutral1"]
            : theme.colors["Secondary5"]};
        }
      }
    }
  `}
`

const StyledCheckboxText = styled.p`
  ${({ theme }) =>
    css`
      line-height: 24px;
      font-family: ${theme.text.fonts.dmSansRegular};
      font-size: ${theme.text.body.m.regular.fontSize}px;
      font-weight: ${theme.text.body.m.regular.fontWeight};
      color: ${theme.colors["Neutral8"]};
    `}
`
