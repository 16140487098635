import * as Types from '../../../../../shared/graphql/generated/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetAllDevicesQueryVariables = Types.Exact<{
  restaurantUUID: Types.Scalars['ID'];
  locationUUID?: Types.InputMaybe<Types.Scalars['ID']>;
  after?: Types.InputMaybe<Types.Scalars['String']>;
  take?: Types.InputMaybe<Types.Scalars['Int']>;
  uuid?: Types.InputMaybe<Types.Scalars['ID']>;
  isActive?: Types.InputMaybe<Types.Scalars['Boolean']>;
}>;


export type GetAllDevicesQuery = { __typename?: 'Query', getAllDevices: { __typename?: 'PaginatedDeviceModel', hasNextPage: boolean, endCursor?: string | null, results?: Array<{ __typename?: 'DeviceModel', uuid: string, deviceKey: string, functionType: Types.DeviceFunctionEnum, isActive: boolean, lastChecking?: any | null, status: Types.DeviceStatusEnum, code?: string | null, location?: { __typename?: 'BasicLocationModel', uuid: string, name: string } | null }> | null } };


export const GetAllDevicesDocument = gql`
    query getAllDevices($restaurantUUID: ID!, $locationUUID: ID, $after: String, $take: Int, $uuid: ID, $isActive: Boolean) {
  getAllDevices(
    restaurantUUID: $restaurantUUID
    locationUUID: $locationUUID
    after: $after
    take: $take
    uuid: $uuid
    isActive: $isActive
  ) {
    hasNextPage
    endCursor
    results {
      uuid
      deviceKey
      functionType
      isActive
      lastChecking
      location {
        uuid
        name
      }
      status
      code
    }
  }
}
    `;

/**
 * __useGetAllDevicesQuery__
 *
 * To run a query within a React component, call `useGetAllDevicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllDevicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllDevicesQuery({
 *   variables: {
 *      restaurantUUID: // value for 'restaurantUUID'
 *      locationUUID: // value for 'locationUUID'
 *      after: // value for 'after'
 *      take: // value for 'take'
 *      uuid: // value for 'uuid'
 *      isActive: // value for 'isActive'
 *   },
 * });
 */
export function useGetAllDevicesQuery(baseOptions: Apollo.QueryHookOptions<GetAllDevicesQuery, GetAllDevicesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllDevicesQuery, GetAllDevicesQueryVariables>(GetAllDevicesDocument, options);
      }
export function useGetAllDevicesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllDevicesQuery, GetAllDevicesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllDevicesQuery, GetAllDevicesQueryVariables>(GetAllDevicesDocument, options);
        }
export type GetAllDevicesQueryHookResult = ReturnType<typeof useGetAllDevicesQuery>;
export type GetAllDevicesLazyQueryHookResult = ReturnType<typeof useGetAllDevicesLazyQuery>;
export type GetAllDevicesQueryResult = Apollo.QueryResult<GetAllDevicesQuery, GetAllDevicesQueryVariables>;