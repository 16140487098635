import SquareLogo from "../../../../shared/assets/images/square-logo.png"
import {
  ExternalIntegrationEnum,
  ExternalSourceEnum,
} from "../../../../shared/graphql/generated/types"
import { INTEGRATION_ACTION_FILTER_NAME } from "../../../../shared/utils/constant/searchParams"
import { ILocationsParams } from "../hookforms.interfaces"
import { useGetLocationIntegrationQuery } from "./GraphQL/getLocationIntegration.generated"
import IntegrationCard from "./components/IntegrationCard"
import { integrationsData, integrationsFilterValues } from "./config"
import { Container, Divider, Grid, Space, Stack, Title } from "@mantine/core"
import { useIntl } from "react-intl"
import { useHistory, useParams } from "react-router-dom"

const IntegrationsContent = () => {
  const intl = useIntl()
  const { push } = useHistory()
  const { locationUUID } = useParams<ILocationsParams>()

  const { data, loading } = useGetLocationIntegrationQuery({
    variables: { source: ExternalSourceEnum.SQUARE },
    fetchPolicy: "network-only",
  })

  const hasSquareMenuIntegration =
    !!data?.getLocationIntegration?.locationIntegration?.externalId

  const hasSquareOrderIntegration =
    !!data?.getLocationIntegration?.locationOrderIntegrations.some(
      (integration) => integration.externalId
    )

  const handleClick = (path: string, type: ExternalIntegrationEnum) => () => {
    const params = new URLSearchParams()
    params.set(INTEGRATION_ACTION_FILTER_NAME, integrationsFilterValues[type])
    push({ pathname: path, search: params.toString() })
  }

  return (
    <Container fluid p={0}>
      <Stack px={32} py={8} h={48} justify="center">
        <Title order={5}>
          {intl.formatMessage({
            id: "restaurants.settings.locations.integrations.header",
            defaultMessage: "Integrations",
          })}
        </Title>
      </Stack>
      <Divider w="100%" color="gray.2" />

      <Container mt={24} ml={120} pr={0} fluid>
        <Divider
          color="gray.4"
          labelProps={{ color: "gray.9" }}
          label={intl.formatMessage({
            id: "restaurants.settings.location.integrations.data.import.section.title",
            defaultMessage: "Data import",
          })}
          w="100%"
          labelPosition="left"
        />
        <Space h="md" />
        <Grid gutter={32} w="100%" maw={928}>
          {integrationsData[ExternalIntegrationEnum.MENU].map(
            (integration, index) => (
              <Grid.Col
                xs={12}
                sm={6}
                lg={4}
                key={`${integration.title.defaultMessage}-${index}`}
              >
                <IntegrationCard
                  title={intl.formatMessage(integration.title)}
                  description={intl.formatMessage(integration.description)}
                  image={SquareLogo}
                  connected={hasSquareMenuIntegration}
                  onClick={handleClick(
                    hasSquareMenuIntegration
                      ? integration.editPath(locationUUID)
                      : integration.createPath(locationUUID),
                    ExternalIntegrationEnum.MENU
                  )}
                  loading={loading}
                />
              </Grid.Col>
            )
          )}
        </Grid>

        <Space h={32} bg="blue" />

        <Divider
          color="gray.4"
          labelProps={{ color: "gray.9" }}
          label={intl.formatMessage({
            id: "restaurants.settings.location.integrations.data.import.section.title",
            defaultMessage: "Order Aggregation",
          })}
          w="100%"
          labelPosition="left"
        />
        <Space h="md" />
        <Grid gutter={32} w="100%" maw={928}>
          {integrationsData[ExternalIntegrationEnum.ORDER_AGGREGATION].map(
            (integration, index) => (
              <Grid.Col
                xs={12}
                sm={6}
                lg={4}
                key={`${integration.title.defaultMessage}-${index}`}
              >
                <IntegrationCard
                  title={intl.formatMessage(integration.title)}
                  description={intl.formatMessage(integration.description)}
                  image={SquareLogo}
                  connected={hasSquareOrderIntegration}
                  onClick={handleClick(
                    hasSquareOrderIntegration
                      ? integration.editPath(locationUUID)
                      : integration.createPath(locationUUID),
                    ExternalIntegrationEnum.ORDER_AGGREGATION
                  )}
                  loading={loading}
                />
              </Grid.Col>
            )
          )}
        </Grid>
      </Container>
    </Container>
  )
}

const Integrations = () => {
  const { locationUUID } = useParams<ILocationsParams>()

  return <IntegrationsContent key={locationUUID} />
}

export default Integrations
