import * as Types from '../../../../../shared/graphql/generated/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AddLocationIntegrationMutationVariables = Types.Exact<{
  data: Types.AddLocationIntegrationInput;
}>;


export type AddLocationIntegrationMutation = { __typename?: 'Mutation', addLocationIntegration: { __typename?: 'ExternalIntegration', accessToken: string, appId: string, externalId: string, source: Types.ExternalSourceEnum, uuid: string, locationIntegration?: { __typename?: 'BasicLocationIntegration', externalId: string, externalName: string, type: Types.ExternalIntegrationEnum } | null, locationOrderIntegrations: Array<{ __typename?: 'BasicLocationIntegration', externalId: string, externalName: string, type: Types.ExternalIntegrationEnum }> } };


export const AddLocationIntegrationDocument = gql`
    mutation AddLocationIntegration($data: AddLocationIntegrationInput!) {
  addLocationIntegration(data: $data) {
    accessToken
    appId
    externalId
    locationIntegration {
      externalId
      externalName
      type
    }
    locationOrderIntegrations {
      externalId
      externalName
      type
    }
    source
    uuid
  }
}
    `;
export type AddLocationIntegrationMutationFn = Apollo.MutationFunction<AddLocationIntegrationMutation, AddLocationIntegrationMutationVariables>;

/**
 * __useAddLocationIntegrationMutation__
 *
 * To run a mutation, you first call `useAddLocationIntegrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddLocationIntegrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addLocationIntegrationMutation, { data, loading, error }] = useAddLocationIntegrationMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAddLocationIntegrationMutation(baseOptions?: Apollo.MutationHookOptions<AddLocationIntegrationMutation, AddLocationIntegrationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddLocationIntegrationMutation, AddLocationIntegrationMutationVariables>(AddLocationIntegrationDocument, options);
      }
export type AddLocationIntegrationMutationHookResult = ReturnType<typeof useAddLocationIntegrationMutation>;
export type AddLocationIntegrationMutationResult = Apollo.MutationResult<AddLocationIntegrationMutation>;
export type AddLocationIntegrationMutationOptions = Apollo.BaseMutationOptions<AddLocationIntegrationMutation, AddLocationIntegrationMutationVariables>;