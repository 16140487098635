import ItemCard from "../../../../../../components/ItemCard"
import paths from "../../../../../../shared/routes/paths"
import AddButton from "../../../../../../ui/AddButton"
import SectionHeader from "../../../../../../ui/SectionHeader"
import React, { useCallback } from "react"
import { useIntl } from "react-intl"
import { useHistory } from "react-router-dom"

interface ITaxComboItemForm {
  setDisplayItemSearch: (visible: boolean) => void
  itemList: Array<{ id: string; uuid: string; name: string; src?: string }>
}

export const TaxComboItemForm: React.FC<ITaxComboItemForm> = ({
  setDisplayItemSearch,
  itemList,
}) => {
  const intl = useIntl()
  const history = useHistory()

  const {
    restaurants: { editProduct },
  } = paths

  const openSearchItemsModal = () => setDisplayItemSearch(true)

  const onClickItem = useCallback(
    (itemUUID: string) => {
      history.push(editProduct(itemUUID))
    },
    [editProduct, history]
  )

  return (
    <div>
      <SectionHeader
        title={intl.formatMessage({
          id: "restaurants.taxes.form.section.3.title",
          defaultMessage: "Items",
        })}
        description={intl.formatMessage({
          id: "restaurants.taxes.form.section.3.description",
          defaultMessage: "Add items to apply this tax",
        })}
        actionButton={
          <AddButton onClick={openSearchItemsModal} type="button" />
        }
      />
      {itemList.map((field) => {
        return (
          <ItemCard
            role="item-card"
            onClick={() => onClickItem(field.uuid)}
            key={field.id}
            title={field.name}
            src={field.src}
          />
        )
      })}
    </div>
  )
}
