import { StyledLayout } from "./LayoutBase.styled"
import type { LayoutProps } from "antd"
import React from "react"

interface ILayoutBase extends LayoutProps {
  flexDirection?: "column" | "column-reverse" | "row" | "row-reverse"
}

export const LayoutBase: React.FC<ILayoutBase> = ({
  flexDirection,
  ...props
}) => {
  return <StyledLayout {...props} $flexDirection={flexDirection} />
}
