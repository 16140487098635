export const IOS_DOMAIN = "co.crmb.ios"
export const ANDROID_DOMAIN = "co.crmb.android"

interface GetWhiteLabelConfigParams {
  restaurantUUID: string
  urlIdentifier: string
  restaurantName: string
  description?: string
  primaryBrandColor?: string
  iosDomain: string
  androidDomain: string
}

export const getWhiteLabelConfig = (params: GetWhiteLabelConfigParams) => {
  const {
    restaurantUUID,
    urlIdentifier,
    restaurantName,
    description,
    primaryBrandColor,
    iosDomain,
    androidDomain,
  } = params

  return JSON.stringify({
    bundleAppleId: `${iosDomain}-${restaurantUUID}-${urlIdentifier.replaceAll(
      "_",
      "-"
    )}`,
    bundleAndroidId: `${androidDomain}_${restaurantUUID}_${urlIdentifier.replaceAll(
      "-",
      "_"
    )}`,
    appName: `${restaurantName}`,
    description: description,
    styles: {
      colors: [
        {
          name: "primaryColor",
          light: primaryBrandColor,
          dark: primaryBrandColor,
        },
      ],
    },
  })
}
