import type { GetAllLocationsByUserQuery } from "../../../../../GraphQL/Queries/getAllLocationsByUser.generated"
import { useGetAllLocationsByUserQuery } from "../../../../../GraphQL/Queries/getAllLocationsByUser.generated"
import { useGeneralContext } from "../../../../../shared/contexts/StoreProvider"
import { AdminRoleNameEnum } from "../../../../../shared/graphql/generated/types"
import { updateGetAllLocationsByUser } from "../../../../../shared/graphql/updateQuery/updateGetAllLocationsByUser"
import Container from "../../../../../ui/Container"
import { showGraphqlErrors } from "../../../../../ui/ErrorList"
import Input from "../../../../../ui/Inputs"
import RadioGroupButton from "../../../../../ui/Radio/RadioGroupButton"
import Select from "../../../../../ui/Select"
import { onPopupScroll } from "../../../../../ui/Select/helpers/onPopupScroll"
import Spacer from "../../../../../ui/Spacer"
import type { IMemberForm } from "../interfaces/hookforms.interfaces"
import type { ILocationAccess } from "../interfaces/membersTable.interfaces"
import roleOptions from "./roleOptions"
import get from "lodash/get"
import React, { useCallback } from "react"
import { Controller, useFormContext } from "react-hook-form"
import { useIntl } from "react-intl"

type MemberFormProps = React.ComponentProps<"form">

export const MemberForm: React.FC<MemberFormProps> = ({ ...props }) => {
  const intl = useIntl()

  const {
    state: {
      currentRestaurant: { uuid: restaurantUUID },
      auth: {
        admin: { uuid: userUUID },
      },
    },
  } = useGeneralContext()

  const {
    control,
    watch,
    formState: { errors },
  } = useFormContext<IMemberForm>()

  const roleNameWatcher = watch("roleName")

  const {
    data: queryLocationsData,
    loading,
    fetchMore,
  } = useGetAllLocationsByUserQuery({
    variables: { userUUID, restaurantUUID },
    fetchPolicy: "cache-and-network",
    onError: showGraphqlErrors,
  })

  const locationsOptions = get(
    queryLocationsData,
    "getAllLocationsByUser.results",
    []
  ).map((item: ILocationAccess) => {
    return { label: item.name, value: item.uuid }
  })

  const hasNextPage = get(
    queryLocationsData,
    "getAllLocationsByUser.hasNextPage",
    false
  )
  const endCursor = get(
    queryLocationsData,
    "getAllLocationsByUser.endCursor",
    null
  )
  const canFetchMore = !loading && hasNextPage && endCursor

  const fetchMoreLocations = useCallback(async () => {
    try {
      await fetchMore({
        variables: { restaurantUUID, after: endCursor },
        updateQuery: (prev: GetAllLocationsByUserQuery, { fetchMoreResult }) =>
          updateGetAllLocationsByUser(prev, fetchMoreResult),
      })
    } catch (error) {
      showGraphqlErrors(error)
    }
  }, [endCursor, fetchMore, restaurantUUID])

  return (
    <form {...props} aria-label="member-form">
      <Container display="flex" flexDirection="column" gap="36px">
        <Container centered>
          <Controller
            control={control}
            name="roleName"
            render={({ field }) => (
              <RadioGroupButton
                value={field.value}
                items={roleOptions.map(
                  ({ value, labelId, defaultMessage }) => ({
                    value,
                    label: intl.formatMessage({ id: labelId, defaultMessage }),
                  })
                )}
                onChange={field.onChange}
              />
            )}
          />
        </Container>

        {roleNameWatcher === AdminRoleNameEnum.RESTAURANT_MANAGER && (
          <>
            <Controller
              control={control}
              name="locationUUIDs"
              render={({ field: { onChange, value } }) => (
                <Select
                  onChange={onChange}
                  value={value}
                  loading={loading}
                  hasError={!!errors?.locationUUIDs}
                  helperText={errors?.locationUUIDs?.[0]?.message} //TODO: Review this error message
                  label={intl.formatMessage({
                    id: "settings.restaurant.members.form.location.label",
                    defaultMessage: "Select Locations",
                  })}
                  options={locationsOptions}
                  mode="multiple"
                  optionLabelProp="children"
                  requirement="required"
                  placeholder={intl.formatMessage({
                    id: "settings.restaurant.members.form.location.placeholder",
                    defaultMessage: "Click to select",
                  })}
                  onPopupScroll={(e) =>
                    onPopupScroll(e, canFetchMore, fetchMoreLocations)
                  }
                />
              )}
            />
          </>
        )}

        <Controller
          control={control}
          name="firstName"
          render={({ field }) => (
            <Input
              {...field}
              label={intl.formatMessage({
                id: "settings.restaurant.members.form.name.label",
                defaultMessage: "First Name",
              })}
              aria-label="firstName"
              hasError={!!errors?.firstName}
              helperText={errors?.firstName?.message}
              requirement="required"
              capitalizeFirstLetter={false}
            />
          )}
        />

        <Controller
          control={control}
          name="lastName"
          render={({ field }) => (
            <Input
              {...field}
              label={intl.formatMessage({
                id: "settings.restaurant.members.form.last.name.label",
                defaultMessage: "Last Name",
              })}
              aria-label="lastName"
              hasError={!!errors?.lastName}
              helperText={errors?.lastName?.message}
              requirement="required"
              capitalizeFirstLetter={false}
            />
          )}
        />

        <Controller
          control={control}
          name="email"
          render={({ field }) => (
            <Input
              {...field}
              label={intl.formatMessage({
                id: "settings.restaurant.members.form.email.label",
                defaultMessage: "Email",
              })}
              aria-label="email"
              requirement="required"
              hasError={!!errors?.email}
              helperText={errors?.email?.message}
            />
          )}
        />
      </Container>

      <Spacer size={24} />
      <button type="submit" className="hidden" />
    </form>
  )
}
