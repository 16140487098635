import { Button, Group, Modal, Space, Text, Title } from "@mantine/core"
import { useIntl } from "react-intl"

type DisconnectModalProps = {
  name: string
  onDisconnect: () => void
  opened: boolean
  close: () => void
}

export const DisconnectModal = (props: DisconnectModalProps) => {
  const { name, onDisconnect, opened, close } = props
  const intl = useIntl()

  return (
    <Modal
      size={380}
      opened={opened}
      onClose={close}
      title={
        <Title order={4}>
          {intl.formatMessage(
            {
              id: "restaurants.settings.locations.external.integration.disconnect.modal.title",
              defaultMessage: "Disconnect {name}",
            },
            { name }
          )}
        </Title>
      }
      transitionProps={{ transition: "fade", duration: 200 }}
      centered
    >
      <Text c="gray.7" size="sm">
        {intl.formatMessage(
          {
            id: "restaurants.settings.locations.external.integration.disconnect.modal.description",
            defaultMessage:
              "Are you sure you want to disconnect {name}? You can connect others integration after. ",
          },
          { name }
        )}
      </Text>

      <Space h={16} />

      <Group position="right">
        <Button variant="filled" color="red.6" onClick={onDisconnect}>
          <Text size="md" weight={400} c="white">
            {intl.formatMessage({
              id: "restaurants.settings.locations.external.integration.disconnect.modal.button",
              defaultMessage: "Yes, disconnect",
            })}
          </Text>
        </Button>
      </Group>
    </Modal>
  )
}
