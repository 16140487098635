import type { RadioGroupProps as AntdRadioGroupProps } from "antd"
import { Radio } from "antd"
import styled, { css } from "styled-components"

export interface IStyledRadioGroupProps extends AntdRadioGroupProps {
  type?: "primary" | "secondary"
  width?: string
}

const getRadioButtonWidth = (size?: "small" | "middle" | "large") => {
  switch (size) {
    case "small": {
      return "38px"
    }

    case "large": {
      return "210px"
    }
    default: {
      return "97px"
    }
  }
}

const getRadioButtonSelectedTypes = (type: string | undefined) => {
  if (type === "secondary") {
    return css`
      ${({ theme }) =>
        css`
          background: ${theme.colors["Primary1"]};
          border-color: ${theme.colors["Primary5"]};
          span {
            color: ${theme.colors["Primary5"]};
            font-weight: bold;
          }
        `}
    `
  }

  return css`
    ${({ theme }) =>
      css`
        background: ${theme.colors["Primary5"]};
        border-color: ${theme.colors["Primary5"]};
        span {
          color: ${theme.colors["Neutral1"]};
        }
      `}
  `
}

const getRadioButtonHoveredTypes = (type: string | undefined) => {
  if (type === "secondary") {
    return css`
      ${({ theme }) =>
        css`
          background: ${theme.colors["Neutral1"]};
          border-color: ${theme.colors["Primary4"]};
          span {
            color: ${theme.colors["Primary4"]};
          }
        `}
    `
  }

  return css`
    ${({ theme }) =>
      css`
        background: ${theme.colors["Primary4"]};
        border-color: ${theme.colors["Primary4"]};
        span {
          color: ${theme.colors["Neutral1"]};
        }
      `}
  `
}

const getRadioButtonDisabledTypes = (type: string | undefined) => {
  if (type === "secondary") {
    return css`
      ${({ theme }) =>
        css`
          background: ${theme.colors["Danger4"]};
          border-color: ${theme.colors["Neutral5"]};
          span {
            color: ${theme.colors["Neutral5"]};
          }
        `}
    `
  }

  return css`
    ${({ theme }) =>
      css`
        background: ${theme.colors["Neutral3"]};
        border-color: ${theme.colors["Neutral4"]};
        span {
          color: ${theme.colors["Neutral5"]};
        }
        &:not(:first-child)::before {
          background: ${theme.colors["Neutral4"]};
        }
      `}
  `
}

export const StyledRadioGroup = styled(Radio.Group)<IStyledRadioGroupProps>`
  ${({ width }) => css`
    width: ${width};
  `};
  &.radio-group-button {
    display: flex;
    .ant-radio-button-wrapper {
      height: 32px;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;

      :first-child {
        border-radius: 4px 0 0 4px;
        ${({ size }) => css`
          min-width: ${getRadioButtonWidth(size)};
        `};
      }

      :last-child {
        border-radius: 0 4px 4px 0;
        ${({ size }) => css`
          min-width: ${getRadioButtonWidth(size)};
        `};
      }
    }

    &.ant-radio-group-solid
      .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
      ${({ type }) => getRadioButtonSelectedTypes(type)}

      :focus-within {
        box-shadow: none;
      }
    }

    .ant-radio-button-wrapper:not(.ant-radio-button-wrapper-disabled):hover {
      ${({ type }) => getRadioButtonHoveredTypes(type)}
    }

    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before,
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover::before {
      ${({ theme, type }) => css`
        background: ${type == "secondary"
          ? theme.colors["Primary4"]
          : theme.colors.transparent};
      `}
    }

    .ant-radio-button-wrapper-disabled {
      ${({ type }) => getRadioButtonDisabledTypes(type)}
    }

    .ant-radio-button-wrapper:not(:first-child)::before {
      ${({ theme, type }) =>
        type &&
        css`
          background: ${type == "secondary"
            ? theme.colors["Neutral5"]
            : theme.colors["Neutral1"]};
        `}
    }
  }
`
