import type { DividerProps as AntDividerProps } from "antd"
import { Divider as AntDivider } from "antd"
import type CSS from "csstype"
import React from "react"
import styled, { css } from "styled-components"

type DividerProps = AntDividerProps & {
  alignSelf?: CSS.Property.AlignSelf
  verticalMargin?: string
}

export const Divider: React.FC<DividerProps> = ({
  alignSelf,
  verticalMargin = "16px",
  ...props
}) => {
  return (
    <StyledDivider
      {...props}
      $alignSelf={alignSelf}
      $verticalMargin={verticalMargin}
    />
  )
}

type StyledDividerProps = {
  $alignSelf?: CSS.Property.AlignSelf
  $verticalMargin?: string
}

export const StyledDivider = styled(AntDivider)<StyledDividerProps>`
  ${({ type, $alignSelf, $verticalMargin }) => css`
    ${type === "vertical" &&
    css`
      height: 1.4em;
      background-color: ${({ theme }) => theme.colors["Neutral9"]};
      opacity: ${({ theme }) => theme.text.opacity.secondary};
    `}

    ${$alignSelf &&
    css`
      align-self: ${$alignSelf};
    `}

    ${$verticalMargin
      ? css`
          margin-top: ${$verticalMargin} !important;
          margin-bottom: ${$verticalMargin} !important;
        `
      : css`
          margin-top: 0 !important;
          margin-bottom: 0 !important;
        `}
  `}
`
