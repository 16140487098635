import { colors } from "../../styles/global/themes/mainTheme"
import type { BadgeProps as AntdBadgeProps } from "antd"
import { Badge as AntdBadge } from "antd"
import React from "react"
import type { IBaseThemeColors } from "styled-components"

type BadgeProps = Omit<AntdBadgeProps, "color"> & {
  color: keyof IBaseThemeColors
}

const Badge: React.FC<BadgeProps> = ({ color, ...rest }) => {
  return <AntdBadge {...rest} color={colors[color]} />
}

export default Badge
