import { useSearchParams } from "../../../../../../shared/hooks/useSearchParams"
import { EMPLOYEE_LIST_FILTER_NAME } from "../../../../../../shared/utils/constant/searchParams"
import { EmployeeFilterEnum } from "../../EmployeesHeader/config"
import { ActivateEmployeeModal } from "../../Modals/ActivateEmployeeModal"
import { DeactivateEmployeeModal } from "../../Modals/DeactivateEmployeeModal"
import { EditEmployeeModal } from "../../Modals/EditEmployeeModal"
import { EmployeeType } from "../../types/employees.types"
import { getIsActiveFilterValue } from "../../utils/get-is-active-filter-value"
import styles from "./EmployeesMenu.module.css"
import { ActionIcon, Flex, Menu, Stack, Text } from "@mantine/core"
import { IconDots, IconEdit, IconUser, IconUserX } from "@tabler/icons-react"
import { useState } from "react"
import { useIntl } from "react-intl"

interface EmployeesMenuProps {
  employee: EmployeeType
}

export const EmployeesMenu = ({ employee }: EmployeesMenuProps) => {
  const intl = useIntl()
  const { getParam } = useSearchParams()
  const filter = getParam(EMPLOYEE_LIST_FILTER_NAME) as EmployeeFilterEnum
  const isActive = getIsActiveFilterValue(filter)

  const [openMenu, setOpenMenu] = useState(false)
  const [openActivateModal, setOpenActivateModal] = useState(false)
  const [openDeactivateModal, setOpenDeactivateModal] = useState(false)
  const [openEditModal, setOpenEditModal] = useState(false)

  const handleEditEmployee = () => {
    setOpenMenu(false)
    setOpenEditModal(true)
  }

  const handleActiveEmployee = () => {
    setOpenMenu(false)
    setOpenActivateModal(true)
  }

  const handleDeactiveEmployee = () => {
    setOpenMenu(false)
    setOpenDeactivateModal(true)
  }

  return (
    <Menu
      withArrow
      opened={openMenu}
      onChange={setOpenMenu}
      offset={1}
      position="bottom-end"
    >
      <Menu.Target>
        <ActionIcon>
          <IconDots size={24} />
        </ActionIcon>
      </Menu.Target>
      <Menu.Dropdown>
        <Stack spacing={0}>
          {employee.deactivatedAt ? (
            <Flex
              className={styles["flex-menu"]}
              w={140}
              gap={8}
              align="center"
              px={12}
              py={8}
              onClick={handleActiveEmployee}
            >
              <IconUser size={12} />
              <Text size="xs">
                {intl.formatMessage({
                  id: "restaurant.time.entries.list.employees.details.menu.activate",
                  defaultMessage: "Activate",
                })}
              </Text>
            </Flex>
          ) : (
            <>
              <Flex
                className={styles["flex-menu"]}
                w={140}
                gap={8}
                align="center"
                px={12}
                py={8}
                onClick={handleEditEmployee}
              >
                <IconEdit size={12} />
                <Text size="xs">
                  {intl.formatMessage({
                    id: "restaurant.time.entries.list.employees.details.menu.edit",
                    defaultMessage: "Edit",
                  })}
                </Text>
              </Flex>
              <Flex
                className={styles["flex-menu"]}
                w={140}
                gap={8}
                align="center"
                px={12}
                py={8}
                onClick={handleDeactiveEmployee}
              >
                <IconUserX size={12} />
                <Text size="xs" c="gray.9">
                  {intl.formatMessage({
                    id: "restaurant.time.entries.list.employees.details.menu.deactivate",
                    defaultMessage: "Deactivate",
                  })}
                </Text>
              </Flex>
            </>
          )}
        </Stack>
      </Menu.Dropdown>
      {openActivateModal && (
        <ActivateEmployeeModal
          activateEmployee={employee}
          opened={openActivateModal}
          onClose={() => setOpenActivateModal(false)}
          isActiveFilter={isActive}
        />
      )}
      {openDeactivateModal && (
        <DeactivateEmployeeModal
          deactivateEmployee={employee}
          opened={openDeactivateModal}
          isActiveFilter={isActive}
          onClose={() => setOpenDeactivateModal(false)}
        />
      )}
      {openEditModal && (
        <EditEmployeeModal
          employee={employee}
          opened={openEditModal}
          onClose={() => setOpenEditModal(false)}
        />
      )}
    </Menu>
  )
}
