import * as Types from '../../../../../../shared/graphql/generated/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateDeliveryPriceMutationVariables = Types.Exact<{
  label?: Types.InputMaybe<Types.Scalars['String']>;
  locationUUID: Types.Scalars['ID'];
  maxDistance: Types.Scalars['Int'];
  price: Types.Scalars['Float'];
}>;


export type CreateDeliveryPriceMutation = { __typename?: 'Mutation', createDeliveryPrice: { __typename?: 'DeliveryPrice', uuid: string, label?: string | null, maxDistance: number, minDistance: number, price: number } };


export const CreateDeliveryPriceDocument = gql`
    mutation createDeliveryPrice($label: String, $locationUUID: ID!, $maxDistance: Int!, $price: Float!) {
  createDeliveryPrice(
    data: {label: $label, location: {uuid: $locationUUID}, maxDistance: $maxDistance, price: $price}
  ) {
    uuid
    label
    maxDistance
    minDistance
    price
  }
}
    `;
export type CreateDeliveryPriceMutationFn = Apollo.MutationFunction<CreateDeliveryPriceMutation, CreateDeliveryPriceMutationVariables>;

/**
 * __useCreateDeliveryPriceMutation__
 *
 * To run a mutation, you first call `useCreateDeliveryPriceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDeliveryPriceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDeliveryPriceMutation, { data, loading, error }] = useCreateDeliveryPriceMutation({
 *   variables: {
 *      label: // value for 'label'
 *      locationUUID: // value for 'locationUUID'
 *      maxDistance: // value for 'maxDistance'
 *      price: // value for 'price'
 *   },
 * });
 */
export function useCreateDeliveryPriceMutation(baseOptions?: Apollo.MutationHookOptions<CreateDeliveryPriceMutation, CreateDeliveryPriceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateDeliveryPriceMutation, CreateDeliveryPriceMutationVariables>(CreateDeliveryPriceDocument, options);
      }
export type CreateDeliveryPriceMutationHookResult = ReturnType<typeof useCreateDeliveryPriceMutation>;
export type CreateDeliveryPriceMutationResult = Apollo.MutationResult<CreateDeliveryPriceMutation>;
export type CreateDeliveryPriceMutationOptions = Apollo.BaseMutationOptions<CreateDeliveryPriceMutation, CreateDeliveryPriceMutationVariables>;