import type { AdminInviteStatusEnum } from "../../../../shared/graphql/generated/types"
import type { IntlMessage } from "../../../../shared/types"

type IMemberStatus = {
  [key in AdminInviteStatusEnum]: IntlMessage
}

export const CustomMemberStatusTitle: IMemberStatus = {
  ACCEPTED: {
    id: "settings.restaurant.members.datatable.status.accepted",
    defaultMessage: "Accepted",
  },
  PENDING: {
    id: "settings.restaurant.members.datatable.status.pending",
    defaultMessage: "Pending",
  },
  REVOKED: {
    id: "settings.restaurant.members.datatable.status.revoked",
    defaultMessage: "Revoked",
  },
}
