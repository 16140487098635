import type { AdminInviteStatusEnum } from "../../../../../shared/graphql/generated/types"
import { colors } from "../../../../../styles/global/themes/mainTheme"

const getBadgeColor = (status: `${AdminInviteStatusEnum}`) => {
  switch (status) {
    case "REVOKED": {
      return colors["Warning5"]
    }

    case "ACCEPTED": {
      return colors["Success5"]
    }

    case "PENDING": {
      return colors["Warning4"]
    }
    default: {
      return colors["Primary5"]
    }
  }
}

export default getBadgeColor
