import ItemCard from "../../../../../components/ItemCard"
import type { ItemCardModel } from "../../../../../components/ItemCard"
import type { DeliveryLocation } from "../../../../../shared/graphql/generated/types"
import AddButton from "../../../../../ui/AddButton"
import { CardSkeleton } from "../../../../../ui/Card/Card.skeleton"
import ConfirmationModal from "../../../../../ui/ConfirmationModal"
import Container from "../../../../../ui/Container"
import { showGraphqlErrors } from "../../../../../ui/ErrorList"
import SectionHeader from "../../../../../ui/SectionHeader"
import HighlightedText from "../../../../../ui/Typography/HighlightedText"
import notification from "../../../../../ui/notification"
import { DeliveryProviderLogos } from "../../../Restaurant/DeliveryIntegration/utils/providers-config.util"
import type {
  ILocationDeliveryParams,
  ISeletectedItem,
} from "../hookforms.interfaces"
import { useCreateManyDeliveryLocationsMutation } from "./GraphQL/createManyDeliveryLocations.generated"
import { useDeleteDeliveryLocationMutation } from "./GraphQL/deleteDeliveryLocation.generated"
import { useGetDeliveryLocationsQuery } from "./GraphQL/getDeliveryLocations.generated"
import { useUpdateDeliveryLocationMutation } from "./GraphQL/updateDeliveryLocation.generated"
import SearchDeliveryIntegrationsModal from "./SearchDeliveryIntegrationsModal"
import classnames from "classnames"
import { useEffect, useState } from "react"
import { useIntl } from "react-intl"
import { useParams } from "react-router-dom"
import styled from "styled-components"

const LocationDeliveryIntegrations = () => {
  const intl = useIntl()

  const { locationUUID } = useParams<ILocationDeliveryParams>()
  const [showDeleteConfirm, setShowDeleteConfirm] = useState<boolean>(false)
  const [displayAddDeliveryIntegration, setDisplayAddDeliveryIntegration] =
    useState<boolean>()
  const [selectedItemForDelete, setSelectedItemForDelete] =
    useState<ISeletectedItem>()

  const displayConfirmationModal = () => setShowDeleteConfirm(true)

  const onCancelDelete = () => setShowDeleteConfirm(false)

  const hideAddDeliveryIntegrationModal = () =>
    setDisplayAddDeliveryIntegration(false)

  const onAfterClose = () => setSelectedItemForDelete(undefined)

  const { data, loading, refetch } = useGetDeliveryLocationsQuery({
    variables: {
      location: {
        uuid: locationUUID,
      },
    },
    skip: !locationUUID,
    notifyOnNetworkStatusChange: true,
  })

  const deliveriesByLocation: DeliveryLocation[] =
    data?.getDeliveryLocations ?? []

  const [updateDeliveryLocation] = useUpdateDeliveryLocationMutation()

  const [createManyDeliveryLocations, { loading: adding }] =
    useCreateManyDeliveryLocationsMutation({
      update: (cache, result) => {
        cache.modify({
          fields: {
            getDeliveryLocations: () => {
              const newIntegration =
                result.data?.createManyDeliveryLocations.map(
                  ({ uuid, deliveryIntegration, isDefault }) => ({
                    uuid,
                    deliveryIntegration,
                    isDefault,
                  })
                )

              return newIntegration
            },
          },
        })
      },
    })

  const [deleteDeliveryLocation] = useDeleteDeliveryLocationMutation({
    update: (cache, result) => {
      cache.modify({
        fields: {
          getDeliveryLocations: (prev) => {
            const previous = prev as DeliveryLocation[]

            return previous.filter(
              (current) =>
                current.uuid !== result.data?.deleteDeliveryLocation.uuid
            )
          },
        },
      })
    },
  })

  const onSave = (selectedIntegrations: ItemCardModel[]) => {
    if (selectedIntegrations.length > 0) {
      return onAddIntegrations(selectedIntegrations)
    }
  }

  const onDeleteDeliveryIntegrations = async () => {
    try {
      if (!selectedItemForDelete) return

      await deleteDeliveryLocation({
        variables: {
          data: {
            location: { uuid: locationUUID ?? "" },
            uuid: selectedItemForDelete.uuid,
          },
        },
      })
    } catch (error) {
      showGraphqlErrors(error)
    }
    setShowDeleteConfirm(false)
  }

  const onDeleteItem = () => {
    onDeleteDeliveryIntegrations()
    setShowDeleteConfirm(false)
  }

  const onDeleteCard = (uuid: string, name: string) => {
    displayConfirmationModal()
    setSelectedItemForDelete({ uuid, name })
  }

  const onDefaultClick = async (deliveryLocationUUID: string) => {
    try {
      await updateDeliveryLocation({
        variables: { data: { uuid: deliveryLocationUUID, isDefault: true } },
        update: (cache, result) => {
          const deliveryLocation = result.data?.updateDeliveryLocation

          if (deliveryLocation) {
            cache.modify({
              fields: {
                getDeliveryLocations: (prev) => {
                  const previous = prev as DeliveryLocation[]

                  return previous.map((itemUpdated) => {
                    if (deliveryLocationUUID === itemUpdated.uuid) {
                      return {
                        ...itemUpdated,
                        isDefault: true,
                      }
                    }

                    return { ...itemUpdated, isDefault: false }
                  })
                },
              },
            })
          }
        },
      })
    } catch (error) {
      showGraphqlErrors(error)
    }
  }

  const onAddIntegrations = async (selectedIntegrations: ItemCardModel[]) => {
    const defaultIndex =
      deliveriesByLocation.length > 0
        ? deliveriesByLocation.findIndex(
            (delivery) => delivery.isDefault === true
          )
        : 0

    const deliveryIntegrations = selectedIntegrations
      .filter(({ uuid }) => !!uuid && uuid.toString())
      .map(({ uuid }, index) => ({
        uuid: uuid,
        isDefault: index === defaultIndex,
      }))

    try {
      await createManyDeliveryLocations({
        variables: {
          data: {
            location: { uuid: locationUUID },
            deliveryIntegrations,
          },
        },
      })

      notification({
        type: "success",
        description: intl.formatMessage({
          id: "settings.locations.delivery.update.success.message",
          defaultMessage: "Your location delivery settings were saved",
        }),
      })

      setDisplayAddDeliveryIntegration(false)
    } catch (error) {
      showGraphqlErrors(error)
    }
  }

  const showAddDeliveryIntegrationModal = () => {
    setDisplayAddDeliveryIntegration(true)
  }

  useEffect(() => {
    refetch()
  }, [locationUUID, refetch])

  return (
    <Container width="100%">
      <SectionHeader
        title={intl.formatMessage({
          id: "settings.locations.delivery.section.integration.title",
          defaultMessage: "Delivery Integrations",
        })}
        description={intl.formatMessage({
          id: "settings.locations.delivery.section.integration.description",
          defaultMessage:
            "Here you can see all the Delivery Integrations that you have added to this location",
        })}
        actionButton={
          <AddButton
            hierarchy="secondary"
            onClick={showAddDeliveryIntegrationModal}
          />
        }
      />

      {loading && <CardSkeleton cards={2} loading />}

      {deliveriesByLocation.length > 0 &&
        !loading &&
        deliveriesByLocation.map((item) => {
          return (
            <StyledItemCardWrapper key={item.uuid}>
              <ItemCard
                id={item.uuid}
                key={item.uuid}
                title={item.deliveryIntegration.integrationLabel ?? ""}
                src={
                  DeliveryProviderLogos[
                    item.deliveryIntegration.integrationName
                  ]
                }
                imageFit="contain"
                disabledDeleteButton={
                  item.isDefault && deliveriesByLocation.length > 1
                }
                onDeleteCard={() =>
                  onDeleteCard(
                    item.uuid,
                    item.deliveryIntegration.integrationLabel ?? ""
                  )
                }
                toolTipText={intl.formatMessage({
                  id: "settings.locations.delivery.default.integration.tooltip.message",
                  defaultMessage:
                    "You should change the default integration before deleting",
                })}
                allowDelete
              >
                {
                  //TODO: Replace with LinkButton
                }
                <HighlightedText
                  className={classnames("location-delivery-default-button", {
                    "is-default": item.isDefault,
                  })}
                  cursor="pointer"
                  onClick={() => !item.isDefault && onDefaultClick(item.uuid)}
                >
                  {intl.formatMessage({
                    id: "settings.locations.delivery.delivery.relations.by.location.list.default.button",
                    defaultMessage: "Default",
                  })}
                </HighlightedText>
              </ItemCard>
            </StyledItemCardWrapper>
          )
        })}

      <SearchDeliveryIntegrationsModal
        visible={displayAddDeliveryIntegration}
        onCancel={hideAddDeliveryIntegrationModal}
        okText={intl.formatMessage({
          id: "settings.locations.delivery.search.integrations.modal.not.found.add.button",
          defaultMessage: "Add",
        })}
        selectedItems={deliveriesByLocation.map(
          ({
            deliveryIntegration: { integrationLabel, integrationName, uuid },
          }) => ({
            uuid,
            name: integrationLabel ?? "",
            delivery: integrationName,
            price: 0,
          })
        )}
        onSave={onSave}
        okButtonProps={{ loading: adding }}
      />

      <ConfirmationModal
        visible={showDeleteConfirm}
        title={intl.formatMessage(
          {
            id: "settings.locations.delivery.confirmation.modal.title",
            defaultMessage:
              "{count, plural, =1 {Are you sure to remove {name}?} other {Are you sure disconnect {name} Integration?}}",
          },
          {
            count: deliveriesByLocation.length,
            name: selectedItemForDelete?.name || "",
          }
        )}
        subtitle={intl.formatMessage(
          {
            id: "settings.locations.delivery.confirmation.modal.subtitle",
            defaultMessage:
              "{count, plural, =1 {This action will remove your delivery configuration for this location} other {This action will disconnect your integration for this Location}}",
          },
          {
            count: deliveriesByLocation.length,
          }
        )}
        onConfirm={onDeleteItem}
        onCancel={onCancelDelete}
        afterClose={onAfterClose}
        confirmButtonText={intl.formatMessage({
          id: "settings.locations.delivery.confirmation.modal.confirm.button",
          defaultMessage: "Yes",
        })}
        cancelButtonText={intl.formatMessage({
          id: "settings.locations.delivery.confirmation.modal.cancel.button",
          defaultMessage: "No",
        })}
      />
    </Container>
  )
}

export default LocationDeliveryIntegrations

const StyledItemCardWrapper = styled.div`
  .location-delivery-default-button {
    opacity: 0;
    align-self: center;

    :hover {
      opacity: 1;
    }
  }
  .is-default {
    opacity: 1;
  }
`
