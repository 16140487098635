import Button from "../../../../../ui/Button"
import Container from "../../../../../ui/Container"
import { showGraphqlErrors } from "../../../../../ui/ErrorList/ErrorList"
import type { IModalProps } from "../../../../../ui/Modal"
import Modal from "../../../../../ui/Modal"
import notification from "../../../../../ui/notification"
import { GetAllTablesByLocationDocument } from "../GraphQL/getAllTablesByLocation.generated"
import TableForm from "../TableForm"
import { TableResolver } from "../TableForm/TableForm.yup"
import type {
  ILocationTableForm,
  ILocationTableZoneModel,
  ITablesPageParams,
} from "../TableForm/hookforms.interfaces"
import { useUpdateLocationTableMutation } from "./GraphQL/updateLocationTable.generated"
import get from "lodash/get"
import React, { useEffect } from "react"
import { FormProvider, useForm } from "react-hook-form"
import { useIntl } from "react-intl"
import { useParams } from "react-router-dom"

type EditTableProps = Omit<IModalProps, "onCancel"> & {
  onClose: () => void
  defaultValues?: ILocationTableZoneModel
}

export const EditTable: React.FC<EditTableProps> = ({
  defaultValues,
  onClose,
  ...props
}) => {
  const intl = useIntl()
  const { locationUUID } = useParams<ITablesPageParams>()

  const [updateLocationTable, { loading }] = useUpdateLocationTableMutation({
    refetchQueries: [GetAllTablesByLocationDocument],
  })

  const methods = useForm<ILocationTableForm>({
    mode: "all",
    resolver: TableResolver,
  })

  const {
    handleSubmit,
    reset,
    formState: { isValid },
  } = methods

  const saveTable = async (formData: ILocationTableForm) => {
    try {
      if (!defaultValues?.uuid) {
        return
      }

      const tableUpdated = await updateLocationTable({
        variables: {
          data: {
            locationUUID: locationUUID ?? "",
            uuid: defaultValues.uuid,
            name: formData.name,
            description: formData.description,
            capacity: formData.capacity,
            tableIdentifier: formData.name,
            tableZoneUUID: formData.tableZoneUUID,
          },
        },
      })

      const tableUUID = get(tableUpdated, "data.updateLocationTable.uuid")

      if (tableUUID) {
        notification({
          description: intl.formatMessage({
            id: "restaurants.location.edit.table.modal.success",
            defaultMessage: "Your table was updated",
          }),
          type: "success",
        })
        reset()
        onClose()
      }
    } catch (error) {
      showGraphqlErrors(error)
    }
  }

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    handleSubmit(saveTable)()
  }

  useEffect(() => {
    if (defaultValues) {
      const { name, capacity, description } = defaultValues
      reset({
        name,
        capacity,
        description: description ?? undefined,
        tableZoneUUID: defaultValues.tableZone?.uuid,
      })
    }
  }, [locationUUID, defaultValues, reset])

  return (
    <FormProvider {...methods}>
      <Modal
        onCancel={onClose}
        {...props}
        footer={
          <Container display="flex" justifyContent="flex-end" gap="8px">
            <Button
              title={intl.formatMessage({
                id: "restaurants.location.table.cancel",
                defaultMessage: "Cancel",
              })}
              hierarchy="secondary"
              onClick={onClose}
            />

            <Button
              disabled={!isValid}
              loading={loading}
              title={intl.formatMessage({
                id: "restaurants.location.table.ok",
                defaultMessage: "Ok",
              })}
              onClick={handleSubmit(saveTable)}
            />
          </Container>
        }
        title={intl.formatMessage({
          id: "restaurants.location.edit.table.modal.title",
          defaultMessage: "Edit Table",
        })}
        afterClose={reset}
        destroyOnClose
      >
        <TableForm onSubmit={onSubmit} />
      </Modal>
    </FormProvider>
  )
}
