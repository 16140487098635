import * as Types from '../../../../../shared/graphql/generated/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetStripeConfigQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetStripeConfigQuery = { __typename?: 'Query', getStripeConfig: { __typename?: 'StripeKeyModel', publishableKey: string } };


export const GetStripeConfigDocument = gql`
    query getStripeConfig {
  getStripeConfig {
    publishableKey
  }
}
    `;

/**
 * __useGetStripeConfigQuery__
 *
 * To run a query within a React component, call `useGetStripeConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStripeConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStripeConfigQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetStripeConfigQuery(baseOptions?: Apollo.QueryHookOptions<GetStripeConfigQuery, GetStripeConfigQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStripeConfigQuery, GetStripeConfigQueryVariables>(GetStripeConfigDocument, options);
      }
export function useGetStripeConfigLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStripeConfigQuery, GetStripeConfigQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStripeConfigQuery, GetStripeConfigQueryVariables>(GetStripeConfigDocument, options);
        }
export type GetStripeConfigQueryHookResult = ReturnType<typeof useGetStripeConfigQuery>;
export type GetStripeConfigLazyQueryHookResult = ReturnType<typeof useGetStripeConfigLazyQuery>;
export type GetStripeConfigQueryResult = Apollo.QueryResult<GetStripeConfigQuery, GetStripeConfigQueryVariables>;