import * as Types from '../../../../../shared/graphql/generated/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeleteDeliveryIntegrationMutationVariables = Types.Exact<{
  uuid: Types.Scalars['ID'];
}>;


export type DeleteDeliveryIntegrationMutation = { __typename?: 'Mutation', deleteDeliveryIntegration: { __typename?: 'DeliveryIntegrationWithLocationsModel', uuid: string, apiKey: string, integrationLabel: string, integrationName: Types.DeliveryIntegrationEnum, isActive: boolean, issues?: string | null, settings?: any | null } };


export const DeleteDeliveryIntegrationDocument = gql`
    mutation deleteDeliveryIntegration($uuid: ID!) {
  deleteDeliveryIntegration(uuid: $uuid) {
    uuid
    apiKey
    integrationLabel
    integrationName
    isActive
    issues
    settings
  }
}
    `;
export type DeleteDeliveryIntegrationMutationFn = Apollo.MutationFunction<DeleteDeliveryIntegrationMutation, DeleteDeliveryIntegrationMutationVariables>;

/**
 * __useDeleteDeliveryIntegrationMutation__
 *
 * To run a mutation, you first call `useDeleteDeliveryIntegrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDeliveryIntegrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDeliveryIntegrationMutation, { data, loading, error }] = useDeleteDeliveryIntegrationMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useDeleteDeliveryIntegrationMutation(baseOptions?: Apollo.MutationHookOptions<DeleteDeliveryIntegrationMutation, DeleteDeliveryIntegrationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteDeliveryIntegrationMutation, DeleteDeliveryIntegrationMutationVariables>(DeleteDeliveryIntegrationDocument, options);
      }
export type DeleteDeliveryIntegrationMutationHookResult = ReturnType<typeof useDeleteDeliveryIntegrationMutation>;
export type DeleteDeliveryIntegrationMutationResult = Apollo.MutationResult<DeleteDeliveryIntegrationMutation>;
export type DeleteDeliveryIntegrationMutationOptions = Apollo.BaseMutationOptions<DeleteDeliveryIntegrationMutation, DeleteDeliveryIntegrationMutationVariables>;