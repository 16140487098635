import { useGeneralContext } from "../../../../../shared/contexts/StoreProvider"
import getInitials from "../../../../../shared/utils/helpers/getInitials"
import Container from "../../../../Container"
import Dropdown from "../../../../Dropdown"
import ImageIcon from "../../../../ImageIcon"
import Text from "../../../../Typography/Text"
import DropdownMenu from "../DropdownMenu"
import React from "react"
import styled, { css } from "styled-components"

export const Logo: React.FC = () => {
  const {
    state: { currentRestaurant },
  } = useGeneralContext()

  if (!currentRestaurant.uuid) {
    return null
  }

  return (
    <StyledLogoWrapper role="logo" id="logo-wrapper">
      <Dropdown
        className="logo"
        overlay={DropdownMenu({
          loading: false,
        })}
        trigger={["click"]}
      >
        <Container
          role="logo-containter"
          className="container"
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          gap="8px"
          overFlowX="hidden"
        >
          {currentRestaurant.icon ? (
            <img width="20px" height="20px" src={currentRestaurant.icon} />
          ) : (
            <ImageIcon
              className="avatar"
              size={20}
              color="Secondary5"
              shape="square"
            >
              {getInitials(currentRestaurant.name)}
            </ImageIcon>
          )}
          <Text className="title" size="s" weight="regular" ellipsis>
            {currentRestaurant.name}
          </Text>
        </Container>
      </Dropdown>
    </StyledLogoWrapper>
  )
}

export const StyledLogoWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;
  position: relative;
  z-index: 1;
  top: 0;
  left: 0;

  ${({ theme }) => css`
    width: ${theme.sizing.sidebar};
    background: ${theme.colors.white};
    box-shadow: inset -1px 0px 0px ${theme.colors["Neutral3"]};
  `};

  .avatar {
    min-width: 20px;
    min-height: 20px;
  }

  .logo {
    display: flex;
    align-items: center;
    width: 100%;
  }

  .title {
    max-width: 128px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`
