import { useGeneralContext } from "../../../shared/contexts/StoreProvider"
import type { AdminRoleNameEnum } from "../../../shared/graphql/generated/types"
import type { MenuItemProps } from "antd"
import { Menu } from "antd"
import React from "react"
import styled, { css } from "styled-components"

type Roles = `${AdminRoleNameEnum}`

type StyledInlineMenuItemProps = {
  width?: number
  allowed?: Roles[]
}

export type InlineMenuItemProps = MenuItemProps & StyledInlineMenuItemProps

export const InlineMenuItem: React.FC<InlineMenuItemProps> = ({
  width = 176,
  allowed,
  ...props
}) => {
  const {
    state: {
      auth: {
        admin: { role },
      },
      currentRestaurantUserRole,
    },
  } = useGeneralContext()

  const ownerRole = role?.name
  const userRole = currentRestaurantUserRole?.name

  const isIncluded = allowed?.find(
    (allow) => userRole === allow || allow === ownerRole
  )

  if ((!userRole || !ownerRole) && !isIncluded && allowed) return null

  return <StyledInlineMenuItem width={width} {...props} />
}

const StyledInlineMenuItem = styled(Menu.Item)<StyledInlineMenuItemProps>`
  ${({ theme, width, icon }) =>
    css`
      &.ant-menu-item {
        margin-top: 0;
        ${icon &&
        css`
          padding-left: 8px !important;
        `}
        height: 32px;
        width: ${width}px;
        border-radius: 4px;

        .anticon {
          color: ${theme.colors.Neutral9};
        }

        ::after {
          border: none;
        }

        .ant-menu-item-icon,
        .anticon {
          min-width: 12px;
        }

        &.ant-menu-item-selected {
          background: ${theme.colors["Primary1"]};
          width: ${width}px;
        }

        .ant-menu-title-content {
          a {
            color: ${theme.colors["Neutral7"]};
            font-size: 13px;
            line-height: 20px;
          }
        }

        &.ant-menu-item-active,
        &.ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
        &.ant-menu-submenu-title:hover {
          color: ${theme.colors["Neutral7"]};
          background: ${theme.colors["Neutral2"]};
          width: ${width}px;
        }

        &:not(:last-child) {
          margin-bottom: 0;
        }

        &:focus-visible,
        &.ant-menu-submenu-title:focus-visible {
          box-shadow: none;
        }
      }
    `}
`
