import safeNumber from "../../../shared/utils/helpers/safeNumber"
import Wrapper from "../../../ui/Field/Wrapper"
import InputLabel from "../../InputLabel"
import InputHelper from "../InputHelper"
import type { InputProps } from "../SimpleInput/SimpleInput"
import { SimpleInput } from "../SimpleInput/SimpleInput"
import { replaceLeadingZeros } from "./constant/regex"
import { setDecimals } from "./utils/get-decimals.util"
import classnames from "classnames"
import React, { useEffect, useImperativeHandle } from "react"
import styled from "styled-components"

type InputNumberProps = Omit<InputProps, "onChange" | "onBlur"> & {
  className?: string
  label?: string
  wrapHelperText?: boolean
  helperText?: string
  hasError?: boolean
  errorMessage?: string
  onChange?: (value: number | null) => void
  onBlur?: (value: number | null) => void
  tooltipTitle?: string
  precision?: number
}

const EnhancedInputNumber = (
  props: InputNumberProps,
  ref:
    | ((instance: unknown) => void)
    | React.RefObject<unknown>
    | null
    | undefined
) => {
  const {
    className,
    label,
    requirement,
    hasError,
    errorMessage,
    helperText,
    labelClassName,
    onBlur,
    width,
    wrapHelperText,
    name,
    onChange,
    tooltipTitle,
    value,
    defaultValue,
    precision = 0,
    bordered = true,
    ...rest
  } = props

  const inputRef = React.createRef<HTMLInputElement>()

  const [formattedValue, setFormattedValue] = React.useState<string | null>(
    () => {
      const initValue = value ?? defaultValue

      return initValue?.toString()
        ? setDecimals(initValue.toString(), precision)
        : null
    }
  )

  useImperativeHandle(ref, () => ({
    focus: () => {
      inputRef?.current?.focus()
    },
  }))

  const onParseToNumber = (event: React.ChangeEvent<HTMLInputElement>) => {
    const eventValue = event.target.value

    if (eventValue === null || eventValue === undefined || eventValue === "") {
      return null
    }

    const replace = eventValue.replace(replaceLeadingZeros, "")

    return safeNumber(setDecimals(replace, precision))
  }

  const onChangeInputNumber = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (rest?.maxLength && event.currentTarget.value.length > rest.maxLength) {
      return
    }

    setFormattedValue(event.currentTarget.value)
    onChange?.(onParseToNumber(event))
  }

  const onBlurInputNumber = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormattedValue(setDecimals(event.target.value, precision))
    onBlur?.(onParseToNumber(event))
  }

  useEffect(() => {
    const disableWheel = () => {
      const activeElement = document?.activeElement as HTMLInputElement

      if (activeElement.type === "number") {
        activeElement.blur()
      }
    }

    document.addEventListener("wheel", disableWheel)

    return () => window.removeEventListener("wheel", disableWheel)
  }, [])

  useEffect(() => {
    if (value != formattedValue) {
      setFormattedValue(value?.toString() ?? null)
    }
  }, [formattedValue, value])

  return (
    <>
      {label && (
        <InputLabel
          label={label}
          labelClassName={labelClassName}
          requirement={requirement}
          tooltipTitle={tooltipTitle}
        />
      )}
      <StyledFieldWrapper
        className={classnames("input-number-wrapper", className)}
      >
        <SimpleInput
          {...rest}
          type="number"
          role="input-number"
          name={name}
          ref={inputRef}
          width={width}
          hasError={hasError}
          errorMessage={errorMessage}
          onBlur={onBlurInputNumber}
          onChangeCapture={onChangeInputNumber}
          value={formattedValue ?? ""}
          size="middle"
          bordered={bordered}
        />

        <InputHelper $hasError={hasError} wrap={wrapHelperText} size="s">
          {helperText}
        </InputHelper>
      </StyledFieldWrapper>
    </>
  )
}

export const InputNumber = React.forwardRef(EnhancedInputNumber)

InputNumber.defaultProps = {
  size: "middle",
}

const StyledFieldWrapper = styled(Wrapper)`
  &.input-number-wrapper {
    display: block;
    position: relative;
  }
`
