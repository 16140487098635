import { Container, Skeleton } from "@mantine/core"

export const OrderMethodSettingsSkeleton = () => {
  return (
    <Container h="100vh">
      <Skeleton height={36} radius="sm" mt={32} />
      <Skeleton height={16} mt={18} w={100} radius="xl" />
      <Skeleton height={8} mt={8} w={80} radius="xl" />
      <Skeleton height={30} mt={6} radius="sm" />
      <Skeleton height={16} mt={32} w={100} radius="xl" />
      <Skeleton height={8} mt={8} w={80} radius="xl" />
      <Skeleton height={30} mt={6} radius="sm" />
      <Skeleton height={16} mt={32} w={100} radius="xl" />
      <Skeleton height={8} mt={8} w={80} radius="xl" />
    </Container>
  )
}
