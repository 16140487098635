import { HH_mm } from "../../../../../../../shared/utils/constant/dateFormats"
import Checkbox from "../../../../../../../ui/Checkbox"
import { showGraphqlErrors } from "../../../../../../../ui/ErrorList"
import InputLabel from "../../../../../../../ui/InputLabel"
import Input from "../../../../../../../ui/Inputs/Input"
import type { IModalProps } from "../../../../../../../ui/Modal"
import Modal from "../../../../../../../ui/Modal"
import SimpleDatePicker from "../../../../../../../ui/SimpleDatePicker"
import { TimeSelectRange } from "../../../../../../../ui/TimeSelectRange/TimeSelectRange"
import type { TimeRangeEnum } from "../../../../../../../ui/TimeSelectRange/time-select-range.enums"
import { useCreateOrUpdateHolidayMutation } from "../GraphQL/createOrUpdateHoliday.generated"
import { GetHolidaysListDocument } from "../GraphQL/getHolidaysList.generated"
import type { IHolidayForm } from "../interfaces/HolidayForm"
import type { IHolidaysTableModel } from "../interfaces/HolidaysTable"
import { HolidayHoursResolver } from "./HolidayModalFormResolver"
import moment from "moment-timezone"
import { useCallback, useEffect } from "react"
import { Controller, useForm } from "react-hook-form"
import { FormattedMessage, useIntl } from "react-intl"
import styled from "styled-components"

interface IHolidayModalForm extends Omit<IModalProps, "onCancel"> {
  onClose: () => void
  locationUUID: string
  holiday?: IHolidaysTableModel
}

export const HolidayModalForm = ({
  locationUUID,
  onClose,
  holiday,
  ...props
}: IHolidayModalForm) => {
  const intl = useIntl()

  const [createOrUpdateHoliday, { loading }] = useCreateOrUpdateHolidayMutation(
    {
      refetchQueries: [GetHolidaysListDocument],
      onCompleted: () => {
        handleModalClose()
      },
    }
  )

  const {
    control,
    watch,
    formState: { isValid, errors, isDirty },
    handleSubmit,
    setValue,
    reset,
    trigger,
  } = useForm<IHolidayForm>({
    mode: "all",
    resolver: HolidayHoursResolver,
    defaultValues: {
      name: holiday?.name,
      isClosed: !holiday?.isOpen,
      date: holiday?.date
        ? moment(`${holiday?.date.split("T")[0]} 00:00`).toISOString()
        : undefined,
      startTime: holiday?.startTime,
      endTime: holiday?.endTime,
      uuid: holiday?.uuid,
    },
  })

  const isClosed = watch("isClosed")
  const startTime = watch("startTime")
  const endTime = watch("endTime")

  const handleModalClose = () => {
    reset()
    onClose()
  }

  const setFormValue = useCallback(
    <T extends keyof IHolidayForm>(
      field: T,
      value: IHolidayForm[T],
      validations = true
    ) => {
      // Already checking the type in the argument of the function
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      setValue(field, value as any, {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true,
      })
      validations && trigger(field)
    },
    [setValue, trigger]
  )

  const handleTimeChange = (value_HH_mm: string, type: `${TimeRangeEnum}`) => {
    const formattedValue = moment(value_HH_mm, HH_mm).toISOString(true)

    if (type === "start") setFormValue("startTime", formattedValue, false)

    if (type === "end") setFormValue("endTime", formattedValue, false)

    trigger("startTime")
    trigger("endTime")
  }

  const handleTimeClear = (type: `${TimeRangeEnum}`) => {
    if (type === "start") setFormValue("startTime", undefined, false)

    if (type === "end") setFormValue("endTime", undefined, false)

    trigger("startTime")
    trigger("endTime")
  }

  useEffect(() => {
    if (isClosed) {
      setFormValue("startTime", undefined)
      setFormValue("endTime", undefined)
    }
  }, [isClosed, setFormValue])

  const handleFormSubmit = async (data: IHolidayForm) => {
    const formattedStartTime = data.startTime
      ? moment(data.startTime).toISOString(true)
      : undefined
    const formattedEndTime = data.endTime
      ? moment(data.endTime).toISOString(true)
      : undefined

    try {
      await createOrUpdateHoliday({
        variables: {
          data: {
            name: data.name,
            date: moment(data.date).format("YYYY-MM-DD") ?? "",
            isOpen: !data.isClosed,
            location: {
              uuid: locationUUID,
            },
            ...(!data.isClosed && {
              startTime: formattedStartTime,
              endTime: formattedEndTime,
            }),
            ...(data.uuid && { uuid: data.uuid }),
          },
        },
      })
    } catch (error) {
      showGraphqlErrors(error)
    }
  }

  return (
    <Modal
      {...props}
      onCancel={handleModalClose}
      title={
        <FormattedMessage
          id="restaurants.locations.hours.of.operation.holidays.modal.title"
          defaultMessage="{action} Holiday"
          values={{ action: holiday?.uuid ? "Edit" : "New" }}
        />
      }
      closable={false}
      width={575}
      okText="Save"
      okButtonProps={{
        disabled: !isValid,
        loading: loading,
      }}
      onOk={handleSubmit(handleFormSubmit)}
      centered
      destroyOnClose
      shouldCloseOnClickOutside={!isDirty}
    >
      <Container>
        <Controller
          control={control}
          name="name"
          render={({ field }) => (
            <StyledInput
              aria-label="name"
              description={
                <InputLabel
                  label={intl.formatMessage({
                    id: "restaurants.locations.hours.of.operation.holidays.modal.name",
                    defaultMessage: "Name",
                  })}
                  requirement="required"
                />
              }
              value={field.value}
              onChange={field.onChange}
              hasError={!!errors.name?.message}
              errorMessage={errors.name?.message}
              useEllipsis
            />
          )}
        />

        <Controller
          control={control}
          name="date"
          render={({ field: { onBlur, name, value } }) => (
            <SimpleDatePicker
              onBlur={onBlur}
              name={name}
              disabledDate={(current) => current.isBefore(moment(), "date")}
              onChange={(date) => {
                if (!date) return

                setFormValue(
                  "date",
                  moment(
                    `${date.toISOString().split("T")[0]} 00:00`
                  ).toISOString()
                )
              }}
              value={value ? moment(value) : undefined}
              allowClear={false}
            />
          )}
        />

        <Controller
          control={control}
          name="isClosed"
          render={({ field }) => (
            <Checkbox
              {...field}
              content={intl.formatMessage({
                id: "restaurants.locations.hours.of.operation.holidays.modal.closed",
                defaultMessage: "Closed",
              })}
              classId="is-open-holiday-checkbox"
              checked={field.value}
            />
          )}
        />

        <TimeSelectRange
          startTime={startTime ? moment(startTime).format(HH_mm) : undefined}
          endTime={endTime ? moment(endTime).format(HH_mm) : undefined}
          onChange={handleTimeChange}
          onClear={handleTimeClear}
          allowAllDay
          disabled={isClosed}
          error={errors.startTime?.message || errors.endTime?.message}
        />
      </Container>
    </Modal>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px;

  > div:last-child {
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
  }
`

const StyledInput = styled(Input)`
  > div {
    background-color: ${({ theme }) => theme.colors["Neutral0"]};
  }
`
