import * as Types from '../../../../../../shared/graphql/generated/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeleteTaxMutationVariables = Types.Exact<{
  uuid: Types.Scalars['ID'];
}>;


export type DeleteTaxMutation = { __typename?: 'Mutation', deleteTax: { __typename?: 'TaxBasicModel', uuid: string, taxRate: number } };


export const DeleteTaxDocument = gql`
    mutation deleteTax($uuid: ID!) {
  deleteTax(uuid: $uuid) {
    uuid
    taxRate
  }
}
    `;
export type DeleteTaxMutationFn = Apollo.MutationFunction<DeleteTaxMutation, DeleteTaxMutationVariables>;

/**
 * __useDeleteTaxMutation__
 *
 * To run a mutation, you first call `useDeleteTaxMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTaxMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTaxMutation, { data, loading, error }] = useDeleteTaxMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useDeleteTaxMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTaxMutation, DeleteTaxMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteTaxMutation, DeleteTaxMutationVariables>(DeleteTaxDocument, options);
      }
export type DeleteTaxMutationHookResult = ReturnType<typeof useDeleteTaxMutation>;
export type DeleteTaxMutationResult = Apollo.MutationResult<DeleteTaxMutation>;
export type DeleteTaxMutationOptions = Apollo.BaseMutationOptions<DeleteTaxMutation, DeleteTaxMutationVariables>;