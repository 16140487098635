import paths from "../../../../shared/routes/paths"

const { locations } = paths.restaurants

export const locationItems = [
  { name: "Categories", path: locations.menusByLocation },
  { name: "Items", path: locations.itemsByLocation },
  { name: "Modifiers", path: locations.modifiersByLocation },
  { name: "Orders", path: locations.ordersByLocation },
  { name: "Catering", path: locations.cateringByLocation },
  { name: "Customers", path: locations.customersByLocation },
  { name: "Reports", path: locations.reportsByLocation },
  { name: "Payroll", path: locations.payrollByLocation },
]
