import Icon from "../Icon"
import Spinner from "../Spinner"
import type {
  ButtonClassType,
  ButtonHierarchyType,
  ButtonShapeType,
  ButtonSizeType,
} from "./interfaces"
import style from "./style"
import React from "react"
import type { ButtonHTMLAttributes } from "react"
import type { IBaseThemeFontProps } from "styled-components"
import styled, { css, useTheme } from "styled-components"

export type ButtonProps = Omit<
  ButtonHTMLAttributes<HTMLButtonElement>,
  "size"
> & {
  loading?: boolean
  leadingIcon?: string
  trailingIcon?: string
  size?: ButtonSizeType
  hierarchy?: ButtonHierarchyType
  display?: ButtonClassType
  shape?: ButtonShapeType
  width?: string
  title?: React.ReactNode
  transparent?: boolean
  visibility?: boolean
}

const Button = (props: ButtonProps) => {
  const {
    title,
    leadingIcon,
    trailingIcon,
    disabled = false,
    hierarchy = "primary",
    size = "default",
    display = "default",
    shape = "default",
    loading = false,
    children,
    visibility = true,
    ...rest
  } = props

  const theme = useTheme()
  const textStyle =
    size === "default" ? theme.text.body.m.regular : theme.text.body.l.regular

  return (
    <StyledButton
      {...rest}
      size={size}
      hierarchy={hierarchy}
      display={display}
      disabled={disabled}
      shape={shape}
      $loading={loading}
      $visibility={visibility}
    >
      <div className="button__container">
        {loading ? (
          <Spinner />
        ) : (
          leadingIcon && (
            <Icon
              size={24}
              classes="button__container-icon"
              remixiconClass={leadingIcon}
            />
          )
        )}

        <StyledButtonText {...textStyle}>{title ?? children}</StyledButtonText>
        {trailingIcon && (
          <Icon
            size={24}
            classes="button__container-icon"
            remixiconClass={trailingIcon}
          />
        )}
      </div>
    </StyledButton>
  )
}

export default Button

const getButtonSize = (size?: ButtonSizeType, icon?: boolean) => {
  if (size === "large") {
    return css`
      height: 40px;
      width: ${icon && "40px"};
      padding: ${icon ? "2px" : "2px 16px 2px 16px"};
    `
  }

  return css`
    height: 32px;
    width: ${icon && "32px"};
    padding: ${icon ? "3px" : "2px 16px 2px 16px"};
  `
}

const StyledButton = styled.button<{
  size: ButtonSizeType
  hierarchy: ButtonHierarchyType
  display: ButtonClassType
  shape: ButtonShapeType
  $loading: boolean
  width?: string
  transparent?: boolean
  $visibility?: boolean
}>`
  white-space: nowrap;

  ${({ width, $visibility }) => css`
    ${!!width &&
    `
      width: ${width};
      
    `}
    visibility: ${$visibility ? "visible" : "hidden"};
  `}

  ${({ shape, size }) => getButtonSize(size, shape === "square")}

  .button__container {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }

  ${({ shape }) =>
    shape === "default" &&
    css`
      .button__container {
        gap: 4px;
      }
    `}

  ${({ hierarchy, display, $loading, transparent }) => {
    const config = style[`${hierarchy}-${display}`]

    return css`
      cursor: pointer;
      border-radius: 4px;

      background: ${transparent ? "transparent" : config.background};
      color: ${config.color};
      border: ${transparent ? "1px solid transparent" : config.border};

      & path {
        fill: ${config.color};
      }

      .button__container-icon {
        margin: 0 !important;
      }

      .button__container-icon i {
        color: ${config.color};
      }

      :hover {
        background: ${config.hover.background};
        border: ${config.hover.border};
        color: ${config.hover.color};

        path {
          fill: ${config.hover.color};
        }

        .button__container-icon i {
          color: ${config.hover.color};
        }
      }

      ${$loading
        ? css`
            cursor: not-allowed;
            background: ${config.loading.background};
            border: ${config.loading.border};
            color: ${config.loading.color};

            path {
              fill: ${config.loading.color};
            }

            .button__container-icon i {
              color: ${config.loading.color};
            }
          `
        : css`
            :active {
              background: ${config.pressed.background};
              border: ${config.pressed.border};
              color: ${config.pressed.color};

              path {
                fill: ${config.pressed.color};
              }

              .button__container-icon i {
                color: ${config.pressed.color};
              }
            }
          `}

      &[disabled] {
        cursor: not-allowed;
        background: ${config.disabled.background};
        border: ${config.disabled.border};
        color: ${config.disabled.color};

        path {
          fill: ${config.disabled.color};
        }

        .button__container-icon i {
          color: ${config.disabled.color};
        }
      }
    `
  }}
`

type StyledButtonTextProps = IBaseThemeFontProps

const StyledButtonText = styled.span<StyledButtonTextProps>`
  ${(props) => css`
    font-size: ${props.fontSize}px;
    font-family: ${props.fontFamily};
    font-weight: ${props.fontWeight};
    letter-spacing: ${props.letterSpacing}px;
    line-height: ${props.lineHeight}px;
  `}
`
