export const EMPTY_ARRAY = []
export const EMPTY_STRING = ""
export const DEBOUNCE_MILLISECONDS = 300
export const WHITE_SPACE = " "
export const START_TIME = "00:00"
export const END_TIME = "23:59"
export const FIRST_HOUR = 0
export const LAST_HOUR = 23
export const FIRST_MINUTE = 0
export const LAST_MINUTE = 59
export const FIRST_SECOND = 0
export const LAST_SECOND = 59
export const FIRST_MILLISECOND = 0
