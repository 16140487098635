import * as Types from '../../../../shared/graphql/generated/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateScheduleForLocationMutationVariables = Types.Exact<{
  data: Types.UpdateLocationScheduleInput;
}>;


export type UpdateScheduleForLocationMutation = { __typename?: 'Mutation', updateScheduleForLocation: Array<{ __typename?: 'ScheduleDetailModel', uuid: string, day: string, endDate: any, startDate: any }> };


export const UpdateScheduleForLocationDocument = gql`
    mutation updateScheduleForLocation($data: UpdateLocationScheduleInput!) {
  updateScheduleForLocation(data: $data) {
    uuid
    day
    endDate
    startDate
  }
}
    `;
export type UpdateScheduleForLocationMutationFn = Apollo.MutationFunction<UpdateScheduleForLocationMutation, UpdateScheduleForLocationMutationVariables>;

/**
 * __useUpdateScheduleForLocationMutation__
 *
 * To run a mutation, you first call `useUpdateScheduleForLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateScheduleForLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateScheduleForLocationMutation, { data, loading, error }] = useUpdateScheduleForLocationMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateScheduleForLocationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateScheduleForLocationMutation, UpdateScheduleForLocationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateScheduleForLocationMutation, UpdateScheduleForLocationMutationVariables>(UpdateScheduleForLocationDocument, options);
      }
export type UpdateScheduleForLocationMutationHookResult = ReturnType<typeof useUpdateScheduleForLocationMutation>;
export type UpdateScheduleForLocationMutationResult = Apollo.MutationResult<UpdateScheduleForLocationMutation>;
export type UpdateScheduleForLocationMutationOptions = Apollo.BaseMutationOptions<UpdateScheduleForLocationMutation, UpdateScheduleForLocationMutationVariables>;