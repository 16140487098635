import type CSS from "csstype"
import React from "react"
import styled, { css } from "styled-components"

type WrapperProps = Omit<React.ComponentProps<"div">, "ref"> & {
  width?: CSS.Property.Width
}

export const SimpleWrapper = (
  props: WrapperProps,
  ref:
    | ((instance: HTMLDivElement | null) => void)
    | React.RefObject<HTMLDivElement>
    | null
    | undefined
) => {
  const { width, ...rest } = props
  return <StyledWrapper ref={ref} $width={width} {...rest} />
}

export const Wrapper = React.forwardRef(SimpleWrapper)

const StyledWrapper = styled.div<{ $width?: CSS.Property.Width }>`
  ${({ $width }) => css`
    ${!!$width &&
    `
      width: ${$width};
    `}
  `}

  display: flex;
  flex-direction: column;
  position: relative;
`
