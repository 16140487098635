import { graphqlClient } from "../../../../../../shared/graphql/client"
import { EmployeeRoleNameEnum } from "../../../../../../shared/graphql/generated/types"
import { putFileWithSignedUrl } from "../../../../../../shared/utils/api/client"
import getImageFilename from "../../../../../../shared/utils/helpers/getImageFilename"
import getImageMetadata from "../../../../../../shared/utils/helpers/getImageMetadata"
import { parsePhoneNumber } from "../../../../../../shared/utils/helpers/parsePhoneNumber"
import { showGraphqlErrors } from "../../../../../../ui/ErrorList"
import { EmployeeForm } from "../../Forms"
import { EmployeeResolver } from "../../Forms/EmployeeForm.yup"
import { EmployeesDocument } from "../../GraphQL/employees.generated"
import { useUpsertEmployeeMutation } from "../../GraphQL/upsertEmployee.generated"
import { EmployeeType } from "../../types/employees.types"
import type { EmployeeFormType } from "../../types/form.types"
import { Button, Group, Modal } from "@mantine/core"
import { get } from "lodash"
import { useState } from "react"
import { FormProvider, useForm } from "react-hook-form"
import { useIntl } from "react-intl"

interface EditEmployeeModalProps {
  employee: EmployeeType
  opened: boolean
  onClose: () => void
}

export const EditEmployeeModal = ({
  employee,
  opened,
  onClose,
}: EditEmployeeModalProps) => {
  const intl = useIntl()

  const employeeFormMethods = useForm<EmployeeFormType>({
    mode: "all",
    resolver: EmployeeResolver,
    defaultValues: {
      code: Number(employee.code),
      firstName: employee.firstName,
      lastName: employee.lastName,
      allowTips: employee.allowTip,
      role: employee.role.name as EmployeeRoleNameEnum,
      phone: parsePhoneNumber(employee.phone).nationalNumber,
      payRate: employee.payRate ?? undefined,
      attachment: null,
    },
  })

  const {
    setError,
    handleSubmit,
    reset,
    formState: { isDirty, isValid },
  } = employeeFormMethods

  const [file, setFile] = useState<File | null>(null)
  const [uploading, setUploading] = useState(false)

  const [updateEmployee, { loading }] = useUpsertEmployeeMutation()

  const submitUpdateEmployee = async (formData: EmployeeFormType) => {
    try {
      await updateEmployee({
        variables: {
          input: {
            uuid: employee.uuid,
            firstName: formData.firstName,
            lastName: formData.lastName,
            code: formData.code.toString(),
            payRate: formData.payRate ?? 0,
            role: formData.role ?? EmployeeRoleNameEnum.RESTAURANT_EMPLOYEE,
            phone: parsePhoneNumber(formData.phone).number,
            allowTip: formData.allowTips,
            attachment: formData.attachment
              ? {
                  ...getImageMetadata(formData.attachment.type),
                  fileName: getImageFilename(formData.attachment.name),
                }
              : null,
          },
        },
        onCompleted: async (data) => {
          if (data) {
            if (data.upsertEmployee.attachment?.signedUrl && file) {
              setUploading(true)
              try {
                await putFileWithSignedUrl(
                  data.upsertEmployee.attachment.signedUrl,
                  file
                )

                await graphqlClient.refetchQueries({
                  include: [EmployeesDocument],
                })

                setUploading(false)
              } catch (error) {
                setUploading(false)
                console.error(error)
              }
            }

            await graphqlClient.refetchQueries({
              include: [EmployeesDocument],
            })

            reset()
            onClose()
          }
        },
      })
    } catch (error) {
      const errorMessage = get(error, "message")

      if (errorMessage.includes("code")) {
        setError("code", { type: "custome", message: "Codes is already taken" })
      } else {
        showGraphqlErrors(error)
      }
    }
  }

  return (
    <FormProvider {...employeeFormMethods}>
      <Modal
        centered
        opened={opened}
        onClose={onClose}
        title={intl.formatMessage({
          id: "locations.employee.new.create.modal.title",
          defaultMessage: "Edit Employee",
        })}
      >
        <form onSubmit={handleSubmit(submitUpdateEmployee)}>
          <EmployeeForm
            onFile={setFile}
            employeeImageUrl={employee.attachment?.signedUrl}
          />
          <Group mt={32} position="right">
            <Button
              type="submit"
              loading={loading || uploading}
              disabled={!isDirty && isValid}
            >
              {intl.formatMessage({
                id: "locations.employee.new.create.modal.button",
                defaultMessage: "Save Employee",
              })}
            </Button>
          </Group>
        </form>
      </Modal>
    </FormProvider>
  )
}
