import { useGeneralContext } from "../../../../../shared/contexts/StoreProvider"
import type { QRConfig } from "../../../../../shared/hooks/useQRStyleConfig"
import getQrCodePath from "../../../../../shared/utils/helpers/getQrCodePath"
import { colors } from "../../../../../styles/global/themes/mainTheme"
import Container from "../../../../../ui/Container"
import { showGraphqlErrors } from "../../../../../ui/ErrorList"
import Spacer from "../../../../../ui/Spacer"
import Text from "../../../../../ui/Typography/Text"
import type { ILocationsParams } from "../../hookforms.interfaces"
import { DisplayQRModalSkeleton } from "../DisplayQRModal/DisplayQRModal.skeleton"
import QRCode from "easyqrcodejs"
import React, { useEffect } from "react"
import { useIntl } from "react-intl"
import { useParams } from "react-router-dom"
import styled from "styled-components"

type TableStickerProps = {
  uuid: string
  tableName: string
  config: QRConfig
  loading?: boolean
  locationName?: string
}

export const TableSticker: React.FC<TableStickerProps> = ({
  tableName,
  loading,
  locationName,
  config,
  uuid,
}) => {
  const code = React.createRef<HTMLDivElement>()
  const codeRef = React.useRef<QRCode | null>(null)
  const initialRender = React.useRef(true)

  const {
    state: {
      currentRestaurant: { urlIdentifier },
    },
  } = useGeneralContext()

  const intl = useIntl()
  const { locationUUID } = useParams<ILocationsParams>()

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false
    } else {
      code.current?.children[0] && code.current.children[0].remove()
    }

    try {
      codeRef.current = new QRCode(code.current, {
        ...config,
        text: getQrCodePath(urlIdentifier, {
          tableUUID: uuid,
          location: locationUUID,
          orderMethod: "DINE_IN",
        }),
        width: 256,
        height: 256,
        correctLevel: QRCode.CorrectLevel.H,
      })
    } catch (error) {
      showGraphqlErrors(error)
    }
  }, [code, config, locationUUID, uuid, urlIdentifier])

  useEffect(() => {
    return () => {
      try {
        codeRef.current && codeRef.current.clear()
      } catch {
        codeRef.current = null
      }
    }
  }, [])

  return (
    <div className="new-page" role="table-sticker">
      <Spacer size={32} />

      <DisplayQRModalSkeleton loading={loading}>
        <Text size="xl" textAlign="center" weight="bold">
          {locationName
            ? intl.formatMessage(
                {
                  id: "restaurants.location.tables.qr.modal.subtitle",
                  defaultMessage: "Welcome to {locationName}",
                },
                { locationName }
              )
            : intl.formatMessage({
                id: "restaurants.location.tables.qr.modal.subtitle.fallback",
                defaultMessage: "Welcome",
              })}
        </Text>

        <Spacer size={8} />
        <StyledDescription>
          <Text size="m" textAlign="center">
            {intl.formatMessage(
              {
                id: "restaurants.location.tables.qr.modal.description",
                defaultMessage:
                  "You’re seated at table {tableName}. Point your phone's camera at the QR code to check out the menu and place your order.",
              },
              { tableName }
            )}
          </Text>
        </StyledDescription>
      </DisplayQRModalSkeleton>

      <Container style={qrContainerStyle}>
        <div ref={code} style={qrStyle} />
      </Container>
      <Spacer size={8} />
    </div>
  )
}

const qrContainerStyle = {
  display: "flex",
  justifyContent: "center",
}

export const qrStyle = {
  display: "flex",
  justifyContent: "center",
  marginTop: 32,
  background: colors["Neutral1"],
  padding: 16,
}

const StyledDescription = styled.div`
  width: 300px;
  margin-left: auto;
  margin-right: auto;
`
