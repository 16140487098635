import colors from "../../styles/design-tokens/json/colors.json"

export default {
  "primary-default": {
    background: colors.Primary5,
    border: `1px solid ${colors.Primary5}`,
    color: colors.Neutral0,
    hover: {
      background: colors.Primary4,
      color: colors.Neutral0,
      border: `1px solid ${colors.Primary4}`,
    },
    pressed: {
      background: colors.Primary6,
      color: colors.Neutral0,
      border: `1px solid ${colors.Primary6}`,
    },
    loading: {
      background: colors.Primary4,
      color: colors.Neutral0,
      border: `1px solid ${colors.Primary4}`,
    },
    disabled: {
      background: colors.Neutral3,
      color: colors.Neutral5,
      border: `1px solid ${colors.Neutral3}`,
    },
  },
  "primary-warning": {
    background: colors.Warning4,
    border: `1px solid ${colors.Warning4}`,
    color: colors.Neutral0,
    hover: {
      background: colors.Warning3,
      color: colors.Neutral0,
      border: `1px solid ${colors.Warning3}`,
    },
    pressed: {
      background: colors.Warning5,
      color: colors.Neutral0,
      border: `1px solid ${colors.Warning5}`,
    },
    loading: {
      background: colors.Warning3,
      color: colors.Neutral0,
      border: `1px solid ${colors.Warning3}`,
    },
    disabled: {
      background: colors.Neutral3,
      color: colors.Neutral5,
      border: `1px solid ${colors.Neutral3}`,
    },
  },
  "primary-danger": {
    background: colors.Danger5,
    border: `1px solid ${colors.Danger5}`,
    color: colors.Neutral0,
    hover: {
      background: colors.Danger3,
      color: colors.Neutral0,
      border: `1px solid ${colors.Danger3}`,
    },
    pressed: {
      background: colors.Danger6,
      color: colors.Neutral0,
      border: `1px solid ${colors.Danger6}`,
    },
    loading: {
      background: colors.Danger4,
      color: colors.Neutral0,
      border: `1px solid ${colors.Danger4}`,
    },
    disabled: {
      background: colors.Neutral3,
      color: colors.Neutral5,
      border: `1px solid ${colors.Neutral3}`,
    },
  },
  "secondary-default": {
    background: colors.Neutral0,
    border: `1px solid ${colors.Primary5}`,
    color: colors.Primary5,
    hover: {
      background: colors.Neutral0,
      color: colors.Primary4,
      border: `1px solid ${colors.Primary4}`,
    },
    pressed: {
      background: colors.Neutral0,
      color: colors.Primary6,
      border: `1px solid ${colors.Primary6}`,
    },
    loading: {
      background: colors.Neutral0,
      color: colors.Primary4,
      border: `1px solid ${colors.Primary4}`,
    },
    disabled: {
      background: colors.Neutral0,
      color: colors.Neutral5,
      border: `1px solid ${colors.Neutral5}`,
    },
  },
  "secondary-warning": {
    background: colors.Neutral0,
    border: `1px solid ${colors.Warning4}`,
    color: colors.Warning4,
    hover: {
      background: colors.Neutral0,
      color: colors.Warning3,
      border: `1px solid ${colors.Warning3}`,
    },
    pressed: {
      background: colors.Neutral0,
      color: colors.Warning5,
      border: `1px solid ${colors.Warning5}`,
    },
    loading: {
      background: colors.Neutral0,
      color: colors.Warning3,
      border: `1px solid ${colors.Warning3}`,
    },
    disabled: {
      background: colors.Neutral0,
      color: colors.Neutral5,
      border: `1px solid ${colors.Neutral5}`,
    },
  },
  "secondary-danger": {
    background: colors.Neutral0,
    border: `1px solid ${colors.Danger5}`,
    color: colors.Danger5,
    hover: {
      background: colors.Neutral0,
      color: colors.Danger4,
      border: `1px solid ${colors.Danger4}`,
    },
    pressed: {
      background: colors.Neutral0,
      color: colors.Danger6,
      border: `1px solid ${colors.Danger6}`,
    },
    loading: {
      background: colors.Neutral0,
      color: colors.Danger4,
      border: `1px solid ${colors.Danger4}`,
    },
    disabled: {
      background: colors.Neutral0,
      color: colors.Neutral5,
      border: `1px solid ${colors.Neutral5}`,
    },
  },
  "tertiary-default": {
    background: colors.Neutral0,
    border: `1px solid ${colors.Neutral0}`,
    color: colors.Primary5,
    hover: {
      background: colors.Neutral2,
      color: colors.Primary5,
      border: `1px solid ${colors.Neutral2}`,
    },
    pressed: {
      background: colors.Neutral3,
      color: colors.Primary6,
      border: `1px solid ${colors.Neutral3}`,
    },
    loading: {
      background: colors.Neutral0,
      color: colors.Primary4,
      border: `1px solid ${colors.Neutral0}`,
    },
    disabled: {
      background: colors.Neutral0,
      color: colors.Neutral5,
      border: `1px solid ${colors.Neutral0}`,
    },
  },
  "tertiary-warning": {
    background: colors.Neutral0,
    border: `1px solid ${colors.Neutral0}`,
    color: colors.Warning4,
    hover: {
      background: colors.Neutral2,
      color: colors.Warning3,
      border: `1px solid ${colors.Neutral2}`,
    },
    pressed: {
      background: colors.Neutral3,
      color: colors.Warning5,
      border: `1px solid ${colors.Neutral3}`,
    },
    loading: {
      background: colors.Neutral0,
      color: colors.Warning3,
      border: `1px solid ${colors.Neutral0}`,
    },
    disabled: {
      background: colors.Neutral0,
      color: colors.Neutral5,
      border: `1px solid ${colors.Neutral0}`,
    },
  },
  "tertiary-danger": {
    background: colors.Neutral0,
    border: `1px solid ${colors.Neutral0}`,
    color: colors.Danger5,
    hover: {
      background: colors.Neutral2,
      color: colors.Danger5,
      border: `1px solid ${colors.Neutral2}`,
    },
    pressed: {
      background: colors.Neutral3,
      color: colors.Danger6,
      border: `1px solid ${colors.Neutral3}`,
    },
    loading: {
      background: colors.Neutral0,
      color: colors.Danger4,
      border: `1px solid ${colors.Neutral0}`,
    },
    disabled: {
      background: colors.Neutral0,
      color: colors.Neutral5,
      border: `1px solid ${colors.Neutral0}`,
    },
  },
}
