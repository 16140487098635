import paths from "../../../../shared/routes/paths"
import type { AllowedRoles } from "../../../RoleView/RoleView"

const { restaurants } = paths
const allowOwnerAndAdmin: AllowedRoles[] = [
  "RESTAURANT_OWNER",
  "RESTAURANT_ADMIN",
]

export const sidebarItems = [
  {
    path: restaurants.menu,
    title: "Categories",
    icon: "ri-restaurant-line",
    allowedRoles: allowOwnerAndAdmin,
  },
  {
    path: restaurants.items,
    title: "Items",
    icon: "ri-list-check-2",
    allowedRoles: allowOwnerAndAdmin,
  },
  {
    path: restaurants.modifiers,
    title: "Modifiers",
    icon: "ri-knife-line",
    allowedRoles: allowOwnerAndAdmin,
  },
  {
    path: restaurants.orders,
    title: "Orders",
    icon: "ri-draft-line",
    allowedRoles: allowOwnerAndAdmin,
  },
  {
    path: restaurants.catering,
    title: "Catering",
    icon: "ri-draft-line",
    allowedRoles: allowOwnerAndAdmin,
  },
  {
    path: restaurants.customers,
    title: "Customers",
    icon: "ri-group-line",
    allowedRoles: allowOwnerAndAdmin,
  },
  {
    path: restaurants.reports,
    title: "Reports",
    icon: "ri-line-chart-line",
    allowedRoles: allowOwnerAndAdmin,
  },
  {
    path: restaurants.discounts,
    title: "Discounts",
    icon: "ri-percent-line",
    allowedRoles: allowOwnerAndAdmin,
  },
]
