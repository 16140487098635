import { AsYouType } from "libphonenumber-js"

const formatPhoneNumber = (value?: string | null) => {
  if (!value) return ""
  value = value.toString()

  if (value.includes("(") && !value.includes(")")) {
    return value.replace("(", "")
  }

  return new AsYouType("US").input(value)
}

export default formatPhoneNumber
