/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  DisableEmployeeMutation,
  DisableEmployeeMutationVariables,
} from "../../GraphQL/disableEmployee.generated"
import {
  EmployeesDocument,
  EmployeesQuery,
  EmployeesQueryVariables,
} from "../../GraphQL/employees.generated"
import {
  EnableEmployeeMutation,
  EnableEmployeeMutationVariables,
} from "../../GraphQL/enableEmployee.generated"
import { CacheUpdateFunction } from "../../types/graphql.enums"
import { ApolloCache } from "@apollo/client"

type HookProps = { isActive: boolean | undefined }

export const useEmployeeCacheUpdate = ({ isActive }: HookProps) => {
  const updateEmployee = <T extends { deactivatedAt: Date; uuid: string }>(
    cache: ApolloCache<any>,
    updatedEmployee: T
  ) => {
    cache.updateQuery<EmployeesQuery, EmployeesQueryVariables>(
      {
        query: EmployeesDocument,
        ...(typeof isActive === "boolean" && {
          variables: { isActive: isActive },
        }),
      },
      (prevData) => {
        if (!prevData?.employees) return prevData

        const elements = [...prevData.employees]

        const elementIndex = elements.findIndex(
          (value) => value?.uuid === updatedEmployee?.uuid
        )

        elements[elementIndex] = {
          ...elements[elementIndex],
          deactivatedAt: updatedEmployee.deactivatedAt,
        }

        return {
          employees: elements,
        }
      }
    )
  }

  const enableEmployeeCacheUpdate: CacheUpdateFunction<
    EnableEmployeeMutation,
    EnableEmployeeMutationVariables
  > = (cache, result) => {
    if (result.data) {
      const enableEmployee = result.data.enableEmployee

      if (enableEmployee) {
        updateEmployee(cache, {
          deactivatedAt: enableEmployee.deactivatedAt,
          uuid: enableEmployee.uuid,
        })
      }
    }
  }

  const disableEmployeeCacheUpdate: CacheUpdateFunction<
    DisableEmployeeMutation,
    DisableEmployeeMutationVariables
  > = (cache, result) => {
    if (result.data) {
      const disableEmployee = result.data.disableEmployee
      if (disableEmployee) {
        updateEmployee(cache, {
          deactivatedAt: disableEmployee.deactivatedAt,
          uuid: disableEmployee.uuid,
        })
      }
    }
  }

  return {
    enableEmployeeCacheUpdate,
    disableEmployeeCacheUpdate,
  }
}
