import Button from "../../../../../../../ui/Button"
import ConfirmationModal from "../../../../../../../ui/ConfirmationModal"
import Container from "../../../../../../../ui/Container"
import BusinessHoursCell from "../../../BusinessHours/BusinessHoursRow"
import type {
  IBusinessHoursForm,
  IBussinessHourRangeType,
} from "../../../hookforms.interfaces"
import { HoursOfOperationResolver } from "./../HoursOfOperation.yup"
import React, { useEffect, useState } from "react"
import { FormProvider, useForm } from "react-hook-form"
import { useIntl } from "react-intl"
import { Prompt } from "react-router-dom"
import styled, { css } from "styled-components"

interface IHoursOfOperationFormProps {
  saveLocation: (formData: IBusinessHoursForm) => Promise<void>
  schedules: Array<IBussinessHourRangeType>
  loading: boolean
  displayEditActions: boolean
}

export const HoursOfOperationForm: React.FC<IHoursOfOperationFormProps> = ({
  saveLocation,
  schedules,
  loading,
}) => {
  const intl = useIntl()

  const [showDiscardChangesConfirm, setShowDiscardChangesConfirm] =
    useState<boolean>(false)

  const formMethods = useForm<IBusinessHoursForm>({
    mode: "all",
    resolver: HoursOfOperationResolver,
    defaultValues: {
      schedules,
    },
  })

  const {
    handleSubmit,
    formState: { isDirty, isValid },
    clearErrors,
    reset,
    watch,
  } = formMethods

  const weekDays = watch("schedules") ?? []

  const onCancelClick = () => {
    reset({ schedules })
    clearErrors()
  }

  const onDiscardChanges = () => {
    setShowDiscardChangesConfirm(false)
    onCancelClick()
  }

  const onCloseDiscardChangesConfirm = () => setShowDiscardChangesConfirm(false)

  useEffect(() => {
    reset({ schedules })
  }, [JSON.stringify(schedules), reset])

  return (
    <FormProvider {...formMethods}>
      <StyledContentLocationOperation>
        {weekDays?.map(({ day, defaultValues }, index) => {
          return (
            <BusinessHoursCell
              key={index}
              dayIndex={index}
              day={day}
              defaultValues={defaultValues}
            />
          )
        })}
      </StyledContentLocationOperation>
      <StyledContentLocationButton>
        <Container display="flex" flexDirection="row" gap="16px">
          <Button
            aria-label="save"
            type="submit"
            title={intl.formatMessage({
              id: "restaurants.locations.overview.edit.button.save",
              defaultMessage: "Save Information",
            })}
            onClick={handleSubmit(saveLocation)}
            disabled={loading || !isDirty || !isValid}
            loading={loading}
          />
        </Container>
      </StyledContentLocationButton>

      <Prompt
        when={isDirty}
        message={intl.formatMessage({
          id: "components.prompt.modal.title",
          defaultMessage:
            "You have unsaved changes, do you want to discard them?",
        })}
      />

      <ConfirmationModal
        title={intl.formatMessage({
          id: "components.prompt.modal.title",
          defaultMessage:
            "You have unsaved changes, do you want to discard them?",
        })}
        confirmButtonText={intl.formatMessage({
          id: "components.prompt.modal.cancel.button",
          defaultMessage: "Discard",
        })}
        visible={showDiscardChangesConfirm}
        onCancel={onCloseDiscardChangesConfirm}
        onOk={onDiscardChanges}
      />
    </FormProvider>
  )
}

const StyledContentLocationOperation = styled.div`
  ${({ theme }) => css`
    border-top: 1px solid ${theme.colors["Neutral3"]};
  `}
`

const StyledContentLocationButton = styled.div`
  margin-top: 64px;
`
