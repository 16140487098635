import * as Types from '../../../../../shared/graphql/generated/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ResendInviteToMemberMutationVariables = Types.Exact<{
  inviteUUID: Types.Scalars['ID'];
}>;


export type ResendInviteToMemberMutation = { __typename?: 'Mutation', resendInviteToMember: { __typename?: 'InviteBasicModel', inviteUUID: string, email: string, firstName: string, lastName: string, inviteStatus: Types.AdminInviteStatusEnum } };


export const ResendInviteToMemberDocument = gql`
    mutation resendInviteToMember($inviteUUID: ID!) {
  resendInviteToMember(inviteUUID: $inviteUUID) {
    inviteUUID
    email
    firstName
    lastName
    inviteStatus
  }
}
    `;
export type ResendInviteToMemberMutationFn = Apollo.MutationFunction<ResendInviteToMemberMutation, ResendInviteToMemberMutationVariables>;

/**
 * __useResendInviteToMemberMutation__
 *
 * To run a mutation, you first call `useResendInviteToMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendInviteToMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendInviteToMemberMutation, { data, loading, error }] = useResendInviteToMemberMutation({
 *   variables: {
 *      inviteUUID: // value for 'inviteUUID'
 *   },
 * });
 */
export function useResendInviteToMemberMutation(baseOptions?: Apollo.MutationHookOptions<ResendInviteToMemberMutation, ResendInviteToMemberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResendInviteToMemberMutation, ResendInviteToMemberMutationVariables>(ResendInviteToMemberDocument, options);
      }
export type ResendInviteToMemberMutationHookResult = ReturnType<typeof useResendInviteToMemberMutation>;
export type ResendInviteToMemberMutationResult = Apollo.MutationResult<ResendInviteToMemberMutation>;
export type ResendInviteToMemberMutationOptions = Apollo.BaseMutationOptions<ResendInviteToMemberMutation, ResendInviteToMemberMutationVariables>;