import styles from "./CustomPrompt.module.css"
import { Button, Group, Modal, Space, Text, Title } from "@mantine/core"
import { useDisclosure } from "@mantine/hooks"

const DISCARD_MESSAGE = "Yes, discard"
const GO_BACK_MESSAGE = "No, go back"
const TITLE = "Discard changes"

type CustomPromptProps = {
  answerCallback: (answer: boolean) => void
  message: string
}

export const CustomPrompt = (props: CustomPromptProps) => {
  const { answerCallback, message } = props
  const [opened, { close }] = useDisclosure(true)

  const onCancel = () => answerCallback(false)

  const onDiscard = () => answerCallback(true)

  return (
    <Modal
      opened={opened}
      onClose={close}
      title={<Title order={4}>{TITLE}</Title>}
      transitionProps={{ transition: "fade", duration: 200 }}
      centered
      zIndex={1000}
    >
      <Text c="gray.7" size="sm">
        {message}
      </Text>

      <Space h={16} />

      <Group position="right">
        <Button
          variant="outline"
          classNames={{ root: styles["button-root"] }}
          onClick={onCancel}
        >
          <Text size="md" weight={400} c="gray.8">
            {GO_BACK_MESSAGE}
          </Text>
        </Button>
        <Button variant="filled" color="red.6" onClick={onDiscard}>
          <Text size="md" weight={400} c="white">
            {DISCARD_MESSAGE}
          </Text>
        </Button>
      </Group>
    </Modal>
  )
}
