import { Anchor, Breadcrumbs as ManBreadcrumbs, Text } from "@mantine/core"
import { IconChevronRight } from "@tabler/icons-react"
import { useHistory } from "react-router-dom"

type BreadcrumbsProps = {
  items: Array<{ title: string; href: string }>
}

export const Breadcrumbs = (props: BreadcrumbsProps) => {
  const { items } = props
  const { push } = useHistory()

  const mapedItems = items.map((item, index) => {
    const lastItemIndex = items.length - 1

    return index === lastItemIndex ? (
      <Text
        key={`${item.href}-${index}`}
        size="md"
        c="gray.8"
        weight={index === items.length - 1 ? 600 : 400}
      >
        {item.title}
      </Text>
    ) : (
      <Anchor
        key={`${item.href}-${index}`}
        onClick={() => push(item.href)}
        style={{ textDecoration: "none" }}
      >
        <Text
          size="md"
          c="gray.8"
          weight={index === items.length - 1 ? 600 : 400}
        >
          {item.title}
        </Text>
      </Anchor>
    )
  })

  return (
    <ManBreadcrumbs separator={<IconChevronRight size={16} />}>
      {mapedItems}
    </ManBreadcrumbs>
  )
}
