import * as Types from '../../../../../shared/graphql/generated/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateLocationCateringRulesMutationVariables = Types.Exact<{
  input: Types.UpdateLocationCateringRulesInput;
}>;


export type UpdateLocationCateringRulesMutation = { __typename?: 'Mutation', updateLocationCateringRules: { __typename?: 'LocationCateringRule', uuid: string, orderScheduling: number, orderMethodAcceptance: Types.OrderMethodAcceptanceEnum, services: Array<{ __typename?: 'CateringServices', uuid: string, type: Types.CateringServiceEnum, minimumAmount?: number | null, feePercentage?: number | null, feeFixedAmount?: number | null }>, prePayment: { __typename?: 'CateringPrePayment', amount?: number | null, percentage?: number | null }, cancelPolicy: Array<{ __typename?: 'CateringCancelPolicy', percentage: number, timeBeforeEvent: Types.TimeBeforeEventEnum }>, employees: Array<{ __typename?: 'EmployeeBasic', uuid: string, firstName: string, lastName: string, deactivatedAt?: any | null, phone: string }> } };


export const UpdateLocationCateringRulesDocument = gql`
    mutation updateLocationCateringRules($input: UpdateLocationCateringRulesInput!) {
  updateLocationCateringRules(input: $input) {
    uuid
    services {
      uuid
      type
      minimumAmount
      feePercentage
      feeFixedAmount
    }
    prePayment {
      amount
      percentage
    }
    orderScheduling
    orderMethodAcceptance
    cancelPolicy {
      percentage
      timeBeforeEvent
    }
    employees {
      uuid
      firstName
      lastName
      deactivatedAt
      phone
    }
  }
}
    `;
export type UpdateLocationCateringRulesMutationFn = Apollo.MutationFunction<UpdateLocationCateringRulesMutation, UpdateLocationCateringRulesMutationVariables>;

/**
 * __useUpdateLocationCateringRulesMutation__
 *
 * To run a mutation, you first call `useUpdateLocationCateringRulesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLocationCateringRulesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLocationCateringRulesMutation, { data, loading, error }] = useUpdateLocationCateringRulesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateLocationCateringRulesMutation(baseOptions?: Apollo.MutationHookOptions<UpdateLocationCateringRulesMutation, UpdateLocationCateringRulesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateLocationCateringRulesMutation, UpdateLocationCateringRulesMutationVariables>(UpdateLocationCateringRulesDocument, options);
      }
export type UpdateLocationCateringRulesMutationHookResult = ReturnType<typeof useUpdateLocationCateringRulesMutation>;
export type UpdateLocationCateringRulesMutationResult = Apollo.MutationResult<UpdateLocationCateringRulesMutation>;
export type UpdateLocationCateringRulesMutationOptions = Apollo.BaseMutationOptions<UpdateLocationCateringRulesMutation, UpdateLocationCateringRulesMutationVariables>;