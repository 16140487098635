import { showGraphqlErrors } from "../../../../../../ui/ErrorList/ErrorList"
import DropDownAction from "../../../../../../ui/Header/DropdownAction"
import Icon from "../../../../../../ui/Icon"
import Select, { SelectDropdownFooter } from "../../../../../../ui/Select"
import notification from "../../../../../../ui/notification"
import { useCreateTableZoneMutation } from "../../CreateTable/GraphQL/createTableZone.generated"
import {
  GetAllTablesZoneByLocationDocument,
  useGetAllTablesZoneByLocationQuery,
} from "../../CreateTable/GraphQL/getAllTablesZoneByLocation.generated"
import SelectZoneActionsMenu from "../SelectZoneActionsMenu"
import type { ILocationTableForm } from "../hookforms.interfaces"
import get from "lodash/get"
import React, { useState } from "react"
import { Controller, useFormContext } from "react-hook-form"
import { useIntl } from "react-intl"

type TableZoneSelectProps = {
  locationUUID: string
}

export const TableZoneSelect: React.FC<TableZoneSelectProps> = ({
  locationUUID,
}) => {
  const intl = useIntl()
  const [newZone, setZone] = useState<string>()
  const [openSelectZone, setOpenSelectZone] = useState<boolean | undefined>()

  const {
    control,
    setValue,
    getValues,
    formState: { errors },
  } = useFormContext<ILocationTableForm>()

  const [createTableZone, { loading: creatingZone }] =
    useCreateTableZoneMutation()

  const { data: zones } = useGetAllTablesZoneByLocationQuery({
    variables: {
      locationUUID,
    },
  })

  const onZoneMenuVisibleChange = (visible: boolean) => {
    if (!visible) {
      setOpenSelectZone(undefined)
    }
  }

  const setZoneSelectListVisible = () => {
    setOpenSelectZone(true)
  }

  const deleteZoneCallback = (zoneItem?: string) => {
    const valueTableZoneUUID = getValues("tableZoneUUID")

    setOpenSelectZone(undefined)

    if (valueTableZoneUUID === zoneItem) {
      setValue("tableZoneUUID", "")
    }
  }

  const zoneOptions = get(zones, "getAllTablesZoneByLocation", []).map(
    (zone) => {
      return {
        label: zone.name,
        value: zone.uuid,
        color: "icons",
        extra: (
          <DropDownAction
            onVisibleChange={onZoneMenuVisibleChange}
            overlay={
              <SelectZoneActionsMenu
                zoneItem={zone}
                deleteCallback={deleteZoneCallback}
              />
            }
            arrow={false}
            minWidth="200px"
          >
            <Icon
              remixiconClass="ri-more-line"
              onClick={setZoneSelectListVisible}
            />
          </DropDownAction>
        ),
      }
    }
  )

  const clearNewZone = () => setZone(undefined)

  const onCreateZone = async () => {
    if (!newZone || !locationUUID) {
      return
    }

    try {
      await createTableZone({
        variables: {
          data: {
            locationUUID,
            name: newZone,
          },
        },
        refetchQueries: [GetAllTablesZoneByLocationDocument],
      })

      notification({
        description: intl.formatMessage(
          {
            id: "restaurants.location.table.form.zone.create.message",
            defaultMessage: "{newZone} Zone Created",
          },
          {
            newZone: newZone,
          }
        ),
        type: "success",
      })

      clearNewZone()
    } catch (error) {
      showGraphqlErrors(error)
    }
  }

  const handleSearch = (valueZone: string) => {
    setZone(valueZone.charAt(0).toUpperCase() + valueZone.slice(1))
  }

  const renderDropdown = (dropdown: React.ReactElement) => {
    const found = dropdown.props?.options?.some?.(
      (option: { label: string }) => option.label === newZone
    )

    return (
      <>
        {dropdown}
        {!found && newZone && (
          <SelectDropdownFooter
            searchValue={newZone}
            onButtonClick={onCreateZone}
            buttonAriaLabel="create-zone-button"
            buttonLoading={creatingZone}
          />
        )}
      </>
    )
  }

  return (
    <Controller
      name="tableZoneUUID"
      control={control}
      render={({ field: { onChange, value } }) => (
        <Select
          onChange={onChange}
          value={value}
          label={intl.formatMessage({
            id: "restaurants.location.table.form.zone.label",
            defaultMessage: "Zone",
          })}
          placeholder={intl.formatMessage({
            id: "restaurants.location.table.form.zone.placeholder",
            defaultMessage: "Please choose one option or create one",
          })}
          requirement="required"
          aria-label="table-zone-select"
          hasError={!!errors?.["tableZoneUUID"]}
          helperText={get(errors, "tableZoneUUID.message")}
          onSearch={handleSearch}
          onClear={clearNewZone}
          options={zoneOptions}
          open={openSelectZone}
          optionFilterProp="label"
          dropdownRender={renderDropdown}
          autoClearSearchValue
          hideSearchIcon
          allowSearch
        />
      )}
    />
  )
}
