import { generateRandomAlphanumericCode } from "../../../../../shared/utils/helpers/generate-random-alphanumeric-code.util"
import AddIcon from "../../../../../ui/AddIcon"
import Container from "../../../../../ui/Container"
import CreateDevice from "../CreateDevice"
import { DEVICE_CODE_LENGTH } from "../DeviceForm/DeviceForm.constants"
import { useEffect, useState } from "react"

export const AddDeviceButton = () => {
  const [displayForm, setDisplayForm] = useState<boolean>(false)

  const onClose = () => {
    setDisplayForm(false)
  }

  const showModal = () => {
    setDisplayForm(true)
  }

  const [randomCode, setRandomCode] = useState<string>(() =>
    generateRandomAlphanumericCode(DEVICE_CODE_LENGTH)
  )

  useEffect(() => {
    if (displayForm === true) {
      const generatedCode = generateRandomAlphanumericCode(DEVICE_CODE_LENGTH)
      setRandomCode(generatedCode)
    }
  }, [displayForm])

  return (
    <Container display="flex" alignItems="center">
      <AddIcon onClick={showModal} />
      {displayForm && (
        <CreateDevice
          visible={displayForm}
          onClose={onClose}
          closable={false}
          randomCode={randomCode}
        />
      )}
    </Container>
  )
}
