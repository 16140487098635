import firstLetterToUpperCase from "../../../../../../shared/utils/helpers/firstLetterToUpperCase"
import Container from "../../../../../../ui/Container"
import Switch from "../../../../../../ui/Switch"
import Text from "../../../../../../ui/Typography/Text"
import DuplicateTimesModal from "../../DuplicateTimesModal"
import type {
  DAYS_OF_THE_WEEK_ENUM,
  IBusinessHoursForm,
  IBussinessHourType,
} from "../../hookforms.interfaces"
import BusinessTimeRange from "../BusinessTimeRange"
import { Col, Row } from "antd"
import React, { useState } from "react"
import { Controller, useFormContext } from "react-hook-form"
import styled, { css } from "styled-components"

type BusinessHoursRowProps = {
  dayIndex: number
  day: `${DAYS_OF_THE_WEEK_ENUM}`
  defaultValues?: Array<IBussinessHourType>
}

export const BusinessHoursRow: React.FC<BusinessHoursRowProps> = ({
  dayIndex,
  day,
  defaultValues,
}) => {
  const defaultCellState: Array<IBussinessHourType> = [
    { day: day, startDate: undefined, endDate: undefined },
  ]
  const inHoursInitialState: Array<IBussinessHourType> = defaultValues?.length
    ? defaultValues
    : defaultCellState
  const [displayDuplicateTimeModal, setDisplayDuplicateTimeModal] =
    useState<boolean>(false)

  const { control, setValue, getValues } = useFormContext<IBusinessHoursForm>()

  const onClose = () => {
    setDisplayDuplicateTimeModal(false)
  }

  const showModal = () => {
    setDisplayDuplicateTimeModal(true)
  }

  const deleteSchedule = (scheduleIndex: string | number) => {
    const value = getValues(`schedules.${dayIndex}.defaultValues`) ?? []
    const schudeles = value?.filter((_, id) => id.toString() !== scheduleIndex)

    setValue(`schedules.${dayIndex}.defaultValues`, schudeles, {
      shouldValidate: true,
    })
  }

  const addSchedule = () => {
    const schedules = getValues(`schedules.${dayIndex}.defaultValues`) ?? []
    const value = schedules?.length ? schedules : defaultCellState
    const schudeles = [...value, { day, startDate: "", endDate: "" }]

    setValue(`schedules.${dayIndex}.defaultValues`, schudeles, {
      shouldValidate: true,
    })
  }

  return (
    <>
      <StyledContainer>
        <Row
          gutter={[
            { xs: 0, sm: 0, md: 0, lg: 0 },
            { xs: 18, sm: 18, md: 24, lg: 24 },
          ]}
        >
          <Col xs={24} sm={24} md={24} lg={6} xl={6}>
            <Container display="flex" alignItems="center" gap="18px">
              {/* The enpoint is missing to be able to show the switch */}
              <Container hidden>
                <Controller
                  control={control}
                  name={`schedules.${dayIndex}.open`}
                  render={({ field: { value, onChange } }) => (
                    <Switch onChange={onChange} checked={value} size="small" />
                  )}
                />
              </Container>
              <Text size="s" weight="bold">
                {firstLetterToUpperCase(day)}
              </Text>
            </Container>
          </Col>
          <Col xs={24} sm={24} md={24} lg={18} xl={18}>
            <Container
              display="flex"
              flexDirection="column"
              gap="12px"
              justifyContent="flex-end"
            >
              {inHoursInitialState?.map((_, scheduleIndex) => (
                <BusinessTimeRange
                  key={scheduleIndex}
                  dayIndex={dayIndex}
                  scheduleIndex={scheduleIndex}
                  addSchedule={addSchedule}
                  deleteSchedule={deleteSchedule}
                  copyShedule={showModal}
                />
              ))}
            </Container>
          </Col>
        </Row>
      </StyledContainer>

      {displayDuplicateTimeModal && (
        <DuplicateTimesModal
          dayCopy={day}
          visible={displayDuplicateTimeModal}
          onClose={onClose}
          schedulesCopy={inHoursInitialState}
        />
      )}
    </>
  )
}

const StyledContainer = styled.div`
  width: 100%;
  padding: 24px 0;
  ${({ theme }) => css`
    border-bottom: 1px solid ${theme.colors["Neutral3"]};
  `}
`
