import * as Types from '../../../../../shared/graphql/generated/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DisableLocationTableMutationVariables = Types.Exact<{
  data: Types.DisableLocationTableInput;
}>;


export type DisableLocationTableMutation = { __typename?: 'Mutation', disableLocationTable: { __typename?: 'LocationTableModel', uuid: string, name: string, description?: string | null, capacity: number, isActive: boolean, qrPath: string, smoking: boolean, status: Types.StatusTableEnum, tableIdentifier: string } };


export const DisableLocationTableDocument = gql`
    mutation disableLocationTable($data: DisableLocationTableInput!) {
  disableLocationTable(data: $data) {
    uuid
    name
    description
    capacity
    isActive
    qrPath
    smoking
    status
    tableIdentifier
  }
}
    `;
export type DisableLocationTableMutationFn = Apollo.MutationFunction<DisableLocationTableMutation, DisableLocationTableMutationVariables>;

/**
 * __useDisableLocationTableMutation__
 *
 * To run a mutation, you first call `useDisableLocationTableMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDisableLocationTableMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [disableLocationTableMutation, { data, loading, error }] = useDisableLocationTableMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useDisableLocationTableMutation(baseOptions?: Apollo.MutationHookOptions<DisableLocationTableMutation, DisableLocationTableMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DisableLocationTableMutation, DisableLocationTableMutationVariables>(DisableLocationTableDocument, options);
      }
export type DisableLocationTableMutationHookResult = ReturnType<typeof useDisableLocationTableMutation>;
export type DisableLocationTableMutationResult = Apollo.MutationResult<DisableLocationTableMutation>;
export type DisableLocationTableMutationOptions = Apollo.BaseMutationOptions<DisableLocationTableMutation, DisableLocationTableMutationVariables>;