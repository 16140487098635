import type { DeliveryIntegrationEnum } from "../../../../../shared/graphql/generated/types"
import type { IntlMessage } from "../../../../../shared/types"

type ICustomDeliveryIntegration = {
  [key in DeliveryIntegrationEnum]: IntlMessage
}

export const CustomDeliveryIntegrationTitle: ICustomDeliveryIntegration = {
  ONFLEET: {
    id: "restaurants.orders.order.delivery.service.onfleet",
    defaultMessage: "OnFleet",
  },
  DOORDASH: {
    id: "restaurants.orders.order.delivery.service.doordash",
    defaultMessage: "Doordash",
  },
}
