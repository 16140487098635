import { SubMenuSkeleton } from "./SubMenu.skeleton"
import range from "lodash/range"
import React from "react"
import styled from "styled-components"

export const SidebarLocationMenuSkeleton: React.FC = () => {
  return (
    <StyledLocationMenuSkeleton className="sidebar-locations-skeleton">
      {range(1, 2).map((key) => (
        <SubMenuSkeleton key={key} />
      ))}
    </StyledLocationMenuSkeleton>
  )
}

const StyledLocationMenuSkeleton = styled.div`
  &.sidebar-locations-skeleton {
    margin: 0 16px;
    .ant-skeleton-title {
      margin-top: 0;
    }
  }
`
