import { device } from "../../../../shared/breakpoints/responsive"
import { useMediaQuery } from "../../../../shared/hooks/useMediaQuery"
import { colors } from "../../../../styles/global/themes/mainTheme"
import Icon from "../../../Icon"
import { Layout } from "antd"
import React, { useState } from "react"
import type { CSSProperties } from "styled-components"
import styled, { css } from "styled-components"

const triggerStyle: CSSProperties = {
  border: `1px solid ${colors.Neutral3}`,
  borderLeft: "none",
  backgroundColor: colors.white,
  borderTopRightRadius: 6,
  borderBottomRightRadius: 6,
}

interface SiderProps {
  children?: React.ReactNode
  collapsed?: boolean
}

export const Sider: React.FC<SiderProps> = (props) => {
  const [isCollapsed, setIsCollapsed] = useState(false)
  const smallerThanMediumScreen = useMediaQuery(device.md)

  const changeCollapsed = (change: boolean) => {
    setIsCollapsed(change)
  }

  return (
    <SiderStyled
      role="sider"
      id="sider"
      {...props}
      breakpoint="md"
      collapsedWidth={0}
      trigger={
        <Icon
          remixiconClass={isCollapsed ? "ri-side-bar-fill" : "ri-side-bar-line"}
        />
      }
      collapsible={smallerThanMediumScreen}
      onBreakpoint={(b) => changeCollapsed(b)}
      onCollapse={(b) => changeCollapsed(b)}
      zeroWidthTriggerStyle={triggerStyle}
    />
  )
}

export const SiderStyled = styled(Layout.Sider).attrs(({ theme }) => ({
  width: theme.sizing.sidebar,
}))`
  ${({ theme }) => css`
    background: ${theme.colors.white};
    height: 100vh;
    box-shadow: inset -1px 0px 0px ${theme.colors["Neutral3"]};

    &.ant-layout-sider-collapsed {
      .settings-bar__footer {
        display: none;
      }
    }

    .ant-layout-sider-children {
      overflow: hidden;
    }
  `}
`
