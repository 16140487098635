import getInitials from "../../../../../shared/utils/helpers/getInitials"
import Icon from "../../../../Icon"
import ImageIcon from "../../../../ImageIcon"
import Text from "../../../../Typography/Text"
import React from "react"
import styled, { css } from "styled-components"

interface IRestaurantItemProps {
  restaurantUUID: string
  restaurantName: string
  isSelected?: boolean
  icon?: string
}

export const RestaurantItem: React.FC<IRestaurantItemProps> = ({
  restaurantName,
  isSelected,
  icon,
}) => {
  return (
    <StyledRestaurantWrapper>
      <StyledLeftContainer>
        {icon ? (
          <img width="20px" height="20px" src={icon} />
        ) : (
          <ImageIcon size={20} color="Secondary5" shape="square">
            {getInitials(restaurantName)}
          </ImageIcon>
        )}
        <Text
          size="s"
          className="rest-item__left-container__title"
          title={restaurantName}
          ellipsis
        >
          {restaurantName}
        </Text>
      </StyledLeftContainer>
      <StyledRightContainer>
        {" "}
        {isSelected && <Icon remixiconClass="ri-check-line" />}
      </StyledRightContainer>
    </StyledRestaurantWrapper>
  )
}

RestaurantItem.defaultProps = {
  isSelected: false,
}

const StyledLeftContainer = styled.div`
  width: 152px;
  overflow: hidden;
  display: flex;
  align-items: center;

  .rest-item__left-container__title {
    align-self: center;
    margin-left: 8px;
  }

  .ant-typography {
    width: 120px;
    overflow-x: hidden;
    text-overflow: ellipsis;
  }
`

const StyledRightContainer = styled.div`
  display: flex;
  align-items: center;
  ${({ theme }) => css`
    color: ${theme.colors.Neutral5};
  `}
`

const StyledRestaurantWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`
