import { useGeneralContext } from "../../../../shared/contexts/StoreProvider"
import { useAutoLoaderContext } from "../../../../ui/AutoLoader/AutoLoaderProvider"
import Container from "../../../../ui/Container"
import { showGraphqlErrors } from "../../../../ui/ErrorList"
import InputNumber from "../../../../ui/Inputs/InputNumber"
import SimpleInput from "../../../../ui/Inputs/SimpleInput"
import DetailPage from "../../../../ui/Layouts/MainLayout/DetailPage"
import PageTitle from "../../../../ui/PageTitle"
import Switch from "../../../../ui/Switch"
import HighlightedText from "../../../../ui/Typography/HighlightedText"
import Text from "../../../../ui/Typography/Text"
import { CreateOrUpdateLoyaltyProgramResolver } from "./CreateLoyaltyProgram.yup"
import { useCreateOrUpdateLoyaltyProgramMutation } from "./GraphQL/createOrUpdateLoyaltyProgram.generated"
import {
  GetLoyaltyProgramByRestaurantDocument,
  useGetLoyaltyProgramByRestaurantQuery,
} from "./GraphQL/getLoyaltyProgramByRestaurant.generated"
import { LoyaltyProgramSkeleton } from "./LoyaltyProgram.skeleton"
import type { ILoyaltyProgram } from "./interfaces/loyaltyProgram.interfaces"
import get from "lodash/get"
import React, { useState } from "react"
import { Controller, useForm } from "react-hook-form"
import { useIntl } from "react-intl"
import { FormattedMessage } from "react-intl"
import styled from "styled-components"

export const LoyaltyProgram: React.FC = () => {
  const intl = useIntl()
  const [settingValues, setSettingValues] = useState(false)

  const { setStatus } = useAutoLoaderContext()

  const defaultValues = {
    conversionRate: 10,
    increment: 0,
    isActive: false,
  }

  const {
    handleSubmit,
    reset,
    control,
    formState: { errors, isValid, dirtyFields },
  } = useForm<ILoyaltyProgram>({
    mode: "onBlur",
    resolver: CreateOrUpdateLoyaltyProgramResolver,
    defaultValues,
  })

  const {
    state: {
      currentRestaurant: { uuid: restaurantUUID },
    },
  } = useGeneralContext()

  const [createOrUpdateLoyaltyProgram] =
    useCreateOrUpdateLoyaltyProgramMutation()

  const { loading } = useGetLoyaltyProgramByRestaurantQuery({
    variables: {
      restaurantUUID,
    },
    notifyOnNetworkStatusChange: true,
    returnPartialData: true,
    skip: !restaurantUUID,
    fetchPolicy: "network-only",
    onError: (apolloError) => {
      setSettingValues(false)
      showGraphqlErrors(apolloError)
    },
    onCompleted: ({ getLoyaltyProgramByRestaurant: data }) => {
      setSettingValues(true)

      if (data) {
        reset({
          increment: data?.increment,
          loyaltyProgramUUID: data?.uuid,
          conversionRate: data?.conversionRate,
          isActive: data?.isActive,
        })
      } else {
        reset(defaultValues)
      }

      setSettingValues(false)
    },
  })

  const saveLoyaltyProgram = async (formData: ILoyaltyProgram) => {
    if (!isValid) {
      return
    }

    try {
      setStatus("LOADING")

      const { data } = await createOrUpdateLoyaltyProgram({
        variables: {
          data: {
            restaurantUUID,
            conversionRate: formData.conversionRate,
            increment: formData.increment ?? undefined,
            isActive: formData.isActive,
            loyaltyProgramUUID: formData.loyaltyProgramUUID ?? undefined,
          },
        },
        refetchQueries: [GetLoyaltyProgramByRestaurantDocument],
      })

      const response = get(data, "createOrUpdateLoyaltyProgram")

      if (response) {
        reset({
          increment: response?.increment,
          loyaltyProgramUUID: response?.uuid,
          conversionRate: response?.conversionRate,
          isActive: response?.isActive,
        })
      }
    } catch (error) {
      showGraphqlErrors(error)
    }

    setStatus("IDLE")
  }

  if (loading || settingValues) {
    return <LoyaltyProgramSkeleton />
  }

  return (
    <DetailPage role="content-layout">
      <PageTitle
        title={intl.formatMessage({
          id: "restaurants.loyalty.program.info.title",
          defaultMessage: "Loyalty program",
        })}
        description={intl.formatMessage({
          id: "restaurants.loyalty.program.info.description",
          defaultMessage: "Manage your Loyalty program settings",
        })}
      />
      <StyledContentTermsConditions>
        <Container display="flex" flexDirection="column" gap="12px">
          <Text size="m" weight="bold">
            {intl.formatMessage({
              id: "restaurants.loyalty.program.terms.conditions.title",
              defaultMessage: "Terms and conditions",
            })}
          </Text>
          <div>
            <Text size="s" color="Neutral7">
              {intl.formatMessage({
                id: "restaurants.loyalty.program.terms.conditions.1",
                defaultMessage:
                  "The Crmb Loyalty Program is a feature that allows you to stablish rewards, in form of store credit, for consume the clients make in your restaurant through the mobile app.",
              })}
            </Text>
            <Text size="s" color="Neutral7">
              {intl.formatMessage({
                id: "restaurants.loyalty.program.terms.conditions.2",
                defaultMessage:
                  "The program is going to be applied to all of your registred locations.",
              })}
            </Text>

            <Text size="s" color="Neutral7">
              <FormattedMessage
                id="restaurants.loyalty.program.terms.conditions.3"
                defaultMessage="The conversion rate for how the costumer obtains points is default to { obtainPoints } (Taxes and delivery fees are excluded)."
                values={{
                  obtainPoints: (
                    <HighlightedText size="s" weight="bold" color="Neutral7">
                      {intl.formatMessage({
                        id: "restaurants.loyalty.program.terms.conditions.3.1",
                        defaultMessage: "1 point per $1 spent",
                      })}
                    </HighlightedText>
                  ),
                }}
              />
            </Text>

            <Text size="s" color="Neutral7">
              <FormattedMessage
                id="restaurants.loyalty.program.terms.conditions.4"
                defaultMessage="The conversion rate for how the costumer redeem points is default to { redeemPoints }"
                values={{
                  redeemPoints: (
                    <HighlightedText size="s" weight="bold" color="Neutral7">
                      {intl.formatMessage({
                        id: "restaurants.loyalty.program.terms.conditions.4.1",
                        defaultMessage: "10 point per $1 of store credit.",
                      })}
                    </HighlightedText>
                  ),
                }}
              />
            </Text>
            <Text size="s" color="Neutral7">
              {intl.formatMessage({
                id: "restaurants.loyalty.program.terms.conditions.5",
                defaultMessage:
                  "You can stablish the increment value for how the points are exchanged in store credit for the costumers.",
              })}
            </Text>
          </div>
        </Container>
      </StyledContentTermsConditions>

      <StyledContentConversionRate>
        <Container display="flex" flexDirection="column" gap="12px">
          <Text size="m" weight="bold">
            {intl.formatMessage({
              id: "restaurants.loyalty.program.conversion.rate.title",
              defaultMessage: "Conversion rate",
            })}
          </Text>
          <Text size="s" color="Neutral7">
            {intl.formatMessage({
              id: "restaurants.loyalty.program.conversion.rate.description",
              defaultMessage:
                "This is the conversion rate for the customer to redeem points. The default value is for every 10 points the customer obtains, it can be redeemed for $1 of store credit.",
            })}
          </Text>
          <Container display="flex" alignItems="center" gap="32px">
            <Controller
              name="conversionRate"
              control={control}
              render={({ field }) => (
                <InputNumber
                  {...field}
                  min={1}
                  size="middle"
                  width="102px"
                  type="number"
                  requirement="required"
                  placeholder={intl.formatMessage({
                    id: "restaurants.loyalty.program.conversion.rate.default.points",
                    defaultMessage: "10",
                  })}
                  suffix={intl.formatMessage({
                    id: "restaurants.loyalty.program.conversion.rate.subfix",
                    defaultMessage: "points",
                  })}
                  onBlur={() => {
                    if (dirtyFields.conversionRate) {
                      field.onBlur()
                      handleSubmit(saveLoyaltyProgram)()
                    }
                  }}
                  hasError={!!errors.conversionRate}
                  helperText={errors.conversionRate?.message}
                />
              )}
            />

            <Text size="m" color="Neutral7">
              =
            </Text>
            <SimpleInput
              min={1}
              name="storeCredits"
              size="middle"
              width="124px"
              requirement="required"
              placeholder={intl.formatMessage({
                id: "restaurants.loyalty.program.conversion.rate.default.store.credits",
                defaultMessage: "1 store credits",
              })}
              prefix={intl.formatMessage({
                id: "generic.symbol.currency",
                defaultMessage: "$",
              })}
              disabled
            />
          </Container>
        </Container>
      </StyledContentConversionRate>
      <StyledContentIncrementValue>
        <Container display="flex" flexDirection="column" gap="12px">
          <Text size="m" weight="bold">
            {intl.formatMessage({
              id: "restaurants.loyalty.program.increment.value.title",
              defaultMessage: "Increment value",
            })}
          </Text>
          <Text size="s" color="Neutral7">
            {intl.formatMessage({
              id: "restaurants.loyalty.program.increment.value.description",
              defaultMessage:
                "Set the increment  where the customers can redeem their earned points.",
            })}
          </Text>
          <Container display="flex" alignItems="center" gap="16px">
            <Controller
              name="increment"
              control={control}
              render={({ field }) => (
                <InputNumber
                  {...field}
                  min={0}
                  aria-label="price-input"
                  hasError={!!errors?.increment}
                  helperText={errors.increment?.message}
                  placeholder="0"
                  type="number"
                  prefix={intl.formatMessage({
                    id: "generic.symbol.currency",
                    defaultMessage: "$",
                  })}
                  autoComplete="off"
                  width="94px"
                  size="middle"
                  precision={2}
                  onBlur={() => {
                    if (dirtyFields.increment) {
                      field.onBlur()
                      handleSubmit(saveLoyaltyProgram)()
                    }
                  }}
                  onlyInteger
                />
              )}
            />
          </Container>
        </Container>
      </StyledContentIncrementValue>
      <StyledContentActiveProgramCheck>
        <Text size="m" weight="bold">
          {intl.formatMessage({
            id: "restaurants.loyalty.program.activate.program.title",
            defaultMessage: "Activate program",
          })}
        </Text>
        <Controller
          name="isActive"
          control={control}
          render={({ field }) => {
            return (
              <Switch
                size="default"
                checked={!!field.value}
                onChange={(checked: boolean) => {
                  isValid && field.onChange({ target: { value: checked } })
                  handleSubmit(saveLoyaltyProgram)()
                }}
                className="activate-program-check"
              />
            )
          }}
        />
      </StyledContentActiveProgramCheck>
    </DetailPage>
  )
}

const StyledContentTermsConditions = styled.div`
  margin-top: 48px;
`

const StyledContentConversionRate = styled.div`
  margin-top: 32px;
`

const StyledContentIncrementValue = styled.div`
  margin-top: 32px;
`

const StyledContentActiveProgramCheck = styled.div`
  margin-top: 64px;

  .activate-program-check {
    margin-top: 12px;
  }
`
