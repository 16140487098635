export const ADJUSMENT_BETWEEN_12_AND_24_HOUR_FORMAT = 12
export const MINUTE_VARIANCE = 15
export const DECREMENT_AFTERNOON_HOUR = 11
export const INCREMENT_AFTERNOON_HOUR = 13
export const MIDDAY = 12
export const MIDNIGHT = 0

export const DISABLE_HOUR_LOWER_LIMIT = 12
export const DISABLE_MINUTE_LOWER_LIMIT = 0
export const DISABLE_HOUR_UPPER_LIMIT = 11
export const DISABLE_MINUTE_UPPER_LIMIT = 45

export const DEFAULT_START_HOUR_VALUE = 9
export const DEFAULT_END_HOUR_VALUE = 19
export const DEFAULT_MINUTE_VALUE = 0

export const DAY_START_HOUR = "00:00"
export const DAY_END_HOUR = "23:59"

export const ALL_DAY_MINUTES_MIN = 1439
