import {
  GetMenuListQuery,
  GetMenuListQueryVariables,
} from "../../../../../GraphQL/Queries/getMenuList.generated"
import {
  MenuStatusEnum,
  SortingByMenuEnum,
} from "../../../../../shared/graphql/generated/types"
import { LazyQueryExecFunction } from "@apollo/client"

export interface GetHasMenuSetupCompletedArgs {
  getMenuList: LazyQueryExecFunction<
    GetMenuListQuery,
    GetMenuListQueryVariables
  >
}

export const getHasMenuSetupCompleted = async ({
  getMenuList,
}: GetHasMenuSetupCompletedArgs) => {
  const { data } = await getMenuList({
    fetchPolicy: "cache-first",
    variables: {
      sort: SortingByMenuEnum.DATE_DESC,
    },
  })

  return !!data?.getMenuList.results?.some(
    (menu) => menu.status === MenuStatusEnum.PUBLISHED
  )
}
