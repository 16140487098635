import { RuleCollapse } from "../../components/RuleCollapse"
import { RuleChip } from "../../components/RuleCollapse/RuleChip"
import { CateringLabels, RulesMessage } from "../../shared/config"
import { CateringForm, FeeQuantityEnum } from "../../shared/hookforms.intefaces"
import { validationCateringTitle } from "../../shared/validation-catering.title"
import styles from "./TypeOfService.module.css"
import { Checkbox, Group, Stack, Text } from "@mantine/core"
import { Controller, useFormContext, useWatch } from "react-hook-form"
import { FormattedMessage, useIntl } from "react-intl"

export const TypeOfServiceForm = () => {
  const {
    control,
    setValue,
    trigger,
    formState: { errors },
  } = useFormContext<CateringForm>()
  const intl = useIntl()

  const [isActive, typeOfService] = useWatch({
    control,
    name: ["isActive", "rules.typeOfService"],
  })

  const chipData = (() => {
    return (Object.keys(typeOfService) as Array<keyof typeof typeOfService>)
      .filter((key) => typeOfService[key])
      .map((key) => intl.formatMessage(CateringLabels[key]))
  })()

  const hasErrors = errors.rules?.typeOfService
  return (
    <RuleCollapse
      label={intl.formatMessage(RulesMessage.typeOfService.title)}
      disabled={!isActive}
      error={Boolean(hasErrors)}
      rightSection={
        chipData.length > 0 && <RuleChip data={chipData} disabled={!isActive} />
      }
    >
      <Text size="sm">
        <FormattedMessage {...RulesMessage.typeOfService.description} />
      </Text>
      <Stack>
        <Group noWrap>
          <Controller
            control={control}
            name="rules.typeOfService.pickup"
            render={({ field, fieldState: { error } }) => (
              <Checkbox
                error={
                  error?.message
                    ? intl.formatMessage(
                        validationCateringTitle[error?.message]
                      )
                    : undefined
                }
                checked={typeOfService.pickup}
                onChange={(event) => {
                  const unCheckingAll =
                    chipData.length === 1 && !event.currentTarget.checked
                  const checkingFirst =
                    chipData.length === 0 && event.currentTarget.checked

                  field.onChange(event)
                  setValue(
                    "rules.typeOfService.pickup",
                    event.currentTarget.checked
                  )
                  setValue(
                    "rules.minimumAmount.pickup.apply",
                    event.currentTarget.checked
                  )
                  setValue(
                    "rules.cateringFee.pickup.apply",
                    event.currentTarget.checked
                  )
                  setValue(
                    "rules.cateringFee.pickup.type",
                    FeeQuantityEnum.AMOUNT
                  )

                  trigger(["rules.minimumAmount", "rules.cateringFee"])

                  if (unCheckingAll || checkingFirst)
                    trigger("rules.typeOfService")
                }}
                className={styles["rule-checkbox"]}
                label={intl.formatMessage(CateringLabels.pickup)}
              />
            )}
          />
          <Controller
            control={control}
            name="rules.typeOfService.delivery"
            render={({ field, fieldState: { error } }) => (
              <Checkbox
                error={
                  error?.message
                    ? intl.formatMessage(
                        validationCateringTitle[error?.message]
                      )
                    : undefined
                }
                checked={typeOfService.delivery}
                onChange={(event) => {
                  const unCheckingAll =
                    chipData.length === 1 && !event.currentTarget.checked
                  const checkingFirst =
                    chipData.length === 0 && event.currentTarget.checked

                  field.onChange(event)
                  setValue(
                    "rules.typeOfService.delivery",
                    event.currentTarget.checked
                  )
                  setValue(
                    "rules.minimumAmount.delivery.apply",
                    event.currentTarget.checked
                  )
                  setValue(
                    "rules.cateringFee.delivery.apply",
                    event.currentTarget.checked
                  )
                  setValue(
                    "rules.cateringFee.delivery.type",
                    FeeQuantityEnum.AMOUNT
                  )
                  trigger(["rules.minimumAmount", "rules.cateringFee"])
                  if (unCheckingAll || checkingFirst)
                    trigger("rules.typeOfService")
                }}
                className={styles["rule-checkbox"]}
                label={intl.formatMessage(CateringLabels.delivery)}
              />
            )}
          />
        </Group>
        <Group noWrap>
          <Controller
            control={control}
            name="rules.typeOfService.foodTruckExperience"
            render={({ field, fieldState: { error } }) => (
              <Checkbox
                error={
                  error?.message
                    ? intl.formatMessage(
                        validationCateringTitle[error?.message]
                      )
                    : undefined
                }
                checked={typeOfService.foodTruckExperience}
                onChange={(event) => {
                  const unCheckingAll =
                    chipData.length === 1 && !event.currentTarget.checked
                  const checkingFirst =
                    chipData.length === 0 && event.currentTarget.checked

                  field.onChange(event)
                  setValue(
                    "rules.typeOfService.foodTruckExperience",
                    event.currentTarget.checked
                  )
                  setValue(
                    "rules.minimumAmount.foodTruckExperience.apply",
                    event.currentTarget.checked
                  )
                  setValue(
                    "rules.cateringFee.foodTruckExperience.apply",
                    event.currentTarget.checked
                  )
                  setValue(
                    "rules.cateringFee.foodTruckExperience.type",
                    FeeQuantityEnum.AMOUNT
                  )
                  trigger(["rules.minimumAmount", "rules.cateringFee"])

                  if (unCheckingAll || checkingFirst)
                    trigger("rules.typeOfService")
                }}
                className={styles["rule-checkbox"]}
                label={intl.formatMessage(CateringLabels.foodTruckExperience)}
              />
            )}
          />
          <Controller
            control={control}
            name="rules.typeOfService.insideVenue"
            render={({ field, fieldState: { error } }) => (
              <Checkbox
                error={
                  error?.message
                    ? intl.formatMessage(
                        validationCateringTitle[error?.message]
                      )
                    : undefined
                }
                checked={typeOfService.insideVenue}
                onChange={(event) => {
                  const unCheckingAll =
                    chipData.length === 1 && !event.currentTarget.checked
                  const checkingFirst =
                    chipData.length === 0 && event.currentTarget.checked

                  field.onChange(event)
                  setValue(
                    "rules.typeOfService.insideVenue",
                    event.currentTarget.checked
                  )
                  setValue(
                    "rules.minimumAmount.insideVenue.apply",
                    event.currentTarget.checked
                  )
                  setValue(
                    "rules.cateringFee.insideVenue.apply",
                    event.currentTarget.checked
                  )
                  setValue(
                    "rules.cateringFee.insideVenue.type",
                    FeeQuantityEnum.AMOUNT
                  )
                  trigger(["rules.minimumAmount", "rules.cateringFee"])

                  if (unCheckingAll || checkingFirst)
                    trigger("rules.typeOfService")
                }}
                className={styles["rule-checkbox"]}
                label={intl.formatMessage(CateringLabels.insideVenue)}
              />
            )}
          />
        </Group>
      </Stack>
    </RuleCollapse>
  )
}
