import type {
  OrderThrottlingRule,
  WeekDaysEnum,
} from "../../../../../shared/graphql/generated/types"
import ThrottlingHoursCell from "../ThrottlingHoursCell"
import reduceIntervalToDaysOfTheWeek from "../utils/getTimeRangesByDaysOfTheWeek"
import type { TimeInterval } from "../utils/override-rules.types"
import { List } from "antd"
import classnames from "classnames"
import React, { useState } from "react"
import styled from "styled-components"

type ThrottlingHoursProps = {
  defaultValues?: Array<OrderThrottlingRule>
  onChange?: (changedHours: Array<TimeInterval>) => void
  readonly?: boolean
  invalidRanges?: Array<{ day: string; rangeIndexes: Array<number> }>
  invalidOrdersLimitRanges?: Array<{ day: string; rangeIndexes: Array<number> }>
  disabled?: boolean
  defaultOrdersLimitValue?: number
}

export const ThrottlingHours: React.FC<ThrottlingHoursProps> = ({
  defaultValues,
  invalidRanges,
  invalidOrdersLimitRanges,
  onChange,
  defaultOrdersLimitValue,
  readonly = false,
}) => {
  const defaultHours =
    defaultValues?.map((item) => ({ ...item, day: item.day.toUpperCase() })) ||
    []
  const [, setUpdatedHours] = useState<Array<TimeInterval>>(defaultHours)
  const weekDays = reduceIntervalToDaysOfTheWeek(defaultValues || [])

  const onCellChange = (
    scheduleOfchangedHours: TimeInterval[],
    day: string,
    isChecked: boolean
  ) => {
    const validSchedule = isChecked ? scheduleOfchangedHours : []

    setUpdatedHours((prev) => {
      const newSchedules = prev || []
      const scheduleLeft = newSchedules.filter(
        (newSchedule) => newSchedule.day?.toUpperCase() !== day.toUpperCase()
      )

      const collectedSchedule = [...scheduleLeft, ...validSchedule]
      onChange && onChange(collectedSchedule)

      return collectedSchedule
    })
  }

  return (
    <StyledList className="custom-list">
      {weekDays?.map((weekDay, weekDayIndex) => (
        <StyledList.Item
          key={weekDay.day}
          className={classnames({ "first-item-list": weekDayIndex === 0 })}
        >
          <ThrottlingHoursCell
            day={weekDay.day.toUpperCase() as WeekDaysEnum}
            rules={weekDay.defaultValues}
            onChange={onCellChange}
            readonly={readonly}
            invalidIndexes={
              invalidRanges?.find(
                ({ day }) => day.toUpperCase() === weekDay.day
              )?.rangeIndexes
            }
            invalidOrdersLimitIndexes={
              invalidOrdersLimitRanges?.find(
                ({ day }) => day.toUpperCase() === weekDay.day
              )?.rangeIndexes
            }
            defaultOrdersLimitValue={defaultOrdersLimitValue}
          />
        </StyledList.Item>
      ))}
    </StyledList>
  )
}

const StyledList = styled(List)`
  &.custom-list {
    .first-item-list {
      padding: 16px 0px 36px 0px !important;
    }

    .ant-list-item {
      padding: 36px 0px;
    }
  }
`
