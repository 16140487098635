import { useSearchParams } from "../../../../shared/hooks/useSearchParams"
import { EMPLOYEE_LIST_FILTER_NAME } from "../../../../shared/utils/constant/searchParams"
import { EmployeesEmpty } from "./EmployeesEmpty"
import { EmployeesHeader } from "./EmployeesHeader"
import { EmployeeFilterEnum } from "./EmployeesHeader/config"
import { EmployeesList } from "./EmployeesList"
import styles from "./EmployeesNew.module.css"
import { EmployeesSkeleton } from "./EmployeesSkeleton"
import { useEmployeesQuery } from "./GraphQL/employees.generated"
import { getIsActiveFilterValue } from "./utils/get-is-active-filter-value"
import { Container, ScrollArea } from "@mantine/core"
import { useState } from "react"

export const Employees = () => {
  const { getParam } = useSearchParams()
  const filter = getParam(EMPLOYEE_LIST_FILTER_NAME) as EmployeeFilterEnum
  const [isActive, setIsActive] = useState<boolean | undefined>(
    getIsActiveFilterValue(filter)
  )
  const { data: employeesData, loading: loadingEmployees } = useEmployeesQuery({
    fetchPolicy: "cache-and-network",
    variables: {
      isActive: isActive,
    },
  })

  const employeesList = employeesData?.employees

  return (
    <>
      <EmployeesHeader onActive={setIsActive} filter={filter} />

      {loadingEmployees ? (
        <EmployeesSkeleton />
      ) : (
        <>
          {employeesList && employeesList?.length > 0 ? (
            <ScrollArea
              classNames={{
                root: styles["scroll-area"],
              }}
              offsetScrollbars
            >
              <EmployeesList employees={employeesList} />
            </ScrollArea>
          ) : (
            <Container size="100%" py={40}>
              <EmployeesEmpty />
            </Container>
          )}
        </>
      )}
    </>
  )
}
