import Container from "../../../../../../../ui/Container"
import { InputNumber } from "../../../../../../../ui/Inputs"
import Text from "../../../../../../../ui/Typography/Text"
import type { IDeliveryZoneForm } from "./interfaces/hookforms.interfaces"
import { Controller, useFormContext } from "react-hook-form"
import { useIntl } from "react-intl"

const FormDeliveryZoneModal = () => {
  const intl = useIntl()

  const {
    control,
    formState: { errors },
  } = useFormContext<IDeliveryZoneForm>()

  return (
    <Container
      width="100%"
      padding="0 40px"
      display="flex"
      flexDirection="column"
      gap="32px"
    >
      <Container width="100%" display="flex" flexDirection="column" gap="12px">
        <Text weight="bold" size="m">
          {intl.formatMessage({
            id: "settings.delivery.zones.form.modal.zone.radius.title",
            defaultMessage: "Zone Radius",
          })}
        </Text>

        <Controller
          control={control}
          name="maxDistance"
          render={({ field }) => (
            <InputNumber
              {...field}
              aria-label="max-distance-input"
              min={1}
              type="number"
              placeholder={intl.formatMessage({
                id: "settings.delivery.zones.form.modal.zone.radius.input.placeholder",
                defaultMessage: "Max",
              })}
              suffix={intl.formatMessage({
                id: "settings.delivery.zones.form.modal.zone.radius.input.suffix",
                defaultMessage: "Miles",
              })}
              hasError={!!errors?.maxDistance}
              errorMessage={errors?.maxDistance?.message}
            />
          )}
        />
      </Container>

      <Container width="100%" display="flex" flexDirection="column" gap="12px">
        <Text weight="bold" size="m">
          {intl.formatMessage({
            id: "settings.delivery.zones.form.modal.delivery.fee.title",
            defaultMessage: "Delivery Fee",
          })}
        </Text>

        <Controller
          control={control}
          name="price"
          render={({ field }) => (
            <InputNumber
              {...field}
              aria-label="price-input"
              min={1}
              step={0.01}
              precision={2}
              type="number"
              prefix={intl.formatMessage({
                id: "generic.symbol.currency",
                defaultMessage: "$",
              })}
              hasError={!!errors?.price}
              errorMessage={errors?.price?.message}
            />
          )}
        />
      </Container>
    </Container>
  )
}

export default FormDeliveryZoneModal
