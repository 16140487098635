import LayoutBase from "../LayoutBase"
import Content from "../MainLayout/Content"
import Sider from "../components/Sider"
import SettingsBar from "./SettingsBar"
import React from "react"

export const SettingsLayout: React.FC = ({ children }) => {
  return (
    <LayoutBase flexDirection="row">
      <Sider>
        <SettingsBar />
      </Sider>
      <Content>{children}</Content>
    </LayoutBase>
  )
}
