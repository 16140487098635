export const columns = [
  {
    id: "restaurants.locations.hours.of.operation.holidays.table.holiday",
    title: "Holiday",
    width: "25%",
  },
  {
    id: "restaurants.locations.hours.of.operation.holidays.table.availability",
    title: "Availability",
    width: "20%",
  },
  {
    id: "restaurants.locations.hours.of.operation.holidays.table.date",
    title: "Date",
    width: "15%",
  },
  {
    id: "restaurants.locations.hours.of.operation.holidays.table.time",
    title: "Time",
    width: "30%",
  },
  {
    title: "",
    width: "44px",
  },
]
