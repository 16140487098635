import * as Types from '../../../../../../shared/graphql/generated/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateTableZoneMutationVariables = Types.Exact<{
  data: Types.UpdateTableZoneInput;
}>;


export type UpdateTableZoneMutation = { __typename?: 'Mutation', updateTableZone: { __typename?: 'TableZoneModel', uuid: string, name: string } };


export const UpdateTableZoneDocument = gql`
    mutation updateTableZone($data: UpdateTableZoneInput!) {
  updateTableZone(data: $data) {
    uuid
    name
  }
}
    `;
export type UpdateTableZoneMutationFn = Apollo.MutationFunction<UpdateTableZoneMutation, UpdateTableZoneMutationVariables>;

/**
 * __useUpdateTableZoneMutation__
 *
 * To run a mutation, you first call `useUpdateTableZoneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTableZoneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTableZoneMutation, { data, loading, error }] = useUpdateTableZoneMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateTableZoneMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTableZoneMutation, UpdateTableZoneMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTableZoneMutation, UpdateTableZoneMutationVariables>(UpdateTableZoneDocument, options);
      }
export type UpdateTableZoneMutationHookResult = ReturnType<typeof useUpdateTableZoneMutation>;
export type UpdateTableZoneMutationResult = Apollo.MutationResult<UpdateTableZoneMutation>;
export type UpdateTableZoneMutationOptions = Apollo.BaseMutationOptions<UpdateTableZoneMutation, UpdateTableZoneMutationVariables>;