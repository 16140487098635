import { IntlMessage } from "../../types"
import { capitalizeFirstLetter } from "./capitalizeFirstLetter"

export enum SortingMethodsEnum {
  DATE_ASC = "DATE_ASC",
  DATE_DESC = "DATE_DESC",
  NAME_ASC = "NAME_ASC",
  NAME_DESC = "NAME_DESC",
  CUSTOM = "CUSTOM",
}

export type SortingOptionsType = {
  value: string
  label: IntlMessage
}

export const sortingOptions: SortingOptionsType[] = [
  {
    value: "DATE_ASC",
    label: {
      id: "generic.sorting.date.asc",
      defaultMessage: "Date asc",
    },
  },
  {
    value: "DATE_DESC",
    label: {
      id: "generic.sorting.date.desc",
      defaultMessage: "Date desc",
    },
  },
  {
    value: "NAME_ASC",
    label: {
      id: "generic.sorting.name.asc",
      defaultMessage: "Name asc",
    },
  },
  {
    value: "NAME_DESC",
    label: {
      id: "generic.sorting.name.desc",
      defaultMessage: "Name desc",
    },
  },
  {
    value: "CUSTOM",
    label: {
      id: "generic.sorting.custom",
      defaultMessage: "Custom",
    },
  },
]

export const sortingItemComponentsOptions = sortingOptions.filter(
  (option) => option.value !== "DATE_ASC" && option.value !== "DATE_DESC"
)

export const isString = (value: unknown): value is string =>
  typeof value === "string"

export const canBeDates = (value: unknown[]) => {
  value.forEach((item) => {
    if (isString(item) && !!Number.isNaN(new Date(item).getTime()))
      //This will help to ensure that as a developer you use the function correctly
      //A user is not supposed to see this error, so we can use a generic message
      throw new Error("Invalid date")
  })
}

export const sortArrayByDate = <T>({
  array,
  key,
  ascending,
}: {
  array: Array<T>
  ascending: boolean
  key: keyof T
}) => {
  const copyArray = [...array]

  return copyArray.sort((a, b) => {
    const valueA = a[key]
    const valueB = b[key]

    canBeDates([valueA, valueB])

    if (!isString(valueA) || !isString(valueB)) {
      return 0
    }

    return ascending
      ? new Date(valueA).getTime() - new Date(valueB).getTime()
      : new Date(valueB).getTime() - new Date(valueA).getTime()
  })
}

export const sortArrayAlphabetically = <T>({
  array,
  ascending,
  key,
  subkey,
}: {
  array: Array<T>
  ascending: boolean
  key: keyof T
  subkey?: string
}) => {
  const copyArray = [...array]

  const sortedArray = copyArray.sort((a, b) => {
    const valueA = getValue(a, key, subkey)
    const valueB = getValue(b, key, subkey)

    if (!isString(valueA) || !isString(valueB)) {
      return 0
    }

    const valueACapitalized = capitalizeFirstLetter(valueA)
    const valueBCapitalized = capitalizeFirstLetter(valueB)

    return ascending
      ? valueACapitalized.localeCompare(valueBCapitalized)
      : valueBCapitalized.localeCompare(valueACapitalized)
  })
  return sortedArray
}

const getValue = <T>(obj: T, mainKey: keyof T, subKey?: string) => {
  let value: unknown = obj[mainKey]

  if (subKey && typeof value === "object") {
    value = (value as Record<string, unknown>)[subKey]
  }

  return value
}
