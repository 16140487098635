import Divider from "../../../Divider"
import Progress from "../../../Progress"
import Text from "../../../Typography/Text"
import RestaurantCompletionProgressDrawer from "./RestaurantCompletionProgressDrawer"
import { useRestaurantCompletionProgress } from "./useRestaurantCompletionProgress"
import { Skeleton } from "antd"
import { useState } from "react"
import { useIntl } from "react-intl"
import styled from "styled-components"

const RestaurantCompletionProgress = () => {
  const intl = useIntl()
  const [isDrawerVisible, setIsDrawerVisible] = useState(false)
  const restaurantCompletionProgressState = useRestaurantCompletionProgress()

  const { loading, percent, completedSteps, totalSteps } =
    restaurantCompletionProgressState

  return (
    <>
      {loading ? (
        <Container style={{ pointerEvents: "none" }}>
          <Divider />
          <StyledSkeleton paragraph={false} active />
          <StyledSkeleton paragraph={false} active />
        </Container>
      ) : (
        <Container onClick={() => setIsDrawerVisible(true)}>
          <Divider />
          <Text size="s" textAlign="center" weight="bold" color="Neutral7">
            {intl.formatMessage({
              id: "components.restaurant.completion.progress.button.text",
              defaultMessage: "Get Started",
            })}
          </Text>
          <Progress
            percent={percent}
            showInfo={false}
            style={{
              margin: "8px 0px 4px",
            }}
          />
          <Text size="s" textAlign="center" color="Neutral6">
            {intl.formatMessage(
              {
                id: "components.restaurant.completion.progress.button.progress.text",
                defaultMessage:
                  "{completedSteps} of {totalSteps} steps completed",
              },
              {
                totalSteps,
                completedSteps,
              }
            )}
          </Text>
        </Container>
      )}

      <RestaurantCompletionProgressDrawer
        visible={isDrawerVisible}
        onClose={() => {
          setIsDrawerVisible(false)
        }}
        restaurantCompletionProgressState={restaurantCompletionProgressState}
      />
    </>
  )
}

const StyledSkeleton = styled(Skeleton)`
  .ant-skeleton-title {
    margin: 8px 0;
  }
`

const Container = styled.button`
  width: 176px;
  outline: 0;
  border: none;
  padding: 0px 12px 12px;
  cursor: pointer;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.Neutral0};
  transition: background-color 0.15s ease-in-out;
  &:hover,
  &:focus {
    background-color: ${({ theme }) => theme.colors.Neutral2};
  }
`

export default RestaurantCompletionProgress
