import { SupportedImages } from "../../../../../../shared/utils/helpers/getImageMetadata"
import { EmployeeFormType } from "../../types/form.types"
import styles from "./DropzoneInput.module.css"
import { Stack, Text } from "@mantine/core"
import { Dropzone, FileWithPath } from "@mantine/dropzone"
import { IconPhoto, IconUpload, IconX } from "@tabler/icons-react"
import { ControllerRenderProps } from "react-hook-form"
import { useIntl } from "react-intl"

const supportedImage = [
  SupportedImages.AVIF,
  SupportedImages.JPEG,
  SupportedImages.JPG,
  SupportedImages.PNG,
  SupportedImages.WEBP,
]
interface DropzoneInputProps {
  onFile: (file: File) => void
  setFile: (file: FileWithPath) => void
  field: ControllerRenderProps<EmployeeFormType, "attachment">
}

export const DropzoneInput = ({
  onFile,
  setFile,
  field,
}: DropzoneInputProps) => {
  const intl = useIntl()

  return (
    <Dropzone
      onDrop={(files) => {
        if (files) {
          setFile(files[0])
          onFile(files[0])
          field.onChange(files[0])
        }
      }}
      w={130}
      h={130}
      accept={supportedImage}
      classNames={{
        root: styles["dropzone-root"],
      }}
    >
      <Stack align="center" justify="center">
        <Dropzone.Accept>
          <IconUpload size={32} />
        </Dropzone.Accept>
        <Dropzone.Reject>
          <IconX size={32} />
        </Dropzone.Reject>
        <Dropzone.Idle>
          <IconPhoto size={32} />
        </Dropzone.Idle>
        <Text ta="center" size="sm">
          {intl.formatMessage({
            id: "settings.locations.employees.form.dropzone.dropzone.input",
            defaultMessage: "Employee Photo",
          })}
        </Text>
      </Stack>
    </Dropzone>
  )
}
