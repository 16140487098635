/* eslint-disable @typescript-eslint/no-unsafe-assignment */

/* eslint-disable unicorn/no-thenable */
import yup from "../../../../../shared/yup"
import {
  CateringForm,
  FeeQuantityEnum,
  TypeOfService,
} from "../shared/hookforms.intefaces"
import {
  CODE_MINIMUM_AMOUNT_APPLY_ID,
  CODE_MINIMUM_AMOUNT_VALUE_ID,
  FIELD_CHECKBOX_REQUIRED_ID,
  FIELD_NUMBER_MIN_OR_ZERO_VALUE_ID,
  FIELD_NUMBER_MIN_VALUE_ID,
  FIELD_TYPE_REQUIRED_ERROR_ID,
} from "../shared/validation-catering.title"
import { typeOfServiceTest } from "../utils/form-yup-helpers"
import { yupResolver } from "@hookform/resolvers/yup"
import { ValidationError } from "yup"

const FormCateringSchema = yup.object().shape({
  selectedEmployees: yup.array().min(1),
  acceptanceMethod: yup.string().required(),
  rules: yup.object().shape({
    cancelPolicy: yup.object().shape({
      isApply: yup.boolean().optional(),
      cancelPolicies: yup.array().when("isApply", {
        is: true,
        then: yup
          .array()
          .of(
            yup.object().shape({
              percentage: yup
                .number()
                .typeError(FIELD_TYPE_REQUIRED_ERROR_ID)
                .required(FIELD_TYPE_REQUIRED_ERROR_ID)
                .positive(FIELD_NUMBER_MIN_VALUE_ID),
              timeBeforeEvent: yup
                .string()
                .typeError(FIELD_TYPE_REQUIRED_ERROR_ID)
                .required(FIELD_TYPE_REQUIRED_ERROR_ID),
            })
          )
          .min(1),
      }),
    }),
    prePayment: yup.object().shape({
      paymentMethod: yup.string().required(FIELD_TYPE_REQUIRED_ERROR_ID),
      paymentValue: yup
        .number()
        .typeError(FIELD_TYPE_REQUIRED_ERROR_ID)
        .required(FIELD_TYPE_REQUIRED_ERROR_ID)
        .min(0, FIELD_NUMBER_MIN_OR_ZERO_VALUE_ID),
    }),
    minimumAmount: yup
      .object()
      .shape({
        pickup: yup
          .object()
          .optional()
          .shape({
            apply: yup.boolean().optional(),
            amount: yup.mixed<string | number>().when("apply", {
              is: true,
              then: yup
                .number()
                .typeError(FIELD_TYPE_REQUIRED_ERROR_ID)
                .required(FIELD_TYPE_REQUIRED_ERROR_ID)
                .positive(FIELD_NUMBER_MIN_VALUE_ID),
            }),
          }),
        delivery: yup
          .object()
          .optional()
          .shape({
            apply: yup.boolean().optional(),
            amount: yup.mixed<string | number>().when("apply", {
              is: true,
              then: yup
                .number()
                .typeError(FIELD_TYPE_REQUIRED_ERROR_ID)
                .required(FIELD_TYPE_REQUIRED_ERROR_ID)
                .positive(FIELD_NUMBER_MIN_VALUE_ID),
            }),
          }),
        foodTruckExperience: yup
          .object()
          .optional()
          .shape({
            apply: yup.boolean().optional(),
            amount: yup.mixed<string | number>().when("apply", {
              is: true,
              then: yup
                .number()
                .typeError(FIELD_TYPE_REQUIRED_ERROR_ID)
                .required(FIELD_TYPE_REQUIRED_ERROR_ID)
                .positive(FIELD_NUMBER_MIN_VALUE_ID),
            }),
          }),
        insideVenue: yup
          .object()
          .optional()
          .shape({
            apply: yup.boolean().optional(),
            amount: yup.mixed<string | number>().when("apply", {
              is: true,
              then: yup
                .number()
                .typeError(FIELD_TYPE_REQUIRED_ERROR_ID)
                .required(FIELD_TYPE_REQUIRED_ERROR_ID)
                .positive(FIELD_NUMBER_MIN_VALUE_ID),
            }),
          }),
      })
      .test("minimum-amount", (value, { parent }) => {
        const contextParent = parent as CateringForm["rules"]

        if (
          contextParent?.prePayment.paymentMethod === FeeQuantityEnum.PERCENTAGE
        ) {
          return true
        }

        const prePayment = contextParent?.prePayment?.paymentValue ?? 0
        const services = Object.entries(value).map(([name, details]) => ({
          name,
          amount: details.amount,
          apply: details.apply,
        }))
        const errors = []

        for (const service of services) {
          const applyService = service.apply

          if (
            applyService &&
            prePayment >
              (typeof service?.amount === "number" ? service?.amount : 0)
          ) {
            errors.push(
              new ValidationError(
                CODE_MINIMUM_AMOUNT_VALUE_ID,
                prePayment,
                `rules.minimumAmount.${service.name}.amount`
              )
            )
          }

          if (
            !applyService &&
            service.amount === 0 &&
            prePayment > 0 &&
            contextParent.typeOfService[service.name as keyof TypeOfService]
          ) {
            errors.push(
              new ValidationError(
                CODE_MINIMUM_AMOUNT_APPLY_ID,
                prePayment,
                `rules.minimumAmount.${service.name}.apply`
              )
            )
          }
        }

        if (errors.length > 0) {
          return new ValidationError(errors)
        }

        return true
      }),
    cateringFee: yup.object().shape({
      pickup: yup
        .object()
        .optional()
        .shape({
          apply: yup.boolean().optional(),
          value: yup.mixed<string | number>().when("apply", {
            is: true,
            then: yup
              .number()
              .typeError(FIELD_TYPE_REQUIRED_ERROR_ID)
              .required(FIELD_TYPE_REQUIRED_ERROR_ID)
              .positive(FIELD_NUMBER_MIN_VALUE_ID),
          }),
        }),
      delivery: yup
        .object()
        .optional()
        .shape({
          apply: yup.boolean().optional(),
          value: yup.mixed<string | number>().when("apply", {
            is: true,
            then: yup
              .number()
              .typeError(FIELD_TYPE_REQUIRED_ERROR_ID)
              .required(FIELD_TYPE_REQUIRED_ERROR_ID)
              .positive(FIELD_NUMBER_MIN_VALUE_ID),
          }),
        }),
      foodTruckExperience: yup
        .object()
        .optional()
        .shape({
          apply: yup.boolean().optional(),
          value: yup.mixed<string | number>().when("apply", {
            is: true,
            then: yup
              .number()
              .typeError(FIELD_TYPE_REQUIRED_ERROR_ID)
              .required(FIELD_TYPE_REQUIRED_ERROR_ID)
              .positive(FIELD_NUMBER_MIN_VALUE_ID),
          }),
        }),
      insideVenue: yup
        .object()
        .optional()
        .shape({
          apply: yup.boolean().optional(),
          value: yup.mixed<string | number>().when("apply", {
            is: true,
            then: yup
              .number()
              .typeError(FIELD_TYPE_REQUIRED_ERROR_ID)
              .required(FIELD_TYPE_REQUIRED_ERROR_ID)
              .positive(FIELD_NUMBER_MIN_VALUE_ID),
          }),
        }),
    }),
    cancelPoliciesApply: yup.boolean().optional(),
    typeOfService: yup
      .object()
      .shape({
        pickup: yup
          .boolean()
          .test(
            "at-least-one-service-pickup",
            FIELD_CHECKBOX_REQUIRED_ID,
            typeOfServiceTest
          ),
        delivery: yup
          .boolean()
          .test(
            "at-least-one-service-delivery",
            FIELD_CHECKBOX_REQUIRED_ID,
            typeOfServiceTest
          ),
        foodTruckExperience: yup
          .boolean()
          .test(
            "at-least-one-service-foodTruck-experience",
            FIELD_CHECKBOX_REQUIRED_ID,
            typeOfServiceTest
          ),
        insideVenue: yup
          .boolean()
          .test(
            "at-least-one-service-inside-venue",
            FIELD_CHECKBOX_REQUIRED_ID,
            typeOfServiceTest
          ),
      })
      .required(),
    orderScheduling: yup.object().shape({
      daysInAdvance: yup
        .number()
        .typeError(FIELD_TYPE_REQUIRED_ERROR_ID)
        .required(FIELD_TYPE_REQUIRED_ERROR_ID)
        .min(0, FIELD_NUMBER_MIN_OR_ZERO_VALUE_ID),
    }),
  }),
})

export const FormCateringResolver = yupResolver(FormCateringSchema)
