import type { CustomAlertProps } from "../../../Alert"
import Alert from "../../../Alert"
import Container from "../../../Container"
import RetryPage from "../../../RetryPage"
import classnames from "classnames"
import React from "react"
import styled from "styled-components"

type DetailPageProps = Omit<React.ComponentProps<"div">, "ref"> &
  Partial<CustomAlertProps> & {
    isActive?: boolean
    error?: { message: string } | null
    reload?: () => void
    maxWidth?: number
  }

export const DetailPage: React.FC<DetailPageProps> = ({
  className,
  children,
  message,
  reload,
  error = null,
  type = "error",
  isActive = true,
  maxWidth = 864,
  ...rest
}) => {
  return (
    <div>
      {!isActive && <Alert type={type} message={message} />}
      <StyledDetailPage
        {...rest}
        className={classnames("detail-page", className, {
          readonly: !isActive,
        })}
      >
        {error ? (
          <RetryPage error={error} reload={reload} fullScreen />
        ) : (
          <StyledContainer $maxWidth={maxWidth}>{children}</StyledContainer>
        )}
      </StyledDetailPage>
    </div>
  )
}

export default DetailPage

export const StyledDetailPage = styled.div`
  padding: 64px 15%;
  margin: 0 auto;
  min-height: calc(100vh - 48px);
  overflow-x: auto;
  overflow-y: auto;
  width: 100%;
`

const StyledContainer = styled(Container)<{ $maxWidth: number }>`
  max-width: ${({ $maxWidth }) => $maxWidth}px;
  margin: 0 auto;
`
