import * as Types from '../../../../../../../shared/graphql/generated/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateOrUpdateHolidayMutationVariables = Types.Exact<{
  data: Types.CreateOrUpdateHolidayInput;
}>;


export type CreateOrUpdateHolidayMutation = { __typename?: 'Mutation', createOrUpdateHoliday: { __typename?: 'Holiday', date: any, endTime?: any | null, isOpen: boolean, name: string, startTime?: any | null, uuid: string } };


export const CreateOrUpdateHolidayDocument = gql`
    mutation CreateOrUpdateHoliday($data: CreateOrUpdateHolidayInput!) {
  createOrUpdateHoliday(data: $data) {
    date
    endTime
    isOpen
    name
    startTime
    uuid
  }
}
    `;
export type CreateOrUpdateHolidayMutationFn = Apollo.MutationFunction<CreateOrUpdateHolidayMutation, CreateOrUpdateHolidayMutationVariables>;

/**
 * __useCreateOrUpdateHolidayMutation__
 *
 * To run a mutation, you first call `useCreateOrUpdateHolidayMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrUpdateHolidayMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrUpdateHolidayMutation, { data, loading, error }] = useCreateOrUpdateHolidayMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateOrUpdateHolidayMutation(baseOptions?: Apollo.MutationHookOptions<CreateOrUpdateHolidayMutation, CreateOrUpdateHolidayMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOrUpdateHolidayMutation, CreateOrUpdateHolidayMutationVariables>(CreateOrUpdateHolidayDocument, options);
      }
export type CreateOrUpdateHolidayMutationHookResult = ReturnType<typeof useCreateOrUpdateHolidayMutation>;
export type CreateOrUpdateHolidayMutationResult = Apollo.MutationResult<CreateOrUpdateHolidayMutation>;
export type CreateOrUpdateHolidayMutationOptions = Apollo.BaseMutationOptions<CreateOrUpdateHolidayMutation, CreateOrUpdateHolidayMutationVariables>;