import * as Types from '../../../../../../shared/graphql/generated/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type CreateLocationTaxMutationVariables = Types.Exact<{
  data: Types.CreateLocationTaxInput;
}>;


export type CreateLocationTaxMutation = { __typename?: 'Mutation', createLocationTax: { __typename?: 'TaxLocationModel', uuid: string, name: string, taxRate: number, description?: Types.Maybe<string>, isGeneral?: Types.Maybe<boolean>, applyFutureLocations?: Types.Maybe<boolean>, locations?: Types.Maybe<Array<{ __typename?: 'LocationBasicModel', uuid: string, name: string }>> } };


export const CreateLocationTaxDocument = gql`
    mutation createLocationTax($data: CreateLocationTaxInput!) {
  createLocationTax(data: $data) {
    uuid
    name
    taxRate
    description
    isGeneral
    applyFutureLocations
    locations {
      uuid
      name
    }
  }
}
    `;
export type CreateLocationTaxMutationFn = Apollo.MutationFunction<CreateLocationTaxMutation, CreateLocationTaxMutationVariables>;

/**
 * __useCreateLocationTaxMutation__
 *
 * To run a mutation, you first call `useCreateLocationTaxMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLocationTaxMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLocationTaxMutation, { data, loading, error }] = useCreateLocationTaxMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateLocationTaxMutation(baseOptions?: Apollo.MutationHookOptions<CreateLocationTaxMutation, CreateLocationTaxMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateLocationTaxMutation, CreateLocationTaxMutationVariables>(CreateLocationTaxDocument, options);
      }
export type CreateLocationTaxMutationHookResult = ReturnType<typeof useCreateLocationTaxMutation>;
export type CreateLocationTaxMutationResult = Apollo.MutationResult<CreateLocationTaxMutation>;
export type CreateLocationTaxMutationOptions = Apollo.BaseMutationOptions<CreateLocationTaxMutation, CreateLocationTaxMutationVariables>;