import type { InputLabelProps } from "../../InputLabel"
import {
  eventCodeArrow,
  eventCodeKey,
  eventKeyOnlyLetters,
  leadingAndTrailingSpaces,
  multipleSpacesToOneSpace,
} from "../SimpleInput/constant/regex"
import type { InputProps as AntdInputProps } from "antd"
import { Input as AntdInput } from "antd"
import type { Property } from "csstype"
import omit from "lodash/omit"
import React, { useImperativeHandle } from "react"
import styled, { css, useTheme } from "styled-components"
import type { IBaseThemeFontProps } from "styled-components"

type StyledInputProps = Omit<AntdInputProps, "width" | "allowClear"> & {
  width?: string
  className?: string
  textTransform?: Property.TextTransform
  hasError?: boolean
}

type CustomInputProps = {
  loading?: boolean
  onlyLetters?: boolean
  capitalizeFirstLetter?: boolean
  typeText?: "body" | "title" | "numbers"
  weight?: "bold" | "regular"
  hasError?: boolean
}

export type InputProps = Omit<InputLabelProps, "label"> &
  StyledInputProps &
  CustomInputProps

const FancyInputInlineText = (
  props: InputProps,
  ref:
    | ((instance: unknown) => void)
    | React.RefObject<unknown>
    | null
    | undefined
) => {
  const {
    type,
    onlyLetters,
    onChange,
    onBlur,
    capitalizeFirstLetter = false,
    typeText = "title",
    weight = "bold",
    size,
    hasError,
    ...rest
  } = omit(props, ["tooltipTitle"])

  const inputRef = React.createRef<AntdInput>()

  useImperativeHandle(ref, () => ({
    focus: () => {
      inputRef?.current?.focus()
    },
    select: () => {
      inputRef?.current?.select()
    },
  }))

  function capitalizeFirstLetterInput(
    event: React.ChangeEvent<HTMLInputElement>
  ) {
    event.target.value =
      event.target.value.charAt(0).toUpperCase() + event.target.value.slice(1)

    return event
  }

  const keyboardDefault = new Set(["Backspace", "Enter"])

  const onInputNumberKeyDown = (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (
      event.code !== "KeyE" &&
      (keyboardDefault.has(event.code) ||
        eventCodeKey.test(event.code) ||
        eventCodeArrow.test(event.code))
    ) {
      props?.onKeyDown?.(event)

      return
    }

    const invalidOnlyLetters =
      props.type !== "number" &&
      onlyLetters &&
      !eventKeyOnlyLetters.test(event.key)

    if (invalidOnlyLetters) {
      event.preventDefault()
    } else {
      props?.onKeyDown?.(event)
    }
  }

  const onChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange?.(event)
  }

  const onBlurInput = (event: React.FocusEvent<HTMLInputElement, Element>) => {
    if (event.target.value !== "") {
      event.target.value = event.target.value
        .replace(leadingAndTrailingSpaces, "")
        .replace(multipleSpacesToOneSpace, " ")
      onChange?.(
        capitalizeFirstLetter ? capitalizeFirstLetterInput(event) : event
      )
    }
    onBlur?.(event)
  }
  const theme = useTheme()

  const sizeInput = size === "large" ? "l" : size === "middle" ? "m" : "s"

  let textStyle = theme.text.body.m.regular

  if (typeText === "title" || typeText === "body") {
    textStyle = theme.text[typeText][sizeInput][weight]
  }

  return (
    <StyledInput
      role="textbox"
      {...rest}
      {...props}
      {...textStyle}
      type={type}
      ref={inputRef}
      onKeyDown={onInputNumberKeyDown}
      onChange={onChangeInput}
      onBlur={onBlurInput}
      autoComplete="off"
      bordered={false}
      hasError={hasError}
    />
  )
}

export const InputInlineText = React.forwardRef(FancyInputInlineText)

const StyledInput = styled(AntdInput)<StyledInputProps & IBaseThemeFontProps>`
  ${({ theme, textTransform, hasError, disabled, ...props }) =>
    css`
      &.ant-input,
      .ant-input-group-addon:not(:first-child):not(:last-child),
      .ant-input-group-wrap:not(:first-child):not(:last-child),
      .ant-input-group > .ant-input:not(:first-child):not(:last-child) {
        font-size: ${props.fontSize}px;
        font-family: ${props.fontFamily};
        font-weight: ${props.fontWeight};
        letter-spacing: ${props.letterSpacing}px;
        line-height: ${props.lineHeight}px;
        text-transform: ${textTransform ? `${textTransform}` : ""};
        background-color: ${theme.colors.transparent};
        color: ${theme.colors["Neutral8"]};
        outline: 0;
        padding: 4px 0;

        &::placeholder {
          color: ${theme.colors.Neutral5};
          text-overflow: initial;
        }
        ${!!hasError && `color: ${theme.colors.Danger5};`}
        ${!!disabled && `color: ${theme.colors.Neutral5};`}
      }
    `};
`
