import { useTableScroll } from "../../shared/hooks/useTableScroll"
import Banner from "../Banner"
import Checkbox from "../Checkbox"
import Container from "../Container"
import Divider from "../Divider"
import { showGraphqlErrors } from "../ErrorList"
import Icon from "../Icon"
import RetryPage from "../RetryPage"
import Text from "../Typography/Text"
import { DataTableRowSkeleton } from "./DataTableRow.skeleton"
import type { ApolloError } from "@apollo/client/errors"
import type { TableProps } from "antd"
import { Table } from "antd"
import type { ResultStatusType } from "antd/lib/result"
import type {
  RowSelectionType,
  TableRowSelection,
} from "antd/lib/table/interface"
import classNames from "classnames"
import get from "lodash/get"
import React from "react"
import InfiniteScroll from "react-infinite-scroll-component"
import { useTheme } from "styled-components"
import type { IBaseThemeColors } from "styled-components"
import styled, { css } from "styled-components"

type DataTableProps<T> = TableProps<T> & {
  rowSelectionType?: RowSelectionType
  onSelectCheckboxes?: (
    selectedRowKeys: string[],
    selectedRows?: Array<T>
  ) => void
  selectedRowKeys?: React.Key[]
  background?: keyof IBaseThemeColors
  bannerText?: (rows?: number) => string
  bannerActions?: React.ReactNode | React.ReactNode[]
  hasNextPage?: boolean
  fetchMore?: () => Promise<void>
  fetchMoreContainerHeight?: number
  emptyMessage?: string | React.ReactNode
  loading?: boolean
  error?: ApolloError | undefined
  errorRefetch?: () => void
  errorStatus?: ResultStatusType
  errorFullScreen?: boolean
  substactPixelesToScrollHeight?: number
  headerColor?: keyof IBaseThemeColors
}

export const DataTable = <
  T extends {
    uuid: string
  }
>({
  rowSelectionType,
  dataSource,
  columns,
  onSelectCheckboxes,
  selectedRowKeys,
  fetchMoreContainerHeight,
  bannerText,
  hasNextPage,
  fetchMore,
  emptyMessage,
  loading,
  bannerActions = null,
  background = "Neutral1",
  error,
  errorRefetch,
  errorStatus,
  errorFullScreen,
  substactPixelesToScrollHeight = 104,
  headerColor = "Neutral1",
  ...props
}: DataTableProps<T>) => {
  const theme = useTheme()
  const loadingRows = 2

  const onFetchNextPage = async () => {
    try {
      hasNextPage && fetchMore?.()
    } catch (fetchError) {
      showGraphqlErrors(fetchError)
    }
  }

  const onSelectCheck = (
    selectedRowKeyValues: string[],
    selectedRows?: Array<T>
  ) => {
    onSelectCheckboxes && onSelectCheckboxes(selectedRowKeyValues, selectedRows)
  }

  const onCheckboxHeaderClick = () => {
    if (dataSource?.length === selectedRowKeys?.length) {
      return onSelectCheck([])
    }

    if (dataSource) {
      onSelectCheck(
        dataSource.map(({ uuid }) => uuid),
        [...dataSource]
      )
    }
  }

  const rowSelection: { checkbox: TableRowSelection<T> } = {
    checkbox: {
      type: "checkbox",
      selectedRowKeys,
      onChange: (selectedRowKeyValues: React.Key[], records) => {
        onSelectCheck(
          selectedRowKeyValues.map((uuid) => uuid as string),
          records
        )
      },
      getCheckboxProps: (record: T) => ({
        disabled: !record.uuid,
      }),
      renderCell: (
        value: boolean,
        record: T,
        _index: number,
        originNode: React.ReactNode
      ) => {
        return (
          <Checkbox
            {...get(originNode, "props")}
            classId={`table-check-${record.uuid}`}
            displayContent={false}
            key={record.uuid}
            checked={value}
          />
        )
      },
      columnTitle: () => {
        const allRowsSelected = dataSource?.length === selectedRowKeys?.length
        const hasData = !!dataSource?.length
        const noneSelected = selectedRowKeys?.length === 0

        return (
          <Checkbox
            classId="table-check-header"
            onClick={onCheckboxHeaderClick}
            indeterminate={!allRowsSelected && !noneSelected}
            checked={hasData && allRowsSelected}
          />
        )
      },
    },
  }

  useTableScroll({
    onScroll: onFetchNextPage,
    loadingRows,
    containerHeight: fetchMoreContainerHeight,
    hasNextPage,
  })

  const errorPage = (
    <RetryPage
      error={error}
      reload={errorRefetch}
      status={errorStatus}
      fullScreen={errorFullScreen}
    />
  )

  return (
    <StyledTableWrapper className="table-list-wrapper" background={background}>
      <InfiniteScroll
        dataLength={Number(dataSource?.length)}
        hasMore={!!hasNextPage}
        height={`calc(100vh - ${substactPixelesToScrollHeight}px)`}
        scrollThreshold="200px"
        next={onFetchNextPage}
        loader={<DataTableRowSkeleton rows={loadingRows} />}
      >
        <Table
          {...props}
          sticky
          locale={
            rowSelection && {
              emptyText: loading ? (
                <DataTableRowSkeleton rows={loadingRows} />
              ) : !loading && !!error ? (
                errorPage
              ) : loading ? (
                <div />
              ) : (
                emptyMessage
              ),
            }
          }
          pagination={false}
          rowKey="uuid"
          rowSelection={
            rowSelectionType && get(rowSelection, rowSelectionType, null)
          }
          columns={columns}
          dataSource={dataSource}
          components={{
            header: {
              cell: ({
                className,
                children,
                ...restProps
              }: React.ComponentProps<"th">) => {
                const [, title] = children as [
                  unknown,
                  string | React.ReactNode
                ]

                if (title) {
                  return (
                    <th
                      {...restProps}
                      style={{ backgroundColor: theme.colors[headerColor] }}
                      role="row"
                      className={classNames(className, "header-cell")}
                    >
                      <Text
                        size="s"
                        weight="bold"
                        title={title.toString()}
                        ellipsis
                      >
                        {title}
                      </Text>
                    </th>
                  )
                }

                return (
                  <th
                    {...restProps}
                    role="row"
                    className={classNames(className, "header-cell")}
                  />
                )
              },
            },
            body: {
              cell: ({
                children,
                className,
                ...restProps
              }: React.ComponentProps<"td">) => {
                const [, content] = children as [
                  unknown,
                  string | React.ReactNode
                ]
                const isSelectionCell = className?.includes(
                  "ant-table-selection-column"
                )

                if (!content) {
                  return (
                    <td {...restProps} className={className}>
                      <Text size="s" weight="bold">
                        -
                      </Text>
                    </td>
                  )
                }

                if (isSelectionCell) {
                  return (
                    <td {...restProps} className={className}>
                      {children}
                    </td>
                  )
                }

                return (
                  <td {...restProps} className={className}>
                    <Text size="s" title={content as string} ellipsis>
                      {children}
                    </Text>
                  </td>
                )
              },
              row: (rowProps: React.ComponentProps<"tr">) => (
                <tr {...rowProps} />
              ),
              wrapper: (wrapperProps: React.ComponentProps<"tbody">) => {
                return <tbody {...wrapperProps}>{wrapperProps.children}</tbody>
              },
            },
          }}
        />
      </InfiniteScroll>

      {selectedRowKeys && selectedRowKeys?.length > 0 ? (
        <Banner className="positioned-banner">
          <Container display="flex" justifyContent="center" alignItems="center">
            {bannerText && (
              <>
                <Icon
                  remixiconClass="ri-check-fill"
                  size={24}
                  color="Neutral8"
                  classes="right-spaced-icon"
                  alignSelf="center"
                />
                <Text size="l" className="right-spaced">
                  {bannerText(selectedRowKeys.length)}
                </Text>
                <Divider
                  type="vertical"
                  className="right-spaced"
                  alignSelf="center"
                />
              </>
            )}
            {(Array.isArray(bannerActions) &&
              bannerActions.map((action) => action)) ||
              bannerActions}
          </Container>
        </Banner>
      ) : null}
    </StyledTableWrapper>
  )
}

interface StyledTablerProps {
  background?: keyof IBaseThemeColors
}

export const StyledTableWrapper = styled.div<StyledTablerProps>`
  ${({ theme, background }) => css`
    &.table-list-wrapper {
      .header-cell,
      .ant-table,
      .ant-table-thead
        > tr
        > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
        background: ${get(theme, `colors['${background}']`)};
      }

      .ant-table-cell {
        padding: 18px 16px;
      }

      .ant-table-tbody > tr > td,
      .ant-table-thead > tr > th {
        border-bottom: 1px solid ${theme.colors["Neutral3"]};
      }

      .ant-table-tbody > tr.ant-table-row:hover > td {
        background: ${theme.colors["Neutral2"]};
      }

      .ant-table-tbody > tr.ant-table-row-selected > td {
        background: ${theme.colors["Neutral1"]};
      }

      .ant-table-tbody tr {
        opacity: 1;
        transition: display 4s, opacity 1s linear;
      }

      .ant-table-tbody tr.hidden-row {
        opacity: 0;
        display: none;
      }

      .ant-table-tbody > tr.ant-table-placeholder:hover > td {
        background: ${theme.colors["Neutral1"]};
        color: ${theme.colors["Neutral1"]};
      }

      .ant-table-empty .ant-table-tbody > tr.ant-table-placeholder {
        background: ${theme.colors["Neutral1"]};
        color: ${theme.colors["Neutral1"]};

        :hover {
          background: ${theme.colors.transparent};
          color: ${theme.colors.transparent};
        }

        td {
          border: none;
        }
      }

      .ant-skeleton-paragraph {
        margin-bottom: 0;
      }
    }
  `}

  td {
    height: 80px;
  }

  .positioned-banner {
    left: 50%;
    transform: translateX(calc(104px - 50%));
  }

  .row-level-1-color-primary-1 {
    .ant-table-row-level-1 td {
      background-color: ${({ theme }) => `${theme.colors["Primary1"]}`};
    }
  }
`
