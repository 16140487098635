import { autocompleteAddressValidation } from "../../../../../../components/AutocompleteAddress/AutocompleteAddress.yup"
import {
  PHONE_FORMAT_ERROR_ID,
  REQUIRED_FIELD_ERROR_ID,
} from "../../../../../../shared/titles/validations.title"
import yup from "../../../../../../shared/yup"
import { yupResolver } from "@hookform/resolvers/yup"

const LocationSchema = yup.object().shape({
  name: yup.string().required(),
  ...autocompleteAddressValidation,
  phone: yup
    .string()
    .required(REQUIRED_FIELD_ERROR_ID)
    .validatePhoneNumber(PHONE_FORMAT_ERROR_ID),
  orderTypes: yup
    .array()
    .min(1)
    .label("order types")
    .of(yup.string())
    .required(),
  schedules: yup.array().of(
    yup.object().shape({
      day: yup.string().dayOfTheWeek(),
      defaultValues: yup.array().of(
        yup
          .object()
          .shape({
            day: yup.string().dayOfTheWeek(),
            startDate: yup.string().timeRangeValue(),
            endDate: yup.string().timeRangeValue(),
          })
          .timeRanges()
      ),
      open: yup.boolean().optional(),
    })
  ),
})

export const LocationResolver = yupResolver(LocationSchema)
