import type { InputLabelProps } from "../../InputLabel"
import InputLabel from "../../InputLabel"
import InputHelper from "../../Inputs/InputHelper"
import type { RadioProps } from "../Radio"
import { Radio } from "../Radio"
import type { RadioGroupProps as AntdRadioGroupProps } from "antd"
import { Radio as AntdRadio } from "antd"
import type { RefObject } from "react"
import React from "react"
import styled, { css } from "styled-components"

type StyledInnerRadioGroupProps = {
  flexFlow: "row" | "column"
}

type RadioGroupProps = Omit<InputLabelProps, "label"> &
  StyledInnerRadioGroupProps &
  AntdRadioGroupProps & {
    items?: RadioProps[]
    label?: React.ReactNode | string
    helperText?: string
    hasError?: boolean
  }

export const RadioGroup = React.forwardRef(
  (
    props: RadioGroupProps,
    ref:
      | ((instance: HTMLParagraphElement | null) => void)
      | RefObject<HTMLParagraphElement>
      | null
      | undefined
  ) => {
    const {
      items,
      disabled,
      helperText,
      hasError,
      label,
      labelClassName,
      tooltipTitle,
      requirement,
      children,
      flexFlow = "row",
      ...groupProps
    } = props

    return (
      <StyledRadioGroupWrapper>
        {label && (
          <InputLabel
            labelClassName={labelClassName}
            tooltipTitle={tooltipTitle}
            requirement={requirement}
            label={label}
          />
        )}
        <StyledRadioInnerWrapper
          {...groupProps}
          disabled={disabled}
          flexFlow={flexFlow}
        >
          {items?.map((radioItem, index) => (
            <Radio
              key={`radio-group-item-${index}`}
              ref={ref}
              disabled={disabled}
              {...radioItem}
            />
          ))}
          {children}
        </StyledRadioInnerWrapper>
        {!!helperText && (
          <InputHelper $hasError={hasError}>{helperText}</InputHelper>
        )}
      </StyledRadioGroupWrapper>
    )
  }
)

const StyledRadioGroupWrapper = styled.div`
  position: relative;
  display: block;
`

const StyledRadioInnerWrapper = styled(
  AntdRadio.Group
)<StyledInnerRadioGroupProps>`
  display: flex;

  ${({ flexFlow }) => css`
    flex-flow: ${flexFlow};
    
    & .ant-radio-wrapper { 
      margin-bottom: ${flexFlow === "row" ? "0" : "4px"};
  `}
`
