import {
  ALPHANUMERIC_DEVICE_CODE_ERROR_ID,
  EXACT_LENGTH_ERROR_ID,
  PHONE_FORMAT_ERROR_ID,
  REQUIRED_FIELD_ERROR_ID,
} from "../../../../../shared/titles/validations.title"
import yup from "../../../../../shared/yup"
import { DEVICE_CODE_LENGTH } from "./DeviceForm.constants"
import { yupResolver } from "@hookform/resolvers/yup"

export const DeviceFormResolver = yupResolver(
  yup.object().shape({
    deviceKey: yup.string().required(),
    functionType: yup.string().required(),
    deviceCode: yup
      .string()
      .matches(/^[\dA-Z]+$/, ALPHANUMERIC_DEVICE_CODE_ERROR_ID)
      .required(REQUIRED_FIELD_ERROR_ID)
      .length(DEVICE_CODE_LENGTH, EXACT_LENGTH_ERROR_ID),
    locationPhone: yup
      .string()
      .required(REQUIRED_FIELD_ERROR_ID)
      .validatePhoneNumber(PHONE_FORMAT_ERROR_ID),
  })
)
