import * as Types from '../../../../../../shared/graphql/generated/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateDeliveryIntegrationMutationVariables = Types.Exact<{
  data: Types.UpdateDeliveryIntegrationInput;
}>;


export type UpdateDeliveryIntegrationMutation = { __typename?: 'Mutation', updateDeliveryIntegration: { __typename?: 'DeliveryIntegrationWithLocationsModel', uuid: string, apiKey: string, integrationLabel: string, integrationName: Types.DeliveryIntegrationEnum, isActive: boolean, issues?: string | null, settings?: any | null, relatedLocations?: Array<{ __typename?: 'LocationWithDeliveryDefaultModel', uuid: string, name: string, isDefault: boolean }> | null } };


export const UpdateDeliveryIntegrationDocument = gql`
    mutation updateDeliveryIntegration($data: UpdateDeliveryIntegrationInput!) {
  updateDeliveryIntegration(data: $data) {
    uuid
    apiKey
    integrationLabel
    integrationName
    isActive
    issues
    settings
    relatedLocations {
      uuid
      name
      isDefault
    }
  }
}
    `;
export type UpdateDeliveryIntegrationMutationFn = Apollo.MutationFunction<UpdateDeliveryIntegrationMutation, UpdateDeliveryIntegrationMutationVariables>;

/**
 * __useUpdateDeliveryIntegrationMutation__
 *
 * To run a mutation, you first call `useUpdateDeliveryIntegrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDeliveryIntegrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDeliveryIntegrationMutation, { data, loading, error }] = useUpdateDeliveryIntegrationMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateDeliveryIntegrationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDeliveryIntegrationMutation, UpdateDeliveryIntegrationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDeliveryIntegrationMutation, UpdateDeliveryIntegrationMutationVariables>(UpdateDeliveryIntegrationDocument, options);
      }
export type UpdateDeliveryIntegrationMutationHookResult = ReturnType<typeof useUpdateDeliveryIntegrationMutation>;
export type UpdateDeliveryIntegrationMutationResult = Apollo.MutationResult<UpdateDeliveryIntegrationMutation>;
export type UpdateDeliveryIntegrationMutationOptions = Apollo.BaseMutationOptions<UpdateDeliveryIntegrationMutation, UpdateDeliveryIntegrationMutationVariables>;