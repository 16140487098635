import Button from "../../../../../ui/Button/Button"
import { Container } from "../../../../../ui/Container/Container"
import { Icon } from "../../../../../ui/Icon/Icon"
import { Input } from "../../../../../ui/Inputs/Input/Input"
import DetailPage from "../../../../../ui/Layouts/MainLayout/DetailPage/DetailPage"
import { PageTitle } from "../../../../../ui/PageTitle/PageTitle"
import { Spacer } from "../../../../../ui/Spacer/Spacer"
import { Switch } from "../../../../../ui/Switch/Switch"
import { Text } from "../../../../../ui/Typography/Text/Text"
import { useState } from "react"
import { useIntl } from "react-intl"
import styled from "styled-components"

interface SwitchSquareProps {
  title: string
  text: string
}

const selectSwitchOptions: SwitchSquareProps[] = [
  {
    title: "Category Catalog",
    text: "Categories, items, combos, and modifiers.",
  },
  {
    title: "Locations",
    text: "List of your available locations.",
  },
  {
    title: "Hours of operation",
    text: "Standard and holidays business hours.",
  },
  {
    title: "Historical orders",
    text: "A historical list of your orders.",
  },
]

export const Square = () => {
  const intl = useIntl()

  const [showKey, setShowKey] = useState<boolean>(false)

  return (
    <DetailPage>
      <PageTitle
        title={intl.formatMessage({
          id: "settings.restaurant.integrations.square.title",
          defaultMessage: "Square",
        })}
      />
      <Container display="flex" gap="10px" alignItems="center">
        <Icon
          remixiconClass="ri-checkbox-blank-circle-fill"
          size={8}
          color={"Neutral5"}
        />
        <Text size="s" color="Neutral8">
          {intl.formatMessage({
            id: "restaurants.integrations.connected",
            defaultMessage: "Not connected",
          })}
        </Text>
      </Container>
      <Spacer size={24} />
      <Container display="grid" gap="32px">
        <StyledSquareContentItem>
          <Text size="l" weight="bold">
            {intl.formatMessage({
              id: "restaurants.integrations.how.it.work.title",
              defaultMessage: "How it works",
            })}
          </Text>
          <Text size="m" color="Neutral6">
            {intl.formatMessage({
              id: "restaurants.integrations.how.it.work.paragraph",
              defaultMessage:
                "Lorem ipsum dolor sit amet consectetur. Eget ac sodales nisi sed tortor hac egestas et phasellus est augue tortor turpis. Lorem ipsum dolor sit amet consectetur. Eget ac sodales nisi sed tortor hac egestas et phasellus est augue tortor turpis.",
            })}
          </Text>
        </StyledSquareContentItem>

        <StyledSquareContentItem>
          <Text size="l" weight="bold">
            {intl.formatMessage({
              id: "restaurants.integrations.api.key.title",
              defaultMessage: "API KEY",
            })}
          </Text>
          <StyledSquareApiSubtitle>
            <Text size="m" color="Neutral6">
              {intl.formatMessage({
                id: "restaurants.integrations.api.key.paragraph1",
                defaultMessage: "Where to find it?",
              })}
            </Text>
            <Text size="m" color="Primary5">
              {intl.formatMessage({
                id: "restaurants.integrations.api.key.paragraph1",
                defaultMessage: "Square docs",
              })}
            </Text>
            <Icon
              remixiconClass="ri-share-box-line"
              size={14}
              color={"Primary5"}
            />
          </StyledSquareApiSubtitle>
          <Container display="flex" gap="8px">
            <Input
              aria-label="api-key"
              type={showKey ? "text" : "password"}
              size="middle"
              width="400px"
              suffix={
                showKey ? (
                  <Icon
                    onClick={() => setShowKey(!showKey)}
                    remixiconClass="ri-eye-line"
                    size={14}
                    color={"Neutral5"}
                    cursor="pointer"
                  />
                ) : (
                  <Icon
                    onClick={() => setShowKey(!showKey)}
                    remixiconClass="ri-eye-off-line"
                    size={14}
                    color={"Neutral5"}
                    cursor="pointer"
                  />
                )
              }
            />
            <Button
              title={intl.formatMessage({
                id: "restaurants.restaurant.integrations.api.key.connect.button",
                defaultMessage: "Connect",
              })}
              disabled
              hierarchy="primary"
            />
          </Container>
        </StyledSquareContentItem>

        <StyledSquareContentItem>
          <Text size="l" weight="bold">
            {intl.formatMessage({
              id: "restaurants.integrations.select.info.import.title",
              defaultMessage: "Select info to import",
            })}
          </Text>
          <Spacer size={15} />
          {selectSwitchOptions.map((option, index) => (
            <Container key={index} display="flex" gap="10px">
              <Switch />
              <div>
                <Text size="m" color="Neutral7">
                  {intl.formatMessage({
                    id: `restaurants.integrations.select.info.switch.${index}`,
                    defaultMessage: option.title,
                  })}
                </Text>
                <Text size="m" color="Neutral5">
                  {intl.formatMessage({
                    id: `restaurants.integrations.select.info.switch.${index}`,
                    defaultMessage: option.text,
                  })}
                </Text>
              </div>
            </Container>
          ))}
          <Spacer size={15} />

          <Button
            title={intl.formatMessage({
              id: "restaurants.restaurant.integrations.import.button",
              defaultMessage: "Import",
            })}
            hierarchy="primary"
          />
        </StyledSquareContentItem>
      </Container>
    </DetailPage>
  )
}

const StyledSquareContentItem = styled.article`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  padding: 24px;

  background: #ffffff;

  border: 1px solid #d9dbe9;
  border-radius: 4px;
`

const StyledSquareApiSubtitle = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`
