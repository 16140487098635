import * as Types from '../../../../../shared/graphql/generated/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateRestaurantMemberMutationVariables = Types.Exact<{
  data: Types.CreateAdminManagerInput;
}>;


export type CreateRestaurantMemberMutation = { __typename?: 'Mutation', createRestaurantMember: { __typename?: 'MemberWithInviteAndLocationsModel', inviteUUID: string, restaurantUUID: string, userUUID?: string | null, email: string, firstName: string, inviteStatus: Types.AdminInviteStatusEnum, lastName: string, roleName: Types.AdminRoleNameEnum, locations: Array<{ __typename?: 'LocationBasicModel', uuid: string, name: string }> } };


export const CreateRestaurantMemberDocument = gql`
    mutation createRestaurantMember($data: CreateAdminManagerInput!) {
  createRestaurantMember(data: $data) {
    inviteUUID
    restaurantUUID
    userUUID
    email
    firstName
    inviteStatus
    lastName
    locations {
      uuid
      name
    }
    roleName
  }
}
    `;
export type CreateRestaurantMemberMutationFn = Apollo.MutationFunction<CreateRestaurantMemberMutation, CreateRestaurantMemberMutationVariables>;

/**
 * __useCreateRestaurantMemberMutation__
 *
 * To run a mutation, you first call `useCreateRestaurantMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRestaurantMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRestaurantMemberMutation, { data, loading, error }] = useCreateRestaurantMemberMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateRestaurantMemberMutation(baseOptions?: Apollo.MutationHookOptions<CreateRestaurantMemberMutation, CreateRestaurantMemberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateRestaurantMemberMutation, CreateRestaurantMemberMutationVariables>(CreateRestaurantMemberDocument, options);
      }
export type CreateRestaurantMemberMutationHookResult = ReturnType<typeof useCreateRestaurantMemberMutation>;
export type CreateRestaurantMemberMutationResult = Apollo.MutationResult<CreateRestaurantMemberMutation>;
export type CreateRestaurantMemberMutationOptions = Apollo.BaseMutationOptions<CreateRestaurantMemberMutation, CreateRestaurantMemberMutationVariables>;