import { useGeneralContext } from "../../../../../shared/contexts/StoreProvider"
import DataTable from "../../../../../ui/DataTable"
import EmptyState from "../../../../../ui/EmptyState"
import DetailPage from "../../../../../ui/Layouts/MainLayout/DetailPage"
import PageTitle from "../../../../../ui/PageTitle"
import type { GetAppHistoryQuery } from "../Graphql/getAppHistory.generated"
import { useGetAppHistoryQuery } from "../Graphql/getAppHistory.generated"
import type { IPublishingModel } from "./interfaces/publishing.interfaces"
import publisinhTableConfig from "./utils/publishing-list.util"
import { get } from "lodash"
import React, { useCallback, useEffect } from "react"
import { useIntl } from "react-intl"

export const PublishingHistory: React.FC = () => {
  const intl = useIntl()
  const [columns] = publisinhTableConfig()

  const {
    state: {
      currentRestaurant: { uuid: restaurantUUID },
    },
  } = useGeneralContext()

  const { data, loading, error, fetchMore, refetch } = useGetAppHistoryQuery({
    variables: {
      restaurantUUID,
    },
    notifyOnNetworkStatusChange: true,
    returnPartialData: true,
    skip: !restaurantUUID,
  })

  const publishList: IPublishingModel[] = get(data, "getAppHistory.results", [])
  const hasNextPage: boolean = get(data, "getAppHistory.hasNextPage", false)
  const endCursor = get(data, "getAppHistory.endCursor", undefined)

  const fetchMoreHistoryApp = useCallback(async () => {
    await fetchMore({
      variables: { restaurantUUID, after: endCursor },
      updateQuery: (prev: GetAppHistoryQuery, { fetchMoreResult }) => {
        if (
          !fetchMoreResult ||
          prev?.getAppHistory?.endCursor ===
            fetchMoreResult?.getAppHistory?.endCursor
        ) {
          return prev
        }

        return Object.assign({}, prev, {
          getAppHistory: {
            ...fetchMoreResult.getAppHistory,
            results: [
              ...(prev.getAppHistory.results as IPublishingModel[]),
              ...(fetchMoreResult.getAppHistory.results as IPublishingModel[]),
            ],
          },
        })
      },
    })
  }, [fetchMore, restaurantUUID, endCursor])

  const retry = () => refetch()

  useEffect(() => {
    refetch()
  }, [refetch])

  return (
    <DetailPage role="publishing-table">
      <PageTitle
        title={intl.formatMessage({
          id: "settings.white.label.application.publishing.title",
          defaultMessage: "Publishing History",
        })}
        description={intl.formatMessage({
          id: "settings.white.label.application.publishing.description",
          defaultMessage:
            "Here you can see the status and history of the released versions of your app",
        })}
      />
      <DataTable
        loading={loading}
        substactPixelesToScrollHeight={295}
        dataSource={publishList}
        columns={columns}
        hasNextPage={hasNextPage}
        fetchMore={fetchMoreHistoryApp}
        error={error}
        errorRefetch={retry}
        emptyMessage={
          <EmptyState
            title={intl.formatMessage({
              id: "settings.white.label.application.publishing.data.table.empty.title",
              defaultMessage: "No data found",
            })}
            description={intl.formatMessage({
              id: "settings.white.label.application.publishing.data.table.empty.description",
              defaultMessage: "You will see publishing history here",
            })}
          />
        }
      />
    </DetailPage>
  )
}
