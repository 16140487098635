import { ReactComponent as QuestionIcon } from "../../shared/assets/icons/question_icon.svg"
import { Button as ButtonAntd } from "antd"
import React from "react"
import styled from "styled-components"

export const QuestionButton: React.FC = () => {
  return (
    <StyledQuestionButton data-testid="question-icon">
      <QuestionIcon />
    </StyledQuestionButton>
  )
}

const StyledQuestionButton = styled(ButtonAntd)`
  position: fixed;
  box-shadow: 0px 2px 8px ${({ theme }) => theme.colors.Neutral4};
  border-radius: 24px;
  width: 40px;
  height: 40px;
  right: 40px;
  bottom: 40px;
  padding: 11px;
  display: flex;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors["Neutral1"]};
  align-items: center;

  &:hover {
    cursor: pointer;
  }

  &:hover,
  &:focus {
    border-color: ${({ theme }) => theme.colors["Primary5"]};
  }

  & path {
    fill: ${({ theme }) => theme.colors["Primary5"]};
  }
`
