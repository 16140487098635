import * as Types from '../../../../../shared/graphql/generated/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetAppHistoryQueryVariables = Types.Exact<{
  restaurantUUID: Types.Scalars['ID'];
  platform?: Types.InputMaybe<Types.AppPlatformEnum>;
  after?: Types.InputMaybe<Types.Scalars['String']>;
  take?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type GetAppHistoryQuery = { __typename?: 'Query', getAppHistory: { __typename?: 'PaginatedAppHistoryModel', endCursor?: string | null, hasNextPage: boolean, results?: Array<{ __typename?: 'AppHistoryModel', dateTime: any, platform: Types.AppPlatformEnum, status: Types.AppHistoryStatusEnum, version?: string | null, uuid: string }> | null } };


export const GetAppHistoryDocument = gql`
    query getAppHistory($restaurantUUID: ID!, $platform: AppPlatformEnum, $after: String, $take: Int) {
  getAppHistory(
    restaurantUUID: $restaurantUUID
    platform: $platform
    after: $after
    take: $take
  ) {
    endCursor
    hasNextPage
    results {
      dateTime
      platform
      status
      version
      uuid
    }
  }
}
    `;

/**
 * __useGetAppHistoryQuery__
 *
 * To run a query within a React component, call `useGetAppHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAppHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAppHistoryQuery({
 *   variables: {
 *      restaurantUUID: // value for 'restaurantUUID'
 *      platform: // value for 'platform'
 *      after: // value for 'after'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useGetAppHistoryQuery(baseOptions: Apollo.QueryHookOptions<GetAppHistoryQuery, GetAppHistoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAppHistoryQuery, GetAppHistoryQueryVariables>(GetAppHistoryDocument, options);
      }
export function useGetAppHistoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAppHistoryQuery, GetAppHistoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAppHistoryQuery, GetAppHistoryQueryVariables>(GetAppHistoryDocument, options);
        }
export type GetAppHistoryQueryHookResult = ReturnType<typeof useGetAppHistoryQuery>;
export type GetAppHistoryLazyQueryHookResult = ReturnType<typeof useGetAppHistoryLazyQuery>;
export type GetAppHistoryQueryResult = Apollo.QueryResult<GetAppHistoryQuery, GetAppHistoryQueryVariables>;