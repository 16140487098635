import type { DropDownProps } from "antd"
import { Dropdown } from "antd"
import classnames from "classnames"
import type CSS from "csstype"
import React from "react"
import styled, { css } from "styled-components"

type StyledDropDownOverlayProps = {
  minWidth?: CSS.Property.MinWidth
  placement?:
    | "bottomCenter"
    | "bottomLeft"
    | "bottomRight"
    | "bottomLeft"
    | "topCenter"
    | "topLeft"
    | "topRight"
}

type DropDownActionProps = DropDownProps & StyledDropDownOverlayProps

export const DropDownAction: React.FC<DropDownActionProps> = ({
  overlayClassName,
  minWidth,
  overlay,
  placement = "bottomLeft",
  ...props
}) => {
  return (
    <Dropdown
      placement={placement}
      trigger={["click"]}
      getPopupContainer={(trigger) => trigger.parentNode as HTMLElement}
      overlay={
        <StyledDropDownOverlay
          className={classnames("drop-down-action-overlay", overlayClassName)}
          minWidth={minWidth}
        >
          {overlay}
        </StyledDropDownOverlay>
      }
      {...props}
    />
  )
}

const StyledDropDownOverlay = styled.div<StyledDropDownOverlayProps>`
  ${({ theme, minWidth }) => css`
    &.drop-down-action-overlay {
      border-radius: 5px;
      outline: 1px solid ${theme.colors.Neutral4};
      box-shadow: ${theme.shadows.elevationMedium};

      .ant-menu-root.ant-menu-vertical {
        border-radius: 5px;
        border-right: 0px;
      }

      .ant-dropdown-menu-item,
      .ant-dropdown-menu-item-group-title {
        padding: 0;
      }

      .ant-menu-item,
      .ant-menu-item-only-child {
        display: flex;
        height: 32px;
        margin: 0;
        padding-top: 4px;
        padding-bottom: 4px;
        align-items: center;
        min-width: ${minWidth};

        &:hover {
          background: ${theme.colors["Neutral2"]};
        }

        &:first-child {
          border-radius: 5px 5px 0 0;
        }

        &:last-child {
          border-radius: 0 0 5px 5px;
        }

        &:only-child {
          border-radius: 5px;
        }
      }

      .ant-menu-item-selected {
        background: ${theme.colors["Primary1"]};
      }

      .ant-dropdown-menu-title-content {
        padding: 4px 12px 4px 12px;
      }
      .ant-menu-item-disabled {
        span,
        i {
          color: ${theme.colors["Neutral5"]};
        }
      }
    }
  `}
`
