import { Skeleton } from "antd"
import React from "react"
import styled, { css } from "styled-components"

interface UploadAvatarSkeletonProps {
  width?: string
  height?: string
}

export const UploadAvatarSkeleton: React.FC<UploadAvatarSkeletonProps> = ({
  width,
  height,
}) => {
  return (
    <StyledUploadAvatarSkeleton
      width={width}
      height={height}
      className="image-skeleton"
    >
      <Skeleton.Image />
    </StyledUploadAvatarSkeleton>
  )
}

interface StyledUploadAvatarSkeletonProps {
  width?: string
  height?: string
}

const StyledUploadAvatarSkeleton = styled.div<StyledUploadAvatarSkeletonProps>`
  &.image-skeleton {
    .ant-skeleton-element .ant-skeleton-image {
      ${({ height }) =>
        height
          ? css`
              height: ${height};
            `
          : css`
              height: 126px;
            `}

      ${({ width }) =>
        width
          ? css`
              width: ${width};
            `
          : css`
              width: 126px;
            `}
    }
  }
`
