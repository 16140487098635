import { useGetAllLocationsByUserQuery } from "../../../../../GraphQL/Queries/getAllLocationsByUser.generated"
import { useGeneralContext } from "../../../../../shared/contexts/StoreProvider"
import type { QRConfig } from "../../../../../shared/hooks/useQRStyleConfig"
import { useQRStyleConfig } from "../../../../../shared/hooks/useQRStyleConfig"
import Banner from "../../../../../ui/Banner"
import Button from "../../../../../ui/Button"
import Container from "../../../../../ui/Container"
import Divider from "../../../../../ui/Divider"
import { showGraphqlErrors } from "../../../../../ui/ErrorList"
import Icon from "../../../../../ui/Icon"
import type { IModalProps } from "../../../../../ui/Modal"
import ModalFull from "../../../../../ui/ModalFull"
import Text from "../../../../../ui/Typography/Text"
import { useGetQrConfigRestaurantLazyQuery } from "../GraphQL/getQrConfigRestaurant.generated"
import type { ITablesPageParams } from "../TableForm/hookforms.interfaces"
import TableSticker from "../TableSticker"
import get from "lodash/get"
import React, { useCallback, useEffect, useState } from "react"
import { useIntl } from "react-intl"
import { useParams } from "react-router-dom"
import { useReactToPrint } from "react-to-print"
import styled from "styled-components"

type QRCodeViewerProps<T> = IModalProps & {
  datasource: Array<T>
}

export const QRCodeViewer = <T extends { uuid: string; name: string }>({
  datasource,
  ...props
}: QRCodeViewerProps<T>) => {
  const { locationUUID } = useParams<ITablesPageParams>()
  const intl = useIntl()

  const {
    state: {
      currentRestaurant: { uuid: restaurantUUID, name },
      auth: {
        admin: { uuid: userUUID },
      },
    },
  } = useGeneralContext()

  const initialRender = React.useRef(true)
  const componentRef = React.useRef(null)
  const [printing, setPrinting] = useState<boolean>()

  const { data, loading } = useGetAllLocationsByUserQuery({
    variables: { restaurantUUID, locationUUID, userUUID },
    onError: showGraphqlErrors,
    skip: !restaurantUUID || !locationUUID || !userUUID || !props.visible,
  })

  const locationName = get(data, " getAllLocationsByUser.results.0.name")

  const reactToPrintContent = React.useCallback(() => {
    return componentRef.current
  }, [])

  const { config, setConfig } = useQRStyleConfig()

  const [getQr] = useGetQrConfigRestaurantLazyQuery({
    fetchPolicy: "no-cache",
    onError: showGraphqlErrors,
    onCompleted: ({ getQrConfigRestaurant }) => {
      if (getQrConfigRestaurant.qrConfig) {
        try {
          const qrConfig: QRConfig = JSON.parse(getQrConfigRestaurant.qrConfig)
          setConfig(qrConfig)
        } catch (error) {
          showGraphqlErrors(error)
        }
      }
    },
  })

  const getQRConfig = useCallback(() => {
    try {
      getQr({ variables: { restaurantUUID } })
    } catch (error) {
      showGraphqlErrors(error)
    }
  }, [getQr, restaurantUUID])

  const onPrint = useReactToPrint({
    content: reactToPrintContent,
    documentTitle: intl.formatMessage(
      {
        id: "restaurants.location.tables.qr.modal.print.title",
        defaultMessage: "{restaurantName} QR Codes For Tables",
      },
      { restaurantName: name }
    ),
    onBeforeGetContent: () => setPrinting(true),
    onAfterPrint: () => setPrinting(false),
    onPrintError: () => setPrinting(false),
    removeAfterPrint: true,
  })

  const clearQRInstance = () => {
    initialRender.current = true
  }

  useEffect(() => {
    if (!props.visible) {
      return
    }

    if (initialRender.current) {
      initialRender.current = false
      getQRConfig()
    }
  }, [getQRConfig, props.visible])

  return (
    <ModalFull {...props} afterClose={clearQRInstance}>
      <Container
        role="qr-code-viewer"
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        alignItems="center"
      >
        <div ref={componentRef}>
          {datasource.map(({ uuid, name: tableName }) => {
            return (
              <TableSticker
                key={uuid}
                config={config}
                tableName={tableName}
                loading={loading}
                locationName={locationName}
                uuid={uuid}
              />
            )
          })}
        </div>
        <StyledBanerWrapper>
          <Banner>
            <>
              <Icon
                remixiconClass="ri-check-fill"
                size={24}
                color="Neutral8"
                classes="right-spaced-icon"
                alignSelf="center"
              />
              <Text size="l" className="right-spaced">
                {intl.formatMessage(
                  {
                    id: "restaurants.location.tables.banner",
                    defaultMessage:
                      "{rows} {rows, plural, one {Table} other {Tables}} Selected",
                  },
                  { rows: datasource.length }
                )}
              </Text>
              <Divider
                type="vertical"
                className="right-spaced"
                alignSelf="center"
              />
            </>

            <Button
              title={intl.formatMessage({
                id: "components.modal.cancel.button",
                defaultMessage: "Cancel",
              })}
              onClick={props.onCancel}
              hierarchy="secondary"
            />
            <StyledPrintButton
              title={intl.formatMessage({
                id: "restaurants.location.tables.qr.modal.print.button",
                defaultMessage: "Print",
              })}
              onClick={onPrint}
              loading={printing}
            />
          </Banner>
        </StyledBanerWrapper>
      </Container>
    </ModalFull>
  )
}

const StyledPrintButton = styled(Button)`
  margin-left: 16px;
`

const StyledBanerWrapper = styled.div`
  width: 651px;
  margin-left: auto;
  margin-right: auto;
`
