import yup from "../../../../../shared/yup"
import { yupResolver } from "@hookform/resolvers/yup"

export const EditTaxSchema = yup.object().shape({
  name: yup.string().required(),
  taxRate: yup.number().positive().required(),
  locations: yup.array().min(1).label("locations").of(yup.string()).required(),
})

export const EditTaxResolver = yupResolver(EditTaxSchema)
