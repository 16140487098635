import DropdownAction from "../../../../../../ui/Header/DropdownAction"
import Icon from "../../../../../../ui/Icon"
import Text from "../../../../../../ui/Typography/Text"
import React from "react"
import { useIntl } from "react-intl"
import styled from "styled-components"

type TimeRangeActionsProps = {
  copyShedule: () => void
  addSchedule?: () => void
}

export const TimeRangeActions: React.FC<TimeRangeActionsProps> = ({
  copyShedule,
  addSchedule,
}) => {
  const intl = useIntl()

  return (
    <DropdownAction
      placement="bottomRight"
      minWidth="100px"
      overlay={
        <ul className="ant-menu ant-menu-root ant-menu-vertical ant-menu-light">
          <StyledMenuItem className="ant-menu-item" onClick={copyShedule}>
            <Icon remixiconClass="ri-file-copy-line" color="Neutral7" />
            <Text size="s">
              {intl.formatMessage({
                id: "restaurants.locations.hours.of.operation.menu.copy",
                defaultMessage: "Copy times",
              })}
            </Text>
          </StyledMenuItem>
          <StyledMenuItem
            className={`ant-menu-item ${
              !addSchedule && "ant-menu-item-disabled"
            }`}
            onClick={addSchedule}
          >
            <Icon remixiconClass="ri-split-cells-vertical" color="Neutral7" />
            <Text size="s">
              {intl.formatMessage({
                id: "restaurants.locations.hours.of.operation.menu.split",
                defaultMessage: "Split Service",
              })}
            </Text>
          </StyledMenuItem>
        </ul>
      }
    >
      <StyledIconPoints>
        <Icon
          remixiconClass="ri-more-fill"
          cursor="pointer"
          color="Neutral6"
          size={16}
        />
      </StyledIconPoints>
    </DropdownAction>
  )
}

const StyledIconPoints = styled.div`
  width: 24px;
  height: 32px;
  margin-left: 6px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

const StyledMenuItem = styled.li`
  display: flex;
  gap: 8px;
`
