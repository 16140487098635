import AddIcon from "../../../../../ui/AddIcon"
import DropdownAction from "../../../../../ui/Header/DropdownAction"
import TaxCategories from "./TaxCategories"

export const AddTaxButton = () => {
  return (
    <DropdownAction overlay={<TaxCategories />}>
      <AddIcon />
    </DropdownAction>
  )
}
