import { Center, Text } from "@mantine/core"
import { useIntl } from "react-intl"

export const EmployeesEmpty = () => {
  const intl = useIntl()

  return (
    <Center>
      {
        <Text size="xl" c="gray.6">
          {intl.formatMessage({
            id: "settings.locations.employees.empty.label",
            defaultMessage: "No employees. Please add one above.",
          })}
        </Text>
      }
    </Center>
  )
}
