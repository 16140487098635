import type { OrderThrottlingRule } from "../../../../../shared/graphql/generated/types"
import {
  MAX_NUMBER_ORDER,
  MIN_NUMBER_ORDER,
} from "../../../../../shared/utils/constant/orderThrottling"
import yup from "../../../../../shared/yup"
import type {
  DateRangeIndexByDay,
  DateRangeIndexModel,
} from "../ThrottlingHours/throttling.types"
import getDateRangesByDaysOfTheWeek from "../utils/getDateRangesByDaysOfTheWeek"

export const OverrideRulesSchema = yup
  .array()
  .of(
    yup.object().shape({
      day: yup.string(),
      startDate: yup.string(),
      endDate: yup.string(),
      ordersLimit: yup.number(),
    })
  )
  .test({
    name: "Time range overlap",
    test: (values, { createError }) => {
      const data = getDateRangesByDaysOfTheWeek(
        values as unknown as Array<OrderThrottlingRule>
      )

      const invalidMaxOrders = data?.map(({ dateRanges, day }) => {
        return {
          day,
          rangeIndexes: dateRanges
            ?.map((dateRange, index) => ({ ...dateRange, index }))
            ?.filter(
              ({ endDate, startDate, ordersLimit }) =>
                !!startDate &&
                !!endDate &&
                ordersLimit !== null &&
                ordersLimit !== undefined &&
                (!(Number(ordersLimit) < MAX_NUMBER_ORDER) ||
                  !(Number(ordersLimit) > MIN_NUMBER_ORDER))
            )
            .map(({ index }) => index),
        }
      })

      const invalidData: Array<DateRangeIndexByDay> = []

      data.forEach(({ day, dateRanges }) => {
        const validRanges: Array<DateRangeIndexModel> = []
        const invalidRanges: Array<DateRangeIndexModel> = []

        dateRanges?.forEach(({ startDate, endDate }, dayIndex) => {
          if (!startDate.isSameOrBefore(endDate)) {
            invalidRanges.push({ day, startDate, endDate, index: dayIndex })

            return
          }

          if (validRanges.length === 0) {
            validRanges.push({ day, startDate, endDate, index: dayIndex })

            return
          }

          const invalidRange = validRanges?.filter(
            (validRange) =>
              startDate.isBetween(validRange.startDate, validRange.endDate) ||
              endDate.isBetween(validRange.startDate, validRange.endDate)
          )

          if (invalidRange.length === 0) {
            validRanges.push({ day, startDate, endDate, index: dayIndex })
          } else {
            invalidRanges.push({ day, startDate, endDate, index: dayIndex })
          }
        })

        invalidData.push({
          day,
          rangeIndexes: invalidRanges.map(({ index }) => index),
        })
      })

      const flatInvalidValues = invalidData.flatMap((row) =>
        row.rangeIndexes.flat()
      )
      const flatInvalidMaxOrders = invalidMaxOrders.flatMap((row) =>
        row.rangeIndexes?.flatMap((item) => item)
      )

      if (flatInvalidValues.length === 0 && flatInvalidMaxOrders.length === 0) {
        return true
      }

      return createError({
        message: JSON.stringify({
          dates: invalidData.flat(),
          ordersLimit: invalidMaxOrders,
        }),
        path: "rules",
        type: "Invalid time ranges",
      })
    },
  })
