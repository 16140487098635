import * as Types from '../../../../../../shared/graphql/generated/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateExternalAccountMutationVariables = Types.Exact<{
  data: Types.CreateExternalAccountInput;
}>;


export type CreateExternalAccountMutation = { __typename?: 'Mutation', createExternalAccount: { __typename?: 'BankingAccountModel', uuid: string, isActive: boolean, name: string, stIsConnected: boolean } };


export const CreateExternalAccountDocument = gql`
    mutation createExternalAccount($data: CreateExternalAccountInput!) {
  createExternalAccount(data: $data) {
    uuid
    isActive
    name
    stIsConnected
  }
}
    `;
export type CreateExternalAccountMutationFn = Apollo.MutationFunction<CreateExternalAccountMutation, CreateExternalAccountMutationVariables>;

/**
 * __useCreateExternalAccountMutation__
 *
 * To run a mutation, you first call `useCreateExternalAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateExternalAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createExternalAccountMutation, { data, loading, error }] = useCreateExternalAccountMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateExternalAccountMutation(baseOptions?: Apollo.MutationHookOptions<CreateExternalAccountMutation, CreateExternalAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateExternalAccountMutation, CreateExternalAccountMutationVariables>(CreateExternalAccountDocument, options);
      }
export type CreateExternalAccountMutationHookResult = ReturnType<typeof useCreateExternalAccountMutation>;
export type CreateExternalAccountMutationResult = Apollo.MutationResult<CreateExternalAccountMutation>;
export type CreateExternalAccountMutationOptions = Apollo.BaseMutationOptions<CreateExternalAccountMutation, CreateExternalAccountMutationVariables>;