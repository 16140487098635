import type {
  OrderThrottlingRule,
  WeekDaysEnum,
} from "../../../../shared/graphql/generated/types"
import { showGraphqlErrors } from "../../../../ui/ErrorList"
import DetailPage from "../../../../ui/Layouts/MainLayout/DetailPage"
import PageTitle from "../../../../ui/PageTitle"
import SectionHeader from "../../../../ui/SectionHeader"
import Spacer from "../../../../ui/Spacer"
import notification from "../../../../ui/notification"
import type { ILocationsParams } from "../hookforms.interfaces"
import { useCreateOrderThrottlingMutation } from "./GraphQL/createOrderThrottling.generated"
import {
  OrderThrottlingDocument,
  useOrderThrottlingQuery,
} from "./GraphQL/orderThrottling.generated"
import { useUpdateOrderThrottlingMutation } from "./GraphQL/updateOrderThrottling.generated"
import { OrderThrottlingSkeleton } from "./OrderThrottling.skeleton"
import OrderThrottlingForm from "./OrderThrottlingForm"
import type { IOrderThrottlingForm } from "./hookforms.interfaces"
import get from "lodash/get"
import moment from "moment-timezone"
import React, { useCallback, useState } from "react"
import { useIntl } from "react-intl"
import { useParams } from "react-router-dom"

export const OrderThrottling: React.FC = () => {
  const intl = useIntl()

  const { locationUUID } = useParams<ILocationsParams>()
  const [loading, setLoading] = useState<boolean>(false)

  const {
    data,
    loading: isFetchingOrderThrottling,
    error,
    refetch,
  } = useOrderThrottlingQuery({
    variables: { locationUUID },
    fetchPolicy: "cache-and-network",
    skip: !locationUUID,
  })

  const [createOrderThrottling] = useCreateOrderThrottlingMutation({
    refetchQueries: [OrderThrottlingDocument],
  })

  const [updateOrderThrottling] = useUpdateOrderThrottlingMutation({
    refetchQueries: [OrderThrottlingDocument],
  })

  const defaultOrdersLimit = get(data, "orderThrottling.defaultOrdersLimit")
  const orderThrottlingUUID = get(data, "orderThrottling.uuid")
  const orderThrottlingRules = get(data, "orderThrottling.rules", [])

  const rules = orderThrottlingRules?.map((value: OrderThrottlingRule) => ({
    // These formats remove the timezone offset from the dates
    day: value.day.toUpperCase() as WeekDaysEnum,
    startDate: moment(value.startDate).format(),
    endDate: moment(value.endDate).format(),
    ordersLimit: value.ordersLimit,
  }))

  const configurationSavedNotification = () =>
    notification({
      description: intl.formatMessage({
        id: "settings.locations.order.throttling.add.override.rule.success.message",
        defaultMessage: "Your order throttling configuration was saved",
      }),
      type: "success",
    })

  const createOrUpdateOrderThrottling = useCallback(
    async (formData: IOrderThrottlingForm) => {
      if (!orderThrottlingUUID) {
        await createOrderThrottling({
          variables: {
            data: {
              location: { uuid: locationUUID },
              rules: formData.rules || [],
              defaultOrdersLimit: formData.defaultOrdersLimit,
            },
          },
          refetchQueries: [OrderThrottlingDocument],
        })

        configurationSavedNotification()

        return
      }

      await updateOrderThrottling({
        variables: {
          data: {
            uuid: orderThrottlingUUID,
            defaultOrdersLimit: formData.defaultOrdersLimit,
            rules: formData.rules,
            isActive: true,
          },
        },
        refetchQueries: [OrderThrottlingDocument],
      })
      configurationSavedNotification()
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      createOrderThrottling,
      intl,
      locationUUID,
      orderThrottlingUUID,
      updateOrderThrottling,
    ]
  )

  const saveOrderThrottling = async (formData: IOrderThrottlingForm) => {
    try {
      setLoading(true)
      await createOrUpdateOrderThrottling(formData)
    } catch (saveError) {
      showGraphqlErrors(saveError, "", 5)
      setLoading(false)
    } finally {
      setLoading(false)
    }
  }

  const retry = () => refetch()

  return (
    <DetailPage maxWidth={950} error={error} reload={retry}>
      <OrderThrottlingSkeleton
        loading={(isFetchingOrderThrottling || !rules) && !error}
      >
        <PageTitle
          title={intl.formatMessage({
            id: "settings.locations.order.throttling.page.title",
            defaultMessage: "Order Throttling",
          })}
          description={intl.formatMessage({
            id: "settings.locations.order.throttling.page.description",
            defaultMessage:
              "Edit the number of orders that your location can handle every 15 minutes",
          })}
        />
        <Spacer size={8} />
        <SectionHeader
          title={intl.formatMessage({
            id: "settings.locations.order.throttling.section.header.default.order.throttling.title",
            defaultMessage: "Default Order Throttling",
          })}
          description={intl.formatMessage({
            id: "settings.locations.order.throttling.section.header.default.order.throttling.description",
            defaultMessage:
              "Set the average number of orders you can handle in a 15 min time window",
          })}
        />
        <Spacer size={4} />

        <OrderThrottlingForm
          saveOrderThrottling={saveOrderThrottling}
          defaultOrdersLimit={defaultOrdersLimit}
          rules={rules}
          loading={loading}
          displayEditActions={!!orderThrottlingUUID}
        />
      </OrderThrottlingSkeleton>
    </DetailPage>
  )
}
