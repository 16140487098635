import { AdminRoleNameEnum } from "../../../../../shared/graphql/generated/types"
import yup from "../../../../../shared/yup"
import { yupResolver } from "@hookform/resolvers/yup"

export const MemberResolver = yupResolver(
  yup
    .object()
    .shape({
      roleName: yup.string().required(),
      firstName: yup.string().required(),
      lastName: yup.string().required(),
      locationUUIDs: yup.array().of(yup.string()).optional(),
      email: yup.string().email().required(),
    })
    .test({
      name: "is-manager-validate-locations",
      test: (values, { createError }) => {
        if (values.roleName === AdminRoleNameEnum.RESTAURANT_MANAGER) {
          const error = createError({
            message: "You must provide at least one location",
            path: "locationUUIDs.0",
          })

          return values?.locationUUIDs
            ? values?.locationUUIDs.length
              ? true
              : error
            : error
        }

        return true
      },
    })
)
