import type { OrderThrottlingRule } from "../../../../../shared/graphql/generated/types"
import moment from "moment-timezone"

const daysOfTheWeek = [1, 2, 3, 4, 5, 6, 7]

const reduceIntervalToDaysOfTheWeek = (rules: Array<OrderThrottlingRule>) => {
  const reducedDate = rules?.map((rule) => {
    const dateStart = moment(rule.startDate)
    const dateEnd = moment(rule.endDate)
    const ordersLimit = rule.ordersLimit

    return {
      day: rule.day,
      startDate: `${dateStart.hours()}:${dateStart.minutes()}`,
      endDate: `${dateEnd.hours()}:${dateEnd.minutes()}`,
      ordersLimit,
    }
  })

  const filterByDay = (
    rulesArray:
      | {
          day: string
          startDate: string
          endDate: string
          ordersLimit: number
        }[]
      | undefined,
    day: number
  ) => {
    const dayName = moment().isoWeekday(day).format("dddd")

    return {
      day: dayName.toUpperCase(),
      defaultValues: rulesArray?.filter(
        (rule) => rule.day && rule.day.toUpperCase() === dayName.toUpperCase()
      ),
    }
  }

  const weekDays = daysOfTheWeek.map((weekDay) =>
    filterByDay(reducedDate, weekDay)
  )

  return weekDays
}

export default reduceIntervalToDaysOfTheWeek
