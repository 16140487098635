import type { MemberWithInviteAndLocationsModel } from "../../../../../shared/graphql/generated/types"
import { AdminInviteStatusEnum } from "../../../../../shared/graphql/generated/types"
import ConfirmationModal from "../../../../../ui/ConfirmationModal"
import Container from "../../../../../ui/Container"
import { showGraphqlErrors } from "../../../../../ui/ErrorList"
import DropdownAction from "../../../../../ui/Header/DropdownAction"
import Icon from "../../../../../ui/Icon"
import Text from "../../../../../ui/Typography/Text"
import notification from "../../../../../ui/notification"
import { useDeleteRestaurantMemberMutation } from "../../DeliveryIntegration/EditDeliveryIntegration/GraphQL/deleteRestaurantMember.generated"
import EditMember from "../EditMember"
import { useResendInviteToMemberMutation } from "../GraphQL/resendInviteToMember.generated"
import type { IMembersTableModel } from "../interfaces/membersTable.interfaces"
import { Menu } from "antd"
import get from "lodash/get"
import React, { useState } from "react"
import { useIntl } from "react-intl"

type DropdownMemberProps = {
  record: IMembersTableModel
}

export const DropdownMember: React.FC<DropdownMemberProps> = ({ record }) => {
  const intl = useIntl()

  const onlyResendInviteTo = [
    AdminInviteStatusEnum.PENDING,
    AdminInviteStatusEnum.REVOKED,
  ]
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] =
    useState(false)
  const [showEditMemberModal, setShowEditMemberModal] = useState(false)

  const [deleteRestaurantMember, { loading }] =
    useDeleteRestaurantMemberMutation({
      update: (cache, result) => {
        cache.modify({
          fields: {
            getAllMembersByRestaurant: (prev) => {
              const previous = prev as Array<MemberWithInviteAndLocationsModel>

              return previous.filter(
                (current) =>
                  current.inviteUUID !==
                  result.data?.deleteRestaurantMember.inviteUUID
              )
            },
          },
        })
      },
    })

  const [resendInviteToMember] = useResendInviteToMemberMutation()

  const onOpenDeleteConfirmationModal = () =>
    setShowDeleteConfirmationModal(true)
  const onCloseDeleteConfirmation = () => setShowDeleteConfirmationModal(false)

  const onOpenEditMemberModal = () => setShowEditMemberModal(true)
  const onCloseEditMemberModal = () => setShowEditMemberModal(false)

  const onDisableMember = async () => {
    if (!record.uuid) {
      return
    }

    try {
      const deletedRestaurantMember = await deleteRestaurantMember({
        variables: {
          data: {
            email: record.email,
            inviteUUID: record.inviteUUID,
            restaurantUUID: record.restaurantUUID,
            userUUID: record.userUUID ? record.userUUID : undefined,
          },
        },
      })
      const inviteUUID = get(
        deletedRestaurantMember,
        "data.deleteRestaurantMember.inviteUUID"
      )

      if (inviteUUID) {
        notification({
          description: intl.formatMessage(
            {
              id: "settings.restaurant.members.datatable.dropdown.delete.notification.description",
              defaultMessage: "Member {memberName} was deleted",
            },
            {
              memberName: `${record.firstName} ${record.lastName}`,
            }
          ),
          type: "success",
        })

        onCloseDeleteConfirmation()
      }

      onCloseDeleteConfirmation()
    } catch (error) {
      showGraphqlErrors(error)
    }
  }

  const onResendInvite = async () => {
    if (!record.uuid) {
      return
    }

    try {
      const resendInvite = await resendInviteToMember({
        variables: {
          inviteUUID: record.inviteUUID,
        },
      })
      const inviteUUID = get(
        resendInvite,
        "data.resendInviteToMember.inviteUUID"
      )

      if (inviteUUID) {
        notification({
          type: "success",
          description: intl.formatMessage({
            id: "settings.restaurant.members.datatable.dropdown.resend.invite.notification.description",
            defaultMessage: "Invite was successfully sent",
          }),
        })
      }
    } catch (error) {
      showGraphqlErrors(error)
    }
  }

  return (
    <Container display="flex" justifyContent="flex-end">
      <DropdownAction
        placement="bottomRight"
        overlay={
          <Container>
            <Menu role="menu-more-options-members" selectedKeys={[]}>
              <Menu.Item
                key="edit-member"
                icon={<Icon remixiconClass="ri-edit-2-line" color="Neutral7" />}
                onClick={onOpenEditMemberModal}
              >
                <Text size="s">
                  {intl.formatMessage({
                    id: "settings.restaurant.members.datatable.dropdown.edit.member",
                    defaultMessage: "Edit Member",
                  })}
                </Text>
              </Menu.Item>
              <Menu.Item
                key="delete-member"
                icon={
                  <Icon remixiconClass="ri-delete-bin-line" color="Neutral7" />
                }
                onClick={onOpenDeleteConfirmationModal}
              >
                <Text size="s">
                  {intl.formatMessage({
                    id: "settings.restaurant.members.datatable.dropdown.delete.member",
                    defaultMessage: "Delete Member",
                  })}
                </Text>
              </Menu.Item>
              {onlyResendInviteTo.includes(record.inviteStatus) && (
                <Menu.Item
                  key="resend-invite"
                  icon={
                    <Icon remixiconClass="ri-mail-send-line" color="Neutral7" />
                  }
                  onClick={onResendInvite}
                >
                  <Text size="s">
                    {intl.formatMessage({
                      id: "settings.restaurant.members.datatable.dropdown.resend.invite",
                      defaultMessage: "Resend Invite",
                    })}
                  </Text>
                </Menu.Item>
              )}
            </Menu>
            {showEditMemberModal && (
              <EditMember
                record={record}
                visible={showEditMemberModal}
                onClose={onCloseEditMemberModal}
                closable={false}
              />
            )}
            {showDeleteConfirmationModal && (
              <ConfirmationModal
                visible={showDeleteConfirmationModal}
                onCancel={onCloseDeleteConfirmation}
                itemType={intl.formatMessage({
                  id: "settings.restaurant.members.datatable.dropdown.delete.member.type",
                  defaultMessage: "Member",
                })}
                onConfirm={onDisableMember}
                loading={loading}
              />
            )}
          </Container>
        }
      >
        <Icon
          remixiconClass="ri-more-fill"
          cursor="pointer"
          color="Neutral5"
          size={16}
        />
      </DropdownAction>
    </Container>
  )
}
