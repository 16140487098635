import { CateringForm } from "../../../shared/hookforms.intefaces"
import { validationCateringTitle } from "../../../shared/validation-catering.title"
import styles from "../MinimumOrderAmount.module.css"
import { Checkbox, Grid, NumberInput, Stack } from "@mantine/core"
import { IconCurrencyDollar } from "@tabler/icons-react"
import { Control, Controller, FieldPath, useFormContext } from "react-hook-form"
import { useIntl } from "react-intl"

type IntlType = {
  id: string
  defaultMessage: string
}

interface MinimumOrderAmountFieldProps {
  control: Control<CateringForm, unknown>
  nameCheckbox: FieldPath<CateringForm>
  nameAmount: FieldPath<CateringForm>
  apply: boolean
  label: IntlType
  id: string
}

export const MinimumOrderAmountField = ({
  control,
  nameCheckbox,
  nameAmount,
  apply,
  label,
  id,
}: MinimumOrderAmountFieldProps) => {
  const intl = useIntl()
  const { setValue } = useFormContext<CateringForm>()

  return (
    <Grid.Col span={6}>
      <Stack id={id} w="100%" spacing={12}>
        <Controller
          control={control}
          name={nameCheckbox}
          render={({ field, fieldState: { error } }) => (
            <Checkbox
              w="100%"
              label={intl.formatMessage(label)}
              checked={apply}
              onChange={(event) => {
                field.onChange(event.target.checked)
                setValue(nameAmount, 0, { shouldValidate: true })
              }}
              error={
                error?.message
                  ? intl.formatMessage(validationCateringTitle[error?.message])
                  : undefined
              }
            />
          )}
        />
        <Controller
          control={control}
          name={nameAmount}
          render={({ field, fieldState: { error } }) => (
            <NumberInput
              type="number"
              w="100%"
              max={10_000}
              {...field}
              value={typeof field.value === "number" ? field.value : ""}
              onChange={field.onChange}
              hideControls
              disabled={!apply}
              icon={<IconCurrencyDollar size={16} />}
              classNames={{ label: styles.label, input: styles.input }}
              error={
                error?.message
                  ? intl.formatMessage(validationCateringTitle[error?.message])
                  : undefined
              }
            />
          )}
        />
      </Stack>
    </Grid.Col>
  )
}
