import { Skeleton } from "antd"
import styled from "styled-components"

export const BreadcrumbSkeleton = () => {
  return (
    <StyledBreadcrumbSkeleton>
      <Skeleton.Input active />
    </StyledBreadcrumbSkeleton>
  )
}

const StyledBreadcrumbSkeleton = styled.div`
  .ant-skeleton-input {
    width: 100px;
    height: 24px;
  }
`
