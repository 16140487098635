import {
  OrderMethodEnum,
  TipValueTypeEnum,
} from "../../../../../../shared/graphql/generated/types"

export const tipsSettingsControl = [
  {
    value: TipValueTypeEnum.PERCENTAGE,
    label: {
      defaultMessage: "Percentage",
      id: "restaurants.locations.overview.order.method.modal.percentage.label",
    },
  },
  {
    value: TipValueTypeEnum.AMOUNTS,
    label: {
      defaultMessage: "Amounts",
      id: "restaurants.locations.overview.order.method.modal.amounts.label",
    },
  },
]

export const DefaultTips: Record<TipValueTypeEnum, Array<number>> = {
  AMOUNTS: [0, 1, 1.5, 2, 2.5],
  PERCENTAGE: [0, 15, 18, 20, 25],
}

export const defaultTipRequest: Record<
  OrderMethodEnum,
  { before: boolean; after: boolean }
> = {
  DINE_IN: { before: false, after: true },
  TAKE_OUT: { before: true, after: false },
  CURBSIDE: { before: true, after: false },
  DELIVERY: { before: true, after: true },
  DRIVE_THRU: { before: false, after: false },
  CATERING: { before: false, after: true },
}
