type IsLocationActiveProps =
  | {
      isActive?: boolean | null
      deletedAt?: string | null
    }
  | undefined

export const isLocationActive = (props: IsLocationActiveProps) => {
  const isActive = props?.isActive ?? true
  const deletedAt = props?.deletedAt

  return deletedAt ? false : isActive
}
