import { GetBankingAccountByRestaurantDocument } from "../../../../../GraphQL/Queries/getBankingAccountByRestaurant.generated"
import ConfirmationModal from "../../../../../ui/ConfirmationModal"
import Container from "../../../../../ui/Container"
import { showGraphqlErrors } from "../../../../../ui/ErrorList"
import DropDownAction from "../../../../../ui/Header/DropdownAction"
import Icon from "../../../../../ui/Icon"
import Text from "../../../../../ui/Typography/Text"
import AddAccountOrDebitCard from "../AddAccountOrDebitCard"
import { StripeAccountRequirements } from "../BankAccountRow/BankAccountRow"
import ConnectBankAccount from "../ConnectBankAccount"
import EditAccountOrDebitCard from "../EditAccountOrDebitCard"
import { useDisableBankingAccountMutation } from "../GraphQL/disableBankingAccount.generated"
import { useRefreshOnboardingMutation } from "../GraphQL/refreshOnboarding.generated"
import { isRequirementsArraysNonEmpty } from "../utils/check-empty-requirements"
import { LoadingOverlay } from "@mantine/core"
import { Menu } from "antd"
import React, { useState } from "react"
import { useIntl } from "react-intl"

type DropdownBankAccountsProps = {
  uuid: string
  bankAccountName: string
  isConnected?: boolean
  hasExternalAccount?: boolean
  requirements?: StripeAccountRequirements
  futureRequirements?: StripeAccountRequirements
}

export const DropdownBankAccounts: React.FC<DropdownBankAccountsProps> = ({
  uuid,
  isConnected,
  requirements,
  futureRequirements,
  bankAccountName,
  hasExternalAccount,
}) => {
  const intl = useIntl()
  const [showConfirmDelete, setShowConfirmDelete] = useState<boolean>(false)
  const [showAccountOrCard, setShowAccountOrCard] = useState<boolean>(false)
  const [showEditAccountOrCard, setShowEditAccountOrCard] =
    useState<boolean>(false)
  const [showAConnectAccount, setShowConnectAccount] = useState<boolean>(false)

  const [disableBankConnection, { loading }] = useDisableBankingAccountMutation(
    {
      refetchQueries: [GetBankingAccountByRestaurantDocument],
    }
  )

  const [refreshOnboarding, { loading: refreshOnBoardingLoading }] =
    useRefreshOnboardingMutation()

  const onDeleteBankAccount = async () => {
    try {
      await disableBankConnection({ variables: { uuid } })
      setShowConfirmDelete(false)
    } catch (error) {
      showGraphqlErrors(error)
    }
  }

  const onCreateConnectionLink = async () => {
    try {
      const { data } = await refreshOnboarding({ variables: { uuid } })

      if (data?.refreshOnboarding.url) {
        window.location.replace(data?.refreshOnboarding.url)
      }
    } catch (error) {
      showGraphqlErrors(error)
    }
  }

  const isRequirementsAnyArrayNonEmpty = isRequirementsArraysNonEmpty({
    requirements,
  })

  const isFutureRequirementsAnyArrayNonEmpty = isRequirementsArraysNonEmpty({
    futureRequirements,
  })

  const onCancelDelete = () => {
    setShowConfirmDelete(false)
  }

  const onDisplayConfirmDelete = () => {
    setShowConfirmDelete(true)
  }

  const onCancelAddAccountOrDebitCard = () => {
    setShowAccountOrCard(false)
  }

  const onCancelEditAccountOrDebitCard = () => {
    setShowEditAccountOrCard(false)
  }

  const onDisplayAddAccountOrDebitCard = () => {
    setShowAccountOrCard(true)
  }

  const onDisplayEditAccountOrDebitCard = () => {
    setShowEditAccountOrCard(true)
  }

  const onCancelConnect = () => {
    setShowConnectAccount(false)
  }

  const onDisplayConnect = () => {
    setShowConnectAccount(true)
  }

  return (
    <Container display="flex" justifyContent="flex-end">
      <DropDownAction
        placement="bottomRight"
        overlay={
          <Container>
            <Menu role="menu-more-options-bank-accounts" selectedKeys={[]}>
              {!isConnected && hasExternalAccount && (
                <Menu.Item
                  key="dropdown-item-connect"
                  onClick={onDisplayConnect}
                  icon={<Icon remixiconClass="ri-link" size={16} />}
                >
                  <Text size="s">
                    {intl.formatMessage({
                      id: "restaurants.bank.accounts.datatable.menu.connect",
                      defaultMessage: "Connect",
                    })}
                  </Text>
                </Menu.Item>
              )}
              {hasExternalAccount && (
                <Menu.Item
                  key="dropdown-item-update-external-account"
                  onClick={onDisplayEditAccountOrDebitCard}
                  icon={<Icon remixiconClass="ri-pencil-line" size={16} />}
                >
                  <Text size="s">
                    {intl.formatMessage({
                      id: "restaurants.bank.accounts.edit.bank.account.title",
                      defaultMessage: "Update Bank Account",
                    })}
                  </Text>
                </Menu.Item>
              )}
              {!hasExternalAccount && (
                <Menu.Item
                  key="dropdown-item-external-account"
                  onClick={onDisplayAddAccountOrDebitCard}
                  icon={<Icon remixiconClass="ri-bank-card-line" size={16} />}
                >
                  <Text size="s">
                    {intl.formatMessage({
                      id: "restaurants.bank.accounts.datatable.menu.external.account",
                      defaultMessage: "Add Bank Account or Debit Card",
                    })}
                  </Text>
                </Menu.Item>
              )}
              {requirements &&
                isConnected &&
                (isRequirementsAnyArrayNonEmpty ||
                  isFutureRequirementsAnyArrayNonEmpty) && (
                  <Menu.Item
                    key="dropdown-item-complete-kyc"
                    icon={<Icon remixiconClass="ri-bank-card-line" size={16} />}
                    onClick={onCreateConnectionLink}
                  >
                    <Text size="s">
                      {intl.formatMessage({
                        id: "restaurants.bank.accounts.datatable.menu.complete.kyc",
                        defaultMessage: "Complete KYC",
                      })}
                    </Text>
                  </Menu.Item>
                )}

              <Menu.Item
                key="dropdown-item-delete-bank"
                icon={<Icon remixiconClass="ri-delete-bin-5-line" size={16} />}
                onClick={onDisplayConfirmDelete}
              >
                <Text size="s">
                  {intl.formatMessage({
                    id: "restaurants.bank.accounts.datatable.menu.delete",
                    defaultMessage: "Delete Bank",
                  })}
                </Text>
              </Menu.Item>
            </Menu>
            <ConfirmationModal
              visible={showConfirmDelete}
              itemName={bankAccountName}
              itemType={intl.formatMessage({
                id: "restaurants.bank.accounts.datatable.menu.delete.type",
                defaultMessage: "Account",
              })}
              onConfirm={onDeleteBankAccount}
              onCancel={onCancelDelete}
              loading={loading}
            />
          </Container>
        }
      >
        <Icon
          remixiconClass="ri-more-fill"
          cursor="pointer"
          color="Neutral5"
          size={16}
          verticalAlign="middle"
        />
      </DropDownAction>

      <AddAccountOrDebitCard
        uuid={uuid}
        visible={showAccountOrCard}
        onCancel={onCancelAddAccountOrDebitCard}
      />
      <EditAccountOrDebitCard
        uuid={uuid}
        visible={showEditAccountOrCard}
        onCancel={onCancelEditAccountOrDebitCard}
      />

      <ConnectBankAccount
        uuid={uuid}
        visible={showAConnectAccount}
        onCancel={onCancelConnect}
      />
      <LoadingOverlay visible={refreshOnBoardingLoading} overlayBlur={2} />
    </Container>
  )
}
