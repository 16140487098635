import { ImageCoverFieldSkeleton } from "."
import type { AttachmentModel } from "../../shared/graphql/generated/types"
import Text from "../Typography/Text"
import { Avatar } from "antd"
import type CSS from "csstype"
import React, { useState } from "react"
import styled, { css } from "styled-components"

interface ICoverPreviewProps {
  attachment: AttachmentModel | null
  placeholderText: string | React.ReactNode
  imgAltText: string
  label?: string
  width?: number
  height?: number
  imageFit?: CSS.Property.ObjectFit
}

type StyledCoverPreviewProps = {
  ShouldShow: boolean
  width: number
  height: number
  $imageFit?: CSS.Property.ObjectFit
}

export const CoverPreview: React.FC<ICoverPreviewProps> = ({
  attachment,
  placeholderText,
  imgAltText,
  label,
  imageFit,
  width = 327,
  height = 208,
}) => {
  const [isImageLoaded, setIsImageLoaded] = useState(false)

  const onImageLoaded = () => setIsImageLoaded(true)

  return (
    <StyledCoverPreview
      ShouldShow={isImageLoaded}
      width={width}
      height={height}
      $imageFit={imageFit}
    >
      {label && (
        <Text size="l" weight="bold">
          {label}
        </Text>
      )}
      {attachment && !isImageLoaded && <ImageCoverFieldSkeleton />}
      {attachment ? (
        <img
          alt={imgAltText}
          src={attachment.signedUrl}
          onLoad={onImageLoaded}
        />
      ) : (
        <StyledCover shape="square" width={width} height={height}>
          {placeholderText}
        </StyledCover>
      )}
    </StyledCoverPreview>
  )
}

const StyledCoverPreview = styled.div<StyledCoverPreviewProps>`
  & .ant-avatar-string {
    font-size: 42px;
  }

  ${({ ShouldShow, width, height, $imageFit }) => css`
    & img {
      width: ${width}px;
      height: ${height}px;
      display: ${ShouldShow ? "flex" : "none"};
      object-fit: ${$imageFit ?? "cover"};
    }
  `}
`

type StyledCoverProps = {
  width: number
  height: number
}

const StyledCover = styled(Avatar)<StyledCoverProps>`
  ${({ theme, width, height }) => css`
    margin-top: 15px;
    width: ${width}px;
    height: ${height}px;
    color: ${theme.colors["Neutral3"]};
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${({ theme: defaultThme }) =>
      defaultThme.colors["Secondary5"]};
    font-size: ${theme.text.title.s.regular.fontSize}px;
  `};
`
