import { EmployeeType } from "../types/employees.types"
import { EmployeesRow } from "./EmployeesRow"
import { Table } from "@mantine/core"

interface EmployeesListProps {
  employees: EmployeeType[]
}

export const EmployeesList = ({ employees }: EmployeesListProps) => {
  return (
    <Table>
      <tbody>
        {employees.map((employee) => (
          <EmployeesRow key={employee.uuid} employee={employee} />
        ))}
      </tbody>
    </Table>
  )
}
