import AccountStatusLabel from "../AccountStatusLabel"
import DropdownBankAccounts from "../DropdownBankAccounts"
import EditBankAccount from "../EditBankAccount"
import { useGetBankingAccountQuery } from "../GraphQL/getBankingAccount.generated"
import { get } from "lodash"
import { useState } from "react"

interface BankAccountRowProps {
  uuid: string
  name: string
  stIsConnected: boolean
  stHasExternalAccount: boolean
}

export interface StripeAccountRequirements {
  alternatives: Array<string>
  currentlyDue: Array<string>
  errors: Array<string>
  eventuallyDue: Array<string>
  pastDue: Array<string>
  pendingVerification: Array<string>
}

export const BankAccountRow = ({
  uuid,
  name,
  stHasExternalAccount,
  stIsConnected,
}: BankAccountRowProps) => {
  const [stripeRequirements, setStripeRequirements] =
    useState<StripeAccountRequirements>()

  const [stripeFutureRequirements, setStripeFutureRequirements] =
    useState<StripeAccountRequirements>()

  useGetBankingAccountQuery({
    variables: {
      uuid: uuid,
    },
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    onCompleted: ({ getBankingAccount }) => {
      if (getBankingAccount.stData) {
        const account = JSON.parse(getBankingAccount?.stData)
        const alternatives = get(account, "requirements.alternatives", [])
        const req = get(account, "requirements.currently_due", [])
        const errors = get(account, "requirements.errors", [])
        const eventually = get(account, "requirements.eventually_due", [])
        const pastDue = get(account, "requirements.past_due", [])
        const pendingVerification = get(
          account,
          "requirements.pending_verification",
          []
        )

        const futureAlternatives = get(
          account,
          "future_requirements.alternatives",
          []
        )
        const futureReq = get(account, "future_requirements.currently_due", [])
        const futureErrors = get(account, "future_requirements.errors", [])
        const futureEventually = get(
          account,
          "future_requirements.eventually_due",
          []
        )
        const futurePastDue = get(account, "future_requirements.past_due", [])
        const futurePendingVerification = get(
          account,
          "future_requirements.pending_verification",
          []
        )

        setStripeRequirements({
          alternatives: alternatives,
          currentlyDue: req,
          errors: errors,
          eventuallyDue: eventually,
          pastDue: pastDue,
          pendingVerification: pendingVerification,
        })

        setStripeFutureRequirements({
          alternatives: futureAlternatives,
          currentlyDue: futureReq,
          errors: futureErrors,
          eventuallyDue: futureEventually,
          pastDue: futurePastDue,
          pendingVerification: futurePendingVerification,
        })
      }
    },
  })

  return (
    <tr key={uuid}>
      <td style={{ height: "85px", width: "60%" }}>
        <EditBankAccount name={name} uuid={uuid} />
      </td>
      <td>
        <AccountStatusLabel
          requirements={stripeRequirements}
          futureRequirements={stripeFutureRequirements}
          isConnected={stIsConnected}
        />
      </td>
      <td
        style={{
          paddingRight: 15,
        }}
      >
        <DropdownBankAccounts
          uuid={uuid}
          bankAccountName={name}
          isConnected={stIsConnected}
          hasExternalAccount={stHasExternalAccount}
          requirements={stripeRequirements}
          futureRequirements={stripeFutureRequirements}
        />
      </td>
    </tr>
  )
}
