import Container from "../Container"
import Icon from "../Icon"
import { Tooltip } from "antd"
import classnames from "classnames"
import React from "react"

type CopyToClipboardProps = {
  copiedTextLabel?: string
  copyTextLabel: string
  content: string
  sizeIcon?: number
}

export const CopyToClipboard: React.FC<CopyToClipboardProps> = ({
  copiedTextLabel,
  copyTextLabel,
  content,
  sizeIcon,
}) => {
  const [linkCopied, setLinkCopied] = React.useState<boolean>(false)

  const copy = () => navigator.clipboard.writeText(content)

  const onCopy = () => {
    copy()

    setTimeout(() => {
      setLinkCopied(true)

      setTimeout(() => {
        setLinkCopied(false)
      }, 1000)
    }, 200)
  }

  return (
    <Tooltip title={linkCopied ? copiedTextLabel : copyTextLabel}>
      <Container display="flex" alignItems="center">
        <Icon
          remixiconClass="ri-links-line"
          alignSelf="center"
          color="Primary5"
          cursor="pointer"
          classes={classnames({ hidden: linkCopied })}
          onClick={onCopy}
          size={sizeIcon ?? 16}
        />
        <Icon
          classes={classnames({ hidden: !linkCopied })}
          remixiconClass="ri-checkbox-circle-fill"
          alignSelf="center"
          size={sizeIcon ?? 24}
          color="Primary5"
          cursor="pointer"
        />
      </Container>
    </Tooltip>
  )
}
