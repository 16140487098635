import type { ContainerProps } from "./Container.styled"
import { StyledContainer } from "./Container.styled"
import React from "react"

export interface IContainerProps extends ContainerProps {
  readonly?: boolean
}

export const Container: React.FC<ContainerProps> = ({
  centered = false,
  ...props
}) => {
  return <StyledContainer {...props} centered={+centered} />
}
