import { useEnableEmployeeMutation } from "../../GraphQL/enableEmployee.generated"
import { EmployeeType } from "../../types/employees.types"
import { useEmployeeCacheUpdate } from "../../utils/cache/employees-cache.util"
import { getFullName } from "../../utils/full-name.util"
import { Button, Flex, Modal, Text } from "@mantine/core"
import { useIntl } from "react-intl"

interface ActivateEmployeeModalProps {
  activateEmployee: EmployeeType
  opened: boolean
  isActiveFilter: boolean | undefined
  onClose: () => void
}

export const ActivateEmployeeModal = ({
  activateEmployee,
  opened,
  isActiveFilter: isActive,
  onClose,
}: ActivateEmployeeModalProps) => {
  const intl = useIntl()

  const { enableEmployeeCacheUpdate } = useEmployeeCacheUpdate({ isActive })

  const [enableEmployee, { loading }] = useEnableEmployeeMutation()

  const handleActivateEmployee = async () => {
    try {
      await enableEmployee({
        variables: {
          input: {
            uuid: activateEmployee.uuid,
          },
        },
        onCompleted: (data) => {
          if (data) {
            onClose()
          }
        },
        update: (cache, result, options) =>
          enableEmployeeCacheUpdate(cache, result, options),
      })
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <Modal
      centered
      opened={opened}
      onClose={onClose}
      title={intl.formatMessage({
        id: "locations.employees.new.modal.activate.title",
        defaultMessage: "Activate Employee",
      })}
      styles={{
        header: {
          padding: "16px 16px 0px 16px",
        },
      }}
    >
      <Text py={8} size="sm" color="gray.7">
        {intl.formatMessage(
          {
            id: "locations.employees.new.modal.activate.description",
            defaultMessage:
              "You’re about to activate {employeeName}. The employee will be able to add new time entries for now on.",
          },
          {
            employeeName: <strong>{getFullName(activateEmployee)}</strong>,
          }
        )}
      </Text>
      <Flex justify="flex-end">
        <Button
          color="primary.6"
          onClick={handleActivateEmployee}
          loading={loading}
          w={98}
        >
          {!loading &&
            intl.formatMessage({
              id: "locations.employees.new.modal.activate.button",
              defaultMessage: "Activate",
            })}
        </Button>
      </Flex>
    </Modal>
  )
}
