import { TipValueTypeEnum } from "../../../../../../../../shared/graphql/generated/types"
import { toFormat } from "../../../../../../../../shared/utils/currency/toFormat.dinero"
import { SelectItem } from "@mantine/core"
import { IntlShape } from "react-intl"

export const getSelectData = (
  options: Array<SelectItem>,
  type: TipValueTypeEnum,
  intl: IntlShape
) => {
  const data: Array<SelectItem> = options.map((option) => ({
    value: option.value.toString(),
    label: getLabel(option, type, intl),
  }))
  return data
}

const getLabel = (
  option: SelectItem,
  type: TipValueTypeEnum,
  intl: IntlShape
) => {
  const isPercentage = type === TipValueTypeEnum.PERCENTAGE
  const formattedValue = isPercentage
    ? `${option.value}%`
    : toFormat(Number(option.value))

  if (option.value === "0") {
    return intl.formatMessage(
      {
        id: "restaurants.locations.overview.order.method.modal.select.not.now.label",
        defaultMessage: "Not Now ({number})",
      },
      {
        number: formattedValue,
      }
    )
  }

  return formattedValue
}
