import { useGeneralContext } from "../../../../../shared/contexts/StoreProvider"
import { StatusTableEnum } from "../../../../../shared/graphql/generated/types"
import getQrCodePath from "../../../../../shared/utils/helpers/getQrCodePath"
import Button from "../../../../../ui/Button"
import Container from "../../../../../ui/Container"
import { showGraphqlErrors } from "../../../../../ui/ErrorList/ErrorList"
import InputLabel from "../../../../../ui/InputLabel"
import type { IModalProps } from "../../../../../ui/Modal"
import Modal from "../../../../../ui/Modal"
import Switch from "../../../../../ui/Switch"
import { GetAllTablesByLocationDocument } from "../GraphQL/getAllTablesByLocation.generated"
import TableForm from "../TableForm"
import { TableResolver } from "../TableForm/TableForm.yup"
import type {
  ILocationTableForm,
  ITablesPageParams,
} from "../TableForm/hookforms.interfaces"
import { useCreateLocationTableMutation } from "./GraphQL/createLocationTable.generated"
import type { ApolloError } from "@apollo/client"
import get from "lodash/get"
import React, { useState } from "react"
import { FormProvider, useForm } from "react-hook-form"
import { useIntl } from "react-intl"
import { useParams } from "react-router"
import styled from "styled-components"

type CreateTableProps = Omit<IModalProps, "onCancel"> & {
  onClose: () => void
  onErrorCallback?: (error?: ApolloError) => void
  defaultValues?: Record<string, unknown>
}

export const CreateTable: React.FC<CreateTableProps> = ({
  defaultValues,
  onClose,
  onErrorCallback,
  ...props
}) => {
  const intl = useIntl()

  const {
    state: {
      currentRestaurant: { urlIdentifier },
    },
  } = useGeneralContext()

  const { locationUUID } = useParams<ITablesPageParams>()
  const [addMore, setAddMore] = useState<boolean>(false)

  const [createLocationTable, { loading }] = useCreateLocationTableMutation({
    refetchQueries: [GetAllTablesByLocationDocument],
  })

  const methods = useForm<ILocationTableForm>({
    mode: "all",
    resolver: TableResolver,
    defaultValues,
  })

  const {
    handleSubmit,
    reset,
    formState: { isValid, dirtyFields },
  } = methods

  const resetForm = () => {
    reset()
    setAddMore(false)
  }

  const saveTable = async (formData: ILocationTableForm) => {
    try {
      const tableCreated = await createLocationTable({
        variables: {
          data: {
            locationUUID,
            name: formData.name,
            description: formData.description || undefined,
            capacity: formData.capacity,
            status: StatusTableEnum.AVAILABLE,
            tableIdentifier: formData.name,
            tableZoneUUID: formData.tableZoneUUID,
            qrPath: getQrCodePath(urlIdentifier, {
              location: locationUUID,
              tableUUID: ":tableUUID",
              orderMethod: "DINE_IN",
            }),
          },
        },
      })

      const tableUUID = get(tableCreated, "data.createLocationTable.uuid")

      if (tableUUID) {
        reset()
        !addMore && onClose()
      }
    } catch (error) {
      showGraphqlErrors(error)
      onErrorCallback && onErrorCallback(error as ApolloError)
    }
  }

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    handleSubmit(saveTable)()
  }

  return (
    <FormProvider {...methods}>
      <Modal
        onCancel={onClose}
        shouldCloseOnClickOutside={Object.keys(dirtyFields).length === 0}
        {...props}
        footer={
          <Container
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
          >
            <StyledModalFooter display="flex" justifyContent="flex-start">
              <InputLabel
                label={
                  <div className="footer-create-more">
                    {intl.formatMessage({
                      id: "restaurants.location.add.table.modal.create.more",
                      defaultMessage: "Save and Create Another",
                    })}
                    <Switch
                      size="small"
                      checked={addMore}
                      onChange={setAddMore}
                      className="create-more-check"
                    />
                  </div>
                }
              />
            </StyledModalFooter>
            <StyledModalFooter
              display="flex"
              justifyContent="flex-end"
              gap="8px"
            >
              <Button
                title={intl.formatMessage({
                  id: "restaurants.location.table.cancel",
                  defaultMessage: "Cancel",
                })}
                hierarchy="secondary"
                onClick={onClose}
                disabled={loading}
              />

              <Button
                disabled={!isValid}
                loading={loading}
                title={intl.formatMessage({
                  id: "restaurants.location.table.ok",
                  defaultMessage: "Ok",
                })}
                onClick={handleSubmit(saveTable)}
              />
            </StyledModalFooter>
          </Container>
        }
        title={intl.formatMessage({
          id: "restaurants.location.add.table.modal.title",
          defaultMessage: "Create Table",
        })}
        afterClose={resetForm}
        destroyOnClose
      >
        <TableForm onSubmit={onSubmit} />
      </Modal>
    </FormProvider>
  )
}

const StyledModalFooter = styled(Container)`
  .input-label-wrapper {
    margin-bottom: 0px;
  }
  .footer-create-more {
    margin-left: 8px;
    margin-right: 8px;
    display: flex;
    align-items: center;
    .create-more-check {
      margin-left: 8px;
    }
  }
`
