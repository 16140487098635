import * as Types from '../../../../../../shared/graphql/generated/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateItemTaxLocationMutationVariables = Types.Exact<{
  data: Types.CreateItemTaxLocationInput;
}>;


export type CreateItemTaxLocationMutation = { __typename?: 'Mutation', createItemTaxLocation: { __typename?: 'TaxItemLocationModel', uuid: string, name: string, taxRate: number, description?: string | null, isGeneral?: boolean | null, applyFutureLocations?: boolean | null, itemsWithLocations: Array<{ __typename?: 'ItemWithLocationModel', item: { __typename?: 'ItemBasicModel', uuid: string, name: string }, location: { __typename?: 'LocationBasicModel', uuid: string, name: string } }> } };


export const CreateItemTaxLocationDocument = gql`
    mutation createItemTaxLocation($data: CreateItemTaxLocationInput!) {
  createItemTaxLocation(data: $data) {
    uuid
    name
    taxRate
    description
    isGeneral
    applyFutureLocations
    itemsWithLocations {
      item {
        uuid
        name
      }
      location {
        uuid
        name
      }
    }
  }
}
    `;
export type CreateItemTaxLocationMutationFn = Apollo.MutationFunction<CreateItemTaxLocationMutation, CreateItemTaxLocationMutationVariables>;

/**
 * __useCreateItemTaxLocationMutation__
 *
 * To run a mutation, you first call `useCreateItemTaxLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateItemTaxLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createItemTaxLocationMutation, { data, loading, error }] = useCreateItemTaxLocationMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateItemTaxLocationMutation(baseOptions?: Apollo.MutationHookOptions<CreateItemTaxLocationMutation, CreateItemTaxLocationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateItemTaxLocationMutation, CreateItemTaxLocationMutationVariables>(CreateItemTaxLocationDocument, options);
      }
export type CreateItemTaxLocationMutationHookResult = ReturnType<typeof useCreateItemTaxLocationMutation>;
export type CreateItemTaxLocationMutationResult = Apollo.MutationResult<CreateItemTaxLocationMutation>;
export type CreateItemTaxLocationMutationOptions = Apollo.BaseMutationOptions<CreateItemTaxLocationMutation, CreateItemTaxLocationMutationVariables>;