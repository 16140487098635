import * as Types from '../../../../../shared/graphql/generated/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetLoyaltyProgramByRestaurantQueryVariables = Types.Exact<{
  restaurantUUID: Types.Scalars['ID'];
}>;


export type GetLoyaltyProgramByRestaurantQuery = { __typename?: 'Query', getLoyaltyProgramByRestaurant?: { __typename?: 'LoyaltyProgramModel', uuid: string, conversionRate: number, increment: number, isActive: boolean } | null };


export const GetLoyaltyProgramByRestaurantDocument = gql`
    query getLoyaltyProgramByRestaurant($restaurantUUID: ID!) {
  getLoyaltyProgramByRestaurant(restaurantUUID: $restaurantUUID) {
    uuid
    conversionRate
    increment
    isActive
  }
}
    `;

/**
 * __useGetLoyaltyProgramByRestaurantQuery__
 *
 * To run a query within a React component, call `useGetLoyaltyProgramByRestaurantQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLoyaltyProgramByRestaurantQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLoyaltyProgramByRestaurantQuery({
 *   variables: {
 *      restaurantUUID: // value for 'restaurantUUID'
 *   },
 * });
 */
export function useGetLoyaltyProgramByRestaurantQuery(baseOptions: Apollo.QueryHookOptions<GetLoyaltyProgramByRestaurantQuery, GetLoyaltyProgramByRestaurantQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLoyaltyProgramByRestaurantQuery, GetLoyaltyProgramByRestaurantQueryVariables>(GetLoyaltyProgramByRestaurantDocument, options);
      }
export function useGetLoyaltyProgramByRestaurantLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLoyaltyProgramByRestaurantQuery, GetLoyaltyProgramByRestaurantQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLoyaltyProgramByRestaurantQuery, GetLoyaltyProgramByRestaurantQueryVariables>(GetLoyaltyProgramByRestaurantDocument, options);
        }
export type GetLoyaltyProgramByRestaurantQueryHookResult = ReturnType<typeof useGetLoyaltyProgramByRestaurantQuery>;
export type GetLoyaltyProgramByRestaurantLazyQueryHookResult = ReturnType<typeof useGetLoyaltyProgramByRestaurantLazyQuery>;
export type GetLoyaltyProgramByRestaurantQueryResult = Apollo.QueryResult<GetLoyaltyProgramByRestaurantQuery, GetLoyaltyProgramByRestaurantQueryVariables>;