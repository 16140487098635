import { useGetBankingAccountByRestaurantQuery } from "../../../../GraphQL/Queries/getBankingAccountByRestaurant.generated"
import { useRestaurantCompletionStepsContext } from "../../../../shared/contexts/RestaurantCompletionStepsProvider"
import { useGeneralContext } from "../../../../shared/contexts/StoreProvider"
import type { BankingAccountModel } from "../../../../shared/graphql/generated/types"
import EmptyState from "../../../../ui/EmptyState"
import { showGraphqlErrors } from "../../../../ui/ErrorList"
import DetailPage from "../../../../ui/Layouts/MainLayout/DetailPage"
import PageTitle from "../../../../ui/PageTitle"
import { BankAccountRow } from "./BankAccountRow"
import { BankAccountTableSkeleton } from "./BankAccountTableSkeleton"
import { useGetStripeConfigQuery } from "./GraphQL/getStripeConfig.generated"
import { Center, ScrollArea, Table } from "@mantine/core"
import { Elements } from "@stripe/react-stripe-js"
import type { Stripe } from "@stripe/stripe-js"
import { loadStripe } from "@stripe/stripe-js"
import React, { useMemo, useState } from "react"
import { useIntl } from "react-intl"
import styled from "styled-components"

export const BankAccounts: React.FC = () => {
  const intl = useIntl()
  const { hasBankAccountSetupCompleted, updateRestaurantCompletionSteps } =
    useRestaurantCompletionStepsContext()

  const [stripePromise, setStripePromise] =
    useState<Promise<Stripe | null> | null>(null)

  const {
    state: {
      currentRestaurant: { uuid: currentRestaurantUUID },
    },
  } = useGeneralContext()

  const { loading: stripeLoading } = useGetStripeConfigQuery({
    fetchPolicy: "cache-first",
    onCompleted: ({ getStripeConfig: { publishableKey } }) => {
      try {
        setStripePromise(loadStripe(publishableKey))
      } catch (error) {
        showGraphqlErrors(error)
      }
    },
  })

  const { data, loading } = useGetBankingAccountByRestaurantQuery({
    variables: { restaurantUUID: currentRestaurantUUID },
    skip: !currentRestaurantUUID,
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
    onCompleted: ({ getBankingAccountsByRestaurant }) => {
      const hasBankAccountConnected = getBankingAccountsByRestaurant.some(
        ({ stIsConnected }) => stIsConnected
      )

      if (hasBankAccountSetupCompleted !== hasBankAccountConnected) {
        updateRestaurantCompletionSteps({
          hasBankAccountSetupCompleted: hasBankAccountConnected,
        })
      }
    },
  })

  const dataSource: BankingAccountModel[] = useMemo(
    () => data?.getBankingAccountsByRestaurant || [],
    [data?.getBankingAccountsByRestaurant]
  )

  return (
    <Elements stripe={stripePromise}>
      <DetailPage>
        <PageTitle
          title={intl.formatMessage({
            id: "restaurants.bank.accounts.title",
            defaultMessage: "Connected Bank Accounts",
          })}
          description={intl.formatMessage({
            id: "restaurants.bank.accounts.description",
            defaultMessage:
              "Here you can see all the Bank Accounts you have created and connected to Crmb Stripe Account",
          })}
        />
        <StyledBankAccountsContent>
          {loading || stripeLoading ? (
            <BankAccountTableSkeleton />
          ) : dataSource.length > 0 ? (
            <ScrollArea
              h="65vh"
              styles={{
                root: {
                  width: "100%",
                },
              }}
            >
              <Table verticalSpacing="xl" highlightOnHover>
                <thead>
                  <tr>
                    <th>
                      {intl.formatMessage({
                        id: "restaurants.bank.accounts.table.thead.name",
                        defaultMessage: "Name",
                      })}
                    </th>
                    <th>
                      {intl.formatMessage({
                        id: "restaurants.bank.accounts.table.thead.status",
                        defaultMessage: "Status",
                      })}
                    </th>
                    <th />
                  </tr>
                </thead>

                <tbody>
                  {dataSource.map((bankItem) => {
                    return <BankAccountRow key={bankItem.uuid} {...bankItem} />
                  })}
                </tbody>
              </Table>
            </ScrollArea>
          ) : (
            <Center h={400} mx="auto">
              <EmptyState
                title={intl.formatMessage({
                  id: "restaurants.bank.accounts.empty.title",
                  defaultMessage: "No bank accounts connected yet",
                })}
                description={intl.formatMessage({
                  id: "restaurants.bank.accounts.empty.description",
                  defaultMessage:
                    "You can connect one by clicking the plus button",
                })}
              />
            </Center>
          )}
        </StyledBankAccountsContent>
      </DetailPage>
    </Elements>
  )
}

const StyledBankAccountsContent = styled.div`
  margin-top: 48px;
`
