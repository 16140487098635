import { useGeneralContext } from "../../../../shared/contexts/StoreProvider"
import notification from "../../../../ui/notification"
import { ActionTypes } from "../../../Restaurants/actions"
import { useUpdateRestaurantMutation } from "../Home/EditRestaurant/GraphQL/updateRestaurant.generated"
import {
  Container,
  Divider,
  Group,
  List,
  Loader,
  Stack,
  Switch,
  Text,
  Title,
} from "@mantine/core"
import { ChangeEvent } from "react"
import { useIntl } from "react-intl"

export const CateringCorporate = () => {
  const intl = useIntl()
  const {
    state: {
      currentRestaurant: { uuid: restaurantUUID, isCateringActive },
      restaurants,
    },
    dispatch,
  } = useGeneralContext()
  const [updateRestaurant, { loading }] = useUpdateRestaurantMutation()

  const activateProgramLabel = intl.formatMessage({
    id: "settings.restaurant.catering.corporate.activate.program.label",
    defaultMessage: "Activate program",
  })

  const toggleCatering = async (value: boolean) => {
    try {
      await updateRestaurant({
        variables: { data: { restaurantUUID, isCateringActive: value } },
      })

      const updatedRestaurants = restaurants.map((restaurant) => ({
        ...restaurant,
        ...(restaurant.uuid === restaurantUUID && { isCateringActive: value }),
      }))

      dispatch({
        type: ActionTypes.AddAllRestaurants,
        payload: updatedRestaurants,
      })
      dispatch({
        type: ActionTypes.SetCurrentState,
        payload: { restaurantUUID: restaurantUUID, isCateringActive: value },
      })
    } catch (error) {
      notification({
        type: "error",
        description: intl.formatMessage({
          id: "settings.restaurant.catering.corporate.error.message",
          defaultMessage:
            "Something went wrong. Please try again. If the problem persists, contact support.",
        }),
      })
      console.error(error)
    }
  }

  const handleUpdateRestaurant = async (e: ChangeEvent<HTMLInputElement>) => {
    e.target.checked ? await toggleCatering(true) : await toggleCatering(false)
  }

  return (
    <Container py={64}>
      <Stack spacing={24}>
        <Stack spacing={2}>
          <Title order={3}>
            {intl.formatMessage({
              id: "settings.restaurant.catering.corporate.title",
              defaultMessage: "Catering",
            })}
          </Title>
          <Text pb={8}>
            {intl.formatMessage({
              id: "settings.restaurant.catering.corporate.subtitle",
              defaultMessage:
                "Crmb offers catering as a service for your restaurant.",
            })}
          </Text>
        </Stack>
        <Divider />
        <Stack>
          <Text c="gray.9">
            {intl.formatMessage({
              id: "settings.restaurant.catering.corporate.terms.and.conditions",
              defaultMessage: "Terms and conditions",
            })}
          </Text>
          <List>
            <List.Item>
              <Text size="sm" c="gray.6">
                {intl.formatMessage({
                  id: "settings.restaurant.catering.corporate.terms.and.conditions.to.catering.program",
                  defaultMessage:
                    "By activating the Catering program you will be responsible of creating menus that belong to the Catering menu type.",
                })}
              </Text>
            </List.Item>
            <List.Item>
              <Text size="sm" c="gray.6">
                {intl.formatMessage({
                  id: "settings.restaurant.catering.corporate.terms.and.conditions.customer.apps",
                  defaultMessage:
                    "If the program is activated but no catering menus are available then the catering option won’t appear on the customer apps.",
                })}
              </Text>
            </List.Item>
          </List>
        </Stack>
        <Stack>
          <Text c="gray.9">{activateProgramLabel}</Text>
          <Group>
            <Switch
              disabled={loading}
              checked={isCateringActive}
              onChange={handleUpdateRestaurant}
              aria-checked={true}
              aria-label={activateProgramLabel}
            />
            {loading && <Loader size="xs" />}
          </Group>
        </Stack>
      </Stack>
    </Container>
  )
}
