import * as Types from '../../../../../../shared/graphql/generated/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeleteDeliveryPriceMutationVariables = Types.Exact<{
  uuid: Types.Scalars['ID'];
  locationUUID: Types.Scalars['ID'];
}>;


export type DeleteDeliveryPriceMutation = { __typename?: 'Mutation', deleteDeliveryPrice: { __typename?: 'DeliveryPrice', uuid: string, label?: string | null, maxDistance: number, minDistance: number, price: number } };


export const DeleteDeliveryPriceDocument = gql`
    mutation deleteDeliveryPrice($uuid: ID!, $locationUUID: ID!) {
  deleteDeliveryPrice(data: {uuid: $uuid, location: {uuid: $locationUUID}}) {
    uuid
    label
    maxDistance
    minDistance
    price
  }
}
    `;
export type DeleteDeliveryPriceMutationFn = Apollo.MutationFunction<DeleteDeliveryPriceMutation, DeleteDeliveryPriceMutationVariables>;

/**
 * __useDeleteDeliveryPriceMutation__
 *
 * To run a mutation, you first call `useDeleteDeliveryPriceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDeliveryPriceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDeliveryPriceMutation, { data, loading, error }] = useDeleteDeliveryPriceMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *      locationUUID: // value for 'locationUUID'
 *   },
 * });
 */
export function useDeleteDeliveryPriceMutation(baseOptions?: Apollo.MutationHookOptions<DeleteDeliveryPriceMutation, DeleteDeliveryPriceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteDeliveryPriceMutation, DeleteDeliveryPriceMutationVariables>(DeleteDeliveryPriceDocument, options);
      }
export type DeleteDeliveryPriceMutationHookResult = ReturnType<typeof useDeleteDeliveryPriceMutation>;
export type DeleteDeliveryPriceMutationResult = Apollo.MutationResult<DeleteDeliveryPriceMutation>;
export type DeleteDeliveryPriceMutationOptions = Apollo.BaseMutationOptions<DeleteDeliveryPriceMutation, DeleteDeliveryPriceMutationVariables>;