import { RadioGroupButton } from "../../../../../../ui/Radio/RadioGroupButton/RadioGroupButton"
import { TabsItemsEnum } from "../../utils/TabsItemsEnum"
import { tabItemsOptions } from "../../utils/TabsItemsOptions"
import { TAB_SEARCH_FILTER_NAME } from "../../utils/searchParams"
import type { RadioChangeEvent } from "antd"
import { useMemo } from "react"
import { useIntl } from "react-intl"
import { useHistory, useLocation } from "react-router"

export const HoursTabs = () => {
  const intl = useIntl()
  const history = useHistory()
  const { search } = useLocation()
  const searchParams = useMemo(() => new URLSearchParams(search), [search])
  const param = searchParams.get(TAB_SEARCH_FILTER_NAME)

  const setTab = (e: RadioChangeEvent) => {
    const params = new URLSearchParams()
    params.set(TAB_SEARCH_FILTER_NAME, e.target.value)
    history.replace({ search: params.toString() })
  }

  return (
    <RadioGroupButton
      items={tabItemsOptions.map((option) => ({
        label: intl.formatMessage({
          id: option.labelId,
          defaultMessage: option.label,
        }),
        value: option.value,
      }))}
      type="secondary"
      value={
        param === TabsItemsEnum.STANDARD || param === TabsItemsEnum.HOLIDAY
          ? param
          : TabsItemsEnum.STANDARD
      }
      onChange={setTab}
    />
  )
}
