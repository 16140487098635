import { colors } from "../../../../../../styles/global/themes/mainTheme"
import type { PublishStatusEnum } from "../../titles/publishStatus.title"

const getBadgeColor = (status: PublishStatusEnum) => {
  switch (status) {
    case "WAITING_FOR_REVIEW": {
      return colors["Warning5"]
    }

    case "PUBLISHED": {
      return colors["Success5"]
    }

    case "FAILITURE": {
      return colors["Danger5"]
    }
    default: {
      return colors["Primary5"]
    }
  }
}

export default getBadgeColor
