import type { MenuItemProps } from "antd"
import { Menu as AntMenu } from "antd"
import React from "react"
import styled, { css } from "styled-components"

const { Item: AntMenuItem } = AntMenu

type StyledSubMenuItemProps = {
  width?: number
}

type SubMenuItem = MenuItemProps & StyledSubMenuItemProps

export const SubMenuItem: React.FC<SubMenuItem> = ({
  width = 176,
  ...props
}) => {
  return <StyledSubMenuItem width={width} {...props} />
}

const StyledSubMenuItem = styled(AntMenuItem)<StyledSubMenuItemProps>`
  ${({ theme, width }) => css`
    &.sidebar-submenu__item {
      padding-left: 30px !important;
    }

    &.sidebar-submenu__item {
      &.ant-menu-item a:hover,
      &.ant-menu-item-active,
      &.ant-menu-item-selected,
      &.ant-menu-item-selected a,
      &.ant-menu-item-selected a:hover {
        color: ${theme.colors["Neutral7"]};
      }

      &.ant-menu-item {
        width: ${width}px;
        border-radius: 4px;
        height: 32px;
        margin: 0px;

        .ant-menu-title-content {
          a {
            color: ${theme.colors["Neutral7"]};
          }
        }
      }

      &.ant-menu-item-active,
      &.ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
      &.ant-menu-submenu-title:hover {
        color: ${theme.colors["Neutral7"]};
        background: ${theme.colors["Neutral2"]};
        width: ${width}px;
      }
    }
  `}
`
