import { IntlFormattersType } from "../types"

export const REQUIRED_FIELD_ERROR_ID = "generic.error.required.field"
export const EXACT_LENGTH_ERROR_ID = "generic.error.exact.length"
export const PHONE_FORMAT_ERROR_ID = "generic.error.phone.format"
export const ALPHANUMERIC_DEVICE_CODE_ERROR_ID =
  "restaurants.devices.forms.generic.input.code.error.alphanumeric"

export const validationsTitle: IntlFormattersType = {
  [REQUIRED_FIELD_ERROR_ID]: {
    id: REQUIRED_FIELD_ERROR_ID,
    defaultMessage: "This field is required",
  },
  [ALPHANUMERIC_DEVICE_CODE_ERROR_ID]: {
    id: ALPHANUMERIC_DEVICE_CODE_ERROR_ID,
    defaultMessage: "The code must be alphanumeric",
  },
  [PHONE_FORMAT_ERROR_ID]: {
    id: PHONE_FORMAT_ERROR_ID,
    defaultMessage: "Invalid format for phone number",
  },
  [EXACT_LENGTH_ERROR_ID]: {
    id: EXACT_LENGTH_ERROR_ID,
    defaultMessage: "The field must be {length} characters",
  },
}
