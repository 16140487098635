import type { WeekDaysEnum } from "../../../../../shared/graphql/generated/types"
import firstLetterToUpperCase from "../../../../../shared/utils/helpers/firstLetterToUpperCase"
import safeNumber from "../../../../../shared/utils/helpers/safeNumber"
import Button from "../../../../../ui/Button"
import { Container } from "../../../../../ui/Container/Container"
import DeleteIconButton from "../../../../../ui/DeleteIconButton"
import Icon from "../../../../../ui/Icon"
import InputNumber from "../../../../../ui/Inputs/InputNumber"
import TimeSelectRange from "../../../../../ui/TimeSelectRange"
import { TimeRangeEnum } from "../../../../../ui/TimeSelectRange/time-select-range.enums"
import Text from "../../../../../ui/Typography/Text"
import type { TimeInterval } from "../utils/override-rules.types"
import classnames from "classnames"
import moment from "moment-timezone"
import React, { useState } from "react"
import { useIntl } from "react-intl"
import styled from "styled-components"

interface ThrottlingHoursCellProps {
  day: WeekDaysEnum
  disabled?: boolean
  rules: Array<TimeInterval> | undefined
  onChange?: (
    changedHours: Array<TimeInterval>,
    day: string,
    isChecked: boolean
  ) => void
  readonly?: boolean
  invalidIndexes?: Array<number>
  invalidOrdersLimitIndexes?: Array<number>
  defaultOrdersLimitValue?: number
}

export const ThrottlingHoursCell: React.FC<ThrottlingHoursCellProps> = ({
  day,
  rules,
  onChange,
  readonly,
  invalidIndexes,
  invalidOrdersLimitIndexes,
  defaultOrdersLimitValue,
  disabled = false,
}) => {
  const intl = useIntl()

  const defaultCellState: TimeInterval[] = [
    {
      day: day,
      startDate: undefined,
      endDate: undefined,
      ordersLimit: defaultOrdersLimitValue,
    },
  ]

  const inHoursInitialState = rules?.length ? rules : defaultCellState
  const [intHours, setIntHours] =
    useState<Array<TimeInterval>>(inHoursInitialState)
  const isCheckedInitialState = rules?.length ? true : false
  const [isChecked, setIsChecked] = useState(isCheckedInitialState)

  const onTimePickerChange = (
    id: number | string,
    timeString: string | null,
    field: `${TimeRangeEnum}`
  ) => {
    const index = Number(id)

    if (!isChecked) setIsChecked(true)

    setIntHours((prev) => {
      const time = timeString ? timeString : undefined
      const newHours = [...prev]

      newHours[index] =
        field === TimeRangeEnum.START
          ? {
              ...newHours[index],
              startDate: time,
              endDate: newHours[index].endDate,
            }
          : {
              ...newHours[index],
              startDate: newHours[index].startDate,
              endDate: time,
            }

      if (time === undefined) {
        onSaveHours()
      }

      onChange && onChange(getISOFormatInterval(newHours), day, true)

      return newHours
    })
  }

  const onInputChange = (ordersLimit: number | null, id: number | string) => {
    const index = Number(id)

    setIntHours((prev) => {
      const newHours = [...prev]

      newHours[index] = {
        ...newHours[index],
        ordersLimit,
      }

      onChange && onChange(getCompleteInterval(newHours), day, isChecked)

      return newHours
    })
  }

  const addHours = () => {
    setIntHours([
      ...intHours,
      {
        day: undefined,
        startDate: undefined,
        endDate: undefined,
        ordersLimit: defaultOrdersLimitValue,
      },
    ])
  }

  const deleteHour = (index: string | number) => {
    const newHours = intHours?.filter((_, ind) => ind !== index)
    setIntHours(newHours)
    const intervalISOFormat = getISOFormatInterval(newHours)

    onChange?.(intervalISOFormat, day, isChecked)
  }

  const getISODate = (currentDay: string, hours: string, minutes: string) => {
    return moment()
      .isoWeekday(currentDay)
      .set("hour", safeNumber(hours))
      .set("minute", safeNumber(minutes))
      .toISOString(true)
  }

  const getISOFormatInterval = (timeInterval: TimeInterval[]) => {
    const rulesWithoutUndefined = timeInterval.filter(
      (overrideRule) => !!overrideRule.startDate || !!overrideRule.endDate
    )

    return rulesWithoutUndefined.map((overrideRule) => {
      const hhmmStart = overrideRule.startDate?.split(":") || []
      const hhmmEnd = overrideRule.endDate?.split(":") || []
      const ordersLimit = overrideRule.ordersLimit || defaultOrdersLimitValue

      const [startHours, startMinutes] = hhmmStart
      const [endHours, endMinutes] = hhmmEnd

      return {
        day,
        ordersLimit,
        endDate: overrideRule.endDate
          ? getISODate(day, endHours, endMinutes)
          : undefined,
        startDate: overrideRule.startDate
          ? getISODate(day, startHours, startMinutes)
          : undefined,
      }
    })
  }

  const getCompleteInterval = (timeInterval: TimeInterval[]) => {
    const rulesWithoutUndefined = timeInterval.filter(
      (overrideRule) => !!overrideRule.startDate || !!overrideRule.endDate
    )

    return rulesWithoutUndefined.map((overrideRule) => {
      const hhmmStart = overrideRule.startDate?.split(":") || []
      const hhmmEnd = overrideRule.endDate?.split(":") || []
      const ordersLimit = overrideRule.ordersLimit

      const [startHours, startMinutes] = hhmmStart
      const [endHours, endMinutes] = hhmmEnd

      return {
        day,
        ordersLimit,
        endDate: overrideRule.endDate
          ? getISODate(day, endHours, endMinutes)
          : undefined,
        startDate: overrideRule.startDate
          ? getISODate(day, startHours, startMinutes)
          : undefined,
      }
    })
  }

  const onSaveHours = () => {
    onChange && onChange(getISOFormatInterval(intHours), day, isChecked)
  }

  return (
    <StyledContainerWrapper
      display="flex"
      justifyContent="space-between"
      gap="20px"
    >
      <StyledRowWrapper>
        <StyledText>
          <Text size="s">{firstLetterToUpperCase(day)}</Text>
        </StyledText>

        <StyledTimePickersWrapper>
          {intHours.map((interval, intervalIndex) => (
            <Container
              key={intervalIndex}
              display="flex"
              flexDirection="row"
              gap="20px"
            >
              <TimeSelectRange
                startTime={interval.startDate}
                endTime={interval.endDate}
                onChange={(time, type) =>
                  onTimePickerChange(intervalIndex, time, type)
                }
                onClear={(type) =>
                  onTimePickerChange(intervalIndex, null, type)
                }
                allowAllDay={false}
                disabled={disabled || readonly}
                error={
                  invalidIndexes?.includes(intervalIndex)
                    ? "Invalid Time Range"
                    : undefined
                }
              />

              {readonly ? (
                <StyledMaxOrdersLabel size="s" className="max-orders-input">
                  {intl.formatMessage(
                    {
                      id: "settings.locations.order.throttling.orders.per.min.label",
                      defaultMessage:
                        "{ordersLimit,plural,=0 {} one {{ordersLimit} Order / 15 min} other {{ordersLimit} Orders / 15 min}} ",
                    },
                    {
                      ordersLimit: interval.ordersLimit ?? 0,
                    }
                  )}
                </StyledMaxOrdersLabel>
              ) : (
                <InputNumber
                  min={1}
                  defaultValue={defaultOrdersLimitValue}
                  value={
                    interval.startDate &&
                    interval.endDate &&
                    interval.ordersLimit?.toString()
                  }
                  onChange={(value) => {
                    onInputChange(value, intervalIndex)
                  }}
                  size="middle"
                  hasError={invalidOrdersLimitIndexes?.includes(intervalIndex)}
                  width="94px"
                  type="number"
                  requirement="required"
                  placeholder={intl.formatMessage({
                    id: "settings.locations.order.throttling.default.oder.throttling.hours",
                    defaultMessage: "10",
                  })}
                  suffix={
                    <Icon
                      size={12}
                      remixiconClass="ri-pencil-line"
                      color="Primary5"
                    />
                  }
                />
              )}

              <DeleteIconButton
                id={intervalIndex}
                onDelete={deleteHour}
                disabled={disabled}
                readonly={readonly || intervalIndex === 0}
              />
            </Container>
          ))}
        </StyledTimePickersWrapper>
      </StyledRowWrapper>

      <Button
        type="button"
        hierarchy="secondary"
        title={intl.formatMessage({
          id: "settings.locations.order.throttling.add.override.button",
          defaultMessage: "Add Override",
        })}
        onClick={addHours}
        className={classnames({ ["override-button-hidden"]: readonly })}
      />
    </StyledContainerWrapper>
  )
}

const StyledContainerWrapper = styled(Container)`
  width: 100%;
  .override-button-hidden {
    opacity: 0;
  }
`

const StyledRowWrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
`

const StyledText = styled.div`
  width: 80px;
  height: 32px;
  flex-shrink: 0;
  margin-right: 20px;
  display: flex;
  align-items: center;
`

const StyledTimePickersWrapper = styled.div`
  height: auto;
  display: flex;
  flex-direction: column;
  gap: 16px;

  &:hover,
  &.focused {
    .override-icon {
      display: initials;
    }
  }

  .override-icon {
    display: none;
  }
`

const StyledMaxOrdersLabel = styled(Text)`
  &.max-orders-input {
    width: 130px;
    line-height: 32px;
  }
`
