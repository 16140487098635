import { MESSAGE_MAX_ORDERS } from "../../../../shared/utils/constant/errors"
import {
  MAX_NUMBER_ORDER,
  MIN_NUMBER_ORDER,
} from "../../../../shared/utils/constant/orderThrottling"
import yup from "../../../../shared/yup"
import { OverrideRulesSchema } from "./ThrottlingHoursCell/ThrottlingHoursCell.yup"
import { yupResolver } from "@hookform/resolvers/yup"

const OrderThrottlingSchema = yup.object().shape({
  defaultOrdersLimit: yup
    .number()
    .moreThan(MIN_NUMBER_ORDER)
    .lessThan(MAX_NUMBER_ORDER, MESSAGE_MAX_ORDERS)
    .required(),
  rules: OverrideRulesSchema.notRequired().default([]),
})

export const OrderThrottlingSchemaResolver = yupResolver(OrderThrottlingSchema)
