import type { SizeType, TagType } from "../../styles/global/types"
import Icon from "../Icon"
import Text from "../Typography/Text"
import type { TagProps as AntTagProps } from "antd"
import { Tag as AntTag, Tooltip } from "antd"
import type CSS from "csstype"
import React from "react"
import type { IBody } from "styled-components"
import styled, { css } from "styled-components"

export type TagProps = AntTagProps & {
  cursor?: CSS.Property.Cursor
  withTooltip?: boolean
  tooltipText?: string
  borderStyle?: CSS.Property.BorderStyle
  size?: SizeType
  icon?: string
  type?: TagType
  margin?: boolean
}

const Div: React.FC = ({ children }) => {
  return <div>{children}</div>
}

const fontLevel: { [key in SizeType]: keyof IBody } = {
  small: "s",
  middle: "m",
  large: "l",
}

export const Tag: React.FC<TagProps> = ({
  children,
  withTooltip,
  tooltipText,
  icon,
  borderStyle = "solid",
  closeIcon = (
    <Icon remixiconClass="ri-close-line" size={12} verticalAlign="middle" />
  ),
  margin = true,
  ...props
}) => {
  const Wrapper = withTooltip ? Tooltip : Div

  return (
    <Wrapper title={tooltipText || children}>
      <StyledTag
        $borderStyle={borderStyle}
        closeIcon={closeIcon}
        margin={margin}
        {...props}
      >
        {icon && <Icon classes="tag__icon" remixiconClass={icon} size={12} />}
        <Text
          className="tag__content"
          size={fontLevel[`${props.size ?? "middle"}`]}
        >
          {children}
        </Text>
      </StyledTag>
    </Wrapper>
  )
}

export const getTagHeight = (size?: SizeType) => {
  switch (size) {
    case "small": {
      return "20px"
    }

    case "large": {
      return "28px"
    }
    default: {
      return "24px"
    }
  }
}

export type StyledTagProps = {
  cursor?: CSS.Property.Cursor
  $borderStyle?: CSS.Property.BorderStyle
  size?: SizeType
  type?: TagType
  margin?: boolean
}

const StyledTag = styled(AntTag)<StyledTagProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 8px;
  gap: 4px;
  box-sizing: border-box;
  width: fit-content;
  ${({ size, margin }) => css`
    margin-right: ${margin ? (size === "large" ? "8px" : "4px") : "0px"};
    height: ${getTagHeight(size)};
  `};

  ${({ cursor }) =>
    cursor &&
    css`
      &:hover {
        cursor: ${cursor};
      }
    `};

  ${({ $borderStyle }) =>
    css`
      border-style: ${$borderStyle};
    `};

  ${({ type = "primary", theme }) =>
    (type === "primary" &&
      css`
        background-color: ${theme.colors["Primary1"]};
        border-color: ${theme.colors["Primary5"]};

        & .tag__content,
        & .tag__icon > i {
          color: ${theme.colors["Primary5"]};
        }

        & .ant-tag-close-icon .anticon > i {
          color: ${theme.colors["Primary4"]};
        }
      `) ||
    (type === "secondary" &&
      css`
        background-color: ${theme.colors["Neutral3"]};
        border-color: ${theme.colors["Neutral5"]};

        & .tag__content,
        & .tag__icon > i {
          color: ${theme.colors["Neutral7"]};
        }

        & .ant-tag-close-icon .anticon > i {
          color: ${theme.colors["Neutral7"]};
        }
      `) ||
    (type === "ghost" &&
      css`
        background-color: ${theme.colors.Neutral2};
        border-color: ${theme.colors["Neutral4"]};

        & .tag__content,
        & .tag__icon > i {
          color: ${theme.colors["Neutral5"]};
        }

        & .ant-tag-close-icon .anticon > i {
          color: ${theme.colors["Neutral5"]};
        }
      `) ||
    (type === "warning" &&
      css`
        background-color: ${theme.colors["Warning1"]};
        border-color: ${theme.colors["Warning4"]};

        & .tag__content,
        & .tag__icon > i {
          color: ${theme.colors["Warning5"]};
        }

        & .ant-tag-close-icon .anticon > i {
          color: ${theme.colors["Warning4"]};
        }
      `)}

  .tag__content {
    display: flex;
    align-items: center;
  }
`
