import { useGeneralContext } from "../../../../../shared/contexts/StoreProvider"
import {
  OrderMethodAcceptanceEnum,
  OrderMethodEnum,
} from "../../../../../shared/graphql/generated/types"
import { useUpdateLocationMutation } from "../../GraphQL/updateLocation.generated"
import { verifyTypeServices } from "../../utils/catering-utils"
import styles from "../Catering.module.css"
import { useGetCateringLocationQuery } from "../Graphql/getCateringLocation.generated"
import { TermsAndConditionsModal } from "../TermsAndConditionsModal"
import { SelectLabel } from "../components/SelectLabel"
import { CancelationPolicyForm } from "../forms/CancelationPolicyForm"
import { CateringFeeForm } from "../forms/CateringFeeForm"
import { FormCateringResolver } from "../forms/FormCatering.yup"
import { MinimumOrderAmountForm } from "../forms/MinimumOrderAmountForm"
import { OrderSchedulingForm } from "../forms/OrderSchedulingForm"
import { PaymentForm } from "../forms/PaymentForm"
import { RefundPolicyForm } from "../forms/RefundPolicyForm"
import { TypeOfServiceForm } from "../forms/TypeOfServiceForm"
import { orderAcceptanceMethods } from "../shared/config"
import type {
  CancelPoliciesType,
  CateringFeeType,
  CateringForm,
  EmployeeType,
  MinimumAmountType,
  PrePaymentType,
  ServicesType,
} from "../shared/hookforms.intefaces"
import { useGetSingleLocationCacheUpdate } from "../utils/cache/get-single-location-cache.util"
import { prePaymentVerify } from "../utils/pre-payment-verify"
import {
  Button,
  Container,
  Group,
  MultiSelect,
  SegmentedControl,
  SegmentedControlItem,
  Stack,
  Text,
  Title,
  useMantineTheme,
} from "@mantine/core"
import { useDisclosure } from "@mantine/hooks"
import { IconAlertCircle, IconDirection } from "@tabler/icons-react"
import { useState } from "react"
import {
  Controller,
  FormProvider,
  useFieldArray,
  useForm,
} from "react-hook-form"
import { FormattedMessage } from "react-intl"
import { useParams } from "react-router-dom"

interface FormCateringProps {
  employees: EmployeeType[]
  employeesUUID?: string[]
  cateringOrderAcceptMethod?: OrderMethodAcceptanceEnum
  services?: ServicesType[]
  cancelPolicy?: {
    isApply: boolean
    cancelPolicies: CancelPoliciesType[]
  }
  orderCateringUUID?: string
  daysInAdvance?: number
  prePayment?: PrePaymentType
  minimumAmount?: MinimumAmountType
  fees?: CateringFeeType
  loading: boolean
  upsertSubmit: (formData: CateringForm) => Promise<void>
}

export const FormCatering = ({
  services,
  employees,
  employeesUUID,
  daysInAdvance,
  cancelPolicy,
  upsertSubmit,
  cateringOrderAcceptMethod,
  prePayment,
  minimumAmount,
  fees,
  loading,
}: FormCateringProps) => {
  const { colors } = useMantineTheme()
  const {
    state: {
      selectedLocation: { uuid: contextLocationUUID },
    },
  } = useGeneralContext()
  const { locationUUID: paramsLocationUUID } =
    useParams<{ locationUUID: string }>()

  const locationUUID = contextLocationUUID || paramsLocationUUID

  const [disableValue, setDisableValue] = useState<string | null>(null)

  const { cateringActivateSingleLocationCacheUpdate } =
    useGetSingleLocationCacheUpdate()

  const [isTermsModalOpen, { close: closeTermsModal, open: openTermsModal }] =
    useDisclosure()

  const [updateLocation, { loading: updateLoading }] =
    useUpdateLocationMutation()

  const typeOfServices = verifyTypeServices(services ?? [])

  const formMethods = useForm<CateringForm>({
    resolver: FormCateringResolver,
    mode: "all",
    defaultValues: {
      selectedEmployees: employeesUUID ?? [],
      acceptanceMethod:
        cateringOrderAcceptMethod ?? OrderMethodAcceptanceEnum.AUTOMATIC,
      rules: {
        typeOfService: typeOfServices,
        minimumAmount: minimumAmount ?? {},
        cateringFee: fees ?? {},
        orderScheduling: { daysInAdvance: daysInAdvance ?? 15 },
        cancelPolicy: {
          isApply: cancelPolicy?.isApply ?? false,
          cancelPolicies: cancelPolicy?.cancelPolicies?.some(
            (item) => item.timeBeforeEvent !== undefined
          )
            ? cancelPolicy?.cancelPolicies
            : [
                {
                  percentage: undefined,
                  timeBeforeEvent: undefined,
                },
              ],
        },
        prePayment: prePaymentVerify(prePayment),
      },
    },
  })

  const {
    reset,
    control,
    handleSubmit,
    setValue,
    formState: { dirtyFields, isDirty, errors },
  } = formMethods

  const { fields, append, remove, replace } = useFieldArray({
    control,
    name: "rules.cancelPolicy.cancelPolicies",
    rules: {
      maxLength: 3,
      minLength: 1,
      required: true,
    },
  })

  const { data, loading: loadingCateringLocation } =
    useGetCateringLocationQuery({
      variables: {
        locationUUID,
      },
      onCompleted: ({ getSingleLocation }) => {
        if (getSingleLocation) {
          setValue("isActive", getSingleLocation.isCateringActive)
        }
      },
    })

  const isCateringActive = Boolean(
    data?.getSingleLocation?.orderTypes?.includes(OrderMethodEnum.CATERING)
  )

  const toggleCateringActive = () => {
    const orderTypes: OrderMethodEnum[] =
      data?.getSingleLocation.orderTypes ?? []

    const updatedOrderTypes = isCateringActive
      ? orderTypes.filter((item) => item !== OrderMethodEnum.CATERING)
      : [...orderTypes, OrderMethodEnum.CATERING]

    updateLocation({
      variables: {
        data: {
          locationUUID,
          orderTypes: updatedOrderTypes,
        },
      },
      update: (cache, result) => {
        cateringActivateSingleLocationCacheUpdate(cache, result, {})
      },
      onCompleted: (result) => {
        if (result.updateLocation) {
          setValue("isActive", result.updateLocation.isCateringActive)
        }
      },
    })
  }

  const segmentedControlData = Object.keys(
    orderAcceptanceMethods
  ).map<SegmentedControlItem>((key) => {
    const method =
      orderAcceptanceMethods[key as keyof typeof orderAcceptanceMethods]
    return {
      label: (
        <FormattedMessage
          defaultMessage={method.defaultMessage}
          id={method.id}
        />
      ),
      value: key,
    }
  })

  const cancelPolicyObject: CancelPoliciesType = {
    percentage: undefined,
    timeBeforeEvent: undefined,
  }

  const handleDiscardChanges = () => {
    const cancelPolicies =
      cancelPolicy && cancelPolicy?.cancelPolicies.length > 0
        ? cancelPolicy.cancelPolicies
        : [cancelPolicyObject]

    reset(undefined, { keepDirty: false })
    setValue("isActive", Boolean(isCateringActive))
    setValue("rules.cancelPolicy.isApply", Boolean(cancelPolicy?.isApply))
    setValue("rules.cancelPolicy.cancelPolicies", cancelPolicies)
    replace(cancelPolicies)
    setDisableValue(null)
  }

  const onSubmitCateringRules = async (formData: CateringForm) => {
    await upsertSubmit(formData)
    reset(undefined, {
      keepDirty: false,
      keepValues: true,
    })
    setValue("isActive", Boolean(isCateringActive))
  }

  const validateFormCatering: boolean = !isDirty || !isCateringActive || loading
  const formHasErrors = Object.keys(errors ?? {}).length > 0
  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(onSubmitCateringRules)}>
        <Container
          mx={{ base: 20, xs: 20, sm: 40, md: 80, xl: 184 }}
          my={64}
          mb={40}
        >
          <Stack spacing={40}>
            <Stack spacing={8}>
              <Group position="apart">
                <Title size="h3" order={1}>
                  <FormattedMessage
                    id="restaurants.settings.location.catering.header"
                    defaultMessage="Catering"
                  />
                </Title>
                {!loadingCateringLocation && (
                  <Button
                    w={98}
                    h={32}
                    p={0}
                    variant={isCateringActive ? "subtle" : "filled"}
                    onClick={toggleCateringActive}
                    loading={updateLoading}
                  >
                    <Text size="sm" weight={400} hidden={updateLoading}>
                      <FormattedMessage
                        id="restaurants.settings.location.catering.activate"
                        defaultMessage="{isActive, select, true {Deactivate} other {Activate}}"
                        values={{ isActive: Boolean(isCateringActive) }}
                      />
                    </Text>
                  </Button>
                )}
              </Group>
              <Text size="sm">
                <FormattedMessage
                  id="restaurants.settings.location.catering.description"
                  defaultMessage="<p>Corporate has activated catering.{br}This enables your location to handle larger orders. You can customize the catering rules according to your preferences.
            {br}{br}By activating the Catering program you will be responsible of creating menus that belong to the Catering menu type.
            {br}If the program is activated but no catering menus are available then the catering option won’t appear on the customer apps.</p>"
                  values={{ p: (text: string) => <p>{text}</p>, br: <br /> }}
                />
              </Text>
            </Stack>

            <Group id="employee-selection" pos="relative">
              <Controller
                control={control}
                name="selectedEmployees"
                render={({ field }) => (
                  <MultiSelect
                    w="100%"
                    data={employees}
                    searchable
                    required
                    disabled={!isCateringActive}
                    {...field}
                    valueComponent={SelectLabel}
                    rightSection={
                      <IconDirection size={20} color={colors.gray[6]} />
                    }
                    nothingFound="No options"
                    label={
                      <Text size="lg" display="inline">
                        <FormattedMessage
                          id="restaurants.settings.location.catering.employees.select.label"
                          defaultMessage="Employees"
                        />
                      </Text>
                    }
                    description={
                      <Text size="sm" c="gray.9">
                        <FormattedMessage
                          id="restaurants.settings.location.catering.employees.select.description"
                          defaultMessage="You must select at least one employee so that we can notify them when a catering order arrives."
                        />
                      </Text>
                    }
                    classNames={{
                      required: styles.asterisk,
                      description: styles.description,
                    }}
                  />
                )}
              />
              {dirtyFields.selectedEmployees && (
                <IconAlertCircle
                  display={0}
                  className={styles["employee-alert-icon"]}
                  size={24}
                  color={colors.yellow[5]}
                />
              )}
            </Group>

            <Stack spacing={0}>
              <Text size="lg" mb={4}>
                <FormattedMessage
                  defaultMessage="Catering order acceptance method"
                  id="restaurants.settings.location.catering.order.acceptance.method.label"
                />
              </Text>
              <Text size="sm" mb={16}>
                <FormattedMessage
                  defaultMessage="Select either automatic or manual order acceptance"
                  id="restaurants.settings.location.catering.order.acceptance.method.description"
                />
              </Text>
              <Controller
                control={control}
                name="acceptanceMethod"
                render={({ field }) => (
                  <SegmentedControl
                    {...field}
                    disabled={!isCateringActive}
                    classNames={{
                      indicator: styles["sc-indicator"],
                      label: styles["sc-label"],
                    }}
                    w={184}
                    h={32}
                    size="xs"
                    data={segmentedControlData}
                  />
                )}
              />
            </Stack>

            <Stack spacing={16} id="rules-section">
              <Stack spacing={4}>
                <Text size="lg">
                  <FormattedMessage
                    defaultMessage="Rules"
                    id="restaurants.settings.location.catering.rules.title"
                  />
                </Text>
                <Text size="sm">
                  <FormattedMessage
                    defaultMessage="The Catering Terms and Conditions for the customer apps contain the following rules."
                    id="restaurants.settings.location.catering.rules.description"
                  />
                </Text>
              </Stack>
              <TypeOfServiceForm />
              <OrderSchedulingForm />
              <MinimumOrderAmountForm />
              <CateringFeeForm />
              <CancelationPolicyForm
                fields={fields}
                append={append}
                remove={remove}
                disableValue={disableValue}
                setDisableValue={setDisableValue}
              />
              <RefundPolicyForm />
              <PaymentForm />
            </Stack>
          </Stack>
          <Group mt={40} position="apart">
            <Group spacing={16}>
              <Button
                type="submit"
                loading={loading}
                disabled={formHasErrors || !isDirty || !isCateringActive}
              >
                <FormattedMessage
                  id="restaurants.settings.location.catering.rules.save.changes.button"
                  defaultMessage="Save Changes"
                />
              </Button>
              <Button
                variant="outline"
                onClick={handleDiscardChanges}
                disabled={validateFormCatering}
              >
                <FormattedMessage
                  id="restaurants.settings.location.catering.rules.discard.changes.button"
                  defaultMessage="Discard Changes"
                />
              </Button>
            </Group>
            <Button
              variant="outline"
              onClick={openTermsModal}
              disabled={formHasErrors || !isCateringActive}
            >
              <FormattedMessage
                id="restaurants.settings.location.catering.rules.preview.button"
                defaultMessage="Preview"
              />
            </Button>
          </Group>
        </Container>
      </form>
      <TermsAndConditionsModal
        opened={isTermsModalOpen}
        onClose={closeTermsModal}
      />
    </FormProvider>
  )
}
