export const ADDRESS_TYPES_REGEX = /(street_address|postal_code|premise)/

export const validateGoogleAddress = (
  regexToFilter: RegExp,
  item: { types: string[] }
) => item.types.some((type) => regexToFilter.test(type))

/* global google */
export const checkIfRealAddress = (
  addressesArray: google.maps.GeocoderResult[]
) =>
  addressesArray.length > 0 &&
  addressesArray.find((item: { types: string[] }) =>
    validateGoogleAddress(ADDRESS_TYPES_REGEX, item)
  )
