import Input, { InputNumber } from "../../../../../ui/Inputs"
import Spacer from "../../../../../ui/Spacer"
import { TableFormSkeleton } from "./TableForm.skeleton"
import TableZoneSelect from "./TableZoneSelect"
import type {
  ILocationTableForm,
  ITablesPageParams,
} from "./hookforms.interfaces"
import get from "lodash/get"
import React from "react"
import { Controller, useFormContext } from "react-hook-form"
import { useIntl } from "react-intl"
import { useParams } from "react-router-dom"
import styled, { css } from "styled-components"

type TableFormProps = Omit<React.ComponentProps<"form">, "ref"> & {
  loading?: boolean
}

export const TableForm: React.FC<TableFormProps> = ({ loading, ...props }) => {
  const intl = useIntl()
  const { locationUUID } = useParams<ITablesPageParams>()

  const {
    control,
    formState: { errors },
  } = useFormContext<ILocationTableForm>()

  return (
    <StyledTableForm
      className="table-form"
      role="form"
      aria-label="table-form"
      {...props}
    >
      <TableFormSkeleton loading={loading} active={loading}>
        <Controller
          control={control}
          name="name"
          render={({ field: nameField }) => (
            <Input
              {...nameField}
              label={intl.formatMessage({
                id: "restaurants.location.table.form.number.label",
                defaultMessage: "Table Number",
              })}
              aria-label="name"
              hasError={!!errors?.name}
              helperText={errors?.name?.message}
              requirement="required"
              capitalizeFirstLetter={false}
            />
          )}
        />

        <Spacer size={24} />
        <Controller
          control={control}
          name="description"
          render={({ field: descriptionField }) => (
            <Input
              {...descriptionField}
              label={intl.formatMessage({
                id: "restaurants.location.table.form.description.label",
                defaultMessage: "Description",
              })}
              capitalizeFirstLetter={false}
            />
          )}
        />

        <Spacer size={24} />
        <Controller
          control={control}
          name="capacity"
          render={({ field: capacityField }) => (
            <InputNumber
              {...capacityField}
              aria-label="capacity"
              type="number"
              min="1"
              label={intl.formatMessage({
                id: "restaurants.location.table.form.capacity.label",
                defaultMessage: "Capacity",
              })}
              requirement="required"
              hasError={!!errors?.["capacity"]}
              helperText={get(errors, "capacity.message")}
              onlyInteger
            />
          )}
        />

        <Spacer size={24} />
        <TableZoneSelect locationUUID={locationUUID} />
        <Spacer size={24} />

        <button type="submit" className="hidden-button" />
      </TableFormSkeleton>
    </StyledTableForm>
  )
}

const StyledTableForm = styled.form`
  &.table-form {
    .hidden-button {
      display: none;
    }

    .anticon-close {
      ${({ theme }) => css`
        color: ${theme.colors["Danger4"]};
      `}
    }
  }
`
