import * as Types from '../../../../../../shared/graphql/generated/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateTaxMutationVariables = Types.Exact<{
  data: Types.CreateTaxInput;
}>;


export type CreateTaxMutation = { __typename?: 'Mutation', createTax: { __typename?: 'TaxModel', uuid: string, name: string, taxRate: number, description?: string | null, isGeneral?: boolean | null, applyFutureLocations?: boolean | null } };


export const CreateTaxDocument = gql`
    mutation createTax($data: CreateTaxInput!) {
  createTax(data: $data) {
    uuid
    name
    taxRate
    description
    isGeneral
    applyFutureLocations
  }
}
    `;
export type CreateTaxMutationFn = Apollo.MutationFunction<CreateTaxMutation, CreateTaxMutationVariables>;

/**
 * __useCreateTaxMutation__
 *
 * To run a mutation, you first call `useCreateTaxMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTaxMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTaxMutation, { data, loading, error }] = useCreateTaxMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateTaxMutation(baseOptions?: Apollo.MutationHookOptions<CreateTaxMutation, CreateTaxMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTaxMutation, CreateTaxMutationVariables>(CreateTaxDocument, options);
      }
export type CreateTaxMutationHookResult = ReturnType<typeof useCreateTaxMutation>;
export type CreateTaxMutationResult = Apollo.MutationResult<CreateTaxMutation>;
export type CreateTaxMutationOptions = Apollo.BaseMutationOptions<CreateTaxMutation, CreateTaxMutationVariables>;