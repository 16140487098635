import { useGeneralContext } from "../../../../shared/contexts/StoreProvider"
import DataTable from "../../../../ui/DataTable"
import EmptyState from "../../../../ui/EmptyState"
import DetailPage from "../../../../ui/Layouts/MainLayout/DetailPage"
import PageTitle from "../../../../ui/PageTitle"
import { useGetAllMembersByRestaurantQuery } from "./GraphQL/getAllMembersByRestaurant.generated"
import type { IMembersTableModel } from "./interfaces/membersTable.interfaces"
import membersTableConfig from "./utils/members-list.util"
import get from "lodash/get"
import React, { useEffect } from "react"
import { useIntl } from "react-intl"
import styled from "styled-components"

export const Members: React.FC = () => {
  const intl = useIntl()
  const [columns] = membersTableConfig()

  const {
    state: {
      currentRestaurant: { uuid: restaurantUUID, name: restaurantName },
    },
  } = useGeneralContext()

  const { data, loading, error, refetch } = useGetAllMembersByRestaurantQuery({
    variables: {
      restaurantUUID,
    },
    skip: !restaurantUUID,
  })

  const preData = get(data, "getAllMembersByRestaurant", [])

  const memberList: IMembersTableModel[] = preData.map((member) => {
    return {
      uuid: member.inviteUUID,
      ...member,
    }
  })

  const retry = () => refetch()

  useEffect(() => {
    if (restaurantUUID) {
      refetch()
    }
  }, [refetch, restaurantUUID])

  return (
    <DetailPage>
      <PageTitle
        title={intl.formatMessage(
          {
            id: "settings.restaurant.members.title",
            defaultMessage: "{restaurantName} Members",
          },
          { restaurantName }
        )}
        description={intl.formatMessage({
          id: "settings.restaurant.members.description",
          defaultMessage: "Here you can see a list of members that you added.",
        })}
      />
      <StyledMembersContent role="members-table">
        <DataTable
          loading={loading}
          dataSource={memberList}
          emptyMessage={
            <EmptyState
              title={intl.formatMessage({
                id: "settings.restaurant.members.datatable.empty.title",
                defaultMessage: "No members added yet",
              })}
              description={intl.formatMessage({
                id: "settings.restaurant.members.datatable.empty.description",
                defaultMessage: "You can add one by clicking the plus button",
              })}
            />
          }
          columns={columns}
          error={error}
          errorRefetch={retry}
          substactPixelesToScrollHeight={311}
        />
      </StyledMembersContent>
    </DetailPage>
  )
}

const StyledMembersContent = styled.div`
  margin-top: 48px;
`
