import { GetBankingAccountByRestaurantDocument } from "../../../../../GraphQL/Queries/getBankingAccountByRestaurant.generated"
import { useClickOutside } from "../../../../../shared/hooks/useClickOutside"
import Button from "../../../../../ui/Button"
import Container from "../../../../../ui/Container"
import { showGraphqlErrors } from "../../../../../ui/ErrorList"
import SimpleInput from "../../../../../ui/Inputs/SimpleInput"
import Text from "../../../../../ui/Typography/Text"
import notification from "../../../../../ui/notification"
import { EditBankAccountResolver } from "./EditBankAccountResolver.yup"
import { useUpdateBankingAccountMutation } from "./GraphQL/updateBankingAccount.generated"
import type { IUpdateBankAccount } from "./hookforms.interfaces"
import classnames from "classnames"
import React, { useState } from "react"
import { Controller, useForm } from "react-hook-form"
import { useIntl } from "react-intl"
import styled from "styled-components"

type EditBankAccountProps = {
  name: string
  uuid: string
}

export const EditBankAccount: React.FC<EditBankAccountProps> = ({
  name,
  uuid,
}) => {
  const intl = useIntl()
  const editContainerRef = React.useRef(null)
  const [focused, setFocused] = useState<boolean>()

  const unfocus = () => setFocused(false)
  const focus = () => setFocused(true)

  useClickOutside(editContainerRef, unfocus)

  const [updateAccount, { loading }] = useUpdateBankingAccountMutation({
    refetchQueries: [GetBankingAccountByRestaurantDocument],
  })

  const {
    handleSubmit,
    control,
    formState: { errors, isValid },
  } = useForm<IUpdateBankAccount>({
    mode: "all",
    resolver: EditBankAccountResolver,
    defaultValues: {
      name,
    },
  })

  const saveBankAccount = async ({ name: accountName }: IUpdateBankAccount) => {
    try {
      await updateAccount({ variables: { data: { uuid, name: accountName } } })

      notification({
        description: intl.formatMessage({
          id: "restaurants.bank.accounts.edit.bank.account.success",
          defaultMessage: "Your Bank Account was Updated",
        }),
        type: "success",
      })
    } catch (error) {
      showGraphqlErrors(error)
    }
  }

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    handleSubmit(saveBankAccount)()
  }

  return (
    <form onSubmit={onSubmit} role="update-bank-account-form">
      <StyledContainer
        reference={editContainerRef}
        className={classnames("edit-bank-account-container", { focused })}
        display="flex"
        flexDirection="row"
        justifyContent="flex-start"
        gap="16px"
      >
        <Controller
          control={control}
          name="name"
          render={({ field: nameField }) => {
            return (
              <Container
                display="flex"
                flexDirection="row"
                alignItems="center"
                gap="16px"
              >
                <div className={classnames("bank-name-input", { focused })}>
                  <SimpleInput
                    {...nameField}
                    aria-label="name"
                    hasError={!!errors?.name}
                    requirement="required"
                    onFocus={focus}
                    onMouseOverCapture={() => {
                      if (name !== nameField.value) {
                        nameField.onChange({ target: { value: name } })
                      }
                    }}
                    capitalizeFirstLetter={false}
                  />
                </div>

                <div className="bank-name-label">
                  <Text size="s">{name}</Text>
                </div>
              </Container>
            )
          }}
        />
        <div className="bank-name-button">
          <Button
            type="submit"
            title="Save"
            loading={loading}
            disabled={!isValid}
          />
        </div>
      </StyledContainer>
    </form>
  )
}

const StyledContainer = styled(Container)`
  &:hover,
  &.focused {
    .bank-name-label {
      display: none;
    }
    .bank-name-input {
      display: initial;
    }
    .bank-name-button {
      display: initial;
    }
  }
  .bank-name-label {
    display: initial;
  }
  .bank-name-input {
    display: none;
  }
  .bank-name-button {
    display: none;
  }
`
