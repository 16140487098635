import { SubscriptionStatusEnum } from "../../../../../shared/graphql/generated/types"
import { capitalizeFirstLetter } from "../../../../../shared/utils/helpers/capitalizeFirstLetter"
import Button from "../../../../../ui/Button"
import Icon from "../../../../../ui/Icon"
import Text from "../../../../../ui/Typography/Text"
import Title from "../../../../../ui/Typography/Title"
import { currencyFormatter } from "../plans.utils"
import { useIntl } from "react-intl"
import styled, { css } from "styled-components"

type PlanCardProps = {
  price: number
  name: string
  features: Record<string, Array<string>>
  subscriptionStatus?: SubscriptionStatusEnum
  days: number
  openGetPlanConfirmModal?: () => void
}

const PlanCard: React.FC<PlanCardProps> = (props) => {
  const {
    name,
    price,
    days,
    features,
    openGetPlanConfirmModal,
    subscriptionStatus,
  } = props
  const intl = useIntl()
  const hasActiveSubscription = !!subscriptionStatus

  return (
    <PlanContainer>
      <FreeTrialBannerContainer $userHasCurrentPlan={hasActiveSubscription}>
        {(!hasActiveSubscription ||
          subscriptionStatus === SubscriptionStatusEnum.TRIALING) && (
          <Text
            size="l"
            weight="bold"
            textAlign="center"
            color={hasActiveSubscription ? "Warning5" : "Success5"}
          >
            {intl.formatMessage(
              {
                id: "settings.restaurant.plans.free_trial_message",
                defaultMessage:
                  "{days, plural, one {# day} other {# days}} {activeSusbscription, plural, one {left in the} other {}} free trial",
              },
              { days, activeSusbscription: Number(hasActiveSubscription) }
            )}
          </Text>
        )}
      </FreeTrialBannerContainer>

      <PlanDetailsContainer>
        <div>
          <Text size="l" weight="bold" textAlign="center" color="Neutral6">
            {capitalizeFirstLetter(name)}
          </Text>
          <div style={{ textAlign: "center" }}>
            <Title size="m" weight="bold" color="Neutral8">
              {currencyFormatter.format(price)}
            </Title>
          </div>
          <Text size="s" textAlign="center" color="Neutral6">
            {intl.formatMessage({
              id: "settings.restaurant.plans.pricing_terms",
              defaultMessage: "per location / month",
            })}
          </Text>
        </div>

        <ActionButton
          size="large"
          disabled={hasActiveSubscription}
          onClick={openGetPlanConfirmModal}
        >
          {hasActiveSubscription
            ? intl.formatMessage({
                id: "settings.restaurant.plans.current_plan_button",
                defaultMessage: "Current plan",
              })
            : intl.formatMessage({
                id: "settings.restaurant.plans.get_plan_button",
                defaultMessage: "Get plan",
              })}
        </ActionButton>
      </PlanDetailsContainer>

      {Object.entries(features).map((feature, index) => (
        <div key={`${index}-${feature[0]}`}>
          <DetailsSectionHeaderContainer>
            <Text size="m" weight="bold" color="Neutral6">
              {feature[0]}
            </Text>
          </DetailsSectionHeaderContainer>

          <DetailsSectionBodyContainer>
            {feature[1].map((detail, subIndex) => (
              <Detail key={`${subIndex}-${index}`} text={detail} />
            ))}
          </DetailsSectionBodyContainer>
        </div>
      ))}
    </PlanContainer>
  )
}

export default PlanCard

interface DetailProps {
  text: string
}

const Detail: React.FC<DetailProps> = (props) => {
  const { text } = props

  return (
    <DetailContainer>
      <Text size="m" color="Neutral8">
        {text}
      </Text>
      <Icon size={24} color="Neutral6" remixiconClass="ri-check-line" />
    </DetailContainer>
  )
}

const DetailContainer = styled.div`
  gap: 8px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const PlanContainer = styled.div`
  ${({ theme }) => css`
    width: 100%;
    max-width: 744px;
    border: 1px solid ${theme.colors.Neutral4};
    background-color: ${theme.colors.Neutral0};
  `}
`

interface FreeTrialBannerContainerProps {
  $userHasCurrentPlan: boolean
}

const FreeTrialBannerContainer = styled.div<FreeTrialBannerContainerProps>`
  ${({ theme, $userHasCurrentPlan }) => css`
    width: 100%;
    padding: 0 16px;
    background-color: ${$userHasCurrentPlan
      ? theme.colors.Warning1
      : theme.colors.Success1};
  `}
`

const PlanDetailsContainer = styled.div`
  gap: 24px;
  display: flex;
  flex-direction: column;
  padding: 16px;
`

const ActionButton = styled(Button)`
  width: 100%;
  max-width: 300px;
  align-self: center;
`

const DetailsSectionHeaderContainer = styled.div`
  ${({ theme }) => css`
    width: 100%;
    padding: 0 16px;
    background-color: ${theme.colors.Neutral2};
  `}
`

const DetailsSectionBodyContainer = styled.div`
  width: 100%;
  padding: 16px;
  gap: 8px;
  display: flex;
  flex-direction: column;
`
