import { Skeleton } from "antd"
import range from "lodash/range"
import React from "react"
import styled from "styled-components"

export const DropDownMenuSkeleton: React.FC = () => {
  return (
    <StyledDropDownMenuSkeleton
      className="dropdown-menu-skeleton"
      role="dropdown-menu-skeleton"
    >
      {range(5).map((key) => (
        <Skeleton key={key} paragraph={false} active />
      ))}
    </StyledDropDownMenuSkeleton>
  )
}

const StyledDropDownMenuSkeleton = styled.div`
  &.dropdown-menu-skeleton {
    margin: 0 16px;
    .ant-skeleton-title {
      margin-top: 0;
      height: 24px;
      margin-right: 16px;
    }
  }
`
