/* eslint-disable unicorn/no-thenable */
import { TipValueTypeEnum } from "../../../../../../shared/graphql/generated/types"
import yup from "../../../../../../shared/yup"
import { yupResolver } from "@hookform/resolvers/yup"

const OrderMethodSettingsSchema = yup.object().shape({
  tipsData: yup.object().when("tipSettings.type", {
    is: TipValueTypeEnum.AMOUNTS,
    then: yup.object().shape({
      AMOUNTS: yup.object().shape({ default: yup.string().required() }),
    }),
    otherwise: yup.object().shape({
      PERCENTAGE: yup.object().shape({ default: yup.string().required() }),
    }),
  }),
})

export const OrderMethodSettingsResolver = yupResolver(
  OrderMethodSettingsSchema
)
