import { GetBankingAccountByRestaurantDocument } from "../../../../../GraphQL/Queries/getBankingAccountByRestaurant.generated"
import Button from "../../../../../ui/Button"
import Container from "../../../../../ui/Container"
import { showGraphqlErrors } from "../../../../../ui/ErrorList"
import type { IModalProps } from "../../../../../ui/Modal"
import Modal from "../../../../../ui/Modal"
import notification from "../../../../../ui/notification"
import AccountDebitCardForm from "../AccountDebitCardForm"
import { AccountDebitCardResolver } from "../AccountDebitCardForm/AccountDebitCardResolver.yup"
import type {
  IAccountOrDebitCard,
  IDebitCard,
  IGenericStripeToken,
} from "../AccountDebitCardForm/hookforms.interfaces"
import { TokenTypeEnum } from "../AccountDebitCardForm/hookforms.interfaces"
import { useCreateExternalAccountMutation } from "./GraphQL/createExternalAccount.generated"
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js"
import React from "react"
import { FormProvider, useForm } from "react-hook-form"
import { useIntl } from "react-intl"

type AddAccountOrDebitCardProps = Omit<IModalProps, "onCancel"> & {
  onCancel: () => void
  uuid: string
}

export const AddAccountOrDebitCard: React.FC<AddAccountOrDebitCardProps> = ({
  uuid,
  onCancel,
  ...props
}) => {
  const intl = useIntl()
  const stripe = useStripe()
  const elements = useElements()

  const [createExternalAccount, { loading }] = useCreateExternalAccountMutation(
    {
      refetchQueries: [GetBankingAccountByRestaurantDocument],
    }
  )

  const formMethods = useForm<IAccountOrDebitCard>({
    mode: "all",
    resolver: AccountDebitCardResolver,
    defaultValues: {
      type: TokenTypeEnum.BANK_ACCOUNT,
      country: "US",
      currency: "usd",
      account_holder_type: "individual",
    },
  })

  const {
    handleSubmit,
    reset,
    watch,
    formState: { isValid, dirtyFields },
  } = formMethods

  const typeWatcher = watch("type")

  const onSubmitCardElement = async (data: IGenericStripeToken) => {
    try {
      if (!stripe || !elements) {
        return
      }

      const cardElement = elements.getElement(CardElement)

      if (cardElement) {
        const result = await stripe.createToken(cardElement, data)

        if (result.error) {
          return showGraphqlErrors(result.error)
        }

        if (result.token.id) {
          await createExternalAccount({
            variables: {
              data: { bankingAccountUUID: uuid, token: result.token.id },
            },
          })

          notification({
            description: intl.formatMessage({
              id: "restaurants.bank.accounts.token.success",
              defaultMessage: "Your operation was successful",
            }),
            type: "success",
          })
          onCancel()
        }
      }
    } catch (error) {
      showGraphqlErrors(error)
    }
  }

  const onSubmitAccount = async (data: IDebitCard) => {
    try {
      if (!stripe) {
        return
      }
      const result = await stripe.createToken("bank_account", data)

      if (result.error) {
        return showGraphqlErrors(result.error)
      }

      if (result.token.id) {
        await createExternalAccount({
          variables: {
            data: { bankingAccountUUID: uuid, token: result.token.id },
          },
        })

        notification({
          type: "success",
          description: intl.formatMessage({
            id: "restaurants.bank.accounts.token.success",
            defaultMessage: "Your operation was successful",
          }),
        })
        onCancel()
      }
    } catch (error) {
      showGraphqlErrors(error)
    }
  }

  const onSubmit = ({ type, ...data }: IAccountOrDebitCard) => {
    if (type === "bank_account") {
      onSubmitAccount(data)
    } else {
      onSubmitCardElement({ currency: data.currency, country: data.country })
    }
  }

  const onSubmitForm = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    handleSubmit(onSubmit)(event)
  }

  return (
    <FormProvider {...formMethods}>
      <Modal
        {...props}
        onCancel={onCancel}
        shouldCloseOnClickOutside={Object.keys(dirtyFields).length === 0}
        closable={false}
        footer={
          <Container display="flex" justifyContent="flex-end" gap="8px">
            <Button
              title={intl.formatMessage({
                id: "components.modal.cancel.button",
                defaultMessage: "Cancel",
              })}
              hierarchy="secondary"
              onClick={onCancel}
            />
            <Button
              disabled={!isValid}
              loading={loading}
              title={intl.formatMessage({
                id: "restaurants.bank.accounts.token.save.card.button",
                defaultMessage: "Save Debit Card",
              })}
              onClick={handleSubmit(onSubmitCardElement)}
              hidden={typeWatcher === "bank_account"}
            />

            <Button
              disabled={!isValid}
              loading={loading}
              title={intl.formatMessage({
                id: "restaurants.bank.accounts.token.save.bank.button",
                defaultMessage: "Save Bank Account",
              })}
              onClick={handleSubmit(onSubmitAccount)}
              hidden={typeWatcher === "card"}
            />
          </Container>
        }
        title={intl.formatMessage({
          id: "restaurants.bank.accounts.token.title",
          defaultMessage: "Add a Bank Account or Debit Card",
        })}
        afterClose={reset}
        destroyOnClose
      >
        <AccountDebitCardForm onSubmit={onSubmitForm} />
      </Modal>
    </FormProvider>
  )
}
