import paths from "../../../../../../shared/routes/paths"
import Text from "../../../../../../ui/Typography/Text"
import { Menu } from "antd"
import { useIntl } from "react-intl"
import { useHistory } from "react-router-dom"

const {
  settings: { restaurant },
} = paths

export const TaxCategories = () => {
  const intl = useIntl()
  const history = useHistory()

  const createSalesTax = () => history.push(restaurant.createSalesTax)
  const createItemTax = () => history.push(restaurant.createItemTax)

  return (
    <Menu role="menu-settings">
      <Menu.Item key="dropdown-create-sales-tax" onClick={createSalesTax}>
        <Text size="s">
          {intl.formatMessage({
            id: "restaurants.taxes.categories.sales",
            defaultMessage: "Sales Taxes",
          })}
        </Text>
      </Menu.Item>
      <Menu.Item key="dropdown-create-item-tax" onClick={createItemTax}>
        <Text size="s">
          {intl.formatMessage({
            id: "restaurants.taxes.categories.item",
            defaultMessage: "Item Taxes",
          })}
        </Text>
      </Menu.Item>
    </Menu>
  )
}
