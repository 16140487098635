import { CacheUpdateFunction } from "../../../Employees/types/graphql.enums"
import {
  UpdateLocationMutation,
  UpdateLocationMutationVariables,
} from "../../../GraphQL/updateLocation.generated"
import {
  GetCateringLocationDocument,
  GetCateringLocationQuery,
  GetCateringLocationQueryVariables,
} from "../../Graphql/getCateringLocation.generated"

export const useGetSingleLocationCacheUpdate = () => {
  const cateringActivateSingleLocationCacheUpdate: CacheUpdateFunction<
    UpdateLocationMutation,
    UpdateLocationMutationVariables
  > = (cache, result) => {
    if (result.data) {
      const locationUpdateCateringActive = result.data.updateLocation

      cache.updateQuery<
        GetCateringLocationQuery,
        GetCateringLocationQueryVariables
      >(
        {
          query: GetCateringLocationDocument,
          variables: {
            locationUUID: locationUpdateCateringActive.uuid,
          },
        },
        (prevData) => {
          if (prevData) {
            return {
              getSingleLocation: {
                ...prevData?.getSingleLocation,
                orderTypes: locationUpdateCateringActive.orderTypes,
                isCateringActive: locationUpdateCateringActive.isCateringActive,
              },
            }
          }
        }
      )
    }
  }

  return {
    cateringActivateSingleLocationCacheUpdate,
  }
}
