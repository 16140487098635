import { useGetAllLocationsByUserQuery } from "../../../../../GraphQL/Queries/getAllLocationsByUser.generated"
import { useGeneralContext } from "../../../../../shared/contexts/StoreProvider"
import type { QRConfig } from "../../../../../shared/hooks/useQRStyleConfig"
import { useQRStyleConfig } from "../../../../../shared/hooks/useQRStyleConfig"
import paths from "../../../../../shared/routes/paths"
import Button from "../../../../../ui/Button"
import Container from "../../../../../ui/Container"
import { showGraphqlErrors } from "../../../../../ui/ErrorList"
import type { IModalProps } from "../../../../../ui/Modal"
import Modal from "../../../../../ui/Modal"
import RoleView from "../../../../../ui/RoleView"
import { useGetQrConfigRestaurantLazyQuery } from "../GraphQL/getQrConfigRestaurant.generated"
import type { ITablesPageParams } from "../TableForm/hookforms.interfaces"
import TableSticker from "../TableSticker"
import get from "lodash/get"
import React, { useCallback, useEffect, useState } from "react"
import { useIntl } from "react-intl"
import { useHistory, useParams } from "react-router-dom"
import { useReactToPrint } from "react-to-print"
import styled, { css } from "styled-components"

type DisplayQRModalProps<T> = IModalProps & {
  tableRowData?: T
}

export const DisplayQRModal = <T,>(props: DisplayQRModalProps<T>) => {
  const { tableRowData } = props
  const { locationUUID } = useParams<ITablesPageParams>()

  const intl = useIntl()
  const { push } = useHistory()

  const code = React.createRef<HTMLDivElement>()
  const componentRef = React.useRef(null)
  const initialRender = React.useRef(true)

  const [printing, setPrinting] = useState<boolean>()

  const reactToPrintContent = React.useCallback(() => {
    return componentRef.current
  }, [])

  const onPrint = useReactToPrint({
    content: reactToPrintContent,
    documentTitle: intl.formatMessage(
      {
        id: "restaurants.location.table.qr.modal.print.title",
        defaultMessage: "QR Codes For Table: {tableName}",
      },
      {
        tableName: get(tableRowData, "name"),
      }
    ),
    onBeforeGetContent: () => setPrinting(true),
    onAfterPrint: () => setPrinting(false),
    onPrintError: () => setPrinting(false),
    removeAfterPrint: true,
  })

  const { config, setConfig } = useQRStyleConfig()

  const {
    state: {
      currentRestaurant: { uuid: restaurantUUID },
      auth: {
        admin: { uuid: userUUID },
      },
    },
  } = useGeneralContext()

  const { data, loading } = useGetAllLocationsByUserQuery({
    variables: { restaurantUUID, locationUUID, userUUID },
    skip: !restaurantUUID || !props.visible,
    onError: showGraphqlErrors,
  })

  const locationName = get(data, " getAllLocationsByUser.results.0.name")

  const [getQr] = useGetQrConfigRestaurantLazyQuery({
    fetchPolicy: "no-cache",
    onError: showGraphqlErrors,
    onCompleted: ({ getQrConfigRestaurant }) => {
      if (getQrConfigRestaurant.qrConfig) {
        try {
          const qrConfig: QRConfig = JSON.parse(getQrConfigRestaurant.qrConfig)
          setConfig(qrConfig)
        } catch (error) {
          showGraphqlErrors(error)
        }
      }
    },
  })

  const getQRConfig = useCallback(() => {
    try {
      getQr({ variables: { restaurantUUID, locationUUID } })
    } catch (error) {
      showGraphqlErrors(error)
    }
  }, [getQr, locationUUID, restaurantUUID])

  useEffect(() => {
    if (!props.visible) {
      return
    }

    if (initialRender.current) {
      getQRConfig()
      initialRender.current = false
    } else {
      code.current?.children[0] && code.current?.children[0].remove()
    }
  }, [code, props.visible, tableRowData, config, getQRConfig])

  const clearQRInstance = () => {
    initialRender.current = true
  }

  const goToCustomizeQRCode = () => {
    push(paths.settings.restaurant.qrCode)
  }

  return (
    <StyledModal
      {...props}
      afterClose={clearQRInstance}
      footer={
        <Container display="flex" width="100%">
          <Container display="flex" width="100%" justifyContent="flex-start">
            <RoleView allowed={["RESTAURANT_OWNER", "RESTAURANT_ADMIN"]}>
              <Button
                title={intl.formatMessage({
                  id: "restaurants.location.tables.qr.modal.customize.link",
                  defaultMessage: "Customize QR Code",
                })}
                hierarchy="tertiary"
                onClick={goToCustomizeQRCode}
              />
            </RoleView>
          </Container>

          <Container
            display="flex"
            width="100%"
            justifyContent="flex-end"
            gap="8px"
          >
            <Button
              title={intl.formatMessage({
                id: "components.modal.cancel.button",
                defaultMessage: "Cancel",
              })}
              hierarchy="secondary"
              onClick={props.onCancel}
            />
            <Button
              loading={printing}
              title={intl.formatMessage({
                id: "restaurants.location.tables.qr.modal.print.button",
                defaultMessage: "Print",
              })}
              onClick={onPrint}
            />
          </Container>
        </Container>
      }
    >
      <Container
        role="display-qr-modal-content"
        display="flex"
        justifyContent="center"
      >
        <div ref={componentRef}>
          <TableSticker
            key={get(tableRowData, "uuid")}
            config={config}
            tableName={get(tableRowData, "name")}
            loading={loading}
            locationName={locationName}
            uuid={get(tableRowData, "uuid")}
          />
        </div>
      </Container>
    </StyledModal>
  )
}

const StyledModal = styled(Modal)`
  ${({ theme }) =>
    css`
      .ant-modal-body {
        background-color: ${theme.colors["Neutral3"]};
      }
    `}
`
