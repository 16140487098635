import { useFilters } from "../../../../shared/contexts/FilterProvider"
import { YYYY_MM_DD } from "../../../../shared/utils/constant/dateFormats"
import DatePicker from "../../../DatePicker"
import type { Moment } from "moment"
import moment from "moment-timezone"
import type { RangeValue } from "rc-picker/lib/interface"
import { useIntl } from "react-intl"

export const FilterByDate = () => {
  const intl = useIntl()

  const { updateDateFilters, dates } = useFilters()

  const values: RangeValue<moment.Moment> =
    !!dates["start-date"] && !!dates["end-date"]
      ? [moment(dates["start-date"]), moment(dates["end-date"])]
      : [moment(), moment()]

  const onDateChange = (changedDates: RangeValue<Moment>) => {
    const startDate = changedDates
      ? moment(changedDates[0]?.toISOString()).format(YYYY_MM_DD)
      : undefined
    const endDate = changedDates
      ? moment(changedDates[1]?.toISOString()).format(YYYY_MM_DD)
      : undefined

    updateDateFilters({ "start-date": startDate, "end-date": endDate })
  }

  return (
    <DatePicker
      picker="date"
      startDatePlaceHolder={intl.formatMessage({
        id: "components.date.picker.start.date.placeholder.title.this.week",
        defaultMessage: "Start date",
      })}
      onChange={onDateChange}
      defaultValue={[moment(), moment().endOf("day")]}
      value={values}
    />
  )
}
