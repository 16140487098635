import type { DeliveryIntegrationWithLocationsModel } from "../../../../../shared/graphql/generated/types"
import { DeliveryIntegrationEnum } from "../../../../../shared/graphql/generated/types"
import { INVALID_PROVIDER_API_KEY } from "../../../../../shared/utils/constant/errors"
import type { GraphqlMutationError } from "../../../../../shared/utils/helpers/parseGraphqlErrorMessages"
import Button from "../../../../../ui/Button"
import Container from "../../../../../ui/Container"
import { showGraphqlErrors } from "../../../../../ui/ErrorList"
import type { IModalProps } from "../../../../../ui/Modal"
import Modal from "../../../../../ui/Modal"
import notification from "../../../../../ui/notification"
import DeliveryIntegrationForm from "../DeliveryIntegrationForm"
import { DeliveryIntegrationResolver } from "../DeliveryIntegrationForm/DeliveryIntegrationResolver.yup"
import { useCreateDeliveryIntegrationMutation } from "../GraphQL/createDeliveryIntegration.generated"
import type { CreateDeliveryIntegrationForm } from "../interfaces/hookforms.interface"
import React from "react"
import { FormProvider, useForm } from "react-hook-form"
import { useIntl } from "react-intl"

type CreateBankAccountProps = Omit<IModalProps, "onCancel"> & {
  onCancel: () => void
  defaultValues?: Record<string, unknown>
}

export const CreateDeliveryIntegration: React.FC<CreateBankAccountProps> = ({
  defaultValues,
  onCancel,
  ...props
}) => {
  const intl = useIntl()

  const methods = useForm<CreateDeliveryIntegrationForm>({
    mode: "all",
    resolver: DeliveryIntegrationResolver,
    defaultValues,
  })

  const {
    handleSubmit,
    reset,
    setError,
    formState: { isValid, dirtyFields },
  } = methods

  const [createDeliveryIntegration, { loading }] =
    useCreateDeliveryIntegrationMutation({
      update: (cache, result) => {
        const data = result.data?.createDeliveryIntegration

        if (data) {
          const newValue: DeliveryIntegrationWithLocationsModel = {
            uuid: data.uuid,
            apiKey: data.apiKey,
            integrationLabel: data.integrationLabel,
            integrationName: data.integrationName,
            isActive: data.isActive,
            issues: data.issues,
            relatedLocations: [],
          }

          cache.modify({
            fields: {
              getDeliveryIntegrationsByRestaurant: (prev) => {
                return [{ ...newValue }, ...prev]
              },
            },
          })
        }
      },
    })

  const saveDeliveryIntegration = async (
    formData: CreateDeliveryIntegrationForm
  ) => {
    try {
      const { data } = await createDeliveryIntegration({
        variables: {
          data: {
            apiKey: formData.apiKey,
            integrationLabel: formData.integrationLabel,
            integrationName: formData.integrationName,
            ...(formData.integrationName ===
              DeliveryIntegrationEnum.DOORDASH && {
              signingSecret: formData.signingSecret,
              developerId: formData.developerId,
            }),
          },
        },
      })

      const deliveryIntegrationUUID = data?.createDeliveryIntegration.uuid

      if (deliveryIntegrationUUID) {
        notification({
          description: intl.formatMessage({
            id: "settings.restaurant.delivery.integration.add.delivery.integration.modal.success.message",
            defaultMessage: "Your delivery integration was created",
          }),
          type: "success",
        })
        reset()
        onCancel()
      }
    } catch (error) {
      const { message } = error as GraphqlMutationError

      if (message === INVALID_PROVIDER_API_KEY) {
        setError("apiKey", {
          type: "manual",
          message: intl.formatMessage({
            id: "settings.restaurant.delivery.integration.add.delivery.integration.form.api.key.error.message",
            defaultMessage: "Please enter a valid API Key",
          }),
        })
      } else {
        showGraphqlErrors(error)
      }
    }
  }

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    handleSubmit(saveDeliveryIntegration)()
  }

  return (
    <FormProvider {...methods}>
      <Modal
        {...props}
        onCancel={onCancel}
        shouldCloseOnClickOutside={Object.keys(dirtyFields).length === 0}
        footer={
          <Container display="flex" justifyContent="flex-end" gap="8px">
            <Button
              disabled={loading}
              title={intl.formatMessage({
                id: "components.modal.cancel.button",
                defaultMessage: "Cancel",
              })}
              hierarchy="secondary"
              onClick={onCancel}
            />
            <Button
              disabled={!isValid}
              loading={loading}
              title={intl.formatMessage({
                id: "settings.restaurant.delivery.integration.add.delivery.integration.modal.save.button",
                defaultMessage: "Connect",
              })}
              onClick={handleSubmit(saveDeliveryIntegration)}
              type="submit"
            />
          </Container>
        }
        title={intl.formatMessage({
          id: "settings.restaurant.delivery.integration.add.delivery.integration.modal.title",
          defaultMessage: "Connect Delivery Integration",
        })}
        afterClose={reset}
        destroyOnClose
      >
        <DeliveryIntegrationForm onSubmit={onSubmit} />
      </Modal>
    </FormProvider>
  )
}
