import Icon from "../Icon"
import Tooltip from "../Tooltip"
import Text from "../Typography/Text"
import classnames from "classnames"
import React from "react"
import styled, { css } from "styled-components"

export type InputLabelProps = {
  label?: React.ReactNode | string
  labelClassName?: string
  tooltipTitle?: string
  requirement?: "optional" | "required"
  size?: "middle" | "large"
}

export const InputLabel: React.FC<InputLabelProps> = ({
  label,
  labelClassName,
  requirement,
  tooltipTitle,
  size,
}) => {
  const letterSize = size === "large" ? "l" : "m"

  return (
    <StyledInputLabelWrapper
      className="input-label-wrapper"
      requirement={requirement}
    >
      {typeof label === "string" ? (
        <Text
          className={classnames("label", labelClassName)}
          size={letterSize}
          color="Neutral8"
        >
          {label}
        </Text>
      ) : (
        label
      )}

      {tooltipTitle && (
        <Tooltip title={tooltipTitle}>
          <Icon
            classes="tooltip__icon"
            size={16}
            remixiconClass="ri-information-line"
            cursor="pointer"
            color="Neutral5"
          />
        </Tooltip>
      )}

      {requirement === "optional" && (
        <Text className="optional" size={letterSize} weight="regular">
          (optional)
        </Text>
      )}
    </StyledInputLabelWrapper>
  )
}

type StyledInputLabelWrapperProps = {
  requirement?: "optional" | "required"
}

const StyledInputLabelWrapper = styled.div<StyledInputLabelWrapperProps>`
  ${({ theme, requirement }) => css`
    display: flex;
    align-items: center;
    flex-direction: row;
    padding-bottom: 4px;
    margin-bottom: 4px;

    & .ant-typography {
      color: ${theme.colors["Neutral8"]};
      opacity: 1;
    }

    ${requirement === "required" &&
    css`
      &::before {
        display: inline-block;
        margin-right: 4px;
        color: ${theme.colors["Danger5"]};
        font-size: ${theme.text.body.s.regular.fontSize};
        line-height: 22px;
        content: "*";
      }
    `}

    span .label {
      margin-bottom: 8px;
    }

    .tooltip__icon {
      margin-left: 4px;
    }

    span.optional {
      color: ${theme.colors["Neutral5"]};
      margin-left: 4px;
    }

    .ant-btn {
      padding: 0;
      margin-left: 4px;
      background-color: ${theme.colors.transparent};
      border: 0;
      height: 22px;
      box-shadow: none;

      &:hover,
      &:focus {
        color: ${theme.colors["Primary5"]};
        background-color: ${theme.colors.transparent};
      }
    }
  `}
`
