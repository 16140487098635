import AddIcon from "../../../../../ui/AddIcon"
import Container from "../../../../../ui/Container"
import CreateBankAccount from "../CreateBankAccount"
import { useState } from "react"

export const AddBankAccountButton = () => {
  const [displayForm, setDisplayForm] = useState<boolean>(false)

  const onClose = () => {
    setDisplayForm(false)
  }

  const showModal = () => {
    setDisplayForm(true)
  }

  return (
    <Container display="flex" alignItems="center">
      <AddIcon onClick={showModal} />
      <CreateBankAccount
        visible={displayForm}
        onCancel={onClose}
        closable={false}
      />
    </Container>
  )
}
