import Container from "../../../../../ui/Container"
import { InputFieldSkeleton } from "../../../../../ui/Inputs"
import React from "react"

type DisplayQRModalSkeletonProps = {
  loading?: boolean
}

export const DisplayQRModalSkeleton: React.FC<DisplayQRModalSkeletonProps> = ({
  loading,
  children,
}) => {
  return loading ? (
    <div>
      <Container display="flex" centered>
        <InputFieldSkeleton width="150px" marginBottom="8px" />
      </Container>
      <InputFieldSkeleton width="300px" marginBottom="4px" />
      <InputFieldSkeleton width="100px" />
    </div>
  ) : (
    <>{children}</>
  )
}
