import Container from "../../Container"
import Wrapper from "../../Field/Wrapper"
import Icon from "../../Icon"
import InputLabel from "../../InputLabel"
import Spacer from "../../Spacer"
import InputHelper from "../InputHelper"
import { getInputTextSizes } from "../SimpleInput"
import { Input } from "antd"
import type { TextAreaProps as AntTextAreaProps } from "antd/lib/input"
import classnames from "classnames"
import React, { useState } from "react"
import styled, { css } from "styled-components"

const { TextArea: AntTextArea } = Input

export type TextAreaProps = Omit<AntTextAreaProps, "size" | "rows"> & {
  className?: string
  label?: string
  labelClassName?: string
  tooltipTitle?: string
  helperText?: string
  wrapHelperText?: boolean
  hasError?: boolean
  requirement?: "optional" | "required"
  inputWrapperClassName?: string
  commentCharactersLimit?: number
  width?: string
  size?: "middle" | "large"
}

const initialCounter = 0

const FancyTextArea = (
  props: TextAreaProps,
  ref:
    | ((instance: HTMLElement | null) => void)
    | React.RefObject<HTMLElement>
    | null
    | undefined
) => {
  const {
    value,
    className,
    requirement,
    hasError,
    helperText,
    label,
    labelClassName,
    tooltipTitle,
    disabled,
    wrapHelperText,
    size = "middle",
    inputWrapperClassName,
    commentCharactersLimit = initialCounter,
    width,
    ...rest
  } = props

  const [countDown, setCountDown] = useState<number>(
    value ? value?.toString().length : initialCounter
  )

  const onKeyUpInput = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.currentTarget.textContent) {
      setCountDown(event.currentTarget.textContent.length)
    } else {
      setCountDown(initialCounter)
    }
  }

  return (
    <Wrapper className={inputWrapperClassName}>
      {label && (
        <InputLabel
          label={label}
          labelClassName={labelClassName}
          requirement={requirement}
          tooltipTitle={tooltipTitle}
          size={size}
        />
      )}

      <StyledFieldWrapper className={classnames("input-wrapper", className)}>
        <Container position="relative">
          <StyledTextArea
            {...rest}
            value={value}
            ref={ref}
            disabled={disabled}
            $hasError={hasError}
            onKeyUp={onKeyUpInput}
            rows={size === "large" ? 5 : 4}
            width={width}
            maxLength={commentCharactersLimit ?? 0}
          />
          {hasError && (
            <Icon
              size={16}
              classes="error__icon"
              remixiconClass="ri-error-warning-line"
              color="Danger5"
            />
          )}
        </Container>

        <Spacer size={4} />

        <InputHelper
          className="input-helper"
          $hasError={hasError}
          wrap={wrapHelperText}
          countDown={countDown}
          commentCharactersLimit={commentCharactersLimit}
        >
          {helperText}
        </InputHelper>
      </StyledFieldWrapper>
    </Wrapper>
  )
}

export const TextArea = React.forwardRef(FancyTextArea)

type StyledTextAreaProps = AntTextAreaProps & {
  $hasError?: boolean
  size?: "middle" | "large"
  width?: string
}

const StyledTextArea = styled(AntTextArea)<StyledTextAreaProps>`
  ${({ disabled, theme, size, $hasError, width }) =>
    css`
      border-radius: 4px;
      padding: 4px 12px;
      font-size: ${getInputTextSizes(size)};
      display: flex;
      flex-flow: row nowrap;
      justify-content: left;
      align-items: center;
      background-color: ${theme.colors.Neutral1};
      color: ${theme.colors.Neutral8};
      border: 1px solid ${theme.colors.Neutral4};
      box-shadow: none;
      resize: none;

      &:hover {
        border: 1px solid ${theme.colors.Primary5};
        box-shadow: none;
      }

      &:focus,
      .ant-input:focus,
      .ant-input-focused {
        border: 2px solid ${theme.colors.Primary5};
        border-right-width: 2px !important;
        box-shadow: none;
      }

      ${width &&
      css`
        width: ${width};
      `}

      ${$hasError &&
      css`
        border: 1px solid ${theme.colors.Danger5};

        &:hover,
        &:active,
        &:focus,
        &:focus-within {
          border-color: ${theme.colors.Danger5};
          box-shadow: none;
        }
      `}
        

      ${disabled &&
      css`
        background-color: ${theme.colors.Neutral3};
        border: 1px solid ${theme.colors.Neutral4};
        color: ${theme.colors.Neutral5};
      `}
    `}
`

const StyledFieldWrapper = styled(Wrapper)`
  &.input-wrapper {
    display: block;
    position: relative;
  }

  .error__icon {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    right: 12px;
  }
`
