import { OrderMethodEnum } from "../../graphql/generated/types"

export const CustomOrderMethodTitle: Record<
  OrderMethodEnum,
  { id: string; defaultMessage: string }
> = {
  CURBSIDE: {
    id: "restaurants.orders.order.method.curbside",
    defaultMessage: "Curbside",
  },
  DELIVERY: {
    id: "restaurants.orders.order.method.delivery",
    defaultMessage: "Delivery",
  },
  DINE_IN: {
    id: "restaurants.orders.order.method.dine.in",
    defaultMessage: "Dine In",
  },
  DRIVE_THRU: {
    id: "restaurants.orders.order.method.drive.thru",
    defaultMessage: "Drive Thru",
  },
  TAKE_OUT: {
    id: "restaurants.orders.order.method.take.out",
    defaultMessage: "ToGo",
  },
  CATERING: {
    id: "restaurants.orders.order.method.catering",
    defaultMessage: "Catering",
  },
}
