import * as Types from '../../../../../../shared/graphql/generated/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateBankingAccountMutationVariables = Types.Exact<{
  data: Types.UpdateBankingAccountInput;
}>;


export type UpdateBankingAccountMutation = { __typename?: 'Mutation', updateBankingAccount: { __typename?: 'BankingAccountModel', uuid: string, isActive: boolean, name: string, stIsConnected: boolean } };


export const UpdateBankingAccountDocument = gql`
    mutation updateBankingAccount($data: UpdateBankingAccountInput!) {
  updateBankingAccount(data: $data) {
    uuid
    isActive
    name
    stIsConnected
  }
}
    `;
export type UpdateBankingAccountMutationFn = Apollo.MutationFunction<UpdateBankingAccountMutation, UpdateBankingAccountMutationVariables>;

/**
 * __useUpdateBankingAccountMutation__
 *
 * To run a mutation, you first call `useUpdateBankingAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBankingAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBankingAccountMutation, { data, loading, error }] = useUpdateBankingAccountMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateBankingAccountMutation(baseOptions?: Apollo.MutationHookOptions<UpdateBankingAccountMutation, UpdateBankingAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateBankingAccountMutation, UpdateBankingAccountMutationVariables>(UpdateBankingAccountDocument, options);
      }
export type UpdateBankingAccountMutationHookResult = ReturnType<typeof useUpdateBankingAccountMutation>;
export type UpdateBankingAccountMutationResult = Apollo.MutationResult<UpdateBankingAccountMutation>;
export type UpdateBankingAccountMutationOptions = Apollo.BaseMutationOptions<UpdateBankingAccountMutation, UpdateBankingAccountMutationVariables>;