import * as Types from '../../../../../shared/graphql/generated/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AddQrConfigToRestaurantMutationVariables = Types.Exact<{
  data: Types.AddQrConfigToRestaurantInput;
}>;


export type AddQrConfigToRestaurantMutation = { __typename?: 'Mutation', addQrConfigToRestaurant: { __typename?: 'RestaurantQrConfigModel', uuid: string, qrConfig?: any | null } };


export const AddQrConfigToRestaurantDocument = gql`
    mutation addQrConfigToRestaurant($data: AddQrConfigToRestaurantInput!) {
  addQrConfigToRestaurant(data: $data) {
    uuid
    qrConfig
  }
}
    `;
export type AddQrConfigToRestaurantMutationFn = Apollo.MutationFunction<AddQrConfigToRestaurantMutation, AddQrConfigToRestaurantMutationVariables>;

/**
 * __useAddQrConfigToRestaurantMutation__
 *
 * To run a mutation, you first call `useAddQrConfigToRestaurantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddQrConfigToRestaurantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addQrConfigToRestaurantMutation, { data, loading, error }] = useAddQrConfigToRestaurantMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAddQrConfigToRestaurantMutation(baseOptions?: Apollo.MutationHookOptions<AddQrConfigToRestaurantMutation, AddQrConfigToRestaurantMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddQrConfigToRestaurantMutation, AddQrConfigToRestaurantMutationVariables>(AddQrConfigToRestaurantDocument, options);
      }
export type AddQrConfigToRestaurantMutationHookResult = ReturnType<typeof useAddQrConfigToRestaurantMutation>;
export type AddQrConfigToRestaurantMutationResult = Apollo.MutationResult<AddQrConfigToRestaurantMutation>;
export type AddQrConfigToRestaurantMutationOptions = Apollo.BaseMutationOptions<AddQrConfigToRestaurantMutation, AddQrConfigToRestaurantMutationVariables>;