import Tag from "../Tag"
import { List, Popover } from "antd"
import Item from "antd/lib/list/Item"
import { LabelValueType } from "rc-select/lib/interface/generator"
import styled, { css } from "styled-components"

export const MaxTag = (options: LabelValueType[], disabled: boolean) => {
  if (!options) return null

  const title = `+ ${options.length} ...`

  return (
    <Popover
      overlayClassName="popover-custom"
      trigger="hover"
      placement="bottomLeft"
      content={
        <StyledList size="small">
          {options.map((option) => (
            <StyledItem key={option.key}>{option.label}</StyledItem>
          ))}
        </StyledList>
      }
    >
      <Tag type={disabled ? "secondary" : "primary"} size="middle">
        {title}
      </Tag>
    </Popover>
  )
}

const StyledList = styled(List)`
  ${({ theme }) => css`
    width: 260px;
    overflow: auto;
    max-height: 400px;
    border-radius: 4px;
    padding: 0px 10px;
    background: ${theme.colors["white"]};
    font-family: ${theme.text.fonts.dmSansRegular};
    font-size: ${theme.text.body.m.regular.fontSize}px;
    font-weight: ${theme.text.body.m.regular.fontWeight}px;
    line-height: ${theme.text.body.m.regular.lineHeight}px;
    letter-spacing: ${theme.text.body.m.regular.letterSpacing}px;
  `};
`

const StyledItem = styled(Item)`
  padding: 8px 12px;
  overflow-wrap: anywhere;

  .ant-list-split &.ant-list-item {
    border-bottom: 0px;
  }
`
