import ConfirmationModal from "../../../../../ui/ConfirmationModal"
import Container from "../../../../../ui/Container"
import { showGraphqlErrors } from "../../../../../ui/ErrorList"
import DropdownAction from "../../../../../ui/Header/DropdownAction"
import Icon from "../../../../../ui/Icon"
import Text from "../../../../../ui/Typography/Text"
import notification from "../../../../../ui/notification"
import EditTable from "../EditTable"
import { useDisableLocationTableMutation } from "../GraphQL/disableLocationTable.generated"
import { GetAllTablesByLocationDocument } from "../GraphQL/getAllTablesByLocation.generated"
import type { ILocationTableZoneModel } from "../TableForm/hookforms.interfaces"
import { Menu } from "antd"
import get from "lodash/get"
import React, { useState } from "react"
import type { Dispatch, SetStateAction } from "react"
import { useIntl } from "react-intl"

type DropdownTablesProps = {
  record: ILocationTableZoneModel
  deletedTable?: Dispatch<SetStateAction<string | undefined>>
}

export const DropdownTables: React.FC<DropdownTablesProps> = ({
  record,
  deletedTable,
}) => {
  const intl = useIntl()
  const [isEditTableModalShown, setIsEditTableModalShown] =
    useState<boolean>(false)
  const [isDeleteTableModalShown, setIsDeleteTableModalShown] =
    useState<boolean>(false)

  const [disableTable, { loading: deleting }] = useDisableLocationTableMutation(
    {
      refetchQueries: [GetAllTablesByLocationDocument],
    }
  )

  const onToggleEditTableModal = () => {
    setIsEditTableModalShown((prev) => {
      return !prev
    })
  }

  const onToggleDeleteTableConfirmationModal = () => {
    setIsDeleteTableModalShown((prev) => {
      return !prev
    })
  }

  const onConfirmDelete = async () => {
    try {
      if (!record.uuid) {
        return
      }

      if (record.location?.uuid) {
        const deleteTable = await disableTable({
          variables: {
            data: {
              uuid: record.uuid,
              locationUUID: record.location.uuid,
            },
          },
        })
        const tableUUID = get(
          deleteTable,
          "data.disableLocationTable.uuid",
          null
        )

        if (tableUUID) {
          deletedTable && deletedTable(tableUUID as string)

          notification({
            description: intl.formatMessage({
              id: "restaurants.location.bulk.delete.table.modal.success",
              defaultMessage: "Your tables were deleted",
            }),
            type: "success",
          })
          onToggleDeleteTableConfirmationModal()
        }
      }
    } catch (error) {
      showGraphqlErrors(error)
    }
  }

  return (
    <Container display="flex" justifyContent="flex-end">
      <DropdownAction
        placement="bottomRight"
        overlay={
          <Container>
            <Menu role="menu-more-options-tables" selectedKeys={[]}>
              <Menu.Item
                key="edit-table"
                icon={<Icon remixiconClass="ri-edit-2-line" color="Neutral7" />}
                onClick={onToggleEditTableModal}
              >
                <Text size="s">
                  {intl.formatMessage({
                    id: "restaurants.locations.tables.menu.edit.table",
                    defaultMessage: "Edit Table",
                  })}
                </Text>
              </Menu.Item>
              <Menu.Item
                key="disable-table"
                icon={
                  <Icon remixiconClass="ri-delete-bin-line" color="Neutral7" />
                }
                onClick={onToggleDeleteTableConfirmationModal}
              >
                <Text size="s">
                  {intl.formatMessage({
                    id: "restaurants.locations.tables.menu.disable",
                    defaultMessage: "Delete Table",
                  })}
                </Text>
              </Menu.Item>
            </Menu>
          </Container>
        }
      >
        <Icon
          remixiconClass="ri-more-fill"
          cursor="pointer"
          color="Neutral5"
          size={16}
        />
      </DropdownAction>
      {isEditTableModalShown && (
        <EditTable
          defaultValues={record}
          visible={isEditTableModalShown}
          onClose={onToggleEditTableModal}
          closable={false}
        />
      )}
      {isDeleteTableModalShown && (
        <ConfirmationModal
          visible={isDeleteTableModalShown}
          itemType={intl.formatMessage({
            id: "restaurants.location.table.delete.table.modal.title.type",
            defaultMessage: "Tables",
          })}
          itemName={record.name}
          onConfirm={onConfirmDelete}
          onCancel={onToggleDeleteTableConfirmationModal}
          loading={deleting}
        />
      )}
    </Container>
  )
}
