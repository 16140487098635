import { EmployeeRoleNameEnum } from "../../../../../../shared/graphql/generated/types"
import { EmployeeType } from "../../types/employees.types"
import { EmployeeRole } from "../../types/graphql.enums"
import { getFullName } from "../../utils/full-name.util"
import { lettersName } from "../../utils/letters-name"
import { EmployeesMenu } from "../EmployeesMenu"
import styles from "./EmployeesRow.module.css"
import {
  Avatar,
  Badge,
  Flex,
  Stack,
  Text,
  useMantineTheme,
} from "@mantine/core"
import { IconCurrencyDollar } from "@tabler/icons-react"
import { useIntl } from "react-intl"
import { formatPhoneNumber } from "react-phone-number-input"

interface EmployeesRowProps {
  employee: EmployeeType
}

export const EmployeesRow = ({ employee }: EmployeesRowProps) => {
  const mantineTheme = useMantineTheme()

  const intl = useIntl()

  return (
    <tr className={styles["tr-content"]}>
      <td>
        <Flex justify="center" align="center">
          {employee.attachment ? (
            <Avatar src={employee.attachment.signedUrl} size={40} />
          ) : (
            <Avatar color="primary.6" size={40} variant="filled">
              {lettersName(getFullName(employee))}
            </Avatar>
          )}
        </Flex>
      </td>
      <td>
        <Stack spacing={5} miw={110}>
          <Text size="sm" fw={600}>
            {getFullName(employee)}
          </Text>
          <div>
            {employee.deactivatedAt ? (
              <Badge w="auto" radius="sm" color="red.4">
                {intl.formatMessage({
                  id: "settings.locations.employees.table.row.badge.inactive",
                  defaultMessage: "Inactive",
                })}
              </Badge>
            ) : (
              <Badge w="auto" radius="sm" color="gray.6">
                {EmployeeRole[
                  employee.role.name as EmployeeRoleNameEnum
                ].toUpperCase()}
              </Badge>
            )}
          </div>
        </Stack>
      </td>
      <td>
        <Stack spacing={8} w={90}>
          {employee.phone && (
            <Text size="xs">{formatPhoneNumber(employee.phone)}</Text>
          )}
          <Text size="xs" c="gray.6">
            ID {employee.code}
          </Text>
        </Stack>
      </td>
      <td>
        {!employee.deactivatedAt && (
          <Flex
            className={styles["flex-content"]}
            justify="center"
            align="center"
            h={24}
            w={128}
            gap={8}
            bg="primary.0"
          >
            <IconCurrencyDollar
              size={12}
              color={mantineTheme.colors.primary[6]}
            />
            <Text size="xs" color="primary.6">
              {employee.payRate
                ? `${employee.payRate.toFixed(2)}/h`
                : `00.00/h`}
            </Text>
          </Flex>
        )}
      </td>
      <td>
        <EmployeesMenu employee={employee} />
      </td>
    </tr>
  )
}
