import Text from "../../Typography/Text"
import type { HeaderCellProps } from "./interfaces"
import React from "react"
import styled, { css } from "styled-components"

const HeaderCell: React.FC<HeaderCellProps> = ({
  children,
  textTransform,
  textAlign,
  width,
}) => {
  return (
    <StyledTh width={width}>
      <Text
        size="s"
        weight="bold"
        textTransform={textTransform}
        textAlign={textAlign}
        ellipsis
      >
        {children}
      </Text>
    </StyledTh>
  )
}

export default HeaderCell

const StyledTh = styled.th<{ width?: string }>`
  ${({ width }) => css`
    ${!!width &&
    css`
      width: ${width};
    `}
  `}
`
