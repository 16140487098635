import { CateringServiceEnum } from "../../graphql/generated/types"

export const cateringOrderMethodMapping: Record<
  CateringServiceEnum,
  {
    id: string
    defaultMessage: string
  }
> = {
  [CateringServiceEnum.DELIVERY]: {
    id: "restaurants.catering.order.method.delivery",
    defaultMessage: "Delivery",
  },
  [CateringServiceEnum.PICK_UP]: {
    id: "restaurants.catering.order.method.pickup",
    defaultMessage: "Pick Up",
  },
  [CateringServiceEnum.FOOD_TRUCK_EXPERIENCE]: {
    id: "restaurants.catering.order.method.food.truck.experience",
    defaultMessage: "Food Truck Experience",
  },
  [CateringServiceEnum.INSIDE_VENUE]: {
    id: "restaurants.catering.order.method.inside.venue",
    defaultMessage: "Inside Venue",
  },
}
