import type { OrderThrottlingRule } from "../../../../../shared/graphql/generated/types"
import Button from "../../../../../ui/Button"
import ConfirmationModal from "../../../../../ui/ConfirmationModal"
import Container from "../../../../../ui/Container"
import Icon from "../../../../../ui/Icon"
import Input from "../../../../../ui/Inputs/Input"
import SectionHeader from "../../../../../ui/SectionHeader"
import Spacer from "../../../../../ui/Spacer"
import Text from "../../../../../ui/Typography/Text"
import notification from "../../../../../ui/notification"
import { OrderThrottlingSchemaResolver } from "../OrderThrottling.yup"
import ThrottlingHours from "../ThrottlingHours"
import type { IOrderThrottlingForm } from "../hookforms.interfaces"
import { Tooltip } from "antd"
import get from "lodash/get"
import React, { useState } from "react"
import { Controller, useForm } from "react-hook-form"
import { useIntl } from "react-intl"
import { Prompt } from "react-router-dom"

interface IOrderThrottlingFormProps {
  saveOrderThrottling: (formData: IOrderThrottlingForm) => Promise<void>
  rules: Array<OrderThrottlingRule>
  loading: boolean
  displayEditActions: boolean
  defaultOrdersLimit?: number
}

export const OrderThrottlingForm: React.FC<IOrderThrottlingFormProps> = ({
  saveOrderThrottling,
  rules,
  loading,
  defaultOrdersLimit,
}) => {
  const intl = useIntl()
  const [showDiscardChangesConfirm, setShowDiscardChangesConfirm] =
    useState<boolean>(false)
  const [defaultOrdersLimitValue, setDefaultOrdersLimitValue] = useState<
    number | undefined
  >(defaultOrdersLimit)

  const formMethods = useForm<IOrderThrottlingForm>({
    resolver: OrderThrottlingSchemaResolver,
    mode: "all",
    defaultValues: {
      rules,
      defaultOrdersLimit: defaultOrdersLimit,
    },
  })

  const {
    handleSubmit,
    formState: { errors, isValid, isDirty },
    control,
    clearErrors,
    reset,
  } = formMethods

  const invalidRanges = () => {
    try {
      const message = get(errors, "rules.message")
      const type = get(errors, "rules.type")

      if (type === "Invalid time ranges") {
        return message && JSON.parse(message)?.dates
      }
    } catch {
      notification({ type: "error", description: get(errors, "rules.type") })
    }
  }

  const invalidOrdersLimitRows = () => {
    try {
      const message = get(errors, "rules.message")
      const type = get(errors, "rules.type")

      if (type === "Invalid time ranges") {
        return message && JSON.parse(message)?.ordersLimit
      }
    } catch {
      notification({ type: "error", description: get(errors, "rules.type") })
    }
  }

  const onCancelClick = () => {
    reset({ rules, defaultOrdersLimit })
    clearErrors()
  }

  const onDiscardChanges = () => {
    setShowDiscardChangesConfirm(false)
    onCancelClick()
  }

  const onChangeDefault = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDefaultOrdersLimitValue(Number(event.target.value))
  }

  const onCloseDiscardChangesConfirm = () => setShowDiscardChangesConfirm(false)

  return (
    <form>
      <Container display="flex" flexDirection="column" gap="4px">
        <Text size="s">
          {intl.formatMessage({
            id: "settings.locations.order.throttling.default.orders.label",
            defaultMessage: "Default Orders / 15 min",
          })}
        </Text>

        <Controller
          name="defaultOrdersLimit"
          control={control}
          render={({ field }) => {
            return (
              <Input
                {...field}
                min={1}
                defaultValue={defaultOrdersLimitValue}
                size="middle"
                width="94px"
                type="number"
                onChange={field.onChange}
                onBlur={onChangeDefault}
                requirement="required"
                hasError={!!errors.defaultOrdersLimit}
                helperText={errors.defaultOrdersLimit?.message}
                placeholder={intl.formatMessage({
                  id: "settings.locations.order.throttling.default.oder.throttling.hours",
                  defaultMessage: "10",
                })}
                suffix={
                  <Icon
                    size={12}
                    remixiconClass="ri-pencil-line"
                    color={"Primary5"}
                  />
                }
                onlyInteger
              />
            )
          }}
        />
      </Container>
      <Spacer size={68} />
      <SectionHeader
        title={intl.formatMessage({
          id: "settings.locations.order.throttling.section.header.throttling.override.rules.title",
          defaultMessage: "Throttling Override Rules",
        })}
        description={intl.formatMessage({
          id: "settings.locations.order.throttling.section.header.throttling.override.rules.description",
          defaultMessage:
            "If two rules conflict, we will use the one that is higher on this list",
        })}
        adviceTooltip={
          <Tooltip
            title={intl.formatMessage({
              id: "settings.locations.order.throttling.section.header.throttling.override.rules.tooltip",
              defaultMessage: "First, you need to place the default orders",
            })}
          >
            <Icon remixiconClass="ri-error-warning-line" size={14} />
          </Tooltip>
        }
      />

      <Controller
        name="rules"
        control={control}
        render={({ field }) => (
          <ThrottlingHours
            defaultValues={rules}
            onChange={field.onChange}
            invalidRanges={invalidRanges()}
            invalidOrdersLimitRanges={invalidOrdersLimitRows()}
            defaultOrdersLimitValue={defaultOrdersLimitValue}
            readonly={!defaultOrdersLimitValue}
          />
        )}
      />
      <Spacer size={40} />
      <Container display="flex" flexDirection="row" gap="16px">
        <Button
          aria-label="save"
          type="submit"
          title={intl.formatMessage({
            id: "settings.locations.order.throttling.save.information.button",
            defaultMessage: "Save Information",
          })}
          onClick={handleSubmit(saveOrderThrottling)}
          disabled={!isDirty || loading || !isValid}
          loading={loading}
        />
      </Container>

      <Prompt
        when={isDirty}
        message={intl.formatMessage({
          id: "components.prompt.modal.title",
          defaultMessage:
            "You have unsaved changes, do you want to discard them?",
        })}
      />

      <ConfirmationModal
        title={intl.formatMessage({
          id: "components.prompt.modal.title",
          defaultMessage:
            "You have unsaved changes, do you want to discard them?",
        })}
        confirmButtonText={intl.formatMessage({
          id: "components.prompt.modal.cancel.button",
          defaultMessage: "Discard",
        })}
        visible={showDiscardChangesConfirm}
        onCancel={onCloseDiscardChangesConfirm}
        onOk={onDiscardChanges}
      />
    </form>
  )
}
