import { IAdmin } from "../../types"
import { getRefreshToken, setAuthentication } from "../storage/auth"
import { post } from "./client"
import { endpoints } from "./endpoints"
import * as Sentry from "@sentry/react"

interface RefreshTokenResponse {
  accessToken: string
  refreshToken: string
  admin: IAdmin
}

export const postRefreshToken = async () => {
  const token = getRefreshToken()

  const response = await post({
    path: endpoints.auth.refreshToken,
    headers: { refreshtoken: token },
  })

  if (!response.ok) {
    Sentry.captureException(new Error("Refresh token failed"), {
      extra: { refreshtoken: token },
    })
  }

  const data = (await response.json()) as RefreshTokenResponse

  const { accessToken, refreshToken } = data

  setAuthentication(accessToken, refreshToken)

  return data
}
