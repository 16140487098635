import {
  GetAllLocationsByUserQuery,
  GetAllLocationsByUserQueryVariables,
} from "../../../../../GraphQL/Queries/getAllLocationsByUser.generated"
import {
  GetSingleLocationQuery,
  GetSingleLocationQueryVariables,
} from "../../../../../GraphQL/Queries/getSingleLocation.generated"
import { LazyQueryExecFunction } from "@apollo/client"

export interface GetMainLocationArgs {
  userUUID: string
  restaurantUUID: string
  getSingleLocation: LazyQueryExecFunction<
    GetSingleLocationQuery,
    GetSingleLocationQueryVariables
  >
  getAllLocationsByUser: LazyQueryExecFunction<
    GetAllLocationsByUserQuery,
    GetAllLocationsByUserQueryVariables
  >
}

export const getMainLocation = async ({
  userUUID,
  restaurantUUID,
  getSingleLocation,
  getAllLocationsByUser,
}: GetMainLocationArgs) => {
  const { data: allLocationsByUserData } = await getAllLocationsByUser({
    fetchPolicy: "cache-first",
    variables: {
      userUUID,
      restaurantUUID,
      isActive: true,
    },
  })

  const mainLocation =
    allLocationsByUserData?.getAllLocationsByUser.results?.[0]

  if (!mainLocation) return

  const { data: singleLocationData } = await getSingleLocation({
    fetchPolicy: "cache-first",
    variables: {
      locationUUID: mainLocation.uuid,
    },
  })

  return singleLocationData?.getSingleLocation
}
