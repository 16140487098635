import { graphqlClient } from "../../../../../../shared/graphql/client"
import { EmployeeRoleNameEnum } from "../../../../../../shared/graphql/generated/types"
import { putFileWithSignedUrl } from "../../../../../../shared/utils/api/client"
import generateRandomCode from "../../../../../../shared/utils/helpers/generate-random-code.util"
import getImageFilename from "../../../../../../shared/utils/helpers/getImageFilename"
import getImageMetadata from "../../../../../../shared/utils/helpers/getImageMetadata"
import { parsePhoneNumber } from "../../../../../../shared/utils/helpers/parsePhoneNumber"
import { showGraphqlErrors } from "../../../../../../ui/ErrorList"
import { EmployeeForm } from "../../Forms"
import { EmployeeResolver } from "../../Forms/EmployeeForm.yup"
import { EmployeesDocument } from "../../GraphQL/employees.generated"
import { useUpsertEmployeeMutation } from "../../GraphQL/upsertEmployee.generated"
import type { EmployeeFormType } from "../../types/form.types"
import styles from "./CreateEmployeeModal.module.css"
import { Button, Group, Modal } from "@mantine/core"
import { get } from "lodash"
import { useEffect, useState } from "react"
import { FormProvider, useForm } from "react-hook-form"
import { useIntl } from "react-intl"

interface CreateEmployeeProps {
  opened: boolean
  onClose: () => void
}

export const CreateEmployeeModal = ({
  opened,
  onClose,
}: CreateEmployeeProps) => {
  const intl = useIntl()

  const employeeFormMethods = useForm<EmployeeFormType>({
    mode: "all",
    resolver: EmployeeResolver,
    defaultValues: {
      code: 0,
      payRate: undefined,
      firstName: "",
      lastName: "",
      phone: "",
      allowTips: false,
      role: EmployeeRoleNameEnum.RESTAURANT_EMPLOYEE,
      attachment: null,
    },
  })

  const [file, setFile] = useState<File | null>(null)
  const [uploading, setUploading] = useState(false)

  const {
    reset,
    handleSubmit,
    setValue,
    setError,
    formState: { isValid, isDirty },
  } = employeeFormMethods

  const [createEmployee, { loading }] = useUpsertEmployeeMutation()

  const submitSaveEmployee = async (formData: EmployeeFormType) => {
    try {
      await createEmployee({
        variables: {
          input: {
            firstName: formData.firstName,
            lastName: formData.lastName,
            code: formData.code.toString(),
            payRate: formData.payRate ?? 0,
            role: formData.role ?? EmployeeRoleNameEnum.RESTAURANT_EMPLOYEE,
            phone: parsePhoneNumber(formData.phone).number,
            allowTip: formData.allowTips,
            attachment: formData.attachment
              ? {
                  contentType: getImageMetadata(formData.attachment.type)
                    .contentType,
                  ext: getImageMetadata(formData.attachment.ext).ext,
                  fileName: getImageFilename(formData.attachment.name),
                }
              : null,
          },
        },
        onCompleted: async (data) => {
          if (data) {
            if (data.upsertEmployee.attachment?.signedUrl && file) {
              setUploading(true)
              try {
                await putFileWithSignedUrl(
                  data.upsertEmployee.attachment.signedUrl,
                  file
                )

                await graphqlClient.refetchQueries({
                  include: [EmployeesDocument],
                })

                setUploading(false)
              } catch (error) {
                setUploading(false)
                console.error(error)
              }
            }

            await graphqlClient.refetchQueries({
              include: [EmployeesDocument],
            })

            reset()
            onClose()
          }
        },
      })
    } catch (error) {
      const errorMessage = get(error, "message")

      if (errorMessage.includes("code")) {
        setError("code", { type: "custome", message: "Codes is already taken" })
      } else {
        showGraphqlErrors(error)
      }
    }
  }

  const handleClose = () => {
    onClose()
    reset()
  }

  useEffect(() => {
    if (opened) {
      const generateCode = generateRandomCode()
      setValue("code", generateCode, { shouldDirty: true })
      setValue("role", EmployeeRoleNameEnum.RESTAURANT_EMPLOYEE, {
        shouldDirty: true,
      })
    }
  }, [opened, setValue])

  return (
    <FormProvider {...employeeFormMethods}>
      <Modal
        centered
        opened={opened}
        onClose={handleClose}
        title={intl.formatMessage({
          id: "locations.employee.new.create.modal.title",
          defaultMessage: "New Employee",
        })}
        styles={{
          body: {
            padding: 0,
          },
        }}
      >
        <form onSubmit={handleSubmit(submitSaveEmployee)} role="form">
          <EmployeeForm onFile={setFile} />
          <Group className={styles["group-footer"]} position="right">
            <Button
              type="submit"
              loading={loading || uploading}
              disabled={!isDirty || !isValid}
            >
              {intl.formatMessage({
                id: "locations.employee.new.create.modal.button",
                defaultMessage: "Save Employee",
              })}
            </Button>
          </Group>
        </form>
      </Modal>
    </FormProvider>
  )
}
