import yup from "../../../../shared/yup"
import { yupResolver } from "@hookform/resolvers/yup"

export const WhiteLabelApplicationResolver = yupResolver(
  yup.object().shape({
    appIcon: yup.mixed().test("required", (value) => {
      return !!value
    }),
    primaryBrandColor: yup.string().required(),
    description: yup.string().required(),
  })
)
