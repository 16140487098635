import type React from "react"
import { useEffect } from "react"

export const useClickOutside = (
  ref:
    | React.MutableRefObject<null>
    | { current: { contains: (arg: unknown) => unknown } },
  onClick: () => void
) => {
  useEffect(() => {
    const handleClickOutside = (event: { target: unknown }) => {
      if (!ref.current?.contains(event.target)) {
        onClick()
      }
    }
    document.addEventListener("mousedown", handleClickOutside)
    document.addEventListener("touchstart", handleClickOutside)

    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
      document.removeEventListener("touchstart", handleClickOutside)
    }
  }, [ref, onClick])
}
