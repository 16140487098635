import paths from "../../../../../shared/routes/paths"

const {
  settings: { locations },
} = paths

export const locationsItems = [
  {
    path: locations.locationInfo,
    name: "Location Info",
  },
  {
    path: locations.hoursOfOperation,
    name: "Hours of Operation",
  },
  {
    path: locations.orderThrottling,
    name: "Order Throttling",
  },
  {
    path: locations.deliveryByLocation,
    name: "Delivery",
  },
  {
    path: locations.bankingByLocation,
    name: "Banking",
  },
  {
    path: locations.employeesByLocation,
    name: "Employees",
  },
  {
    path: locations.tablesByLocation,
    name: "Tables",
  },
  {
    path: locations.devicesByLocation,
    name: "Devices",
  },
  {
    path: locations.integrationsByLocation,
    name: "Integrations",
  },
  {
    path: locations.cateringByLocation,
    name: "Catering",
  },
]
