import Container from "../../../../../../ui/Container"
import Text from "../../../../../../ui/Typography/Text"
import type { PublishStatusEnum } from "../../titles/publishStatus.title"
import { CustomPublishStatusTitle } from "../../titles/publishStatus.title"
import getBadgeColor from "../utils/get-badge-color.util"
import { Badge } from "antd"
import React from "react"
import { useIntl } from "react-intl"

type PublishingStatusLabelProps = {
  status: PublishStatusEnum
}

export const PublishingStatusLabel: React.FC<PublishingStatusLabelProps> = ({
  status,
}) => {
  const intl = useIntl()

  return (
    <Container
      aria-label="publishing-status-label"
      display="flex"
      flexDirection="row"
      gap="8px"
    >
      <Text size="s">
        <Badge color={getBadgeColor(status)} />
        {intl.formatMessage(CustomPublishStatusTitle[status])}
      </Text>
    </Container>
  )
}
