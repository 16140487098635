import Text from "../../Typography/Text"
import { Tag } from "antd"
import type { CheckableTagProps as AntCheckableTagProps } from "antd/lib/tag/CheckableTag"
import classnames from "classnames"
import React from "react"
import styled, { css } from "styled-components"

const { CheckableTag: CheckableAntTag } = Tag

export interface CheckableTagProps extends AntCheckableTagProps {
  size?: "small"
}

const getTextSize = (size?: "small") => {
  if (size === "small") {
    return "m"
  }

  return "l"
}

const getCheckableTagSize = (size?: "small") => {
  if (size === "small") {
    return css`
      padding: 4px 18px;
      margin: 0;
    `
  }

  return css`
    padding: 4px 24px;
  `
}

export const CheckableTag: React.FC<CheckableTagProps> = ({
  checked,
  children,
  size,
  ...props
}) => {
  return (
    <StyledTag {...props} checked={checked} size={size}>
      <Text
        size={getTextSize(size)}
        className={classnames({
          "tag-content-checked": checked,
          "tag-content": !checked,
        })}
      >
        {children}
      </Text>
    </StyledTag>
  )
}

const StyledTag = styled(CheckableAntTag)<{ size?: "small" }>`
  ${({ size }) => getCheckableTagSize(size)}

  ${({ theme }) =>
    css`
      :active {
        background: ${theme.colors["Primary4"]};
      }
    `}

  ${({ checked, theme }) =>
    checked &&
    css`
      background: ${theme.colors["Primary5"]};
      border: 1px solid ${theme.colors["Primary5"]};
      border-radius: 2px;

      .tag-content-checked {
        color: ${theme.colors.Neutral1};
      }
    `}

  ${({ checked, theme }) =>
    !checked &&
    css`
      background: ${theme.colors["Neutral1"]};
      border: 1px solid ${theme.colors["Primary5"]};
      border-radius: 2px;

      .tag-content {
        color: ${theme.colors.Primary5};
      }
    `}
`
