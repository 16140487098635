import * as Types from '../../../../../shared/graphql/generated/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateOrderThrottlingMutationVariables = Types.Exact<{
  data: Types.CreateOrderThrottlingInput;
}>;


export type CreateOrderThrottlingMutation = { __typename?: 'Mutation', createOrderThrottling: { __typename?: 'OrderThrottling', uuid: string, isActive: boolean, defaultIntervalTime: number, defaultOrdersLimit: number, rules: Array<{ __typename?: 'OrderThrottlingRule', uuid: string, day: Types.WeekDaysEnum, startDate: any, endDate: any, endMin: number, ordersLimit: number, startMin: number, type: Types.OrderThrottlingTypeEnum }> } };


export const CreateOrderThrottlingDocument = gql`
    mutation createOrderThrottling($data: CreateOrderThrottlingInput!) {
  createOrderThrottling(data: $data) {
    uuid
    rules {
      uuid
      day
      startDate
      endDate
      endMin
      ordersLimit
      startMin
      type
    }
    isActive
    defaultIntervalTime
    defaultOrdersLimit
  }
}
    `;
export type CreateOrderThrottlingMutationFn = Apollo.MutationFunction<CreateOrderThrottlingMutation, CreateOrderThrottlingMutationVariables>;

/**
 * __useCreateOrderThrottlingMutation__
 *
 * To run a mutation, you first call `useCreateOrderThrottlingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrderThrottlingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrderThrottlingMutation, { data, loading, error }] = useCreateOrderThrottlingMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateOrderThrottlingMutation(baseOptions?: Apollo.MutationHookOptions<CreateOrderThrottlingMutation, CreateOrderThrottlingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOrderThrottlingMutation, CreateOrderThrottlingMutationVariables>(CreateOrderThrottlingDocument, options);
      }
export type CreateOrderThrottlingMutationHookResult = ReturnType<typeof useCreateOrderThrottlingMutation>;
export type CreateOrderThrottlingMutationResult = Apollo.MutationResult<CreateOrderThrottlingMutation>;
export type CreateOrderThrottlingMutationOptions = Apollo.BaseMutationOptions<CreateOrderThrottlingMutation, CreateOrderThrottlingMutationVariables>;