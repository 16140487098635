import safeNumber from "../../shared/utils/helpers/safeNumber"
import Container from "../Container"
import { DataTableRowSkeleton } from "../DataTable/DataTableRow.skeleton"
import RetryPage from "../RetryPage"
import type { TypographyTextProps } from "../Typography/Text"
import HeaderCell from "./HeaderCell"
import type { ApolloError } from "@apollo/client"
import get from "lodash/get"
import React, { useEffect } from "react"
import styled, { css } from "styled-components"

type ColumCellProps = Pick<TypographyTextProps, "textTransform" | "textAlign">

export type ColumProps = ColumCellProps & {
  title: string
  width?: string
}

type TableProps = {
  top?: string
  columns: Array<ColumProps>
  loading?: boolean
  error?: ApolloError | undefined
  errorRefetch?: () => void
  fetchMore?: () => void
  hasNextPage?: boolean
  leftSpace?: number
  rightSpace?: number
  isArrayEmpty?: boolean
  emptyState?: React.ReactNode
  showHeader?: boolean
  ref?: React.Ref<HTMLTableElement>
  iconVisibility?: number
}

const Table: React.FC<TableProps> = React.forwardRef(
  (
    {
      columns,
      loading,
      error,
      errorRefetch,
      children,
      fetchMore,
      hasNextPage,
      top,
      rightSpace,
      leftSpace,
      emptyState,
      isArrayEmpty = false,
      showHeader = true,
      iconVisibility,
    },
    ref
  ) => {
    useEffect(() => {
      const container: HTMLElement | null = document.querySelector(
        "#main-layout-content-internal"
      )
      const body: HTMLElement | null = document.querySelector(".table-body")

      const lastRow: HTMLElement | null = document.querySelector(
        `.table-body tr:nth-last-child(${2 + 1})`
      )

      const windowHeight =
        window.innerHeight - get(body, "offsetParent.offsetTop", 0)
      const lastRowHeight = safeNumber(lastRow?.getBoundingClientRect().top)

      if (windowHeight > lastRowHeight && hasNextPage && !!fetchMore) {
        fetchMore()
      }

      const handleScroll = () => {
        if (container && !!fetchMore) {
          const scrollTop = container.scrollTop
          const scrollHeight = container.scrollHeight
          const offsetHeight = container.offsetHeight
          const contentHeight = scrollHeight - offsetHeight
          const scrolledToBottom = Math.abs(contentHeight - scrollTop) < 1

          if (scrolledToBottom && hasNextPage) {
            fetchMore()
          }
        }
      }

      if (container) {
        container.addEventListener("scroll", handleScroll, false)
      }

      return () => {
        if (container) {
          container.removeEventListener("scroll", handleScroll)
        }
      }
    }, [fetchMore, hasNextPage])

    return (
      <StyledContentTable
        id="main-layout-content-internal"
        top={top}
        leftSpace={leftSpace}
        rightSpace={rightSpace}
      >
        <StyledOrderTable ref={ref} iconVisibility={iconVisibility}>
          {showHeader && (
            <thead className="table-header">
              <tr>
                {columns.map(
                  (
                    { textAlign = "left", textTransform, width, title },
                    index
                  ) => {
                    {
                      return (
                        <HeaderCell
                          key={index}
                          width={width}
                          textAlign={textAlign}
                          textTransform={textTransform}
                        >
                          {title}
                        </HeaderCell>
                      )
                    }
                  }
                )}
              </tr>
            </thead>
          )}

          <tbody className="table-body">
            {!error && children}
            {!!loading && (
              <tr>
                <td colSpan={columns.length}>
                  <DataTableRowSkeleton rows={2} />
                </td>
              </tr>
            )}
            {!!error && !loading && (
              <tr role="row-error" className="row-error">
                <td colSpan={columns.length}>
                  <RetryPage error={error} reload={errorRefetch} fullScreen />
                </td>
              </tr>
            )}
            {isArrayEmpty && !loading && emptyState}
          </tbody>
        </StyledOrderTable>
      </StyledContentTable>
    )
  }
)

export default Table

const StyledOrderTable = styled.table<{ iconVisibility?: number }>`
  ${({ theme, iconVisibility }) => css`
    width: 100%;
    table-layout: auto;

    th,
    td {
      height: 56px;
      padding-left: 16px;
      padding-right: 16px;
      cursor: pointer;
    }

    thead {
      border-bottom: 1px solid ${theme.colors["Neutral3"]};
    }

    th {
      position: sticky;
      top: 0;
      z-index: 2;
      background: ${theme.colors["Neutral2"]};
    }

    tr:not([role="row-error"]):not([role="row-empty"]):hover {
      background: ${theme.colors["Neutral1"]};

      i {
        opacity: ${iconVisibility ? iconVisibility : null};
      }
    }
  `}
`

const StyledContentTable = styled(Container)<{
  top?: string
  leftSpace?: number
  rightSpace?: number
}>`
  ${({ top, leftSpace, rightSpace }) => css`
    width: 100%;
    overflow-x: auto;
    height: ${top ? `calc(100vh - ${top})` : "0"};

    th:last-child,
    td:last-child {
      padding-right: ${rightSpace ?? 16}px;
    }

    th:first-child,
    td:first-child {
      padding-left: ${leftSpace ?? 16}px;
    }
  `}
`
