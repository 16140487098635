import { Layout } from "antd"
import React from "react"
import styled, { css } from "styled-components"

export const PageHeader: React.FC = (props) => (
  <StyledPageHeader {...props} className="page-header" />
)

export const StyledPageHeader = styled(Layout.Header)`
  ${({ theme }) => css`
    background: ${theme.colors["Neutral1"]};
    box-shadow: inset 0px -1px 0px ${theme.colors["Neutral3"]};
    height: 48px;
    line-height: 48px;
  `}

  &.ant-layout-header {
    padding: 0;
  }
`
