import { generatePaths } from "."
import { DEFAULT_ID } from "../../shared/utils/constant/breadcrumb"
import storage from "../../shared/utils/storage"
import type { IconColor } from "../Icon"
import Icon from "../Icon"
import type { TextColor } from "../Typography/Text"
import Text from "../Typography/Text"
import { BreadcrumbSkeleton } from "./Breadcrumb.skeleton"
import { slicePaths } from "./breadcrumb-object.util"
import type { IBreadcrumbLocations, IBreadcrumbs } from "./interfaces"
import React, { Fragment, useState } from "react"
import { Link, useHistory, useLocation, useParams } from "react-router-dom"
import styled from "styled-components"

interface BreadcrumbProps {
  id?: string
  breadcrumbs?: IBreadcrumbs[]
  pageName?: string
  maxItems?: number
  separatorRemixIcon?: string
  separatorIconColor?: IconColor
  separatorIconSize?: number
  collapseIconColor?: IconColor
  collapseIconSize?: number
  collapseRemixIcon?: string
  linkTextColor?: TextColor
  textColor?: TextColor
  loading?: boolean
}

const Breadcrumb = (props: BreadcrumbProps) => {
  const {
    id = DEFAULT_ID,
    maxItems = 4,
    separatorRemixIcon = "ri-arrow-right-s-line",
    separatorIconColor = "Neutral6",
    separatorIconSize = 16,
    collapseIconColor = "Primary5",
    collapseIconSize = 24,
    collapseRemixIcon = "ri-more-fill",
    linkTextColor = "Primary5",
    textColor = "Neutral8",
    loading = false,
    breadcrumbs,
    pageName,
  } = props
  const { key: locationKey, pathname } = useLocation()
  const params = useParams()
  const history = useHistory()
  const [collapsed, setCollapsed] = useState(false)

  const hasHistory = !!locationKey
  const locations = storage.get(id) as IBreadcrumbLocations[]
  const slicedPaths = slicePaths(id, locations, pathname)

  const onCollapse = () => setCollapsed(true)

  const renderItems = (items: React.ReactNode[]) => {
    if (items?.length >= maxItems) {
      return [
        ...items?.slice(0, 1),
        <Icon
          key="expandable-icon"
          remixiconClass={collapseRemixIcon}
          color={collapseIconColor}
          size={collapseIconSize}
          cursor="pointer"
          onClick={onCollapse}
        />,
        <Icon
          key="fixed-separator"
          color={separatorIconColor}
          remixiconClass={separatorRemixIcon}
          size={separatorIconSize}
        />,
        ...items?.slice(-1, items.length),
      ]
    }

    return items
  }

  if (!hasHistory) {
    const generatedPaths = generatePaths({
      breadcrumbsData: breadcrumbs ?? [],
      params,
      search: location.search,
      pathName: location.pathname,
      customTitle: pageName,
    })
    const locationsToSave = generatedPaths.map((location) => ({
      path: location.pathPattern,
      title: location.title,
    }))
    storage.set(id, locationsToSave)
    history.replace(pathname, { [id]: true })

    const list = generatedPaths.map((location, index) => {
      const isLast = index === generatedPaths.length - 1

      if (isLast)
        return (
          <>
            <Text key="last-breadcrumb" color={textColor}>
              {pageName}
            </Text>
            {loading && <BreadcrumbSkeleton />}
          </>
        )

      return (
        <Fragment key={location.pathPattern}>
          <StyledItem to={location.pathPattern}>
            <Text color={linkTextColor}>{location.title}</Text>
          </StyledItem>
          <Icon
            color={separatorIconColor}
            remixiconClass={separatorRemixIcon}
            size={separatorIconSize}
          />
        </Fragment>
      )
    })

    return (
      <StyledContainer>
        <StyledList>{collapsed ? list : renderItems(list)}</StyledList>
      </StyledContainer>
    )
  }

  if (hasHistory) {
    const list = slicedPaths?.map((location, index) => {
      const isLast = index === slicedPaths.length - 1
      const isFirst = index === 0

      return (
        <Fragment key={location.path}>
          <StyledItem
            to={{
              pathname: location.path,
              state: { [id]: isFirst ? false : true },
            }}
          >
            <Text color={linkTextColor}>{location.title}</Text>
          </StyledItem>
          <Icon
            color={separatorIconColor}
            remixiconClass={separatorRemixIcon}
            size={separatorIconSize}
          />
          {isLast && <Text color={textColor}>{pageName}</Text>}
          {loading && <BreadcrumbSkeleton />}
        </Fragment>
      )
    })

    return (
      <StyledContainer>
        <StyledList>{collapsed ? list : renderItems(list)}</StyledList>
      </StyledContainer>
    )
  }

  return null
}

export default Breadcrumb

const StyledContainer = styled.nav`
  margin: 0px;
`

const StyledList = styled.ol`
  display: flex;
  flex-direction: row;
  gap: 8px;
  list-style: none;
  align-items: center;
  padding: 0;
  margin: 0;
`

const StyledItem = styled(Link)`
  text-align: center;
  display: list-item;
  cursor: pointer;
`
