import Text from "../../Typography/Text"
import type { TypographyTextProps } from "../../Typography/Text"
import React from "react"
import type { IBody } from "styled-components"
import styled, { css } from "styled-components"

interface IInputHelperText extends TypographyTextProps {
  $hasError?: boolean
  top?: string
  left?: string
  right?: string
  bottom?: string
  wrap?: boolean
  countDown?: number
  commentCharactersLimit?: number
  size?: keyof IBody
}

export const InputHelperText: React.FC<IInputHelperText> = ({
  children,
  top,
  right,
  bottom,
  wrap,
  countDown,
  commentCharactersLimit,
  $hasError = false,
  size = "m",
  left = "0",
  ...props
}) => {
  return children || commentCharactersLimit ? (
    <StyledInputHelperTextWrapper
      $hasError={$hasError}
      className="input-helper"
      top={top}
      left={left}
      right={right}
      bottom={bottom}
      $wrap={wrap}
    >
      <Text
        {...props}
        size={size}
        weight="regular"
        color={$hasError ? "Danger5" : "Neutral5"}
      >
        {children}
      </Text>

      {!!commentCharactersLimit && (
        <Text size={size} weight="regular" className="counter" color="Neutral5">
          {`${countDown}/${commentCharactersLimit}`}
        </Text>
      )}
    </StyledInputHelperTextWrapper>
  ) : null
}

interface StyledInputHelperTextWrapperProps {
  $hasError: boolean
  top?: string
  left?: string
  right?: string
  bottom?: string
  $wrap?: boolean
}

export const StyledInputHelperTextWrapper = styled.div<StyledInputHelperTextWrapperProps>`
  width: 100%;
  display: flex;
  justify-content: space-between;
  position: absolute;

  ${({ $wrap }) =>
    css`
      white-space: ${$wrap ? "normal" : "nowrap"};
    `}

  ${({ top }) =>
    top &&
    css`
      top: ${top};
    `}

  ${({ right }) =>
    right &&
    css`
      right: ${right};
    `}

  ${({ bottom }) =>
    bottom &&
    css`
      bottom: ${bottom};
    `}

  ${({ left }) =>
    left &&
    css`
      left: ${left};
    `}
`
