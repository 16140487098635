import AddIcon from "../../../../../ui/AddIcon"
import Container from "../../../../../ui/Container"
import CreateTable from "../CreateTable"
import { useState } from "react"

export const AddTableButton = () => {
  const [displayForm, setDisplayForm] = useState<boolean>(false)

  const onClose = () => {
    setDisplayForm(false)
  }

  const showModal = () => {
    setDisplayForm(true)
  }

  return (
    <Container display="flex" alignItems="center">
      <AddIcon onClick={showModal} />
      <CreateTable visible={displayForm} onClose={onClose} closable={false} />
    </Container>
  )
}
