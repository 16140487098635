import { Stack, Text } from "@mantine/core"
import { ReactNode } from "react"

interface LabelInformationProps {
  label: string
  labelWeight?: 400 | 600
  description?: ReactNode
  descriptionSize?: "xs" | "sm" | "md" | "lg" | "xl"
}

export const LabelInformation = ({
  label,
  labelWeight = 400,
  description,
  descriptionSize = "md",
}: LabelInformationProps) => {
  return (
    <Stack spacing={4}>
      <Text fw={labelWeight} color="gray.6">
        {label}
      </Text>
      <Text size={descriptionSize}>{description}</Text>
    </Stack>
  )
}
