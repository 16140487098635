import { Skeleton } from "antd"
import styled from "styled-components"

export const ImageCoverFieldSkeleton = () => {
  return (
    <StyledAvatarFieldSkeleton className="avatar-field-skeleton">
      <Skeleton.Avatar active shape="square" />
    </StyledAvatarFieldSkeleton>
  )
}

const StyledAvatarFieldSkeleton = styled.div`
  &.avatar-field-skeleton {
    .ant-skeleton-avatar {
      width: 200px;
      height: 400px;
    }
  }
`
