import * as Types from '../../../../../shared/graphql/generated/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetLocationIntegrationQueryVariables = Types.Exact<{
  source: Types.ExternalSourceEnum;
}>;


export type GetLocationIntegrationQuery = { __typename?: 'Query', getLocationIntegration?: { __typename?: 'ExternalIntegration', accessToken: string, appId: string, externalId: string, source: Types.ExternalSourceEnum, uuid: string, locationIntegration?: { __typename?: 'BasicLocationIntegration', externalId: string, externalName: string, type: Types.ExternalIntegrationEnum } | null, locationOrderIntegrations: Array<{ __typename?: 'BasicLocationIntegration', externalId: string, externalName: string, type: Types.ExternalIntegrationEnum }> } | null };


export const GetLocationIntegrationDocument = gql`
    query GetLocationIntegration($source: ExternalSourceEnum!) {
  getLocationIntegration(source: $source) {
    accessToken
    appId
    externalId
    locationIntegration {
      externalId
      externalName
      type
    }
    locationOrderIntegrations {
      externalId
      externalName
      type
    }
    source
    uuid
  }
}
    `;

/**
 * __useGetLocationIntegrationQuery__
 *
 * To run a query within a React component, call `useGetLocationIntegrationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLocationIntegrationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLocationIntegrationQuery({
 *   variables: {
 *      source: // value for 'source'
 *   },
 * });
 */
export function useGetLocationIntegrationQuery(baseOptions: Apollo.QueryHookOptions<GetLocationIntegrationQuery, GetLocationIntegrationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLocationIntegrationQuery, GetLocationIntegrationQueryVariables>(GetLocationIntegrationDocument, options);
      }
export function useGetLocationIntegrationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLocationIntegrationQuery, GetLocationIntegrationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLocationIntegrationQuery, GetLocationIntegrationQueryVariables>(GetLocationIntegrationDocument, options);
        }
export type GetLocationIntegrationQueryHookResult = ReturnType<typeof useGetLocationIntegrationQuery>;
export type GetLocationIntegrationLazyQueryHookResult = ReturnType<typeof useGetLocationIntegrationLazyQuery>;
export type GetLocationIntegrationQueryResult = Apollo.QueryResult<GetLocationIntegrationQuery, GetLocationIntegrationQueryVariables>;