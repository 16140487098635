import { Flex } from "@mantine/core"
import { ReactNode } from "react"

export const RuleChipLayout = ({ children }: { children: ReactNode }) => {
  return (
    <Flex gap={8} wrap={"wrap"}>
      {children}
    </Flex>
  )
}
