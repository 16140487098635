import paths from "../../../../../shared/routes/paths"
import type { AllowedRoles } from "../../../../RoleView/RoleView"

interface IntegrationItemsProps {
  path: string
  title: string
  allowedRoles: AllowedRoles[]
}

const {
  settings: {
    restaurant: { integrations },
  },
} = paths

const allowOwnerAndAdmin: AllowedRoles[] = [
  "RESTAURANT_OWNER",
  "RESTAURANT_ADMIN",
]

export const integrationItems: IntegrationItemsProps[] = [
  {
    path: integrations.square,
    title: "Square",
    allowedRoles: allowOwnerAndAdmin,
  },
]
