import { useGeneralContext } from "../../../../../shared/contexts/StoreProvider"
import type { MemberWithInviteAndLocationsModel } from "../../../../../shared/graphql/generated/types"
import { AdminRoleNameEnum } from "../../../../../shared/graphql/generated/types"
import Button from "../../../../../ui/Button"
import Container from "../../../../../ui/Container"
import { showGraphqlErrors } from "../../../../../ui/ErrorList"
import InputLabel from "../../../../../ui/InputLabel"
import type { IModalProps } from "../../../../../ui/Modal"
import Modal from "../../../../../ui/Modal"
import Switch from "../../../../../ui/Switch"
import notification from "../../../../../ui/notification"
import { useCreateRestaurantMemberMutation } from "../GraphQL/createRestaurantMember.generated"
import MemberForm from "../MemberForm"
import { MemberResolver } from "../MemberForm/MemberResolver.yup"
import type { IMemberForm } from "../interfaces/hookforms.interfaces"
import React, { useState } from "react"
import { FormProvider, useForm } from "react-hook-form"
import { useIntl } from "react-intl"
import styled from "styled-components"

type CreateMemberProps = Omit<IModalProps, "onCancel"> & {
  onClose: () => void
  defaultValues?: Record<string, unknown>
}

export type CustomMembersModel = MemberWithInviteAndLocationsModel & {
  uuid: string
}

export const CreateMember: React.FC<CreateMemberProps> = ({
  defaultValues,
  onClose,
  ...props
}) => {
  const intl = useIntl()
  const [addMore, setAddMore] = useState<boolean>(false)

  const {
    state: {
      currentRestaurant: { uuid: restaurantUUID },
    },
  } = useGeneralContext()

  const methods = useForm<IMemberForm>({
    mode: "all",
    resolver: MemberResolver,
    defaultValues: {
      roleName: AdminRoleNameEnum.RESTAURANT_ADMIN,
      ...defaultValues,
    },
  })

  const {
    handleSubmit,
    reset,
    formState: { isValid, dirtyFields },
  } = methods

  const resetForm = () => {
    reset()
    setAddMore(false)
  }

  const [createRestaurantMember, { loading }] =
    useCreateRestaurantMemberMutation({
      update: (cache, result) => {
        const data = result.data?.createRestaurantMember

        if (data) {
          const newValue: CustomMembersModel = {
            uuid: data.inviteUUID,
            email: data.email,
            firstName: data.firstName,
            inviteStatus: data.inviteStatus,
            inviteUUID: data.inviteUUID,
            lastName: data.lastName,
            locations: data.locations,
            restaurantUUID: data.restaurantUUID,
            roleName: data.roleName,
            userUUID: data.userUUID,
          }

          cache.modify({
            fields: {
              getAllMembersByRestaurant: (prev) => {
                return [{ ...newValue }, ...prev]
              },
            },
          })
        }
      },
    })

  const saveMember = async (formData: IMemberForm) => {
    try {
      const { data } = await createRestaurantMember({
        variables: {
          data: {
            email: formData.email,
            firstName: formData.firstName,
            lastName: formData.lastName,
            ...(formData.roleName === AdminRoleNameEnum.RESTAURANT_MANAGER && {
              locationUUIDs: formData.locationUUIDs,
            }),
            restaurantUUID,
            roleName: formData.roleName,
          },
        },
      })

      const inviteEmail = data?.createRestaurantMember.email

      if (inviteEmail) {
        notification({
          description: intl.formatMessage({
            id: "settings.restaurant.members.create.member.success.description",
            defaultMessage: "We just sent an invite link to your new member.",
          }),
          type: "success",
        })
        reset()
      }

      addMore ? reset({ roleName: formData.roleName }) : (onClose(), reset())
    } catch (error) {
      showGraphqlErrors(error)
    }
  }

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    handleSubmit(saveMember)()
  }

  return (
    <FormProvider {...methods}>
      <Modal
        {...props}
        onCancel={onClose}
        shouldCloseOnClickOutside={Object.keys(dirtyFields).length === 0}
        footer={
          <Container
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
          >
            <StyledModalFooter display="flex" justifyContent="flex-start">
              <InputLabel
                label={
                  <div className="footer-create-more">
                    {intl.formatMessage({
                      id: "settings.restaurant.members.add.member.modal.create.more",
                      defaultMessage: "Save and Create Another",
                    })}
                    <Switch
                      size="small"
                      checked={addMore}
                      onChange={setAddMore}
                      className="create-more-check"
                    />
                  </div>
                }
              />
            </StyledModalFooter>
            <StyledModalFooter
              display="flex"
              justifyContent="flex-end"
              gap="8px"
            >
              <Button
                title={intl.formatMessage({
                  id: "settings.restaurant.members.add.member.modal.cancel",
                  defaultMessage: "Cancel",
                })}
                hierarchy="secondary"
                onClick={onClose}
                disabled={loading}
              />
              <Button
                disabled={!isValid}
                loading={loading}
                title={intl.formatMessage({
                  id: "settings.restaurant.members.add.member.modal.ok",
                  defaultMessage: "Ok",
                })}
                onClick={handleSubmit(saveMember)}
              />
            </StyledModalFooter>
          </Container>
        }
        title={intl.formatMessage({
          id: "settings.restaurant.members.add.member.modal.title",
          defaultMessage: "Add Member",
        })}
        afterClose={resetForm}
        destroyOnClose
      >
        <MemberForm onSubmit={onSubmit} />
      </Modal>
    </FormProvider>
  )
}

const StyledModalFooter = styled(Container)`
  .input-label-wrapper {
    margin-bottom: 0px;
  }
  .footer-create-more {
    margin-left: 8px;
    margin-right: 8px;
    display: flex;
    align-items: center;
    .create-more-check {
      margin-left: 8px;
    }
  }
`
