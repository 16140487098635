/* eslint-disable unicorn/no-thenable */
import yup from "../../../../../../../shared/yup"
import { yupResolver } from "@hookform/resolvers/yup"

const HolidaysHoursSchema = yup.object().shape({
  name: yup.string().required(),
  date: yup.string().required(),
  isClosed: yup.bool(),
  startTime: yup.string().when("isClosed", {
    is: false,
    then: yup.string().timeRange(),
  }),
  endTime: yup.string().when("isClosed", {
    is: false,
    then: yup.string().timeRange(),
  }),
})

export const HolidayHoursResolver = yupResolver(HolidaysHoursSchema)
