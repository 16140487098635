const numericCharacterStringCodePointOffset = 48
const uppercaseCharacterStringCodePointOffset = 55

const numericCharacterStringCodePointLimit = 9
const uppercaseCharacterStringCodePointLimit = 35

const MAX_UINT8_VALUE = 255

export const generateRandomAlphanumericCode = (length: number): string => {
  const randomValues = [...crypto.getRandomValues(new Uint8Array(length))]

  const randomCharValues = randomValues.map((randomValue) => {
    const standardizedRandomValue = Math.trunc(
      (randomValue / MAX_UINT8_VALUE) * uppercaseCharacterStringCodePointLimit
    )

    let randomValueStringCodePoint = standardizedRandomValue

    randomValueStringCodePoint +=
      standardizedRandomValue <= numericCharacterStringCodePointLimit
        ? numericCharacterStringCodePointOffset
        : uppercaseCharacterStringCodePointOffset

    return String.fromCodePoint(randomValueStringCodePoint)
  })

  return randomCharValues.join("")
}
