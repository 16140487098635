import validateImageFileType from "../../shared/utils/helpers/validateImageFileType"
import StyledInputWrapper from "../Field/Wrapper"
import Icon from "../Icon"
import type { InputLabelProps } from "../InputLabel"
import InputLabel from "../InputLabel"
import InputHelper from "../Inputs/InputHelper"
import Spinner from "../Spinner"
import notification from "../notification"
import { cropperAspectRatio } from "./utils/cropperAspect.util"
import { Upload } from "antd"
import type { ImgCropProps } from "antd-img-crop"
import ImgCrop from "antd-img-crop"
import type { RcFile, UploadChangeParam } from "antd/lib/upload"
import type { UploadFile } from "antd/lib/upload/interface"
import classnames from "classnames"
import type CSS from "csstype"
import React from "react"
import type { RefObject } from "react"
import { useIntl } from "react-intl"
import styled, { css } from "styled-components"

const { Dragger } = Upload

export type UploadAvatarProps = Omit<InputLabelProps, "label"> & {
  className?: string
  helperText?: string
  hasError?: boolean
  avatar?: RcFile | string
  label?: React.ReactNode | string
  onChange: (
    avatar?: RcFile,
    info?: UploadChangeParam<UploadFile<unknown>>
  ) => void
  hint?: string
  deletable?: boolean
  onDelete?: () => void
  height?: number
  width?: number
  cropper?: boolean
  imageFit?: CSS.Property.ObjectFit
  cropperAspect?:
    | "square"
    | "rectangle"
    | "vertical_rectangle"
    | "horizontal_rectangle"
  validateSquareLogo?: boolean
  beforeUpload?: (file: RcFile) => void
  loading?: boolean
  disabled?: boolean
}

export const UploadAvatar = React.forwardRef(
  (
    props: UploadAvatarProps,
    ref:
      | ((instance: HTMLParagraphElement | null) => void)
      | RefObject<HTMLParagraphElement>
      | null
      | undefined
  ) => {
    const hoverDivTop = 8
    const hoverDivLeft = 8

    const {
      avatar,
      onChange,
      label,
      helperText,
      hasError,
      className,
      hint,
      deletable,
      onDelete,
      imageFit,
      cropper = false,
      validateSquareLogo,
      beforeUpload,
      height = 130,
      width = 130,
      cropperAspect = "square",
      disabled = false,
      loading = false,
      ...labelProps
    } = props
    const intl = useIntl()

    const Cropper = React.useCallback(
      ({ children }: { children: ImgCropProps["children"] }) => {
        if (cropper) {
          const aspect = cropperAspectRatio[cropperAspect]

          return (
            <ImgCrop
              aspect={aspect}
              minZoom={0.5}
              cropperProps={{ restrictPosition: false }}
            >
              {children}
            </ImgCrop>
          )
        }

        return children
      },
      [cropper, cropperAspect]
    )

    const onDraggerChange = (info: UploadChangeParam<UploadFile<unknown>>) =>
      onChange(info.file.originFileObj, info)

    const getImageSource = (file: RcFile | string) => {
      try {
        return typeof avatar === "string"
          ? avatar
          : URL.createObjectURL(file as RcFile)
      } catch {
        return undefined
      }
    }

    const getDimensionsFromBlobUrl = (
      dataURL: string
    ): Promise<{ height: number; width: number }> =>
      new Promise((resolve) => {
        const img = new Image()

        img.addEventListener("load", () => {
          resolve({
            height: img.height,
            width: img.width,
          })
        })
        img.src = dataURL
      })

    const beforeUploadd = async (file: RcFile) => {
      const isValid = validateImageFileType(file.type)

      if (!isValid) {
        notification({
          description: intl.formatMessage({
            id: "components.upload.images.wrong.format.message",
            defaultMessage:
              "You can only upload JPG, JPEG, AVIF, WEBP, or PNG pictures.",
          }),
          type: "error",
        })
      }

      if (validateSquareLogo) {
        const blobUrl = URL.createObjectURL(file)

        const { width: widthFromBlob, height: heightFromBlob } =
          await getDimensionsFromBlobUrl(blobUrl)

        if (widthFromBlob !== 1024 || heightFromBlob !== 1024) {
          notification({
            description: intl.formatMessage({
              id: "components.upload.images.wrong.dimension.logo.message",
              defaultMessage: "You must provide a 1024 x 1024 pixel image.",
            }),
            type: "info",
          })

          return false
        }
      }

      return isValid
    }

    const onDeleteImage = (
      event: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
      event.stopPropagation()
      event.preventDefault()
      onDelete?.()
    }

    return (
      <StyledInputWrapper className={classnames("avatar", className)}>
        {label && <InputLabel label={label} {...labelProps} />}

        <StyledUploadAvatar
          ref={ref}
          width={width}
          height={height}
          top={hoverDivTop}
          left={hoverDivLeft}
          $imageFit={imageFit}
          $avatar={avatar}
        >
          <Cropper>
            <Dragger
              beforeUpload={beforeUpload ? beforeUpload : beforeUploadd}
              onChange={onDraggerChange}
              listType="picture"
              accept="image/*"
              multiple={false}
              showUploadList={false}
              disabled={disabled}
            >
              {avatar ? (
                <div>
                  <img className="img-avatar" src={getImageSource(avatar)} />

                  <div
                    className={`hover-container ${
                      loading ? "loading-container" : ""
                    }`}
                  >
                    {loading ? (
                      <Spinner />
                    ) : (
                      <>
                        <label htmlFor={"edit-image-input"}>
                          <Icon remixiconClass="ri-pencil-line" />
                        </label>
                        {deletable && (
                          <button
                            data-value={avatar}
                            role="delete-image"
                            onClick={onDeleteImage}
                          >
                            <Icon remixiconClass="ri-delete-bin-7-line" />
                          </button>
                        )}
                      </>
                    )}
                  </div>
                </div>
              ) : (
                <>
                  <Icon
                    remixiconClass="ri-add-fill"
                    size={16}
                    color="Neutral7"
                  />
                  {!!hint && <p className="ant-upload-hint">{hint}</p>}
                </>
              )}
            </Dragger>
          </Cropper>
        </StyledUploadAvatar>

        {!!helperText && (
          <InputHelper $hasError={hasError}>{helperText}</InputHelper>
        )}
      </StyledInputWrapper>
    )
  }
)

const getImageHoverWidth = (width: number, divLeft: number) => {
  return width - divLeft * 2 - 4
}

const getImageHoverHeight = (height: number, divTop: number) => {
  return height - divTop * 2 - 4
}

interface StyledUploadAvatarProps {
  width: number
  height: number
  top: number
  left: number
  $imageFit?: CSS.Property.ObjectFit
  $avatar?: RcFile | string
}

const StyledUploadAvatar = styled.div<StyledUploadAvatarProps>`
  ${({ width, height, top, left, theme, $imageFit, $avatar }) => css`
    width: ${width}px;
    height: ${height}px;

    input {
      display: none;
    }

    img {
      width: ${width - 2}px;
      height: ${height - 2}px;
      border-radius: 4px;
      object-fit: ${$imageFit ?? "cover"};
    }

    .ant-upload .ant-upload-btn {
      padding: 0;
    }

    .ant-upload.ant-upload-drag {
      border: ${$avatar && "none"};
      border-radius: 4px;
      padding: 0;
    }

    .ant-upload.ant-upload-drag:hover {
      border-color: ${theme.colors["Primary3"]};
      background: ${theme.colors["Primary1"]};
    }

    .ant-upload-hint {
      margin-top: 12px;
    }

    .hover-container {
      position: absolute;
      top: ${top + 1}px;
      left: ${left + 1}px;
      width: ${getImageHoverWidth(width, left)}px;
      height: ${getImageHoverHeight(height, top)}px;
      border-radius: 4px;
      z-index: 10;
      display: none;

      background: ${theme.colors.Neutral5};

      input {
        display: none;
      }

      button {
        height: 30px;
        border: none;
        background-color: unset;
        margin: 0;
        padding: 0;
        margin-left: 8px;

        :hover {
          cursor: pointer;
        }
      }

      label:hover {
        cursor: pointer;
      }
    }

    .loading-container {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    :hover {
      img {
        width: ${getImageHoverWidth(width, left)}px;
        height: ${getImageHoverHeight(height, top)}px;
      }

      .hover-container {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .anticon {
        color: ${theme.colors.white};
      }
    }
  `}
`
