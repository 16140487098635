import type { DeviceFunctionEnum } from "../../../../../shared/graphql/generated/types"
import { IntlMessage } from "../../../../../shared/types"

type ICustomDeviceFunction = {
  [key in DeviceFunctionEnum]: IntlMessage
}

export const CustomDeviceFunctionTitle: ICustomDeviceFunction = {
  KDS: {
    id: "restaurants.devices.device.function.kds",
    defaultMessage: "KDS",
  },
  KIOSK: {
    id: "restaurants.devices.device.function.kiosk",
    defaultMessage: "KIOSK",
  },
  POS: {
    id: "restaurants.devices.device.function.pos",
    defaultMessage: "POS",
  },
}
