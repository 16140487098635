import { GetAllLocationsByUserDocument } from "../../../../GraphQL/Queries/getAllLocationsByUser.generated"
import paths from "../../../../shared/routes/paths"
import Button from "../../../../ui/Button"
import ConfirmationModal from "../../../../ui/ConfirmationModal"
import { showGraphqlErrors } from "../../../../ui/ErrorList"
import Spacer from "../../../../ui/Spacer"
import HighlightedText from "../../../../ui/Typography/HighlightedText"
import Text from "../../../../ui/Typography/Text"
import notification from "../../../../ui/notification"
import { useDeleteLocationMutation } from "./GraphQL/deleteLocation.generated"
import React, { useState } from "react"
import { useIntl } from "react-intl"
import { FormattedMessage } from "react-intl"
import { useHistory } from "react-router-dom"

type DeleteLocationProps = {
  locationUUID: string
  locationName: string
}

export const DeleteLocation: React.FC<DeleteLocationProps> = ({
  locationUUID,
  locationName,
}) => {
  const intl = useIntl()
  const { push } = useHistory()
  const [showDeleteConfirm, setShowDeleteConfirm] = useState<boolean>(false)

  const [deleteLocation] = useDeleteLocationMutation({
    refetchQueries: [GetAllLocationsByUserDocument],
  })

  const onDeleteLocation = async () => {
    try {
      await deleteLocation({
        variables: {
          locationUUID,
        },
      })

      notification({
        description: intl.formatMessage({
          id: "restaurants.locations.delete.message",
          defaultMessage: "Your location was deleted",
        }),
        type: "success",
      })
      setShowDeleteConfirm(false)
      push(paths.settings.restaurant.restaurantInfo)
    } catch (error) {
      showGraphqlErrors(error)
    }
  }

  const onConfirmDeleteLocation = () => {
    setShowDeleteConfirm(true)
  }

  const onCancelDelete = () => {
    setShowDeleteConfirm(false)
  }

  return (
    <div>
      <Text size="l" weight="bold">
        {intl.formatMessage({
          id: "restaurants.locations.overview.delete.title",
          defaultMessage: "Delete Location",
        })}
      </Text>
      <Text size="m" weight="regular">
        <FormattedMessage
          id="restaurants.locations.overview.delete.description"
          defaultMessage="If you want to permanently delete {locationName} Location and all of its information, you can do so below."
          values={{
            locationName: (
              <HighlightedText size="m">{locationName}</HighlightedText>
            ),
          }}
        />
      </Text>
      <Spacer size={20} />
      <Button
        title={intl.formatMessage({
          id: "restaurants.locations.overview.delete.button",
          defaultMessage: "Delete Location",
        })}
        hierarchy="secondary"
        aria-label="delete-location-button"
        onClick={onConfirmDeleteLocation}
        display="danger"
      />

      <ConfirmationModal
        visible={showDeleteConfirm}
        itemType={intl.formatMessage({
          id: "restaurants.locations.delete.modal.title.type",
          defaultMessage: "Location",
        })}
        onConfirm={onDeleteLocation}
        onCancel={onCancelDelete}
      />
    </div>
  )
}
