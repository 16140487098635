import type { ItemCardModel } from "../../../../../components/ItemCard"
import { useGeneralContext } from "../../../../../shared/contexts/StoreProvider"
import paths from "../../../../../shared/routes/paths"
import Button from "../../../../../ui/Button"
import Container from "../../../../../ui/Container"
import { showGraphqlErrors } from "../../../../../ui/ErrorList/ErrorList"
import ModalFull from "../../../../../ui/ModalFull"
import Spacer from "../../../../../ui/Spacer"
import notification from "../../../../../ui/notification"
import { SearchItemsModal } from "../../../../Restaurants/MenuItems/components/SearchItemsModal/SearchItemsModal"
import { useCreateTaxMutation } from "../CreateTax/GraphQL/createTax.generated"
import TaxComboItemForm from "../TaxForm/TaxComboItemForm"
import TaxInformationForm from "../TaxForm/TaxInformationForm"
import TaxLocationsForm from "../TaxForm/TaxLocationsForm"
import { CreateItemTaxResolver } from "./CreateItemTax.yup"
import { useCreateItemTaxLocationMutation } from "./GraphQL/createItemTaxLocation.generated"
import type { IItemTaxModel, ITaxItem } from "./hookforms.interfaces"
import get from "lodash/get"
import React, { useState } from "react"
import { FormProvider, useFieldArray, useForm } from "react-hook-form"
import { useIntl } from "react-intl"
import { useHistory } from "react-router-dom"

type CreateItemTaxProps = {
  defaultValues?: Record<string, unknown>
}

export const CreateItemTax: React.FC<CreateItemTaxProps> = ({
  defaultValues,
}) => {
  const {
    state: {
      currentRestaurant: { uuid: restaurantUUID },
    },
  } = useGeneralContext()

  const intl = useIntl()
  const { goBack } = useHistory()
  const { settings } = paths

  const [displayItemSearch, setDisplayItemSearch] = useState<
    boolean | undefined
  >()

  const formMethods = useForm<IItemTaxModel>({
    mode: "all",
    resolver: CreateItemTaxResolver,
    defaultValues: {
      isGeneral: false,
      applyAllLocations: false,
      applyFutureLocations: false,
      ...defaultValues,
    },
  })

  const {
    control,
    handleSubmit,
    setValue,
    formState: { isValid, isDirty },
  } = formMethods

  const { fields: itemList, append: appendItem } = useFieldArray({
    control,
    name: "items",
  })

  const [createGeneralTax, { loading }] = useCreateTaxMutation()
  const [createLocationItemTax, createLocationItemTaxState] =
    useCreateItemTaxLocationMutation()

  const onCreateItemTax = async ({
    name,
    taxRate,
    description,
    applyFutureLocations,
    locations,
    items,
  }: IItemTaxModel) => {
    try {
      if (items.length === 0) {
        return notification({
          description: intl.formatMessage({
            id: "restaurants.taxes.form.section.3.description",
            defaultMessage: "Add items to apply this tax",
          }),
          type: "error",
        })
      }

      const { data } = await createGeneralTax({
        variables: {
          data: {
            restaurantUUID,
            name,
            ...(!!description && { description }),
            taxRate,
            applyFutureLocations,
            isGeneral: false,
          },
        },
      })

      if (locations?.length) {
        const createdTaxUUID = get(data, "createTax.uuid")

        await createLocationItemTax({
          variables: {
            data: {
              taxUUID: createdTaxUUID,
              locationUUIDs: locations,
              itemUUIDs: items.map(({ uuid }) => uuid),
            },
          },
        })
      }

      notification({
        description: intl.formatMessage({
          id: "restaurants.taxes.item.form.save.message",
          defaultMessage: "Your item tax was created",
        }),
        type: "success",
      })
      goBack()
    } catch (error) {
      showGraphqlErrors(error)
    }
  }

  const handleSelectedItems = (selectedItems: ItemCardModel[]) => {
    setValue("items", [])
    appendItem(selectedItems as Array<ITaxItem>)
    setDisplayItemSearch(false)
  }

  const onCancelSearch = () => setDisplayItemSearch(false)

  return (
    <FormProvider {...formMethods}>
      <ModalFull
        id="create-item-tax-modal"
        goBackPath={settings.restaurant.taxes}
        title={intl.formatMessage({
          id: "restaurants.taxes.form.new.title",
          defaultMessage: "New Taxes",
        })}
        visible
        closable
      >
        <form
          onSubmit={handleSubmit(onCreateItemTax)}
          role="form"
          aria-label="create-item-tax-form"
        >
          <TaxInformationForm />
          <Spacer size={64} />
          <TaxLocationsForm />
          <Spacer size={64} />
          <TaxComboItemForm
            setDisplayItemSearch={setDisplayItemSearch}
            itemList={itemList}
          />
          <Spacer size={64} />

          <Container display="flex" justifyContent="flex-start">
            <Button
              loading={loading || createLocationItemTaxState.loading}
              title={intl.formatMessage({
                id: "restaurants.taxes.item.form.save.button",
                defaultMessage: "Save Information",
              })}
              onClick={handleSubmit(onCreateItemTax)}
              hierarchy="secondary"
              disabled={!isValid || !isDirty}
            />
          </Container>
          <button type="submit" className="hidden" />
        </form>
      </ModalFull>
      {displayItemSearch && (
        <SearchItemsModal
          visible={displayItemSearch}
          onCancel={onCancelSearch}
          selectedItems={itemList.map(({ uuid, name, src }) => ({
            uuid,
            name,
            src,
          }))}
          onSave={handleSelectedItems}
        />
      )}
    </FormProvider>
  )
}
