import type { CountryCode, PhoneNumber } from "libphonenumber-js"
import { parsePhoneNumber as parsePhoneNumberLib } from "libphonenumber-js"

const isPhoneNumber = (value: string, region?: CountryCode): boolean => {
  if (typeof value !== "string" || value.trim() !== value) {
    return false
  }

  try {
    const phoneNumber = parsePhoneNumber(value, region)

    if (region && phoneNumber.country !== region) {
      return false
    }

    return phoneNumber.isValid()
  } catch {
    return false
  }
}

const parsePhoneNumber = (
  phoneNumber: string,
  countryCode: CountryCode = "US"
): PhoneNumber => {
  return parsePhoneNumberLib(phoneNumber, countryCode)
}

const parseNationalPhoneNumber = (phoneNumber: string) => {
  const parsedPhoneNumber = parsePhoneNumber(phoneNumber)

  return parsedPhoneNumber.formatNational()
}

export { parsePhoneNumber, isPhoneNumber, parseNationalPhoneNumber }
