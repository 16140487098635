import type { DeliveryIntegrationWithLocationsModel } from "../../../../../shared/graphql/generated/types"
import ConfirmationModal from "../../../../../ui/ConfirmationModal"
import Container from "../../../../../ui/Container"
import { showGraphqlErrors } from "../../../../../ui/ErrorList"
import DropDownAction from "../../../../../ui/Header/DropdownAction"
import Icon from "../../../../../ui/Icon"
import HighlightedText from "../../../../../ui/Typography/HighlightedText"
import Text from "../../../../../ui/Typography/Text"
import notification from "../../../../../ui/notification"
import { GetDeliveryLocationsDocument } from "../../../Locations/LocationDelivery/LocationDeliveryIntegrations/GraphQL/getDeliveryLocations.generated"
import { useUpdateDeliveryIntegrationMutation } from "../EditDeliveryIntegration/GraphQL/updateDeliveryIntegration.generated"
import { useDeleteDeliveryIntegrationMutation } from "../GraphQL/deleteDeliveryIntegration.generated"
import type { IDeliveryIntegrationsTableModel } from "../interfaces/deliveryIntegrationsTable.interfaces"
import { Menu } from "antd"
import get from "lodash/get"
import React, { useState } from "react"
import { useIntl } from "react-intl"
import { FormattedMessage } from "react-intl"

type DropdownDeliveryIntegrationsProps = {
  record: IDeliveryIntegrationsTableModel
}

export const DropdownDeliveryIntegration: React.FC<DropdownDeliveryIntegrationsProps> =
  ({ record }) => {
    const intl = useIntl()
    const [showDeleteConfirmModal, setShowDeleteConfirmModal] =
      useState<boolean>(false)
    const [showLocationsWarning, setShowLocationsWarning] =
      useState<boolean>(false)
    const [
      showActivateDeactivateConfirmModal,
      setShowActivateDeactivateConfirmModal,
    ] = useState<boolean>(false)

    const dependentLocations = record.relatedLocations?.filter(
      ({ isDefault }) => isDefault
    )

    const [
      updateDeliveryIntegration,
      { loading: isDeliveryIntegrationUpdating },
    ] = useUpdateDeliveryIntegrationMutation({
      refetchQueries: [GetDeliveryLocationsDocument],
      update: (cache, result) => {
        const data = result.data?.updateDeliveryIntegration

        if (data) {
          const newValue: DeliveryIntegrationWithLocationsModel = {
            uuid: data.uuid,
            apiKey: data.apiKey,
            integrationLabel: data.integrationLabel,
            integrationName: data.integrationName,
            isActive: data.isActive,
            issues: data.issues,
            relatedLocations: data.relatedLocations,
          }

          cache.modify({
            fields: {
              getDeliveryIntegrationsByRestaurant: (prev) => {
                const previous =
                  prev as Array<DeliveryIntegrationWithLocationsModel>

                return previous.map((current) => {
                  if (
                    current.uuid === result.data?.updateDeliveryIntegration.uuid
                  ) {
                    return newValue
                  }

                  return current
                })
              },
            },
          })
        }
      },
    })

    const [
      deleteDeliveryIntegration,
      { loading: isDeliveryIntegrationDeleting },
    ] = useDeleteDeliveryIntegrationMutation({
      refetchQueries: [GetDeliveryLocationsDocument],
      update: (cache, result) => {
        cache.modify({
          fields: {
            getDeliveryIntegrationsByRestaurant: (prev) => {
              const previous =
                prev as Array<DeliveryIntegrationWithLocationsModel>

              return previous.filter(
                (current) =>
                  current.uuid !== result.data?.deleteDeliveryIntegration.uuid
              )
            },
          },
        })
      },
    })

    const onShowDeleteConfirmationModal = () => {
      if (dependentLocations?.length) {
        setShowLocationsWarning(true)

        return
      }

      setShowDeleteConfirmModal(true)
    }

    const onCloseDeleteConfirmationModal = () => {
      setShowDeleteConfirmModal(false)
    }

    const onCloseWarning = () => {
      setShowLocationsWarning(false)
    }

    const onShowActivateDeactivateConfirmationModal = () => {
      if (record.isActive && dependentLocations?.length) {
        setShowLocationsWarning(true)

        return
      }

      setShowActivateDeactivateConfirmModal(true)
    }

    const onCloseActivateDeactivateConfirmationModal = () => {
      setShowActivateDeactivateConfirmModal(false)
    }

    const onConfirmDelete = async () => {
      if (!record.uuid) {
        return
      }

      try {
        const deletedDeliveryIntegration = await deleteDeliveryIntegration({
          variables: {
            uuid: record.uuid,
          },
        })
        const deliveryIntegrationUUID = get(
          deletedDeliveryIntegration,
          "data.deleteDeliveryIntegration.uuid"
        )

        if (deliveryIntegrationUUID) {
          notification({
            description: intl.formatMessage({
              id: "settings.restaurant.delivery.integration.delete.delivery.integration.modal.success.message",
              defaultMessage: "Your delivery integration was deleted",
            }),
            type: "success",
          })

          onCloseDeleteConfirmationModal()
        }
      } catch (error) {
        showGraphqlErrors(error)
      }
    }

    const onConfirmationActivateDeactivate = async () => {
      try {
        await updateDeliveryIntegration({
          variables: {
            data: { uuid: record.uuid, isActive: !record.isActive },
          },
        })

        notification({
          description: intl.formatMessage({
            id: "settings.restaurant.delivery.integration.update.delivery.integration.modal.success.message",
            defaultMessage: "Your delivery integration was updated",
          }),
          type: "success",
        })
      } catch (error) {
        showGraphqlErrors(error)
      }
    }

    return (
      <Container display="flex" justifyContent="flex-end">
        <DropDownAction
          placement="bottomRight"
          overlay={
            <Container>
              <Menu role="menu-more-options-device" selectedKeys={[]}>
                <Menu.Item
                  key="change-integration-status"
                  onClick={onShowActivateDeactivateConfirmationModal}
                >
                  <Text size="s">
                    {intl.formatMessage(
                      {
                        id: "settings.restaurant.delivery.integration.dropdown.item.activate.or.deactivate.integration",
                        defaultMessage:
                          "{isActive,plural,=0 {Activate} other {Deactivate}}",
                      },
                      {
                        isActive: Number(record.isActive),
                      }
                    )}
                  </Text>
                </Menu.Item>
                <Menu.Item
                  key="delete-integration"
                  onClick={onShowDeleteConfirmationModal}
                >
                  <Text size="s">
                    {intl.formatMessage({
                      id: "settings.restaurant.delivery.integration.dropdown.item.delete.integration",
                      defaultMessage: "Delete Integration",
                    })}
                  </Text>
                </Menu.Item>
              </Menu>

              <ConfirmationModal
                visible={showLocationsWarning}
                title={intl.formatMessage({
                  id: "settings.restaurant.delivery.integration.delete.delivery.integration.modal.warning.title",
                  defaultMessage: "Warning!",
                })}
                subtitle={
                  <FormattedMessage
                    id="settings.restaurant.delivery.integration.delete.delivery.integration.modal.warning.description"
                    defaultMessage="You can not remove or deactivate this integration because is set as default in the following locations: {locationsNames}."
                    values={{
                      locationsNames: (
                        <HighlightedText
                          size="m"
                          weight="bold"
                          color="Neutral8"
                        >
                          {dependentLocations
                            ?.map(({ name }) => name)
                            .join(", ")}
                        </HighlightedText>
                      ),
                    }}
                  />
                }
                onCancel={onCloseWarning}
                cancelButtonText={intl.formatMessage({
                  id: "components.modal.ok.button",
                  defaultMessage: "OK",
                })}
              />
              <ConfirmationModal
                visible={showDeleteConfirmModal}
                itemType={intl.formatMessage({
                  id: "settings.restaurant.delivery.integration.delete.delivery.integration.modal.title.type",
                  defaultMessage: "Integration",
                })}
                itemName={record.integrationLabel}
                onConfirm={onConfirmDelete}
                onCancel={onCloseDeleteConfirmationModal}
                loading={isDeliveryIntegrationDeleting}
              />

              {showActivateDeactivateConfirmModal && (
                <ConfirmationModal
                  visible={showActivateDeactivateConfirmModal}
                  onConfirm={onConfirmationActivateDeactivate}
                  onCancel={onCloseActivateDeactivateConfirmationModal}
                  cancelButtonText={intl.formatMessage({
                    id: "components.confirmation.modal.cancel.button",
                    defaultMessage: "No",
                  })}
                  confirmButtonText={intl.formatMessage({
                    id: "components.confirmation.modal.confirm.button",
                    defaultMessage: "Yes",
                  })}
                  title={intl.formatMessage(
                    {
                      id: "settings.restaurant.delivery.integration.activate.deactivate.delivery.integration.modal.title",
                      defaultMessage:
                        "Are you sure to {isActive,plural,=0 {activate} other {deactivate}} this Integration?",
                    },
                    {
                      isActive: Number(record.isActive),
                    }
                  )}
                  subtitle={intl.formatMessage(
                    {
                      id: "settings.restaurant.delivery.integration.activate.deactivate.delivery.integration.modal.subtitle",
                      defaultMessage:
                        "{isActive,plural,=0 {Activating} other {Deactivating}} this integration will affect your locations",
                    },
                    {
                      isActive: Number(record.isActive),
                    }
                  )}
                  loading={isDeliveryIntegrationUpdating}
                />
              )}
            </Container>
          }
        >
          <Icon
            remixiconClass="ri-more-fill"
            cursor="pointer"
            color="Neutral5"
            size={16}
          />
        </DropDownAction>
      </Container>
    )
  }
