type Requirements = {
  alternatives?: string[]
  currentlyDue?: string[]
  errors?: string[]
  eventuallyDue?: string[]
  pastDue?: string[]
  pendingVerification?: string[]
}

type ArrayValidationProps = {
  requirements?: Requirements
  futureRequirements?: Requirements
}

export const createArrayValidationObject = ({
  requirements,
  futureRequirements,
}: ArrayValidationProps) => {
  return [
    requirements?.alternatives ?? [],
    requirements?.currentlyDue ?? [],
    requirements?.errors ?? [],
    requirements?.eventuallyDue ?? [],
    requirements?.pastDue ?? [],
    requirements?.pendingVerification ?? [],
    futureRequirements?.alternatives ?? [],
    futureRequirements?.currentlyDue ?? [],
    futureRequirements?.errors ?? [],
    futureRequirements?.eventuallyDue ?? [],
    futureRequirements?.pastDue ?? [],
    futureRequirements?.pendingVerification ?? [],
  ]
}

export const isRequirementsArraysNonEmpty = ({
  requirements,
  futureRequirements,
}: ArrayValidationProps): boolean => {
  const arrays = createArrayValidationObject({
    requirements,
    futureRequirements,
  })
  return arrays.some((array) => array.length > 0)
}
