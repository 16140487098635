import AddButton from "../../../../../ui/AddButton"
import Container from "../../../../../ui/Container"
import SectionHeader from "../../../../../ui/SectionHeader"
import NewDeliveryZoneModal from "./ModalDeliveryZones/NewDeliveryZoneModal"
import TableDeliveryZones from "./TableDeliveryZones"
import { useState } from "react"
import { useIntl } from "react-intl"

const LocationDeliveryZones = () => {
  const intl = useIntl()

  const [displayModalNewDeliveryZone, setDisplayModalNewDeliveryZone] =
    useState<boolean>(false)

  const onShowModalNewDeliveryZone = () => {
    setDisplayModalNewDeliveryZone(true)
  }

  const onCloseNewDeliveryZone = () => {
    setDisplayModalNewDeliveryZone(false)
  }

  return (
    <Container width="100%">
      <SectionHeader
        title={intl.formatMessage({
          id: "settings.locations.delivery.section.zone.title",
          defaultMessage: "Delivery zones",
        })}
        description={intl.formatMessage({
          id: "settings.locations.delivery.section.zone.description",
          defaultMessage:
            "Add zones with specific rates for this location, based on distance.",
        })}
        actionButton={
          <AddButton
            hierarchy="secondary"
            onClick={onShowModalNewDeliveryZone}
          />
        }
      />

      <TableDeliveryZones />

      <NewDeliveryZoneModal
        visible={displayModalNewDeliveryZone}
        onClose={onCloseNewDeliveryZone}
      />
    </Container>
  )
}

export default LocationDeliveryZones
