import { useGeneralContext } from "../../../../../shared/contexts/StoreProvider"
import { ExternalIntegrationEnum } from "../../../../../shared/graphql/generated/types"
import { useSearchParams } from "../../../../../shared/hooks/useSearchParams"
import paths from "../../../../../shared/routes/paths"
import {
  INTEGRATION_ACTION_FILTER_NAME,
  OAUTH_CODE_FILTER_NAME,
} from "../../../../../shared/utils/constant/searchParams"
import Spinner from "../../../../../ui/Spinner"
import { ActionTypes } from "../../../../Restaurants/actions"
import { integrationsFilterValues } from "../config"
import { Center } from "@mantine/core"
import { useEffect, useMemo } from "react"
import { useHistory } from "react-router-dom"

export const SquareOAuth = () => {
  const { getParam } = useSearchParams()
  const { dispatch } = useGeneralContext()
  const history = useHistory()

  const state = getParam("state")
  const oAuthCode = getParam("code")

  const [locationConnected, connectionType] = (useMemo(() => {
    return state ? state.split(",") : null
  }, [state]) as [string | null, ExternalIntegrationEnum | null]) || [
    null,
    null,
  ]

  const actionType = useMemo(() => {
    const validType = connectionType
      ? Object.values(ExternalIntegrationEnum).includes(connectionType)
      : false
    return validType ? connectionType : null
  }, [connectionType])

  useEffect(() => {
    const redirectionData = !!locationConnected && !!connectionType
    if (redirectionData) {
      dispatch({
        type: ActionTypes.SetCurrentState,
        payload: {
          restaurantUUID: locationConnected,
          refirectToRoot: false,
        },
      })
    }

    const params = new URLSearchParams()
    actionType &&
      params.set(
        INTEGRATION_ACTION_FILTER_NAME,
        integrationsFilterValues[actionType]
      )
    oAuthCode && params.set(OAUTH_CODE_FILTER_NAME, oAuthCode)

    history.replace({
      pathname: locationConnected
        ? paths.settings.locations.createSquareIntegrationByLocation(
            locationConnected
          )
        : paths.general.notFound,
      ...(locationConnected && { search: params.toString() }),
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Center h="100%">
      <Spinner />
    </Center>
  )
}
