import type { LayoutProps } from "antd"
import { Layout } from "antd"
import styled, { css } from "styled-components"

export interface IStyledLayoutProps extends LayoutProps {
  $flexDirection?: "column" | "column-reverse" | "row" | "row-reverse"
}

export const StyledLayout = styled(Layout)<IStyledLayoutProps>`
  ${({ $flexDirection }) =>
    !!$flexDirection &&
    css`
      flex-direction: ${$flexDirection};
      width: 100%;
      height: 100%;
      display: flex;
      background: ${({ theme }) => theme.colors["Neutral2"]};
      overflow-x: hidden;
      overflow-y: auto;
    `}
`
