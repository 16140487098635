import type { TypographyTextProps } from "../Text"
import { StyledText } from "../Text"
import type CSS from "csstype"
import React from "react"
import type { IBody } from "styled-components"
import styled, { css, useTheme } from "styled-components"

type HighlightedTextProps = TypographyTextProps & {
  size?: keyof IBody
  cursor?: CSS.Property.Cursor
}

export const HighlightedText: React.FC<HighlightedTextProps> = (props) => {
  const { size = "m", weight = "regular" } = props
  const theme = useTheme()
  const style = theme.text.body[size][weight]

  return <StyledHighlightedText {...style} {...props} />
}

const StyledHighlightedText = styled(StyledText)<HighlightedTextProps>`
  ${({ theme, color = "Primary5" }) =>
    css`
      color: ${theme.colors[`${color}`]};
      line-height: 20px !important;
    `}

  ${({ cursor }) =>
    cursor &&
    css`
      &:hover {
        cursor: ${cursor};
      }
    `}
`
