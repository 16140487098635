import type { DeliveryIntegrationWithLocationsModel } from "../../../../../shared/graphql/generated/types"
import { useClickOutside } from "../../../../../shared/hooks/useClickOutside"
import Button from "../../../../../ui/Button"
import Container from "../../../../../ui/Container"
import { showGraphqlErrors } from "../../../../../ui/ErrorList"
import SimpleInput from "../../../../../ui/Inputs/SimpleInput"
import Text from "../../../../../ui/Typography/Text"
import notification from "../../../../../ui/notification"
import { GetDeliveryLocationsDocument } from "../../../Locations/LocationDelivery/LocationDeliveryIntegrations/GraphQL/getDeliveryLocations.generated"
import { EditDeliveryIntegrationResolver } from "./EditDeliveryIntegrationResolver.yup"
import { useUpdateDeliveryIntegrationMutation } from "./GraphQL/updateDeliveryIntegration.generated"
import type { IUpdateDeliveryIntegration } from "./hookforms.interfaces"
import classnames from "classnames"
import React, { useState } from "react"
import { Controller, useForm } from "react-hook-form"
import { useIntl } from "react-intl"
import styled from "styled-components"

type EditDeliveryIntegrationProps = {
  integrationLabel: string
  uuid: string
}

export const EditDeliveryIntegration: React.FC<EditDeliveryIntegrationProps> =
  ({ integrationLabel, uuid }) => {
    const intl = useIntl()
    const editContainerRef = React.useRef(null)
    const [focused, setFocused] = useState<boolean>()

    const unfocus = () => setFocused(false)
    const focus = () => setFocused(true)

    useClickOutside(editContainerRef, unfocus)

    const [updateDeliveryIntegration, { loading }] =
      useUpdateDeliveryIntegrationMutation({
        refetchQueries: [GetDeliveryLocationsDocument],
        update: (cache, result) => {
          const data = result.data?.updateDeliveryIntegration

          if (data) {
            const newValue: DeliveryIntegrationWithLocationsModel = {
              uuid: data.uuid,
              apiKey: data.apiKey,
              integrationLabel: data.integrationLabel,
              integrationName: data.integrationName,
              isActive: data.isActive,
              issues: data.issues,
              relatedLocations: data.relatedLocations,
            }

            cache.modify({
              fields: {
                getDeliveryIntegrationsByRestaurant: (prev) => {
                  const previous =
                    prev as Array<DeliveryIntegrationWithLocationsModel>

                  return previous.map((current) => {
                    if (
                      current.uuid ===
                      result.data?.updateDeliveryIntegration.uuid
                    ) {
                      return newValue
                    }

                    return current
                  })
                },
              },
            })
          }
        },
      })

    const {
      handleSubmit,
      control,
      formState: { errors, isValid },
    } = useForm<IUpdateDeliveryIntegration>({
      mode: "all",
      resolver: EditDeliveryIntegrationResolver,
      defaultValues: {
        integrationLabel,
      },
    })

    const saveDeliveryIntegration = async ({
      integrationLabel: label,
    }: IUpdateDeliveryIntegration) => {
      try {
        await updateDeliveryIntegration({
          variables: { data: { uuid, integrationLabel: label } },
        })

        notification({
          description: intl.formatMessage({
            id: "settings.restaurant.delivery.integration.update.delivery.integration.modal.success.message",
            defaultMessage: "Your delivery integration was updated",
          }),
          type: "success",
        })
      } catch (error) {
        showGraphqlErrors(error)
      }
    }

    const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault()
      handleSubmit(saveDeliveryIntegration)()
    }

    return (
      <form onSubmit={onSubmit} role="update-delivery-integration-form">
        <StyledContainer
          reference={editContainerRef}
          className={classnames("edit-delivery-integration-container", {
            focused,
          })}
          display="flex"
          flexDirection="row"
          justifyContent="flex-start"
          gap="16px"
        >
          <Controller
            control={control}
            name="integrationLabel"
            render={({ field: integrationLabelField }) => {
              return (
                <Container
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  gap="16px"
                >
                  <div
                    className={classnames("integration-label-input", {
                      focused,
                    })}
                  >
                    <SimpleInput
                      {...integrationLabelField}
                      aria-label="integrationLabel"
                      hasError={!!errors?.integrationLabel}
                      requirement="required"
                      onFocus={focus}
                      onMouseOverCapture={() => {
                        if (integrationLabel !== integrationLabelField.value) {
                          integrationLabelField.onChange({
                            target: { value: integrationLabel },
                          })
                        }
                      }}
                      capitalizeFirstLetter={false}
                    />
                  </div>

                  <div className="integration-label">
                    <Text size="s">{integrationLabel}</Text>
                  </div>
                </Container>
              )
            }}
          />
          <div className="integration-label-button">
            <Button
              type="submit"
              title="Save"
              loading={loading}
              disabled={!isValid}
            />
          </div>
        </StyledContainer>
      </form>
    )
  }

const StyledContainer = styled(Container)`
  &:hover,
  &.focused {
    .integration-label {
      display: none;
    }

    .integration-label-input {
      display: initial;
    }

    .integration-label-button {
      display: initial;
    }
  }

  .integration-label {
    height: 28px;
    display: initial;

    span {
      line-height: 28px;
    }
  }

  .integration-label-input {
    display: none;
  }

  .integration-label-button {
    display: none;
  }
`
