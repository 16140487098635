import { useGeneralContext } from "../../../../shared/contexts/StoreProvider"
import DataTable from "../../../../ui/DataTable"
import EmptyState from "../../../../ui/EmptyState"
import DetailPage from "../../../../ui/Layouts/MainLayout/DetailPage"
import PageTitle from "../../../../ui/PageTitle"
import type { ILocationsParams } from "../hookforms.interfaces"
import type { GetAllDevicesQuery } from "./GraphQl/getAllDevices.generated"
import { useGetAllDevicesQuery } from "./GraphQl/getAllDevices.generated"
import type { IDeviceRowModel } from "./datatable.interfaces"
import devicesTableConfig from "./utils/devices-list.util"
import get from "lodash/get"
import React, { useCallback } from "react"
import { useIntl } from "react-intl"
import { useParams } from "react-router-dom"
import styled from "styled-components"

export const Devices: React.FC = () => {
  const intl = useIntl()
  const { locationUUID } = useParams<ILocationsParams>()
  const [columns] = devicesTableConfig()

  const {
    state: {
      currentRestaurant: { uuid: restaurantUUID },
    },
  } = useGeneralContext()

  const { data, loading, error, fetchMore, refetch } = useGetAllDevicesQuery({
    variables: { restaurantUUID, locationUUID, isActive: true },
    notifyOnNetworkStatusChange: true,
    returnPartialData: true,
    skip: !restaurantUUID || !locationUUID,
  })

  const devicesList: IDeviceRowModel[] = get(data, "getAllDevices.results", [])
  const hasNextPage: boolean = get(data, "getAllDevices.hasNextPage", false)
  const endCursor = get(data, "getAllDevices.endCursor", undefined)

  const fetchMoreDevices = useCallback(async () => {
    await fetchMore({
      variables: { restaurantUUID, locationUUID, after: endCursor },
      updateQuery: (prev: GetAllDevicesQuery, { fetchMoreResult }) => {
        if (
          !fetchMoreResult ||
          prev?.getAllDevices?.endCursor ===
            fetchMoreResult?.getAllDevices?.endCursor
        ) {
          return prev
        }

        return Object.assign({}, prev, {
          getAllDevices: {
            ...fetchMoreResult.getAllDevices,
            results: [
              ...(prev.getAllDevices.results as IDeviceRowModel[]),
              ...(fetchMoreResult.getAllDevices.results as IDeviceRowModel[]),
            ],
          },
        })
      },
    })
  }, [restaurantUUID, locationUUID, endCursor, fetchMore])

  const retry = () => refetch()

  return (
    <DetailPage>
      <PageTitle
        title={intl.formatMessage({
          id: "restaurants.devices.info.title",
          defaultMessage: "Devices connected",
        })}
        description={intl.formatMessage({
          id: "restaurants.devices.info.description",
          defaultMessage:
            "Here you will find a list of all the devices that are logged in to your Crmb Account",
        })}
      />
      <StyledDevicesContent role="devices-table">
        <DataTable
          loading={loading}
          dataSource={devicesList}
          columns={columns}
          hasNextPage={hasNextPage}
          fetchMore={fetchMoreDevices}
          error={error}
          errorRefetch={retry}
          emptyMessage={
            <EmptyState
              title={intl.formatMessage({
                id: "restaurants.devices.empty.title",
                defaultMessage: "No devices added yet",
              })}
              description={intl.formatMessage({
                id: "restaurants.devices.empty.description",
                defaultMessage: "You can add one by clicking the plus button",
              })}
            />
          }
          substactPixelesToScrollHeight={312}
        />
      </StyledDevicesContent>
    </DetailPage>
  )
}

const StyledDevicesContent = styled.div`
  margin-top: 48px;
`
