import Icon from "../../../../ui/Icon"
import { DropdownTables } from "./DropdownTables"
import type { ILocationTableZoneModel } from "./TableForm/hookforms.interfaces"
import type { Dispatch, SetStateAction } from "react"
import { FormattedMessage } from "react-intl"

type TableListProps<T> = {
  onQRClick?: (qrPath?: string, record?: T) => void
  deletedTable?: Dispatch<SetStateAction<string | undefined>>
}

const tableColumns = <T,>(props?: TableListProps<T>) => {
  const columns = [
    {
      dataIndex: "name",
      title: (
        <FormattedMessage
          id="restaurants.location.tables.datatable.header.name"
          defaultMessage="Table Name"
        />
      ),
    },
    {
      dataIndex: "description",
      title: (
        <FormattedMessage
          id="restaurants.location.tables.datatable.header.description"
          defaultMessage="Description"
        />
      ),
    },
    {
      dataIndex: "capacity",
      title: (
        <FormattedMessage
          id="restaurants.location.tables.datatable.header.capacity"
          defaultMessage="Capacity"
        />
      ),
    },
    {
      dataIndex: "tableZone",
      title: (
        <FormattedMessage
          id="restaurants.location.tables.datatable.header.zone"
          defaultMessage="Zone"
        />
      ),
      render: (tableZone: { name: string; uuid: string }) => {
        return <div className="ellipsis">{tableZone?.name}</div>
      },
    },
    {
      dataIndex: "qrPath",
      title: (
        <FormattedMessage
          id="restaurants.location.tables.datatable.header.qr"
          defaultMessage="QR Code"
        />
      ),
      render: (qrPath?: string, record?: T) => (
        <Icon
          remixiconClass="ri-qr-code-line"
          color="Neutral6"
          cursor="pointer"
          onClick={() => {
            props?.onQRClick && props.onQRClick(qrPath, record)
          }}
        />
      ),
    },
    {
      dataIndex: "uuid",
      title: "",
      width: "48px",
      render: (_: string, record: T) => {
        return (
          <DropdownTables
            record={record as unknown as ILocationTableZoneModel}
            deletedTable={props?.deletedTable}
          />
        )
      },
    },
  ]

  return [columns]
}

export default tableColumns
