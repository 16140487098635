import yup from "../../../../../../../shared/yup"
import { yupResolver } from "@hookform/resolvers/yup"

// This is the maximum value supported by the database
const MAX_DISTANCE_LIMIT = 1_334_388

const DeliveryZoneSchema = yup.object().shape({
  price: yup.number().moreThan(0).required(),
  maxDistance: yup.number().moreThan(0).lessThan(MAX_DISTANCE_LIMIT).required(),
})

export const DeliveryZoneResolver = yupResolver(DeliveryZoneSchema)
