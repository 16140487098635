import milesToMeters from "../../../../../../../shared/utils/helpers/milesToMeters"
import Button from "../../../../../../../ui/Button"
import Container from "../../../../../../../ui/Container"
import { showGraphqlErrors } from "../../../../../../../ui/ErrorList"
import Modal from "../../../../../../../ui/Modal"
import type { IModalProps } from "../../../../../../../ui/Modal"
import type { ILocationDeliveryParams } from "../../../hookforms.interfaces"
import { useCreateDeliveryPriceMutation } from "../../GraphQL/createDeliveryPrice.generated"
import FormDeliveryZoneModal from "../FormDeliveryZoneModal"
import { DeliveryZoneResolver } from "../FormDeliveryZoneModal/FormDeliveryZoneModal.yup"
import type { IDeliveryZoneForm } from "../FormDeliveryZoneModal/interfaces/hookforms.interfaces"
import React from "react"
import { FormProvider, useForm } from "react-hook-form"
import { useIntl } from "react-intl"
import { useParams } from "react-router-dom"

type NewDeliveryZoneModalProps = Omit<IModalProps, "onCancel"> & {
  onClose: () => void
}

const NewDeliveryZoneModal: React.FC<NewDeliveryZoneModalProps> = ({
  onClose,
  ...props
}) => {
  const intl = useIntl()
  const { locationUUID } = useParams<ILocationDeliveryParams>()

  const NewDeliveryZoneModalMethods = useForm<IDeliveryZoneForm>({
    mode: "all",
    resolver: DeliveryZoneResolver,
  })

  const {
    formState: { isValid },
    reset,
    handleSubmit,
  } = NewDeliveryZoneModalMethods

  const [createDeliveryZone, { loading }] = useCreateDeliveryPriceMutation({
    update: (cache, result) => {
      cache.modify({
        fields: {
          getDeliveryPricesByLocation: (data) => {
            return [...data, result.data?.createDeliveryPrice]
          },
        },
      })
    },
  })

  const onCreateDeliveryZone = async (formData: IDeliveryZoneForm) => {
    try {
      await createDeliveryZone({
        variables: {
          locationUUID,
          maxDistance: milesToMeters(formData.maxDistance ?? 0),
          price: formData.price ?? 0,
        },
      })

      reset()
      onClose()
    } catch (error) {
      showGraphqlErrors(error)
    }
  }

  return (
    <FormProvider {...NewDeliveryZoneModalMethods}>
      <Modal
        {...props}
        title={intl.formatMessage({
          id: "settings.locations.delivery.zones.new.modal.title",
          defaultMessage: "New Delivery Zone",
        })}
        width="520px"
        onCancel={onClose}
        closable={false}
        footer={
          <Container
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
            gap="8px"
          >
            <Button
              title={intl.formatMessage({
                id: "settings.locations.delivery.zones.new.modal.button.cancel",
                defaultMessage: "Cancel",
              })}
              hierarchy="secondary"
              onClick={onClose}
              size="large"
              disabled={loading}
            />

            <Button
              title={intl.formatMessage({
                id: "settings.locations.delivery.zones.new.modal.button.create",
                defaultMessage: "Add",
              })}
              size="large"
              disabled={!isValid}
              onClick={handleSubmit(onCreateDeliveryZone)}
              loading={loading}
            />
          </Container>
        }
        shouldCloseOnClickOutside
        destroyOnClose
        centered
      >
        <FormDeliveryZoneModal />
      </Modal>
    </FormProvider>
  )
}

export default NewDeliveryZoneModal
