import Container from "../Container"
import { LabelFieldSkeleton } from "../InputLabel"
import { Skeleton } from "antd"
import styled from "styled-components"

export const ImageIconFieldSkeleton = () => {
  return (
    <Container display="flex" flexDirection="column" gap="16px">
      <StyledAvatarFieldSkeleton className="avatar-field-skeleton">
        <LabelFieldSkeleton />
        <Skeleton.Avatar active shape="square" />
      </StyledAvatarFieldSkeleton>
    </Container>
  )
}

const StyledAvatarFieldSkeleton = styled.div`
  &.avatar-field-skeleton {
    .ant-skeleton-avatar {
      width: 128px;
      height: 128px;
    }
  }
`
