import { useGeneralContext } from "../../../../../shared/contexts/StoreProvider"
import { OrderMethodEnum } from "../../../../../shared/graphql/generated/types"
import { CustomOrderMethodTitle } from "../../../../../shared/utils/constant/orderMethod.title"
import { useGetOrderMethodSettingQuery } from "./GraphQL/getOrderMethodSettings.generated"
import { OrderMethodSettingsSkeleton } from "./components/OrderMethodSettingsSkeleton/OrderMethodSettingsSkeleton"
import OrderMethodSettingsForm from "./forms/OrderMethodSettingsForm"
import { Modal, Text, Title } from "@mantine/core"
import { useIntl } from "react-intl"

type OrderMethodModalProps = {
  opened: boolean
  onClose: () => void
  type: OrderMethodEnum
}

export const OrderMethodSettingsModal = (props: OrderMethodModalProps) => {
  const { opened, onClose, type } = props
  const intl = useIntl()

  const {
    state: {
      currentRestaurant: { name: restaurantName },
      selectedLocation: { name: locationName },
    },
  } = useGeneralContext()

  const { data: orderMethodSettingData, loading } =
    useGetOrderMethodSettingQuery({
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      variables: { orderMethod: type },
      onError: (error) => console.error(error),
    })

  return (
    <Modal
      onClose={onClose}
      size="lg"
      centered
      opened={opened}
      title={
        <>
          <Title order={4}>
            {intl.formatMessage(
              {
                id: "restaurants.locations.overview.order.method.modal.title",
                defaultMessage: "{orderMethod} Settings",
              },
              { orderMethod: CustomOrderMethodTitle[type].defaultMessage }
            )}
          </Title>
          <Text size="sm" color="gray.7" py={8}>
            {intl.formatMessage(
              {
                id: "restaurants.locations.overview.order.method.modal.title",
                defaultMessage:
                  "Preferences for {orderMethod} at {restaurantName} - {locationName} ",
              },
              {
                orderMethod: CustomOrderMethodTitle[type].defaultMessage,
                restaurantName,
                locationName,
              }
            )}
          </Text>
        </>
      }
    >
      {loading ? (
        <OrderMethodSettingsSkeleton />
      ) : (
        <OrderMethodSettingsForm
          orderMethod={type}
          responseData={orderMethodSettingData}
          onClose={onClose}
        />
      )}
    </Modal>
  )
}
