import React, { useEffect } from "react"
import type { DropResult, ResponderProvided } from "react-beautiful-dnd"
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd"

interface DraggableProps<T> {
  data: T[]
  isDragDisabled: boolean
  renderContent: (data: T, index: number) => React.ReactNode
  droppableId: string
  onDragEnd(result: DropResult, dragItem: T, provided: ResponderProvided): void
  getListStyle?: (isDraggingOver: boolean) => React.CSSProperties
}

export const DraggableList = <T,>({
  data = [],
  isDragDisabled,
  renderContent,
  droppableId,
  onDragEnd,
  getListStyle,
}: DraggableProps<T>) => {
  const [mounted, setMounted] = React.useState(false)

  const onDragFinish = (result: DropResult, provided: ResponderProvided) => {
    const dragItem = data[result.source.index]

    onDragEnd(result, dragItem, provided)
  }

  useEffect(() => {
    setMounted(true)
  }, [])

  if (!mounted || data.length === 0) return null

  return (
    <DragDropContext onDragEnd={onDragFinish}>
      <Droppable droppableId={droppableId}>
        {(droppableProvided, snapshot) => (
          <div
            {...droppableProvided.droppableProps}
            ref={droppableProvided.innerRef}
            style={getListStyle?.(snapshot.isDraggingOver)}
            className={droppableId}
          >
            {data.map((item, index) => (
              <Draggable
                key={`item-${index}`}
                index={index}
                isDragDisabled={isDragDisabled}
                draggableId={`item-${index}`}
              >
                {(draggableProvided) => (
                  <div
                    {...draggableProvided.draggableProps}
                    {...draggableProvided.dragHandleProps}
                    ref={draggableProvided.innerRef}
                  >
                    {renderContent(item, index)}
                  </div>
                )}
              </Draggable>
            ))}
            {droppableProvided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  )
}
