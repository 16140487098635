/* eslint-disable react/prop-types */
import partnershipImage from "../../../../../shared/assets/images/partnership.svg"
import { useGeneralContext } from "../../../../../shared/contexts/StoreProvider"
import { SubscriptionTierEnum } from "../../../../../shared/graphql/generated/types"
import { showGraphqlErrors } from "../../../../../ui/ErrorList"
import Modal from "../../../../../ui/Modal"
import Text from "../../../../../ui/Typography/Text"
import { useCreateCheckoutSessionMutation } from "../GraphQL/createCheckoutSession.generated"
import { currencyFormatter, formatDate } from "../plans.utils"
import * as Sentry from "@sentry/react"
import { useIntl } from "react-intl"
import styled from "styled-components"

interface GetPlanConfirmModalProps {
  isOpen: boolean
  handleClose: () => void
  planData: { date: Date; price: number; name: string }
}

export const GetPlanConfirmModal: React.FC<GetPlanConfirmModalProps> = (
  props
) => {
  const { isOpen, handleClose, planData } = props

  const intl = useIntl()
  const {
    state: {
      currentRestaurant: { uuid: currentRestaurantUuid },
    },
  } = useGeneralContext()
  const [createCheckoutSession, createCheckoutSessionMutation] =
    useCreateCheckoutSessionMutation()

  const handleConfirm = async () => {
    try {
      const { data } = await createCheckoutSession({
        variables: {
          data: {
            subscriptionTier: SubscriptionTierEnum.STANDARD,
            restaurant: {
              uuid: currentRestaurantUuid,
            },
          },
        },
      })

      if (!data) throw new Error("No data")

      const checkoutSessionURL = data.createCheckoutSession.stCheckoutSessionURL

      window.location.replace(checkoutSessionURL)
    } catch (error) {
      showGraphqlErrors(error)
      Sentry.captureException(error)
    }
  }

  return (
    <Modal
      centered
      visible={isOpen}
      onCancel={handleClose}
      onOk={handleConfirm}
      okButtonProps={{
        loading: createCheckoutSessionMutation.loading,
      }}
      title={intl.formatMessage(
        {
          id: "settings.restaurant.plans.get_plan_confirm_modal.title",
          defaultMessage: "{name} plan",
        },
        { name: planData.name }
      )}
      okText="Confirm"
      enableCloseButton={false}
      shouldCloseOnClickOutside
    >
      <BodyContainer>
        <img
          src={partnershipImage}
          width={128}
          height={128}
          alt="partnership"
          style={{ alignSelf: "center" }}
        />

        <Text size="m" color="Neutral8">
          {intl.formatMessage(
            {
              id: "settings.restaurant.plans.get_plan_confirm_modal.description",
              defaultMessage:
                "You are about to get the <strong>{name} plan</strong>. By clicking 'Confirm', your credit card will be charged <strong>{price}</strong> for each active location on <strong>{date}</strong>.",
            },
            {
              name: planData.name,
              price: currencyFormatter.format(planData.price),
              date: formatDate(planData.date),
              strong: (text) => <strong>{text}</strong>,
            }
          )}
        </Text>
      </BodyContainer>
    </Modal>
  )
}

const BodyContainer = styled.div`
  gap: 32px;
  display: flex;
  flex-direction: column;
`
