import * as Types from '../../../../../shared/graphql/generated/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetSquareAuthUrlQueryVariables = Types.Exact<{
  integrationType: Types.ExternalIntegrationEnum;
}>;


export type GetSquareAuthUrlQuery = { __typename?: 'Query', getSquareAuthUrl: string };


export const GetSquareAuthUrlDocument = gql`
    query getSquareAuthUrl($integrationType: ExternalIntegrationEnum!) {
  getSquareAuthUrl(integrationType: $integrationType)
}
    `;

/**
 * __useGetSquareAuthUrlQuery__
 *
 * To run a query within a React component, call `useGetSquareAuthUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSquareAuthUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSquareAuthUrlQuery({
 *   variables: {
 *      integrationType: // value for 'integrationType'
 *   },
 * });
 */
export function useGetSquareAuthUrlQuery(baseOptions: Apollo.QueryHookOptions<GetSquareAuthUrlQuery, GetSquareAuthUrlQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSquareAuthUrlQuery, GetSquareAuthUrlQueryVariables>(GetSquareAuthUrlDocument, options);
      }
export function useGetSquareAuthUrlLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSquareAuthUrlQuery, GetSquareAuthUrlQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSquareAuthUrlQuery, GetSquareAuthUrlQueryVariables>(GetSquareAuthUrlDocument, options);
        }
export type GetSquareAuthUrlQueryHookResult = ReturnType<typeof useGetSquareAuthUrlQuery>;
export type GetSquareAuthUrlLazyQueryHookResult = ReturnType<typeof useGetSquareAuthUrlLazyQuery>;
export type GetSquareAuthUrlQueryResult = Apollo.QueryResult<GetSquareAuthUrlQuery, GetSquareAuthUrlQueryVariables>;