import { Container, Flex, Group, Skeleton, Stack } from "@mantine/core"

export const CateringSkeleton = () => {
  return (
    <Container py={64} size="md">
      <Stack>
        <Group position="apart">
          <Skeleton h={32} w={100} />
          <Skeleton h={32} w={100} />
        </Group>
        <Stack>
          <Skeleton height={40} w="100%" />
          <Skeleton height={40} w="100%" />
        </Stack>
      </Stack>

      <Stack mt={40} spacing={40}>
        <Stack>
          <Skeleton height={30} w={100} />
          <Skeleton height={40} w="100%" />
          <Skeleton height={40} w="100%" />
        </Stack>
        <Stack>
          <Skeleton height={25} w={200} />
          <Skeleton height={20} w={250} />
          <Skeleton height={24} w={164} />
        </Stack>
        <Stack>
          {Array.from({ length: 7 }).map((_, index) => (
            <Skeleton key={index} height={56} w="100%" />
          ))}
          <Skeleton height={56} w="100%" />
        </Stack>
        <Stack>
          <Flex justify="space-between">
            <Group>
              <Skeleton height={32} w={120} />
              <Skeleton height={32} w={120} />
            </Group>
            <Skeleton height={32} w={120} />
          </Flex>
        </Stack>
      </Stack>
    </Container>
  )
}
