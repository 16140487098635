import * as Sentry from "@sentry/react"

const sentrySetup = () => {
  Sentry.init({
    ignoreErrors: ["Auth in progress"],
    dsn: process.env.REACT_APP_SENTRY_DS ?? "",
    environment: process.env.REACT_APP_ENV ?? "",
    // Performance Monitoring
    tracesSampleRate: 1, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  })
}

export default sentrySetup
