import * as Types from '../../../../../shared/graphql/generated/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetBillingSubscriptionPlansQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetBillingSubscriptionPlansQuery = { __typename?: 'Query', getBillingSubscriptionPlans: Array<{ __typename?: 'SubscriptionPlan', features: any, name: string, price: number, stLookUpKey: Types.SubscriptionTierEnum, trialDays: number, uuid: string }> };


export const GetBillingSubscriptionPlansDocument = gql`
    query getBillingSubscriptionPlans {
  getBillingSubscriptionPlans {
    features
    name
    price
    stLookUpKey
    trialDays
    uuid
  }
}
    `;

/**
 * __useGetBillingSubscriptionPlansQuery__
 *
 * To run a query within a React component, call `useGetBillingSubscriptionPlansQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBillingSubscriptionPlansQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBillingSubscriptionPlansQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetBillingSubscriptionPlansQuery(baseOptions?: Apollo.QueryHookOptions<GetBillingSubscriptionPlansQuery, GetBillingSubscriptionPlansQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBillingSubscriptionPlansQuery, GetBillingSubscriptionPlansQueryVariables>(GetBillingSubscriptionPlansDocument, options);
      }
export function useGetBillingSubscriptionPlansLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBillingSubscriptionPlansQuery, GetBillingSubscriptionPlansQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBillingSubscriptionPlansQuery, GetBillingSubscriptionPlansQueryVariables>(GetBillingSubscriptionPlansDocument, options);
        }
export type GetBillingSubscriptionPlansQueryHookResult = ReturnType<typeof useGetBillingSubscriptionPlansQuery>;
export type GetBillingSubscriptionPlansLazyQueryHookResult = ReturnType<typeof useGetBillingSubscriptionPlansLazyQuery>;
export type GetBillingSubscriptionPlansQueryResult = Apollo.QueryResult<GetBillingSubscriptionPlansQuery, GetBillingSubscriptionPlansQueryVariables>;