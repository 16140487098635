import type { TypographyTextProps } from "../../Typography/Text"
import Text from "../../Typography/Text"
import React from "react"

type BodyCellProps = Pick<TypographyTextProps, "textTransform" | "textAlign">

const BodyCell: React.FC<BodyCellProps> = ({
  children,
  textTransform,
  textAlign,
}) => {
  return (
    <td>
      <Text
        size="s"
        textTransform={textTransform}
        textAlign={textAlign}
        ellipsis
      >
        {children}
      </Text>
    </td>
  )
}

export default BodyCell
