import { ConfirmationModal } from "../../../../../../../ui/ConfirmationModal/ConfirmationModal"
import { showGraphqlErrors } from "../../../../../../../ui/ErrorList"
import DropDownAction from "../../../../../../../ui/Header/DropdownAction"
import Icon from "../../../../../../../ui/Icon"
import Text from "../../../../../../../ui/Typography/Text"
import notification from "../../../../../../../ui/notification"
import { useDeleteHolidayMutation } from "../GraphQL/deleteHoliday.generated"
import { HolidayModalForm } from "../HolidayModalForm"
import type { IHolidaysTableModel } from "./../interfaces/HolidaysTable"
import { useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import styled from "styled-components"

interface IHolidayDropdown {
  holiday: IHolidaysTableModel
  locationUUID: string
}

export const HolidayDropdown = ({
  holiday,
  locationUUID,
}: IHolidayDropdown) => {
  const [formModalVisible, setFormModalVisible] = useState(false)
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)
  const intl = useIntl()

  const [deleteHoliday, { loading: deleteHolidayLoading }] =
    useDeleteHolidayMutation({
      update: (cache, result) => {
        cache.modify({
          fields: {
            getHolidaysList: (data: IHolidaysTableModel[]) => {
              return data.filter(
                (current) => current.uuid !== result.data?.deleteHoliday.uuid
              )
            },
          },
        })
      },
      onCompleted: () => {
        notification({
          description: intl.formatMessage({
            id: "restaurants.locations.hours.of.operation.holidays.notification.delete",
            defaultMessage: "Your holiday was deleted.",
          }),
          type: "success",
        })
      },
    })

  const handleDelete = async () => {
    if (!holiday.uuid) return

    try {
      await deleteHoliday({
        variables: {
          uuid: holiday.uuid,
        },
      })
    } catch (error) {
      showGraphqlErrors(error)
    } finally {
      setShowDeleteConfirmation(false)
    }
  }

  return (
    <>
      <DropDownAction
        placement="bottomRight"
        overlay={
          <ul className="ant-menu ant-menu-root ant-menu-vertical ant-menu-light">
            <StyledMenuItem
              className="ant-menu-item"
              onClick={() => setFormModalVisible(true)}
            >
              <Icon remixiconClass="ri-edit-line" color="Neutral7" />
              <Text size="s">
                {intl.formatMessage({
                  id: "restaurants.locations.hours.of.operation.holidays.table.dropdown.edit",
                  defaultMessage: "Edit",
                })}
              </Text>
            </StyledMenuItem>
            <StyledMenuItem
              className="ant-menu-item"
              onClick={() => setShowDeleteConfirmation(true)}
            >
              <Icon remixiconClass="ri-delete-bin-5-line" color="Neutral7" />
              <Text size="s">
                {intl.formatMessage({
                  id: "restaurants.locations.hours.of.operation.holidays.table.dropdown.delete",
                  defaultMessage: "Delete",
                })}
              </Text>
            </StyledMenuItem>
          </ul>
        }
      >
        <StyledIconPoints>
          <Icon
            remixiconClass="ri-more-fill"
            cursor="pointer"
            color="Neutral6"
            size={16}
          />
        </StyledIconPoints>
      </DropDownAction>

      {formModalVisible && (
        <HolidayModalForm
          visible={formModalVisible}
          locationUUID={locationUUID}
          onClose={() => setFormModalVisible(false)}
          holiday={holiday}
        />
      )}

      {showDeleteConfirmation && (
        <ConfirmationModal
          centered
          visible={showDeleteConfirmation}
          onConfirm={handleDelete}
          onCancel={() => setShowDeleteConfirmation(false)}
          title={intl.formatMessage({
            id: "restaurants.locations.hours.of.operation.holidays.delete.title",
            defaultMessage: "Are you sure you want to delete this holiday?",
          })}
          subtitle={
            <FormattedMessage
              id="restaurants.locations.hours.of.operation.holidays.delete.description"
              defaultMessage="You are about to delete <strong>{holidayName}</strong> holiday."
              values={{
                holidayName: holiday.name,
                strong: (holidayName: string) => (
                  <StrongText>{holidayName}</StrongText>
                ),
              }}
            />
          }
          confirmButtonText={intl.formatMessage({
            id: "restaurants.locations.hours.of.operation.holidays.delete.ok",
            defaultMessage: "Yes, delete",
          })}
          cancelButtonText={intl.formatMessage({
            id: "restaurants.locations.hours.of.operation.holidays.delete.cancel",
            defaultMessage: "No, cancel",
          })}
          loading={deleteHolidayLoading}
        />
      )}
    </>
  )
}

const StyledIconPoints = styled.div`
  width: 24px;
  height: 32px;
  margin-left: 6px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

const StyledMenuItem = styled.li`
  display: flex;
  gap: 8px;
`

const StrongText = styled.strong`
  font-weight: bold !important;
`
