import Container from "../Container"
import Text from "../Typography/Text"
import type CSS from "csstype"
import React from "react"
import styled, { css } from "styled-components"

interface ISectionHeader {
  title?: string
  description?: string | React.ReactNode
  actionButton?: React.ReactNode
  titleGap?: CSS.Property.Width
  adviceTooltip?: React.ReactNode
  marginBottom?: string
}

export const SectionHeader: React.FC<ISectionHeader> = ({
  title,
  description,
  actionButton,
  titleGap = "8px",
  marginBottom = "16px",
  adviceTooltip,
}) => {
  return (
    <StyledSection $titleGap={titleGap} $marginBottom={marginBottom}>
      <Container
        display="flex"
        justifyContent="space-between"
        alignItems="flex-start"
        flexDirection="row"
      >
        <Container display="flex" flexDirection="column" width="100%">
          <Text size="m" weight="bold" className="title">
            {title}
          </Text>
          <Text size="s">
            {description} {adviceTooltip}
          </Text>
        </Container>
        {actionButton}
      </Container>
    </StyledSection>
  )
}

interface StyledSectionProps {
  $titleGap: CSS.Property.Width
  $marginBottom: string
}

const StyledSection = styled.section<StyledSectionProps>`
  width: 100%;
  margin-bottom: ${({ $marginBottom }) => $marginBottom};
  min-width: 294px;

  ${({ $titleGap }) => css`
    .title {
      margin-bottom: ${$titleGap};
    }
  `}
`
