import Container from "../Container"
import type { TooltipProps as AntTooltipProps } from "antd"
import { Tooltip as AntTooltip } from "antd"
import React from "react"
import styled, { css } from "styled-components"

type TooltipProps = {
  width?: number
} & AntTooltipProps

export const Tooltip: React.FC<TooltipProps> = ({
  children,
  visible = true,
  ...props
}) => {
  return visible ? (
    <StyledTooltip
      overlayInnerStyle={{ whiteSpace: "break-spaces", textAlign: "center" }}
      getPopupContainer={(triggerNode) => triggerNode}
      {...props}
    >
      <Container display="inline-block">{children}</Container>
    </StyledTooltip>
  ) : (
    <div>{children}</div>
  )
}

const StyledTooltip = styled(AntTooltip)<TooltipProps>`
  .ant-tooltip-inner {
    background-color: ${({ theme }) => theme.colors["Neutral8"]};
    border-radius: 8px;
  }

  ${({ width }) =>
    width &&
    css`
      .ant-tooltip-inner {
        width: ${width}px;
      }
    `}
`
