import type { AppHistoryStatusEnum } from "../../../../../shared/graphql/generated/types"
import type { IntlMessage } from "../../../../../shared/types"

export type PublishStatusEnum = `${AppHistoryStatusEnum}`

type ICustomPublishStatus = { [key in PublishStatusEnum]: IntlMessage }

export const CustomPublishStatusTitle: ICustomPublishStatus = {
  WAITING_FOR_REVIEW: {
    id: "settings.white.label.application.publishing.data.table.status.waiting",
    defaultMessage: "Waiting for Review",
  },
  PUBLISHED: {
    id: "settings.white.label.application.publishing.data.table.status.published",
    defaultMessage: "Published",
  },
  FAILITURE: {
    id: "settings.white.label.application.publishing.data.table.status.failiture",
    defaultMessage: "Failiture",
  },
}
