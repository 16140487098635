export const reorderArray = <T>(
  list: T[],
  startIndex: number,
  endIndex: number
) => {
  if (startIndex === endIndex) return

  const result = [...list]
  const [movedItem] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, movedItem)

  return result
}
