import * as Types from '../../../../../../../shared/graphql/generated/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeleteHolidayMutationVariables = Types.Exact<{
  uuid: Types.Scalars['ID'];
}>;


export type DeleteHolidayMutation = { __typename?: 'Mutation', deleteHoliday: { __typename?: 'Holiday', uuid: string } };


export const DeleteHolidayDocument = gql`
    mutation DeleteHoliday($uuid: ID!) {
  deleteHoliday(uuid: $uuid) {
    uuid
  }
}
    `;
export type DeleteHolidayMutationFn = Apollo.MutationFunction<DeleteHolidayMutation, DeleteHolidayMutationVariables>;

/**
 * __useDeleteHolidayMutation__
 *
 * To run a mutation, you first call `useDeleteHolidayMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteHolidayMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteHolidayMutation, { data, loading, error }] = useDeleteHolidayMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useDeleteHolidayMutation(baseOptions?: Apollo.MutationHookOptions<DeleteHolidayMutation, DeleteHolidayMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteHolidayMutation, DeleteHolidayMutationVariables>(DeleteHolidayDocument, options);
      }
export type DeleteHolidayMutationHookResult = ReturnType<typeof useDeleteHolidayMutation>;
export type DeleteHolidayMutationResult = Apollo.MutationResult<DeleteHolidayMutation>;
export type DeleteHolidayMutationOptions = Apollo.BaseMutationOptions<DeleteHolidayMutation, DeleteHolidayMutationVariables>;