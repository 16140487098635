export const tabItemsOptions = [
  {
    labelId: "restaurants.locations.overview.schedule.tabs.standard",
    label: "Standard",
    value: "standard",
  },
  {
    labelId: "restaurants.locations.overview.schedule.tabs.holiday",
    label: "Holidays",
    value: "holidays",
  },
]
