import { TokenCardHolderTypeEnum } from "../AccountDebitCardForm/hookforms.interfaces"

const holderTypeOptions = [
  {
    value: TokenCardHolderTypeEnum.INDIVIDUAL,
    labelId: "restaurants.bank.accounts.token.holder.type.individual",
    defaultMessage: "Individual",
  },
  {
    value: TokenCardHolderTypeEnum.COMPANY,
    labelId: "restaurants.bank.accounts.token.holder.type.company",
    defaultMessage: "Company",
  },
]

export default holderTypeOptions
