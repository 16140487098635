import { useEmployeesQuery } from "../Employees/GraphQL/employees.generated"
import { CreateCatering } from "./CreateCatering"
import { useLocationCateringRuleQuery } from "./Graphql/locationCateringRule.generated"
import { UpdateCatering } from "./UpdateCatering"
import { CateringSkeleton } from "./components/CateringSkeleton"
import type { EmployeeType } from "./shared/hookforms.intefaces"
import { useEffect, useMemo } from "react"
import { useParams } from "react-router-dom"

export const Catering = () => {
  const { locationUUID } = useParams<{ locationUUID: string }>()

  const { data: employeesData, refetch: refetchEmployees } = useEmployeesQuery({
    variables: {
      isActive: true,
    },
    fetchPolicy: "cache-and-network",
  })

  const employees: EmployeeType[] = useMemo(() => {
    return (
      employeesData?.employees.map((employee) => ({
        value: employee.uuid,
        label: `${employee.firstName} ${employee.lastName}`,
      })) ?? []
    )
  }, [employeesData?.employees])

  const {
    data: locationCateringRule,
    loading,
    refetch: refetchLocationCateringRule,
  } = useLocationCateringRuleQuery({
    fetchPolicy: "cache-and-network",
  })

  // Refresh content when locationUUID changes
  useEffect(() => {
    if (locationUUID) {
      refetchEmployees()
      refetchLocationCateringRule()
    }
  }, [locationUUID, refetchLocationCateringRule, refetchEmployees])

  if (loading) {
    return <CateringSkeleton />
  }

  return (
    <>
      {locationCateringRule?.locationCateringRule ? (
        <UpdateCatering employees={employees} />
      ) : (
        <CreateCatering employees={employees} />
      )}
    </>
  )
}
