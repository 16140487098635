import type { OrderThrottlingRule } from "../../../../../shared/graphql/generated/types"
import moment from "moment-timezone"

const daysOfTheWeek = [1, 2, 3, 4, 5, 6, 7]

export const getDateRangesByDaysOfTheWeek = (
  rules: Array<OrderThrottlingRule>
) => {
  const reducedDate = rules?.map((overrideRule) => {
    return {
      day: overrideRule.day,
      startDate: moment(new Date(overrideRule.startDate), "hh:mm:ss"),
      endDate: moment(new Date(overrideRule.endDate), "hh:mm:ss"),
      ordersLimit: overrideRule.ordersLimit,
      uuid: overrideRule.uuid,
    } as unknown as OrderThrottlingRule
  })

  const filterByDay = (
    rulesArray:
      | Array<{
          day: string
          startDate: moment.Moment
          endDate: moment.Moment
          ordersLimit: number
        }>
      | undefined,
    day: number
  ) => {
    const dayName = moment().isoWeekday(day).format("dddd")

    return {
      day: dayName.toUpperCase(),
      dateRanges: rulesArray
        ?.filter(
          (overrideRule) =>
            overrideRule.day.toUpperCase() === dayName.toUpperCase()
        )
        .map(({ startDate, endDate, ordersLimit }) => ({
          startDate,
          endDate,
          ordersLimit,
        })),
    }
  }

  const weekDays = daysOfTheWeek.map((weekDay) =>
    filterByDay(reducedDate, weekDay)
  )

  return weekDays
}

export default getDateRangesByDaysOfTheWeek
