import { useGeneralContext } from "../../../../shared/contexts/StoreProvider"
import type { DeliveryIntegrationEnum } from "../../../../shared/graphql/generated/types"
import Container from "../../../../ui/Container"
import DataTable from "../../../../ui/DataTable"
import EmptyState from "../../../../ui/EmptyState"
import Icon from "../../../../ui/Icon"
import DetailPage from "../../../../ui/Layouts/MainLayout/DetailPage"
import PageTitle from "../../../../ui/PageTitle"
import Text from "../../../../ui/Typography/Text"
import DropdownDeliveryIntegration from "./DropdownDelivery"
import EditDeliveryIntegration from "./EditDeliveryIntegration"
import { useGetDeliveryIntegrationsByRestaurantQuery } from "./GraphQL/getDeliveryIntegrationsByRestaurant.generated"
import type { IDeliveryIntegrationsTableModel } from "./interfaces/deliveryIntegrationsTable.interfaces"
import { CustomDeliveryIntegrationTitle } from "./titles/deliveryIntegration.title"
import { Table, Tooltip } from "antd"
import get from "lodash/get"
import React, { useEffect } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import styled from "styled-components"

export const DeliveryIntegrations: React.FC = () => {
  const intl = useIntl()

  const {
    state: {
      currentRestaurant: { uuid: restaurantUUID },
    },
  } = useGeneralContext()

  const { data, loading, error, refetch } =
    useGetDeliveryIntegrationsByRestaurantQuery({
      variables: {
        restaurantUUID,
      },
      skip: !restaurantUUID,
      notifyOnNetworkStatusChange: true,
    })

  const deliveryIntegrationList: IDeliveryIntegrationsTableModel[] = get(
    data,
    "getDeliveryIntegrationsByRestaurant",
    []
  )

  const retry = () => refetch()

  useEffect(() => {
    if (restaurantUUID) {
      refetch()
    }
  }, [refetch, restaurantUUID])

  return (
    <DetailPage>
      <PageTitle
        title={intl.formatMessage({
          id: "settings.restaurant.delivery.integration.title",
          defaultMessage: "Connect Delivery Integration",
        })}
        description={intl.formatMessage({
          id: "settings.restaurant.delivery.integration.description",
          defaultMessage:
            "Here you can see all the Delivery Integrations that you have connected to Crmb.",
        })}
      />
      <StyledDeliveryIntegrationContent>
        <DataTable
          substactPixelesToScrollHeight={311}
          dataSource={deliveryIntegrationList}
          loading={loading}
          emptyMessage={
            <EmptyState
              title={intl.formatMessage({
                id: "settings.restaurant.delivery.integration.datatable.empty.title",
                defaultMessage: "No delivery integrations added yet",
              })}
              description={intl.formatMessage({
                id: "settings.restaurant.delivery.integration.datatable.empty.description",
                defaultMessage:
                  "You can add a delivery service by clicking the plus button",
              })}
            />
          }
          error={error}
          errorRefetch={retry}
        >
          <Table.Column
            dataIndex="integrationLabel"
            title={
              <FormattedMessage
                id="settings.restaurant.delivery.integration.datatable.header.name"
                defaultMessage="Name"
              />
            }
            width="50%"
            render={(
              integrationLabel: string,
              record: IDeliveryIntegrationsTableModel
            ) => (
              <EditDeliveryIntegration
                integrationLabel={integrationLabel}
                uuid={record.uuid}
              />
            )}
          />
          <Table.Column
            dataIndex="integrationName"
            title={
              <FormattedMessage
                id="settings.restaurant.delivery.integration.datatable.header.provider"
                defaultMessage="Provider"
              />
            }
            width="20%"
            render={(integrationName: `${DeliveryIntegrationEnum}`) => {
              return intl.formatMessage(
                CustomDeliveryIntegrationTitle[integrationName]
              )
            }}
          />
          <Table.Column
            dataIndex="isActive"
            title={
              <FormattedMessage
                id="settings.restaurant.delivery.integration.datatable.header.status"
                defaultMessage="Status"
              />
            }
            width="20%"
            render={(
              isActive: boolean,
              record: IDeliveryIntegrationsTableModel
            ) => {
              return record.issues ? (
                <Tooltip
                  title={record.issues}
                  align={{
                    offset: [-40, 0],
                  }}
                >
                  <StyledWrapper
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    gap="8px"
                  >
                    <StlyedTextContainer>
                      <Text size="s">Error</Text>
                    </StlyedTextContainer>
                    <Container className="icon-container">
                      <Icon
                        remixiconClass="ri-information-line"
                        size={14}
                        cursor="pointer"
                      />
                    </Container>
                  </StyledWrapper>
                </Tooltip>
              ) : (
                <StlyedTextContainer>
                  <Text size="s"> {isActive ? "Active" : "Inactive"}</Text>
                </StlyedTextContainer>
              )
            }}
          />
          <Table.Column
            dataIndex=""
            width="10%"
            render={(_: string, record: IDeliveryIntegrationsTableModel) => {
              return <DropdownDeliveryIntegration record={record} />
            }}
          />
        </DataTable>
      </StyledDeliveryIntegrationContent>
    </DetailPage>
  )
}

const StyledDeliveryIntegrationContent = styled.div`
  margin-top: 48px;
`

const StyledWrapper = styled(Container)`
  .icon-container {
    & .ri-information-line {
      margin-top: 4px !important;
    }
  }
`

const StlyedTextContainer = styled.div`
  span {
    line-height: 20px;
  }
`
